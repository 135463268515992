import { Box, Button, FormLabel } from '@chakra-ui/react'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { DownloadButton } from '../DownloadButton'

export const InsuranceDownloads = () => {
  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>Dokumenty k pojištění</FormSectionTitle>

        <FormSectionDescription>
          <Button type="button" colorScheme="primary">
            Odeslat e-mail klientovi
          </Button>
        </FormSectionDescription>
      </FormSectionHeader>

      <FormSectionBody>
        <Box sx={{ display: 'grid', gap: 8, placeItems: 'start' }}>
          <Box sx={{ display: 'grid', gap: 4, placeItems: 'start' }}>
            <FormLabel>Dokumenty a informace k pojištění</FormLabel>

            <DownloadButton isDownloadAll>Stáhnout kompletní dokumentaci</DownloadButton>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { base: '1fr', md: '1fr 1fr' },
                placeContent: 'start',
                placeItems: 'start',
                gap: 2,
              }}
            >
              <DownloadButton>Zelená karta</DownloadButton>
              <DownloadButton>Zelená karta na zeleném podkladu</DownloadButton>
              <DownloadButton>PDF kalkulace</DownloadButton>
              <DownloadButton>Záznam z jednání a Informace pro klienta</DownloadButton>
              <DownloadButton>
                Pojištění vozidel: Informační dokument o pojistném produktu
              </DownloadButton>
            </Box>
          </Box>

          <Box sx={{ display: 'grid', gap: 4, placeItems: 'start' }}>
            <FormLabel>Právní dokumenty k pojištění</FormLabel>

            <DownloadButton>Pojistné podmínky</DownloadButton>
          </Box>
        </Box>
      </FormSectionBody>
    </FormSection>
  )
}
