import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { LoggedProfile } from '../../containers/profile/LoggedProfile'
import { profile } from '../../data/profile'

export const ProfilePage = () => {
  return (
    <Section>
      <Container>
        <LoggedProfile profile={profile} />
      </Container>
    </Section>
  )
}
