import React, { useContext } from 'react'

interface IOfferPropertyContext {
  onCompareClick?: Function
}

interface IOfferPropertyProvider {
  onCompareClick?: Function
  children?: React.ReactNode
}

const defaultState = {
  onCompareClick: () => {},
}

const OfferPropertyContext = React.createContext<IOfferPropertyContext>(defaultState)

export const OfferPropertyProvider = ({ onCompareClick, children }: IOfferPropertyProvider) => {
  return (
    <OfferPropertyContext.Provider
      value={{
        onCompareClick,
      }}
    >
      {children && children}
    </OfferPropertyContext.Provider>
  )
}

export const useOfferProperty = () => {
  const context = useContext(OfferPropertyContext)

  if (context === undefined) {
    throw new Error('useOfferProperty must be used within a OfferPropertyProvider!')
  }

  return context
}
