import { Box, Text, useBoolean } from '@chakra-ui/react'

import { IFAQ } from '../../data/faq'
import { Plus } from '../../icons'

export const FAQItem = ({ item }: { item: IFAQ }) => {
  const [isOpen, setOpen] = useBoolean()

  return (
    <Box sx={{ display: 'grid', p: 4, gap: 4, bg: 'white', borderRadius: 'md', boxShadow: 'card' }}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          placeContent: 'start',
          placeItems: 'start',
          cursor: 'pointer',
        }}
        onClick={setOpen.toggle}
      >
        <Plus
          sx={{ transform: isOpen ? 'rotate(45deg)' : 'none', transition: 'all 200ms' }}
          color="secondary.500"
        />

        <Text sx={{ fontWeight: 'semibold' }}>{item.question}</Text>
      </Box>

      {isOpen && (
        <Box sx={{ pl: 7, pb: 4 }}>
          <Text>{item.answer}</Text>
        </Box>
      )}
    </Box>
  )
}
