import { Icon } from '@chakra-ui/react'

export const Responsibility = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 28 28" fill="none">
<path d="M14.438 2.142C14.3102 2.04974 14.1566 2.00009 13.999 2.00009C13.8414 2.00009 13.6878 2.04974 13.56 2.142C12.917 2.606 11.472 3.454 9.664 4.183C7.854 4.913 5.74 5.5 3.75 5.5C3.55109 5.5 3.36032 5.57901 3.21967 5.71967C3.07902 5.86032 3 6.05108 3 6.25V14.002C3 17.029 4.703 19.843 6.837 21.952C8.97 24.059 11.665 25.592 13.87 25.976L13.999 25.998L14.127 25.976C16.333 25.591 19.027 24.059 21.16 21.952C23.295 19.843 24.998 17.029 24.998 14.002V6.25C24.998 6.05108 24.919 5.86032 24.7783 5.71967C24.6377 5.57901 24.4469 5.5 24.248 5.5C22.258 5.5 20.145 4.913 18.335 4.183C16.526 3.453 15.081 2.606 14.438 2.143V2.142ZM4.5 14.002V6.977C6.515 6.853 8.532 6.257 10.225 5.574C11.813 4.934 13.158 4.199 13.999 3.656C14.839 4.199 16.185 4.934 17.774 5.574C19.466 6.257 21.484 6.854 23.498 6.977V14.002C23.498 16.472 22.089 18.925 20.106 20.884C18.168 22.799 15.805 24.118 13.999 24.474C12.193 24.118 9.829 22.799 7.891 20.884C5.908 18.925 4.5 16.474 4.5 14.002ZM19.772 10.289C19.8428 10.2204 19.8994 10.1386 19.9385 10.0482C19.9777 9.95779 19.9987 9.86054 20.0002 9.76202C20.0018 9.6635 19.984 9.56563 19.9477 9.474C19.9115 9.38237 19.8575 9.29878 19.789 9.228C19.7204 9.15721 19.6386 9.10063 19.5482 9.06147C19.4578 9.0223 19.3605 9.00134 19.262 8.99976C19.1635 8.99818 19.0656 9.01602 18.974 9.05227C18.8824 9.08851 18.7988 9.14245 18.728 9.211L11.484 16.221L9.254 14.195C9.1058 14.0679 8.91391 14.0037 8.71909 14.0159C8.52426 14.028 8.34186 14.1157 8.21063 14.2602C8.0794 14.4047 8.00969 14.5947 8.01629 14.7898C8.02289 14.9849 8.1053 15.1697 8.246 15.305L10.996 17.805C11.137 17.9332 11.3216 18.0029 11.5122 17.9999C11.7027 17.9969 11.885 17.9215 12.022 17.789L19.772 10.289Z" fill="currentColor"/>
	</Icon>
)


export const ResponsibilityString=`<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.438 2.142C14.3102 2.04974 14.1566 2.00009 13.999 2.00009C13.8414 2.00009 13.6878 2.04974 13.56 2.142C12.917 2.606 11.472 3.454 9.664 4.183C7.854 4.913 5.74 5.5 3.75 5.5C3.55109 5.5 3.36032 5.57901 3.21967 5.71967C3.07902 5.86032 3 6.05108 3 6.25V14.002C3 17.029 4.703 19.843 6.837 21.952C8.97 24.059 11.665 25.592 13.87 25.976L13.999 25.998L14.127 25.976C16.333 25.591 19.027 24.059 21.16 21.952C23.295 19.843 24.998 17.029 24.998 14.002V6.25C24.998 6.05108 24.919 5.86032 24.7783 5.71967C24.6377 5.57901 24.4469 5.5 24.248 5.5C22.258 5.5 20.145 4.913 18.335 4.183C16.526 3.453 15.081 2.606 14.438 2.143V2.142ZM4.5 14.002V6.977C6.515 6.853 8.532 6.257 10.225 5.574C11.813 4.934 13.158 4.199 13.999 3.656C14.839 4.199 16.185 4.934 17.774 5.574C19.466 6.257 21.484 6.854 23.498 6.977V14.002C23.498 16.472 22.089 18.925 20.106 20.884C18.168 22.799 15.805 24.118 13.999 24.474C12.193 24.118 9.829 22.799 7.891 20.884C5.908 18.925 4.5 16.474 4.5 14.002ZM19.772 10.289C19.8428 10.2204 19.8994 10.1386 19.9385 10.0482C19.9777 9.95779 19.9987 9.86054 20.0002 9.76202C20.0018 9.6635 19.984 9.56563 19.9477 9.474C19.9115 9.38237 19.8575 9.29878 19.789 9.228C19.7204 9.15721 19.6386 9.10063 19.5482 9.06147C19.4578 9.0223 19.3605 9.00134 19.262 8.99976C19.1635 8.99818 19.0656 9.01602 18.974 9.05227C18.8824 9.08851 18.7988 9.14245 18.728 9.211L11.484 16.221L9.254 14.195C9.1058 14.0679 8.91391 14.0037 8.71909 14.0159C8.52426 14.028 8.34186 14.1157 8.21063 14.2602C8.0794 14.4047 8.00969 14.5947 8.01629 14.7898C8.02289 14.9849 8.1053 15.1697 8.246 15.305L10.996 17.805C11.137 17.9332 11.3216 18.0029 11.5122 17.9999C11.7027 17.9969 11.885 17.9215 12.022 17.789L19.772 10.289Z" fill="#EE7400"/>
</svg>
`;