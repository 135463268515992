import { Box, Table, Tbody, Tr, useToast } from '@chakra-ui/react'
import { Citizen, External, Group, Key, User } from '../../icons'
import { Card, CardBody } from '../../components/Card'
import { UserHeader } from './containers/UserHeader'
import { NumbersTitle, NumbersWrapper, UserNumbersTable } from './containers/NumbersTable'
import { UserDetailItemLabel, UserDetailItemValue } from './containers/UserDetailItem'
import { PageContent } from '../PageContent'
import { useParams } from 'react-router-dom'
import { useGetUserId } from '../../model/api/user/useGetUserId'
import { Loading } from '../../components/Loading'
import { useEffect } from 'react'
import { createAddressFromOptionalBits } from '../../model/utils/addressUtils'
import { useGetUserCurrent } from '../../model/api/user/useGetUserCurrent'
import { UserTypeEnum } from '../../model/enums/UserTypeEnum'
import { UserRoleEnum } from '../../model/enums/UserRoleEnum'
import moment from 'moment/moment'
import { LastChangeInformation } from '../../components/LastChangeInformation'

export const UserDetail = () => {
  const { id } = useParams<'id'>()
  const toastMessage = useToast()
  const { data, isLoading, isError, error } = useGetUserId(id)
  const fullName = `${data?.data.firstName} ${data?.data.lastName}`
  const address = createAddressFromOptionalBits(
    data?.data.city,
    data?.data.zip,
    data?.data.street,
    data?.data.streetNumber,
  )
  const insuranceAllowed = data ? data?.data.isAllowedInsurance : false
  const status = data ? data?.data.isActive : false
  const { data: currentUserData } = useGetUserCurrent()
  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return (
    <>
      {isLoading || !data?.data ? (
        <Loading />
      ) : (
        <PageContent>
          <UserHeader
            fullName={fullName}
            email={data?.data.email}
            phone={data?.data.phone}
            address={address}
            status={status}
            insuranceAllowed={insuranceAllowed}
            isCurrent={currentUserData?.data.id === data?.data.id}
            canResetPassword={data?.data.userType !== UserTypeEnum.LDAP}
            parentId={data?.data.parent?.id}
          />

          <Card>
            <CardBody>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { base: '1fr', lg: '21.25rem 1fr 1fr' },
                  alignItems: 'start',
                  columnGap: 8,
                  rowGap: 4,
                }}
              >
                <div>
                  <Table variant="unstyled">
                    <Tbody sx={{ '& > td': { mb: 2 } }}>
                      <Tr>
                        <td>
                          <UserDetailItemLabel>
                            <Group />
                            Organizace
                          </UserDetailItemLabel>
                        </td>
                        <td>
                          <UserDetailItemValue>{data?.data.parent?.username}</UserDetailItemValue>
                        </td>
                      </Tr>
                      <Tr>
                        {data?.data.role !== UserRoleEnum.SALESMAN && (
                          <>
                            <td>
                              <UserDetailItemLabel>
                                <User />
                                Typ uživatele
                              </UserDetailItemLabel>
                            </td>
                            <td>
                              <UserDetailItemValue>
                                {data?.data.userType?.toUpperCase()}
                              </UserDetailItemValue>
                            </td>
                          </>
                        )}
                      </Tr>
                      <Tr>
                        <td>
                          <UserDetailItemLabel>
                            <Key />
                            Role
                          </UserDetailItemLabel>
                        </td>
                        <td>
                          <UserDetailItemValue>
                            {data?.data.role === UserRoleEnum.SALESMAN
                              ? 'OBCHODNÍK'
                              : data?.data.role?.toUpperCase()}
                          </UserDetailItemValue>
                        </td>
                      </Tr>
                      {data?.data.role !== UserRoleEnum.SALESMAN && (
                        <Tr>
                          <td>
                            <UserDetailItemLabel>
                              <Citizen />
                              Uživatelské jméno
                            </UserDetailItemLabel>
                          </td>
                          <td>
                            <UserDetailItemValue>{data?.data.username}</UserDetailItemValue>
                          </td>
                        </Tr>
                      )}
                      {data?.data.usernameOtherSystem && (
                        <Tr>
                          <td>
                            <UserDetailItemLabel>
                              <External />
                              Uživatelské jméno
                              <br />
                              do&nbsp;jiných systémů
                            </UserDetailItemLabel>
                          </td>
                          <td>
                            <UserDetailItemValue>
                              {data?.data.usernameOtherSystem}
                            </UserDetailItemValue>
                          </td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                  {data?.data.updatedBy && moment(data?.data.updatedAt).isValid() && (
                    <Box mt={8}>
                      <LastChangeInformation
                        updatedBy={data?.data.updatedBy}
                        updatedAt={data?.data.updatedAt}
                      />
                    </Box>
                  )}
                </div>

                {data?.data.role !== UserRoleEnum.SALESMAN && data?.data.personalNumbers && (
                  <NumbersWrapper>
                    <NumbersTitle>Osobní čísla</NumbersTitle>

                    <UserNumbersTable rows={data.data.personalNumbers} />
                  </NumbersWrapper>
                )}
              </Box>
            </CardBody>
          </Card>
        </PageContent>
      )}
    </>
  )
}
