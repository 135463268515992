import { FormLabel, Switch } from '@chakra-ui/react'
import { SwitchControl } from '../components/SwitchControl'
import { IQueryParams } from '../model/interfaces/entities/IQueryParams'

export const ShowInactiveSwitch = ({
  queryParams,
  setQueryParams,
}: {
  queryParams: IQueryParams | {search?: string, unreadOnly?: number}
  setQueryParams: Function
}) => {
  return (
    <SwitchControl>
      <Switch
        size="md"
        id="unreadOnly"
        isChecked={queryParams.unreadOnly !== 1}
        onChange={(e) => {
          setQueryParams({ ...queryParams, page: 1, unreadOnly: e.target.checked ? undefined : 1 })
        }}
      />
      <FormLabel htmlFor="showInactive" variant="switch">
        Zobrazit i neaktivní
      </FormLabel>
    </SwitchControl>
  )
}
