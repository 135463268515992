import { OrganizationEditFormValues } from '../model/interfaces/entities/IOrganizationEditFormValues'
import { OrganizationTypeEnum } from '../model/enums/OrganizationTypeEnum'

export const initialValues: OrganizationEditFormValues = {
  role: 'basic',
  username: '',
  street: '',
  streetNumber: '',
  description: '',
  ino: '',
  type: OrganizationTypeEnum.BRANCH,
  prefillData: false,

  personalNumbers: [],
  web: '',
  contactPerson: '',
  email: '',
  phone: '',
  landlinePhone: '',
  educatedHead: '',
  educatedFooter: '',
  registerNote: '',
  isBusinessSubject: false,
  showLogoOkBroker: false,
  isRequiredEmail: false,
  helpdeskFullName: '',
  helpdeskTechnicalIssue: false,
  helpdeskProductIssue: false,
  helpdeskMobilePhonePrefix: '',
  helpdeskMobilePhoneNumber: '',
  helpdeskEmail: '',
  carInsuranceDocumentsLinks: '',
  carInsuranceIPID: '',
  carInsuranceAutobazar: false,
  carInsuranceShowPrefferedLiquidation: false,
  isActive: true,

  zip: '',
  country: '',
  city: '',
  logo: 'R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=',
  ruianId: 0,
  ipidUrl: '',
  documentUrls: '',
}
