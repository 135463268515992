import { Box, Button, FormLabel } from '@chakra-ui/react'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { DownloadButton } from '../DownloadButton'

export const InsuranceDownloads = () => {
  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>Dokumenty k pojištění</FormSectionTitle>

        <FormSectionDescription>
          <Button type="button" colorScheme="primary">
            Odeslat e-mail klientovi
          </Button>
        </FormSectionDescription>
      </FormSectionHeader>

      <FormSectionBody>
        <Box sx={{ display: 'grid', gap: 8, placeItems: 'start' }}>
          <Box sx={{ display: 'grid', gap: 4, placeItems: 'start' }}>
            <FormLabel>Dokumenty a informace k pojištění</FormLabel>

            <DownloadButton isDownloadAll>Stáhnout kompletní dokumentaci</DownloadButton>
          </Box>

          <Box sx={{ display: 'grid', gap: 4, placeItems: 'start' }}>
            <FormLabel>Právní dokumenty k pojištění</FormLabel>

            <DownloadButton>Pojistné podmínky</DownloadButton>
          </Box>
        </Box>
      </FormSectionBody>
    </FormSection>
  )
}
