import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGetOrganizationsPagination } from '../../model/api/organization/useGetOrganizationPagination'
import { IQueryParams } from '../../model/interfaces/entities/IQueryParams'
import { calculateTotalPages } from '../../utils/calculateTotalPages'
import { useIsSupervisorOrAdmin } from '../../model/utils/userUtils'
import { Box, Button, Flex, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import { OrgInfoSectionHeading } from './OrganizationDetail'
import { ShowInactiveSwitch } from '../ShowInactiveSwitch'
import { Add } from '../../icons'
import { PageToolbar } from '../PageToolbar'
import { SearchField } from '../SearchField'
import { TableWrapper } from '../../components/TableWrapper'
import { Loading } from '../../components/Loading'
import { Pagination } from '../../components/Pagination'
import { OrganizationChildrenRow } from './OrganizationChildrenRow'
import { appendOrRemoveQueryParameters } from '../../model/utils/urlUtils'
import { omit } from 'lodash'

export const ORGANIZATION_CHILD_LIST_PREFIX = 'organizationSub-'
const ORGANIZATION_CHILD_LIST_LIMIT = 1_000_000

export const OrganizationChildrenList: React.FC = () => {
  const navigate = useNavigate()
  const { id: parentId } = useParams<'id'>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [queryParams, setRawQueryParams] = useState<IQueryParams>({
    unreadOnly:
      searchParams.get(ORGANIZATION_CHILD_LIST_PREFIX + 'unreadOnly') === '0' ? undefined : 1,
    page: searchParams.get(ORGANIZATION_CHILD_LIST_PREFIX + 'page')
      ? parseInt(searchParams.get(ORGANIZATION_CHILD_LIST_PREFIX + 'page')!)
      : 1,
    search: searchParams.get(ORGANIZATION_CHILD_LIST_PREFIX + 'search')
      ? searchParams.get(ORGANIZATION_CHILD_LIST_PREFIX + 'search')!
      : undefined,
    limit: ORGANIZATION_CHILD_LIST_LIMIT,
  })

  const setQueryParams = (params: IQueryParams): void => {
    const newSearchParams = appendOrRemoveQueryParameters(
      omit(params, ['limit']),
      searchParams,
      ORGANIZATION_CHILD_LIST_PREFIX,
    )

    newSearchParams.delete(`${ORGANIZATION_CHILD_LIST_PREFIX}unreadOnly`)
    if (typeof params.unreadOnly === 'undefined') {
      newSearchParams.set(`${ORGANIZATION_CHILD_LIST_PREFIX}unreadOnly`, '0')
    }

    setSearchParams(newSearchParams)
    setRawQueryParams(params)
  }

  const { data, isLoading, isError } = useGetOrganizationsPagination(
    queryParams.page,
    queryParams.limit,
    queryParams.unreadOnly,
    queryParams.search,
    parentId,
  )

  const totalPages = calculateTotalPages(data?.data.count, queryParams.limit)
  const userIsSupervisor = useIsSupervisorOrAdmin()

  const [startPage, setStartPage] = useState<number>(1)
  const [endPage, setEndPage] = useState<number>(10)

  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      <PageToolbar
        leftElements={<OrgInfoSectionHeading>Podřízené organizace </OrgInfoSectionHeading>}
        rightElements={
          <Flex sx={{ gap: 4 }}>
            <ShowInactiveSwitch queryParams={queryParams} setQueryParams={setQueryParams} />
            <Button
              hidden={!userIsSupervisor}
              colorScheme="primary"
              size="sm"
              onClick={() => navigate(`/organization/create/${parentId}`)}
            >
              <Add />
              Vytvořit novou organizaci
            </Button>
          </Flex>
        }
      />

      <SearchField
        inputPlaceholder="Hledat dle názvu"
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />

      <TableWrapper>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Td>Organizace</Td>
              <Td>Popis</Td>
              <Td>Typ</Td>
              <Td></Td>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {data?.data.items.map((organization) => (
                  <OrganizationChildrenRow key={organization.id} organization={organization} />
                ))}
              </>
            )}
          </Tbody>
          <Tfoot>
            <Td colSpan={3}>
              <Pagination
                totalPages={totalPages}
                currentPage={queryParams.page}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                startPage={startPage}
                endPage={endPage}
                onSetStartPage={setStartPage}
                onSetEndPage={setEndPage}
                hide={true}
              />
            </Td>
          </Tfoot>
        </Table>
      </TableWrapper>
    </Box>
  )
}
