import { OrganizationTypeEnum } from '../enums/OrganizationTypeEnum'
import { IOrganization } from '../interfaces/entities/IOrganization'
import { ISelectOption } from '../interfaces/ISelectOption'

export const getOrganizationTypTranslated = (type: OrganizationTypeEnum): string => {
  switch (type) {
    case OrganizationTypeEnum.BRANCH:
      return 'Pobočka'
    case OrganizationTypeEnum.PPZ:
      return 'VZ'
    case OrganizationTypeEnum.PARTNER:
      return 'Partner'
  }
}

export const organizationTypeSupportsSubs = (type?: OrganizationTypeEnum): boolean => {
  // TODO: REMOVE BEFORE COMMIT
  return true

  return (
    typeof type !== 'undefined' &&
    [OrganizationTypeEnum.BRANCH, OrganizationTypeEnum.PARTNER].includes(type!)
  )
}

export const organizationListToSelectOptions = (list: IOrganization[]): ISelectOption[] => {
  return list.map((item) => ({
    label: item.username,
    value: item.id,
  }))
}
