export interface PropertyInsuranceTypeValues {
  propertyType: 'house' | 'flat' | null
  insuranceType: 'all' | 'property' | 'household' | null
}

export interface IPropertyObject {
  id: number
  name: string
  insuranceType: PropertyInsuranceTypeValues['insuranceType']
}

const housePropertyObjects: Array<IPropertyObject> = [
  {
    id: 1,
    name: 'Hlavní stavba',
    insuranceType: 'property',
  },
  {
    id: 2,
    name: 'Vedlejší stavba',
    insuranceType: 'property',
  },
  {
    id: 3,
    name: 'Vestavěné skříně',
    insuranceType: 'property',
  },
  {
    id: 4,
    name: 'Vestavěný spotřebič',
    insuranceType: 'property',
  },
  {
    id: 5,
    name: 'Solární panely',
    insuranceType: 'property',
  },
  {
    id: 6,
    name: 'Zabezpečovací systém',
    insuranceType: 'property',
  },
  {
    id: 7,
    name: 'Bojler',
    insuranceType: 'property',
  },
  {
    id: 8,
    name: 'Klimatizace',
    insuranceType: 'property',
  },
  {
    id: 9,
    name: 'Ústřední vytápění',
    insuranceType: 'property',
  },
  {
    id: 10,
    name: 'Kuchyňská linka',
    insuranceType: 'property',
  },
]

const houseHouseholdObjects: Array<IPropertyObject> = [
  {
    id: 1,
    name: 'Nábytek',
    insuranceType: 'household',
  },
  {
    id: 2,
    name: 'Knihy',
    insuranceType: 'household',
  },
  {
    id: 3,
    name: 'Oblečení',
    insuranceType: 'household',
  },
  {
    id: 4,
    name: 'Sportovní potřeby',
    insuranceType: 'household',
  },
  {
    id: 5,
    name: 'Dětské hračky',
    insuranceType: 'household',
  },
  {
    id: 6,
    name: 'Volně stojící spotřebič',
    insuranceType: 'household',
  },
  {
    id: 7,
    name: 'Zahradní technika',
    insuranceType: 'household',
  },
  {
    id: 8,
    name: 'Elektronika',
    insuranceType: 'household',
  },
  {
    id: 9,
    name: 'Příslušenství k autu',
    insuranceType: 'household',
  },
]

const flatPropertyObjects: Array<IPropertyObject> = [
  {
    id: 1,
    name: 'Hlavní stavba',
    insuranceType: 'property',
  },
  {
    id: 2,
    name: 'Vestavěné skříně',
    insuranceType: 'property',
  },
  {
    id: 3,
    name: 'Kuchyňská linka',
    insuranceType: 'property',
  },
  {
    id: 4,
    name: 'Toaleta',
    insuranceType: 'property',
  },
  {
    id: 5,
    name: 'Vana',
    insuranceType: 'property',
  },
  {
    id: 6,
    name: 'Sprchový kout',
    insuranceType: 'property',
  },
  {
    id: 7,
    name: 'Vytápění',
    insuranceType: 'property',
  },
  {
    id: 8,
    name: 'Podlahové krytiny',
    insuranceType: 'property',
  },
  {
    id: 9,
    name: 'Okna',
    insuranceType: 'property',
  },
]

const flatHouseholdObjects: Array<IPropertyObject> = [
  {
    id: 1,
    name: 'Sedací souprava',
    insuranceType: 'household',
  },
  {
    id: 2,
    name: 'Nábytek',
    insuranceType: 'household',
  },
  {
    id: 3,
    name: 'Oblečení',
    insuranceType: 'household',
  },
  {
    id: 4,
    name: 'Sportovní potřeby',
    insuranceType: 'household',
  },
  {
    id: 5,
    name: 'Dětské hračky',
    insuranceType: 'household',
  },
  {
    id: 6,
    name: 'Volně stojící spotřebič',
    insuranceType: 'household',
  },
  {
    id: 7,
    name: 'Elektronika',
    insuranceType: 'household',
  },
]

export const houseObjects = [...housePropertyObjects, ...houseHouseholdObjects]
export const flatObjects = [...flatPropertyObjects, ...flatHouseholdObjects]
