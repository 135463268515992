import { Icon } from '@chakra-ui/react'

export const Selected = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<circle cx="10" cy="10" r="8" fill="currentColor"/>
<path d="M13.7647 7.20479C13.9095 7.34141 13.9941 7.52984 13.9997 7.72864C14.0053 7.92744 13.9316 8.12033 13.7947 8.26491L9.53466 12.7654C9.46562 12.8382 9.38265 12.8965 9.29065 12.9368C9.19866 12.9771 9.09951 12.9985 8.99906 12.9999C8.89861 13.0013 8.7989 12.9826 8.70581 12.9449C8.61273 12.9072 8.52816 12.8513 8.45711 12.7804L6.20177 10.5302C6.06898 10.388 5.99668 10.1999 6.00012 10.0056C6.00355 9.81127 6.08245 9.62586 6.22019 9.48843C6.35793 9.351 6.54376 9.27228 6.73852 9.26885C6.93328 9.26542 7.12177 9.33755 7.26429 9.47005L8.97333 11.1742L12.7022 7.23479C12.8391 7.09027 13.0279 7.00593 13.2272 7.0003C13.4264 6.99467 13.6198 7.06823 13.7647 7.20479Z" fill="white"/>
	</Icon>
)


export const SelectedString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="8" fill="#2F855A"/>
<path d="M13.7647 7.20479C13.9095 7.34141 13.9941 7.52984 13.9997 7.72864C14.0053 7.92744 13.9316 8.12033 13.7947 8.26491L9.53466 12.7654C9.46562 12.8382 9.38265 12.8965 9.29065 12.9368C9.19866 12.9771 9.09951 12.9985 8.99906 12.9999C8.89861 13.0013 8.7989 12.9826 8.70581 12.9449C8.61273 12.9072 8.52816 12.8513 8.45711 12.7804L6.20177 10.5302C6.06898 10.388 5.99668 10.1999 6.00012 10.0056C6.00355 9.81127 6.08245 9.62586 6.22019 9.48843C6.35793 9.351 6.54376 9.27228 6.73852 9.26885C6.93328 9.26542 7.12177 9.33755 7.26429 9.47005L8.97333 11.1742L12.7022 7.23479C12.8391 7.09027 13.0279 7.00593 13.2272 7.0003C13.4264 6.99467 13.6198 7.06823 13.7647 7.20479Z" fill="white"/>
</svg>
`;