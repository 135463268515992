import { Icon } from '@chakra-ui/react'

export const InsuranceWaterPipesDamage = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<rect x="0.5" y="16.5" rx="0.5" stroke="currentColor"/>
<rect x="46.5" y="16.5" rx="0.5" stroke="currentColor"/>
<path d="M2 19H47" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M2 29H47" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M28 37.8262C28 35.7295 25.7963 33.5142 24.5 32C23.2037 33.5142 21 35.7295 21 37.8262C21 39.2239 21.5185 40.2722 21.7778 40.6217C22.1667 41.0876 22.9444 42 24.5 42C26.0556 42 26.8333 41.0876 27.2222 40.6217C27.4815 40.2722 28 39.2239 28 37.8262Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M27 23L27.3354 23.6708C27.7059 23.4856 27.8561 23.0351 27.6708 22.6646L27 23ZM23 25L22.6646 24.3292C22.2941 24.5144 22.1439 24.9649 22.3292 25.3354L23 25ZM24.3292 29.3354C24.5144 29.7059 24.9649 29.8561 25.3354 29.6708C25.7059 29.4856 25.8561 29.0351 25.6708 28.6646L24.3292 29.3354ZM24.3292 19.3354L26.3292 23.3354L27.6708 22.6646L25.6708 18.6646L24.3292 19.3354ZM26.6646 22.3292L22.6646 24.3292L23.3354 25.6708L27.3354 23.6708L26.6646 22.3292ZM22.3292 25.3354L24.3292 29.3354L25.6708 28.6646L23.6708 24.6646L22.3292 25.3354Z" fill="currentColor"/>
	</Icon>
)


export const InsuranceWaterPipesDamageString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="16.5" width="1" height="15" rx="0.5" stroke="#EE7400"/>
<rect x="46.5" y="16.5" width="1" height="15" rx="0.5" stroke="#EE7400"/>
<path d="M2 19H47" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M2 29H47" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M28 37.8262C28 35.7295 25.7963 33.5142 24.5 32C23.2037 33.5142 21 35.7295 21 37.8262C21 39.2239 21.5185 40.2722 21.7778 40.6217C22.1667 41.0876 22.9444 42 24.5 42C26.0556 42 26.8333 41.0876 27.2222 40.6217C27.4815 40.2722 28 39.2239 28 37.8262Z" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M27 23L27.3354 23.6708C27.7059 23.4856 27.8561 23.0351 27.6708 22.6646L27 23ZM23 25L22.6646 24.3292C22.2941 24.5144 22.1439 24.9649 22.3292 25.3354L23 25ZM24.3292 29.3354C24.5144 29.7059 24.9649 29.8561 25.3354 29.6708C25.7059 29.4856 25.8561 29.0351 25.6708 28.6646L24.3292 29.3354ZM24.3292 19.3354L26.3292 23.3354L27.6708 22.6646L25.6708 18.6646L24.3292 19.3354ZM26.6646 22.3292L22.6646 24.3292L23.3354 25.6708L27.3354 23.6708L26.6646 22.3292ZM22.3292 25.3354L24.3292 29.3354L25.6708 28.6646L23.6708 24.6646L22.3292 25.3354Z" fill="#EE7400"/>
</svg>
`;