import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { UserDetail } from '../../containers/profile/UserDetail'

export const UserDetailPage = () => {
  return (
    <Section>
      <Container>
        <UserDetail />
      </Container>
    </Section>
  )
}
