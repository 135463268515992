import { carkulkaCompanies } from '../../../utils/companies'
import { BusinessCompanyDiscount } from '../BusinessCompanyDiscount'
import { BusinessDiscountsWrapper } from '../BusinessDiscountsWrapper'

export const BusinessDiscounts = () => {
  return (
    <BusinessDiscountsWrapper>
      {carkulkaCompanies.map((company) => {
        if (company.businessDiscounts && company.businessDiscounts.carkulka) {
          return (
            <BusinessCompanyDiscount
              key={company.id}
              name={company.name}
              discounts={company.businessDiscounts.carkulka}
            />
          )
        }

        return null
      })}
    </BusinessDiscountsWrapper>
  )
}
