import { Icon } from '@chakra-ui/react'

export const Percent = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M5 15L15 5" stroke="currentColor" strokeLinecap="round"/>
<circle cx="6" cy="6" r="2.5" stroke="currentColor"/>
<circle cx="14" cy="14" r="2.5" stroke="currentColor"/>
	</Icon>
)


export const PercentString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 15L15 5" stroke="#003D66" stroke-linecap="round"/>
<circle cx="6" cy="6" r="2.5" stroke="#003D66"/>
<circle cx="14" cy="14" r="2.5" stroke="#003D66"/>
</svg>
`;