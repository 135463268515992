import { Badge, Box, Container, Grid, Image } from '@chakra-ui/react'

import { Section } from '../../components/Section'
import { Card, CardBody, CardTitle } from '../../components/Card'
import chaloupkaDashboardImage from '../../assets/images/chaloupka-dashboard-image.jpg'
import { NewsList } from '../../containers/news/NewsList'
import { filterNewsByApp } from '../../data/news'
import { ConceptsList } from '../../containers/concepts'
import { conceptsProperties } from '../../data/concepts'
import { contractsProperties } from '../../data/contracts'
import { ContractsList } from '../../containers/contracts'
import { CustomLineChart } from '../../containers/Chart'
import { fadeInLeft } from '../../utils/fadeInLeft'

export const DashboardPage = () => {
  const chaloupkaNews = filterNewsByApp([], 'chaloupka')

  return (
    <Section>
      <Container>
        <Grid sx={{ gap: 8 }}>
          <Grid
            className="dashboard-header"
            sx={{
              gridTemplateColumns: { base: '1fr', xl: '1fr 2fr' },
              gap: 4,
              justifyContent: 'space-around',
            }}
          >
            <Card sx={{ order: { base: 1, xl: 0 } }}>
              <CardTitle>
                Aktuality
                <Badge>1</Badge>
              </CardTitle>

              <CardBody sx={{ p: 0 }}>
                <NewsList news={chaloupkaNews} />
              </CardBody>
            </Card>

            <Box sx={{ display: 'grid', placeContent: 'center', placeItems: 'center' }}>
              <Image
                sx={{
                  order: { base: 0, xl: 1 },
                  animation: `${fadeInLeft} 0.5s 1s`,
                  animationFillMode: 'backwards',
                }}
                width={453}
                src={chaloupkaDashboardImage}
                alt=""
                aria-hidden
                style={{ mixBlendMode: 'multiply' }}
              />
            </Box>
          </Grid>

          <Card>
            <CardTitle>
              Neuzavřené nabídky
              <Badge>12</Badge>
            </CardTitle>

            <CardBody sx={{ p: 0 }}>
              <ConceptsList concepts={conceptsProperties} />
            </CardBody>
          </Card>

          <Card>
            <CardTitle>
              Blížící se výročí smluv
              <Badge>33</Badge>
            </CardTitle>

            <CardBody sx={{ p: 0 }}>
              <ContractsList contracts={contractsProperties} />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <CustomLineChart />
            </CardBody>
          </Card>
        </Grid>
      </Container>
    </Section>
  )
}
