import { Icon } from '@chakra-ui/react'

export const StepsIconHouse = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M8.998 2.38801C9.27332 2.14064 9.63038 2.0038 10.0005 2.0038C10.3706 2.0038 10.7277 2.14064 11.003 2.38801L16.503 7.33001C16.6594 7.47063 16.7844 7.64257 16.8701 7.83466C16.9557 8.02675 17 8.2347 17 8.44501V15.5C17 15.8978 16.842 16.2794 16.5607 16.5607C16.2794 16.842 15.8978 17 15.5 17H13C12.6022 17 12.2206 16.842 11.9393 16.5607C11.658 16.2794 11.5 15.8978 11.5 15.5V12C11.5 11.8674 11.4473 11.7402 11.3536 11.6465C11.2598 11.5527 11.1326 11.5 11 11.5H9C8.86739 11.5 8.74021 11.5527 8.64645 11.6465C8.55268 11.7402 8.5 11.8674 8.5 12V15.5C8.5 15.8978 8.34196 16.2794 8.06066 16.5607C7.77936 16.842 7.39782 17 7 17H4.5C4.10218 17 3.72064 16.842 3.43934 16.5607C3.15804 16.2794 3 15.8978 3 15.5V8.44501C3 8.02001 3.18 7.61501 3.498 7.33001L8.998 2.38801ZM10.334 3.13201C10.2422 3.04964 10.1233 3.00409 10 3.00409C9.8767 3.00409 9.75775 3.04964 9.666 3.13201L4.166 8.07401C4.1139 8.12078 4.0722 8.17797 4.0436 8.24188C4.01501 8.30579 4.00015 8.37499 4 8.44501V15.5C4 15.6326 4.05268 15.7598 4.14645 15.8536C4.24021 15.9473 4.36739 16 4.5 16H7C7.13261 16 7.25979 15.9473 7.35355 15.8536C7.44732 15.7598 7.5 15.6326 7.5 15.5V12C7.5 11.6022 7.65804 11.2207 7.93934 10.9393C8.22064 10.658 8.60218 10.5 9 10.5H11C11.3978 10.5 11.7794 10.658 12.0607 10.9393C12.342 11.2207 12.5 11.6022 12.5 12V15.5C12.5 15.6326 12.5527 15.7598 12.6464 15.8536C12.7402 15.9473 12.8674 16 13 16H15.5C15.6326 16 15.7598 15.9473 15.8536 15.8536C15.9473 15.7598 16 15.6326 16 15.5V8.44501C15.9998 8.37499 15.985 8.30579 15.9564 8.24188C15.9278 8.17797 15.8861 8.12078 15.834 8.07401L10.334 3.13201Z" fill="currentColor"/>
	</Icon>
)


export const StepsIconHouseString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.998 2.38801C9.27332 2.14064 9.63038 2.0038 10.0005 2.0038C10.3706 2.0038 10.7277 2.14064 11.003 2.38801L16.503 7.33001C16.6594 7.47063 16.7844 7.64257 16.8701 7.83466C16.9557 8.02675 17 8.2347 17 8.44501V15.5C17 15.8978 16.842 16.2794 16.5607 16.5607C16.2794 16.842 15.8978 17 15.5 17H13C12.6022 17 12.2206 16.842 11.9393 16.5607C11.658 16.2794 11.5 15.8978 11.5 15.5V12C11.5 11.8674 11.4473 11.7402 11.3536 11.6465C11.2598 11.5527 11.1326 11.5 11 11.5H9C8.86739 11.5 8.74021 11.5527 8.64645 11.6465C8.55268 11.7402 8.5 11.8674 8.5 12V15.5C8.5 15.8978 8.34196 16.2794 8.06066 16.5607C7.77936 16.842 7.39782 17 7 17H4.5C4.10218 17 3.72064 16.842 3.43934 16.5607C3.15804 16.2794 3 15.8978 3 15.5V8.44501C3 8.02001 3.18 7.61501 3.498 7.33001L8.998 2.38801ZM10.334 3.13201C10.2422 3.04964 10.1233 3.00409 10 3.00409C9.8767 3.00409 9.75775 3.04964 9.666 3.13201L4.166 8.07401C4.1139 8.12078 4.0722 8.17797 4.0436 8.24188C4.01501 8.30579 4.00015 8.37499 4 8.44501V15.5C4 15.6326 4.05268 15.7598 4.14645 15.8536C4.24021 15.9473 4.36739 16 4.5 16H7C7.13261 16 7.25979 15.9473 7.35355 15.8536C7.44732 15.7598 7.5 15.6326 7.5 15.5V12C7.5 11.6022 7.65804 11.2207 7.93934 10.9393C8.22064 10.658 8.60218 10.5 9 10.5H11C11.3978 10.5 11.7794 10.658 12.0607 10.9393C12.342 11.2207 12.5 11.6022 12.5 12V15.5C12.5 15.6326 12.5527 15.7598 12.6464 15.8536C12.7402 15.9473 12.8674 16 13 16H15.5C15.6326 16 15.7598 15.9473 15.8536 15.8536C15.9473 15.7598 16 15.6326 16 15.5V8.44501C15.9998 8.37499 15.985 8.30579 15.9564 8.24188C15.9278 8.17797 15.8861 8.12078 15.834 8.07401L10.334 3.13201Z" fill="#7B341E"/>
</svg>
`;