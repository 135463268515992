import { useMutation } from '@tanstack/react-query'
import api from '../api'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { AxiosResponse } from 'axios'

export const useResetPassword = (
  onSuccess: (data: AxiosResponse<any, any>) => void,
  onError: (data: any) => void,
) => {
  return useMutation((id: string) => api.post(`${ApiRouteEnum.USER}/${id}/reset-password`), {
    onSuccess,
    onError,
  })
}
