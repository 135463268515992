import { Button, Flex, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import { Loading } from '../../components/Loading'
import { Pagination } from '../../components/Pagination'
import { TableWrapper } from '../../components/TableWrapper'
import { IConcept } from '../../data/concepts'
import { useGetOffers } from '../../model/api/offers/useGetOffers'
import { CONCEPTS_LIST_PAGE_LIMIT } from '../../pages/chaloupka/constats'
import { calculateTotalPages } from '../../utils/calculateTotalPages'
import { ConceptRow } from './ConceptRow'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const ConceptsList = ({
  params,
  setParams,
  withPaginator = false,
  onNumberOfConcepts,
}: {
  params?: any
  setParams?: (params: any) => void
  concepts: Array<IConcept>
  withPaginator?: boolean
  onNumberOfConcepts?: (value: number) => void
}) => {
  const { data, isLoading } = useGetOffers({ ...params })
  const navigate = useNavigate()
  const [startPage, setStartPage] = useState<number>(1)
  const [endPage, setEndPage] = useState<number>(10)
  useEffect(() => {
    if (data?.data.count && onNumberOfConcepts) {
      onNumberOfConcepts(data.data.count)
    }
  }, [data, onNumberOfConcepts])

  if (isLoading) {
    return <Loading />
  }
  return (
    <TableWrapper>
      <Table cellPadding="0" cellSpacing="0">
        <Thead>
          <Tr>
            <Th>
              <p>Číslo nabídky</p>
              <p>Stav</p>
            </Th>

            <Th>
              <p>Datum vytvoření</p>
              <p>Datum zahájení</p>
            </Th>

            <Th sx={{ px: 0 }}>{/* <!-- Space for initials --> */}</Th>

            <Th sx={{ pl: 0 }}>
              <p>Pojistník</p>
            </Th>

            <Th>
              <p>Pojišťovna</p>
            </Th>

            <Th>
              <p>Název nabídky</p>
              <p>Zpracoval</p>
            </Th>

            <Th>{/* <!-- Space for status --> */}</Th>

            <Th>{/* <!-- Space for buttons --> */}</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data?.data.items &&
            data?.data.items.length > 0 &&
            data?.data.items.map((concepts, index) => {
              return <ConceptRow key={index} {...concepts} />
            })}
        </Tbody>

        <Tfoot>
          <Tr>
            <Td colSpan={8}>
              {withPaginator && (
                  <Pagination
                    totalPages={calculateTotalPages(data?.data.count, CONCEPTS_LIST_PAGE_LIMIT)}
                    currentPage={params.page}
                    queryParams={params}
                    setQueryParams={setParams}
                    startPage={startPage}
                    endPage={endPage}
                    onSetStartPage={setStartPage}
                    onSetEndPage={setEndPage}
                    count={data?.data.count}
                  />
              )}

              {!withPaginator && (
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() => navigate(`/sme/concepts`)}
                >
                  Více neuzavřených nabídek
                </Button>
              )}
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableWrapper>
  )
}
