import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { FAQAdminList } from '../../containers/faq/FAQAdminList'

export const FAQAdmin = () => {
  return (
    <Section>
      <Container>
        <FAQAdminList />
      </Container>
    </Section>
  )
}
