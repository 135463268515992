import { Text, useToken } from '@chakra-ui/react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'
import { Card, CardBody } from '../components/Card'
import { formatPrice } from '../utils/formatPrice'
const data = [
  { name: 'Červen', value: 150000 },
  { name: 'Červenec', value: 290000 },
  { name: 'Srpen', value: 190000 },
  { name: 'Září', value: 130000 },
  { name: 'Říjen', value: 110000 },
  { name: 'Listopad', value: 190000 },
]

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Card>
        <CardBody sx={{ p: 2 }}>
          <Text>{`${label}: ${payload[0].value}`}</Text>
        </CardBody>
      </Card>
    )
  }

  return null
}

export const CustomLineChart = () => {
  const primaryColor = useToken('colors', 'primary.500')

  return (
    <LineChart width={1120} height={400} data={data}>
      <CartesianGrid stroke="#f0f0f0" />
      <XAxis dataKey="name" fontSize="11px" tickMargin={10} tickSize={0} />
      <YAxis
        fontSize="11px"
        tickMargin={10}
        tickSize={0}
        width={80}
        tickFormatter={(value) => formatPrice({ price: value })}
      />
      <Tooltip content={(props) => <CustomTooltip {...props} />} />
      <Line type="monotone" dataKey="value" stroke={primaryColor} strokeWidth={2} />
    </LineChart>
  )
}
