import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  useDisclosure,
} from '@chakra-ui/react'
import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../../components/FormSection'
import { MainContent } from '../../../components/MainContent'
import { Add } from '../../../icons'
import { Form, Formik } from 'formik'
import { RadioStack } from '../../../components/RadioStack'
import { FormSpacer } from '../../../components/FormSpacer'
import { Loading } from '../../../components/Loading'
import { useGetCompanies } from '../../../model/api/company/useGetCompany'
import { InsuranceCompanies } from '../../../components/InsuranceCompanies'
import { useOfferAdditionalInsurance } from '../../../model/api/offers/useOfferAdditionalInsurance'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../../model/constants/validationMessages'

export interface IOfferAdditionalInsurance {
  range: string
  insurance: {
    companies: string[]
  }
}

const validationSchema = Yup.object({
  range: Yup.string().required(IS_REQUIRED),
  insurance: Yup.object({
    companies: Yup.array().min(1, IS_REQUIRED),
  }),
})

export const OfferAdditionalInsurance: React.FC<{ id?: string }> = ({ id }) => {
  const { mutate, isLoading: isMutating } = useOfferAdditionalInsurance(id)
  const initialValues: IOfferAdditionalInsurance = {
    range: '',
    insurance: {
      companies: [],
    },
  }
  const onSubmitForm = (values: IOfferAdditionalInsurance) => {
    mutate(values)
  }
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: dataCompanies, isLoading: isLoadingCompanies } = useGetCompanies()

  if (isLoadingCompanies || isMutating) {
    return <Loading />
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values, handleChange, setValues }) => {
        return (
          <Form>
            <MainContent>
              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Přidat další variantu pojištění</FormSectionTitle>

                  <FormSectionDescription>
                    Zadejte parametry pro vytvoření další varianty pojištění.
                  </FormSectionDescription>
                </FormSectionHeader>
                <FormSectionBody sx={{ gap: 8 }}>
                  <Flex sx={{ w: '100%', placeContent: 'end' }}>
                    <Button type="button" variant="outline" onClick={isOpen ? onClose : onOpen}>
                      {!isOpen && <Add color="secondary.500" />}
                      {isOpen ? 'Skrýt' : 'Přidat další variantu pojištění'}
                    </Button>
                  </Flex>
                  {isOpen && (
                    <>
                      <FormControl id="range" isInvalid={touched.range && !!errors.range}>
                        <FormLabel>Rozsah pojištění</FormLabel>
                        <RadioGroup id="range" name="range" value={values?.range}>
                          <RadioStack variant="stackedTile">
                            <Radio onChange={handleChange} value="low">
                              Nižší
                            </Radio>
                            <Radio onChange={handleChange} value="medium">
                              Střední
                            </Radio>
                            <Radio onChange={handleChange} value="high">
                              Vyšší
                            </Radio>
                          </RadioStack>
                        </RadioGroup>
                        {touched?.range && <FormErrorMessage>{errors?.range}</FormErrorMessage>}
                      </FormControl>

                      <FormSpacer />

                      {isLoadingCompanies || !dataCompanies ? (
                        <Loading />
                      ) : (
                        <>
                          <FormControl
                            id="insurance.companies"
                            isInvalid={
                              touched.insurance?.companies && !!errors.insurance?.companies
                            }
                          >
                            <InsuranceCompanies
                              values={values.insurance}
                              companies={dataCompanies.data}
                              setFinalCompanies={(newInsuranceValues: { companies: string[] }) => {
                                setValues({
                                  ...values,
                                  insurance: { ...newInsuranceValues },
                                })
                              }}
                            />
                            {touched.insurance?.companies && (
                              <FormErrorMessage>{errors.insurance?.companies}</FormErrorMessage>
                            )}
                          </FormControl>
                          <Button type="submit" variant="outline">
                            <Add color="secondary.500" />
                            Přidat do kalkulace
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </FormSectionBody>
              </FormSection>
            </MainContent>
          </Form>
        )
      }}
    </Formik>
  )
}
