import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'
import { FormGroup } from '../components/FormGroup'
import * as Yup from 'yup'

import { FormLabel } from '../components/FormLabel'
import {
  FormSection,
  FormSectionBody,
  FormSectionHeader,
  FormSectionTitle,
} from '../components/FormSection'
import { InputGroup } from '../components/InputGroup'
import { MainContent } from '../components/MainContent'
import { RadioStack } from '../components/RadioStack'
import { TextareaGroup } from '../components/TextareaGroup'
import { Check, Duplicate } from '../icons'
import { FormButtons } from './FormButtons'
import { Formik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { Loading } from '../components/Loading'
import { IS_NUMBER, IS_REQUIRED } from '../model/constants/validationMessages'
import { usePostStep5 } from '../model/api/insuranceForm/usePostStep5'
import { submitHandler, validateAndScroll } from '../model/utils/formHelpers'
import { usePatchStep5 } from '../model/api/insuranceForm/usePatchStep5'
import { FormSubmitEnum } from '../model/enums/FormSubmitEnum'
import { ValuesWithSubmitter } from '../model/types/ValuesWithSubmitter'
import { FormControl } from '../components/FormControl'
import { CheckboxStack } from '../components/CheckboxStack'
import React, { ChangeEvent, useEffect } from 'react'
import { DefaultErrorCallback } from '../model/api/DefaultErrorCallback'
import { Card, CardBody } from '../components/Card'
import { useCustomCopyOffer } from '../model/hooks/useCustomCopyOffer'
import { RequiredField } from './RequiredField'
import { CompanyAgreementTypeEnum } from '../model/enums/CompanyAgreementTypeEnum'
import {
  useUserIsAllowedInsurance,
  useUserIsAllowedInsuranceAnotherUser,
} from '../model/utils/userUtils'
import { useGetUser } from '../model/api/user/useGetUser'
import { useGetUserCurrent } from '../model/api/user/useGetUserCurrent'
import { UserRoleEnum } from '../model/enums/UserRoleEnum'
import useCloneClickHandler from '../model/hooks/useCloneClickHandler'

export interface IMeetingRecordFormValues {
  discrepanciesDescription: string
  reason: number
  reasonDescription: string
  impacts?: number[]
  insuranceDescription: string
  otherRequirements: boolean | string
  otherInsurance: boolean | string
  productKnowledge: number
  discrepancies: boolean
  place: string
  contractType: number
  negotiationMethod: number
  dueNotice: number[]
  personalNumber?: number
  manager?: number | any
  gainer?: number | any
}

interface MeetingRecordFormProps {
  initialData?: IMeetingRecordFormValues
  onSubmit: Function
  viewOnly?: boolean
  offerErrors?: string[]
  allowedAgreements: CompanyAgreementTypeEnum[]
}

const validationSchema = Yup.object({
  discrepanciesDescription: Yup.string().optional().nullable(),
  reason: Yup.number().required(IS_REQUIRED).min(1, 'Je potřeba zvolit').nullable(),
  reasonDescription: Yup.string().required(IS_REQUIRED).nullable(),
  insuranceDescription: Yup.string().required(IS_REQUIRED).nullable(),
  otherRequirements: Yup.string().required(IS_REQUIRED).nullable(),
  otherInsurance: Yup.string().required(IS_REQUIRED).nullable(),
  place: Yup.string().required(IS_REQUIRED).nullable(),
  contractType: Yup.number().required(IS_REQUIRED).min(1, 'Je potřeba zvolit').nullable(),
  negotiationMethod: Yup.number().required(IS_REQUIRED).min(1, 'Je potřeba zvolit').nullable(),
  personalNumber: Yup.number().nullable().typeError(IS_NUMBER),
})

export const MeetingRecordForm = ({
  initialData,
  onSubmit,
  viewOnly,
  offerErrors,
  allowedAgreements
}: MeetingRecordFormProps) => {
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  const toastMessage = useToast()
  const { mutateCopyOffer, isCopyingOffer } = useCustomCopyOffer(id)
  const userIsAllowedInsunrace = useUserIsAllowedInsurance()
  const userIsAllowedInsuranceAnotherUser = useUserIsAllowedInsuranceAnotherUser()
  const { data: currentUserData } = useGetUserCurrent()
  const { data: selectableUserData, isLoading: selectableUserDataLoading } = useGetUser(
    1,
    99999,
    undefined,
    undefined,
    undefined,
    UserRoleEnum.SALESMAN,
    !currentUserData?.data?.isAllowedInsuranceAnotherUser,
  )
  const reasonTexts = [
    'Rozsah pojistného krytí s ohledem na výši pojistného.',
    'Cena, rozsah pojištění a limity/pojistná částka odpovídají požadavkům Zákazníka.',
    'Z nabízených variant si Zákazník sám zvolil tuto pojišťovnu a trvá nauzavření tohoto pojištění',
  ]
  const impactTexts = [
    'Výluky z pojištění uvedené v pojistných podmínkách.',
    'V důsledku neplacení může dojít ke stornu pojistné smlouvy a tím ke ztrátě pojistné ochrany a krytí rizik.',
    'Vznik smlouvy je podmíněn zaplacením pojistného, které musí být připsáno na účtu pojišťovny dle platebních instrukcí uvedených v návrhu smlouvy.',
    'V průběhu trvání pojistné smlouvy bude pravděpodobně potřebné aktualizovat pojistné částky a rozsah krytí v závislosti.',
    'Povinnost oznámit změny kontaktních údajů.',
    'Možné riziko podpojištění v budoucnu, pokud nebude pojistná smlouva aktualizovaná.',
  ]

  const handleCloneClick = useCloneClickHandler(mutateCopyOffer)

  const successPatchCallback = () => {
    toastMessage({
      title: 'Uloženo',
      description: 'Změny byly uloženy',
      status: 'success',
      isClosable: true,
      position: 'top',
    })
  }

  const errorCallback = (error: any) => {
    const errorMessage = error?.response?.data?.error || 'Nepodařilo se uložit'

    toastMessage({
      title: 'Chyba',
      description: errorMessage,
      status: 'error',
      isClosable: true,
      position: 'top',
    })
  }

  const successCallback = () => {
    onSubmit()
  }

  const { mutate, isLoading } = usePostStep5(successCallback, errorCallback, id)
  const { mutate: patchMutate, isLoading: patchIsLoading } = usePatchStep5(
    successPatchCallback,
    errorCallback,
    id,
  )

  const submitting: boolean = isLoading || patchIsLoading

  const onSubmitForm = (values: ValuesWithSubmitter<IMeetingRecordFormValues>) => {
    const submitter = values[FormSubmitEnum.SUBMITTER_FIELD]
    delete values[FormSubmitEnum.SUBMITTER_FIELD]
    delete values.personalNumber
    delete values.impacts

    const formattedValues: IMeetingRecordFormValues = {
      ...values,
      reason: Number(values.reason),
      otherRequirements: values.otherRequirements === 'yes',
      otherInsurance: values.otherInsurance === 'yes',
      productKnowledge: Number(values.productKnowledge),
      discrepancies: !values.discrepancies,
      contractType: Number(values.contractType),
      negotiationMethod: Number(values.negotiationMethod),
      dueNotice: values.dueNotice.map((value) => Number(value)),
      manager: values.manager ? Number(values.manager) : null,
      gainer: values.gainer ? Number(values.gainer) : null,
    }

    if (submitter === FormSubmitEnum.SUBMIT_AND_SAVE) {
      return patchMutate(formattedValues)
    }

    mutate(formattedValues)
  }
  return (
    <>
      {initialData ? (
        <Formik
          initialValues={initialData}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            values,
            setValues,
            handleChange,
            validateForm,
            handleSubmit,
            setFieldValue,
          }) => {
            const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
              if (
                values.reasonDescription === null ||
                values.reasonDescription === '' ||
                values.reasonDescription === reasonTexts[values.reason - 1]
              ) {
                setValues({
                  ...values,
                  reasonDescription: reasonTexts[(parseInt(e.target.value) as number) - 1],
                  reason: parseInt(e.target.value) as number,
                })
              }
            }
            const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
              const content = values.insuranceDescription || ''
              const currentImpacts = values.impacts !== undefined ? values.impacts : []
              const pattern = new RegExp(
                currentImpacts
                  .sort()
                  .map((item) => {
                    return impactTexts[item - 1]
                  })
                  .join('|'),
                'g',
              )
              const modifiedContent = content.replace(pattern, '').trim()
              const clickedCheckbox = parseInt(e.target.value)
              let newImpacts: number[] = []

              if (currentImpacts.includes(clickedCheckbox)) {
                newImpacts = currentImpacts.filter((value) => value !== clickedCheckbox)
              } else {
                newImpacts = [...currentImpacts, clickedCheckbox]
              }

              const impactText = newImpacts
                .sort()
                .map((item) => {
                  return impactTexts[item - 1]
                })
                .join(' ')

              setValues({
                ...values,
                insuranceDescription:
                  modifiedContent.length === 0 ? impactText : values.insuranceDescription,
                impacts: newImpacts,
              })
            }
            return (
              <form onSubmit={(e) => submitHandler(e, handleSubmit, setFieldValue)}>
                <MainContent>
                  <FormSection>
                    <FormSectionHeader>
                      <FormSectionTitle>Záznam z jednání</FormSectionTitle>
                    </FormSectionHeader>

                    <FormSectionBody sx={{ gap: 8 }}>
                      <FormControl
                        variant="fullWidth"
                        isInvalid={
                          touched.discrepanciesDescription && !!errors.discrepanciesDescription
                        }
                      >
                        <FormLabel>
                          Případné nesrovnalosti mezi požadavky Zákazníka a sjednávaným pojištěním
                          nebo jeho podstatnou změnou
                        </FormLabel>

                        <TextareaGroup
                          placeholder="Uveďte nesrovnalosti mezi nabízeným pojištěním a požadavky zákazníka. Tyto údaje se propíšou do Záznamu z jednání, které zákazník podepisuje."
                          errors={errors as unknown as FieldError}
                          name="discrepanciesDescription"
                          defaultValue={values.discrepanciesDescription}
                          onChange={handleChange}
                        />
                        {touched.discrepanciesDescription && (
                          <FormErrorMessage>{errors.discrepanciesDescription}</FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl isInvalid={touched.reason && !!errors.reason}>
                        <FormLabel>
                          Důvody, na kterých je doporučení založeno
                          <RequiredField />
                        </FormLabel>
                        <RadioGroup name="reason" value={values.reason?.toString()}>
                          <Stack>
                            <Radio
                              onChange={(e) => {
                                handleChange(e)
                                handleRadioChange(e)
                              }}
                              value="1"
                            >
                              {reasonTexts[0]}
                            </Radio>
                            <Radio
                              onChange={(e) => {
                                handleChange(e)
                                handleRadioChange(e)
                              }}
                              value="2"
                            >
                              {reasonTexts[1]}
                            </Radio>
                            <Radio
                              onChange={(e) => {
                                handleChange(e)
                                handleRadioChange(e)
                              }}
                              value="3"
                            >
                              {reasonTexts[2]}
                            </Radio>
                          </Stack>
                        </RadioGroup>
                        {touched.reason && <FormErrorMessage>{errors.reason}</FormErrorMessage>}
                      </FormControl>

                      <FormControl
                        variant="fullWidth"
                        isInvalid={touched.reasonDescription && !!errors.reasonDescription}
                      >
                        <Textarea
                          placeholder="Důvody, na kterých je doporučení založeno"
                          name="reasonDescription"
                          value={values.reasonDescription}
                          onChange={handleChange}
                        />
                        {touched.reasonDescription && (
                          <FormErrorMessage>{errors.reasonDescription}</FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl
                        variant="fullWidth"
                        isInvalid={touched.insuranceDescription && !!errors.insuranceDescription}
                      >
                        <FormLabel tooltip="Popis dopadů sjednání/změny/ukončení pojištění, včetně souvisejících rizik">
                          Popis dopadů sjednání/změny/ukončení pojištění, včetně souvisejících rizik
                          <RequiredField />
                        </FormLabel>
                        <CheckboxGroup>
                          <Stack>
                            <Checkbox
                              id="impacts"
                              onChange={(e) => {
                                handleChange(e)
                                handleCheckboxChange(e)
                              }}
                              value="1"
                            >
                              {impactTexts[0]}
                            </Checkbox>
                            <Checkbox
                              id="impacts"
                              onChange={(e) => {
                                handleChange(e)
                                handleCheckboxChange(e)
                              }}
                              value="2"
                            >
                              {impactTexts[1]}
                            </Checkbox>
                            <Checkbox
                              id="impacts"
                              onChange={(e) => {
                                handleChange(e)
                                handleCheckboxChange(e)
                              }}
                              value="3"
                            >
                              {impactTexts[2]}
                            </Checkbox>
                            <Checkbox
                              id="impacts"
                              onChange={(e) => {
                                handleChange(e)
                                handleCheckboxChange(e)
                              }}
                              value="4"
                            >
                              {impactTexts[3]}
                            </Checkbox>
                            <Checkbox
                              id="impacts"
                              onChange={(e) => {
                                handleChange(e)
                                handleCheckboxChange(e)
                              }}
                              value="5"
                            >
                              {impactTexts[4]}
                            </Checkbox>
                          </Stack>
                        </CheckboxGroup>

                        <TextareaGroup
                          placeholder="Napište popis dopadů..."
                          name="insuranceDescription"
                          value={values.insuranceDescription}
                          onChange={handleChange}
                        />

                        <FormHelperText>
                          V případě doporučení nahrazení stávající pojistné smlouvy (tzv.
                          přesmluvnění) je třeba uvést, které požadavky, potřeby a cíle nebylo možné
                          dostatečně vyřešit změnou stávající smlouvy a vysvětlit dopady a
                          související rizika pro zákazníka. V případě doporučení sjednání pojistné
                          smlouvy je třeba vysvětlit dopady a související rizika se sjednáním
                          pojistné smlouvy nebo podstatnou změnou pojistné smlouvy.
                        </FormHelperText>
                        {touched.insuranceDescription && (
                          <FormErrorMessage>{errors.insuranceDescription}</FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl
                        isInvalid={touched.otherRequirements && !!errors.otherRequirements}
                      >
                        <FormLabel tooltip="Zákazník odmítá sdělit další požadavky?">
                          Zákazník odmítá sdělit další požadavky? <RequiredField />
                        </FormLabel>

                        <RadioGroup
                          name="otherRequirements"
                          value={
                            values.otherRequirements !== null
                              ? values.otherRequirements === 'yes' ||
                                values.otherRequirements === true
                                ? 'yes'
                                : 'no'
                              : undefined
                          }
                        >
                          <RadioStack variant="stackedTile">
                            <Radio onChange={handleChange} value="yes">
                              Ano
                            </Radio>
                            <Radio onChange={handleChange} value="no">
                              Ne
                            </Radio>
                          </RadioStack>
                        </RadioGroup>
                        {touched.otherRequirements && (
                          <FormErrorMessage>{errors.otherRequirements}</FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl isInvalid={touched.otherInsurance && !!errors.otherInsurance}>
                        <FormLabel tooltip="Má zákazník vedle této pojistné smlouvy uzavřenou další se stejným krytím?">
                          Má zákazník vedle této pojistné smlouvy uzavřenou další se stejným krytím?
                          <RequiredField />
                        </FormLabel>

                        <RadioGroup
                          name="otherInsurance"
                          value={
                            values.otherInsurance !== null
                              ? values.otherInsurance === 'yes' || values.otherInsurance === true
                                ? 'yes'
                                : 'no'
                              : undefined
                          }
                        >
                          <RadioStack variant="stackedTile">
                            <Radio onChange={handleChange} value="yes">
                              Ano
                            </Radio>
                            <Radio onChange={handleChange} value="no">
                              Ne
                            </Radio>
                          </RadioStack>
                        </RadioGroup>
                        {touched.otherInsurance && (
                          <FormErrorMessage>{errors.otherInsurance}</FormErrorMessage>
                        )}
                      </FormControl>

                      <Checkbox
                        name="discrepancies"
                        checked={values.discrepancies}
                        onChange={handleChange}
                      >
                        Zákazník ani Zprostředkovatel si nejsou vědomi žádných nesrovnalostí mezi
                        požadavky Zákazníka a nabízeným pojištěním.
                      </Checkbox>

                      <FormControl isInvalid={touched.place && !!errors.place}>
                        <FormLabel>
                          Místo sjednání
                          <RequiredField />
                        </FormLabel>

                        <InputGroup
                          name="place"
                          value={values.place}
                          onChange={handleChange}
                          placeholder="Např. Brno"
                        />
                        {touched.place && <FormErrorMessage>{errors.place}</FormErrorMessage>}
                      </FormControl>

                      <FormControl isInvalid={touched.contractType && !!errors.contractType}>
                        <FormLabel tooltip="Druh smlouvy">Druh smlouvy</FormLabel>

                        <RadioGroup name="contractType" value={values.contractType}>
                          <RadioStack>
                            <Radio onChange={handleChange} isChecked={values.contractType === 1}>
                              Nová smlouva
                            </Radio>
                          </RadioStack>
                        </RadioGroup>
                        {touched.contractType && (
                          <FormErrorMessage>{errors.contractType}</FormErrorMessage>
                        )}
                      </FormControl>

                      <FormGroup>
                        <FormControl
                          isInvalid={touched.negotiationMethod && !!errors.negotiationMethod}
                        >
                          <FormLabel>
                            Způsob sjednání <RequiredField />
                          </FormLabel>

                          <RadioGroup
                            name="negotiationMethod"
                            value={values.negotiationMethod?.toString()}
                          >
                            <RadioStack variant="stackedTile">
                              <Radio
                                isDisabled={
                                  viewOnly ||
                                  !allowedAgreements.includes(CompanyAgreementTypeEnum.SIGNATURE)
                                }
                                onChange={handleChange}
                                value="1"
                              >
                                Sjednání podpisem
                              </Radio>
                              <Radio
                                isDisabled={
                                  viewOnly ||
                                  !allowedAgreements.includes(CompanyAgreementTypeEnum.PAYMENT)
                                }
                                onChange={handleChange}
                                value="2"
                              >
                                Sjednání zaplacením
                              </Radio>
                            </RadioStack>
                          </RadioGroup>
                          {touched.negotiationMethod && (
                            <FormErrorMessage>{errors.negotiationMethod}</FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl isInvalid={touched.dueNotice && !!errors.dueNotice}>
                          <FormLabel tooltip="Upozornění na blížící se datum splatnosti prvního pojistného">
                            Upozornění na blížící se datum splatnosti prvního pojistného
                          </FormLabel>
                          {/* <CheckboxGroup defaultValue={values.dueNotice}> */}
                          <CheckboxStack>
                            <Checkbox
                              onChange={(e) => {
                                !values.dueNotice.includes(Number(e.target.value))
                                  ? setValues({
                                      ...values,
                                      dueNotice: values.dueNotice.concat(Number(e.target.value)),
                                    })
                                  : setValues({
                                      ...values,
                                      dueNotice: values.dueNotice.filter(
                                        (item) => item !== Number(e.target.value),
                                      ),
                                    })
                              }}
                              value={1}
                              name="dueNotice"
                              isChecked={values.dueNotice.includes(1)}
                            >
                              Sjednatel
                            </Checkbox>
                            <Checkbox
                              onChange={(e) => {
                                !values.dueNotice.includes(Number(e.target.value))
                                  ? setValues({
                                      ...values,
                                      dueNotice: values.dueNotice.concat(Number(e.target.value)),
                                    })
                                  : setValues({
                                      ...values,
                                      dueNotice: values.dueNotice.filter(
                                        (item) => item !== Number(e.target.value),
                                      ),
                                    })
                              }}
                              value={2}
                              name="dueNotice"
                              isChecked={values.dueNotice.includes(2)}
                            >
                              Zákazník
                            </Checkbox>
                          </CheckboxStack>
                          {/* </CheckboxGroup> */}
                          {touched.dueNotice && (
                            <FormErrorMessage>{errors.dueNotice}</FormErrorMessage>
                          )}
                        </FormControl>
                      </FormGroup>

                      {userIsAllowedInsuranceAnotherUser && (
                        <FormControl>
                          <FormLabel>Sjednání za jiného uživatele</FormLabel>

                          <Box sx={{ display: 'grid', gap: 4 }}>
                            <HStack>
                              <FormLabel tooltip="Ziskatel">Ziskatel</FormLabel>
                              <Select value={values.gainer} name="gainer" onChange={handleChange}>
                                <option value={''}>Vyberte ziskatele</option>
                                {selectableUserData?.data?.items.map((i) => (
                                  <option value={i.id}>
                                    {i.firstName} {i.lastName}
                                  </option>
                                ))}
                              </Select>
                            </HStack>

                            <HStack>
                              <FormLabel tooltip="Správce">Správce</FormLabel>
                              <Select value={values.manager} name="manager" onChange={handleChange}>
                                <option value={''}>Vyberte správce</option>
                                {selectableUserData?.data?.items.map((i) => (
                                  <option value={i.id}>
                                    {i.firstName} {i.lastName}
                                  </option>
                                ))}
                              </Select>
                            </HStack>
                          </Box>
                        </FormControl>
                      )}

                      <FormControl isInvalid={touched.personalNumber && !!errors.personalNumber}>
                        <FormLabel>Ziskatelské číslo</FormLabel>

                        <InputGroup
                          name="personalNumber"
                          onChange={handleChange}
                          value={values.personalNumber}
                          placeholder="Např. 12348765"
                          isDisabled={true}
                        />
                        {touched.personalNumber && (
                          <FormErrorMessage>{errors.personalNumber}</FormErrorMessage>
                        )}
                      </FormControl>
                    </FormSectionBody>
                  </FormSection>
                  {offerErrors &&
                    offerErrors.map((item, itemIndex) => {
                      return (
                        <Card
                          key={'errorMessage' + itemIndex}
                          bg="red.100"
                          mb={10}
                          border="1px"
                          borderColor="red.300"
                          color="red.600"
                        >
                          <CardBody>{item}</CardBody>
                        </Card>
                      )
                    })}
                  <FormButtons
                    leftButtons={
                      <Button
                        isLoading={isCopyingOffer}
                        variant="outline"
                        size="sm"
                        type="button"
                        onMouseDown={handleCloneClick}
                      >
                        <Duplicate color="secondary.500" />
                        Klonovat
                      </Button>
                    }
                    rightButtons={
                      !viewOnly ? (
                        <>
                          <Button
                            variant="outline"
                            disabled={submitting}
                            type="submit"
                            name={FormSubmitEnum.SUBMIT_AND_SAVE}
                            isLoading={submitting}
                          >
                            Uložit
                          </Button>
                          {userIsAllowedInsunrace && (
                            <Button
                              colorScheme="green"
                              isLoading={submitting}
                              isDisabled={submitting}
                              loadingText="Sjednávám..."
                              type="submit"
                              onClick={() => {
                                validateAndScroll(values, validateForm, toastMessage)
                              }}
                            >
                              <Check />
                              Sjednat pojištění
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          colorScheme="primary"
                          onClick={() => navigate(`/sme/wizard/${id}/completed`)}
                        >
                          Pokračovat
                        </Button>
                      )
                    }
                  />
                </MainContent>
              </form>
            )
          }}
        </Formik>
      ) : (
        <Loading />
      )}
    </>
  )
}
