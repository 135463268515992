import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import { MouseEvent, useState } from 'react'

import { DismissCircle } from '../icons'

interface ConfirmRemoveButtonProps extends ButtonProps {}

export const ConfirmRemoveButton = ({ onClick }: ConfirmRemoveButtonProps) => {
  const [confirm, setConfirm] = useState<boolean>(false)

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (!confirm) {
      setConfirm(true)
    } else {
      onClick && onClick(e)
    }
  }

  return (
    <Flex sx={{ gap: 2, w: '100%', justifyContent: confirm ? 'space-between' : 'start' }}>
      {confirm && (
        <Button
          type="button"
          colorScheme={'gray'}
          variant="ghost"
          onClick={() => setConfirm(false)}
        >
          Zrušit
        </Button>
      )}

      <Button
        type="button"
        colorScheme="red"
        variant={confirm ? 'solid' : 'outline'}
        onClick={handleClick}
      >
        <DismissCircle color={confirm ? 'white' : 'red'} />
        {confirm ? 'Opravdu odebrat?' : 'Odebrat tuto adresu'}
      </Button>
    </Flex>
  )
}
