import { Icon } from '@chakra-ui/react'

export const NotSelected = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M5.78947 9H9.21053H10.7895H14.2105C14.4199 9 14.6207 9.10536 14.7688 9.29289C14.9168 9.48043 15 9.73478 15 10C15 10.2652 14.9168 10.5196 14.7688 10.7071C14.6207 10.8946 14.4199 11 14.2105 11H10.7895H9.21053H5.78947C5.58009 11 5.37929 10.8946 5.23123 10.7071C5.08318 10.5196 5 10.2652 5 10C5 9.73478 5.08318 9.48043 5.23123 9.29289C5.37929 9.10536 5.58009 9 5.78947 9Z" fill="currentColor"/>
<circle cx="10" cy="10" r="7.25" stroke="currentColor" strokeWidth="1.5"/>
	</Icon>
)


export const NotSelectedString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.78947 9H9.21053H10.7895H14.2105C14.4199 9 14.6207 9.10536 14.7688 9.29289C14.9168 9.48043 15 9.73478 15 10C15 10.2652 14.9168 10.5196 14.7688 10.7071C14.6207 10.8946 14.4199 11 14.2105 11H10.7895H9.21053H5.78947C5.58009 11 5.37929 10.8946 5.23123 10.7071C5.08318 10.5196 5 10.2652 5 10C5 9.73478 5.08318 9.48043 5.23123 9.29289C5.37929 9.10536 5.58009 9 5.78947 9Z" fill="#A0AEC0"/>
<circle cx="10" cy="10" r="7.25" stroke="#A0AEC0" stroke-width="1.5"/>
</svg>
`;