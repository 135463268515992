import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import api from '../api'
import { IAuthOkkSso } from '../../interfaces/entities/IAuthOkkSso'

export const useGetAuthOkhSso = () => {
  return useQuery(
    [ApiRouteEnum.AUTH_OKH_SSO],
    () => api.get<IAuthOkkSso>(ApiRouteEnum.AUTH_OKH_SSO),
    {
      retry: false,
    },
  )
}
