declare namespace Intl {
  interface DateTimeFormatOptions {
    dateStyle?: 'full' | 'long' | 'medium' | 'short'
    timeStyle?: 'full' | 'long' | 'medium' | 'short'
  }
}

interface IFormatDate extends Intl.DateTimeFormatOptions {
  date: Date
}

export const formatDate = ({ date, dateStyle, timeStyle }: IFormatDate) => {
  return Intl.DateTimeFormat('cs-CZ', {
    dateStyle,
    timeStyle,
  }).format(date)
}
