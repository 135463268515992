import { Box, FormControl, FormHelperText } from '@chakra-ui/react'
import React from 'react'

import { FormLabel } from '../../../../components/FormLabel'
import { InputGroup } from '../../../../components/InputGroup'
import { SaveAndRecalculateButton } from '../../../SaveAndRecalculateButton'

const DiscountInputGroup = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ display: 'grid', placeItems: 'start', gridTemplateColumns: '1fr 1fr', gap: 4 }}>
      {children}
    </Box>
  )
}

const DiscountInput = ({
  children,
  label,
  helperText,
  max,
}: {
  children: React.ReactNode
  label: string
  helperText?: string
  max: number
}) => {
  const labelText = `${label} (max. ${max} %)`

  return (
    <FormControl>
      <FormLabel sx={{ fontSize: 'sm', fontWeight: 'normal' }}>{labelText}</FormLabel>

      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(
            child,
            {
              min: 0,
              max,
              htmlSize: 8,
              inputMode: 'decimal',
              selectAllonFocus: true,
            } as any,
          )
        }
        return child
      })}

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export const DiscountsTab = () => {
  return (
    <Box sx={{ display: 'grid', placeContent: 'start', placeItems: 'start', gap: 4 }}>
      <Box
        sx={{
          display: { base: 'grid', md: 'flex' },
          placeItems: 'start',
          gap: { base: 4, lg: 16 },
        }}
      >
        <Box sx={{ display: 'grid', placeItems: 'start', gap: 4 }}>
          <FormLabel>Obchodní slevy</FormLabel>

          <DiscountInputGroup>
            <DiscountInput label="Ochodní sleva" max={30}>
              <InputGroup id="" name="" defaultValue={30} />
            </DiscountInput>
          </DiscountInputGroup>
        </Box>
      </Box>

      <SaveAndRecalculateButton />
    </Box>
  )
}
