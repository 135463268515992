import { OrganizationEditSchema } from '../utils/OrganizationEditValidation'
import { Form, Formik } from 'formik'
import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../components/FormSection'
import { FormGroup } from '../components/FormGroup'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Grid,
  Select,
  Switch,
  useToast,
} from '@chakra-ui/react'
import { FormLabel } from '../components/FormLabel'
import { InputGroup } from '../components/InputGroup'
import { OrganizationTypeEnum } from '../model/enums/OrganizationTypeEnum'
import { organizationTypeSupportsSubs } from '../model/utils/organizationUtils'
import { TextareaGroup } from '../components/TextareaGroup'
import { FormSpacer } from '../components/FormSpacer'
import AddressAutocomplete from '../components/AddressAutocomplete'
import { IAddressSuggestion } from '../model/interfaces/entities/IAddressSuggestion'
import {
  transformAddressToStreet,
  transformAddressToStreetNumber,
} from '../model/utils/addressUtils'
import { SwitchControl } from '../components/SwitchControl'
import { phonePrefixes } from '../utils/phonePrefixes'
import { ArrowLeft } from '../icons'
import { validateAndScroll } from '../model/utils/formHelpers'
import React from 'react'
import { useGetCompanies } from '../model/api/company/useGetCompany'
import { OrganizationEditFormValues } from '../model/interfaces/entities/IOrganizationEditFormValues'
import { RequiredField } from './RequiredField'
import { useIsSupervisor } from '../model/utils/userUtils'

export const OrganizationForm: React.FC<{
  initialData: OrganizationEditFormValues
  onSubmitForm: (values: any) => void
  onGoBack: () => void
  isLoading: boolean
}> = ({ initialData, onSubmitForm, onGoBack, isLoading }) => {
  const { data: dataCompany } = useGetCompanies()
  const toastMessage = useToast()
  const isSupervisor = useIsSupervisor()

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialData}
      onSubmit={(values) => onSubmitForm(values)}
      validationSchema={OrganizationEditSchema}
    >
      {({ handleChange, errors, touched, values, setValues, validateForm }) => {
        console.log(values)
        return (
          <Form>
            <FormSection>
              <FormSectionHeader>
                <FormSectionTitle>Informace o organizaci</FormSectionTitle>

                <FormSectionDescription>Základní informace o organizaci.</FormSectionDescription>
              </FormSectionHeader>

              <FormSectionBody>
                <FormGroup sx={{ columnGap: 4 }}>
                  <FormControl isInvalid={touched.type && !!errors.type}>
                    <FormLabel>Typ organizační jednotky</FormLabel>

                    <Select
                      placeholder="Vyberte..."
                      value={values.type}
                      id="type"
                      name="type"
                      onChange={handleChange}
                    >
                      <option value={OrganizationTypeEnum.BRANCH}>Pobočka</option>
                      <option value={OrganizationTypeEnum.PPZ}>VZ</option>
                      {isSupervisor && (
                        <option value={OrganizationTypeEnum.PARTNER}>Partner</option>
                      )}
                    </Select>
                    {touched.type && <FormErrorMessage>{errors.type}</FormErrorMessage>}
                  </FormControl>

                  <FormControl isInvalid={touched.username && !!errors.username}>
                    <FormLabel htmlFor="username">
                      Název
                      <RequiredField />
                    </FormLabel>
                    <InputGroup
                      id="username"
                      name="username"
                      placeholder="Uživatelské jméno"
                      autoComplete="name"
                      onChange={handleChange}
                      defaultValue={values.username}
                    />
                    {touched.username && <FormErrorMessage>{errors.username}</FormErrorMessage>}
                  </FormControl>
                </FormGroup>

                {values.type && organizationTypeSupportsSubs(values.type) && (
                  <Checkbox
                    name="prefillData"
                    isChecked={!!values.prefillData}
                    onChange={handleChange}
                  >
                    Propisování údajů na podřízené záznamy
                  </Checkbox>
                )}

                <FormControl variant="fullWidth">
                  <FormLabel>Popis</FormLabel>

                  <TextareaGroup
                    id="description"
                    name="description"
                    placeholder="Popis organizace"
                    autoComplete="description"
                    onChange={handleChange}
                    defaultValue={values.description}
                  ></TextareaGroup>
                </FormControl>

                <FormControl isInvalid={touched.ino && !!errors.ino}>
                  <FormLabel>
                    IČO
                    {values.ino === '' && <RequiredField />}
                  </FormLabel>

                  <InputGroup
                    id="ino"
                    name="ino"
                    placeholder="Identifikační číslo firmy"
                    autoComplete="businessId"
                    onChange={handleChange}
                    defaultValue={values.ino}
                  />
                  {touched.ino && <FormErrorMessage>{errors.ino}</FormErrorMessage>}
                </FormControl>

                <FormSpacer />

                <FormGroup>
                  <Box sx={{ display: 'grid', gap: 8, justifyItems: 'start' }}>
                    <Grid
                      sx={{
                        gap: 4,
                        justifyItems: 'start',
                      }}
                    >
                      <FormControl isInvalid={touched.street && !!errors.ruianId}>
                        <AddressAutocomplete
                          onAddressAccepted={(address: IAddressSuggestion) => {
                            setValues({
                              ...values,
                              street: transformAddressToStreet(address),
                              zip: address.postalCode || '',
                              city: address.city || '',
                              streetNumber: transformAddressToStreetNumber(address),
                              ruianId: address.ruianId!,
                            })
                          }}
                        />
                        {touched.street && <FormErrorMessage>{errors.ruianId}</FormErrorMessage>}
                      </FormControl>
                    </Grid>
                  </Box>
                </FormGroup>
                <FormGroup>
                  <FormControl isInvalid={touched.street && !!errors.street}>
                    <FormLabel>
                      Ulice
                      {values.ino === '' && <RequiredField />}
                    </FormLabel>

                    <InputGroup
                      id="street"
                      name="street"
                      placeholder="Např. Dlouhá"
                      htmlSize={24}
                      autoComplete="street"
                      onChange={handleChange}
                      value={values.street}
                      isDisabled={true}
                    />
                    {touched.street && <FormErrorMessage>{errors.street}</FormErrorMessage>}
                  </FormControl>

                  <FormControl isInvalid={touched.streetNumber && !!errors.streetNumber}>
                    <FormLabel>
                      Číslo popisné nebo orientační
                      {values.ino === '' && <RequiredField />}
                    </FormLabel>

                    <InputGroup
                      id="streetNumber"
                      name="streetNumber"
                      placeholder="Např. 123"
                      htmlSize={4}
                      autoComplete="houseNumber"
                      onChange={handleChange}
                      value={values.streetNumber}
                      isDisabled={true}
                    />
                    {touched.streetNumber && (
                      <FormErrorMessage>{errors.streetNumber}</FormErrorMessage>
                    )}
                  </FormControl>
                </FormGroup>

                <FormGroup>
                  <FormControl isInvalid={touched.zip && !!errors.zip}>
                    <FormLabel htmlFor="postalCode" tooltip="PSČ">
                      PSČ
                      {values.ino === '' && <RequiredField />}
                    </FormLabel>
                    <InputGroup
                      id="zip"
                      name="zip"
                      placeholder="00000"
                      autoComplete="postal-code"
                      htmlSize={4}
                      onChange={handleChange}
                      value={values.zip}
                      isDisabled={true}
                    />
                    {touched.zip && (
                      <FormErrorMessage maxW="fit-content">{errors.zip}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl>
                    <FormLabel>Stát</FormLabel>

                    <Select
                      isDisabled={true}
                      defaultValue="CZ"
                      id="country"
                      name="country"
                      onChange={handleChange}
                    >
                      <option value="CZ">Česko</option>
                    </Select>
                  </FormControl>

                  <FormControl isInvalid={touched.city && !!errors.city}>
                    <FormLabel>
                      Město
                      {values.ino === '' && <RequiredField />}
                    </FormLabel>

                    <InputGroup
                      id="city"
                      name="city"
                      placeholder="Např. Praha"
                      autoComplete="city"
                      onChange={handleChange}
                      value={values.city}
                      isDisabled={true}
                    />
                    {touched.city && <FormErrorMessage>{errors.city}</FormErrorMessage>}
                  </FormControl>
                </FormGroup>

                <FormSpacer />

                <FormControl>
                  <FormLabel>Aktivní</FormLabel>

                  <SwitchControl>
                    <FormLabel htmlFor="active" variant="switch">
                      Ne
                    </FormLabel>
                    <Switch
                      id="isActive"
                      name="isActive"
                      size="md"
                      isChecked={values.isActive}
                      onChange={handleChange}
                    />
                    <FormLabel htmlFor="active" variant="switch">
                      Ano
                    </FormLabel>
                  </SwitchControl>
                </FormControl>
              </FormSectionBody>
            </FormSection>

            {dataCompany?.data && dataCompany?.data.length > 0 && (
              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Ziskatelská čísla</FormSectionTitle>

                  <FormSectionDescription>
                    Zadejte ziskatelská čísla u jednotlivých pojišťoven.
                  </FormSectionDescription>
                </FormSectionHeader>

                <FormSectionBody>
                  <Box
                    sx={{
                      display: 'grid',
                      gridAutoFlow: 'reverse',
                      gridTemplateColumns: { base: '1fr', md: '1fr 1fr' },
                      gridTemplateRows: `repeat(${Math.round(dataCompany?.data.length / 2)}, auto)`,
                      columnGap: 16,
                      rowGap: 4,
                    }}
                  >
                    {values.personalNumbers.map((personalNumber, personalNumberIndex) => {
                      return (
                        <FormControl key={personalNumberIndex}>
                          <FormLabel htmlFor={`personalNumbers[${personalNumberIndex}].number`}>
                            {personalNumber.company.name}
                          </FormLabel>
                          <InputGroup
                            id={`personalNumbers[${personalNumberIndex}].number`}
                            name={`personalNumbers[${personalNumberIndex}].number`}
                            onChange={handleChange}
                            defaultValue={personalNumber.number}
                          />
                        </FormControl>
                      )
                    })}
                  </Box>
                </FormSectionBody>
              </FormSection>
            )}

            <FormSection>
              <FormSectionHeader>
                <FormSectionTitle>Nastavení aplikace</FormSectionTitle>

                <FormSectionDescription>
                  Dostupná nastavení aplikace pro danou organizaci.
                </FormSectionDescription>
              </FormSectionHeader>

              <FormSectionBody>
                <FormControl isInvalid={touched.web && !!errors.web}>
                  <FormLabel>Webové stránky</FormLabel>

                  <InputGroup
                    id="web"
                    name="web"
                    placeholder="Např. https://www.okgroup.cz/"
                    autoComplete="web"
                    onChange={handleChange}
                    defaultValue={values.web}
                  />
                  {touched.web && <FormErrorMessage>{errors.web}</FormErrorMessage>}
                </FormControl>
                <FormControl
                  variant="fullWidth"
                  isInvalid={touched.contactPerson && !!errors.contactPerson}
                >
                  <FormLabel>Kontaktní osoba</FormLabel>

                  <InputGroup
                    id="contactPerson"
                    name="contactPerson"
                    onChange={handleChange}
                    defaultValue={values.contactPerson}
                  />
                  {touched.contactPerson && (
                    <FormErrorMessage>{errors.contactPerson}</FormErrorMessage>
                  )}
                </FormControl>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 4,
                  }}
                >
                  <FormControl isInvalid={touched.email && !!errors.email}>
                    <FormLabel>E-mail</FormLabel>

                    <InputGroup
                      id="email"
                      name="email"
                      onChange={handleChange}
                      defaultValue={values.email}
                    />
                    {touched.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                  </FormControl>
                  <FormControl isInvalid={touched.phone && !!errors.phone}>
                    <FormLabel>Mobil</FormLabel>

                    <InputGroup
                      id="phone"
                      name="phone"
                      onChange={handleChange}
                      defaultValue={values.phone}
                    />
                    {touched.phone && <FormErrorMessage>{errors.phone}</FormErrorMessage>}
                  </FormControl>
                  <FormControl isInvalid={touched.landlinePhone && !!errors.landlinePhone}>
                    <FormLabel>Telefon</FormLabel>

                    <InputGroup
                      id="landlinePhone"
                      name="landlinePhone"
                      onChange={handleChange}
                      defaultValue={values.landlinePhone}
                    />
                    {touched.landlinePhone && (
                      <FormErrorMessage>{errors.landlinePhone}</FormErrorMessage>
                    )}
                  </FormControl>
                </Box>

                <FormControl variant="fullWidth">
                  <FormLabel>Poučení subjektu - záhlaví</FormLabel>

                  <TextareaGroup
                    id="educatedHead"
                    name="educatedHead"
                    placeholder="Napište poučení subjektu, které se zobrazí v záhlaví."
                    autoComplete="educatedHead"
                    onChange={handleChange}
                    value={values.educatedHead}
                  />
                </FormControl>

                <FormControl variant="fullWidth">
                  <FormLabel>Poučení subjektu - zápatí </FormLabel>

                  <TextareaGroup
                    id="educatedFooter"
                    name="educatedFooter"
                    placeholder="Napište poučení subjektu, které se zobrazí v zápatí."
                    autoComplete="educatedFooter"
                    onChange={handleChange}
                    value={values.educatedFooter}
                  />
                </FormControl>

                <FormControl variant="fullWidth">
                  <FormLabel>Obchodní rejstřík</FormLabel>

                  <TextareaGroup
                    id="registerNote"
                    name="registerNote"
                    placeholder="Poznámka o zápisu firmy do obchodního rejstříku"
                    autoComplete="businessRegistration"
                    onChange={handleChange}
                    value={values.registerNote}
                  />
                </FormControl>

                <FormGroup>
                  <FormControl>
                    <FormLabel>Podnikatelský subjekt</FormLabel>

                    <SwitchControl>
                      <FormLabel htmlFor="isBusinessSubject" variant="switch">
                        Ne
                      </FormLabel>
                      <Switch
                        id="isBusinessSubject"
                        name="isBusinessSubject"
                        size="md"
                        autoComplete="isBusinessSubject"
                        onChange={handleChange}
                        isChecked={!!values.isBusinessSubject}
                      />
                      <FormLabel htmlFor="isBusinessSubject" variant="switch">
                        Ano
                      </FormLabel>
                    </SwitchControl>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Zobrazit logo OK Brokers v ZzJ</FormLabel>

                    <SwitchControl>
                      <FormLabel htmlFor="showLogoOkBroker" variant="switch">
                        Ne
                      </FormLabel>
                      <Switch
                        id="showLogoOkBroker"
                        name="showLogoOkBroker"
                        size="md"
                        autoComplete="showLogoOkBroker"
                        onChange={handleChange}
                        isChecked={values.showLogoOkBroker}
                      />
                      <FormLabel htmlFor="showLogoOkBroker" variant="switch">
                        Ano
                      </FormLabel>
                    </SwitchControl>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Povinné vyplnění emailu pojistníka</FormLabel>

                    <SwitchControl>
                      <FormLabel htmlFor="isRequiredEmail" variant="switch">
                        Ne
                      </FormLabel>
                      <Switch
                        id="isRequiredEmail"
                        name="isRequiredEmail"
                        size="md"
                        autoComplete="isRequiredEmail"
                        onChange={handleChange}
                        isChecked={values.isRequiredEmail}
                      />
                      <FormLabel htmlFor="isRequiredEmail" variant="switch">
                        Ano
                      </FormLabel>
                    </SwitchControl>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <FormControl isInvalid={touched.ipidUrl && !!errors.ipidUrl}>
                    <FormLabel>Odkaz na IPID</FormLabel>
                    <InputGroup
                      placeholder="Odkaz na IPID"
                      id="ipidUrl"
                      name="ipidUrl"
                      onChange={handleChange}
                      defaultValue={values.ipidUrl}
                    />
                    {touched.ipidUrl && <FormErrorMessage>{errors.ipidUrl}</FormErrorMessage>}
                  </FormControl>
                </FormGroup>
                <FormControl
                  variant="fullWidth"
                  isInvalid={touched.documentUrls && !!errors.documentUrls}
                >
                  <FormLabel>Odkazy na dokumentaci</FormLabel>

                  <TextareaGroup
                    id="documentUrls"
                    name="documentUrls"
                    placeholder="Odkazy na dokumentaci oddělené středníkem"
                    onChange={handleChange}
                    value={values.documentUrls}
                  />
                </FormControl>
              </FormSectionBody>
            </FormSection>

            <FormSection>
              <FormSectionHeader>
                <FormSectionTitle>Kontaktní osoba HelpDesku</FormSectionTitle>

                <FormSectionDescription>
                  Zadejte kontakt na kontaktní osobu, která bude dostupná pro případ dotazl přes
                  HelpDesk.
                </FormSectionDescription>
              </FormSectionHeader>

              <FormSectionBody>
                <FormGroup>
                  <FormControl>
                    <FormLabel>Celé jméno</FormLabel>

                    <InputGroup
                      id="helpdeskFullName"
                      name="helpdeskFullName"
                      placeholder="Např. Jana Nováková"
                      autoComplete="helpdeskFullName"
                      onChange={handleChange}
                      value={values.helpdeskFullName}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Technický problém</FormLabel>

                    <SwitchControl>
                      <FormLabel htmlFor="helpdeskTechnicalIssue" variant="switch">
                        Ne
                      </FormLabel>
                      <Switch
                        id="helpdeskTechnicalIssue"
                        name="helpdeskTechnicalIssue"
                        size="md"
                        autoComplete="helpdeskTechnicalIssue"
                        onChange={handleChange}
                        isChecked={values.helpdeskTechnicalIssue}
                      />
                      <FormLabel htmlFor="helpdeskTechnicalIssue" variant="switch">
                        Ano
                      </FormLabel>
                    </SwitchControl>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Produktový problém</FormLabel>

                    <SwitchControl>
                      <FormLabel htmlFor="helpdeskProductIssue" variant="switch">
                        Ne
                      </FormLabel>
                      <Switch
                        id="helpdeskProductIssue"
                        name="helpdeskProductIssue"
                        size="md"
                        autoComplete="helpdeskProductIssue"
                        onChange={handleChange}
                        isChecked={values.helpdeskProductIssue}
                      />
                      <FormLabel htmlFor="helpdeskProductIssue" variant="switch">
                        Ano
                      </FormLabel>
                    </SwitchControl>
                  </FormControl>
                </FormGroup>

                <FormGroup>
                  <FormControl>
                    <FormLabel>Mobilní telefon</FormLabel>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'auto auto',
                        placeContent: 'start',
                        placeItems: 'start',
                        gap: 2,
                      }}
                    >
                      <Select
                        id="helpdeskMobilePhonePrefix"
                        name="helpdeskMobilePhonePrefix"
                        onChange={handleChange}
                        value={values.helpdeskMobilePhonePrefix}
                      >
                        {phonePrefixes &&
                          phonePrefixes.map((prefix) => {
                            return (
                              <option key={prefix} value={prefix}>
                                {prefix}
                              </option>
                            )
                          })}
                      </Select>

                      <InputGroup
                        id="helpdeskMobilePhoneNumber"
                        name="helpdeskMobilePhoneNumber"
                        inputMode="tel"
                        placeholder="Telefonní číslo"
                        pattern="[0-9\ ]+"
                        htmlSize={10}
                        onChange={handleChange}
                        value={values.helpdeskMobilePhoneNumber}
                      />
                    </Box>
                  </FormControl>

                  <FormControl>
                    <FormLabel>E-mail</FormLabel>

                    <InputGroup
                      id="helpdeskEmail"
                      name="helpdeskEmail"
                      type="email"
                      placeholder="Např. jana.novakova@okgroup.cz"
                      autoComplete="helpdeskEmail"
                      onChange={handleChange}
                      value={values.helpdeskEmail}
                    />
                  </FormControl>
                </FormGroup>
              </FormSectionBody>
            </FormSection>
            <Grid
              sx={{
                width: '100%',
                gridTemplateColumns: 'auto auto',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button variant="ghost" type="button" onClick={onGoBack}>
                <ArrowLeft color="secondary.500" />
                Zpět
              </Button>
              <Button
                colorScheme="primary"
                type="submit"
                isLoading={isLoading}
                onClick={() => validateAndScroll(values, validateForm, toastMessage)}
              >
                Uložit
              </Button>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
