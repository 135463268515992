import { Icon } from '@chakra-ui/react'

export const House = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 28 28" fill="none">
<path d="M12.592 3.496C12.9913 3.17561 13.488 3.001 14 3.001C14.512 3.001 15.0087 3.17561 15.408 3.496L23.158 9.714C23.4209 9.92493 23.6331 10.1922 23.7788 10.4962C23.9246 10.8001 24.0001 11.1329 24 11.47V22.75C24 23.3467 23.7629 23.919 23.341 24.341C22.919 24.7629 22.3467 25 21.75 25H18.75C18.1533 25 17.581 24.7629 17.159 24.341C16.7371 23.919 16.5 23.3467 16.5 22.75V16.75C16.5 16.5511 16.421 16.3603 16.2803 16.2197C16.1397 16.079 15.9489 16 15.75 16H12.25C12.0511 16 11.8603 16.079 11.7197 16.2197C11.579 16.3603 11.5 16.5511 11.5 16.75V22.75C11.5 23.3467 11.2629 23.919 10.841 24.341C10.419 24.7629 9.84674 25 9.25 25H6.25C5.65326 25 5.08097 24.7629 4.65901 24.341C4.23705 23.919 4 23.3467 4 22.75V11.47C4 10.788 4.31 10.142 4.842 9.715L12.592 3.497V3.496ZM14.469 4.666C14.3359 4.55937 14.1705 4.50127 14 4.50127C13.8295 4.50127 13.6641 4.55937 13.531 4.666L5.781 10.884C5.69334 10.9542 5.62258 11.0433 5.57395 11.1445C5.52531 11.2458 5.50004 11.3567 5.5 11.469V22.749C5.5 23.164 5.836 23.499 6.25 23.499H9.25C9.44891 23.499 9.63968 23.42 9.78033 23.2793C9.92098 23.1387 10 22.9479 10 22.749V16.749C10 16.1523 10.2371 15.58 10.659 15.158C11.081 14.7361 11.6533 14.499 12.25 14.499H15.75C16.0456 14.499 16.3382 14.5572 16.6113 14.6704C16.8843 14.7835 17.1324 14.9493 17.3413 15.1584C17.5503 15.3674 17.716 15.6156 17.829 15.8887C17.942 16.1618 18.0001 16.4544 18 16.75V22.75C18 23.165 18.336 23.5 18.75 23.5H21.75C21.9489 23.5 22.1397 23.421 22.2803 23.2803C22.421 23.1397 22.5 22.9489 22.5 22.75V11.47C22.5001 11.3578 22.475 11.2469 22.4265 11.1457C22.378 11.0444 22.3075 10.9553 22.22 10.885L14.47 4.667L14.469 4.666Z" fill="currentColor"/>
	</Icon>
)


export const HouseString=`<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.592 3.496C12.9913 3.17561 13.488 3.001 14 3.001C14.512 3.001 15.0087 3.17561 15.408 3.496L23.158 9.714C23.4209 9.92493 23.6331 10.1922 23.7788 10.4962C23.9246 10.8001 24.0001 11.1329 24 11.47V22.75C24 23.3467 23.7629 23.919 23.341 24.341C22.919 24.7629 22.3467 25 21.75 25H18.75C18.1533 25 17.581 24.7629 17.159 24.341C16.7371 23.919 16.5 23.3467 16.5 22.75V16.75C16.5 16.5511 16.421 16.3603 16.2803 16.2197C16.1397 16.079 15.9489 16 15.75 16H12.25C12.0511 16 11.8603 16.079 11.7197 16.2197C11.579 16.3603 11.5 16.5511 11.5 16.75V22.75C11.5 23.3467 11.2629 23.919 10.841 24.341C10.419 24.7629 9.84674 25 9.25 25H6.25C5.65326 25 5.08097 24.7629 4.65901 24.341C4.23705 23.919 4 23.3467 4 22.75V11.47C4 10.788 4.31 10.142 4.842 9.715L12.592 3.497V3.496ZM14.469 4.666C14.3359 4.55937 14.1705 4.50127 14 4.50127C13.8295 4.50127 13.6641 4.55937 13.531 4.666L5.781 10.884C5.69334 10.9542 5.62258 11.0433 5.57395 11.1445C5.52531 11.2458 5.50004 11.3567 5.5 11.469V22.749C5.5 23.164 5.836 23.499 6.25 23.499H9.25C9.44891 23.499 9.63968 23.42 9.78033 23.2793C9.92098 23.1387 10 22.9479 10 22.749V16.749C10 16.1523 10.2371 15.58 10.659 15.158C11.081 14.7361 11.6533 14.499 12.25 14.499H15.75C16.0456 14.499 16.3382 14.5572 16.6113 14.6704C16.8843 14.7835 17.1324 14.9493 17.3413 15.1584C17.5503 15.3674 17.716 15.6156 17.829 15.8887C17.942 16.1618 18.0001 16.4544 18 16.75V22.75C18 23.165 18.336 23.5 18.75 23.5H21.75C21.9489 23.5 22.1397 23.421 22.2803 23.2803C22.421 23.1397 22.5 22.9489 22.5 22.75V11.47C22.5001 11.3578 22.475 11.2469 22.4265 11.1457C22.378 11.0444 22.3075 10.9553 22.22 10.885L14.47 4.667L14.469 4.666Z" fill="#EE7400"/>
</svg>
`;