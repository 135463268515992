import {
  Box,
  BoxProps,
  StyleProps,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
} from '@chakra-ui/react'

interface FormSectionProps {
  children: React.ReactNode
  variant?: 'default'
}

interface FormSectionTitleProps extends BoxProps {
  children: React.ReactNode
}

export const FormSection = (props: FormSectionProps & StyleProps) => {
  const { variant, children, ...rest } = props

  const styles = useMultiStyleConfig('FormSection', { variant })

  return (
    <Box as="section" __css={styles.wrapper} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
}

export const FormSectionHeader = (props: FormSectionTitleProps) => {
  const styles = useStyles()

  return <Box as="header" __css={styles.header} {...props} />
}

export const FormSectionTitle = (props: FormSectionTitleProps) => {
  const styles = useStyles()

  return <Box as="h2" __css={styles.title} {...props} />
}

export const FormSectionDescription = (props: FormSectionTitleProps) => {
  const styles = useStyles()

  return <Box as="p" __css={styles.description} {...props} />
}

export const FormSectionBody = (props: FormSectionTitleProps) => {
  const styles = useStyles()
  
  return <Box as="main" __css={styles.body} {...props} />
}
