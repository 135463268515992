import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { Box, IconButton, Tooltip } from '@chakra-ui/react'

import roofUnselected from '../assets/images/roof-unselected.svg'
import roofSelected from '../assets/images/roof-selected.svg'
import floorUnselected from '../assets/images/floor-unselected.svg'
import { Add, CheckCircle, QuestionMark } from '../icons'

const Roof = ({
  selected = false,
  onClick,
}: {
  selected?: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '15rem',
        height: '2.75rem',
        gap: 2,
        pb: 1.5,
        placeContent: 'center',
        placeItems: 'end',
        bgImage: selected ? roofSelected : roofUnselected,
        bgSize: 'contain',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {!selected && <Add />}
      {selected && <CheckCircle />}
      podkroví
    </Box>
  )
}

const Floor = ({
  children,
  selected = false,
  number,
  onClick,
}: {
  children?: React.ReactNode
  selected?: boolean
  number?: number
  onClick: Function
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '15rem',
        height: '2.75rem',
        gap: 2,
        placeContent: 'center',
        placeItems: 'center',
        bgColor: 'white',
        bgImage: selected ? 'none' : floorUnselected,
        borderRadius: 'md',
        border: selected ? '1px solid' : 'none',
        borderColor: selected ? 'green.500' : 'transparent',
        bgSize: 'contain',
        cursor: 'pointer',
      }}
      onClick={() => onClick(selected)}
    >
      {!selected && <Add />}
      {selected && <CheckCircle />}
      {!children && `${number}. patro`}
      {children && children}
    </Box>
  )
}

export const HouseFloorSelector = ({
  floors = 1,
  basement = false,
  onFloorsChange,
  onBasementChange,
}: {
  floors: number
  basement: boolean
  onFloorsChange: Function
  onBasementChange: Function
}) => {
  const [roofSelected, setRoofSelected] = useState(false)
  const [floorsSelected, setFloorsSelected] = useState<number>(floors)
  const [basementSelected, setBasementSelected] = useState(basement)
  const [, setForceUpdate] = useState(Date.now())
  let floorsArray = useRef(Array.from(Array(floorsSelected), (_, i) => i + 1))

  const handleRoofClick = () => {
    setRoofSelected(!roofSelected)
  }

  const handleFloorClick = (isSelected: boolean) => {
    let newFloorsSelected

    if (isSelected) {
      newFloorsSelected = floorsSelected - 1
    } else {
      newFloorsSelected = floorsSelected + 1
    }

    setFloorsSelected(newFloorsSelected)
    onFloorsChange(newFloorsSelected)
  }

  const handleBasementClick = (selected: boolean) => {
    onBasementChange(!selected)
    setBasementSelected(!selected)
  }

  useEffect(() => {
    floorsArray.current = Array.from(Array(floorsSelected), (_, i) => i + 1)
    setForceUpdate(Date.now())
  }, [floorsSelected])

  useEffect(() => {
    setFloorsSelected(floors)
    setForceUpdate(Date.now())
  }, [floors])

  useEffect(() => {
    setBasementSelected(basement)
    setForceUpdate(Date.now())
  }, [basement])

  return (
    <Box sx={{ display: 'grid', gap: 2, placeItems: 'center' }}>
      <Roof selected={roofSelected} onClick={handleRoofClick} />

      <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column-reverse' }}>
        {floorsArray.current.length > 0 &&
          floorsArray.current.map((floor) => {
            return <Floor key={floor} number={floor} selected onClick={handleFloorClick} />
          })}

        <Floor key={floorsSelected + 1} number={floorsSelected + 1} onClick={handleFloorClick} />
      </Box>

      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          gap: 2,
          pt: 2,
          pb: 4,
          px: 8,
          bg: 'gray.50',
          borderTop: '1px solid',
          borderTopColor: 'gray.300',
        }}
      >
        <Floor selected={basementSelected} onClick={handleBasementClick}>
          sklep
        </Floor>

        <Tooltip label="Nápověda ke sklepu">
          <IconButton aria-label="">
            <QuestionMark />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}
