import React, { useState } from 'react'
import { Badge, Box, Button, Flex, Heading, Image, Switch, Text } from '@chakra-ui/react'

import { logos } from '../../utils/companiesLogos'
import { Duplicate, Refresh } from '../../icons'
import { Stars } from '../Stars'
import { OfferProps } from './OfferCar'

const OfferHeaderButtons = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ display: 'flex', gap: { base: 1, md: 2 } }}>{children}</Box>
}

const OfferHeaderButtonLabel = ({ children }: { children: React.ReactNode }) => {
  return <Text sx={{ display: { base: 'none', md: 'inline' } }}>{children}</Text>
}

interface OfferHeaderProps {
  displayId: string
  company: OfferProps['company']
  status: string
  color: string
}

export const OfferHeader = ({ displayId, company, status, color }: OfferHeaderProps) => {
  const [isRecalculating, setIsRecalculating] = useState(false)

  return (
    <Box
      sx={{
        display: { base: 'grid', sm: 'flex' },
        alignContent: { base: 'stretch', sm: 'center' },
        justifyContent: { base: 'strecth', sm: 'space-between' },
        columnGap: 4,
        rowGap: 2,
        py: 2,
        px: 4,
        bgColor: color,
        borderBottom: color === 'white' ? '1px solid' : 'none',
        borderBottomColor: 'gray.200',
        borderTopRadius: 'md',
      }}
      as="header"
    >
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          columnGap: { base: 4, lg: 8 },
        }}
      >
        <Flex sx={{ alignItems: 'center', columnGap: { base: 4, lg: 8 } }}>
          <Image
            src={logos[company.id]}
            alt={company.name}
            width="64px"
            height="48px"
            style={{
              mixBlendMode: 'multiply',
              pointerEvents: 'none',
            }}
          />

          <Heading as="h2" sx={{ fontSize: { base: 'sm', lg: 'md' } }}>
            {company.name}
          </Heading>
        </Flex>

        {status !== 'error' && <Stars />}

        {status === 'error' && <Badge colorScheme="red">Chyba kalkulace</Badge>}

        {status === 'selected' && <Badge colorScheme="green">Vybraná nabídka</Badge>}
      </Flex>

      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', gap: { base: 2, lg: 8 } }}>
        <OfferHeaderButtons>
          <Button variant="outline" size="sm">
            <Duplicate color="secondary.500" />
            <OfferHeaderButtonLabel>Klonovat</OfferHeaderButtonLabel>
          </Button>
          <Button
            variant="outline"
            size="sm"
            isDisabled={isRecalculating}
            isLoading={isRecalculating}
            loadingText="Přepočítávám..."
            onClick={() => {
              setIsRecalculating(true)

              setTimeout(() => setIsRecalculating(false), 1000)
            }}
          >
            <Refresh color="secondary.500" />
            <OfferHeaderButtonLabel>Přepočítat</OfferHeaderButtonLabel>
          </Button>
          <Button variant="outline" size="sm">
            XML
          </Button>
        </OfferHeaderButtons>

        <Flex sx={{ gap: { base: 4, lg: 8 } }}>
          <Text sx={{ fontWeight: 'semibold', justifySelf: 'end' }} as="p">
            {displayId}
          </Text>

          <Switch id={`offer-toggle-${displayId}`} size="md" />
        </Flex>
      </Flex>
    </Box>
  )
}
