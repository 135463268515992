import queryString from 'query-string'
import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IOrganizationItem } from '../../interfaces/entities/IOrganization'

export const useGetOrganizationsPagination = (
  page: number | undefined,
  limit: number,
  unreadOnly?: number,
  search?: string,
  parentId?: string,
  organizationType?: string
) => {
  const query = queryString.stringify(
    {
      page,
      limit,
      isActive: unreadOnly,
      search,
      organizationId: parentId,
      organizationType
    },
    {
      skipEmptyString: true,
    },
  )
  const organizationPagination: string = `${ApiRouteEnum.ORGANIZATION}?${query}`
  return useQuery(
    [ApiRouteEnum.ORGANIZATION, query],
    () => api.get<IOrganizationItem>(organizationPagination),
    {
      retry: false,
    },
  )
}
