import { InsuranceAssistance, InsuranceAssistanceExtra, InsuranceFlood } from '../icons'

export const enum ExtraInsuranceSmeItemIconsEnum {
  Assistance = 'assistance',
  AssistanceExtra = 'assistanceExtra',
  Flood = 'flood',
}

export const getExtraInsuranceSmeIcon = (
  icon: ExtraInsuranceSmeItemIconsEnum,
  size: string = '48px',
  color: string = 'gray.800',
) => {
  switch (icon) {
    case 'assistance':
      return <InsuranceAssistance width={size} height={size} color={color} />
    case 'assistanceExtra':
      return <InsuranceAssistanceExtra width={size} height={size} color={color} />
    case 'flood':
      return <InsuranceFlood width={size} height={size} color={color} />
  }
}
