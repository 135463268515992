import { Box, useToast } from '@chakra-ui/react'

import { useEffect, useState } from 'react'
import { Loading } from '../../components/Loading'

import { useGetFAQ } from '../../model/api/faq/useGetFAQ'
import { usePostFAQ } from '../../model/api/faq/usePostFAQ'
import { FAQAdminItem } from './FAQAdminItem'
import { IFAQ, IFAQItem } from '../../model/interfaces/entities/IFAQ'
import { usePatchFAQId } from '../../model/api/faq/usePatchFAQId'

export const FAQAdminList = () => {
  const toastMessage = useToast()
  const { data, isLoading, isError, error } = useGetFAQ()
  const [newItemKey, setNewItemKey] = useState(0)

  const successCallBackPost: (data: IFAQItem) => void = (data: IFAQItem) => {
    toastMessage({
      title: 'Nová položka byla úspěšně vytvořena',
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
    setNewItemKey(newItemKey + 1)
  }

  const successCallBackPatch: (data: IFAQItem) => void = (data: IFAQItem) => {}
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Došlo k chybě',
      description: error,
      status: 'error',
      isClosable: true,
    })
  }

  const { mutate } = usePostFAQ(successCallBackPost, errorCallBack)
  const onSubmitFormPost: (formValues: IFAQ) => void = (formValues: IFAQ) => {
    mutate(formValues)
  }
  const { mutate: mutatePatch } = usePatchFAQId(successCallBackPatch, errorCallBack)
  const onSubmitFormPatch: (formValues: IFAQItem, id: number) => void = (
    formValues: IFAQItem,
    id: number,
  ) => {
    mutatePatch({ faq: formValues, id })
  }

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box sx={{ display: 'grid', gap: 4 }}>
          {data?.data.items &&
            data?.data.items.length > 0 &&
            data?.data.items.map((item) => {
              return (
                <FAQAdminItem
                  key={item.id}
                  item={item}
                  onSubmitForm={(formValues: IFAQItem) => onSubmitFormPatch(formValues, item.id)}
                />
              )
            })}

          <FAQAdminItem key={newItemKey} onSubmitForm={onSubmitFormPost} />
        </Box>
      )}
    </>
  )
}
