import { mode } from '@chakra-ui/theme-tools'

const variantDefault = (props: any) => {
  const { colorScheme: c } = props

  return {
    tabpanels: {
      bg: 'white',
      boxShadow: 'sm',
    },
    tablist: {
      pt: 0,
    },
    tab: {
      px: [2, 3, 4],
      py: [2, 3, 4],
      fontSize: { base: 'xs', sm: 'sm' },
      color: mode('gray.600', 'inherit')(props),
      borderBottomRadius: 'md',
      _selected: {
        bg: mode(`#fff`, 'gray.800')(props),
        boxShadow: 'sm',
        color: mode(`${c}.600`, `${c}.300`)(props),
        fontWeight: 'medium',
      },
    },
  }
}

export const Tabs = {
  variants: {
    default: variantDefault,
  },
}
