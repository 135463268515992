import { Button, useToast } from '@chakra-ui/react'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { MainContent } from '../../components/MainContent'
import { FormButtons } from '../FormButtons'
import { Duplicate, Save } from '../../icons'
import { ExtraInsuranceSme } from '../ExtraInsuranceSme'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { usePostStep2 } from '../../model/api/insuranceForm/usePostStep2'
import { submitHandler, validateAndScroll } from '../../model/utils/formHelpers'
import { FormSubmitEnum } from '../../model/enums/FormSubmitEnum'
import { usePatchStep2 } from '../../model/api/insuranceForm/usePatchStep2'
import { ValuesWithSubmitter } from '../../model/types/ValuesWithSubmitter'
import { useCustomCopyOffer } from '../../model/hooks/useCustomCopyOffer'
import useCloneClickHandler from '../../model/hooks/useCloneClickHandler'
import { compareObjects } from '../../utils/object'
import React from 'react'

interface Step2FormProps {
  viewOnly: boolean
  onSubmit: Function
  initialValues: Step2FormValues
  currentValidStep: number
}

export interface Step2FormValues {
  assistance: string
  flood: boolean
}

const validationSchema = Yup.object({
  flood: Yup.boolean(),
})

export const Step2Form = ({ viewOnly, onSubmit, initialValues, currentValidStep }: Step2FormProps) => {
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  const toastMessage = useToast()

  const successCallback = () => {
    onSubmit()
  }

  const { mutateCopyOffer, isCopyingOffer } = useCustomCopyOffer(id)
  const handleCloneClick = useCloneClickHandler(mutateCopyOffer)

  const successPatchCallback = () => {
    toastMessage({
      title: 'Uloženo',
      description: 'Změny byly uloženy',
      status: 'success',
      isClosable: true,
      position: 'top',
    })
  }

  const errorCallback = (error: any) => {
    const errorMessage = error?.response?.data?.error || 'Nepodařilo se uložit'

    toastMessage({
      title: 'Chyba',
      description: errorMessage,
      status: 'error',
      isClosable: true,
      position: 'top',
    })
  }

  const { mutate, isLoading } = usePostStep2(successCallback, errorCallback, id)
  const { mutate: patchMutate, isLoading: patchIsLoading } = usePatchStep2(
    successPatchCallback,
    errorCallback,
    id,
  )

  const submitting: boolean = isLoading || patchIsLoading

  const onSubmitForm = (values: ValuesWithSubmitter<Step2FormValues>) => {
    const submitter = values[FormSubmitEnum.SUBMITTER_FIELD]
    delete values[FormSubmitEnum.SUBMITTER_FIELD]
    const body: Partial<Step2FormValues> = { assistance: values.assistance, flood: values.flood }
    if (submitter === FormSubmitEnum.SUBMIT_AND_SAVE) {
      return patchMutate({
        additionalInsurance: {
          ...body,
          assistance: values.assistance,
        },
      })
    }

    mutate({
      additionalInsurance: {
        ...body,
        assistance: values.assistance,
      },
    })
  }

  return (
    <Formik
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      enableReinitialize={false}
      validationSchema={validationSchema}
    >
      {({ errors, touched, handleChange, values, validateForm, handleSubmit, setFieldValue }) => {
        const changes = compareObjects(initialValues, values)
          .filter((diff: any) => !['applicant.addressSameAsPlace'].includes(diff.path));

        // @ts-ignore
        const canNextWithoutSave = changes.length === 0 && currentValidStep >= 2;

        return(
          <form onSubmit={(e) => submitHandler(e, handleSubmit, setFieldValue)}>
            <MainContent>
              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Připojištění</FormSectionTitle>

                  <FormSectionDescription>
                    Vyberte limity zvoleného připojišťění.
                  </FormSectionDescription>
                </FormSectionHeader>

                <FormSectionBody>
                  <ExtraInsuranceSme
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={{ ...values, assistance: values.assistance || '' }}
                    touched={touched}
                    errors={errors}
                  />
                </FormSectionBody>
              </FormSection>

              <FormButtons
                leftButtons={
                  <Button
                    isLoading={isCopyingOffer}
                    variant="outline"
                    size="sm"
                    type="button"
                    onMouseDown={handleCloneClick}
                  >
                    <Duplicate color="secondary.500" />
                    Klonovat
                  </Button>
                }
                rightButtons={
                  !viewOnly ? (
                    <>
                      {!canNextWithoutSave && (
                        <Button
                          variant="outline"
                          disabled={submitting}
                          type="submit"
                          name={FormSubmitEnum.SUBMIT_AND_SAVE}
                          isLoading={submitting}
                        >
                          Uložit
                        </Button>
                      )}
                      {canNextWithoutSave ? (
                        <Button
                          colorScheme="primary"
                          type="submit"
                          onClick={() => navigate(`/sme/wizard/edit/${id}/3`)}
                        >
                          Pokračovat
                        </Button>
                      ) : (
                        <Button
                          colorScheme="primary"
                          isLoading={submitting}
                          isDisabled={submitting}
                          loadingText={isLoading ? 'Počítám nabídky...' : undefined}
                          type="submit"
                          name={FormSubmitEnum.SUBMIT_AND_CONTINUE}
                          onClick={() => {
                            validateAndScroll(values, validateForm, toastMessage)
                          }}
                        >
                          <Save />
                          Uložit a spočítat nabídky
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      colorScheme="primary"
                      onClick={() => navigate(`/sme/wizard/view/${id}/3`)}
                    >
                      Pokračovat
                    </Button>
                  )
                }
              />
            </MainContent>
          </form>
        );
      }}
    </Formik>
  )
}
