import { Box, Flex, FormControl } from '@chakra-ui/react'
import { FormLabel } from '../../../../components/FormLabel'
import { SaveAndRecalculateButton } from '../../../SaveAndRecalculateButton'
import { YearlyMilageSelect } from '../../../YearlyMilageSelect'

export const YearlyMilageTab = () => {
  return (
    <Box>
      <FormControl>
        <FormLabel>Vyberte nájezd kilometrů za rok</FormLabel>

        <Flex sx={{ flexWrap: 'wrap', gap: 4, justifyContent: 'start' }}>
          <YearlyMilageSelect />

          <SaveAndRecalculateButton />
        </Flex>
      </FormControl>
    </Box>
  )
}
