import { useCallback, useState } from 'react'
import { useUpdateInstallment } from '../../../model/api/offers/useUpdateInstallment'
import { IBodyCell } from './columnSections'
import {
  Box,
  BoxProps,
  Checkbox,
  forwardRef,
  Select,
  SelectProps,
  Skeleton,
  Text,
  useBoolean,
} from '@chakra-ui/react'

const formatPrice = ({ price, currency = 'CZK' }: { price: number; currency?: string }) => {
  return Intl.NumberFormat('cs-CZ', {
    style: 'currency',
    maximumFractionDigits: 0,
    currency: currency,
  }).format(price)
}

const OfferCellWrapper = ({ children, sx, ...rest }: BoxProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gap: 1,
        px: 2,
        py: 2,
        lineHeight: '20px',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

interface TinySelectProps extends SelectProps {}

const TinySelect = forwardRef((props: TinySelectProps, ref) => {
  return (
    <Select
      ref={ref}
      size="xs"
      sx={{ maxH: 5, m: 0, py: 0.5 }}
      rootProps={{
        sx: {
          maxH: 5,
        },
      }}
      {...props}
    />
  )
})

const paymentFrequencyOptions = [
  { value: 'quarter', label: 'Čtvrtletní' },
  { value: 'half_year', label: 'Pololetní' },
  { value: 'year', label: 'Roční' },
]

export interface IOfferCellPaymentProps extends Omit<IBodyCell, 'value'> {
  value: number
}

export const OfferInstallmentCell = ({
  id,
  offerId,
  itemId,
  value,
  isEditable = false,
  paymentFrequency,
  viewOnly,
}: IBodyCell) => {
  const [isEditing, setEditing] = useBoolean(false)
  const [frequency, setFrequency] = useState(paymentFrequency)
  const updateInstallment = useUpdateInstallment(offerId, itemId)

  const handleOnEdit = (value: string) => {
    setFrequency(value)
    setEditing.on()
    setTimeout(() => {
      setEditing.off()
    }, 1000)
  }

  const handleUpdateInstallment = useCallback(
    async (frequency: any) => {
      try {
        await updateInstallment?.mutateAsync({
          frequency,
        })
      } catch (error) {
        console.log(error)
      }
    },
    [updateInstallment],
  )

  return (
    <OfferCellWrapper
      key={id}
      sx={{ alignItems: 'center', justifyContent: 'space-between', justifyItems: 'end' }}
    >
      <TinySelect
        isDisabled={!isEditable || isEditing || !!viewOnly}
        onChange={(e) => {
          handleOnEdit(e.target.value)
          handleUpdateInstallment(e.target.value)
        }}
        value={paymentFrequency || undefined}
      >
        {paymentFrequencyOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TinySelect>
      <>
        {typeof value === 'number' && (
          <Skeleton isLoaded={!isEditing} sx={{ display: 'flex' }}>
            <Text sx={{ fontSize: 'sm', px: 2 }}>
              {formatPrice({
                price: value,
                currency: 'CZK',
              })}
            </Text>
            <Checkbox size="sm" visibility="hidden" />
          </Skeleton>
        )}
      </>
    </OfferCellWrapper>
  )
}
