import { ChakraProvider } from '@chakra-ui/react'
import '@fontsource/inter/variable.css'

import { theme } from './theme'
import { Router } from './Router'
import { ScrollToTop } from './components/ScrollToTop'

export const App = () => (
  <ChakraProvider theme={theme}>
    <ScrollToTop />
    <Router />
  </ChakraProvider>
)
