import { brokerNumbers, IBrokerNumber } from './profile'

export interface IOrganizationContact {
  person: string
  city?: string
  email: string
  phone: string
}

export interface IOrganization {
  id: number
  name: string
  businessId: string
  parentOrganization?: string
  contact: IOrganizationContact
  address: string
  description?: string
  active: boolean
  type: string
  businessNumbers?: Array<IBrokerNumber>
  logo?: string
}

export const enum organizationTypeLabel {
  branch = 'Pobočka',
  partner = 'Partner',
  ppz = 'PPZ',
}

export const organizations: Array<IOrganization> = [
  {
    id: 1,
    name: 'Autosalon',
    businessId: '123456789',
    contact: {
      person: 'Jan Svěrák',
      city: 'Praha',
      email: 'jana.novakova@okgroup.cz',
      phone: '+420 777 323 434',
    },
    address: 'Hlavní třída 165/2, 708 00 Ostrava-Poruba',
    description: 'Testovací organizace Allianz za účelem otestování funkcionality',
    active: false,
    type: organizationTypeLabel.branch,
    businessNumbers: brokerNumbers,
  },
  {
    id: 2,
    name: 'Euroagency',
    businessId: '123456789',
    contact: {
      person: 'Jan Světlík',
      city: 'Ostrava',
      email: 'jana.novakova@okgroup.cz',
      phone: '+420 777 323 434',
    },
    address: 'Hlavní třída 165/2, 708 00 Ostrava-Poruba',
    description: 'Organizace DOV',
    active: true,
    type: organizationTypeLabel.partner,
  },
  {
    id: 3,
    name: 'OK Group',
    businessId: '123456789',
    parentOrganization: 'OK Holding',
    contact: {
      person: 'Roman Šebrle',
      city: 'Praha',
      email: 'jana.novakova@okgroup.cz',
      phone: '+420 777 323 434',
    },
    address: 'Hlavní třída 165/2, 708 00 Ostrava-Poruba',
    description: 'ČOV',
    active: true,
    type: organizationTypeLabel.partner,
  },
]
