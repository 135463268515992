import moment, { Moment } from 'moment'

export const CZECH_SHORT_DATE_FORMAT = 'D. M. YYYY'
export const dateToCzechShortFormat = (date: string | Moment): string => {
  const originalDate: Moment = moment(date)

  if (!originalDate.isValid()) {
    console.error('Invalid date', date)
    return ''
  }

  return originalDate.format(CZECH_SHORT_DATE_FORMAT)
}
