import { forwardRef, Select, SelectProps } from '@chakra-ui/react'

export const YearlyMilageSelect = forwardRef((props: SelectProps, ref) => {
  return (
    <Select id="yearlyMilage" ref={ref} {...props}>
      <option disabled value="">
        Vyberte roční nájezd
      </option>
      {Array.from(Array(10).keys()).map((i) => {
        const value = (i + 1) * 10000
        const label = Intl.NumberFormat('cs-CZ').format(value)
        return <option key={i} value={value}>{`${label} km`}</option>
      })}
    </Select>
  )
})
