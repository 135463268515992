import React from 'react'
import { Box } from '@chakra-ui/react'

import { Card, CardBody } from '../../components/Card'

export const SearchWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Card>
      <CardBody>
        <Box
          sx={{
            display: 'grid',
            columnGap: { base: 4, xl: 8 },
            rowGap: { base: 4, xl: 4 },
            gridTemplateColumns: { base: '1fr', md: 'repeat(4, 1fr)' },
          }}
        >
          {children}
        </Box>
      </CardBody>
    </Card>
  )
}
