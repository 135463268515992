import { Icon } from '@chakra-ui/react'

export const Craft = (props: any) => (
  <Icon boxSize={props.boxSize || '20px'} {...props} viewBox="0 0 20 20" fill="none">
    <path
      d="M6 4.5V6H4C3.46957 6 2.96086 6.21071 2.58579 6.58579C2.21071 6.96086 2 7.46957 2 8V15C2 15.5304 2.21071 16.0391 2.58579 16.4142C2.96086 16.7893 3.46957 17 4 17H16.003C16.5334 17 17.0421 16.7893 17.4172 16.4142C17.7923 16.0391 18.003 15.5304 18.003 15V8C18.003 7.46957 17.7923 6.96086 17.4172 6.58579C17.0421 6.21071 16.5334 6 16.003 6H14V4.5C14 4.10218 13.842 3.72064 13.5607 3.43934C13.2794 3.15804 12.8978 3 12.5 3H7.5C7.10218 3 6.72064 3.15804 6.43934 3.43934C6.15804 3.72064 6 4.10218 6 4.5ZM7.5 4H12.5C12.6326 4 12.7598 4.05268 12.8536 4.14645C12.9473 4.24021 13 4.36739 13 4.5V6H7V4.5C7 4.36739 7.05268 4.24021 7.14645 4.14645C7.24021 4.05268 7.36739 4 7.5 4ZM4 7H16.003C16.2682 7 16.5226 7.10536 16.7101 7.29289C16.8976 7.48043 17.003 7.73478 17.003 8V10H14V9.5C14 9.36739 13.9473 9.24021 13.8536 9.14645C13.7598 9.05268 13.6326 9 13.5 9C13.3674 9 13.2402 9.05268 13.1464 9.14645C13.0527 9.24021 13 9.36739 13 9.5V10H7V9.5C7 9.36739 6.94732 9.24021 6.85355 9.14645C6.75979 9.05268 6.63261 9 6.5 9C6.36739 9 6.24021 9.05268 6.14645 9.14645C6.05268 9.24021 6 9.36739 6 9.5V10H3V8C3 7.73478 3.10536 7.48043 3.29289 7.29289C3.48043 7.10536 3.73478 7 4 7ZM13 11V11.5C13 11.6326 13.0527 11.7598 13.1464 11.8536C13.2402 11.9473 13.3674 12 13.5 12C13.6326 12 13.7598 11.9473 13.8536 11.8536C13.9473 11.7598 14 11.6326 14 11.5V11H17.003V15C17.003 15.2652 16.8976 15.5196 16.7101 15.7071C16.5226 15.8946 16.2682 16 16.003 16H4C3.73478 16 3.48043 15.8946 3.29289 15.7071C3.10536 15.5196 3 15.2652 3 15V11H6V11.5C6 11.6326 6.05268 11.7598 6.14645 11.8536C6.24021 11.9473 6.36739 12 6.5 12C6.63261 12 6.75979 11.9473 6.85355 11.8536C6.94732 11.7598 7 11.6326 7 11.5V11H13Z"
      fill="currentColor"
    />
  </Icon>
)

export const CraftString = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 4.5V6H4C3.46957 6 2.96086 6.21071 2.58579 6.58579C2.21071 6.96086 2 7.46957 2 8V15C2 15.5304 2.21071 16.0391 2.58579 16.4142C2.96086 16.7893 3.46957 17 4 17H16.003C16.5334 17 17.0421 16.7893 17.4172 16.4142C17.7923 16.0391 18.003 15.5304 18.003 15V8C18.003 7.46957 17.7923 6.96086 17.4172 6.58579C17.0421 6.21071 16.5334 6 16.003 6H14V4.5C14 4.10218 13.842 3.72064 13.5607 3.43934C13.2794 3.15804 12.8978 3 12.5 3H7.5C7.10218 3 6.72064 3.15804 6.43934 3.43934C6.15804 3.72064 6 4.10218 6 4.5ZM7.5 4H12.5C12.6326 4 12.7598 4.05268 12.8536 4.14645C12.9473 4.24021 13 4.36739 13 4.5V6H7V4.5C7 4.36739 7.05268 4.24021 7.14645 4.14645C7.24021 4.05268 7.36739 4 7.5 4ZM4 7H16.003C16.2682 7 16.5226 7.10536 16.7101 7.29289C16.8976 7.48043 17.003 7.73478 17.003 8V10H14V9.5C14 9.36739 13.9473 9.24021 13.8536 9.14645C13.7598 9.05268 13.6326 9 13.5 9C13.3674 9 13.2402 9.05268 13.1464 9.14645C13.0527 9.24021 13 9.36739 13 9.5V10H7V9.5C7 9.36739 6.94732 9.24021 6.85355 9.14645C6.75979 9.05268 6.63261 9 6.5 9C6.36739 9 6.24021 9.05268 6.14645 9.14645C6.05268 9.24021 6 9.36739 6 9.5V10H3V8C3 7.73478 3.10536 7.48043 3.29289 7.29289C3.48043 7.10536 3.73478 7 4 7ZM13 11V11.5C13 11.6326 13.0527 11.7598 13.1464 11.8536C13.2402 11.9473 13.3674 12 13.5 12C13.6326 12 13.7598 11.9473 13.8536 11.8536C13.9473 11.7598 14 11.6326 14 11.5V11H17.003V15C17.003 15.2652 16.8976 15.5196 16.7101 15.7071C16.5226 15.8946 16.2682 16 16.003 16H4C3.73478 16 3.48043 15.8946 3.29289 15.7071C3.10536 15.5196 3 15.2652 3 15V11H6V11.5C6 11.6326 6.05268 11.7598 6.14645 11.8536C6.24021 11.9473 6.36739 12 6.5 12C6.63261 12 6.75979 11.9473 6.85355 11.8536C6.94732 11.7598 7 11.6326 7 11.5V11H13Z" fill="#EE7400"/>
</svg>
`
