import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import Api from '../api'
import queryString from 'query-string'
import { IAddressSuggestion } from '../../interfaces/entities/IAddressSuggestion'

export const useGetAddress = (searchedAddress: string) => {
  const query = queryString.stringify({
    query: searchedAddress,
    limit: 10,
  })
  const url = `${ApiRouteEnum.ADDRESS}?${query}`
  return useQuery([ApiRouteEnum.ADDRESS, query], () => Api.get<IAddressSuggestion[]>(url))
}
