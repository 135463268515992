import { Badge, Box, Link, Text, useBoolean } from '@chakra-ui/react'
import { Alert } from '../icons'
import { Container } from './Container'

export interface AppAlertProps {
  title?: string
  badgeLabel?: string
  linkHref?: string
  linkLabel?: string
}

export const AppAlert = ({ title, badgeLabel, linkHref, linkLabel }: AppAlertProps) => {
  const [isOpen, setIsOpen] = useBoolean(true)

  if (!isOpen) {
    return null
  }

  return (
    <Box
      sx={{
        bg: 'orange.100',
        borderBottom: '1px solid',
        borderBottomColor: 'orange.200',
        cursor: 'pointer',
      }}
      onClick={setIsOpen.off}
    >
      <Container>
        <Box
          sx={{
            gap: 2,
            py: 2,
            display: 'grid',
            gridAutoFlow: 'column dense',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'start',
            justifyItems: 'start',
          }}
        >
          <Alert color="secondary.500" />

          {title && <Text sx={{ fontWeight: 'medium', whiteSpace: 'nowrap' }}>{title}</Text>}

          <Badge colorScheme="orange" sx={{ display: { base: 'none', xl: 'initial' } }}>
            {badgeLabel}
          </Badge>

          <Text
            sx={{
              display: 'inline-block',
              maxW: '100%',
              fontSize: 'sm',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {linkHref && linkLabel && (
              <Link
                sx={{
                  textDecoration: 'underline',
                }}
                href={linkHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkLabel}
              </Link>
            )}
          </Text>
        </Box>
      </Container>
    </Box>
  )
}
