import { Box, BoxProps, Checkbox, Text, Tooltip, useBoolean, useToast } from '@chakra-ui/react'
import React, { MouseEventHandler, useContext } from 'react'

import {
  Alert,
  CheckCircle,
  ChevronRight,
  Group,
  InsuranceBuildingResp,
  InsuranceDoors,
  InsurancePersonal,
  StepsIconHouse,
} from '../../../icons'
import { EditablePrice } from '../../EditablePrice'
import { IBodyCell, IHeaderCell } from './columnSections'
import { IItem, ISubLimit } from '../../../model/interfaces/entities/IItem'
import { usePatchStep3 } from '../../../model/api/insuranceForm/usePatchStep3'
import { useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import { FormIsDisabledContext } from '../../../model/contexts/FormIsDisabledContext'
import { NumberInputDecorator } from '../../../model/utils/numberInputUtils'
import { EditableSelect } from '../../EditableSelect'
import { useUpdateDiscount } from '../../../model/api/offers/useUpdateDiscount'

const icons: { [index: string]: any } = {
  household: <InsuranceDoors color="secondary.500" />,
  property: <StepsIconHouse color="secondary.500" />,
  common: <Group color="secondary.500" />,
  lifeLiability: <InsurancePersonal color="secondary.500" />,
  propertyLiability: <InsuranceBuildingResp color="secondary.500" />,
}

export interface IOfferPropertyColumnSection {
  subLimits?: ISubLimit[]
  itemId?: string
  items?: IItem[]
  id?: string
  title?: string
  icon?: keyof typeof icons
  isOpened?: boolean
  onClick: MouseEventHandler<HTMLDivElement>
  headerCells?: Array<IHeaderCell>
  bodyCells?: Array<IBodyCell>
  titleBg?: string
  viewOnly?: boolean
}

export const SectionTitle = ({
  label,
  icon,
  isOpened = false,
  bg,
  onClick,
}: {
  label?: React.ReactNode | string
  icon?: keyof typeof icons
  isOpened?: boolean
  bg?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}) => {
  const isExpandable = !!onClick

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        p: 2,
        bg: bg || isExpandable ? 'gray.100' : 'gray.50',
        cursor: isExpandable ? 'pointer' : 'default',
        _hover: isExpandable ? { bg: 'gray.200' } : {},
      }}
      onClick={onClick && onClick}
    >
      <Box
        sx={{
          h: 5,
          transform: isOpened ? 'rotate(90deg)' : '',
          transformOrigin: 'center center',
          visibility: isExpandable ? 'visible' : 'hidden',
        }}
      >
        <ChevronRight width={3} height={3} />
      </Box>

      {icon && icons[icon]}

      {label && (
        <Text sx={{ lineHeight: 5, fontWeight: 'medium', whiteSpace: 'nowrap' }}>{label}</Text>
      )}
    </Box>
  )
}

const SectionBody = ({
  children,
  isOpened = false,
}: {
  children?: React.ReactNode
  isOpened?: boolean
}) => {
  return isOpened ? <Box sx={{ bg: 'white' }}>{children && children}</Box> : null
}

const OfferPropertyCell = ({ children, sx, ...rest }: BoxProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gap: 1,
        px: 2,
        py: 2,
        lineHeight: '20px',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

let lastChangedObject: any = {}
export const OfferCell = ({
  id,
  offerId,
  type,
  value,
  isEditable,
  isBEChecked,
  itemId,
  textValue,
  enums,
  info,
  successHoverText,
  isDisabledCheckbox,
  suffix = undefined,
  hideCheckbox = false,
  minDiscount,
  maxDiscount,
  viewOnly,
}: IBodyCell) => {
  const [isChecked, setChecked] = useBoolean(isBEChecked)
  let children
  const toastMessage = useToast()
  const [overwrittenValue, setOverwrittenValue] = React.useState<
    string | number | true | undefined
  >(undefined)

  React.useEffect(() => {
    if (isBEChecked) {
      setChecked.on()
    } else {
      setChecked.off()
    }
  }, [isBEChecked])

  const { id: generalID } = useParams<'id'>()
  const successCallBack = (data: any) => {
    return
  }

  const errorCallBack = (error: any) => {
    const errorMessage = error?.response?.data?.error || 'Nepodařilo se uložit'

    toastMessage({
      title: 'Chyba',
      description: errorMessage,
      status: 'error',
      isClosable: true,
      position: 'top',
    })
  }

  const mutate = (data: any) => {
    if (isEqual(lastChangedObject, { ...data, id })) {
      return
    }

    lastChangedObject = { ...data, id }

    patch3mutate(data)
  }
  const { mutate: patch3mutate } = usePatchStep3(
    successCallBack,
    errorCallBack,
    generalID,
    itemId,
    id,
  )

  const formIsDisabled = useContext(FormIsDisabledContext)
  const updateDiscount = useUpdateDiscount(errorCallBack, offerId, itemId)
  let isSendingDiscountMutation = false

  if (isEditable && typeof enums !== 'undefined' && enums.length > 0) {
    children = (
      <>
        <EditableSelect
          isDisabled={!isChecked}
          defaultValue={
            overwrittenValue ||
            (typeof value === 'number' ? NumberInputDecorator.denormalize(value) : value) ||
            textValue
          }
          options={enums || []}
          onSubmit={(value) => {
            if (formIsDisabled) {
              return
            }
            mutate({ value: value })
            setOverwrittenValue(value)
          }}
          viewOnly={!!viewOnly}
        />
      </>
    )
  } else if (!!successHoverText) {
    children = (
      <Tooltip shouldWrapChildren hasArrow label={successHoverText} placement="top">
        <CheckCircle mr={4} />
      </Tooltip>
    )
  } else if (!!textValue) {
    children = (
      <Tooltip shouldWrapChildren hasArrow label={textValue} placement="top">
        <Text
          data-tip={textValue}
          fontSize="0.75rem"
          style={{
            textOverflow: 'ellipsis',
            maxWidth: '150px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingRight: '4px',
          }}
          color={isChecked || !isEditable ? 'current' : 'gray.500'}
          mr={4}
        >
          {textValue}
        </Text>
      </Tooltip>
    )
  } else {
    children = (
      <>
        <EditablePrice
          isDisabled={!isEditable || !!textValue || formIsDisabled || !isChecked}
          isRowChecked={isChecked}
          isRowEditable={isEditable}
          defaultValue={textValue || (value as string)}
          suffix={suffix}
          minDiscount={minDiscount}
          maxDiscount={maxDiscount}
          onSubmit={async (e) => {
            const valueToBeSet = textValue || (value as string)
            if (e === valueToBeSet) return
            if (hideCheckbox) {
              if (isSendingDiscountMutation) {
                return
              }
              try {
                isSendingDiscountMutation = true
                await updateDiscount?.mutateAsync({ discount: e })
              } catch (error) {
                console.log(error)
              } finally {
                isSendingDiscountMutation = false
              }
            } else {
              mutate({
                value: e,
              })
            }
          }}
        />
      </>
    )
  }

  return (
    <>
      <OfferPropertyCell key={id} sx={{ justifyContent: 'end', justifyItems: 'end' }}>
        {children}
        {typeof info === 'string' && info !== '' && (
          <Tooltip shouldWrapChildren hasArrow label={info} placement="top">
            <span style={{ display: 'inline-block', marginBottom: '1px' }}>
              <Alert boxSize="16px" color="gray.500" />
            </span>
          </Tooltip>
        )}
        <Checkbox
          size="sm"
          visibility={!isEditable || formIsDisabled || hideCheckbox ? 'hidden' : undefined}
          isChecked={isChecked}
          onChange={() => {
            setChecked.toggle()
            mutate({ isChecked: !isChecked, value: textValue || value })
          }}
          isDisabled={isDisabledCheckbox}
        />
      </OfferPropertyCell>
    </>
  )
}

export const OfferPropertyColumnSection = ({
  id,
  title,
  icon,
  isOpened = false,
  onClick,
  titleBg,
  subLimits,
  items,
  itemId,
  viewOnly,
}: IOfferPropertyColumnSection) => {
  return (
    <Box id={id} key={id}>
      <SectionTitle label={title} icon={icon} isOpened={isOpened} onClick={onClick} bg={titleBg} />
      <SectionBody isOpened={isOpened}>
        {items &&
          items[0].limits.map((generalLimit) => {
            if (generalLimit.name === title) {
              return (
                generalLimit.subLimits &&
                generalLimit.subLimits.map((generalSubLimit) => {
                  return (
                    <OfferPropertyCell key={generalSubLimit.name}>
                      <Text sx={{ pl: '24px', fontSize: 'sm', whiteSpace: 'nowrap' }}>
                        {generalSubLimit.name}
                      </Text>
                    </OfferPropertyCell>
                  )
                })
              )
            }
          })}

        {subLimits &&
          subLimits.map((subLimit, index) => {
            return (
              <Box
                key={Math.random()}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(${subLimits.length}, auto)`,
                  justifyContent: 'end',
                }}
                height="36px"
              >
                <OfferCell
                  key={subLimit.id}
                  id={subLimit.id.toString()}
                  itemId={itemId}
                  isEditable={subLimit.isEditable}
                  isBEChecked={subLimit.isChecked}
                  type="number"
                  value={subLimit.numericValue}
                  textValue={subLimit.textValue}
                  enums={subLimit.enum}
                  info={subLimit.info}
                  successHoverText={subLimit.successHoverText}
                  isDisabledCheckbox={subLimit.isDisabledCheckbox}
                  viewOnly={viewOnly}
                />
              </Box>
            )
          })}
      </SectionBody>
    </Box>
  )
}
