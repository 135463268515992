export const Section = {
  baseStyle: {
    display: 'grid',
    gap: 6,
  },
  variants: {
    default: {
      py: 8,
      pb: {
        base: 20,
        xl: 8,
      }
    },
    login: {
      py: {
        base: 8,
        md: 12,
        lg: 16,
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
}
