import { mode, Styles } from '@chakra-ui/theme-tools'

export const styles: Styles = {
  global: (props) => ({
    body: {
      bg: mode('gray.50', 'gray.800')(props),
      color: mode('primary.800', 'whiteAlpha.900')(props),
      fontFamily: 'body',
      fontSize: 'md',
      lineHeight: 'base',
      transitionProperty: 'background-color',
      transitionDuration: 'normal',
    },
    '*::placeholder': {
      color: mode('gray.500', 'whiteAlpha.400')(props),
    },
    '*, *::before, &::after': {
      borderColor: mode('gray.200', 'whiteAlpha.300')(props),
      wordWrap: 'break-word',
    },
  }),
}
