import { Box } from '@chakra-ui/react'

import { useOfferGrid } from '../../../context/OfferGridContext'
import { OfferPropertyColumnSection, SectionTitle } from './OferPropertyColumnSection'
import { IItem } from '../../../model/interfaces/entities/IItem'

export const OfferPropertyHeaderColumn = ({
  limitNames,
  items,
}: {
  limitNames: string[]
  items?: IItem[]
}) => {
  const { openedColumns, onSectionTitleClick } = useOfferGrid()

  return (
    <Box sx={{ display: 'grid', gap: 'px', alignSelf: 'end' }}>
      {limitNames &&
        limitNames.map((name, index) => {
          return (
            <OfferPropertyColumnSection
              key={index}
              id={name}
              title={name}
              items={items}
              isOpened={openedColumns.includes(name)}
              onClick={() => onSectionTitleClick(name)}
            />
          )
        })}

      <SectionTitle label="Sleva" />
      <SectionTitle label="Splátky" />
    </Box>
  )
}
