import { Icon } from '@chakra-ui/react'

export const Search = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M8.5 3C9.54533 2.99982 10.5691 3.29754 11.4513 3.85829C12.3335 4.41903 13.0376 5.21957 13.4812 6.16612C13.9248 7.11267 14.0894 8.16603 13.9559 9.2028C13.8223 10.2396 13.396 11.2168 12.727 12.02L16.854 16.146C16.9426 16.2342 16.9949 16.3524 17.0007 16.4772C17.0065 16.6021 16.9653 16.7246 16.8852 16.8206C16.8052 16.9166 16.6921 16.9791 16.5682 16.9958C16.4443 17.0126 16.3187 16.9823 16.216 16.911L16.146 16.854L12.02 12.727C11.3412 13.2921 10.5363 13.6854 9.67335 13.8736C8.81041 14.0619 7.91483 14.0395 7.06236 13.8085C6.20989 13.5774 5.42559 13.1445 4.77582 12.5462C4.12604 11.948 3.6299 11.2021 3.32935 10.3716C3.0288 9.54104 2.93269 8.65035 3.04914 7.77483C3.1656 6.89931 3.49119 6.06471 3.99838 5.34163C4.50558 4.61855 5.17945 4.02825 5.96301 3.62066C6.74657 3.21307 7.61677 3.00018 8.5 3V3ZM8.5 4C7.30653 4 6.16193 4.47411 5.31802 5.31802C4.47411 6.16193 4 7.30653 4 8.5C4 9.69347 4.47411 10.8381 5.31802 11.682C6.16193 12.5259 7.30653 13 8.5 13C9.69347 13 10.8381 12.5259 11.682 11.682C12.5259 10.8381 13 9.69347 13 8.5C13 7.30653 12.5259 6.16193 11.682 5.31802C10.8381 4.47411 9.69347 4 8.5 4Z" fill="currentColor"/>
	</Icon>
)


export const SearchString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 3C9.54533 2.99982 10.5691 3.29754 11.4513 3.85829C12.3335 4.41903 13.0376 5.21957 13.4812 6.16612C13.9248 7.11267 14.0894 8.16603 13.9559 9.2028C13.8223 10.2396 13.396 11.2168 12.727 12.02L16.854 16.146C16.9426 16.2342 16.9949 16.3524 17.0007 16.4772C17.0065 16.6021 16.9653 16.7246 16.8852 16.8206C16.8052 16.9166 16.6921 16.9791 16.5682 16.9958C16.4443 17.0126 16.3187 16.9823 16.216 16.911L16.146 16.854L12.02 12.727C11.3412 13.2921 10.5363 13.6854 9.67335 13.8736C8.81041 14.0619 7.91483 14.0395 7.06236 13.8085C6.20989 13.5774 5.42559 13.1445 4.77582 12.5462C4.12604 11.948 3.6299 11.2021 3.32935 10.3716C3.0288 9.54104 2.93269 8.65035 3.04914 7.77483C3.1656 6.89931 3.49119 6.06471 3.99838 5.34163C4.50558 4.61855 5.17945 4.02825 5.96301 3.62066C6.74657 3.21307 7.61677 3.00018 8.5 3V3ZM8.5 4C7.30653 4 6.16193 4.47411 5.31802 5.31802C4.47411 6.16193 4 7.30653 4 8.5C4 9.69347 4.47411 10.8381 5.31802 11.682C6.16193 12.5259 7.30653 13 8.5 13C9.69347 13 10.8381 12.5259 11.682 11.682C12.5259 10.8381 13 9.69347 13 8.5C13 7.30653 12.5259 6.16193 11.682 5.31802C10.8381 4.47411 9.69347 4 8.5 4Z" fill="#003D66"/>
</svg>
`;