import { Icon } from '@chakra-ui/react'

export const Clock = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2V2ZM10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C11.8565 17 13.637 16.2625 14.9497 14.9497C16.2625 13.637 17 11.8565 17 10C17 8.14348 16.2625 6.36301 14.9497 5.05025C13.637 3.7375 11.8565 3 10 3ZM9.5 5C9.61703 4.99996 9.73036 5.04097 9.82026 5.11589C9.91016 5.19081 9.97094 5.29489 9.992 5.41L10 5.5V10H12.5C12.6249 9.99977 12.7454 10.0463 12.8378 10.1305C12.9301 10.2147 12.9876 10.3304 12.9989 10.4548C13.0102 10.5793 12.9745 10.7034 12.8988 10.8028C12.8231 10.9023 12.7129 10.9697 12.59 10.992L12.5 11H9.5C9.38297 11 9.26964 10.959 9.17974 10.8841C9.08984 10.8092 9.02906 10.7051 9.008 10.59L9 10.5V5.5C9 5.36739 9.05268 5.24021 9.14645 5.14645C9.24021 5.05268 9.36739 5 9.5 5Z" fill="currentColor"/>
	</Icon>
)


export const ClockString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2V2ZM10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C11.8565 17 13.637 16.2625 14.9497 14.9497C16.2625 13.637 17 11.8565 17 10C17 8.14348 16.2625 6.36301 14.9497 5.05025C13.637 3.7375 11.8565 3 10 3ZM9.5 5C9.61703 4.99996 9.73036 5.04097 9.82026 5.11589C9.91016 5.19081 9.97094 5.29489 9.992 5.41L10 5.5V10H12.5C12.6249 9.99977 12.7454 10.0463 12.8378 10.1305C12.9301 10.2147 12.9876 10.3304 12.9989 10.4548C13.0102 10.5793 12.9745 10.7034 12.8988 10.8028C12.8231 10.9023 12.7129 10.9697 12.59 10.992L12.5 11H9.5C9.38297 11 9.26964 10.959 9.17974 10.8841C9.08984 10.8092 9.02906 10.7051 9.008 10.59L9 10.5V5.5C9 5.36739 9.05268 5.24021 9.14645 5.14645C9.24021 5.05268 9.36739 5 9.5 5Z" fill="#003D66"/>
</svg>
`;