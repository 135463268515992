import { Avatar, Box, Button, Flex, Image, Link, Td, Text, Tr, useBoolean } from '@chakra-ui/react'
import { IContractRows } from '../../model/interfaces/entities/IContractRows'
import { dateToCzechShortFormat } from '../../model/utils/dateUtils'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { prefixUrlWithBasePath } from '../../model/utils/urlUtils'
import { ContractStatusEnum } from '../../model/enums/ContractStatusEnum'

export const ContractRow = ({
  dateStart,
  dateCreated,
  dateAnniversary,
  company,
  number,
  user,
  applicant,
  insurance,
  id,
  dateCreatedContract,
  status,
}: IContractRows) => {
  const navigate = useNavigate()
  const [buttonsVisible, setButtonsVisible] = useBoolean(false)
  const anniversary = dateAnniversary ? moment(dateAnniversary).add(1, 'year').format() : undefined
  const agreementType = (() => {
    if (insurance.agreement === 'signature') return 'Sjednání podpisem'
    if (insurance.agreement === 'payment') return 'Sjednání zaplacením'
  })()

  return (
    <Tr onMouseEnter={setButtonsVisible.on} onMouseLeave={setButtonsVisible.off}>
      <Td>
        <Box className="contract__id-progress-note">
          <Box className="contract__id-progress">
            <Link
              sx={{ color: 'primary.400', fontSize: 'xl', fontWeight: 'semibold' }}
              cursor="pointer"
              href={`/sme/wizard/${id}/completed`}
              textDecoration={status === ContractStatusEnum.CANCELED ? 'line-through' : undefined}
              _hover={{
                textDecoration: status === ContractStatusEnum.CANCELED ? 'line-through' : 'none',
              }}
            >
              {number}
            </Link>
          </Box>
        </Box>
      </Td>
      <Td>
        <Text
          sx={{ fontSize: 'sm' }}
          textDecoration={status === ContractStatusEnum.CANCELED ? 'line-through' : undefined}
        >
          {id}
        </Text>
      </Td>
      <Td>
        {insurance.dateStart && (
          <Text
            sx={{ fontSize: 'sm', fontWeight: 'semibold' }}
            textDecoration={status === ContractStatusEnum.CANCELED ? 'line-through' : undefined}
          >
            {dateToCzechShortFormat(insurance.dateStart)}
          </Text>
        )}
        {dateCreatedContract && (
          <Text
            sx={{ fontSize: 'sm' }}
            textDecoration={status === ContractStatusEnum.CANCELED ? 'line-through' : undefined}
          >
            {dateToCzechShortFormat(dateCreatedContract)}
          </Text>
        )}
      </Td>

      <Td sx={{ px: 0 }}>
        {user && <Avatar size="sm" colorScheme="primary" name={applicant.companyName} />}
      </Td>

      <Td sx={{ pl: 0 }}>
        {user && (
          <Text
            sx={{ fontSize: 'sm' }}
            textDecoration={status === ContractStatusEnum.CANCELED ? 'line-through' : undefined}
          >
            {applicant.companyName}
          </Text>
        )}
      </Td>

      <Td>
        <Image
          src={prefixUrlWithBasePath(company.logo)}
          alt={company.name}
          width="64px"
          height="48px"
        />
      </Td>
      <Td>
        <Text textDecoration={status === ContractStatusEnum.CANCELED ? 'line-through' : undefined}>
          {agreementType}
        </Text>
      </Td>

      <Td>
        <Flex
          sx={{ gap: 1, placeContent: 'end', visibility: buttonsVisible ? 'visible' : 'hidden' }}
        >
          <Link href={`/sme/wizard/${id}/completed`} _hover={{textDecoration: 'none'}}>
            <Button
              variant="outline"
              size="sm"
            >
              Zobrazit
            </Button>
          </Link>
        </Flex>
      </Td>
    </Tr>
  )
}
