import { Box, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { IUser, IUserItem } from '../model/interfaces/entities/IUser'
import { usePostUser } from '../model/api/user/usePostUser'
import { userInitialValues } from '../utils/UserEditInitialValues'
import { UserEditFormProps } from '../model/interfaces/entities/IUserEditFormValues'
import { useGetCompanies } from '../model/api/company/useGetCompany'
import { UserForm } from './UserForm'
import transformPersonalNumbers from '../model/utils/transformPersonalNumbers'
import { Loading } from '../components/Loading'
import { useIsSupervisorOrAdmin } from '../model/utils/userUtils'
import { useEffect } from 'react'
import { UserRoleEnum } from '../model/enums/UserRoleEnum'

export const UserCreateForm = ({ profile }: UserEditFormProps) => {
  const navigate = useNavigate()
  const { id } = useParams<'id'>()
  const toastMessage = useToast()
  const { data: dataCompany } = useGetCompanies()
  const successCallBack: (data: IUser) => void = (data: any) => {
    navigate(`/organization/detail/${id}`)
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Došlo k chybě',
      description: error.response.data.error,
      status: 'error',
      isClosable: true,
    })
  }
  const userIsSupervisor = useIsSupervisorOrAdmin()
  useEffect(() => {
    if (userIsSupervisor === false) {
      navigate('/404')
    }
  }, [userIsSupervisor, navigate])

  const { mutate, isLoading } = usePostUser(successCallBack, errorCallBack)
  if (!dataCompany) {
    return <Loading />
  }
  const onSubmitForm: (formValues: IUserItem) => void = (formValues: IUserItem) => {
    if (formValues.role === UserRoleEnum.SALESMAN) {
      return mutate({
        parent: Number(formValues.parent) as number,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        usernameOtherSystem: formValues.usernameOtherSystem,
        role: formValues.role,
      })
    }
    mutate({
      ...formValues,
      personalNumbers: formValues.personalNumbers,
      parent: Number(formValues.parent) as number,
    })
  }

  return (
    <Box>
      <UserForm
        initialValues={{
          ...transformPersonalNumbers(dataCompany.data, userInitialValues),
          parent: (id ? Number(id) : undefined) as any,
        }}
        onSubmitForm={onSubmitForm}
        onGoBack={() => navigate(`/organization/detail/${id}`)}
        isLoading={isLoading}
      />
    </Box>
  )
}
