import { mode, cssVar, SystemStyleFunction } from '@chakra-ui/theme-tools'

const $bg = cssVar('tooltip-bg')
const $arrowBg = cssVar('popper-arrow-bg')

const baseStyle: SystemStyleFunction = (props) => {
  const bg = mode('white', 'gray.300')(props)
  return {
    [$bg.variable]: `colors.${bg}`,
    px: 2,
    py: 1,
    bg: [$bg.reference],
    [$arrowBg.variable]: [$bg.reference],
    color: mode('primary.700', 'gray.900')(props),
    borderRadius: 'md',
    fontWeight: 'normal',
    fontSize: 'md',
    boxShadow: 'tooltip',
    maxW: '320px',
    zIndex: 'tooltip',
  }
}

export const Tooltip = {
  baseStyle,
}
