import { Box, Button, Flex, Image, useToast } from '@chakra-ui/react'
import { Trash } from '../../icons'
import { FileInput } from '../../components/FileInput'
import { IHelpDeskFormValues } from '../../model/interfaces/entities/IHelpDeskFormValues'
import { usePostLogo } from '../../model/api/organization/usePostLogo'
import { useParams } from 'react-router-dom'
import { useDeleteLogo } from '../../model/api/organization/useDeleteLogo'
import { OrganizationEditFormValues } from '../../model/interfaces/entities/IOrganizationEditFormValues'

const ImageWrapper: React.FC<{ logo?: string }> = ({ logo }) => {
  return (
    <Box
      sx={{
        w: '10rem',
        h: '5rem',
        display: 'grid',
        placeContent: 'center',
        placeItems: 'center',
        border: '1px dashed',
        borderColor: 'gray.500',
        borderRadius: 'md',
        color: 'gray.500',
      }}
    >
      {logo ? (
        <Image src={logo} alt="Logo organizace" maxHeight="100%" overflow="auto" />
      ) : (
        <>žádné logo</>
      )}
    </Box>
  )
}

export const OrganizationLogoUploadForm: React.FC<{
  organization?: OrganizationEditFormValues
}> = ({ organization }) => {
  const toastMessage = useToast()
  const { id } = useParams()

  const successCallBack: (data: IHelpDeskFormValues) => void = (data: IHelpDeskFormValues) => {
    toastMessage({
      title: 'Logo nahráno',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }

  const successCallBackDelete: (data: IHelpDeskFormValues) => void = (
    data: IHelpDeskFormValues,
  ) => {
    toastMessage({
      title: 'Logo smazáno',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Došlo k chybě',
      description: error + '',
      status: 'error',
      isClosable: true,
    })
  }

  const { mutate, isLoading } = usePostLogo(successCallBack, errorCallBack, id)

  const imgToBase = async (e: any) => {
    const fileBase = e.currentTarget?.files[0]
    if (!fileBase) return
    if (fileBase.size > 10 * 1024 * 1024) {
      toastMessage({
        title: 'Nelze nahrát soubor větší než 10mb',
        status: 'error',
        isClosable: true,
      })
      return null
    }
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      mutate(reader.result)
    })
    reader.readAsDataURL(fileBase)
  }

  const { mutate: mutateDelete } = useDeleteLogo(successCallBackDelete, errorCallBack)

  const deleteLogo = () => {
    mutateDelete(id)
  }

  return (
    <Flex sx={{ gap: 4 }}>
      <ImageWrapper logo={organization?.logo} />

      <Box sx={{ display: 'grid', gap: 2 }}>
        <FileInput id="fileLogo" multiple onChange={imgToBase} />

        <Button
          disabled={!organization || !organization.logo}
          type="button"
          variant="outline"
          size="sm"
          colorScheme="orange"
          onClick={deleteLogo}
        >
          <Trash color="red.500" />
          Smazat logo
        </Button>
      </Box>
    </Flex>
  )
}
