import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Text,
  useEditableControls,
} from '@chakra-ui/react'
import { Add, Close, EditFilled } from '../icons'
import { NumberInputDecorator } from '../model/utils/numberInputUtils'
import React, { useEffect, useState } from 'react'

const EditableControls: React.FC<{
  currentValue?: string
  onSetValue: (newValue: string) => void
  onSetShowRange: (newShowRangeValue: boolean) => void
  minDiscount?: number
  maxDiscount?: number
  defaultValue?: string
}> = ({ currentValue, onSetValue, onSetShowRange, minDiscount, maxDiscount, defaultValue }) => {
  const { isEditing, getCancelButtonProps, getEditButtonProps } = useEditableControls()
  const iconSize = 4
  useEffect(() => {
    if (isEditing) {
      onSetShowRange(true)
    } else {
      onSetShowRange(false)
    }
  }, [isEditing])
  if (!isEditing && currentValue !== '') {
    if (maxDiscount !== undefined && Number(currentValue) > maxDiscount) {
      onSetValue(defaultValue || '')
    } else if (minDiscount !== undefined && Number(currentValue) < minDiscount) {
      onSetValue(defaultValue || '')
    }
  }
  return isEditing ? (
    <ButtonGroup justifyContent="center">
      <IconButton
        variant="ghost"
        colorScheme="white"
        aria-label="Zrušit"
        icon={<Close width={iconSize} height={iconSize} />}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton
        variant="ghost"
        colorScheme="white"
        aria-label="Upravit limit"
        sx={{ py: 2 }}
        {...getEditButtonProps()}
      >
        <EditFilled color="gray.500" width={iconSize} height={iconSize} />
      </IconButton>
    </Flex>
  )
}

export const EditablePrice = ({
  defaultValue,
  onSubmit,
  editablePreviewSx,
  isDisabled,
  suffix,
  minDiscount,
  maxDiscount,
  isRowChecked,
  isRowEditable,
}: {
  defaultValue: string | undefined
  onSubmit: (nextValue: string) => void
  editablePreviewSx?: object
  isDisabled?: boolean
  suffix?: string
  minDiscount?: number
  maxDiscount?: number
  isRowChecked?: boolean
  isRowEditable?: boolean
}) => {
  const [value, setValue] = useState(defaultValue)
  const [showRange, setShowRange] = useState(false)
  const handleSetValue = (number: string) => {
    setValue(number)
  }
  const handleSetShowRange = (showRangeValue: boolean) => {
    setShowRange(showRangeValue)
  }
  const editableValue = (() => {
    if (!value && !isDisabled) {
      return ''
    }
    if (value) return NumberInputDecorator.denormalize(value)
    return ''
  })()
  return (
    <Editable
      isDisabled={isDisabled}
      value={editableValue}
      sx={{ display: 'flex', gap: 1 }}
      onSubmit={(val) => onSubmit(NumberInputDecorator.removeNonDigits(val))}
    >
      {showRange && minDiscount !== undefined && maxDiscount !== undefined && (
        <Text fontSize="0.65rem" mr={4}>
          {`${minDiscount}% - ${maxDiscount}%`}
        </Text>
      )}
      <EditablePreview
        sx={{
          p: 0,
          fontSize: 'sm',
          textDecoration: 'underline',
          textDecorationStyle: 'dotted',
          ...editablePreviewSx,
        }}
        mr={isDisabled ? 4 : 0}
        color={isRowChecked || !isRowEditable ? 'current' : 'gray.500'}
      />
      <EditableInput
        sx={{
          w: 'auto',
          maxW: '100px',
          py: 0.25,
          bg: 'white',
          fontSize: 'sm',
          lineHeight: '19px',
          textAlign: 'end',
        }}
        value={NumberInputDecorator.denormalize(value)}
        onChange={(e) => {
          const normalizedValue = NumberInputDecorator.normalizeFromEvent(e)
          setValue(normalizedValue?.toString() || '')
        }}
      />
      {suffix && suffix}
      {!isDisabled && (
        <EditableControls
          currentValue={value}
          onSetValue={handleSetValue}
          onSetShowRange={handleSetShowRange}
          minDiscount={minDiscount}
          maxDiscount={maxDiscount}
          defaultValue={defaultValue}
        />
      )}
      {isDisabled && !value && (
        <Add boxSize={5} mr={4} color={'red'} style={{ transform: 'rotate(45deg)' }} />
      )}
    </Editable>
  )
}
