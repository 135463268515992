import { IOffersItem } from '../../interfaces/entities/IOffers'
import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const useGetContractById = (
  id: string | undefined,
  successCallBack: Function,
  errorCallBack: Function,
  enabled: boolean = true,
) => {
  return useQuery(
    [ApiRouteEnum.CONTRACT, id],
    () => api.get<IOffersItem>(`${ApiRouteEnum.CONTRACT}/${id}`),
    {
      retry: false,
      onSuccess: (response) => successCallBack(response),
      onError: (error) => errorCallBack(error),
      enabled,
    },
  )
}
