import { Icon } from '@chakra-ui/react'

export const Upload = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M10 2C12.817 2 14.415 3.923 14.647 6.246H14.717C16.532 6.246 18 7.758 18 9.623C18 9.718 17.996 9.813 17.989 9.906C17.6667 9.50394 17.292 9.14677 16.875 8.844C16.565 7.911 15.712 7.246 14.718 7.246H14.647C14.3991 7.246 14.16 7.15392 13.9762 6.98762C13.7923 6.82133 13.6768 6.59266 13.652 6.346C13.45 4.325 12.109 3 10 3C7.886 3 6.551 4.316 6.348 6.345C6.32344 6.59184 6.20801 6.82075 6.02414 6.98725C5.84026 7.15376 5.60106 7.24598 5.353 7.246H5.283C4.027 7.246 3 8.304 3 9.623C3 10.943 4.028 12 5.282 12H7.948C7.85953 12.3274 7.8003 12.6621 7.771 13H5.282C3.469 13 2 11.488 2 9.623C2 7.82 3.373 6.347 5.102 6.251L5.353 6.246C5.587 3.908 7.183 2 10 2ZM13.5 9C14.6935 9 15.8381 9.47411 16.682 10.318C17.5259 11.1619 18 12.3065 18 13.5C18 14.6935 17.5259 15.8381 16.682 16.682C15.8381 17.5259 14.6935 18 13.5 18C12.3065 18 11.1619 17.5259 10.318 16.682C9.47411 15.8381 9 14.6935 9 13.5C9 12.3065 9.47411 11.1619 10.318 10.318C11.1619 9.47411 12.3065 9 13.5 9V9ZM15.102 13.898C15.2076 14.0036 15.3507 14.0629 15.5 14.0629C15.6493 14.0629 15.7924 14.0036 15.898 13.898C16.0036 13.7924 16.0629 13.6493 16.0629 13.5C16.0629 13.3507 16.0036 13.2076 15.898 13.102L13.898 11.102C13.8458 11.0496 13.7838 11.0081 13.7155 10.9797C13.6472 10.9514 13.5739 10.9368 13.5 10.9368C13.4261 10.9368 13.3528 10.9514 13.2845 10.9797C13.2162 11.0081 13.1542 11.0496 13.102 11.102L11.102 13.102C11.0497 13.1543 11.0083 13.2163 10.98 13.2846C10.9517 13.3529 10.9371 13.4261 10.9371 13.5C10.9371 13.5739 10.9517 13.6471 10.98 13.7154C11.0083 13.7837 11.0497 13.8457 11.102 13.898C11.1543 13.9503 11.2163 13.9917 11.2846 14.02C11.3529 14.0483 11.4261 14.0629 11.5 14.0629C11.5739 14.0629 11.6471 14.0483 11.7154 14.02C11.7837 13.9917 11.8457 13.9503 11.898 13.898L13 12.796V15.5C13 15.6326 13.0527 15.7598 13.1464 15.8536C13.2402 15.9473 13.3674 16 13.5 16C13.6326 16 13.7598 15.9473 13.8536 15.8536C13.9473 15.7598 14 15.6326 14 15.5V12.796L15.102 13.898Z" fill="currentColor"/>
	</Icon>
)


export const UploadString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 2C12.817 2 14.415 3.923 14.647 6.246H14.717C16.532 6.246 18 7.758 18 9.623C18 9.718 17.996 9.813 17.989 9.906C17.6667 9.50394 17.292 9.14677 16.875 8.844C16.565 7.911 15.712 7.246 14.718 7.246H14.647C14.3991 7.246 14.16 7.15392 13.9762 6.98762C13.7923 6.82133 13.6768 6.59266 13.652 6.346C13.45 4.325 12.109 3 10 3C7.886 3 6.551 4.316 6.348 6.345C6.32344 6.59184 6.20801 6.82075 6.02414 6.98725C5.84026 7.15376 5.60106 7.24598 5.353 7.246H5.283C4.027 7.246 3 8.304 3 9.623C3 10.943 4.028 12 5.282 12H7.948C7.85953 12.3274 7.8003 12.6621 7.771 13H5.282C3.469 13 2 11.488 2 9.623C2 7.82 3.373 6.347 5.102 6.251L5.353 6.246C5.587 3.908 7.183 2 10 2ZM13.5 9C14.6935 9 15.8381 9.47411 16.682 10.318C17.5259 11.1619 18 12.3065 18 13.5C18 14.6935 17.5259 15.8381 16.682 16.682C15.8381 17.5259 14.6935 18 13.5 18C12.3065 18 11.1619 17.5259 10.318 16.682C9.47411 15.8381 9 14.6935 9 13.5C9 12.3065 9.47411 11.1619 10.318 10.318C11.1619 9.47411 12.3065 9 13.5 9V9ZM15.102 13.898C15.2076 14.0036 15.3507 14.0629 15.5 14.0629C15.6493 14.0629 15.7924 14.0036 15.898 13.898C16.0036 13.7924 16.0629 13.6493 16.0629 13.5C16.0629 13.3507 16.0036 13.2076 15.898 13.102L13.898 11.102C13.8458 11.0496 13.7838 11.0081 13.7155 10.9797C13.6472 10.9514 13.5739 10.9368 13.5 10.9368C13.4261 10.9368 13.3528 10.9514 13.2845 10.9797C13.2162 11.0081 13.1542 11.0496 13.102 11.102L11.102 13.102C11.0497 13.1543 11.0083 13.2163 10.98 13.2846C10.9517 13.3529 10.9371 13.4261 10.9371 13.5C10.9371 13.5739 10.9517 13.6471 10.98 13.7154C11.0083 13.7837 11.0497 13.8457 11.102 13.898C11.1543 13.9503 11.2163 13.9917 11.2846 14.02C11.3529 14.0483 11.4261 14.0629 11.5 14.0629C11.5739 14.0629 11.6471 14.0483 11.7154 14.02C11.7837 13.9917 11.8457 13.9503 11.898 13.898L13 12.796V15.5C13 15.6326 13.0527 15.7598 13.1464 15.8536C13.2402 15.9473 13.3674 16 13.5 16C13.6326 16 13.7598 15.9473 13.8536 15.8536C13.9473 15.7598 14 15.6326 14 15.5V12.796L15.102 13.898Z" fill="#003D66"/>
</svg>
`;