import React, { useContext } from 'react'
import { IItem } from '../model/interfaces/entities/IItem'

interface IOfferGridContext {
  openedColumns: Array<any>
  onSectionTitleClick: Function
}

interface IOfferGridProvider {
  openedColumns: any
  onSectionTitleClick: Function
  children?: React.ReactNode
}

const defaultState = {
  openedColumns: [],
  onSectionTitleClick: () => {},
}

const OfferGridContext = React.createContext<IOfferGridContext>(defaultState)

export const OfferGridProvider = ({
  openedColumns,
  onSectionTitleClick,
  children,
}: IOfferGridProvider) => {
  return (
    <OfferGridContext.Provider
      value={{
        openedColumns,
        onSectionTitleClick,
      }}
    >
      {children && children}
    </OfferGridContext.Provider>
  )
}

export const useOfferGrid = () => {
  const context = useContext(OfferGridContext)

  if (context === undefined) {
    throw new Error('useOfferGrid must be used within a OfferGridProvider!')
  }

  return context
}
