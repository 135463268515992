import { Box } from '@chakra-ui/react'
import React from 'react'

export const BusinessDiscountsWrapper = ({ children }: {children: React.ReactNode}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: 16,
        columnGap: 8,
        w: '100%',
        p: 4,
        backgroundColor: 'gray.50',
        borderRadius: 'md',
        gridTemplateColumns: {
          base: '1fr',
          lg: '1fr 1fr',
        },
      }}
    >
      {children}
    </Box>
  )
}
