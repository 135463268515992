import {
  InsuranceAssistance,
  InsuranceAssistanceExtra,
  InsuranceBrokenPipe,
  InsuranceGlassDamage,
  InsuranceFlood,
  InsuranceTheft,
  InsuranceElecricityShort,
  InsuranceVandalism,
  InsuranceWaterPipesDamage,
  InsuranceNaturalDamage,
  InsurancePropertyOwnershipLiability,
  InsuranceLifeLiability,
  InsuranceValorization,
  InsuranceNewPriceInsurance,
  InsuranceAtmosphericLeakage,
} from '../icons'

export const enum ExtraInsurancePropertyItemIconsEnum {
  Assistance = 'assistance',
  AssistanceExtra = 'assistanceExtra',
  BrokenPipe = 'brokenPipe',
  Theft = 'theft',
  GlassDamage = 'glassDamage',
  Flood = 'flood',
  ElecricityShort = 'elecricityShort',
  Vandalism = 'vandalism',
  WaterPipesDamage = 'waterPipesDamage',
  AtmosphericLeakage = 'atmosphericLeakage',
  NaturalDamage = 'naturalDamage',
  PropertyOwnershipLiability = 'propertyOwnershipLiability',
  LifeLiability = 'lifeLiability',
  Valorization = 'valorization',
  NewPriceInsurance = 'newPriceInsurance',
}

export const getExtraInsurancePropertyIcon = (
  icon: ExtraInsurancePropertyItemIconsEnum,
  size: string = '48px',
  color: string = 'gray.800',
) => {
  switch (icon) {
    case 'assistance':
      return <InsuranceAssistance width={size} height={size} color={color} />
    case 'assistanceExtra':
      return <InsuranceAssistanceExtra width={size} height={size} color={color} />
    case 'brokenPipe':
      return <InsuranceBrokenPipe width={size} height={size} color={color} />
    case 'glassDamage':
      return <InsuranceGlassDamage width={size} height={size} color={color} />
    case 'flood':
      return <InsuranceFlood width={size} height={size} color={color} />
    case 'theft':
      return <InsuranceTheft width={size} height={size} color={color} />
    case 'elecricityShort':
      return <InsuranceElecricityShort width={size} height={size} color={color} />
    case 'vandalism':
      return <InsuranceVandalism width={size} height={size} color={color} />
    case 'waterPipesDamage':
      return <InsuranceWaterPipesDamage width={size} height={size} color={color} />
    case 'atmosphericLeakage':
      return <InsuranceAtmosphericLeakage width={size} height={size} color={color} />
    case 'naturalDamage':
      return <InsuranceNaturalDamage width={size} height={size} color={color} />
    case 'propertyOwnershipLiability':
      return <InsurancePropertyOwnershipLiability width={size} height={size} color={color} />
    case 'lifeLiability':
      return <InsuranceLifeLiability width={size} height={size} color={color} />
    case 'valorization':
      return <InsuranceValorization width={size} height={size} color={color} />
    case 'newPriceInsurance':
      return <InsuranceNewPriceInsurance width={size} height={size} color={color} />
  }
}
