import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools'

const baseStyle: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props

  return {
    transitionProperty: 'common',
    transitionDuration: 'fast',
    transitionTimingFunction: 'ease-out',
    cursor: 'pointer',
    textDecoration: 'none',
    outline: 'none',
    color: c ? mode(`${c}.600`, `${c}.200`)(props) : 'inherit',
    _hover: {
      textDecoration: 'underline',
    },
    _focus: {
      boxShadow: 'outline',
    },
  }
}

const variantUnderline: SystemStyleFunction = (props) => {
  return {
    textDecoration: 'underline',
  }
}

const variants = {
  underline: variantUnderline,
}

export const Link = {
  baseStyle,
  variants,
}
