import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

import { IUserItem } from '../../interfaces/entities/IUser'

export const useGetUserId = (id: string | undefined) => {
  const userDetailsEndpoint: string = ApiRouteEnum.USER + `/${id}`
  return useQuery([ApiRouteEnum.USER, id], () => api.get<IUserItem>(userDetailsEndpoint), {
    retry: false,
  })
}
