import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const useDeleteLogo = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (id: string | undefined) => api.delete(`${ApiRouteEnum.ORGANIZATION}/${id}/logo`),
    {
      onSuccess: (data) => {
        successCallBack(data.data)
        return queryClient.invalidateQueries({ queryKey: [ApiRouteEnum.ORGANIZATION] })
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
