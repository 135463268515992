import { Icon } from '@chakra-ui/react'

export const Truck = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 28 28" fill="none">
<path d="M19 19.5V6C19 4.89543 18.1046 4 17 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22V22M14 22H11" stroke="currentColor" strokeWidth="1.5"/>
<path d="M19 8H21C21.5 8 22.6 8.3 23 9.5C23.4 10.7 24.1667 12.6667 24.5 13.5M24.5 13.5H19M24.5 13.5V19M20 22C20.5 22 21.8 22 23 22C24.2 22 24.5 20.6667 24.5 20V19M24.5 19H22.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<circle cx="8" cy="22" r="3" stroke="currentColor" strokeWidth="1.5"/>
<circle cx="17" cy="22" r="3" stroke="currentColor" strokeWidth="1.5"/>
	</Icon>
)


export const TruckString=`<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 19.5V6C19 4.89543 18.1046 4 17 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22V22M14 22H11" stroke="#EE7400" stroke-width="1.5"/>
<path d="M19 8H21C21.5 8 22.6 8.3 23 9.5C23.4 10.7 24.1667 12.6667 24.5 13.5M24.5 13.5H19M24.5 13.5V19M20 22C20.5 22 21.8 22 23 22C24.2 22 24.5 20.6667 24.5 20V19M24.5 19H22.5" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="8" cy="22" r="3" stroke="#EE7400" stroke-width="1.5"/>
<circle cx="17" cy="22" r="3" stroke="#EE7400" stroke-width="1.5"/>
</svg>
`;