import { Icon } from '@chakra-ui/react'

export const Print = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M5 4.5C5 4.10218 5.15804 3.72064 5.43934 3.43934C5.72064 3.15804 6.10218 3 6.5 3H13.5C13.8978 3 14.2794 3.15804 14.5607 3.43934C14.842 3.72064 15 4.10218 15 4.5V5H15.5C16.163 5 16.7989 5.26339 17.2678 5.73223C17.7366 6.20107 18 6.83696 18 7.5V12.5C18 12.8978 17.842 13.2794 17.5607 13.5607C17.2794 13.842 16.8978 14 16.5 14H15V15.5C15 15.8978 14.842 16.2794 14.5607 16.5607C14.2794 16.842 13.8978 17 13.5 17H6.5C6.10218 17 5.72064 16.842 5.43934 16.5607C5.15804 16.2794 5 15.8978 5 15.5V14H3.5C3.10218 14 2.72064 13.842 2.43934 13.5607C2.15804 13.2794 2 12.8978 2 12.5V7.5C2 6.83696 2.26339 6.20107 2.73223 5.73223C3.20107 5.26339 3.83696 5 4.5 5H5V4.5ZM6 5H14V4.5C14 4.36739 13.9473 4.24021 13.8536 4.14645C13.7598 4.05268 13.6326 4 13.5 4H6.5C6.36739 4 6.24021 4.05268 6.14645 4.14645C6.05268 4.24021 6 4.36739 6 4.5V5ZM5 13V11.5C5 11.1022 5.15804 10.7206 5.43934 10.4393C5.72064 10.158 6.10218 10 6.5 10H13.5C13.8978 10 14.2794 10.158 14.5607 10.4393C14.842 10.7206 15 11.1022 15 11.5V13H16.5C16.6326 13 16.7598 12.9473 16.8536 12.8536C16.9473 12.7598 17 12.6326 17 12.5V7.5C17 7.10218 16.842 6.72064 16.5607 6.43934C16.2794 6.15804 15.8978 6 15.5 6H4.5C4.10218 6 3.72064 6.15804 3.43934 6.43934C3.15804 6.72064 3 7.10218 3 7.5V12.5C3 12.6326 3.05268 12.7598 3.14645 12.8536C3.24021 12.9473 3.36739 13 3.5 13H5ZM6.5 11C6.36739 11 6.24021 11.0527 6.14645 11.1464C6.05268 11.2402 6 11.3674 6 11.5V15.5C6 15.6326 6.05268 15.7598 6.14645 15.8536C6.24021 15.9473 6.36739 16 6.5 16H13.5C13.6326 16 13.7598 15.9473 13.8536 15.8536C13.9473 15.7598 14 15.6326 14 15.5V11.5C14 11.3674 13.9473 11.2402 13.8536 11.1464C13.7598 11.0527 13.6326 11 13.5 11H6.5Z" fill="currentColor"/>
	</Icon>
)


export const PrintString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 4.5C5 4.10218 5.15804 3.72064 5.43934 3.43934C5.72064 3.15804 6.10218 3 6.5 3H13.5C13.8978 3 14.2794 3.15804 14.5607 3.43934C14.842 3.72064 15 4.10218 15 4.5V5H15.5C16.163 5 16.7989 5.26339 17.2678 5.73223C17.7366 6.20107 18 6.83696 18 7.5V12.5C18 12.8978 17.842 13.2794 17.5607 13.5607C17.2794 13.842 16.8978 14 16.5 14H15V15.5C15 15.8978 14.842 16.2794 14.5607 16.5607C14.2794 16.842 13.8978 17 13.5 17H6.5C6.10218 17 5.72064 16.842 5.43934 16.5607C5.15804 16.2794 5 15.8978 5 15.5V14H3.5C3.10218 14 2.72064 13.842 2.43934 13.5607C2.15804 13.2794 2 12.8978 2 12.5V7.5C2 6.83696 2.26339 6.20107 2.73223 5.73223C3.20107 5.26339 3.83696 5 4.5 5H5V4.5ZM6 5H14V4.5C14 4.36739 13.9473 4.24021 13.8536 4.14645C13.7598 4.05268 13.6326 4 13.5 4H6.5C6.36739 4 6.24021 4.05268 6.14645 4.14645C6.05268 4.24021 6 4.36739 6 4.5V5ZM5 13V11.5C5 11.1022 5.15804 10.7206 5.43934 10.4393C5.72064 10.158 6.10218 10 6.5 10H13.5C13.8978 10 14.2794 10.158 14.5607 10.4393C14.842 10.7206 15 11.1022 15 11.5V13H16.5C16.6326 13 16.7598 12.9473 16.8536 12.8536C16.9473 12.7598 17 12.6326 17 12.5V7.5C17 7.10218 16.842 6.72064 16.5607 6.43934C16.2794 6.15804 15.8978 6 15.5 6H4.5C4.10218 6 3.72064 6.15804 3.43934 6.43934C3.15804 6.72064 3 7.10218 3 7.5V12.5C3 12.6326 3.05268 12.7598 3.14645 12.8536C3.24021 12.9473 3.36739 13 3.5 13H5ZM6.5 11C6.36739 11 6.24021 11.0527 6.14645 11.1464C6.05268 11.2402 6 11.3674 6 11.5V15.5C6 15.6326 6.05268 15.7598 6.14645 15.8536C6.24021 15.9473 6.36739 16 6.5 16H13.5C13.6326 16 13.7598 15.9473 13.8536 15.8536C13.9473 15.7598 14 15.6326 14 15.5V11.5C14 11.3674 13.9473 11.2402 13.8536 11.1464C13.7598 11.0527 13.6326 11 13.5 11H6.5Z" fill="#003D66"/>
</svg>
`;