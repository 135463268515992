import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePutUser = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id: string | undefined,
) => {
  const userDetailsEndpoint: string = ApiRouteEnum.USER + `/${id}`
  return useMutation((userData: any) => api.patch(userDetailsEndpoint, userData), {
    mutationKey: [userDetailsEndpoint],
    onSuccess: (data) => {
      successCallBack(data.data)
    },
    onError: (error) => errorCallBack(error),
  })
}
