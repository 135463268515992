import { Button } from "@chakra-ui/react";

import { Container } from "../../../components/Container";
import { Section } from "../../../components/Section";
import { EmptyState } from "../../../components/EmptyState";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOfferById } from "../../../model/api/offers/useGetOfferById";

export const TooFar = () => {
	const { id } = useParams<"id">();
	const { data: offerData } = useGetOfferById(id);
	const navigate = useNavigate();

	return (
		<Section>
			<Container>
				<EmptyState
					title='Tento krok ještě není dostupný'
					cta={
						<>
							{offerData?.data?.step && (
								<Button
									colorScheme='primary'
									onClick={() => navigate(`/sme/wizard/edit/${id}/${offerData?.data?.step!}`)}
								>
									Zpět na předchozí stranu
								</Button>
							)}
						</>
					}
				/>
			</Container>
		</Section>
	);
};
