import { AxiosResponse } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useCopyOffer } from '../api/offers/useCopyOffer' // Replace with the actual path to your useCopyOffer hook
import { IOffersItem } from '../interfaces/entities/IOffers'

export function useCustomCopyOffer(id?: string) {
  const toastMessage = useToast()
  const navigate = useNavigate()
  const { mutate: mutateCopyOffer, isLoading: isCopyingOffer } = useCopyOffer(
    (data: AxiosResponse<IOffersItem>, openInNewab: boolean = false) => {
      toastMessage({
        title: 'Kopie pojištění byla úspěšně vytvořena',
        status: 'success',
        position: 'top',
      })
      const clonedOfferId = data?.data?.id

      if (typeof clonedOfferId === 'undefined') {
        return
      }

      if (openInNewab) {
        window.open(`/sme/wizard/edit/${clonedOfferId}/1`, '_blank')
      } else {
        navigate(`/sme/wizard/edit/${clonedOfferId}/1`)
      }
    },
    (error) => {
      toastMessage({
        title: 'Kopii pojištění se nepodařilo vytvořit',
        description: error.response?.data.error,
        status: 'error',
        position: 'top',
      })
    },
    id,
  )
  return { mutateCopyOffer, isCopyingOffer }
}
