import React from 'react'
import { Grid, GridProps, Heading, Image, forwardRef } from '@chakra-ui/react'

import symbolOKApps from '../assets/images/symbol-okapps.svg'

export interface EmptyStateProps extends GridProps {
  cta?: React.ReactNode
}

export const EmptyState = forwardRef(
  ({ children, title = 'Nenalezeno', cta, ...rest }: EmptyStateProps, ref) => {
    return (
      <Grid sx={{ placeContent: 'center', gap: 8, placeItems: 'center', textAlign: 'center' }}>
        <Image sx={{ maxW: 40, w: 'full', h: 'auto' }} src={symbolOKApps} alt="" aria-hidden />

        <Heading sx={{ fontSize: '3xl', fontWeight: 'medium' }} as="h2">
          {title}
        </Heading>

        {cta && cta}
      </Grid>
    )
  },
)
