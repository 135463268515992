import { Box } from '@chakra-ui/react'

import { Container } from '../../../components/Container'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { OfferPropertyCompare } from '../../../containers/offers/OfferPropertyCompare'
import { useParams } from 'react-router-dom'
import { IOffersItem } from '../../../model/interfaces/entities/IOffers'
import { Loading } from '../../../components/Loading'
import { InsuranceInfoSme } from './InsuranceInfoSme'
import { useFormStepGuard } from '../../../model/utils/formHelpers'
import React from 'react'
import { FormIsDisabledContext } from '../../../model/contexts/FormIsDisabledContext'
import { useOfferOrContractData } from '../../../model/hooks/useOfferOrContractData'

export const Step3: React.FC<{ viewOnly?: boolean }> = ({ viewOnly = false }) => {
  const { id } = useParams<'id'>()
  const successCallBack = (resp: IOffersItem) => {
    return
  }

  const errorCallBack = (error: any) => {
    console.error(error)
  }
  const [data, isLoading] = useOfferOrContractData(id, successCallBack, errorCallBack, viewOnly)

  useFormStepGuard(3, data?.data)
  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      {data && (
        <Section>
          <Container>
            <StepsContent>
              <Steps icon="briefcase" currentStep={3} viewOnly={viewOnly} passed={data?.data.step ?? 0} />
              <InsuranceInfoSme
                emailAndPrintAvailable={true}
                offer={data.data}
                cloneAvailable={true}
              />
            </StepsContent>
          </Container>

          <Box sx={{ px: 4 }}>
            <FormIsDisabledContext.Provider value={viewOnly}>
              <OfferPropertyCompare
                offerId={data?.data.id}
                item={data?.data?.item}
                items={data.data.items}
                itemsWithDiscrepancy={data.data.itemsWithDiscrepancy || []}
                viewOnly={viewOnly}
              />
            </FormIsDisabledContext.Provider>
          </Box>
        </Section>
      )}
    </>
  )
}
