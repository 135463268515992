import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const useDeleteFAQ = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
) => {
  const queryClient = useQueryClient()

  return useMutation((id: number) => api.delete(`${ApiRouteEnum.FAQ}/${id}`), {
    onSuccess: (data) => {
      successCallBack(data.data)
      return queryClient.invalidateQueries({ queryKey: [ApiRouteEnum.FAQ] })
    },
    onError: (error) => errorCallBack(error),
  })
}
