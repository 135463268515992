import type { SystemStyleObject } from '@chakra-ui/theme-tools'

const baseStyle: SystemStyleObject = {
  w: '100%',
  mx: 'auto',
  maxW: '77rem',
  px: [4, 4, 4, 4, 4, 0],
}

export const Container = {
  baseStyle,
}
