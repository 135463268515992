import React from 'react'
import { Box, BoxProps, Flex, FormControl, Input } from '@chakra-ui/react'

import { FormLabel } from '../components/FormLabel'
import { useFormContext } from 'react-hook-form'
import { Switch } from '../components/Switch'
import { FormSpacer } from '../components/FormSpacer'
import { FormGroup } from '../components/FormGroup'
import {
  ExtraInsurancePropertyItemIconsEnum,
  getExtraInsurancePropertyIcon,
} from '../utils/getExtraInsurancePropertyIcon'
import { LiabilitySlider } from './LiabilitySlider'
import { CustomNumberInput } from './CustomNumberInput'

const ExtraInsuranceItem = ({
  children,
  label,
  tooltip,
  icon,
  sx,
}: {
  children: React.ReactNode
  label: string
  tooltip?: string
  icon: ExtraInsurancePropertyItemIconsEnum | false
  sx?: BoxProps['sx']
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, ...sx }}>
      {icon && getExtraInsurancePropertyIcon(icon, '48px', 'secondary.500')}
      <FormControl sx={{}}>
        <FormLabel tooltip={tooltip}>{label}</FormLabel>
        {children}
      </FormControl>
    </Box>
  )
}

export const ExtraInsuranceProperty = ({ showIcons = true }: { showIcons?: boolean }) => {
  const { register, getValues, setValue, watch } = useFormContext()
  const propertyOwnershipLiabilityMin = 0
  const propertyOwnershipLiabilityMax = 50000000
  const propertyOwnershipLiabilityStep = 1000000
  const lifeLiabilityMin = 0
  const lifeLiabilityMax = 50000000
  const lifeLiabilityStep = 1000000
  const insuredPropertyValueMin = 0
  const insuredPropertyValueMax = 50000000
  const insuredPropertyValueStep = 1000000
  const insuredHouseholdValueMin = 0
  const insuredHouseholdValueMax = 5000000
  const insuredHouseholdValueStep = 100000

  return (
    <Box sx={{ w: '100%', display: 'grid', gap: 4 }}>
      <Flex sx={{ p: 4, bgColor: 'blue.50', borderRadius: 'md' }}>
        <Box
          sx={{ display: 'grid', gap: 8, gridTemplateColumns: { base: '1fr', md: '1.2fr 2fr' } }}
        >
          <FormControl>
            <FormLabel>Pojistná částka nemovitosti</FormLabel>

            <CustomNumberInput
              defaultValue={watch('insuredPropertyValue')}
              onValueChange={(value) => setValue('insuredPropertyValue', value)}
            />
            <Input type="hidden" {...register('insuredPropertyValue')} />
          </FormControl>

          <LiabilitySlider
            name="insuredPropertyValue"
            min={insuredPropertyValueMin}
            max={insuredPropertyValueMax}
            step={insuredPropertyValueStep}
            defaultValue={watch('insuredPropertyValue')}
            onChange={(value) => setValue('insuredPropertyValue', value)}
          />
        </Box>
        <Box
          sx={{ display: 'grid', gap: 8, gridTemplateColumns: { base: '1fr', md: '1.2fr 2fr' } }}
        >
          <FormControl>
            <FormLabel>Pojistná částka domácnosti</FormLabel>

            <CustomNumberInput
              defaultValue={watch('insuredHouseholdValue')}
              onValueChange={(value) => setValue('insuredHouseholdValue', value)}
            />
            <Input type="hidden" {...register('insuredHouseholdValue')} />
          </FormControl>

          <LiabilitySlider
            name="insuredHouseholdValue"
            min={insuredHouseholdValueMin}
            max={insuredHouseholdValueMax}
            step={insuredHouseholdValueStep}
            defaultValue={watch('insuredHouseholdValue')}
            onChange={(value) => setValue('insuredHouseholdValue', value)}
          />
        </Box>
      </Flex>

      <FormSpacer />

      <Box
        sx={{
          w: '100%',
          display: 'grid',
          gridTemplateColumns: { base: '1fr', lg: '1fr 1fr 1fr' },
          gap: 8,
          placeItems: 'start',
        }}
      >
        <ExtraInsuranceItem
          label="Asistence"
          tooltip="Asistence"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.Assistance}
        >
          <Switch {...register('assistance')} />
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Asistence rozšířená"
          tooltip="Asistence rozšířená"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.AssistanceExtra}
        >
          <Switch {...register('assistanceExtra')} />
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Havárie rozvodů (lom trubky)"
          tooltip="Havárie rozvodů (lom trubky)"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.BrokenPipe}
        >
          <Switch {...register('brokenPipe')} />
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Odcizení věci"
          tooltip="Odcizení věci"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.Theft}
        >
          <Switch {...register('theft')} />
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Pojištění skel"
          tooltip="Pojištění skel"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.GlassDamage}
        >
          <Switch {...register('glassDamage')} />
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Povodeň, záplava"
          tooltip="Povodeň, záplava"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.Flood}
        >
          <Switch {...register('flood')} />
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Přepětí, podpětí, zkrat"
          tooltip="Přepětí, podpětí, zkrat"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.ElecricityShort}
        >
          <Switch {...register('elecricityShort')} />
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Vandalismus"
          tooltip="Vandalismus"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.Vandalism}
        >
          <Switch {...register('vandalism')} />
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Vodovodní škody"
          tooltip="Vodovodní škody"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.WaterPipesDamage}
        >
          <Switch {...register('waterPipesDamage')} />
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Zatečení atmosférických srážek"
          tooltip="Zatečení atmosférických srážek"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.AtmosphericLeakage}
        >
          <Switch {...register('atmosphericLeakage')} />
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Živelní škody"
          tooltip="Živelní škody"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.NaturalDamage}
        >
          <Switch {...register('naturalDamage')} />
        </ExtraInsuranceItem>
      </Box>

      <FormSpacer />

      <FormGroup>
        <ExtraInsuranceItem
          label="Odpovědnost z vlastnictví nemovitosti"
          tooltip="Odpovědnost z vlastnictví nemovitosti"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.PropertyOwnershipLiability}
          sx={{ minWidth: '22rem' }}
        >
          <Switch {...register('propertyOwnershipLiability')} />
        </ExtraInsuranceItem>

        {watch('propertyOwnershipLiability') && (
          <Box
            sx={{ display: 'grid', gap: 8, gridTemplateColumns: { base: '1fr', md: '1fr 1.8fr' } }}
          >
            <FormControl>
              <FormLabel>Limit plnění</FormLabel>

              <CustomNumberInput
                defaultValue={getValues('propertyOwnershipLiabilityValue')}
                onValueChange={(value) => setValue('propertyOwnershipLiabilityValue', value)}
              />
              <Input type="hidden" {...register('propertyOwnershipLiabilityValue')} />
            </FormControl>

            <LiabilitySlider
              name="propertyOwnershipLiabilityValue"
              min={propertyOwnershipLiabilityMin}
              max={propertyOwnershipLiabilityMax}
              step={propertyOwnershipLiabilityStep}
              defaultValue={getValues('propertyOwnershipLiabilityValue')}
              onChange={(value) => setValue('propertyOwnershipLiabilityValue', value)}
            />
          </Box>
        )}
      </FormGroup>

      <FormGroup sx={{ w: '100%' }}>
        <ExtraInsuranceItem
          label="Odpovědnost z běžného života"
          tooltip="Odpovědnost z běžného života"
          icon={showIcons && ExtraInsurancePropertyItemIconsEnum.LifeLiability}
          sx={{ minWidth: '22rem' }}
        >
          <Switch {...register('lifeLiability')} />
        </ExtraInsuranceItem>

        {watch('lifeLiability') && (
          <Box
            sx={{ display: 'grid', gap: 8, gridTemplateColumns: { base: '1fr', md: '1fr 1.8fr' } }}
          >
            <FormControl>
              <FormLabel>Limit plnění</FormLabel>

              <CustomNumberInput
                defaultValue={getValues('lifeLiabilityValue')}
                onValueChange={(value) => setValue('lifeLiabilityValue', value)}
              />
              <Input type="hidden" {...register('lifeLiabilityValue')} />
            </FormControl>

            <LiabilitySlider
              name="lifeLiabilityValue"
              min={lifeLiabilityMin}
              max={lifeLiabilityMax}
              step={lifeLiabilityStep}
              defaultValue={getValues('lifeLiabilityValue')}
              onChange={(value) => setValue('lifeLiabilityValue', value)}
            />
          </Box>
        )}
      </FormGroup>

      <FormSpacer />

      <ExtraInsuranceItem
        label="Souhlas s valorizací pojistné částky"
        tooltip="Souhlas s valorizací pojistné částky"
        icon={showIcons && ExtraInsurancePropertyItemIconsEnum.Valorization}
      >
        <Switch {...register('valorization')} />
      </ExtraInsuranceItem>

      <ExtraInsuranceItem
        label="Pojištění na novou cenu"
        tooltip="Pojištění na novou cenu"
        icon={showIcons && ExtraInsurancePropertyItemIconsEnum.NewPriceInsurance}
      >
        <Switch {...register('newPriceInsurance')} />
      </ExtraInsuranceItem>
    </Box>
  )
}
