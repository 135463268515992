import { Box, Checkbox, CheckboxGroup, FormControl, FormHelperText, Select } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { FormGroup } from '../components/FormGroup'
import { FormLabel } from '../components/FormLabel'
import { FormSpacer } from '../components/FormSpacer'
import { InputGroup } from '../components/InputGroup'
import { TextareaGroup } from '../components/TextareaGroup'

export const AdditionalVehicleInfo = () => {
  const { register } = useFormContext()

  return (
    <>
      <FormGroup>
        <FormControl>
          <FormLabel>VIN</FormLabel>

          <InputGroup id="vin" htmlSize={14} isDisabled {...register('vin')} />
        </FormControl>

        <FormControl>
          <FormLabel>Číslo velkého TP</FormLabel>

          <InputGroup id="tp" htmlSize={10} placeholder="Číslo TP" {...register('tp')} />
        </FormControl>

        <FormControl>
          <FormLabel tooltip="Reg. značka (SPZ)">Reg. značka (SPZ)</FormLabel>

          <InputGroup id="spz" htmlSize={10} placeholder="SPZ" {...register('spz')} />
        </FormControl>

        <FormControl>
          <FormLabel tooltip="Druh registrační značky (SPZ)">
            Druh registrační značky (SPZ)
          </FormLabel>

          <Select {...register('spzType')}>
            <option value="stable">Stálá</option>
          </Select>
        </FormControl>
      </FormGroup>

      <FormControl variant="fullWidth">
        <FormLabel>Bližší specifikace vozidla</FormLabel>

        <InputGroup
          placeholder="Např. detailní model, motorizace apod."
          {...register('vehicleSpecification')}
        />
      </FormControl>

      <FormSpacer />

      <Box sx={{ w: '100%', display: 'grid', gridTemplateColumns: { base: '1fr', md: 'auto 1fr' }, placeItems: 'start', gap: 8 }}>
        <FormControl>
          <FormLabel>Poškození vozidla</FormLabel>

          <CheckboxGroup> 
            <Checkbox>Vůz je v době sjednání poškozen</Checkbox>
            <Checkbox>Vůz byl v minulosti vážně poškozen</Checkbox>
          </CheckboxGroup>
        </FormControl>

        <FormControl variant="fullWidth">
          <FormLabel tooltip="Popis poškození">Popis poškození</FormLabel>

          <TextareaGroup
            placeholder="Např. Oděrka na pravém předním blatníku..."
            {...register('vehicleSpecification')}
          />

          <FormHelperText>Tento popis bude uveden ve smlouvě.</FormHelperText>
        </FormControl>
      </Box>
    </>
  )
}
