import React from 'react'
import { IOffersItem } from '../../../model/interfaces/entities/IOffers'
import moment from 'moment/moment'
import { InsuranceInfo } from '../../../containers/InsuranceInfo'

export const InsuranceInfoSme: React.FC<{
  offer?: IOffersItem
  emailAndPrintAvailable?: boolean
  cloneAvailable?: boolean
  insureeEmail?: string
}> = ({ offer, emailAndPrintAvailable, cloneAvailable = false, insureeEmail }) => {
  return (
    <InsuranceInfo
      offerId={offer?.id ? String(offer.id) : undefined}
      startDate={moment(offer?.insurance.dateStart).toDate()}
      insureeDetails={`${offer?.applicant.companyName} ${offer?.applicant.ino}`}
      subjectDetails={`${offer?.applicant.city} ${offer?.applicant.street} ${offer?.applicant.streetNumber} ${offer?.applicant.zip}`}
      additionalInfo={`${offer?.business.field}`}
      // dummy info from broker added, need to be updated correct
      broker={{
        broker: {
          name: `${offer?.user.firstName} ${offer?.user.lastName}`,
          link: `/user/detail/${offer?.user.id}`,
          email: offer?.user.email,
        },
        company: {
          name: offer?.organization.username || '',
          link: `/organization/detail/${offer?.organization.id}`,
        },
        date: moment(offer?.dateCreated).toDate(),
      }}
      emailAndPrintAvailable={emailAndPrintAvailable}
      cloneAvailable={cloneAvailable}
      insureeEmail={offer?.applicant.email ? offer?.applicant.email : insureeEmail}
    />
  )
}
