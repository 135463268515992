import React, { useContext } from 'react'
import { FormLabel } from '../../components/FormLabel'
import { FormControl } from '../../components/FormControl'
import { Select as Select2 } from 'chakra-react-select'
import { useGetOfferOtherFields } from '../../model/api/offerOtherFields/useGetOfferOtherFields'

export interface InsuredBranchesProps {
  values: any
  setValues: (values: any) => void
}

export const InsuredBranches = ({
  values,
  setValues,
}: InsuredBranchesProps) => {
  const offerFields = useGetOfferOtherFields().data?.data;
  const offerFieldsSelect = offerFields?.filter(offerFieldItem => {
    return offerFieldItem.id.toString() !== values.business?.field?.id.toString()
  }).map((offerFieldItem) => ({
    label: offerFieldItem.name,
    value: offerFieldItem.id,
  }))

  let insuredBranches = (values.business.otherFields ?? []);
  insuredBranches = insuredBranches.map((offerFieldItem: any) => ({
    label: offerFieldItem.name ?? offerFieldItem.label,
    value: offerFieldItem.id ?? offerFieldItem.value
  }))


  return (
    <>
      <FormControl>
        <FormLabel>
          Obory podnikání
        </FormLabel>
        <Select2
          placeholder="Vyberte obor…"
          onChange={(option) => {
            setValues({
              ...values,
              business: {
                ...values.business,
                otherFields: option
              }
            })
          }}
          value={insuredBranches}
          options={offerFieldsSelect}
          isClearable={true}
          isMulti={true}
        />
      </FormControl>
    </>
  )
}
