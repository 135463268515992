import { Badge } from '@chakra-ui/react'
import { IProfile } from '../../../data/profile'

export const UserInsuranceAllowed = ({ allowed }: { allowed: IProfile['insuranceAllowed'] }) => {
  let label = ''
  let colorScheme = ''

  switch (allowed) {
    case true:
      label = 'Pojištění povoleno'
      colorScheme = 'green'
      break
    case false:
      label = 'Pojištění zakázáno'
      colorScheme = 'red'
      break
  }

  return <Badge colorScheme={colorScheme}>{label}</Badge>
}
