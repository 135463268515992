import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { ILoginData } from '../../interfaces/entities/ILoginData'

export const usePostLogin = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
) => {
  return useMutation((loginData: ILoginData) => api.post(ApiRouteEnum.LOGIN, loginData), {
    mutationKey: [ApiRouteEnum.LOGIN],
    onSuccess: (data) => {
      successCallBack(data.data)
    },
    onError: (error) => errorCallBack(error),
  })
}
