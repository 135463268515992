import { Box, createStylesContext, useMultiStyleConfig } from '@chakra-ui/react'

import { Container } from '../components/Container'
import { NewInsuranceLink } from './NewInsuranceLink'
import { Menu } from './Menu'
import { MobileMenu } from './MobileMenu'
import { AppSwitcher } from './AppSwitcher'
import { AppAlert } from './AppAlert'
import { alerts } from '../data/alerts'

interface HeaderProps {
  variant?: String
}

export const Header = ({ variant }: HeaderProps) => {
  const styles = useMultiStyleConfig('Header', { variant })
  const [StylesProvider] = createStylesContext('Component')

  return (
    <>
      {alerts &&
        alerts.length > 0 &&
        alerts.map((alert) => {
          return <AppAlert key={alert.title} {...alert} />
        })}

      <Box __css={styles.header}>
        <StylesProvider value={styles}>
          <Container>
            <Box __css={styles.content} as="nav">
              <Box __css={styles.logo}>
                <AppSwitcher />
              </Box>

              <Menu />
              <NewInsuranceLink />
              <MobileMenu />
            </Box>
          </Container>
        </StylesProvider>
      </Box>
    </>
  )
}
