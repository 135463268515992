import { Box, Heading, Table, Tbody, Text, Tr } from '@chakra-ui/react'
import { TableWrapper } from '../../../components/TableWrapper'
import { BusinessNumber } from '../../../model/interfaces/entities/IOrganizationEditFormValues'

export const NumbersWrapper = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ display: 'grid', gap: 4 }}>{children}</Box>
}

export const NumbersTitle = ({ children }: { children: React.ReactNode }) => {
  return <Heading sx={{ fontSize: 'xl', fontWeight: 'normal' }}>{children}</Heading>
}

export const UserNumbersTable = ({ rows }: { rows: Array<BusinessNumber> }) => {
  return (
    <TableWrapper>
      <Table>
        <Tbody>
          {rows &&
            rows.map((row) => {
              return (
                <Tr key={row.company.id}>
                  <td>
                    <Text sx={{ fontWeight: 'medium' }}>{row.company.name}</Text>
                  </td>
                  <td>{row.number}</td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>
    </TableWrapper>
  )
}
