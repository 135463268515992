import { Icon } from '@chakra-ui/react'

export const Gastro = (props: any) => (
  <Icon boxSize={props.boxSize || '20px'} {...props} viewBox="0 0 20 20" fill="none">
    <path
      d="M9.5 3.00001C9.61703 2.99997 9.73036 3.04098 9.82026 3.1159C9.91016 3.19081 9.97094 3.29489 9.992 3.41001L10 3.50001V6.50001C10.0001 7.07633 9.80111 7.635 9.43665 8.08146C9.0722 8.52792 8.56468 8.83474 8 8.95001V16.5C8.00023 16.625 7.95367 16.7455 7.86949 16.8378C7.78531 16.9301 7.66961 16.9876 7.54518 16.9989C7.42074 17.0102 7.29659 16.9745 7.19717 16.8988C7.09775 16.8231 7.03026 16.713 7.008 16.59L7 16.5V8.95001C6.46417 8.84042 5.97902 8.5582 5.61888 8.14659C5.25874 7.73497 5.04346 7.21665 5.006 6.67101L5 6.50001V3.50001C4.99977 3.37506 5.04633 3.25456 5.13051 3.16222C5.21469 3.06989 5.33039 3.01242 5.45482 3.00113C5.57926 2.98984 5.70341 3.02555 5.80283 3.10123C5.90225 3.1769 5.96974 3.28706 5.992 3.41001L6 3.50001V6.50001C5.99984 6.81033 6.09593 7.11307 6.27503 7.3665C6.45413 7.61992 6.70742 7.81156 7 7.91501V3.50001C6.99977 3.37506 7.04633 3.25456 7.13051 3.16222C7.21469 3.06989 7.33039 3.01242 7.45482 3.00113C7.57926 2.98984 7.70341 3.02555 7.80283 3.10123C7.90225 3.1769 7.96974 3.28706 7.992 3.41001L8 3.50001V7.91401C8.538 7.72401 8.935 7.23601 8.993 6.64901L9 6.50001V3.50001C9 3.3674 9.05268 3.24022 9.14645 3.14645C9.24022 3.05268 9.36739 3.00001 9.5 3.00001ZM14.5 3.00001C14.617 2.99997 14.7304 3.04098 14.8203 3.1159C14.9102 3.19081 14.9709 3.29489 14.992 3.41001L15 3.50001V16.5C15.0002 16.625 14.9537 16.7455 14.8695 16.8378C14.7853 16.9301 14.6696 16.9876 14.5452 16.9989C14.4207 17.0102 14.2966 16.9745 14.1972 16.8988C14.0977 16.8231 14.0303 16.713 14.008 16.59L14 16.5V11H12.5C12.383 11 12.2696 10.959 12.1797 10.8841C12.0898 10.8092 12.0291 10.7051 12.008 10.59L12 10.5V5.50001C12 4.83696 12.2634 4.20108 12.7322 3.73224C13.2011 3.2634 13.837 3.00001 14.5 3.00001ZM14 10V4.08501C13.462 4.27501 13.065 4.76301 13.007 5.35101L13 5.50001V10H14V4.08501V10Z"
      fill="currentColor"
    />
  </Icon>
)

export const GastroString = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 3.00001C9.61703 2.99997 9.73036 3.04098 9.82026 3.1159C9.91016 3.19081 9.97094 3.29489 9.992 3.41001L10 3.50001V6.50001C10.0001 7.07633 9.80111 7.635 9.43665 8.08146C9.0722 8.52792 8.56468 8.83474 8 8.95001V16.5C8.00023 16.625 7.95367 16.7455 7.86949 16.8378C7.78531 16.9301 7.66961 16.9876 7.54518 16.9989C7.42074 17.0102 7.29659 16.9745 7.19717 16.8988C7.09775 16.8231 7.03026 16.713 7.008 16.59L7 16.5V8.95001C6.46417 8.84042 5.97902 8.5582 5.61888 8.14659C5.25874 7.73497 5.04346 7.21665 5.006 6.67101L5 6.50001V3.50001C4.99977 3.37506 5.04633 3.25456 5.13051 3.16222C5.21469 3.06989 5.33039 3.01242 5.45482 3.00113C5.57926 2.98984 5.70341 3.02555 5.80283 3.10123C5.90225 3.1769 5.96974 3.28706 5.992 3.41001L6 3.50001V6.50001C5.99984 6.81033 6.09593 7.11307 6.27503 7.3665C6.45413 7.61992 6.70742 7.81156 7 7.91501V3.50001C6.99977 3.37506 7.04633 3.25456 7.13051 3.16222C7.21469 3.06989 7.33039 3.01242 7.45482 3.00113C7.57926 2.98984 7.70341 3.02555 7.80283 3.10123C7.90225 3.1769 7.96974 3.28706 7.992 3.41001L8 3.50001V7.91401C8.538 7.72401 8.935 7.23601 8.993 6.64901L9 6.50001V3.50001C9 3.3674 9.05268 3.24022 9.14645 3.14645C9.24022 3.05268 9.36739 3.00001 9.5 3.00001ZM14.5 3.00001C14.617 2.99997 14.7304 3.04098 14.8203 3.1159C14.9102 3.19081 14.9709 3.29489 14.992 3.41001L15 3.50001V16.5C15.0002 16.625 14.9537 16.7455 14.8695 16.8378C14.7853 16.9301 14.6696 16.9876 14.5452 16.9989C14.4207 17.0102 14.2966 16.9745 14.1972 16.8988C14.0977 16.8231 14.0303 16.713 14.008 16.59L14 16.5V11H12.5C12.383 11 12.2696 10.959 12.1797 10.8841C12.0898 10.8092 12.0291 10.7051 12.008 10.59L12 10.5V5.50001C12 4.83696 12.2634 4.20108 12.7322 3.73224C13.2011 3.2634 13.837 3.00001 14.5 3.00001ZM14 10V4.08501C13.462 4.27501 13.065 4.76301 13.007 5.35101L13 5.50001V10H14V4.08501V10Z" fill="#EE7400"/>
</svg>
`
