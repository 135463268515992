import { Container } from '../../../components/Container'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { MeetingRecordProperty } from '../../../containers/MeetingRecordProperty'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../components/Loading'
import { InsuranceInfoSme } from './InsuranceInfoSme'
import { useFormStepGuard } from '../../../model/utils/formHelpers'
import { FormIsDisabledContext } from '../../../model/contexts/FormIsDisabledContext'
import { useOfferOrContractData } from '../../../model/hooks/useOfferOrContractData'

export const Step5: React.FC<{ viewOnly?: boolean }> = ({ viewOnly = false }) => {
  const { id } = useParams<'id'>()
  const successCallBack = () => {
    return
  }

  const errorCallBack = (error: any) => {
    console.error(error)
  }
  const [data, isLoading] = useOfferOrContractData(id, successCallBack, errorCallBack, viewOnly)

  useFormStepGuard(5, data?.data)

  return (
    <>
      {data ? (
        <Section>
          <Container>
            <StepsContent>
              <Steps icon="briefcase" currentStep={5} viewOnly={viewOnly} passed={data?.data.step ?? 0} />

              <InsuranceInfoSme
                emailAndPrintAvailable={true}
                offer={data.data}
                cloneAvailable={true}
              />
              <FormIsDisabledContext.Provider value={viewOnly}>
                <MeetingRecordProperty viewOnly={viewOnly} />
              </FormIsDisabledContext.Provider>
            </StepsContent>
          </Container>
        </Section>
      ) : (
        <Loading />
      )}
    </>
  )
}
