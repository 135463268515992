import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { INewsNewItemFormValues } from '../../interfaces/entities/INews'

export const usePatchNews = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id?: string,
) => {
  const url = ApiRouteEnum.NEWS + `/${id}`

  return useMutation((newsData: INewsNewItemFormValues) => api.patch(url, newsData), {
    mutationKey: [ApiRouteEnum.LOGIN],
    onSuccess: (data) => {
      successCallBack(data.data)
    },
    onError: (error) => errorCallBack(error),
  })
}
