import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '../api'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const useCopyItem = (
  successCallback: () => void,
  errorCallback: (data: any) => void,
  offerId?: string,
  itemId?: string,
) => {
  const queryClient = useQueryClient()

  return useMutation(() => api.post(`${ApiRouteEnum.OFFERS}/${offerId}/step3/${itemId}/copy`), {
    mutationKey: [`${ApiRouteEnum.OFFERS}/${offerId}/step3`],
    onSuccess: () => {
      queryClient.invalidateQueries([ApiRouteEnum.OFFERS])
      successCallback()
    },
    onError: (error) => errorCallback(error),
  })
}
