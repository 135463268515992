import { Heading } from '@chakra-ui/react'
import { IOrganization } from '../../data/organizations'

import { OrganizationEditForm } from '../OrganizationEditForm'
import { PageContent } from '../PageContent'

export const OrganizationEdit = ({ organization }: { organization?: IOrganization }) => {
  return (
    <PageContent>
      <Heading>Upravit organizaci</Heading>

      {organization && <OrganizationEditForm />}
    </PageContent>
  )
}
