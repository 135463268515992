import { Box, Heading, HeadingProps, Text, useToast } from '@chakra-ui/react'

import { Card, CardBody } from '../../components/Card'
import { IOrganization, IOrganizationContact } from '../../data/organizations'
import { Building, CheckCircle, Email, Mobile } from '../../icons'
import { UserNumbersTable } from '../profile/containers/NumbersTable'
import { UserInfo } from '../profile/containers/UserInfo'
import { OrganizationDiscounts } from './OrganizationDiscounts'
import { OrganizationLogoUploadForm } from './OrganizationLogoUploadForm'
import { OrganizationUserList } from './OrganizationUserList'
import { Loading } from '../../components/Loading'
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useGetOrganizationsId } from '../../model/api/organization/useGetOrganizationId'
import { BusinessNumber } from '../../model/interfaces/entities/IOrganizationEditFormValues'
import { OrganizationChildrenList } from './OrganizationChildrenList'
import { OrganizationTypeEnum } from '../../model/enums/OrganizationTypeEnum'
import {
  getOrganizationTypTranslated,
  organizationTypeSupportsSubs,
} from '../../model/utils/organizationUtils'
import moment from 'moment'
import { LastChangeInformation } from '../../components/LastChangeInformation'

export const OrgInfoSectionHeading = (props: HeadingProps) => (
  <Heading sx={{ fontSize: 'xl', fontWeight: 'normal' }} {...props} />
)

const OrganizationContactInfo = ({ contact }: { contact: IOrganizationContact }) => {
  const { person, email, phone } = contact

  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      <OrgInfoSectionHeading>Kontaktní osoba</OrgInfoSectionHeading>

      <Box sx={{ display: 'grid', gap: 2 }}>
        {person && <Text sx={{ color: 'primary.700', fontWeight: 'medium' }}>{person}</Text>}

        {email && (
          <UserInfo>
            <Email color="secondary.500" />
            {email}
          </UserInfo>
        )}

        {phone && (
          <UserInfo>
            <Mobile color="secondary.500" />
            {phone}
          </UserInfo>
        )}
      </Box>
    </Box>
  )
}

const OrganizationCompanyInfo = ({
  address,
  businessId,
  type,
}: {
  address?: string
  businessId?: string
  type?: OrganizationTypeEnum
}) => {
  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      <OrgInfoSectionHeading>Společnost</OrgInfoSectionHeading>

      {address && (
        <UserInfo>
          <Building color="secondary.500" />
          {address}
        </UserInfo>
      )}

      {businessId && (
        <UserInfo>
          <CheckCircle color="secondary.500" />
          {`IČO: ${businessId}`}
        </UserInfo>
      )}

      {type && (
        <UserInfo>
          <Building color="secondary.500" />
          Typ: {getOrganizationTypTranslated(type)}
        </UserInfo>
      )}
    </Box>
  )
}

const OrganizationBusinessNumbers = ({
  businessNumbers,
}: {
  businessNumbers?: Array<BusinessNumber>
}) => {
  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      <OrgInfoSectionHeading>Ziskatelská čísla</OrgInfoSectionHeading>

      {businessNumbers && <UserNumbersTable rows={businessNumbers} />}
    </Box>
  )
}

export const OrganizationDetail = ({ organization }: { organization: IOrganization }) => {
  const toastMessage = useToast()
  const { id } = useParams<'id'>()
  const { data, isLoading, isError, error } = useGetOrganizationsId(id)
  const { street, streetNumber, zip, city } = data?.data || {}
  const address = `${street} ${streetNumber}, ${zip} ${city}`
  const location = useLocation()

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Card>
          <CardBody sx={{ p: 4 }}>
            <Box
              sx={{
                display: 'grid',
                gap: 8,
                gridTemplateColumns: { base: '1fr', md: '1fr 1fr' },
              }}
            >
              <Box sx={{ display: 'grid', gap: 12 }}>
                <OrganizationCompanyInfo
                  address={address}
                  businessId={data?.data.ino}
                  type={data?.data.type}
                />

                <OrganizationLogoUploadForm organization={data?.data} />

                {data?.data.updatedBy && moment(data?.data.updatedAt).isValid() && (
                  <LastChangeInformation
                    updatedBy={data?.data.updatedBy}
                    updatedAt={data?.data.updatedAt}
                  />
                )}
              </Box>
              <Box>
                {data?.data.personalNumbers && (
                  <OrganizationBusinessNumbers businessNumbers={data?.data.personalNumbers} />
                )}
              </Box>
            </Box>
          </CardBody>
        </Card>
      )}
      <OrganizationUserList key={`${location.pathname}-user-list`} />

      {data?.data && organizationTypeSupportsSubs(data?.data.type) && (
        <OrganizationChildrenList key={`${location.pathname}-children-list`} />
      )}

      <OrganizationDiscounts />
    </>
  )
}
