import { useNavigate, useParams } from 'react-router-dom'

import { Container } from '../../../components/Container'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { Step1Form } from '../../../containers/sme/Step1Form'
import { Loading } from '../../../components/Loading'
import { IOffersItem } from '../../../model/interfaces/entities/IOffers'
import { FormIsDisabledContext } from '../../../model/contexts/FormIsDisabledContext'
import { useOfferOrContractData } from '../../../model/hooks/useOfferOrContractData'
import { DefaultErrorCallback } from '../../../model/api/DefaultErrorCallback'

export const EditStep1: React.FC<{ viewOnly?: boolean; cloneAvailable?: boolean }> = ({
  viewOnly = false,
}) => {
  const navigate = useNavigate()
  const { id } = useParams<'id'>()

  const handleSubmit = (response: IOffersItem) => {
    navigate(`/sme/wizard/edit/${response.id}/2`)
  }

  const successCallBack = (resp: IOffersItem) => {}

  const [data, isLoading] = useOfferOrContractData(
    id,
    successCallBack,
    DefaultErrorCallback,
    viewOnly,
  )

  return (
    <Section>
      <Container>
        <StepsContent>
          <Steps icon="briefcase" currentStep={1} viewOnly={viewOnly} passed={data?.data.step ?? 0} />
          {isLoading || !data ? (
            <Loading />
          ) : (
            <FormIsDisabledContext.Provider value={viewOnly}>
              <Step1Form
                type={'edit'}
                onSubmit={handleSubmit}
                initialBusinessValues={{
                  oldProfession: data.data.business.oldProfession,
                  oldField: data.data.business.oldField,
                  profession: data.data.business.profession.id,
                  field: data.data.business.field.id,
                }}
                initialInsuranceValues={{
                  ...data.data.insurance,
                  companies: data.data.insurance.companies.map((e) => e.id),
                }}
                initialPlaceValues={data.data.places}
                initialAdditionalValues={{
                  ...data.data.additional,
                  region: data.data.additional.region || 'cr',
                }}
                initialApplicantValues={{
                  ...data.data.applicant,
                  mobilePhone: data.data.applicant.mobilePhone || '',
                  email: data.data.applicant.email || '',
                  identificationNumber: data.data.applicant.identificationNumber || '',
                }}
                viewOnly={viewOnly}
                cloneAvailable={true}
              />
            </FormIsDisabledContext.Provider>
          )}
        </StepsContent>
      </Container>
    </Section>
  )
}
