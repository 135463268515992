import { Box, Select } from '@chakra-ui/react'
import type { FieldValues } from 'react-hook-form'

import { phonePrefixes } from '../utils/phonePrefixes'
import { InputGroup } from './InputGroup'

export const PhoneInputGroup = ({ select, input }: { select: FieldValues; input: FieldValues }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        placeContent: 'start',
        placeItems: 'start',
        gap: 2,
      }}
    >
      <Select {...select}>
        {phonePrefixes &&
          phonePrefixes.map((prefix) => {
            return (
              <option key={prefix} value={prefix}>
                {prefix}
              </option>
            )
          })}
      </Select>

      <InputGroup
        inputMode="tel"
        placeholder="Telefonní číslo"
        pattern="[0-9\ ]+"
        htmlSize={10}
        {...input}
      />
    </Box>
  )
}
