import React from 'react'
import * as Yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { InputGroup } from '../components/InputGroup'
import { IS_REQUIRED } from '../model/constants/validationMessages'
import { useSetPassword } from '../model/api/user/useSetNewPassword'

export interface INewPasswordFormValues {
  password: string
  passwordConfirmation: string
}

const initialNewPasswordFormValues: INewPasswordFormValues = {
  password: '',
  passwordConfirmation: '',
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(IS_REQUIRED)
    .min(8, 'Heslo musí mít alespoň 8 znaků')
    .matches(/[a-z]/, 'Heslo musí obsahovat kombinaci velkých a malých znaků')
    .matches(/[A-Z]/, 'Heslo musí obsahovat kombinaci velkých a malých znaků')
    .matches(/[\W_]/, 'Heslo musí obsahovat 1 nealfanumerický znak')
    .matches(/\d.*\d/, 'Heslo musí obsahovat nejméně 2 čísla'),
  passwordConfirmation: Yup.string()
    .required(IS_REQUIRED)
    .oneOf([Yup.ref('password'), null], 'Zadané hesla se musí shodovat'),
})

export const NewPasswordForm: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const hash = searchParams.get('hash')

  const { mutate: setPassword, isLoading: isSettingPassword } = useSetPassword(
    () => {
      toast({
        title: 'Heslo změněno',
        description: 'Můžete se přihlásit',
        status: 'success',
        position: 'top',
        duration: 5000,
      })

      navigate('/auth/login')
    },
    (error) => {
      toast({
        title: 'Nepodařilo se uložit',
        description: error?.response?.data?.error,
        status: 'error',
        isClosable: true,
        position: 'top',
        duration: 5000,
      })
    },
  )

  if (!hash) {
    return <Text color="red.700">Neplatný odkaz pro obnovení hesla. Zkuste to prosím znovu.</Text>
  }

  const onSubmit = async (values: INewPasswordFormValues) => {
    setPassword({ password: values.password, hash: hash })
  }

  return (
    <Formik
      initialValues={initialNewPasswordFormValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, errors, touched, values }) => (
        <Form>
          <Grid sx={{ gap: 8 }}>
            <Heading as="h2" size="md">
              Nové heslo pro přihlášení
            </Heading>

            <FormControl variant="fullWidth" isInvalid={touched.password && !!errors.password}>
              <Grid sx={{ gridTemplateColumns: 'auto auto', justifyContent: 'space-between' }}>
                <FormLabel htmlFor="password">Heslo</FormLabel>
              </Grid>
              <InputGroup
                id="password"
                type="password"
                placeholder="Vaše přístupové heslo"
                sx={{ w: '100%' }}
                selectAllonFocus
                onChange={handleChange}
                value={values.password}
                autoComplete="new-password"
              />
              {touched.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
            </FormControl>

            <FormControl
              variant="fullWidth"
              isInvalid={touched.passwordConfirmation && !!errors.passwordConfirmation}
            >
              <Grid sx={{ gridTemplateColumns: 'auto auto', justifyContent: 'space-between' }}>
                <FormLabel htmlFor="passwordConfirmation">Heslo pro kontrolu</FormLabel>
              </Grid>
              <InputGroup
                id="passwordConfirmation"
                type="password"
                placeholder="Vaše přístupové heslo znovu"
                sx={{ w: '100%' }}
                selectAllonFocus
                onChange={handleChange}
                value={values.passwordConfirmation}
              />
              {touched.passwordConfirmation && (
                <FormErrorMessage>{errors.passwordConfirmation}</FormErrorMessage>
              )}
            </FormControl>

            <Grid
              sx={{
                width: '100%',
                gridTemplateColumns: 'auto auto',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button colorScheme="primary" type="submit" isLoading={isSettingPassword}>
                {isSettingPassword ? 'Odesílám' : 'Odeslat'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
