import { Icon } from '@chakra-ui/react'

export const Pdf = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M6.5 11C6.36739 11 6.24021 11.0527 6.14645 11.1464C6.05268 11.2402 6 11.3674 6 11.5V13.5C6 13.6326 6.05268 13.7598 6.14645 13.8536C6.24021 13.9473 6.36739 14 6.5 14C6.63261 14 6.75979 13.9473 6.85355 13.8536C6.94732 13.7598 7 13.6326 7 13.5V13.334H7.333C7.64251 13.334 7.93934 13.211 8.15819 12.9922C8.37705 12.7733 8.5 12.4765 8.5 12.167C8.5 11.8575 8.37705 11.5607 8.15819 11.3418C7.93934 11.123 7.64251 11 7.333 11H6.5ZM7.333 12.334H7V12H7.333C7.37729 12 7.41977 12.0176 7.45109 12.0489C7.48241 12.0802 7.5 12.1227 7.5 12.167C7.5 12.2113 7.48241 12.2538 7.45109 12.2851C7.41977 12.3164 7.37729 12.334 7.333 12.334V12.334Z" fill="currentColor"/>
<path d="M12 11.5C12 11.3674 12.0527 11.2402 12.1464 11.1464C12.2402 11.0527 12.3674 11 12.5 11H13.5C13.6326 11 13.7598 11.0527 13.8536 11.1464C13.9473 11.2402 14 11.3674 14 11.5C14 11.6326 13.9473 11.7598 13.8536 11.8536C13.7598 11.9473 13.6326 12 13.5 12H13V12.335H13.5C13.6326 12.335 13.7598 12.3877 13.8536 12.4814C13.9473 12.5752 14 12.7024 14 12.835C14 12.9676 13.9473 13.0948 13.8536 13.1886C13.7598 13.2823 13.6326 13.335 13.5 13.335H13V13.499C13.0001 13.5647 12.9873 13.6297 12.9623 13.6904C12.9373 13.7511 12.9006 13.8063 12.8543 13.8528C12.7607 13.9468 12.6336 13.9997 12.501 14C12.3684 14.0003 12.2411 13.9478 12.1472 13.8543C12.0532 13.7607 12.0003 13.6336 12 13.501V11.5Z" fill="currentColor"/>
<path d="M9.499 11C9.36639 11 9.23921 11.0527 9.14545 11.1464C9.05168 11.2402 8.999 11.3674 8.999 11.5V13.5C8.999 13.6326 9.05168 13.7598 9.14545 13.8536C9.23921 13.9473 9.36639 14 9.499 14H10C10.3978 14 10.7794 13.842 11.0607 13.5607C11.342 13.2794 11.5 12.8978 11.5 12.5C11.5 12.1022 11.342 11.7206 11.0607 11.4393C10.7794 11.158 10.3978 11 10 11H9.5H9.499ZM9.999 13V12H10C10.1326 12 10.2598 12.0527 10.3536 12.1464C10.4473 12.2402 10.5 12.3674 10.5 12.5C10.5 12.6326 10.4473 12.7598 10.3536 12.8536C10.2598 12.9473 10.1326 13 10 13H9.999Z" fill="currentColor"/>
<path d="M4 4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H10.586C10.9837 2.00035 11.365 2.15862 11.646 2.44L15.561 6.354C15.8424 6.63503 16.0006 7.01631 16.001 7.414V9.082C16.293 9.18572 16.5458 9.37735 16.7245 9.63055C16.9032 9.88375 16.9991 10.1861 16.999 10.496V14.499C16.999 14.809 16.903 15.1115 16.7241 15.3647C16.5452 15.6179 16.2922 15.8094 16 15.913V16C16 16.5304 15.7893 17.0391 15.4142 17.4142C15.0391 17.7893 14.5304 18 14 18H6C5.46957 18 4.96086 17.7893 4.58579 17.4142C4.21071 17.0391 4 16.5304 4 16V15.913C3.70777 15.8094 3.45481 15.6179 3.27591 15.3647C3.09701 15.1115 3.00097 14.809 3.001 14.499V10.496C3.00104 10.1858 3.09723 9.88333 3.27632 9.6301C3.4554 9.37687 3.70858 9.18538 4.001 9.082V4H4ZM15 8H11.5C11.1022 8 10.7206 7.84196 10.4393 7.56066C10.158 7.27936 10 6.89782 10 6.5V3H6C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V8.996H15V8ZM5 15.999C4.99987 16.1304 5.02564 16.2605 5.07583 16.382C5.12603 16.5034 5.19967 16.6138 5.29254 16.7068C5.38541 16.7997 5.49569 16.8735 5.61708 16.9238C5.73848 16.9741 5.86859 17 6 17H14C14.1314 17 14.2615 16.9741 14.3829 16.9238C14.5043 16.8735 14.6146 16.7997 14.7075 16.7068C14.8003 16.6138 14.874 16.5034 14.9242 16.382C14.9744 16.2605 15.0001 16.1304 15 15.999H5ZM11 3.207V6.5C11 6.63261 11.0527 6.75979 11.1464 6.85355C11.2402 6.94732 11.3674 7 11.5 7H14.793L11 3.207V3.207ZM4.501 9.996C4.36839 9.996 4.24121 10.0487 4.14745 10.1424C4.05368 10.2362 4.001 10.3634 4.001 10.496V14.499C4.001 14.6316 4.05368 14.7588 4.14745 14.8526C4.24121 14.9463 4.36839 14.999 4.501 14.999H15.5C15.6326 14.999 15.7598 14.9463 15.8536 14.8526C15.9473 14.7588 16 14.6316 16 14.499V10.496C16 10.3634 15.9473 10.2362 15.8536 10.1424C15.7598 10.0487 15.6326 9.996 15.5 9.996H4.5H4.501Z" fill="currentColor"/>
	</Icon>
)


export const PdfString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.5 11C6.36739 11 6.24021 11.0527 6.14645 11.1464C6.05268 11.2402 6 11.3674 6 11.5V13.5C6 13.6326 6.05268 13.7598 6.14645 13.8536C6.24021 13.9473 6.36739 14 6.5 14C6.63261 14 6.75979 13.9473 6.85355 13.8536C6.94732 13.7598 7 13.6326 7 13.5V13.334H7.333C7.64251 13.334 7.93934 13.211 8.15819 12.9922C8.37705 12.7733 8.5 12.4765 8.5 12.167C8.5 11.8575 8.37705 11.5607 8.15819 11.3418C7.93934 11.123 7.64251 11 7.333 11H6.5ZM7.333 12.334H7V12H7.333C7.37729 12 7.41977 12.0176 7.45109 12.0489C7.48241 12.0802 7.5 12.1227 7.5 12.167C7.5 12.2113 7.48241 12.2538 7.45109 12.2851C7.41977 12.3164 7.37729 12.334 7.333 12.334V12.334Z" fill="#003D66"/>
<path d="M12 11.5C12 11.3674 12.0527 11.2402 12.1464 11.1464C12.2402 11.0527 12.3674 11 12.5 11H13.5C13.6326 11 13.7598 11.0527 13.8536 11.1464C13.9473 11.2402 14 11.3674 14 11.5C14 11.6326 13.9473 11.7598 13.8536 11.8536C13.7598 11.9473 13.6326 12 13.5 12H13V12.335H13.5C13.6326 12.335 13.7598 12.3877 13.8536 12.4814C13.9473 12.5752 14 12.7024 14 12.835C14 12.9676 13.9473 13.0948 13.8536 13.1886C13.7598 13.2823 13.6326 13.335 13.5 13.335H13V13.499C13.0001 13.5647 12.9873 13.6297 12.9623 13.6904C12.9373 13.7511 12.9006 13.8063 12.8543 13.8528C12.7607 13.9468 12.6336 13.9997 12.501 14C12.3684 14.0003 12.2411 13.9478 12.1472 13.8543C12.0532 13.7607 12.0003 13.6336 12 13.501V11.5Z" fill="#003D66"/>
<path d="M9.499 11C9.36639 11 9.23921 11.0527 9.14545 11.1464C9.05168 11.2402 8.999 11.3674 8.999 11.5V13.5C8.999 13.6326 9.05168 13.7598 9.14545 13.8536C9.23921 13.9473 9.36639 14 9.499 14H10C10.3978 14 10.7794 13.842 11.0607 13.5607C11.342 13.2794 11.5 12.8978 11.5 12.5C11.5 12.1022 11.342 11.7206 11.0607 11.4393C10.7794 11.158 10.3978 11 10 11H9.5H9.499ZM9.999 13V12H10C10.1326 12 10.2598 12.0527 10.3536 12.1464C10.4473 12.2402 10.5 12.3674 10.5 12.5C10.5 12.6326 10.4473 12.7598 10.3536 12.8536C10.2598 12.9473 10.1326 13 10 13H9.999Z" fill="#003D66"/>
<path d="M4 4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H10.586C10.9837 2.00035 11.365 2.15862 11.646 2.44L15.561 6.354C15.8424 6.63503 16.0006 7.01631 16.001 7.414V9.082C16.293 9.18572 16.5458 9.37735 16.7245 9.63055C16.9032 9.88375 16.9991 10.1861 16.999 10.496V14.499C16.999 14.809 16.903 15.1115 16.7241 15.3647C16.5452 15.6179 16.2922 15.8094 16 15.913V16C16 16.5304 15.7893 17.0391 15.4142 17.4142C15.0391 17.7893 14.5304 18 14 18H6C5.46957 18 4.96086 17.7893 4.58579 17.4142C4.21071 17.0391 4 16.5304 4 16V15.913C3.70777 15.8094 3.45481 15.6179 3.27591 15.3647C3.09701 15.1115 3.00097 14.809 3.001 14.499V10.496C3.00104 10.1858 3.09723 9.88333 3.27632 9.6301C3.4554 9.37687 3.70858 9.18538 4.001 9.082V4H4ZM15 8H11.5C11.1022 8 10.7206 7.84196 10.4393 7.56066C10.158 7.27936 10 6.89782 10 6.5V3H6C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V8.996H15V8ZM5 15.999C4.99987 16.1304 5.02564 16.2605 5.07583 16.382C5.12603 16.5034 5.19967 16.6138 5.29254 16.7068C5.38541 16.7997 5.49569 16.8735 5.61708 16.9238C5.73848 16.9741 5.86859 17 6 17H14C14.1314 17 14.2615 16.9741 14.3829 16.9238C14.5043 16.8735 14.6146 16.7997 14.7075 16.7068C14.8003 16.6138 14.874 16.5034 14.9242 16.382C14.9744 16.2605 15.0001 16.1304 15 15.999H5ZM11 3.207V6.5C11 6.63261 11.0527 6.75979 11.1464 6.85355C11.2402 6.94732 11.3674 7 11.5 7H14.793L11 3.207V3.207ZM4.501 9.996C4.36839 9.996 4.24121 10.0487 4.14745 10.1424C4.05368 10.2362 4.001 10.3634 4.001 10.496V14.499C4.001 14.6316 4.05368 14.7588 4.14745 14.8526C4.24121 14.9463 4.36839 14.999 4.501 14.999H15.5C15.6326 14.999 15.7598 14.9463 15.8536 14.8526C15.9473 14.7588 16 14.6316 16 14.499V10.496C16 10.3634 15.9473 10.2362 15.8536 10.1424C15.7598 10.0487 15.6326 9.996 15.5 9.996H4.5H4.501Z" fill="#003D66"/>
</svg>
`;