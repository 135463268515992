import {
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  IconButton,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { Card, CardBody } from '../components/Card'
import { FileInput } from '../components/FileInput'
import { FormLabel } from '../components/FormLabel'
import { InputGroup } from '../components/InputGroup'
import { TextareaGroup } from '../components/TextareaGroup'
import { Trash } from '../icons'
import { usePostHelpDesk } from '../model/api/helpDesk/usePostHelpDesk'
import {
  IHelpDeskFile,
  IHelpDeskFormValues,
} from '../model/interfaces/entities/IHelpDeskFormValues'
import { helpDeskInitialValues } from '../utils/HelpDeskInitialValues'
import { useGetHelpDeskQuestionTypes } from '../model/api/helpDesk/useGetHelpDeskQuestionTypes'
import { IS_REQUIRED } from '../model/constants/validationMessages'

export const HelpDesk = ({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean
  onOpen: Function
  onClose: Function
}) => {
  const [fileBlob, setFileBlob] = useState<IHelpDeskFile>()
  const toastMessage = useToast()

  const validationSchema = Yup.object({
    name: Yup.string().required(IS_REQUIRED),
    email: Yup.string().required(IS_REQUIRED),
    phone: Yup.string().required(IS_REQUIRED),
    subject: Yup.string().required(IS_REQUIRED),
    questionType: Yup.number().required(IS_REQUIRED),
    message: Yup.string().required(IS_REQUIRED),
  })

  const successCallBack: (data: IHelpDeskFormValues) => void = (data: IHelpDeskFormValues) => {
    toastMessage({
      title: 'Žádost na HelpDesk odeslána',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
    onClose()
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Došlo k chybě',
      description: error + '',
      status: 'error',
      isClosable: true,
    })
  }

  const { data: questionTypes } = useGetHelpDeskQuestionTypes()
  const { mutate, isLoading } = usePostHelpDesk(successCallBack, errorCallBack)
  const onSubmitForm: (formValues: IHelpDeskFormValues) => void = (
    formValues: IHelpDeskFormValues,
  ) => {
    formValues.file = fileBlob
    formValues.questionType = Number(formValues.questionType)
    mutate(formValues)
  }

  const imgToBase = async (e: any) => {
    const fileBase = e.currentTarget?.files[0]
    if (!fileBase) return
    if (fileBase.size > 10 * 1024 * 1024) {
      toastMessage({
        title: 'Maximální velikost souboru je 10MB',
        status: 'error',
        isClosable: true,
      })
      return null
    }
    const reader = new FileReader()
    let blob = await fetch(fileBase.objectURL).then((r) => r.blob())
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64 = reader.result
      setFileBlob({ content: base64, filename: fileBase.name })
    }
  }

  return (
    <Card
      sx={{
        display: 'grid',
        position: 'fixed',
        top: 'auto',
        height: '100%',
        maxH: 'calc(100vh - 12rem)',
        right: 4,
        bottom: { base: 40, xl: 24 },
        transform: isOpen ? 'translateX(0)' : 'translateX(200%)',
        transitionProperty: 'all',
        transitionDuration: 'fast',
        boxShadow: '3xl',
        overflow: 'hidden',
      }}
    >
      <CardBody sx={{ height: '100%', overflow: 'auto' }}>
        <Flex as="header" sx={{ mb: 4, alignItems: 'center', justifyContent: 'space-between' }}>
          <Heading as="h2" sx={{ fontSize: 'lg' }}>
            HelpDesk
          </Heading>

          <CloseButton onClick={() => onClose()} />
        </Flex>
        <Formik
          initialValues={helpDeskInitialValues}
          onSubmit={(values) => onSubmitForm(values)}
          validationSchema={validationSchema}
        >
          {({ errors, touched, handleChange }) => (
            <Form>
              <Box sx={{ display: 'grid', gap: 4 }}>
                <FormControl id="name" isInvalid={touched.name && !!errors.name}>
                  <FormLabel htmlFor="name">Jméno a příjmení</FormLabel>

                  <InputGroup
                    id="name"
                    type="text"
                    inputMode="text"
                    autoComplete="name"
                    placeholder="Vaše jméno a příjmení"
                    onChange={handleChange}
                  />
                  {touched.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                </FormControl>

                <FormControl id="email" isInvalid={touched.email && !!errors.email}>
                  <FormLabel htmlFor="email">E-mail</FormLabel>

                  <InputGroup
                    id="email"
                    type="email"
                    inputMode="email"
                    autoComplete="email"
                    placeholder="Váš e-mail"
                    onChange={handleChange}
                  />
                  {touched.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                </FormControl>

                <FormControl id="phone" isInvalid={touched.phone && !!errors.phone}>
                  <FormLabel htmlFor="phone">Telefon</FormLabel>

                  <InputGroup
                    id="phone"
                    type="tel"
                    inputMode="tel"
                    autoComplete="phone"
                    placeholder="Vaše telefonní číslo"
                    onChange={handleChange}
                  />
                  {touched.phone && <FormErrorMessage>{errors.phone}</FormErrorMessage>}
                </FormControl>

                <FormControl id="subject" isInvalid={touched.subject && !!errors.subject}>
                  <FormLabel htmlFor="subject">Předmět</FormLabel>

                  <InputGroup
                    id="subject"
                    name="subject"
                    type="text"
                    inputMode="text"
                    placeholder="Např. Číslo nabídky"
                    onChange={handleChange}
                  />
                  {touched.subject && <FormErrorMessage>{errors.subject}</FormErrorMessage>}
                </FormControl>

                <FormControl
                  variant="fullWidth"
                  id="questionType"
                  isInvalid={touched.questionType && !!errors.questionType}
                >
                  <FormLabel>Typ dotazu</FormLabel>

                  <Select id="questionType" onChange={handleChange} sx={{ w: '100%' }}>
                    <option disabled value="">
                      Vyberte typ dotazu
                    </option>
                    {questionTypes?.data?.map((questionType) => (
                      <option key={questionType.id} value={questionType.id}>
                        {questionType.name}
                      </option>
                    ))}
                  </Select>
                  {touched.questionType && (
                    <FormErrorMessage>{errors.questionType}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  variant="fullWidth"
                  id="message"
                  isInvalid={touched.message && !!errors.message}
                >
                  <FormLabel htmlFor="message">Zpráva</FormLabel>

                  <TextareaGroup
                    id="message"
                    name="message"
                    autoComplete="off"
                    placeholder="Napište text Vaší zprávy"
                    rows={4}
                    onChange={handleChange}
                  />
                  {touched.message && <FormErrorMessage>{errors.message}</FormErrorMessage>}
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="filee">Přílohy</FormLabel>

                  <FileInput id="filee" multiple onChange={imgToBase} />
                </FormControl>

                {fileBlob && (
                  <Box>
                    <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', gap: 4 }}>
                      <Text
                        sx={{
                          maxW: '240px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {fileBlob.filename}
                      </Text>

                      <IconButton
                        colorScheme="white"
                        aria-label="Odebrat soubor"
                        onClick={() => setFileBlob(undefined)}
                      >
                        <Trash color="red.500" width={4} height={4} />
                      </IconButton>
                    </Flex>
                  </Box>
                )}
                <Button colorScheme="primary" type="submit" isLoading={isLoading}>
                  Odeslat zprávu
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}
