import { useDisclosure } from '@chakra-ui/react'
import { NewInsuranceVariantWrapper } from '../carkulka/NewInsuranceVariantWrapper'
import { ExtraInsuranceCar } from '../ExtraInsuranceCar'
import { ExtraInsuranceModal } from '../ExtraInsuranceModal'
import { PageContent } from '../PageContent'
import { PaymentFrequency } from '../PaymentFrequency'
import { OffersBottomToolbar } from './OffersBottomToolbar'

import { OffersCarList } from './OffersCarList'
import { OffersToolbar } from './OffersToolbar'

export const OffersCar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <PageContent>
      <OffersToolbar onSettingsClick={() => onOpen()} />

      <OffersCarList offerId="16834" />

      <OffersBottomToolbar />

      <NewInsuranceVariantWrapper />

      <ExtraInsuranceModal isOpen={isOpen} onClose={onClose}>
        <PaymentFrequency />
        <ExtraInsuranceCar />
      </ExtraInsuranceModal>
    </PageContent>
  )
}
