import { Link, Table, Tbody } from '@chakra-ui/react'

import {
  FormSection,
  FormSectionBody,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { RecapTableRow } from './Recap'
import React from 'react'
import { IOffersItem } from '../../model/interfaces/entities/IOffers'
import { createAddressFromOptionalBits } from '../../model/utils/addressUtils'
import { InsuranceTypeEnum } from '../../model/enums/InsuranceTypeEnum'
import { UserLink } from '../../components/UserLink'

export const ContractRecap: React.FC<{ offer?: IOffersItem }> = ({ offer }) => {
  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>Údaje o smlouvě</FormSectionTitle>
      </FormSectionHeader>

      <FormSectionBody>
        <Table variant="unstyled">
          <Tbody>
            <RecapTableRow col1="Název nabídky" col2={offer?.insurance.name} />
            <RecapTableRow
              col1="Pojistník"
              col2={`${offer?.applicant.title} ${offer?.applicant.firstName} ${offer?.applicant.familyName}`}
            />
            <RecapTableRow
              col1="Adresa"
              col2={createAddressFromOptionalBits(
                offer?.applicant.city,
                offer?.applicant.zip,
                offer?.applicant.street,
                offer?.applicant.streetNumber,
              )}
            />
            <RecapTableRow
              col1="Kontakty"
              col2={
                <>
                  <Link href={`tel:${offer?.applicant.mobilePhone}`}>
                    {offer?.applicant.mobilePhone}
                  </Link>
                  ,<Link href={`mailto:${offer?.applicant.email}`}>{offer?.applicant.email}</Link>
                </>
              }
            />
          </Tbody>
        </Table>

        {offer?.insurance.type?.includes(InsuranceTypeEnum.BUILDING) && (
          <Table variant="unstyled">
            <Tbody>
              <RecapTableRow
                col1="Místo pojištění"
                col2={createAddressFromOptionalBits(
                  offer?.places[0]?.city,
                  offer?.places[0]?.zip,
                  offer?.places[0]?.street,
                  offer?.places[0]?.streetNumber,
                )}
              />
            </Tbody>
          </Table>
        )}

        <Table variant="unstyled">
          <Tbody>
            {offer?.user && (
              <RecapTableRow
                col1="Zpracovatel nabídky"
                col2={<UserLink user={offer?.user} dateCreated={offer?.dateCreated} />}
              />
            )}
            {offer?.contractUser && (
              <RecapTableRow
                col1="Sjednatel smlouvy"
                col2={
                  <UserLink user={offer?.contractUser} dateCreated={offer?.dateCreatedContract} />
                }
              />
            )}
            <RecapTableRow col1="Ziskatelské číslo" col2={offer?.organization.personalNumbers?.find(personalNumber => personalNumber.company.id === offer?.company?.id)?.number ?? ''} />
          </Tbody>
        </Table>
      </FormSectionBody>
    </FormSection>
  )
}
