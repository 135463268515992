import { Box, Button, forwardRef } from '@chakra-ui/react'

import { Upload } from '../icons'

export const FileInput = forwardRef((props: any, ref: any) => {
  const { onChange } = props
  const acceptedFileTypes: string =
    'image/png,image/jpg,image/gif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain'
  return (
    <Box sx={{ display: 'grid', gap: 2 }}>
      <Button as="label" variant="outline">
        <input
          style={{ width: 1, height: 1, padding: 0, margin: 0, opacity: 0.1 }}
          onChange={onChange}
          type="file"
          accept={acceptedFileTypes}
          ref={ref}
          {...props}
        />
        <Upload color="secondary.500" />
        Nahrát soubor
      </Button>
    </Box>
  )
})
