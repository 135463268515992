import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IOrganizationItem } from '../../interfaces/entities/IOrganization'
import queryString from 'query-string'
import { OrganizationTypeEnum } from '../../enums/OrganizationTypeEnum'

export const useGetOrganizations = (
  page?: number | undefined,
  limit?: number,
  isActive?: boolean,
  organizationType?: OrganizationTypeEnum,
) => {
  const query = queryString.stringify(
    {
      page,
      limit,
      isActive,
      organizationType,
    },
    {
      skipEmptyString: true,
    },
  )

  return useQuery(
    [ApiRouteEnum.ORGANIZATION],
    () => api.get<IOrganizationItem>(`${ApiRouteEnum.ORGANIZATION}?${query}`),
    {
      retry: false,
    },
  )
}
