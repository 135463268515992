import { Icon } from '@chakra-ui/react'

export const Thief = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M24.0011 5.26978C26.4221 5.26978 28.7451 6.23178 30.4571 7.94378C32.1691 9.65578 33.1311 11.9788 33.1311 14.3998C33.1311 16.8208 32.1691 19.1438 30.4571 20.8558C28.7451 22.5678 26.4221 23.5298 24.0011 23.5298C21.5801 23.5298 19.2571 22.5678 17.5451 20.8558C15.8331 19.1438 14.8711 16.8208 14.8711 14.3998C14.8711 11.9788 15.8331 9.65578 17.5451 7.94378C19.2571 6.23178 21.5801 5.26978 24.0011 5.26978ZM16.3311 14.3998C16.3311 16.4338 17.1391 18.3848 18.5771 19.8238C20.0161 21.2618 21.9671 22.0698 24.0011 22.0698C26.0351 22.0698 27.9861 21.2618 29.4251 19.8238C30.8631 18.3848 31.6711 16.4338 31.6711 14.3998C31.6711 12.3658 30.8631 10.4148 29.4251 8.97578C27.9861 7.53778 26.0351 6.72978 24.0011 6.72978C21.9671 6.72978 20.0161 7.53778 18.5781 8.97578C17.1391 10.4148 16.3311 12.3658 16.3311 14.3998Z" fill="currentColor"/>
<path d="M12.0208 26.8338H36.0008C37.1588 26.8338 38.2688 27.2938 39.0878 28.1128C39.9068 28.9318 40.3668 30.0418 40.3668 31.1998C40.3668 35.0938 38.4438 38.0338 35.4438 39.9468C32.4288 41.8678 28.3628 42.7658 24.0008 42.7658C19.6388 42.7658 15.5728 41.8678 12.5578 39.9468C9.55777 38.0318 7.63477 35.0968 7.63477 31.1998C7.63477 30.6248 7.74877 30.0558 7.96977 29.5248C8.18977 28.9938 8.51377 28.5118 8.92077 28.1058C9.32877 27.7008 9.81177 27.3798 10.3438 27.1608C10.8758 26.9428 11.4458 26.8318 12.0208 26.8338ZM9.16677 31.1998C9.16677 34.5048 10.7338 36.9628 13.3828 38.6528C16.0088 40.3288 19.7418 41.2338 24.0008 41.2338C28.2598 41.2338 31.9928 40.3288 34.6188 38.6528C37.2708 36.9648 38.8348 34.5028 38.8348 31.1998C38.8348 30.4478 38.5358 29.7278 38.0048 29.1958C37.4728 28.6648 36.7528 28.3658 36.0008 28.3658H12.0228C10.4338 28.3658 9.16677 29.6338 9.16677 31.1998Z" fill="currentColor"/>
<path fillRule="evenodd" clipRule="evenodd" d="M24.0006 11.8543C21.1396 11.8543 18.0396 11.0593 16.8466 10.6613C16.8086 10.6483 16.7656 10.6523 16.7306 10.6723C16.6946 10.6913 16.6686 10.7253 16.6586 10.7643L15.4586 15.5643C15.4386 15.6433 15.4866 15.7223 15.5646 15.7423C17.1716 16.1443 21.1086 16.9463 24.0006 16.9463C26.8926 16.9463 30.8296 16.1443 32.4366 15.7423C32.5146 15.7223 32.5626 15.6433 32.5426 15.5643L31.3426 10.7643C31.3326 10.7253 31.3066 10.6913 31.2706 10.6723C31.2356 10.6523 31.1926 10.6483 31.1546 10.6613C29.9616 11.0593 26.8616 11.8543 24.0006 11.8543ZM22.5376 14.4473C22.4786 14.6653 22.3156 14.8353 22.0936 14.9503C21.7276 15.1393 21.2066 15.1813 20.6716 15.0383C20.1356 14.8953 19.7056 14.5973 19.4836 14.2513C19.3486 14.0403 19.2926 13.8113 19.3506 13.5933C19.4086 13.3763 19.5716 13.2063 19.7946 13.0913C20.1606 12.9013 20.6816 12.8593 21.2166 13.0023C21.7516 13.1463 22.1816 13.4433 22.4036 13.7903C22.5396 14.0013 22.5956 14.2293 22.5376 14.4473ZM27.2046 15.0383C26.6696 15.1813 26.1486 15.1393 25.7826 14.9503C25.5606 14.8353 25.3976 14.6653 25.3386 14.4473C25.2806 14.2293 25.3366 14.0013 25.4726 13.7903C25.6946 13.4433 26.1246 13.1463 26.6596 13.0023C27.1946 12.8593 27.7166 12.9013 28.0816 13.0913C28.3046 13.2063 28.4676 13.3763 28.5256 13.5933C28.5836 13.8113 28.5276 14.0403 28.3926 14.2513C28.1706 14.5973 27.7406 14.8953 27.2046 15.0383Z" fill="currentColor"/>
	</Icon>
)


export const ThiefString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.0011 5.26978C26.4221 5.26978 28.7451 6.23178 30.4571 7.94378C32.1691 9.65578 33.1311 11.9788 33.1311 14.3998C33.1311 16.8208 32.1691 19.1438 30.4571 20.8558C28.7451 22.5678 26.4221 23.5298 24.0011 23.5298C21.5801 23.5298 19.2571 22.5678 17.5451 20.8558C15.8331 19.1438 14.8711 16.8208 14.8711 14.3998C14.8711 11.9788 15.8331 9.65578 17.5451 7.94378C19.2571 6.23178 21.5801 5.26978 24.0011 5.26978ZM16.3311 14.3998C16.3311 16.4338 17.1391 18.3848 18.5771 19.8238C20.0161 21.2618 21.9671 22.0698 24.0011 22.0698C26.0351 22.0698 27.9861 21.2618 29.4251 19.8238C30.8631 18.3848 31.6711 16.4338 31.6711 14.3998C31.6711 12.3658 30.8631 10.4148 29.4251 8.97578C27.9861 7.53778 26.0351 6.72978 24.0011 6.72978C21.9671 6.72978 20.0161 7.53778 18.5781 8.97578C17.1391 10.4148 16.3311 12.3658 16.3311 14.3998Z" fill="#EE7400"/>
<path d="M12.0208 26.8338H36.0008C37.1588 26.8338 38.2688 27.2938 39.0878 28.1128C39.9068 28.9318 40.3668 30.0418 40.3668 31.1998C40.3668 35.0938 38.4438 38.0338 35.4438 39.9468C32.4288 41.8678 28.3628 42.7658 24.0008 42.7658C19.6388 42.7658 15.5728 41.8678 12.5578 39.9468C9.55777 38.0318 7.63477 35.0968 7.63477 31.1998C7.63477 30.6248 7.74877 30.0558 7.96977 29.5248C8.18977 28.9938 8.51377 28.5118 8.92077 28.1058C9.32877 27.7008 9.81177 27.3798 10.3438 27.1608C10.8758 26.9428 11.4458 26.8318 12.0208 26.8338ZM9.16677 31.1998C9.16677 34.5048 10.7338 36.9628 13.3828 38.6528C16.0088 40.3288 19.7418 41.2338 24.0008 41.2338C28.2598 41.2338 31.9928 40.3288 34.6188 38.6528C37.2708 36.9648 38.8348 34.5028 38.8348 31.1998C38.8348 30.4478 38.5358 29.7278 38.0048 29.1958C37.4728 28.6648 36.7528 28.3658 36.0008 28.3658H12.0228C10.4338 28.3658 9.16677 29.6338 9.16677 31.1998Z" fill="#EE7400"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.0006 11.8543C21.1396 11.8543 18.0396 11.0593 16.8466 10.6613C16.8086 10.6483 16.7656 10.6523 16.7306 10.6723C16.6946 10.6913 16.6686 10.7253 16.6586 10.7643L15.4586 15.5643C15.4386 15.6433 15.4866 15.7223 15.5646 15.7423C17.1716 16.1443 21.1086 16.9463 24.0006 16.9463C26.8926 16.9463 30.8296 16.1443 32.4366 15.7423C32.5146 15.7223 32.5626 15.6433 32.5426 15.5643L31.3426 10.7643C31.3326 10.7253 31.3066 10.6913 31.2706 10.6723C31.2356 10.6523 31.1926 10.6483 31.1546 10.6613C29.9616 11.0593 26.8616 11.8543 24.0006 11.8543ZM22.5376 14.4473C22.4786 14.6653 22.3156 14.8353 22.0936 14.9503C21.7276 15.1393 21.2066 15.1813 20.6716 15.0383C20.1356 14.8953 19.7056 14.5973 19.4836 14.2513C19.3486 14.0403 19.2926 13.8113 19.3506 13.5933C19.4086 13.3763 19.5716 13.2063 19.7946 13.0913C20.1606 12.9013 20.6816 12.8593 21.2166 13.0023C21.7516 13.1463 22.1816 13.4433 22.4036 13.7903C22.5396 14.0013 22.5956 14.2293 22.5376 14.4473ZM27.2046 15.0383C26.6696 15.1813 26.1486 15.1393 25.7826 14.9503C25.5606 14.8353 25.3976 14.6653 25.3386 14.4473C25.2806 14.2293 25.3366 14.0013 25.4726 13.7903C25.6946 13.4433 26.1246 13.1463 26.6596 13.0023C27.1946 12.8593 27.7166 12.9013 28.0816 13.0913C28.3046 13.2063 28.4676 13.3763 28.5256 13.5933C28.5836 13.8113 28.5276 14.0403 28.3926 14.2513C28.1706 14.5973 27.7406 14.8953 27.2046 15.0383Z" fill="#EE7400"/>
</svg>
`;