import { Icon } from '@chakra-ui/react'

export const Offer = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M13.245 2.81699L3.639 12.423L3.522 12.551C3.33592 12.7726 3.20013 13.0318 3.124 13.311L2.018 17.366L2.003 17.446C1.9946 17.5268 2.00604 17.6085 2.03631 17.6839C2.06658 17.7593 2.11478 17.8262 2.17673 17.8788C2.23868 17.9314 2.31251 17.9681 2.39184 17.9857C2.47117 18.0034 2.5536 18.0014 2.632 17.98L6.687 16.874L6.852 16.821C7.12358 16.7211 7.37026 16.5635 7.575 16.359L17.18 6.75299L17.31 6.61299C17.7803 6.06855 18.0214 5.36314 17.9829 4.64475C17.9444 3.92635 17.6291 3.25079 17.1033 2.75978C16.5775 2.26876 15.882 2.00044 15.1626 2.01111C14.4433 2.02178 13.756 2.3106 13.245 2.81699V2.81699ZM4.346 13.13L12.384 5.09199L14.905 7.61299L6.867 15.651L6.77 15.737L6.662 15.809C6.58701 15.8525 6.50666 15.8862 6.423 15.909L3.212 16.785L4.088 13.574L4.131 13.451C4.18112 13.331 4.25415 13.222 4.346 13.13V13.13ZM16.473 3.52399L16.583 3.64399C17.167 4.34399 17.131 5.38799 16.473 6.04599L15.613 6.90599L13.092 4.38499L13.952 3.52499L14.072 3.41499C14.4139 3.13063 14.8496 2.98427 15.2938 3.00462C15.738 3.02497 16.1586 3.21056 16.473 3.52499V3.52399Z" fill="currentColor"/>
<path d="M11.648 3H2.5C2.36739 3 2.24021 3.05268 2.14645 3.14645C2.05268 3.24021 2 3.36739 2 3.5C2 3.63261 2.05268 3.75979 2.14645 3.85355C2.24021 3.94732 2.36739 4 2.5 4H10.648L11.648 3Z" fill="currentColor"/>
<path d="M8.648 6H2.5C2.36739 6 2.24021 6.05268 2.14645 6.14645C2.05268 6.24021 2 6.36739 2 6.5C2 6.63261 2.05268 6.75979 2.14645 6.85355C2.24021 6.94732 2.36739 7 2.5 7H7.648L8.648 6Z" fill="currentColor"/>
<path d="M4.648 10L5.648 9H2.5C2.36739 9 2.24021 9.05268 2.14645 9.14645C2.05268 9.24021 2 9.36739 2 9.5C2 9.63261 2.05268 9.75979 2.14645 9.85355C2.24021 9.94732 2.36739 10 2.5 10H4.648Z" fill="currentColor"/>
	</Icon>
)


export const OfferString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.245 2.81699L3.639 12.423L3.522 12.551C3.33592 12.7726 3.20013 13.0318 3.124 13.311L2.018 17.366L2.003 17.446C1.9946 17.5268 2.00604 17.6085 2.03631 17.6839C2.06658 17.7593 2.11478 17.8262 2.17673 17.8788C2.23868 17.9314 2.31251 17.9681 2.39184 17.9857C2.47117 18.0034 2.5536 18.0014 2.632 17.98L6.687 16.874L6.852 16.821C7.12358 16.7211 7.37026 16.5635 7.575 16.359L17.18 6.75299L17.31 6.61299C17.7803 6.06855 18.0214 5.36314 17.9829 4.64475C17.9444 3.92635 17.6291 3.25079 17.1033 2.75978C16.5775 2.26876 15.882 2.00044 15.1626 2.01111C14.4433 2.02178 13.756 2.3106 13.245 2.81699V2.81699ZM4.346 13.13L12.384 5.09199L14.905 7.61299L6.867 15.651L6.77 15.737L6.662 15.809C6.58701 15.8525 6.50666 15.8862 6.423 15.909L3.212 16.785L4.088 13.574L4.131 13.451C4.18112 13.331 4.25415 13.222 4.346 13.13V13.13ZM16.473 3.52399L16.583 3.64399C17.167 4.34399 17.131 5.38799 16.473 6.04599L15.613 6.90599L13.092 4.38499L13.952 3.52499L14.072 3.41499C14.4139 3.13063 14.8496 2.98427 15.2938 3.00462C15.738 3.02497 16.1586 3.21056 16.473 3.52499V3.52399Z" fill="#003D66"/>
<path d="M11.648 3H2.5C2.36739 3 2.24021 3.05268 2.14645 3.14645C2.05268 3.24021 2 3.36739 2 3.5C2 3.63261 2.05268 3.75979 2.14645 3.85355C2.24021 3.94732 2.36739 4 2.5 4H10.648L11.648 3Z" fill="#003D66"/>
<path d="M8.648 6H2.5C2.36739 6 2.24021 6.05268 2.14645 6.14645C2.05268 6.24021 2 6.36739 2 6.5C2 6.63261 2.05268 6.75979 2.14645 6.85355C2.24021 6.94732 2.36739 7 2.5 7H7.648L8.648 6Z" fill="#003D66"/>
<path d="M4.648 10L5.648 9H2.5C2.36739 9 2.24021 9.05268 2.14645 9.14645C2.05268 9.24021 2 9.36739 2 9.5C2 9.63261 2.05268 9.75979 2.14645 9.85355C2.24021 9.94732 2.36739 10 2.5 10H4.648Z" fill="#003D66"/>
</svg>
`;