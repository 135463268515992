export interface IBrokerNumber {
  id: number
  companyName: string
  number: number | string
}

export interface IProfile {
  id: number
  status: true | false
  insuranceAllowed: boolean
  userType: 'app' | 'ldap' | 'ws'
  role: 'user' | 'admin'
  username: string
  firstName: string
  lastName: string
  email: string
  phone: string
  address: string
  brokerNumbers?: Array<IBrokerNumber>
  personalNumbers: Array<IBrokerNumber>
}

export const getUserStatus = (
  status: IProfile['status'],
): { label: string; colorScheme: string } => {
  switch (status) {
    case true:
      return { label: 'Aktivní', colorScheme: 'green' }
    case false:
      return { label: 'Neaktivní', colorScheme: 'orange' }
  }
}

export const getUserType = (
  userType: IProfile['userType'],
): { label: string; colorScheme: string } => {
  switch (userType) {
    case 'app':
      return { label: 'APP User', colorScheme: 'blue' }
    case 'ldap':
      return { label: 'LDAP User', colorScheme: 'blue' }
    case 'ws':
      return { label: 'WS User', colorScheme: 'blue' }
    default:
      return { label: 'APP User', colorScheme: 'blue' }
  }
}

export const brokerNumbers: Array<IBrokerNumber> = [
  {
    id: 1,
    companyName: 'Allianz',
    number: 20244173,
  },
  {
    id: 2,
    companyName: 'ČPP',
    number: 7770446003,
  },
  {
    id: 3,
    companyName: 'ČSOB Pojišťovna',
    number: 5115545,
  },
  {
    id: 4,
    companyName: 'Direct Pojišťovna',
    number: '0100111',
  },
  {
    id: 5,
    companyName: 'Generali Česká Pojišťovna',
    number: 638666621,
  },
  {
    id: 6,
    companyName: 'HVP',
    number: 89961,
  },
  {
    id: 7,
    companyName: 'Kooperativa',
    number: 300181,
  },
  {
    id: 8,
    companyName: 'Pillow pojišťovna',
    number: 120,
  },
  {
    id: 9,
    companyName: 'Pojišťovna VZP',
    number: 50350000001,
  },
  {
    id: 10,
    companyName: 'Slavia pojišťovna',
    number: 4423,
  },
  {
    id: 11,
    companyName: 'UNIQA',
    number: 2158725,
  },
  {
    id: 12,
    companyName: 'UNIQA pojišťovna (AXA)',
    number: 299133,
  },
]

const personalNumbers: Array<IBrokerNumber> = [
  {
    id: 1,
    companyName: 'Allianz',
    number: 20244173,
  },
  {
    id: 2,
    companyName: 'ČPP',
    number: 7770446003,
  },
  {
    id: 3,
    companyName: 'ČSOB Pojišťovna',
    number: 5115545,
  },
  {
    id: 4,
    companyName: 'Direct Pojišťovna',
    number: '0100111',
  },
  {
    id: 5,
    companyName: 'Generali Česká Pojišťovna',
    number: 638666621,
  },
  {
    id: 6,
    companyName: 'HVP',
    number: 89961,
  },
  {
    id: 7,
    companyName: 'Kooperativa',
    number: 300181,
  },
  {
    id: 8,
    companyName: 'Pillow pojišťovna',
    number: 120,
  },
  {
    id: 9,
    companyName: 'Pojišťovna VZP',
    number: 50350000001,
  },
  {
    id: 10,
    companyName: 'Slavia pojišťovna',
    number: 4423,
  },
  {
    id: 11,
    companyName: 'UNIQA',
    number: 2158725,
  },
  {
    id: 12,
    companyName: 'UNIQA pojišťovna (AXA)',
    number: 299133,
  },
]

export const profile: IProfile = {
  id: 1,
  status: true,
  insuranceAllowed: true,
  userType: 'app',
  role: 'admin',
  username: 'jana.novakova',
  firstName: 'Jana',
  lastName: 'Nováková',
  email: 'jana.novakova@okgroup.cz',
  phone: '+420 777 323 434',
  address: 'Hlavní třída 165/2, 708 00 Ostrava-Poruba',
  brokerNumbers,
  personalNumbers,
}

export const dummyUser: IProfile = {
  id: 2,
  status: false,
  insuranceAllowed: false,
  userType: 'app',
  role: 'admin',
  username: 'rostislav.ctvrtlik',
  firstName: 'Rostislav',
  lastName: 'Čtvrtlík',
  email: 'rostislav.ctvrtlik@okgroup.cz',
  phone: '+420 777 323 434',
  address: 'Hlavní třída 165/2, 708 00 Ostrava-Poruba',
  personalNumbers,
}

export const users: Array<IProfile> = [dummyUser, profile]
