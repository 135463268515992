import { Icon } from '@chakra-ui/react'

export const InsuranceNewPriceInsurance = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M39 12H9C7.89543 12 7 12.8954 7 14V33C7 34.1046 7.89543 35 9 35H39C40.1046 35 41 34.1046 41 33V14C41 12.8954 40.1046 12 39 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M14.26 28.216C14.26 28.692 14.638 29.07 15.128 29.07C15.604 29.07 15.982 28.692 15.982 28.216V26.186L17.578 24.646L20.728 28.664C20.91 28.902 21.12 29.07 21.456 29.07C21.946 29.07 22.324 28.706 22.324 28.23C22.324 27.964 22.226 27.796 22.086 27.614L18.782 23.512L21.834 20.572C22.03 20.39 22.142 20.194 22.142 19.928C22.142 19.508 21.82 19.13 21.344 19.13C21.036 19.13 20.826 19.242 20.63 19.452L15.982 24.128V19.984C15.982 19.508 15.604 19.13 15.128 19.13C14.638 19.13 14.26 19.508 14.26 19.984V28.216Z" fill="currentColor"/>
<path d="M26.9806 19.55L26.2527 18.92C25.8886 18.612 25.6087 18.542 25.2167 18.542C24.9927 18.542 24.8106 18.64 24.8106 18.822C24.8106 18.934 24.8947 19.046 24.9787 19.158L25.9026 20.32C26.1126 20.572 26.3226 20.726 26.7147 20.726H27.2186C27.6107 20.726 27.8347 20.572 28.0307 20.32L28.9547 19.158C29.0387 19.046 29.1227 18.934 29.1227 18.822C29.1227 18.64 28.9687 18.542 28.7447 18.542C28.3527 18.542 28.0866 18.612 27.7087 18.92L26.9806 19.55ZM27.0367 29.168C28.2687 29.168 29.0667 28.748 29.7247 28.118C29.8787 27.992 29.9627 27.81 29.9627 27.586C29.9627 27.18 29.6406 26.844 29.2346 26.844C29.0386 26.844 28.8707 26.942 28.7727 27.026C28.3107 27.418 27.7927 27.684 27.1207 27.684C25.8187 27.684 24.9087 26.634 24.9087 25.318V25.29C24.9087 24.002 25.8187 22.938 27.0507 22.938C27.7227 22.938 28.1987 23.204 28.6327 23.568C28.7447 23.652 28.9267 23.736 29.1367 23.736C29.5707 23.736 29.9207 23.4 29.9207 22.966C29.9207 22.686 29.7667 22.476 29.6546 22.378C29.0246 21.832 28.2267 21.454 27.0647 21.454C24.8386 21.454 23.2147 23.218 23.2147 25.318V25.346C23.2147 27.446 24.8387 29.168 27.0367 29.168Z" fill="currentColor"/>
<path d="M35 16V20M33 18H37" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
)


export const InsuranceNewPriceInsuranceString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39 12H9C7.89543 12 7 12.8954 7 14V33C7 34.1046 7.89543 35 9 35H39C40.1046 35 41 34.1046 41 33V14C41 12.8954 40.1046 12 39 12Z" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M14.26 28.216C14.26 28.692 14.638 29.07 15.128 29.07C15.604 29.07 15.982 28.692 15.982 28.216V26.186L17.578 24.646L20.728 28.664C20.91 28.902 21.12 29.07 21.456 29.07C21.946 29.07 22.324 28.706 22.324 28.23C22.324 27.964 22.226 27.796 22.086 27.614L18.782 23.512L21.834 20.572C22.03 20.39 22.142 20.194 22.142 19.928C22.142 19.508 21.82 19.13 21.344 19.13C21.036 19.13 20.826 19.242 20.63 19.452L15.982 24.128V19.984C15.982 19.508 15.604 19.13 15.128 19.13C14.638 19.13 14.26 19.508 14.26 19.984V28.216Z" fill="#EE7400"/>
<path d="M26.9806 19.55L26.2527 18.92C25.8886 18.612 25.6087 18.542 25.2167 18.542C24.9927 18.542 24.8106 18.64 24.8106 18.822C24.8106 18.934 24.8947 19.046 24.9787 19.158L25.9026 20.32C26.1126 20.572 26.3226 20.726 26.7147 20.726H27.2186C27.6107 20.726 27.8347 20.572 28.0307 20.32L28.9547 19.158C29.0387 19.046 29.1227 18.934 29.1227 18.822C29.1227 18.64 28.9687 18.542 28.7447 18.542C28.3527 18.542 28.0866 18.612 27.7087 18.92L26.9806 19.55ZM27.0367 29.168C28.2687 29.168 29.0667 28.748 29.7247 28.118C29.8787 27.992 29.9627 27.81 29.9627 27.586C29.9627 27.18 29.6406 26.844 29.2346 26.844C29.0386 26.844 28.8707 26.942 28.7727 27.026C28.3107 27.418 27.7927 27.684 27.1207 27.684C25.8187 27.684 24.9087 26.634 24.9087 25.318V25.29C24.9087 24.002 25.8187 22.938 27.0507 22.938C27.7227 22.938 28.1987 23.204 28.6327 23.568C28.7447 23.652 28.9267 23.736 29.1367 23.736C29.5707 23.736 29.9207 23.4 29.9207 22.966C29.9207 22.686 29.7667 22.476 29.6546 22.378C29.0246 21.832 28.2267 21.454 27.0647 21.454C24.8386 21.454 23.2147 23.218 23.2147 25.318V25.346C23.2147 27.446 24.8387 29.168 27.0367 29.168Z" fill="#EE7400"/>
<path d="M35 16V20M33 18H37" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;