import { Icon } from '@chakra-ui/react'

export const News = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M9.998 2C13.147 2 15.742 4.335 15.982 7.355L15.996 7.578L16 7.802L15.999 11.398L16.924 13.62C16.947 13.674 16.964 13.73 16.977 13.787L16.993 13.873L17.001 14.005C16.9999 14.2257 16.9258 14.4398 16.7904 14.614C16.6549 14.7881 16.4656 14.9126 16.252 14.968L16.136 14.995L16.001 15.005L12.5 15.004L12.495 15.165C12.4548 15.7994 12.1745 16.3947 11.711 16.8298C11.2475 17.2648 10.6357 17.507 10 17.507C9.36432 17.507 8.7525 17.2648 8.28902 16.8298C7.82553 16.3947 7.54518 15.7994 7.505 15.165L7.5 15.004H3.999C3.91121 15.0042 3.82379 14.9927 3.739 14.97L3.615 14.928C3.41314 14.8429 3.2448 14.6939 3.13586 14.5038C3.02692 14.3138 2.98341 14.0932 3.012 13.876L3.033 13.748L3.076 13.62L3.999 11.401L4 7.793L4.004 7.568C4.127 4.451 6.771 2 9.998 2ZM11.5 15.004H8.5L8.507 15.145C8.54065 15.4913 8.69361 15.8151 8.9397 16.061C9.18579 16.3069 9.50971 16.4596 9.856 16.493L10 16.5C10.3727 16.5 10.7321 16.3612 11.0081 16.1108C11.2841 15.8603 11.457 15.516 11.493 15.145L11.5 15.004ZM9.998 3C7.375 3 5.228 4.924 5.018 7.385L5.004 7.597L5 7.802V11.5L4.962 11.692L3.999 14.005L15.957 14.007L16.002 14.005L15.038 11.692L15 11.5V7.812L14.996 7.608C14.891 5.035 12.695 3 9.998 3Z" fill="currentColor"/>
	</Icon>
)


export const NewsString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.998 2C13.147 2 15.742 4.335 15.982 7.355L15.996 7.578L16 7.802L15.999 11.398L16.924 13.62C16.947 13.674 16.964 13.73 16.977 13.787L16.993 13.873L17.001 14.005C16.9999 14.2257 16.9258 14.4398 16.7904 14.614C16.6549 14.7881 16.4656 14.9126 16.252 14.968L16.136 14.995L16.001 15.005L12.5 15.004L12.495 15.165C12.4548 15.7994 12.1745 16.3947 11.711 16.8298C11.2475 17.2648 10.6357 17.507 10 17.507C9.36432 17.507 8.7525 17.2648 8.28902 16.8298C7.82553 16.3947 7.54518 15.7994 7.505 15.165L7.5 15.004H3.999C3.91121 15.0042 3.82379 14.9927 3.739 14.97L3.615 14.928C3.41314 14.8429 3.2448 14.6939 3.13586 14.5038C3.02692 14.3138 2.98341 14.0932 3.012 13.876L3.033 13.748L3.076 13.62L3.999 11.401L4 7.793L4.004 7.568C4.127 4.451 6.771 2 9.998 2ZM11.5 15.004H8.5L8.507 15.145C8.54065 15.4913 8.69361 15.8151 8.9397 16.061C9.18579 16.3069 9.50971 16.4596 9.856 16.493L10 16.5C10.3727 16.5 10.7321 16.3612 11.0081 16.1108C11.2841 15.8603 11.457 15.516 11.493 15.145L11.5 15.004ZM9.998 3C7.375 3 5.228 4.924 5.018 7.385L5.004 7.597L5 7.802V11.5L4.962 11.692L3.999 14.005L15.957 14.007L16.002 14.005L15.038 11.692L15 11.5V7.812L14.996 7.608C14.891 5.035 12.695 3 9.998 3Z" fill="#003D66"/>
</svg>
`;