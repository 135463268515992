import React, { useContext } from 'react'
import { availableApps, IAvailableApps } from '../utils/availableApps'

interface IAppContext {
  app: IAvailableApps['appId']
}

interface IAppProvider {
  app: IAvailableApps['appId']
  children?: React.ReactNode
}

const defaultState = {
  app: availableApps[0].id,
}

const AppContext = React.createContext<IAppContext>(defaultState)

export const AppProvider = ({ app, children }: IAppProvider) => {
  return (
    <AppContext.Provider
      value={{
        app,
      }}
    >
      {children && children}
    </AppContext.Provider>
  )
}

export const useApp = () => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider!')
  }

  return context
}
