import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { dummyUser } from '../../data/profile'
import { UserCreate } from '../../containers/profile/UserCreate'

export const UserCreatePage = () => {
  return (
    <Section>
      <Container>
        <UserCreate profile={dummyUser} />
      </Container>
    </Section>
  )
}
