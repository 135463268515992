import {
  Box,
  BoxProps,
  Button,
  Flex,
  IconButton,
  Link,
  StylesProvider,
  Text,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react'

import { Container } from '../components/Container'
import { HelpDesk } from '../containers/HelpDesk'
import { External, Help } from '../icons'
import { useGetUserCurrent } from '../model/api/user/useGetUserCurrent'

interface FooterProps extends BoxProps {
  variant?: String
}

const FooterLink = ({ children, href, ...rest }: { children: React.ReactNode; href: string }) => {
  return (
    <Button
      as={Link}
      variant="ghost"
      size="xs"
      colorScheme="whiteAlpha"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      _hover={{
        bg: 'transparent',
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}

export const Footer = ({ sx, variant }: FooterProps) => {
  const styles = useMultiStyleConfig('Footer', { variant })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: currentUserData } = useGetUserCurrent()

  return (
    <Box __css={styles.footer} as="footer" sx={sx}>
      <StylesProvider value={styles}>
        <Container>
          <Box as="section" __css={styles.content}>
            <IconButton
              aria-label="HelpDesk"
              colorScheme="primary"
              sx={{
                position: 'fixed',
                p: 4,
                right: 4,
                bottom: { base: 20, xl: 4 },
                boxShadow: '3xl',
                borderRadius: 'full',
                zIndex: 'helpDeskButton',
              }}
              onClick={isOpen ? onClose : onOpen}
            >
              <Help color="white" width={8} height={8} />
            </IconButton>

            <HelpDesk isOpen={isOpen} onOpen={onOpen} onClose={onClose} />

            <Box sx={{ color: 'white', fontSize: 'xs' }}>
              <Text sx={{}}>
                <span>Provozovatel systému:</span> OK GROUP a.s., Mánesova 3014/16, 612 00 Brno,
                <a href="mailto:businesska@okholding.cz"> businesska@okholding.cz</a>
              </Text>
              <Text className="site-footer__text opacity-80">
                Copyright © OK GROUP a.s. 2023. Designed by ctyri.media. Developed by Apps Dev Team.
              </Text>
            </Box>

            <Flex sx={{ gap: 1 }}>
              <FooterLink href={(currentUserData && currentUserData?.data.parent?.footerUrl) || ''}>
                Pojistné podmínky
                <External />
              </FooterLink>

              <FooterLink href="https://atlas.okholding.cz/tabulky/3">
                Produktová srovnání
                <External />
              </FooterLink>
            </Flex>
          </Box>
        </Container>
      </StylesProvider>
    </Box>
  )
}
