import { Badge } from '@chakra-ui/react'
import { IProfile } from '../../../data/profile'

export const UserStatus = ({ status }: { status: IProfile['status'] }) => {
  let label = ''
  let colorScheme = ''

  switch (status) {
    case true:
      label = 'Aktivní účet'
      colorScheme = 'green'
      break
    case false:
      label = 'Neaktivní účet'
      colorScheme = 'orange'
      break
  }

  return <Badge colorScheme={colorScheme}>{label}</Badge>
}
