import { Box, Button, FormControl, FormLabel, Select, Spinner, useToast } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Datepicker } from '../../../components/Datepicker'
import { InputGroup } from '../../../components/InputGroup'
import { Export } from '../../../icons'
import { ExportWrapper } from '../../../components/ExportWrapper'
import { useGetContractsExportMutation } from '../../../model/api/contracts/useGetContractsExportMutation'
import { AxiosResponse } from 'axios'
import moment from 'moment'

export const ContractsPropertyExportForm = () => {
  const toast = useToast()
  const getContractsExportMutation = useGetContractsExportMutation()

  return (
    <Formik
      initialValues={{
        dateFrom: new Date('2023-01-01 00:00:00'),
        dateTo: new Date(),
        email: '',
        type: 'download',
      }}
      onSubmit={async (values) => {
        return await getContractsExportMutation.mutateAsync(values, {
          onSuccess: (dataFromBE: AxiosResponse<BlobPart>) => {
            if (values.type === 'download') {
              const csvData = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), dataFromBE.data], {
                type: 'text/csv;charset=utf-8',
              })
              const link = document.createElement('a')
              link.download = `smlouvy-export-${moment().format('DD_MM_YYYY')}.csv`
              link.href = window.URL.createObjectURL(csvData)
              link.click()
            }

            toast({
              title:
                values.type === 'email'
                  ? 'Export byl úspěšně odeslán'
                  : 'Export byl úspěšně stažen',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top',
            })
          },
        })
      }}
      validationSchema={Yup.object().shape({
        type: Yup.string().required(),
        email: Yup.string().when('type', {
          is: (val: any) => val === 'email',
          then: Yup.string().required(),
        }),
      })}
    >
      {({ isSubmitting, values, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <ExportWrapper>
              <FormControl variant="fullWidth">
                <FormLabel htmlFor="dateFrom">Datum od</FormLabel>

                <Datepicker
                  date={values.dateFrom}
                  onDateChange={(date: Date) => setFieldValue('dateFrom', date)}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="dateTo">Datum do</FormLabel>

                <Datepicker
                  date={values.dateTo}
                  onDateChange={(date: Date) => setFieldValue('dateTo', date)}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="exportType">Typ přehledu</FormLabel>

                <Select onChange={(val) => setFieldValue('type', val.target.value)}>
                  <option value="download">Přehled sjednaných smluv</option>
                  <option value="email">Přehled sjednaných smluv e-mailem</option>
                </Select>
              </FormControl>

              <FormControl variant="fullWidth" isInvalid={!!touched.email && !!errors.email}>
                {values.type === 'email' && (
                  <>
                    <FormLabel htmlFor="email">E-mail</FormLabel>

                    <InputGroup
                      id="email"
                      placeholder="Zadejte e-mail, na který export odešleme"
                      inputMode="email"
                      onChange={(el) => setFieldValue('email', el.target.value)}
                    />
                  </>
                )}
              </FormControl>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  columnGap: 4,
                  rowGap: 4,
                  alignItems: 'end',
                  justifyContent: 'end',
                  gridColumn: { base: '', md: '4 / 5' },
                }}
              >
                <Button type="submit" colorScheme="primary">
                  {isSubmitting ? <Spinner /> : <Export />}
                  Exportovat
                </Button>
              </Box>
            </ExportWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}
