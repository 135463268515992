export interface IAvailableApps {
  appId: 'carkulka' | 'chaloupka' | 'sme' | 'cestovko'
}

interface availableAppsProps {
  id: IAvailableApps['appId']
}

export const availableApps: Array<availableAppsProps> = [
  {
    id: 'carkulka',
  },
  {
    id: 'chaloupka',
  },
  {
    id: 'sme',
  },
  {
    id: 'cestovko',
  },
]
