import { Box, Link, Text, useToken } from '@chakra-ui/react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { useApp } from '../context/AppContext'
import { StepsIconBriefcase, StepsIconCar, StepsIconHouse } from '../icons'
import { IAvailableApps } from '../utils/availableApps'

type IconType = 'car' | 'house' | 'briefcase'

interface StepProps {
  app: IAvailableApps['appId']
  current?: boolean
  passed: number
  icon: IconType
  number: number
  label: string
  clickable: boolean
  viewOnly?: boolean
  currentStep: number
}

interface StepsProps {
  icon: IconType
  currentStep: number
  blocked?: boolean
  viewOnly?: boolean
  passed?: number
}

interface StepLineProps {
  isFirst?: boolean
  isLast?: boolean
  passed?: boolean
  current?: boolean
}

const StepLine = ({ isFirst, isLast, passed }: StepLineProps) => (
  <Box
    sx={{
      width: '100%',
      height: 1,
      backgroundColor: passed ? 'secondary.50' : 'gray.200',
      visibility: isFirst || isLast ? 'hidden' : 'visible',
    }}
  />
)

const getStepIcon = ({ icon, iconColor }: { icon: IconType; iconColor: string }) => {
  switch (icon) {
    case 'car':
      return <StepsIconCar color={iconColor} />
    case 'house':
      return <StepsIconHouse color={iconColor} />
    case 'briefcase':
      return <StepsIconBriefcase color={iconColor} />
  }
}

const Step = ({ app, current, passed, icon, number, label, clickable, viewOnly, currentStep }: StepProps) => {
  const stepSize = 8
  const isFirst = number === 1
  const isLast = number === 5
  const iconColor = useToken('colors', 'secondary.700')
  const { id } = useParams<'id'>()
  const toLink = (() => {
    if (!id) {
      return `/${app}/wizard/create/1`
    }

    if (!viewOnly) {
      return `/${app}/wizard/edit/${id}/${number}`
    }

    if (isLast) {
      return `/${app}/wizard/${id}/completed`
    }

    return `/${app}/wizard/view/${id}/${number}`
  })();


  return (
    <Box as="li" sx={{ display: 'grid', gap: 1 }} className="steps__item">
      <Link
        as={RouterLink}
        sx={{ display: 'grid', gap: 2, textDecoration: 'none', _hover: { textDecoration: 'none' } }}
        className="steps__item-link"
        to={clickable ? toLink : '#'}
      >
        <Box
          sx={{
            display: 'grid',
            alignItems: 'center',
            justifyItems: 'center',
            gridTemplateColumns: `auto ${useToken('sizes', 8)} auto`,
          }}
          className="steps__header"
        >
          <StepLine isFirst={isFirst} passed={current || ((number - 1) <= currentStep) || viewOnly} />

          <Box
            sx={{
              display: 'grid',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              width: stepSize,
              height: stepSize,
              backgroundColor: current || ((number - 1) <= currentStep) || viewOnly ? 'secondary.50' : 'gray.200',
              borderRadius: 'full',
              color: 'primary.800',
              fontWeight: 'medium',
              textAlign: 'center',
              transitionProperty: 'all',
              transitionDuration: 'normal',
            }}
            className="steps__number"
          >
            {current && getStepIcon({ icon, iconColor })}

            {!current && number}
          </Box>

          <StepLine isLast={isLast} passed={(number <= currentStep) || viewOnly} />
        </Box>

        <Text
          sx={{
            py: 0,
            px: 2,
            fontSize: { base: 'xs', md: 'md' },
            fontWeight: current ? 'medium' : 'initial',
            overflow: 'hidden',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          className="steps__label"
        >
          {label}
        </Text>
      </Link>
    </Box>
  )
}

export const Steps = ({ icon, currentStep, blocked, viewOnly, passed }: StepsProps) => {
  const { app } = useApp()

  const steps = [
    {
      label: 'Základní údaje',
      path: `/1`,
    },
    {
      label: 'Připojištění a slevy',
      path: `/2`,
    },
    {
      label: 'Kalkulace',
      path: `/3`,
    },
    {
      label: 'Doplnění informací',
      path: `/4`,
    },
    {
      label: 'Shrnutí a sjednání',
      path: `/5`,
    },
  ]

  return (
    <Box
      as="ul"
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        justifyContent: 'stretch',
        listStyle: 'none',
      }}
    >
      {steps.map(({ label, path }, i) => (
        <Step
          key={label}
          app={app}
          icon={icon}
          number={i + 1}
          label={label}
          current={currentStep === i + 1}
          currentStep={currentStep - 1}
          passed={passed ?? 0}
          clickable={(currentStep > i && !blocked) || (viewOnly ?? true)}
          viewOnly={viewOnly}
        />
      ))}
    </Box>
  )
}
