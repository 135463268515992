import { FormErrorMessage, Tooltip } from '@chakra-ui/react'

import { FormLabel } from '../../components/FormLabel'
import { CheckboxStack } from '../../components/CheckboxStack'
import { CheckboxIconGroup } from '../../components/CheckboxIconGroup'
import { CheckboxIcon } from '../../components/CheckboxIcon'
import { QuestionMark } from '../../icons'
import { FormControl } from '../../components/FormControl'
import { RequiredField } from '../RequiredField'

export type IInsuraceTypeProps = {
  touched?: any
  errors?: any
  values: any
  setValues: any
}

const InsuranceTypeResponsibilityTooltip = (
  <>
    Hradí újmy na životě a zdraví člověka, na hmotné věci i zvířeti, které způsobité jinému subjektu
    souvisejcící s podnikatelskou činností.
    <br />
    <br />
    <ul style={{ marginLeft: '12px' }}>
      <li>Odpovědnost za újmu způsobenou na věci </li>
      <li>Odpovědnost za újmu způsobenou na zdraví nebo životě</li>
      <li>Odpovědnost za následnou finanční škodu</li>
      <li>Odpovědnost za škodu způsobenou vadným výrobkem</li>
    </ul>
  </>
)

export const InsuranceType = (props: IInsuraceTypeProps) => {
  const { touched, errors, values, setValues } = props
  const value = values.insurance
  // const { control } = useFormContext()

  const setValue = (type: string): void => {
    const newTypes: Array<string> = values?.insurance?.type || []

    newTypes.includes(type) ? newTypes.splice(newTypes.indexOf(type), 1) : newTypes.push(type)

    setValues({
      ...values,
      insurance: {
        ...values.insurance,
        type: newTypes,
      },
    })
  }

  return (
    <FormControl
      id="insurance.type"
      isInvalid={touched.insurance?.type && !!errors.insurance?.type}
    >
      <FormLabel>
        Předmět pojištění
        <RequiredField />
      </FormLabel>
      <CheckboxIconGroup name="insurance.type">
        <CheckboxStack>
          <CheckboxIcon
            value="building"
            customIcon="buildings"
            isChecked={value?.type.includes('building')}
            onChange={() => setValue('building')}
          >
            Budovy
            <Tooltip
              shouldWrapChildren
              label="ve vlastnictví, popř. povinné pojištění dle nájemní smlouvy"
            >
              <QuestionMark boxSize="16px" ml={1} />
            </Tooltip>
          </CheckboxIcon>
          <CheckboxIcon
            customIcon="vehicle"
            value="machine"
            isChecked={value?.type.includes('machine')}
            onChange={() => setValue('machine')}
          >
            Výrobní a&nbsp;provozní zařízení
            <Tooltip shouldWrapChildren label="Movité věci, elektronické a strojní zařízení">
              <QuestionMark boxSize="16px" ml={1} />
            </Tooltip>
          </CheckboxIcon>
          <CheckboxIcon
            customIcon="responsibility"
            value="responsibility"
            isChecked={value?.type.includes('responsibility')}
            onChange={() => setValue('responsibility')}
          >
            Odpovědnost
            <Tooltip shouldWrapChildren label={InsuranceTypeResponsibilityTooltip}>
              <QuestionMark boxSize="16px" ml={1} />
            </Tooltip>
          </CheckboxIcon>
          <CheckboxIcon
            customIcon="interruptions"
            value="stopped_business"
            isChecked={value?.type.includes('stopped_business')}
            onChange={() => setValue('stopped_business')}
          >
            Přerušení provozu
            <Tooltip
              shouldWrapChildren
              label="Kompenzuje finanční ztráty v případě neočekávaného přerušení podnikatelské činnosti z důvodu živelných škod na pojištěném majetku."
            >
              <QuestionMark boxSize="16px" ml={1} />
            </Tooltip>
          </CheckboxIcon>
        </CheckboxStack>
      </CheckboxIconGroup>
      {touched.insurance?.type && <FormErrorMessage>{errors.insurance?.type}</FormErrorMessage>}
    </FormControl>
  )
}
