import { Icon } from '@chakra-ui/react'

export const Info = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M10.492 8.91C10.4697 8.78705 10.4023 8.67689 10.3028 8.60122C10.2034 8.52554 10.0793 8.48983 9.95482 8.50112C9.83039 8.51241 9.71469 8.56988 9.63051 8.66222C9.54633 8.75455 9.49977 8.87505 9.5 9V13.502L9.508 13.592C9.53026 13.7149 9.59775 13.8251 9.69717 13.9008C9.79659 13.9765 9.92074 14.0122 10.0452 14.0009C10.1696 13.9896 10.2853 13.9321 10.3695 13.8398C10.4537 13.7474 10.5002 13.6269 10.5 13.502V9L10.492 8.91Z" fill="currentColor"/>
<path d="M10.799 6.75C10.799 6.55109 10.72 6.36032 10.5793 6.21967C10.4387 6.07902 10.2479 6 10.049 6C9.85009 6 9.65932 6.07902 9.51867 6.21967C9.37802 6.36032 9.299 6.55109 9.299 6.75C9.299 6.94891 9.37802 7.13968 9.51867 7.28033C9.65932 7.42098 9.85009 7.5 10.049 7.5C10.2479 7.5 10.4387 7.42098 10.5793 7.28033C10.72 7.13968 10.799 6.94891 10.799 6.75Z" fill="currentColor"/>
<path d="M18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10ZM3 10C3 9.08075 3.18106 8.1705 3.53284 7.32122C3.88463 6.47194 4.40024 5.70026 5.05025 5.05025C5.70026 4.40024 6.47194 3.88463 7.32122 3.53284C8.1705 3.18106 9.08075 3 10 3C10.9193 3 11.8295 3.18106 12.6788 3.53284C13.5281 3.88463 14.2997 4.40024 14.9497 5.05025C15.5998 5.70026 16.1154 6.47194 16.4672 7.32122C16.8189 8.1705 17 9.08075 17 10C17 11.8565 16.2625 13.637 14.9497 14.9497C13.637 16.2625 11.8565 17 10 17C8.14348 17 6.36301 16.2625 5.05025 14.9497C3.7375 13.637 3 11.8565 3 10Z" fill="currentColor"/>
	</Icon>
)


export const InfoString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.492 8.91C10.4697 8.78705 10.4023 8.67689 10.3028 8.60122C10.2034 8.52554 10.0793 8.48983 9.95482 8.50112C9.83039 8.51241 9.71469 8.56988 9.63051 8.66222C9.54633 8.75455 9.49977 8.87505 9.5 9V13.502L9.508 13.592C9.53026 13.7149 9.59775 13.8251 9.69717 13.9008C9.79659 13.9765 9.92074 14.0122 10.0452 14.0009C10.1696 13.9896 10.2853 13.9321 10.3695 13.8398C10.4537 13.7474 10.5002 13.6269 10.5 13.502V9L10.492 8.91Z" fill="#4299E1"/>
<path d="M10.799 6.75C10.799 6.55109 10.72 6.36032 10.5793 6.21967C10.4387 6.07902 10.2479 6 10.049 6C9.85009 6 9.65932 6.07902 9.51867 6.21967C9.37802 6.36032 9.299 6.55109 9.299 6.75C9.299 6.94891 9.37802 7.13968 9.51867 7.28033C9.65932 7.42098 9.85009 7.5 10.049 7.5C10.2479 7.5 10.4387 7.42098 10.5793 7.28033C10.72 7.13968 10.799 6.94891 10.799 6.75Z" fill="#4299E1"/>
<path d="M18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10ZM3 10C3 9.08075 3.18106 8.1705 3.53284 7.32122C3.88463 6.47194 4.40024 5.70026 5.05025 5.05025C5.70026 4.40024 6.47194 3.88463 7.32122 3.53284C8.1705 3.18106 9.08075 3 10 3C10.9193 3 11.8295 3.18106 12.6788 3.53284C13.5281 3.88463 14.2997 4.40024 14.9497 5.05025C15.5998 5.70026 16.1154 6.47194 16.4672 7.32122C16.8189 8.1705 17 9.08075 17 10C17 11.8565 16.2625 13.637 14.9497 14.9497C13.637 16.2625 11.8565 17 10 17C8.14348 17 6.36301 16.2625 5.05025 14.9497C3.7375 13.637 3 11.8565 3 10Z" fill="#4299E1"/>
</svg>
`;