import { Icon } from '@chakra-ui/react'

export const Export = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M9.646 2.14598C9.69245 2.09941 9.74762 2.06247 9.80837 2.03727C9.86911 2.01206 9.93423 1.99908 10 1.99908C10.0658 1.99908 10.1309 2.01206 10.1916 2.03727C10.2524 2.06247 10.3076 2.09941 10.354 2.14598L14.354 6.14598C14.4479 6.23986 14.5006 6.3672 14.5006 6.49998C14.5006 6.63275 14.4479 6.76009 14.354 6.85398C14.2601 6.94786 14.1328 7.00061 14 7.00061C13.8672 7.00061 13.7399 6.94786 13.646 6.85398L10.5 3.70698V14.5C10.5 14.6326 10.4473 14.7598 10.3536 14.8535C10.2598 14.9473 10.1326 15 10 15C9.86739 15 9.74021 14.9473 9.64645 14.8535C9.55268 14.7598 9.5 14.6326 9.5 14.5V3.70698L6.354 6.85398C6.30751 6.90047 6.25232 6.93734 6.19158 6.9625C6.13084 6.98766 6.06574 7.00061 6 7.00061C5.93426 7.00061 5.86916 6.98766 5.80842 6.9625C5.74768 6.93734 5.69249 6.90047 5.646 6.85398C5.59951 6.80749 5.56264 6.7523 5.53748 6.69156C5.51232 6.63082 5.49937 6.56572 5.49937 6.49998C5.49937 6.43423 5.51232 6.36913 5.53748 6.30839C5.56264 6.24766 5.59951 6.19247 5.646 6.14598L9.646 2.14598ZM4 17.5C4 17.3674 4.05268 17.2402 4.14645 17.1464C4.24021 17.0527 4.36739 17 4.5 17H15.5C15.6326 17 15.7598 17.0527 15.8536 17.1464C15.9473 17.2402 16 17.3674 16 17.5C16 17.6326 15.9473 17.7598 15.8536 17.8535C15.7598 17.9473 15.6326 18 15.5 18H4.5C4.36739 18 4.24021 17.9473 4.14645 17.8535C4.05268 17.7598 4 17.6326 4 17.5Z" fill="currentColor"/>
	</Icon>
)


export const ExportString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.646 2.14598C9.69245 2.09941 9.74762 2.06247 9.80837 2.03727C9.86911 2.01206 9.93423 1.99908 10 1.99908C10.0658 1.99908 10.1309 2.01206 10.1916 2.03727C10.2524 2.06247 10.3076 2.09941 10.354 2.14598L14.354 6.14598C14.4479 6.23986 14.5006 6.3672 14.5006 6.49998C14.5006 6.63275 14.4479 6.76009 14.354 6.85398C14.2601 6.94786 14.1328 7.00061 14 7.00061C13.8672 7.00061 13.7399 6.94786 13.646 6.85398L10.5 3.70698V14.5C10.5 14.6326 10.4473 14.7598 10.3536 14.8535C10.2598 14.9473 10.1326 15 10 15C9.86739 15 9.74021 14.9473 9.64645 14.8535C9.55268 14.7598 9.5 14.6326 9.5 14.5V3.70698L6.354 6.85398C6.30751 6.90047 6.25232 6.93734 6.19158 6.9625C6.13084 6.98766 6.06574 7.00061 6 7.00061C5.93426 7.00061 5.86916 6.98766 5.80842 6.9625C5.74768 6.93734 5.69249 6.90047 5.646 6.85398C5.59951 6.80749 5.56264 6.7523 5.53748 6.69156C5.51232 6.63082 5.49937 6.56572 5.49937 6.49998C5.49937 6.43423 5.51232 6.36913 5.53748 6.30839C5.56264 6.24766 5.59951 6.19247 5.646 6.14598L9.646 2.14598ZM4 17.5C4 17.3674 4.05268 17.2402 4.14645 17.1464C4.24021 17.0527 4.36739 17 4.5 17H15.5C15.6326 17 15.7598 17.0527 15.8536 17.1464C15.9473 17.2402 16 17.3674 16 17.5C16 17.6326 15.9473 17.7598 15.8536 17.8535C15.7598 17.9473 15.6326 18 15.5 18H4.5C4.36739 18 4.24021 17.9473 4.14645 17.8535C4.05268 17.7598 4 17.6326 4 17.5Z" fill="#003D66"/>
</svg>
`;