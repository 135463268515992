import queryString from 'query-string'
import { IOffers } from './../../interfaces/entities/IOffers'
import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const useGetOffers = (params: any) => {
  const query = queryString.stringify(params, { skipEmptyString: true })
  const offers: string = `${ApiRouteEnum.OFFERS}?${query}`
  return useQuery([ApiRouteEnum.OFFERS, query], () => api.get<IOffers>(offers), {
    retry: false,
  })
}
