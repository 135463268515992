import { radioAnatomy as parts } from '@chakra-ui/anatomy'
import { cssVar } from '@chakra-ui/react'
import {
  mode,
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'

import { Checkbox } from './Checkbox'

const defaultBorderRadius = 'md'
const dbr = `var(${cssVar(`ok-radii-${defaultBorderRadius}`).variable})`

const baseStyleControl: SystemStyleFunction = (props) => {
  const { control = {} } = Checkbox.baseStyle(props)

  return {
    ...control,
    borderRadius: 'full',
    _checked: {
      ...(control as any)['_checked'],
      bg: 'white',
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: 2,
        h: 2,
        borderRadius: '50%',
        bg: 'primary.500',
      },
    },
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  label: Checkbox.baseStyle(props).label,
  container: Checkbox.baseStyle(props).container,
  control: baseStyleControl(props),
})

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    control: { w: 4, h: 4 },
    label: { fontSize: 'md' },
  },
  lg: {
    control: { w: 5, h: 5 },
    label: { fontSize: 'lg' },
  },
  sm: {
    control: { width: 3, height: 3 },
    label: { fontSize: 'sm' },
  },
}

const variantTile: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: {
      px: 4,
      py: 3,
      bg: 'gray.50',
      border: '1px solid',
      borderColor: mode('gray.400', 'whiteAlpha.800')(props),
      borderRadius: defaultBorderRadius,
      _hover: {
        bg: 'white',
      },
      _last: {
        borderRightWidth: '1px',
      },
    },
  }
}

const variantStackedTile: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: {
      ...variantTile(props).container,
      borderRadius: 0,
      borderBottomWidth: {
        base: 0,
        lg: '1px',
      },
      borderRightWidth: { base: '1px', lg: 0 },
      _first: {
        borderBottomWidth: {
          base: 0,
          lg: '1px',
        },
        borderRadius: {
          base: `${dbr} ${dbr} 0 0`,
          lg: `${dbr} 0 0 ${dbr}`,
        },
      },
      _last: {
        borderRadius: {
          base: `0 0 ${dbr} ${dbr}`,
          lg: `0 ${dbr} ${dbr} 0`,
        },
        borderBottomWidth: {
          base: '1px',
          lg: '1px',
        },
        borderRightWidth: { lg: '1px' },
      },
    },
  }
}

const variantSimple: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: {
      mr: 2,
      bg: 'transparent',
    },
  }
}

const variants = {
  tile: variantTile,
  stackedTile: variantStackedTile,
  simple: variantSimple,
}

const defaultProps = {
  size: 'md',
  variant: 'base',
  colorScheme: 'primary',
}

export const Radio = {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants,
  defaultProps,
}
