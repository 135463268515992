import { Icon } from '@chakra-ui/react'

export const Mobile = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 21 20" fill="none">
<path d="M9.416 14C9.28339 14 9.15622 14.0527 9.06245 14.1464C8.96868 14.2402 8.916 14.3674 8.916 14.5C8.916 14.6326 8.96868 14.7598 9.06245 14.8536C9.15622 14.9473 9.28339 15 9.416 15H11.416C11.5486 15 11.6758 14.9473 11.7696 14.8536C11.8633 14.7598 11.916 14.6326 11.916 14.5C11.916 14.3674 11.8633 14.2402 11.7696 14.1464C11.6758 14.0527 11.5486 14 11.416 14H9.416ZM7.416 2C6.88557 2 6.37686 2.21071 6.00179 2.58579C5.62671 2.96086 5.416 3.46957 5.416 4V16C5.416 16.5304 5.62671 17.0391 6.00179 17.4142C6.37686 17.7893 6.88557 18 7.416 18H13.416C13.9464 18 14.4551 17.7893 14.8302 17.4142C15.2053 17.0391 15.416 16.5304 15.416 16V4C15.416 3.46957 15.2053 2.96086 14.8302 2.58579C14.4551 2.21071 13.9464 2 13.416 2H7.416ZM6.416 4C6.416 3.73478 6.52136 3.48043 6.70889 3.29289C6.89643 3.10536 7.15078 3 7.416 3H13.416C13.6812 3 13.9356 3.10536 14.1231 3.29289C14.3106 3.48043 14.416 3.73478 14.416 4V16C14.416 16.2652 14.3106 16.5196 14.1231 16.7071C13.9356 16.8946 13.6812 17 13.416 17H7.416C7.15078 17 6.89643 16.8946 6.70889 16.7071C6.52136 16.5196 6.416 16.2652 6.416 16V4Z" fill="currentColor"/>
	</Icon>
)


export const MobileString=`<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.416 14C9.28339 14 9.15622 14.0527 9.06245 14.1464C8.96868 14.2402 8.916 14.3674 8.916 14.5C8.916 14.6326 8.96868 14.7598 9.06245 14.8536C9.15622 14.9473 9.28339 15 9.416 15H11.416C11.5486 15 11.6758 14.9473 11.7696 14.8536C11.8633 14.7598 11.916 14.6326 11.916 14.5C11.916 14.3674 11.8633 14.2402 11.7696 14.1464C11.6758 14.0527 11.5486 14 11.416 14H9.416ZM7.416 2C6.88557 2 6.37686 2.21071 6.00179 2.58579C5.62671 2.96086 5.416 3.46957 5.416 4V16C5.416 16.5304 5.62671 17.0391 6.00179 17.4142C6.37686 17.7893 6.88557 18 7.416 18H13.416C13.9464 18 14.4551 17.7893 14.8302 17.4142C15.2053 17.0391 15.416 16.5304 15.416 16V4C15.416 3.46957 15.2053 2.96086 14.8302 2.58579C14.4551 2.21071 13.9464 2 13.416 2H7.416ZM6.416 4C6.416 3.73478 6.52136 3.48043 6.70889 3.29289C6.89643 3.10536 7.15078 3 7.416 3H13.416C13.6812 3 13.9356 3.10536 14.1231 3.29289C14.3106 3.48043 14.416 3.73478 14.416 4V16C14.416 16.2652 14.3106 16.5196 14.1231 16.7071C13.9356 16.8946 13.6812 17 13.416 17H7.416C7.15078 17 6.89643 16.8946 6.70889 16.7071C6.52136 16.5196 6.416 16.2652 6.416 16V4Z" fill="#003D66"/>
</svg>
`;