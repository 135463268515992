import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePostStep3 = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id?: string,
  itemId?: string,
) => {
  return useMutation(() => api.post(`${ApiRouteEnum.OFFERS}/${id}/step3/${itemId}`), {
    mutationKey: [`post-${ApiRouteEnum.OFFERS}/step3`],
    onSuccess: (data) => {
      successCallBack(data.data)
    },
    onError: (error) => errorCallBack(error),
  })
}
