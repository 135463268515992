export function compareObjects(obj1: object, obj2: object, path: string = '') {
  const changes: any[] = [];

  function compare(obj1: any, obj2: any, path: string) {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      if (obj1 !== obj2) {
        changes.push({
          path,
          first_value: obj1,
          second_value: obj2
        });
      }
      return;
    }

    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    keys.forEach(key => {
      const newPath = path ? `${path}.${key}` : key;
      compare(obj1[key], obj2[key], newPath);
    });
  }

  compare(obj1, obj2, path);
  return changes;
}