import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePutOrganizations = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id: string | undefined,
) => {
  const organizationDetailsEndpoint: string = ApiRouteEnum.ORGANIZATION + `/${id}`
  return useMutation(
    (organizationData: any) => api.patch(organizationDetailsEndpoint, organizationData),
    {
      mutationKey: [organizationDetailsEndpoint],
      onSuccess: (data) => {
        successCallBack(data.data)
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
