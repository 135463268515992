import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { INewsData, IQueryParamsNews } from '../../interfaces/entities/INews'

export const useGetNews = (queryParams?: IQueryParamsNews | undefined) => {
  return useQuery(
    [ApiRouteEnum.NEWS, queryParams],
    () =>
      api.get<INewsData>(ApiRouteEnum.NEWS, {
        params: queryParams,
      }),
    {
      retry: false,
    },
  )
}
