import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { UserEdit } from '../../containers/profile/UserEdit'
import { dummyUser } from '../../data/profile'

export const UserEditPage = () => {
  return (
    <Section>
      <Container>
        <UserEdit profile={dummyUser} />
      </Container>
    </Section>
  )
}
