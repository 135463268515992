import { Button, Heading, useToast } from '@chakra-ui/react'

import { Container } from '../../../components/Container'
import { MainContent } from '../../../components/MainContent'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { Recap } from '../../../containers/sme/Recap'
import { MeetingRecordOverview } from '../../../containers/MeetingRecordOverview'
import { ContractRecap } from '../../../containers/sme/ContractRecap'
import { InsuranceSummary } from '../../../containers/InsuranceSummary'
import { InsuranceDownloads } from '../../../containers/sme/InsuranceDownloads'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetContractById } from '../../../model/api/contracts/usetGetContractById'
import { Loading } from '../../../components/Loading'
import { Duplicate } from '../../../icons'
import React from 'react'
import { useCopyOffer } from '../../../model/api/offers/useCopyOffer'
import { AxiosResponse } from 'axios'
import { IOffersItem } from '../../../model/interfaces/entities/IOffers'
import { FormButtons } from '../../../containers/FormButtons'
import useCloneClickHandler from '../../../model/hooks/useCloneClickHandler'

export const CompletedPage = () => {
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  const toastMessage = useToast()

  const { mutate: mutateCopyOffer, isLoading: isCopyingOffer } = useCopyOffer(
    (data: AxiosResponse<IOffersItem>, openInNewab: boolean = false) => {
      toastMessage({
        title: 'Kopie pojištění byla úspěšně vytvořena',
        status: 'success',
        position: 'top',
      })
      const clonedOfferId = data?.data?.id

      if (typeof clonedOfferId === 'undefined') {
        return
      }

      if (openInNewab) {
        window.open(`/sme/wizard/edit/${clonedOfferId}/1`, '_blank')
      } else {
        navigate(`/sme/wizard/edit/${clonedOfferId}/1`)
      }
    },
    (error) => {
      toastMessage({
        title: 'Kopii pojištění se nepodařilo vytvořit',
        description: error.response?.data.error,
        status: 'error',
        position: 'top',
      })
    },
    id,
  )

  const handleCloneClick = useCloneClickHandler(mutateCopyOffer)
  const successCallBack = () => {
    return
  }

  const errorCallBack = (error: any) => {
    console.error(error)
  }

  const { data, isLoading } = useGetContractById(id, successCallBack, errorCallBack)

  if (isLoading) {
    return <Loading large={true} />
  }

  return (
    <Section>
      <Container>
        <StepsContent>
          <Heading
            sx={{
              fontSize: '5xl',
              fontWeight: 'bold',
              letterSpacing: 'tighter',
              textAlign: 'center',
            }}
            as="h1"
          >
            Sjednané pojištění č. {data?.data.number}
          </Heading>

          <Steps icon="briefcase" currentStep={5} viewOnly={true} passed={5} />

          <InsuranceSummary offer={data?.data} />

          <MainContent>
            <Recap offer={data?.data} />

            <InsuranceDownloads
              insureeEmail={data?.data.applicant.email}
              documents={data?.data.documents || []}
            />

            <ContractRecap offer={data?.data} />

            <MeetingRecordOverview offer={data?.data} />

            <FormButtons
              leftButtons={
                <Button
                  isLoading={isCopyingOffer}
                  variant="outline"
                  size="sm"
                  type="button"
                  onMouseDown={handleCloneClick}
                >
                  <Duplicate color="secondary.500" />
                  Klonovat
                </Button>
              }
            />
          </MainContent>
        </StepsContent>
      </Container>
    </Section>
  )
}
