import React, { ChangeEvent, useContext, useRef, useState } from 'react'
import {
  Box,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverContent,
  useDisclosure,
} from '@chakra-ui/react'
import { debounce } from '../utils/debounce'
import { InputGroup } from './InputGroup'
import { IAddressSuggestion } from '../model/interfaces/entities/IAddressSuggestion'
import { useGetAddress } from '../model/api/address/useGetAddress'
import {
  transformAddressToStreet,
  transformAddressToStreetNumber,
} from '../model/utils/addressUtils'
import { FormLabel } from './FormLabel'
import { FormIsDisabledContext } from '../model/contexts/FormIsDisabledContext'

interface IAddressAutocompleteProps {
  onAddressAccepted: (address: IAddressSuggestion) => void
  isCompanyHq?: boolean
  extended?: boolean
}

const AddressAutocomplete: React.FC<IAddressAutocompleteProps> = (props) => {
  const { isOpen: isAddressOpen, onOpen: onAddressOpen, onClose: onAddressClose } = useDisclosure()
  const { onAddressAccepted, isCompanyHq = false, extended = false } = props
  const [street, setStreet] = useState('')
  const { data } = useGetAddress(street)
  const inputRef = useRef<HTMLInputElement>()
  const formIsDisabled = useContext(FormIsDisabledContext)

  React.useEffect(() => {
    if (inputRef.current && data?.data && street) {
      setTimeout(() => {
        inputRef?.current?.focus()
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to run this effect only on street change
  }, [street])

  const handleStreetChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value) {
      setStreet(value)
      onAddressOpen()
    }
  }, 800)

  const handleSuggestionClick = (suggestion: IAddressSuggestion) => {
    onAddressAccepted(suggestion)
    onAddressClose()
  }

  const transformAddressFirstLine = (address: IAddressSuggestion): string => {
    const parts = [transformAddressToStreet(address), transformAddressToStreetNumber(address)]

    if (address.type) {
      parts.push(address.type)
    }

    return parts.join(' ')
  }

  return (
    <>
      <FormLabel tooltip="Zadejte název ulice a číslo popisné/orientační a vyberte ze seznamu">
        {isCompanyHq ? 'Adresa sídla' : 'Adresa'}
      </FormLabel>
      <Popover isOpen={isAddressOpen} matchWidth closeOnBlur closeOnEsc>
        <PopoverAnchor>
          <InputGroup
            placeholder="Např.: Dlouhá 123"
            htmlSize={24}
            autoComplete="off"
            inputMode="text"
            onChange={(event) => {
              handleStreetChange(event)
            }}
            // onFocus={(event) => handleStreetChange(event)}
            ref={inputRef}
            isDisabled={extended || formIsDisabled}
          />
        </PopoverAnchor>

        {typeof data?.data !== 'undefined' && data?.data.length > 0 && (
          <PopoverContent sx={{ display: 'grid', justifyContent: 'stretch' }}>
            <PopoverArrow />
            {data?.data.map((suggestion: IAddressSuggestion, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'grid',
                    px: 2,
                    py: 1,
                    justifyContent: 'start',
                    cursor: 'pointer',
                    textAlign: 'start',
                    _hover: { backgroundColor: 'gray.100' },
                  }}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <span>{transformAddressFirstLine(suggestion)}</span>
                  <small>
                    {suggestion.postalCode} {suggestion.city}
                  </small>
                </Box>
              )
            })}
          </PopoverContent>
        )}
      </Popover>
    </>
  )
}

export default AddressAutocomplete as React.ComponentType<IAddressAutocompleteProps>
