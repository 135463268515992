import { keyframes } from '@emotion/react'

export const fadeInLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(4rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`
