import { IAvailableApps } from './availableApps'
import carkulkaLogo from '../assets/images/logo-carkulka.svg'
import chaloupkaLogo from '../assets/images/logo-chaloupka.svg'
import smeLogo from '../assets/images/logo-sme-new.svg'
import cestovkoLogo from '../assets/images/logo-cestovko.svg'

interface GetCurrentAppLogoProps {
  app: IAvailableApps['appId']
}

export const getCurrentAppLogo = ({ app }: GetCurrentAppLogoProps) => {
  let logo = null

  switch (app) {
    default:
    case 'carkulka':
      logo = carkulkaLogo
      break
    case 'chaloupka':
      logo = chaloupkaLogo
      break
    case 'sme':
      logo = smeLogo
      break
    case 'cestovko':
      logo = cestovkoLogo
      break
  }

  return logo
}
