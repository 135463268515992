import { Flex, Heading, useBoolean } from '@chakra-ui/react'

import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { ConceptsList } from '../../containers/concepts'
import { PageToolbar } from '../../containers/PageToolbar'
import { conceptsProperties } from '../../data/concepts'
import { SearchButton } from '../../containers/search/SearchButton'
import { PageContent } from '../../containers/PageContent'
import { useState } from 'react'
import { ConceptsPropertySearchForm } from '../../containers/search/chaloupka/ConceptsPropertySearchForm'
import { IPageLimit, IParams } from '../../model/interfaces/entities/IContracts'
import { useSearchParams } from 'react-router-dom'
import { CONCEPTS_LIST_PAGE_LIMIT } from './constats'
import { convertParamsToSearchParams } from '../../model/utils/urlUtils'
import { omit } from 'lodash'

export const ConceptsPropertyPage = () => {
  const [isOpen, setOpen] = useBoolean(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [params, setRawParams] = useState<IParams & IPageLimit>({
    number: searchParams.get('number') || undefined,
    dateCreatedFrom: searchParams.get('dateCreatedFrom') || undefined,
    dateCreatedTo: searchParams.get('dateCreatedTo') || undefined,
    companyId: searchParams.get('companyId') || undefined,
    client: searchParams.get('client') || undefined,
    in: searchParams.get('in') || undefined,
    organizationId: searchParams.get('organizationId') || undefined,
    userId: searchParams.get('userId') || undefined,
    status: searchParams.get('status') ? searchParams.get('status')!.split(',') : [],
    page: searchParams.get('page') ? parseInt(searchParams.get('page')!) : 1,
    limit: CONCEPTS_LIST_PAGE_LIMIT,
  })

  const setParams = (newParams: IParams & IPageLimit) => {
    setSearchParams(convertParamsToSearchParams(omit(newParams, ['limit'])))
    setRawParams(newParams)
  }

  return (
    <Section>
      <Container>
        <PageContent>
          <PageToolbar
            leftElements={
              <Flex sx={{ gap: 4, alignItems: 'center' }}>
                <Heading as="h1">Rozpracované nabídky</Heading>
              </Flex>
            }
            rightElements={
              <Flex sx={{ gap: 4 }}>
                <SearchButton isOpen={isOpen} setOpen={setOpen} />
              </Flex>
            }
          />

          {isOpen && <ConceptsPropertySearchForm setParams={setParams} params={params} />}

          <ConceptsList
            concepts={conceptsProperties}
            withPaginator
            params={params}
            setParams={setParams}
          />
        </PageContent>
      </Container>
    </Section>
  )
}
