import { useState } from 'react'
import { Button } from '@chakra-ui/react'

import { Refresh } from '../icons'

export const SaveAndRecalculateButton = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <Button
      variant="outline"
      isDisabled={isSubmitting}
      isLoading={isSubmitting}
      loadingText="Přepočítávám..."
      onClick={() => {
        setIsSubmitting(true)

        setTimeout(() => setIsSubmitting(false), 1000)
      }}
    >
      <Refresh color="secondary.500" />
      Uložit a přepočítat
    </Button>
  )
}
