import { useParams } from 'react-router-dom'

import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { NewsDetail } from '../../containers/news/NewsDetail'
import { PageContent } from '../../containers/PageContent'
import { INewsItemV2 } from '../../model/interfaces/entities/INews'
import { useGetNewsDetails } from '../../model/api/news/useGetNewsDetails'
import { Spinner, useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useGetNews } from '../../model/api/news/useGetNews'

export const NewsDetailPage = () => {
  let { id } = useParams<'id'>()
  const { data, error, isError, isLoading } = useGetNewsDetails(id)
  const {
    data: otherNewsData,
    isLoading: otherNewsIsLoading,
    isError: otherNewsIsError,
    error: otherNewsError,
  } = useGetNews()
  const toastMessage = useToast()
  const otherNews: INewsItemV2[] | undefined = otherNewsData?.data.items.filter(
    (n) => n.id !== Number(id),
  )

  useEffect(() => {
    ;(isError || otherNewsIsError) &&
      toastMessage({
        title: 'Došlo k chybě',
        description: (error || otherNewsError) + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError, otherNewsError])
  return (
    <Section>
      <Container>
        <PageContent>
          {(isLoading || otherNewsIsLoading) && <Spinner />}
          {data?.data && otherNews && <NewsDetail {...data.data} otherNews={otherNews} />}
        </PageContent>
      </Container>
    </Section>
  )
}
