import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'
import { PartsStyleFunction, mode } from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleFunction = (props) => {
  return {
    header: {
      py: 4,
      background: mode('white', 'gray.800')(props),
      boxShadow: 'header',
    },
    content: {
      display: 'grid',
      gridTemplateColumns: {
        base: 'auto auto',
        xl: 'auto 1fr',
      },
      gap: {
        xs: 4,
        xl: 6,
      },
      alignItems: 'center',
      justifyContent: { base: 'space-between', xl: 'start' },
    },
    logo: {},
  }
}

export const Header: ComponentMultiStyleConfig = {
  parts: ['header', 'content', 'logo'],
  baseStyle,
}
