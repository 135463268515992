import {
  Box,
  ButtonGroup,
  Editable,
  EditablePreview,
  Flex,
  forwardRef,
  IconButton,
  Select,
  SelectProps,
  useEditableControls,
  useEditableState,
  useOutsideClick,
} from '@chakra-ui/react'
import { ChangeEvent, useRef, useState } from 'react'
import { Check, Close, EditFilled } from '../icons'

export interface TinySelectProps extends SelectProps {}

interface EditableInputControlProps {
  options: (string | number)[]
  value?: string
  onChange: (value: string) => void
  isDisabled?: boolean
}

export const TinySelect = forwardRef((props: TinySelectProps, ref) => {
  return (
    <Select
      ref={ref}
      size="xs"
      sx={{ maxH: 5, m: 0, py: 0.5 }}
      rootProps={{
        sx: {
          maxH: 5,
        },
      }}
      {...props}
    />
  )
})

const EditableSelectControl = forwardRef(
  ({ options, value, onChange, isDisabled }: EditableInputControlProps, ref) => {
    const { isEditing, onSubmit, onCancel } = useEditableState()
    const selectRef = useRef(null)
    useOutsideClick({
      ref: selectRef,
      handler: () => {
        if (isEditing) onCancel()
      },
    })

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
      onChange(e.target.value)
      onSubmit()
    }

    if (!isEditing) return null
    return (
      <Box ref={selectRef}>
        <TinySelect isDisabled={isDisabled} ref={ref} value={value} onChange={handleChange}>
          {options.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </TinySelect>
      </Box>
    )
  },
)

export const format = (value: string) => {
  if (!value) return ''

  if (Number.isNaN(parseFloat(value))) return value

  // Format value to czech format
  const formattedValue = new Intl.NumberFormat('cs-CZ').format(parseFloat(value))

  return formattedValue
}

export const parse = (value: string) => {
  // Replace any whitespace character with empty string
  const parsedValue = value.replace(/\s/gi, '')

  return parsedValue
}

export const EditableControls = ({
  showSubmitButton = false,
  hideCancelButton = false,
  isDisabled = false,
}: {
  showSubmitButton?: boolean
  hideCancelButton?: boolean
  isDisabled?: boolean
}) => {
  const { isEditing, getCancelButtonProps, getSubmitButtonProps, getEditButtonProps } =
    useEditableControls()
  const iconSize = 4

  return isEditing ? (
    <ButtonGroup justifyContent="center">
      {showSubmitButton && (
        <IconButton
          variant="ghost"
          colorScheme="white"
          aria-label="Uložit"
          icon={<Check width={iconSize} height={iconSize} />}
          isDisabled={isDisabled}
          {...getSubmitButtonProps()}
        />
      )}

      {!hideCancelButton && (
        <IconButton
          variant="ghost"
          colorScheme="white"
          aria-label="Zrušit"
          icon={<Close width={iconSize} height={iconSize} />}
          isDisabled={isDisabled}
          {...getCancelButtonProps()}
        />
      )}
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton
        variant="ghost"
        colorScheme="white"
        aria-label="Upravit limit"
        sx={{ py: 2 }}
        isDisabled={isDisabled}
        {...getEditButtonProps()}
      >
        <EditFilled color="gray.500" width={iconSize} height={iconSize} />
      </IconButton>
    </Flex>
  )
}

export const EditableSelect = ({
  defaultValue,
  onSubmit,
  options,
  editablePreviewSx,
  isDisabled = false,
  viewOnly = false,
}: {
  defaultValue: string | number | boolean | undefined
  onSubmit: (nextValue: string) => void
  options: (string | number)[]
  editablePreviewSx?: object
  isDisabled?: boolean
  viewOnly?: boolean
}) => {
  const [value, setValue] = useState(defaultValue?.toString() || options[0].toString())
  const handleChange = (nextValue: string) => {
    const formatted = nextValue
    setValue(formatted)
    onSubmit(formatted)
  }

  return (
    <Editable isDisabled={isDisabled || viewOnly} value={value} sx={{ display: 'flex', gap: 1 }}>
      <EditablePreview
        sx={{
          p: 0,
          fontSize: 'sm',
          textDecoration: 'underline',
          textDecorationStyle: 'dotted',
          ...editablePreviewSx,
        }}
        color={isDisabled ? 'gray.500' : 'current'}
      />

      <EditableSelectControl
        isDisabled={isDisabled}
        value={value}
        options={options}
        onChange={handleChange}
      />

      <EditableControls hideCancelButton isDisabled={isDisabled} />
    </Editable>
  )
}
