import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { OfferGridProvider } from '../../../context/OfferGridContext'
import { OfferPropertyHeaderColumn } from './OfferPropertyHeaderColumn'
import { IItem } from '../../../model/interfaces/entities/IItem'
import { OfferPropertyColumnWrapper } from './OfferPropertyColumnWrapper'

export const OfferPropertyGrid = ({
  item,
  items,
  itemsWithDiscrepancy,
  selectingItemsIDs,
  setSelectingItemsIDs,
  viewOnly,
  offerId
}: {
  item?: IItem
  items?: IItem[]
  itemsWithDiscrepancy?: IItem[]
  selectingItemsIDs?: number[]
  setSelectingItemsIDs?: Function
  viewOnly?: boolean
  offerId?: number
}) => {
  const [openedColumns, setOpenedColumns] = useState<string[]>([])
  const [limitsNames, setLimitsNames] = useState<string[]>([])
  const handleSectionTitleClick = (sectionId: string) => {
    let newColumns = []

    if (openedColumns.includes(sectionId)) {
      newColumns = openedColumns.filter((column) => column !== sectionId)
    } else {
      newColumns = [...openedColumns, sectionId]
    }

    setOpenedColumns(newColumns)
  }

  useEffect(() => {
    let limitsArray: string[] = []
    if (items && items.length > 0) {
      items.map((item) => {
        item.limits.map((limit) => limitsArray.push(limit.name))
      })
      limitsArray = Array.from(new Set(limitsArray))
      setLimitsNames(limitsArray)
    }
    if((!items || items.length === 0) && itemsWithDiscrepancy){
      itemsWithDiscrepancy.map((item) => {
        item.limits.map((limit) => limitsArray.push(limit.name))
      })
      limitsArray = Array.from(new Set(limitsArray))
      setLimitsNames(limitsArray)
    }
  }, [items])

  return (
    <OfferGridProvider openedColumns={openedColumns} onSectionTitleClick={handleSectionTitleClick}>
      <Box sx={{ display: 'flex', justifyContent: 'start', maxW: '100%', overflowY: 'auto' }}>
        <Box
          sx={{
            position: 'sticky',
            left: 0,
            bg: 'white',
            zIndex: '100',
            display: "flex",
            flexDirection: "column-reverse",
            background: "gray.50"
          }}
        >
          <OfferPropertyHeaderColumn
            items={items && items.length > 0 ? items : itemsWithDiscrepancy}
            limitNames={limitsNames}
          />
        </Box>
        {(items || []).length > 0 && (
          <OfferPropertyColumnWrapper
            item={item}
            items={items!}
            status={'success'}
            selectingItemsIDs={selectingItemsIDs}
            setSelectingItemsIDs={setSelectingItemsIDs}
            viewOnly={viewOnly}
            offerId={offerId}
          />
        )}
        {(itemsWithDiscrepancy || []).length > 0 && (
          <OfferPropertyColumnWrapper
            item={item}
            items={itemsWithDiscrepancy!}
            status={'error'}
            setSelectingItemsIDs={setSelectingItemsIDs}
            selectingItemsIDs={selectingItemsIDs}
            viewOnly={viewOnly}
            offerId={offerId}
          />
        )}
      </Box>
    </OfferGridProvider>
  )
}
