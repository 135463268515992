import { Icon } from '@chakra-ui/react'

export const Add = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M6.00002 10C6.00002 9.86739 6.05269 9.74021 6.14646 9.64645C6.24023 9.55268 6.36741 9.5 6.50002 9.5H9.50002V6.5C9.50002 6.36739 9.55269 6.24021 9.64646 6.14645C9.74023 6.05268 9.86741 6 10 6C10.1326 6 10.2598 6.05268 10.3536 6.14645C10.4473 6.24021 10.5 6.36739 10.5 6.5V9.5H13.5C13.6326 9.5 13.7598 9.55268 13.8536 9.64645C13.9473 9.74021 14 9.86739 14 10C14 10.1326 13.9473 10.2598 13.8536 10.3536C13.7598 10.4473 13.6326 10.5 13.5 10.5H10.5V13.5C10.5 13.6326 10.4473 13.7598 10.3536 13.8536C10.2598 13.9473 10.1326 14 10 14C9.86741 14 9.74023 13.9473 9.64646 13.8536C9.55269 13.7598 9.50002 13.6326 9.50002 13.5V10.5H6.50002C6.36741 10.5 6.24023 10.4473 6.14646 10.3536C6.05269 10.2598 6.00002 10.1326 6.00002 10Z" fill="currentColor"/>
<path d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1572 14.1566 18 12.1217 18 10C18 7.87827 17.1572 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84286 5.84344 2.00001 7.87827 2.00001 10C2.00001 12.1217 2.84286 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18V18ZM10 17C9.08075 17 8.1705 16.8189 7.32122 16.4672C6.47194 16.1154 5.70027 15.5998 5.05026 14.9497C4.40025 14.2997 3.88463 13.5281 3.53285 12.6788C3.18107 11.8295 3.00001 10.9193 3.00001 10C3.00001 9.08075 3.18107 8.1705 3.53285 7.32122C3.88463 6.47194 4.40025 5.70026 5.05026 5.05025C5.70027 4.40024 6.47194 3.88463 7.32122 3.53284C8.1705 3.18106 9.08075 3 10 3C11.8565 3 13.637 3.7375 14.9498 5.05025C16.2625 6.36301 17 8.14348 17 10C17 11.8565 16.2625 13.637 14.9498 14.9497C13.637 16.2625 11.8565 17 10 17Z" fill="currentColor"/>
	</Icon>
)


export const AddString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.00002 10C6.00002 9.86739 6.05269 9.74021 6.14646 9.64645C6.24023 9.55268 6.36741 9.5 6.50002 9.5H9.50002V6.5C9.50002 6.36739 9.55269 6.24021 9.64646 6.14645C9.74023 6.05268 9.86741 6 10 6C10.1326 6 10.2598 6.05268 10.3536 6.14645C10.4473 6.24021 10.5 6.36739 10.5 6.5V9.5H13.5C13.6326 9.5 13.7598 9.55268 13.8536 9.64645C13.9473 9.74021 14 9.86739 14 10C14 10.1326 13.9473 10.2598 13.8536 10.3536C13.7598 10.4473 13.6326 10.5 13.5 10.5H10.5V13.5C10.5 13.6326 10.4473 13.7598 10.3536 13.8536C10.2598 13.9473 10.1326 14 10 14C9.86741 14 9.74023 13.9473 9.64646 13.8536C9.55269 13.7598 9.50002 13.6326 9.50002 13.5V10.5H6.50002C6.36741 10.5 6.24023 10.4473 6.14646 10.3536C6.05269 10.2598 6.00002 10.1326 6.00002 10Z" fill="#003D66"/>
<path d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1572 14.1566 18 12.1217 18 10C18 7.87827 17.1572 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84286 5.84344 2.00001 7.87827 2.00001 10C2.00001 12.1217 2.84286 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18V18ZM10 17C9.08075 17 8.1705 16.8189 7.32122 16.4672C6.47194 16.1154 5.70027 15.5998 5.05026 14.9497C4.40025 14.2997 3.88463 13.5281 3.53285 12.6788C3.18107 11.8295 3.00001 10.9193 3.00001 10C3.00001 9.08075 3.18107 8.1705 3.53285 7.32122C3.88463 6.47194 4.40025 5.70026 5.05026 5.05025C5.70027 4.40024 6.47194 3.88463 7.32122 3.53284C8.1705 3.18106 9.08075 3 10 3C11.8565 3 13.637 3.7375 14.9498 5.05025C16.2625 6.36301 17 8.14348 17 10C17 11.8565 16.2625 13.637 14.9498 14.9497C13.637 16.2625 11.8565 17 10 17Z" fill="#003D66"/>
</svg>
`;