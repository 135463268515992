import { Box, Button, FormControl, Select, Text } from '@chakra-ui/react'
import { FormLabel } from '../../../../components/FormLabel'
import { IPropertyOfferInsurance } from '../../../../data/offers/property'
import { Percent } from '../../../../icons'
import { formatPrice } from '../../../../utils/formatPrice'
import { EditablePrice } from '../../../EditablePrice'
import { InsuranceLimitsSelector } from './InsuranceLimitsSelector'

const InsuranceInfo = ({
  label,
  name,
  value,
  participation,
}: {
  label: string
  name: string
  value: number
  participation: number
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: { base: 1, lg: 2 },
        justifyContent: 'space-between',
        p: 2,
        gridTemplateColumns: { base: '1fr', lg: '1fr 1fr' },
        alignContent: 'start',
        w: '100%',
        bg: 'gray.100',
        borderRadius: 'md',
      }}
    >
      <Box sx={{ display: 'grid', gap: 1 }}>
        <Text sx={{ fontWeight: 'semibold', lineHeight: '1.5rem' }}>{label}</Text>

        <Text sx={{ fontSize: 'sm' }}>{name}</Text>
      </Box>

      <Box sx={{ display: 'flex', gap: 1, alignItems: 'start', justifyItems: 'end' }}>
        <Box sx={{ display: 'grid', gap: 1, justifyItems: 'end' }}>
          <EditablePrice
            defaultValue={formatPrice({ price: value })}
            onSubmit={(v) => console.log(v)}
            editablePreviewSx={{ fontWeight: 'semibold' }}
          />

          <Text sx={{ mr: 6, fontSize: 'sm' }}>{formatPrice({ price: participation })}</Text>
        </Box>
      </Box>
    </Box>
  )
}

const ControlsTotals = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: 4,
      }}
    >
      {children}
    </Box>
  )
}

const Controls = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        alignItems: 'end',
        columnGap: 4,
        rowGap: 2,
      }}
    >
      {children}
    </Box>
  )
}

const Totals = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: { base: 2, lg: 4 },
      }}
    >
      {children}
    </Box>
  )
}

const Total = ({
  label,
  size = 'md',
  color = 'gray.100',
  price,
}: {
  label: string
  size?: string
  color?: string
  price: number
}) => {
  return (
    <Box sx={{ display: 'grid', gap: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          bgColor: color,
          borderRadius: 'base',
          lineHeight: '1.25rem',
        }}
      >
        <Text sx={{ fontSize: 'base' }}>{label}</Text>
        <Text sx={{ fontSize: size === 'lg' ? 'xl' : 'base', fontWeight: 'semibold' }}>
          {formatPrice({ price })}
        </Text>
      </Box>
    </Box>
  )
}

export const BasicInfoTab = ({
  status,
  color,
  insurances,
}: {
  status: string
  color: string
  insurances: Array<any>
}) => {
  return (
    <Box
      sx={{
        display: { base: 'grid' },
        gridTemplateColumns: { base: '1fr', md: '2fr 1fr' },
        alignItems: 'start',
        columnGap: 8,
        rowGap: 4,
      }}
    >
      <Box sx={{ display: 'grid', gap: 4, alignContent: 'start' }}>
        {insurances &&
          insurances.length > 0 &&
          insurances.map(({ label, name, value, participation }: IPropertyOfferInsurance) => {
            return (
              <Box key={label} sx={{ display: 'grid', gap: 2 }}>
                <InsuranceInfo
                  label={label}
                  name={name}
                  value={value}
                  participation={participation}
                />

                <InsuranceLimitsSelector />
              </Box>
            )
          })}
      </Box>

      {/* Controls and totals */}
      <ControlsTotals>
        <Totals>
          <Total label="Pojistné za rok" price={23749} size="lg" color={color} />
        </Totals>

        <Controls>
          <FormControl sx={{ gap: 3 }}>
            <FormLabel sx={{ fontWeight: 'normal' }}>Frekvence platby</FormLabel>

            <Select sx={{ w: '100%' }}>
              <option value="monthly">Měsíční</option>
              <option value="quarterly">Čtvrtletní</option>
              <option value="semiyearly">Pololetní</option>
              <option value="yearly">Roční</option>
            </Select>
          </FormControl>

          <Button variant="outline">
            <Percent color="secondary.500" />
            Slevy
          </Button>
        </Controls>
      </ControlsTotals>
    </Box>
  )
}
