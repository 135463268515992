import { Box, BoxProps, Spinner } from '@chakra-ui/react'

interface LoadingOverlayProps extends BoxProps {
  children: React.ReactNode
  isLoading?: boolean
}

export const LoadingOverlay = ({ children, isLoading = false, ...rest }: LoadingOverlayProps) => {
  return (
    <Box sx={{ position: 'relative' }} {...rest}>
      <Box sx={{ position: 'relative' }}>{children}</Box>

      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            bg: 'rgba(255, 255, 255, 0.5)',
            cursor: 'progress',
            inset: 0,
            zIndex: 'docked',
          }}
        >
          <Spinner sx={{ position: 'absolute', top: '50%', left: '50%' }} />
        </Box>
      )}
    </Box>
  )
}
