import { Flex, FormControl, Select, Switch } from '@chakra-ui/react'

import { FormLabel } from '../../components/FormLabel'
import { SwitchControl } from '../../components/SwitchControl'
import { CompaniesSelect } from '../CompaniesSelect'
import { IQueryParamsNews } from '../../model/interfaces/entities/INews'

export const NewsFilterForm = ({
  queryParams,
  setQueryParams,
}: {
  queryParams: IQueryParamsNews
  setQueryParams: Function
}) => {
  return (
    <Flex>
      <FormControl>
        <FormLabel>Filtrovat</FormLabel>

        <Flex sx={{ gap: 4, flexWrap: 'wrap' }}>
          <CompaniesSelect
            onChange={(e) => {
              setQueryParams({ ...queryParams, page: 1, companyId: e.target.value })
            }}
          />

          <Select
            onChange={(e) => {
              setQueryParams({ ...queryParams, page: 1, priority: e.target.value })
            }}
          >
            <option value="">Dle priority</option>
            <option value="standard">Standard</option>
            <option value="high">High</option>
          </Select>

          <SwitchControl>
            <Switch
              size="md"
              id="unreadOnly"
              onChange={(e) => {
                setQueryParams({ ...queryParams, page: 1, unreadOnly: e.target.checked ? 1 : 0 })
              }}
            />
            <FormLabel htmlFor="unreadOnly" variant="switch">
              Pouze nepřečtené
            </FormLabel>
          </SwitchControl>
        </Flex>
      </FormControl>
    </Flex>
  )
}
