import { Button } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { MainContent } from '../../components/MainContent'
import { FormButtons } from '../FormButtons'
import { Save } from '../../icons'
import { AdditionalVehicleInfo } from '../AdditionalVehicleInfo'
import { AdditionalInsureeInfo } from '../AdditionalInsureeInfo'

interface Step4FormProps {
  onSubmit: Function
}

export interface Step4FormValues {
  vin: string
  tp: string
  spz: string
  spzType: string
  vehicleSpecification: string
  insureeType: string
  bcn: string
  namePrefix: string
  firstName: string
  lastName: string
  namePostfix: string
  street: string
  houseNumber: string
  mobilePhonePrefix: string
  mobilePhoneNumber: string
  landlinePrefix: string
  landlineNumber: string
  email: string
  acceptECommunication: boolean
}

export const Step4Form = ({ onSubmit }: Step4FormProps) => {
  const methods = useForm<Step4FormValues>({
    defaultValues: {
      vin: 'TMBLK9NE4J0338194',
      spzType: 'stable',
      insureeType: '1',
      bcn: '7505250000',
      firstName: 'Jan',
      lastName: 'Novák',
      street: 'Hlavní třída',
      houseNumber: '331',
      mobilePhonePrefix: '+420',
      landlinePrefix: '+420',
      email: 'jan.novak@email.cz',
      acceptECommunication: true,
    },
  })
  const {
    handleSubmit,
    // register,
    formState: { isSubmitting },
  } = methods

  const onSubmitForm = (values: Step4FormValues) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(values)
        resolve(true)
      }, 500)
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <MainContent>
          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Doplňující informace o vozidle</FormSectionTitle>

              <FormSectionDescription>
                Doplňte informace o vozidle, jako je bližší specifikace nebo jeho poškození.
              </FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              <AdditionalVehicleInfo />
            </FormSectionBody>
          </FormSection>

          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Doplňující informace o pojistníkovi</FormSectionTitle>

              <FormSectionDescription>
                Doplňte informace o pojistníkovi a dalších osobách pro sjednání pojištění.
              </FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              <AdditionalInsureeInfo />
            </FormSectionBody>
          </FormSection>

          <FormButtons
            rightButtons={
              <>
                <Button variant="outline" disabled={isSubmitting} type="button">
                  Uložit
                </Button>
                <Button
                  colorScheme="primary"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  loadingText="Ukládám..."
                  type="submit"
                >
                  <Save />
                  Uložit a pokračovat na shrnutí
                </Button>
              </>
            }
          />
        </MainContent>
      </form>
    </FormProvider>
  )
}
