import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ColorModeScript } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/toast'

import { theme } from './theme'
import { App } from './App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const container = document.getElementById('app')
const root = createRoot(container!)
const { ToastContainer } = createStandaloneToast()
const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <BrowserRouter>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
      <ToastContainer />
    </BrowserRouter>
  </React.StrictMode>,
)
