import { useNavigate } from 'react-router-dom'

import { Container } from '../../../components/Container'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { Step1Form, Step1FormValues } from '../../../containers/carkulka/Step1Form'

export const Step1 = () => {
  const navigate = useNavigate()

  const handleSubmit = (values: Step1FormValues) => {
    navigate('/carkulka/wizard/2')
  }

  return (
    <Section>
      <Container>
        <StepsContent>
          <Steps icon="car" currentStep={1} />

          <Step1Form onSubmit={handleSubmit} />
        </StepsContent>
      </Container>
    </Section>
  )
}
