import React from 'react'
import { Box } from '@chakra-ui/react'

interface MainContentProps {
  children: React.ReactNode
}

export const MainContent = ({ children }: MainContentProps) => {
  return <Box sx={{ display: 'grid', gap: 0 }}>{children}</Box>
}
