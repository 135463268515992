import { Icon } from '@chakra-ui/react'

export const CheckCircle = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2V2ZM10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C11.8565 17 13.637 16.2625 14.9497 14.9497C16.2625 13.637 17 11.8565 17 10C17 8.14348 16.2625 6.36301 14.9497 5.05025C13.637 3.7375 11.8565 3 10 3ZM13.358 7.646C13.4408 7.72873 13.492 7.8379 13.5026 7.95449C13.5132 8.07108 13.4826 8.18769 13.416 8.284L13.358 8.354L9.354 12.358C9.27127 12.4408 9.1621 12.492 9.04551 12.5026C8.92892 12.5132 8.81231 12.4826 8.716 12.416L8.646 12.358L6.646 10.358C6.55742 10.2698 6.50508 10.1516 6.4993 10.0268C6.49352 9.90191 6.53472 9.77941 6.61478 9.68342C6.69483 9.58742 6.80794 9.5249 6.93182 9.50816C7.05569 9.49142 7.18134 9.52169 7.284 9.593L7.354 9.651L9 11.298L12.651 7.646C12.7448 7.55226 12.8719 7.49961 13.0045 7.49961C13.1371 7.49961 13.2642 7.55226 13.358 7.646V7.646Z" fill="currentColor"/>
	</Icon>
)


export const CheckCircleString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2V2ZM10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C11.8565 17 13.637 16.2625 14.9497 14.9497C16.2625 13.637 17 11.8565 17 10C17 8.14348 16.2625 6.36301 14.9497 5.05025C13.637 3.7375 11.8565 3 10 3ZM13.358 7.646C13.4408 7.72873 13.492 7.8379 13.5026 7.95449C13.5132 8.07108 13.4826 8.18769 13.416 8.284L13.358 8.354L9.354 12.358C9.27127 12.4408 9.1621 12.492 9.04551 12.5026C8.92892 12.5132 8.81231 12.4826 8.716 12.416L8.646 12.358L6.646 10.358C6.55742 10.2698 6.50508 10.1516 6.4993 10.0268C6.49352 9.90191 6.53472 9.77941 6.61478 9.68342C6.69483 9.58742 6.80794 9.5249 6.93182 9.50816C7.05569 9.49142 7.18134 9.52169 7.284 9.593L7.354 9.651L9 11.298L12.651 7.646C12.7448 7.55226 12.8719 7.49961 13.0045 7.49961C13.1371 7.49961 13.2642 7.55226 13.358 7.646V7.646Z" fill="#003D66"/>
</svg>
`;