import { Alert, Image, Table, TableCellProps, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { formatPrice } from '../../utils/formatPrice'
import { logos } from '../../utils/companiesLogos'
import { formatDate } from '../../utils/formatDate'

interface RecapTableRowProps extends TableCellProps {
  col1: string | React.ReactNode
  col2?: string | React.ReactNode
  col3?: string | React.ReactNode
  isHighlighted?: boolean
}

export const RecapTableRow = ({ col1, col2, col3, isHighlighted, sx, ...rest }: RecapTableRowProps) => {
  return (
    <Tr sx={{ m: 0, p: 0 }}>
      <Td sx={{ w: '15rem', p: 1, ...sx }}>
        {col1 && <Text sx={{ fontWeight: isHighlighted ? 'semibold' : 'medium' }}>{col1}</Text>}
      </Td>
      <Td sx={{ p: 1, ...sx }}>{col2 && col2}</Td>
      {col3 && (
        <Td sx={{ p: 1, fontWeight: isHighlighted ? 'semibold' : 'normal', textAlign: 'end', ...sx }}>
          {col3}
        </Td>
      )}
    </Tr>
  )
}

export const Recap = () => {
  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>Rekapitulace pojištění</FormSectionTitle>

        <FormSectionDescription>
          <Image src={logos['kooperativa']} alt="Kooperativa" width="64px" height="48px" />
        </FormSectionDescription>
      </FormSectionHeader>

      <FormSectionBody>
        <Table variant="unstyled">
          <Tbody>
            <RecapTableRow
              col1="POV"
              col2="limit 200/200 mil. Kč"
              col3={formatPrice({ price: 6822 })}
            />
            <RecapTableRow
              col1="HAV"
              col2="Allrisk,  Spoluúčast 3%, min. 3 000 Kč"
              col3={formatPrice({ price: 16927 })}
            />
            <RecapTableRow
              col1="Připojištění"
              col2="Pojištení všech vyhledových skel limit 10000 Kč"
              col3={formatPrice({ price: 1512 })}
            />
            <RecapTableRow isHighlighted col1="Celkem" col3={formatPrice({ price: 23749 })} />
          </Tbody>
        </Table>

        <Table variant="unstyled">
          <Tbody>
            <RecapTableRow
              col1="Splatnost 1. pojistného"
              col2={formatDate({ date: new Date(2022, 0, 31) })}
            />
            <RecapTableRow col1="Způsob sjednání" col2="Zaplacením" />
            <RecapTableRow col1="Splátky pojistného" col2="Roční" />
            <RecapTableRow
              col1="Datum sjednání"
              col2={formatDate({ date: new Date(2021, 10, 28) })}
            />
          </Tbody>
        </Table>

        <Alert colorScheme="orange">
          <Text>
            Pokud nebude první pojistné uhrazeno v termínu, pojistná smlouva nevznikne!
            <br />
            Pojištění vyžaduje provedení prohlídky vozidla technikem od Global Expert.
          </Text>
        </Alert>
      </FormSectionBody>
    </FormSection>
  )
}
