import { ChangeEventHandler } from "react"
import { FormControl, FormLabel, Select } from "@chakra-ui/react"

export const PaymentFrequency = ({
  value,
  onChange,
}: {
  value?: string
  onChange?: ChangeEventHandler<HTMLSelectElement> | undefined
}) => {
  return (
    <FormControl>
      <FormLabel>Frekvence platby</FormLabel>

      <Select value={value} onChange={onChange && onChange}>
        <option value="yearly">Roční</option>
        <option value="semiyearly">Pololetní</option>
        <option value="quarterly">Čtvrtletní</option>
        <option value="monthly">Měsíční</option>
      </Select>
    </FormControl>
  )
}