import { AppAlertProps } from '../components/AppAlert'

export const alerts: Array<AppAlertProps> = [
  // {
  //   title: 'Plánovaná odstávka',
  //   badgeLabel: 'Kooperativa',
  //   linkLabel: 'Aplikace Kooperativa pojištovny bude 28.10.2021 mimo provoz od 23:00 do 23:30.',
  //   linkHref: 'https://www.koop.cz/',
  // },
]
