import { useState } from 'react'
import { Button, Flex } from '@chakra-ui/react'

import { Cancellation, Check } from '../icons'
import { useCancelContract } from '../model/api/contracts/useCancelContract'
import { ContractStatusEnum } from '../model/enums/ContractStatusEnum'

export const CancelContract: React.FC<{ id: string | number; status: number }> = ({
  id,
  status,
}) => {
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false)

  const { mutate, isLoading, isSuccess: isJustCanceled } = useCancelContract(id)
  const isCanceled = isJustCanceled || status === ContractStatusEnum.CANCELED

  const handleCancelConfirm = () => {
    setConfirmCancel(true)
  }

  const handleCancelContract = () => {
    mutate()
  }

  return (
    <>
      {!isCanceled && !confirmCancel && (
        <Button variant="outline" colorScheme="red" onClick={handleCancelConfirm}>
          <Cancellation />
          Stornovat pro chybu ve smlouvě
        </Button>
      )}

      {!isCanceled && confirmCancel && (
        <Flex sx={{ gap: 2 }}>
          <Button
            variant="solid"
            colorScheme="red"
            onClick={handleCancelContract}
            disabled={isLoading}
          >
            <Cancellation />
            {isLoading ? 'Stornuji...' : 'Opravdu stornovat?'}
          </Button>

          {!isLoading && (
            <Button variant="outline" onClick={() => setConfirmCancel(false)}>
              Nestornovat
            </Button>
          )}
        </Flex>
      )}

      {isCanceled && (
        <Button variant="outline" colorScheme="green" onClick={() => false} disabled={true}>
          <Check />
          Stornováno
        </Button>
      )}
    </>
  )
}
