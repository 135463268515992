import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

interface OfferWrapperProps extends BoxProps {
  children: React.ReactNode
}

export const OfferWrapper = ({ children, ...rest }: OfferWrapperProps) => {
  return <Box sx={{ borderRadius: 'md', boxShadow: 'card' }} {...rest}>{children}</Box>
}
