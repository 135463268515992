import React from 'react'
import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
  Box,
  Tooltip,
  Text,
} from '@chakra-ui/react'
import { LabelHintIcon } from '../icons'

interface FormLabelProps extends ChakraFormLabelProps {
  children: React.ReactNode
  tooltip?: string | React.ReactNode
  optional?: boolean | undefined
}

export const FormLabel = ({ children, tooltip, optional, ...rest }: FormLabelProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', columnGap: 4, justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            columnGap: 2,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            justifyItems: 'center',
          }}
        >
          <ChakraFormLabel {...rest}>{children}</ChakraFormLabel>

          {tooltip && (
            <Tooltip hasArrow label={tooltip} placement="top">
              <Box sx={{ lineHeight: 0 }}>
                <LabelHintIcon width={4} height={4} />
              </Box>
            </Tooltip>
          )}
        </Box>

        {optional && (
          <Text variant="textMuted" sx={{ fontSize: 'sm' }}>
            Nepovinné
          </Text>
        )}
      </Box>
    </Box>
  )
}
