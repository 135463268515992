import { Button, Flex, Heading, useBoolean } from '@chakra-ui/react'

import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { ConceptsList } from '../../containers/concepts'
import { ConceptsCarSearch } from '../../containers/search/carkulka/ConceptsCarSearch'
import { PageToolbar } from '../../containers/PageToolbar'
import { conceptsCars } from '../../data/concepts'
import { Select } from '../../icons'
import { SearchButton } from '../../containers/search/SearchButton'
import { PageContent } from '../../containers/PageContent'

export const ConceptsCarPage = () => {
  const [isOpen, setOpen] = useBoolean(false)

  return (
    <Section>
      <Container>
        <PageContent>
          <PageToolbar
            leftElements={
              <Flex sx={{ gap: 4, alignItems: 'center' }}>
                <Heading as="h1">Rozpracované nabídky</Heading>
              </Flex>
            }
            rightElements={
              <Flex sx={{ gap: 4 }}>
                <Button variant="ghost">
                  <Select color="secondary.500" />
                  Vybrat ke smazání
                </Button>
                <SearchButton isOpen={isOpen} setOpen={setOpen} />
              </Flex>
            }
          />

          {isOpen && <ConceptsCarSearch />}

          <ConceptsList concepts={conceptsCars} withPaginator />
        </PageContent>
      </Container>
    </Section>
  )
}
