import { SystemStyleObject } from '@chakra-ui/react'

const baseStyle: SystemStyleObject = {
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: {
    base: 4,
    lg: 8,
  },
  rowGap: 4,
  alignContent: 'start',
  alignItems: 'start',
  justifyItems: 'start',
}

const variantVertical: SystemStyleObject = {
  display: 'grid',
}

const variantCarRegistration: SystemStyleObject = {
  w: '100%',
  alignItems: 'end',
  justifyContent: 'space-between',
}

const variantPrice: SystemStyleObject = {
  w: '100%',
  alignItems: 'end',
}

const sizes = {
  md: {},
  lg: {
    columnGap: 16,
  },
}

const variants = {
  vartical: variantVertical,
  carRegistration: variantCarRegistration,
  price: variantPrice,
}

const defaultProps = {
  variant: 'default',
  size: 'md',
}

export const FormGroup = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
