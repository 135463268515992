import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Select,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useState } from 'react'

import { Datepicker } from '../../../components/Datepicker'
import { InputGroup } from '../../../components/InputGroup'
import { Loading } from '../../../components/Loading'
import { Search } from '../../../icons'
import { useGetCompanies } from '../../../model/api/company/useGetCompany'
import {
  ContractsPropertySearchFormProps,
  IContractsPropertySearchFormValues,
} from '../../../model/interfaces/entities/IContractsSearchForm'

import { SearchWrapper } from '../SearchWrapper'
import { useGetOrganizations } from '../../../model/api/organization/useGetOrganizations'
import { useGetUser } from '../../../model/api/user/useGetUser'
import moment from 'moment/moment'
import { OrganizationTypeEnum } from '../../../model/enums/OrganizationTypeEnum'
import { Select as Select2 } from 'chakra-react-select'
import { organizationListToSelectOptions } from '../../../model/utils/organizationUtils'
import { usersListToSelectOptions } from '../../../model/utils/userUtils'

const initialValuesConcepts: IContractsPropertySearchFormValues = {
  number: undefined,
  dateCreatedFrom: undefined,
  dateCreatedTo: undefined,
  companyId: undefined,
  client: undefined,
  ino: undefined,
  organizationId: undefined,
  userId: undefined,
  status: undefined,
  page: undefined,
}

export const ContractsPropertySearchForm = ({
  setParams,
  params,
}: ContractsPropertySearchFormProps) => {
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    params.dateCreatedFrom ? moment(params.dateCreatedFrom).toDate() : undefined,
  )
  const [dateTo, setDateTo] = useState<Date | undefined>(
    params.dateCreatedTo ? moment(params.dateCreatedTo).toDate() : undefined,
  )

  const { data: companies, isLoading } = useGetCompanies()
  const { data: organizations, isLoading: isLoadingOrganizations } = useGetOrganizations(
    1,
    1_000_000,
    true,
  )
  const { data: users, isLoading: isLoadingUsers } = useGetUser(1, 1_000_000)

  const organizationSelectOptions = organizationListToSelectOptions(
    organizations?.data?.items || [],
  )
  const userSelectOptions = usersListToSelectOptions(users?.data?.items || [])

  const onSubmitForm: (formValues: any) => void = (formValues: any) => {
    if (dateFrom) {
      formValues.dateCreatedFrom = dateFrom.toLocaleDateString('en-CA')
    }
    if (dateTo) {
      formValues.dateCreatedTo = dateTo.toLocaleDateString('en-CA')
    }

    setParams({
      ...formValues,
      page: 1,
    })
  }

  return (
    <Formik
      initialValues={params}
      onSubmit={(values) => onSubmitForm(values)}
      enableReinitialize={true}
    >
      {({ handleChange, values, setFieldValue }) => {
        return (
          <Form>
            <SearchWrapper>
              <FormControl variant="fullWidth">
                <FormLabel htmlFor="number">Číslo smlouvy</FormLabel>

                <InputGroup
                  name="number"
                  value={values.number}
                  id="number"
                  placeholder="Např: 73622"
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="dateFrom">Datum od</FormLabel>

                <Datepicker
                  id="dateCreatedFrom"
                  name="dateCreatedFrom"
                  date={dateFrom}
                  onDateChange={(date: Date) => setDateFrom(date)}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="dateTo">Datum do</FormLabel>

                <Datepicker
                  id="dateCreatedTo"
                  name="dateCreatedTo"
                  date={dateTo}
                  onDateChange={(date: Date) => setDateTo(date)}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="companyId">Pojišťovna</FormLabel>

                {isLoading ? (
                  <Loading />
                ) : (
                  <Select
                    name="companyId"
                    value={values.companyId}
                    id="companyId"
                    onChange={handleChange}
                    placeholder="Všechny pojišťovny"
                  >
                    {companies?.data &&
                      companies?.data.length > 0 &&
                      companies?.data.map((company, index) => {
                        return (
                          <option key={index} value={company.id}>
                            {company.name}
                          </option>
                        )
                      })}
                  </Select>
                )}
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="client">Pojistník (příjmení/název firmy)</FormLabel>

                <InputGroup
                  name="client"
                  value={values.client}
                  id="client"
                  placeholder="Např: Novák"
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="ino">Pojistník (rodné číslo/IČO)</FormLabel>

                <InputGroup
                  name="ino"
                  value={values.ino}
                  id="ino"
                  placeholder="Např: 9001012233"
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="organizationId">Organizace</FormLabel>
                <Select2
                  name="organizationId"
                  id="organizationId"
                  placeholder="Všechny organizace"
                  isLoading={isLoadingOrganizations}
                  value={organizationSelectOptions?.find((o) => o.value == values.organizationId)}
                  onChange={(option) => setFieldValue('organizationId', option?.value)}
                  options={organizationSelectOptions}
                  loadingMessage={() => 'Načítání organizací...'}
                  isClearable={true}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="userId">Uživatel</FormLabel>
                <Select2
                  id="userId"
                  name="userId"
                  placeholder="Všichni uživatelé"
                  onChange={(option) => {
                    console.log(option)
                    setFieldValue('userId', option?.value)
                  }}
                  value={userSelectOptions.find((u) => u.value == values.userId)}
                  options={userSelectOptions}
                  isLoading={isLoadingUsers}
                  loadingMessage={() => 'Načítání uživatelů...'}
                  isClearable={true}
                />
              </FormControl>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  columnGap: 4,
                  rowGap: 4,
                  alignItems: 'end',
                  justifyContent: 'space-between',
                  gridColumn: { base: '', md: '3 / 5' },
                }}
              >
                <Field name="status">
                  {({ field }: FieldProps) => {
                    const { onChange, ...rest } = field

                    return (
                      <FormControl id="status">
                        <FormLabel>Stav smlouvy</FormLabel>
                        <RadioGroup {...rest} name="status" value={values.status}>
                          <HStack sx={{ h: '36px' }}>
                            <Radio
                              onChange={handleChange}
                              {...rest}
                              value="11"
                              isChecked={values.status === '11'}
                            >
                              Aktivní
                            </Radio>
                            <Radio
                              onChange={handleChange}
                              {...rest}
                              value="12"
                              isChecked={values.status === '12'}
                            >
                              Stornované
                            </Radio>
                          </HStack>
                        </RadioGroup>
                      </FormControl>
                    )
                  }}
                </Field>

                <Button type="submit" colorScheme="primary">
                  <Search />
                  Vyhledat
                </Button>
              </Box>
            </SearchWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}
