import { FormErrorMessage, Textarea, TextareaProps } from '@chakra-ui/react'
import { ForwardedRef, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

interface TextareaGroupProps extends TextareaProps {
  errors?: FieldError | undefined
}

export const TextareaGroup = forwardRef(({ errors, ...rest }: TextareaGroupProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
  const errorMessage = errors && errors?.message

  return (
    <>
      <Textarea {...rest} ref={ref} />

      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </>
  )
})
