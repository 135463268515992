import {
  chakra,
  forwardRef,
  layoutPropNames,
  omitThemingProps,
  SystemProps,
  SystemStyleObject,
  ThemingProps,
  useMultiStyleConfig,
  HTMLChakraProps,
} from '@chakra-ui/system'
import { callAll, split, __DEV__ } from '@chakra-ui/utils'
import { mode } from '@chakra-ui/theme-tools'

import { Box, useRadio, UseRadioProps } from '@chakra-ui/react'

import {
  Car,
  Truck,
  Motobike,
  Other,
  HouseholdProperty,
  House,
  Household,
  Hut,
  Buildings,
  Stock,
  Vehicle,
  Responsibility,
  Interruptions,
} from '../icons'
import { useRadioIconGroupContext } from './RadioIconGroup'
import { Retail } from '../icons/Retail'
import { Craft } from '../icons/Craft'
import { Gastro } from '../icons/Gastro'
import { Services } from '../icons/Services'
import { Production } from '../icons/Production'

export const enum RadionIconIconsEnum {
  Car = 'car',
  Truck = 'truck',
  Motobike = 'motobike',
  Other = 'other',
  HouseholdProperty = 'householdProperty',
  House = 'house',
}

export interface IGetRadioIcon {
  icon: string
  defaultIconSize?: string
}

export const getRadioIcon = ({ icon, defaultIconSize = '28px' }: IGetRadioIcon) => {
  const defaultIconProps = {
    color: 'secondary.500',
    width: defaultIconSize,
    height: defaultIconSize,
  }

  switch (icon) {
    case 'car':
      return <Car {...defaultIconProps} />
    case 'truck':
      return <Truck {...defaultIconProps} />
    case 'motobike':
      return <Motobike {...defaultIconProps} />
    case 'other':
      return <Other {...defaultIconProps} />
    case 'householdProperty':
      return <HouseholdProperty {...defaultIconProps} width="70px" />
    case 'house':
      return <House {...defaultIconProps} />
    case 'household':
      return <Household {...defaultIconProps} />
    case 'hut':
      return <Hut {...defaultIconProps} />
    case 'buildings':
      return <Buildings {...defaultIconProps} />
    case 'stock':
      return <Stock {...defaultIconProps} />
    case 'vehicle':
      return <Vehicle {...defaultIconProps} />
    case 'responsibility':
      return <Responsibility {...defaultIconProps} />
    case 'interruptions':
      return <Interruptions {...defaultIconProps} />
    case 'retail':
      return <Retail {...defaultIconProps} />
    case 'craft':
      return <Craft {...defaultIconProps} />
    case 'gastro':
      return <Gastro {...defaultIconProps} />
    case 'services':
      return <Services {...defaultIconProps} />
    case 'production':
      return <Production {...defaultIconProps} />
  }
}

type Omitted = 'onChange' | 'defaultChecked' | 'checked' | 'icon'
interface BaseControlProps extends Omit<HTMLChakraProps<'div'>, Omitted> {}

export interface RadioIconProps extends UseRadioProps, ThemingProps<'Radio'>, BaseControlProps {
  /**
   * The spacing between the checkbox and its label text
   * @default 0.5rem
   * @type SystemProps["marginLeft"]
   */
  spacing?: SystemProps['marginLeft']
  /**
   * If `true`, the radio will occupy the full width of its parent container
   *
   * @deprecated
   * This component defaults to 100% width,
   * please use the props `maxWidth` or `width` to configure
   */
  isFullWidth?: boolean
  /**
   * Additional props to be forwarded to the `input` element
   */
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  icon?: string
  hideControl?: boolean
  description?: React.ReactNode | string
}

/**
 * Radio component is used in forms when a user needs to select a single value from
 * several options.
 *
 * @see Docs https://chakra-ui.com/radio
 */
export const RadioIcon = forwardRef<RadioIconProps, 'input'>((props, ref) => {
  const group = useRadioIconGroupContext()
  const {
    onChange: onChangeProp,
    value: valueProp,
    icon,
    hideControl = false,
    description,
    variant,
  } = props

  const styles = useMultiStyleConfig('Radio', { ...props })

  const ownProps = omitThemingProps(props)

  const {
    spacing = '0.5rem',
    children,
    isDisabled = group?.isDisabled,
    isFocusable = group?.isFocusable,
    inputProps: htmlInputProps,
    ...rest
  } = ownProps

  let isChecked = props.isChecked
  if (group?.value != null && valueProp != null) {
    isChecked = group.value === valueProp
  }

  let onChange = onChangeProp
  if (group?.onChange && valueProp != null) {
    onChange = callAll(group.onChange, onChangeProp)
  }

  const name = props?.name

  const { getInputProps, getCheckboxProps, getLabelProps, getRootProps, htmlProps } = useRadio({
    ...rest,
    isChecked,
    isFocusable,
    isDisabled,
    onChange,
    name,
  })

  const [layoutProps, otherProps] = split(htmlProps, layoutPropNames as any)

  const checkboxProps = getCheckboxProps(otherProps)
  const inputProps = getInputProps(htmlInputProps, ref)
  const labelProps = getLabelProps()
  const rootProps = Object.assign({}, layoutProps, getRootProps())

  const rootStyles = {
    ...styles.container,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'top',
    cursor: 'pointer',
    width: {
      base: '100%',
      sm: 'auto',
    },
    bg: isChecked
      ? mode('white', 'whiteAlpha.800')
      : mode(variant === 'simple' ? 'transparent' : 'gray.50', 'whiteAlpha.800'),
    borderColor: isChecked ? 'green' : mode('gray.400', 'whiteAlpha.800'),
  }

  const checkboxStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    ...styles.control,
  }

  const labelStyles: SystemStyleObject = {
    ...styles.label,
    userSelect: 'none',
    // marginStart: hideControl || variant === 'simple' ? 0 : spacing,
    fontWeight: description ? '500' : '400',
  }

  return (
    <chakra.label className="chakra-radio" {...rootProps} __css={rootStyles}>
      <Box
        sx={{
          display: { base: 'grid', sm: 'grid' },
          gap: 2,
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
        }}
      >
        {icon && getRadioIcon({ icon })}

        <Box
          sx={{
            display: description !== undefined ? 'grid' : 'flex',
            gap: 2,
            placeContent: 'center',
            placeItems: 'center',
          }}
        >
          <input className="chakra-radio__input" {...inputProps} />

          {!hideControl && (
            <chakra.span
              className="chakra-radio__control"
              {...checkboxProps}
              __css={checkboxStyles}
            />
          )}

          {children && (
            <chakra.span className="chakra-radio__label" {...labelProps} __css={labelStyles}>
              {children}
            </chakra.span>
          )}
        </Box>

        {description && description}
      </Box>
    </chakra.label>
  )
})

if (__DEV__) {
  RadioIcon.displayName = 'RadioIcon'
}
