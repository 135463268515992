import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePatchStep3 = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id?: string,
  itemId?: string,
  limitId?: string,
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (form3Body: any) => {
      return api.patch(`${ApiRouteEnum.OFFERS}/${id}/step3/${itemId}/${limitId}`, form3Body)
    },
    {
      mutationKey: [`${ApiRouteEnum.OFFERS}/${itemId}/step3`],
      onSuccess: (data) => {
        queryClient.invalidateQueries([ApiRouteEnum.OFFERS])
        // @ts-ignore
        successCallBack(data.data)
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
