import { Box, Spinner } from '@chakra-ui/react'

export const Loading = ({ large = false }: { large?: boolean }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        py: large ? 8 : 0,
        placeContent: 'center',
        placeItems: 'center',
      }}
    >
      <Spinner />
      Načítání...
    </Box>
  )
}
