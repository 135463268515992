import { Icon } from '@chakra-ui/react'

export const Interruptions = (props: any) => (
  <Icon boxSize={props.boxSize || '20px'} {...props} viewBox="0 0 28 28" fill="none">
    <path
      d="M12.25 9.625C12.25 9.39293 12.1579 9.17037 11.9938 9.00628C11.8297 8.84218 11.6071 8.75 11.375 8.75C11.143 8.75 10.9204 8.84218 10.7563 9.00628C10.5922 9.17037 10.5 9.39293 10.5 9.625V18.375C10.5 18.6071 10.5922 18.8296 10.7563 18.9937C10.9204 19.1578 11.143 19.25 11.375 19.25C11.6071 19.25 11.8297 19.1578 11.9938 18.9937C12.1579 18.8296 12.25 18.6071 12.25 18.375V9.625ZM17.5 9.625C17.5 9.39293 17.4079 9.17037 17.2438 9.00628C17.0797 8.84218 16.8571 8.75 16.625 8.75C16.393 8.75 16.1704 8.84218 16.0063 9.00628C15.8422 9.17037 15.75 9.39293 15.75 9.625V18.375C15.75 18.6071 15.8422 18.8296 16.0063 18.9937C16.1704 19.1578 16.393 19.25 16.625 19.25C16.8571 19.25 17.0797 19.1578 17.2438 18.9937C17.4079 18.8296 17.5 18.6071 17.5 18.375V9.625ZM14 2.33333C7.55654 2.33333 2.33337 7.5565 2.33337 14C2.33337 20.4435 7.55654 25.6667 14 25.6667C20.4435 25.6667 25.6667 20.4435 25.6667 14C25.6667 7.5565 20.4435 2.33333 14 2.33333ZM4.08337 14C4.08337 11.3699 5.12816 8.84759 6.9879 6.98785C8.84763 5.12812 11.37 4.08333 14 4.08333C16.6301 4.08333 19.1524 5.12812 21.0122 6.98785C22.8719 8.84759 23.9167 11.3699 23.9167 14C23.9167 16.6301 22.8719 19.1524 21.0122 21.0121C19.1524 22.8719 16.6301 23.9167 14 23.9167C11.37 23.9167 8.84763 22.8719 6.9879 21.0121C5.12816 19.1524 4.08337 16.6301 4.08337 14Z"
      fill="currentColor"
    />
  </Icon>
)

export const InterruptionsString = `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.25 9.625C12.25 9.39293 12.1579 9.17037 11.9938 9.00628C11.8297 8.84218 11.6071 8.75 11.375 8.75C11.143 8.75 10.9204 8.84218 10.7563 9.00628C10.5922 9.17037 10.5 9.39293 10.5 9.625V18.375C10.5 18.6071 10.5922 18.8296 10.7563 18.9937C10.9204 19.1578 11.143 19.25 11.375 19.25C11.6071 19.25 11.8297 19.1578 11.9938 18.9937C12.1579 18.8296 12.25 18.6071 12.25 18.375V9.625ZM17.5 9.625C17.5 9.39293 17.4079 9.17037 17.2438 9.00628C17.0797 8.84218 16.8571 8.75 16.625 8.75C16.393 8.75 16.1704 8.84218 16.0063 9.00628C15.8422 9.17037 15.75 9.39293 15.75 9.625V18.375C15.75 18.6071 15.8422 18.8296 16.0063 18.9937C16.1704 19.1578 16.393 19.25 16.625 19.25C16.8571 19.25 17.0797 19.1578 17.2438 18.9937C17.4079 18.8296 17.5 18.6071 17.5 18.375V9.625ZM14 2.33333C7.55654 2.33333 2.33337 7.5565 2.33337 14C2.33337 20.4435 7.55654 25.6667 14 25.6667C20.4435 25.6667 25.6667 20.4435 25.6667 14C25.6667 7.5565 20.4435 2.33333 14 2.33333ZM4.08337 14C4.08337 11.3699 5.12816 8.84759 6.9879 6.98785C8.84763 5.12812 11.37 4.08333 14 4.08333C16.6301 4.08333 19.1524 5.12812 21.0122 6.98785C22.8719 8.84759 23.9167 11.3699 23.9167 14C23.9167 16.6301 22.8719 19.1524 21.0122 21.0121C19.1524 22.8719 16.6301 23.9167 14 23.9167C11.37 23.9167 8.84763 22.8719 6.9879 21.0121C5.12816 19.1524 4.08337 16.6301 4.08337 14Z" fill="#EE7400"/>
</svg>
`
