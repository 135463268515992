export const prefixUrlWithBasePath = (url: string): string => {
  return url
}

export const convertParamsToSearchParams = (
  params: { [key in string]: any },
  prefix: string = '',
) => {
  const out: { [key in string]: string } = {}

  for (const key in params) {
    const pathKey = `${prefix}${key}`
    if (!params[key]) {
      continue
    }

    out[pathKey] = String(params[key])
  }

  return out
}

export const appendOrRemoveQueryParameters = (
  params: { [key in string]: any },
  searchParams: URLSearchParams,
  prefix: string = '',
): URLSearchParams => {
  for (const key in params) {
    const pathKey = `${prefix}${key}`
    searchParams.delete(pathKey)

    if (!params[key]) {
      continue
    }

    searchParams.set(pathKey, String(params[key]))
  }

  return searchParams
}

export const queryParameterToId = (parameter: string | number | undefined): number | undefined => {
  if (!parameter) {
    return undefined
  }

  if (typeof parameter === 'number') {
    return parameter
  }

  return parseInt(parameter)
}
