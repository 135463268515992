import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IFAQ } from '../../interfaces/entities/IFAQ'

export const useGetFAQ = () => {
  return useQuery([ApiRouteEnum.FAQ], () => api.get<IFAQ>(ApiRouteEnum.FAQ), {
    retry: false,
  })
}
