import { useMemo } from 'react'
import { chakra, forwardRef, ThemingProps } from '@chakra-ui/system'
import { __DEV__, cx } from '@chakra-ui/utils'
import { createContext } from '@chakra-ui/react-utils'
import { useCheckboxGroup, UseCheckboxGroupProps, UseCheckboxGroupReturn } from '@chakra-ui/react'
import { Control } from 'react-hook-form'

export interface CheckboxIconGroupProps
  extends UseCheckboxGroupProps,
    Omit<ThemingProps<'Checkbox'>, 'orientation'> {
  children?: React.ReactNode
  control?: Control<any>
  name: string
}

export interface CheckboxIconGroupContext
  extends Pick<UseCheckboxGroupReturn, 'onChange' | 'value' | 'isDisabled'>,
    Omit<ThemingProps<'Checkbox'>, 'orientation'> {}

const [CheckboxIconGroupProvider, useCheckboxIconGroupContext] =
  createContext<CheckboxIconGroupContext>({
    name: 'CheckboxIconGroupContext',
    strict: false,
  })

export { useCheckboxIconGroupContext }

/**
 * Used for multiple checkboxes which are bound in one group,
 * and it indicates whether one or more 2 are selected.
 *
 * @see Docs https://chakra-ui.com/checkbox
 */
export const CheckboxIconGroup = forwardRef<CheckboxIconGroupProps, 'div'>((props, ref) => {
  const { colorScheme, size, variant, children, name, className, control, isDisabled, ...rest } =
    props

  // const {
  //   field,
  //   formState: { errors },
  // } = useController({
  //   control,
  //   name,
  // })
  const { value, onChange } = useCheckboxGroup({ ...rest, onChange: () => false })

  const group = useMemo(
    () => ({
      size,
      onChange,
      colorScheme,
      value,
      variant,
      isDisabled,
    }),
    [size, onChange, colorScheme, value, variant, isDisabled],
  )

  const _className = cx('chakra-checkbox-group', className)
  // const errorMessage = errors && errors[name] && errors[name]?.message

  return (
    <CheckboxIconGroupProvider value={group}>
      <chakra.div className={_className}>{children}</chakra.div>
      {/*{errorMessage && <FormErrorMessage>{errorMessage as string}</FormErrorMessage>}*/}
    </CheckboxIconGroupProvider>
  )
})

if (__DEV__) {
  CheckboxIconGroup.displayName = 'CheckboxIconGroup'
}
