import { Icon } from '@chakra-ui/react'

export const Other = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 28 28" fill="none">
<path d="M1.5 12.6667V20H7.45238M1.5 12.6667H20.5476M1.5 12.6667V9H20.5476V12.6667M20.5476 12.6667V20M20.5476 20H14.5952M20.5476 20H26.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<circle cx="11" cy="19.5" r="3.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
)


export const OtherString=`<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5 12.6667V20H7.45238M1.5 12.6667H20.5476M1.5 12.6667V9H20.5476V12.6667M20.5476 12.6667V20M20.5476 20H14.5952M20.5476 20H26.5" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="11" cy="19.5" r="3.5" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;