import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IMeetingRecordFormValues } from '../../../containers/MeetingRecordForm'

export const usePatchStep5 = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id?: string,
) => {
  const queryClient = useQueryClient()
  return useMutation(
    (form5Body: IMeetingRecordFormValues) =>
      api.patch(`${ApiRouteEnum.OFFERS}/${id}/step5`, form5Body),
    {
      mutationKey: [`patch-${ApiRouteEnum.OFFERS}/step5`],
      onSuccess: (data) => {
        successCallBack(data.data)
      },
      onError: (error) => {
        queryClient.invalidateQueries({ queryKey: [ApiRouteEnum.OFFERS, id] })
        errorCallBack(error)
      },
    },
  )
}
