import React, { useMemo, useState } from 'react'

import { InputGroup } from '../../components/InputGroup'
import { FormLabel } from '../../components/FormLabel'
import { Box, FormControl, Text } from '@chakra-ui/react'

const DiscountInput = ({
  label,
  min,
  max,
  defaultValue = '',
}: {
  label: string
  min: string
  max: string
  defaultValue?: string
}) => {
  const inputMin = parseInt(min)
  const inputMax = parseInt(max)
  const [value, setValue] = useState(defaultValue || inputMax)

  return (
    <FormControl>
      <FormLabel className="business-discount-label" sx={{ fontSize: 'sm', fontWeight: 'normal', whiteSpace: 'nowrap' }}>{label}</FormLabel>

      <InputGroup
        type="text"
        htmlSize={1}
        value={value}
        maxLength={2}
        min={inputMin}
        max={inputMax}
        pattern="[0-9]{0,2}"
        selectAllonFocus
        sx={{ w: 12 }}
        onChange={(e) => setValue(e.currentTarget.value)}
      />
    </FormControl>
  )
}

const DiscountLimits = ({ discounts, limit }: { discounts: any; limit: string }) => {
  const keys = useMemo(() => Object.keys(discounts), [discounts])

  return (
    <>
      {keys.map((key, i) => {
        const discount = discounts[key]

        return (
          <span key={key}>
            {`${key} ${discount[limit]}`}
            {i < keys.length - 1 && ', '}
          </span>
        )
      })}
    </>
  )
}

export const BusinessCompanyDiscount = ({ name, discounts }: { name: string; discounts: any }) => {
  const discountsKeys = Object.keys(discounts)
  const minimalDiscounts = useMemo(
    () => <DiscountLimits discounts={discounts} limit="min" />,
    [discounts],
  )
  const maximalDiscounts = useMemo(
    () => <DiscountLimits discounts={discounts} limit="max" />,
    [discounts],
  )

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        gridTemplateColumns: '60% auto',
        justifyContent: 'start',
        justifyItems: 'start',
      }}
    >
      <Box sx={{ display: 'grid', gap: 1 }} as="header">
        <p className="business-discounts-company__name">{name}</p>

        <Text
          variant="textMuted"
          sx={{ fontSize: 'xs' }}
          as="p"
          className="business-discounts-company__limit"
        >
          Maximální slevy: {maximalDiscounts}
          <br />
          Minimální slevy: {minimalDiscounts}
        </Text>
      </Box>

      {discountsKeys.length > 0 && (
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 4 }}>
          {discountsKeys.map((key) => {
            const discount = discounts[key]

            return <DiscountInput key={key} label={key} min={discount.min} max={discount.max} />
          })}
        </Box>
      )}
    </Box>
  )
}
