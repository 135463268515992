import { Box, Button, FormControl, FormLabel, HStack } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'

import { Datepicker } from '../../../components/Datepicker'
import { InputGroup } from '../../../components/InputGroup'
import { Radio } from '../../../components/Radio'
import { RadioGroup } from '../../../components/RadioGroup'
import { Search } from '../../../icons'
import { CompaniesSelect } from '../../CompaniesSelect'
import { SearchWrapper } from '../SearchWrapper'

export interface ContractsCarSearchFormValues {
  offerNumber: string
  dateFrom: Date
  dateTo: Date
  insuranceCompany: string
  spz: string
  vin: string
  insureeName: string
  insureeNumber: string
  organization: string
  user: string
  contractStatus: 'all' | 'active' | 'canceled' | null
}

interface ContractsCarSearchFormProps {
  onSubmit: Function
}

export const ContractsCarSearchForm = ({ onSubmit }: ContractsCarSearchFormProps) => {
  const methods = useForm<ContractsCarSearchFormValues>({
    defaultValues: {
      contractStatus: 'all',
    },
  })
  const { control, register, getValues, setValue, handleSubmit } = methods

  const handleFormSubmit = (values: ContractsCarSearchFormValues) => {
    onSubmit(values)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <SearchWrapper>
          <FormControl variant="fullWidth">
            <FormLabel htmlFor="offerNumber">Číslo nabídky</FormLabel>

            <InputGroup id="offerNumber" placeholder="Např: 73622" {...register('offerNumber')} />
          </FormControl>

          <FormControl variant="fullWidth">
            <FormLabel htmlFor="dateFrom">Datum od</FormLabel>

            <Datepicker
              date={getValues('dateFrom')}
              onDateChange={(date: Date) => setValue('dateFrom', date)}
            />
          </FormControl>

          <FormControl variant="fullWidth">
            <FormLabel htmlFor="dateTo">Datum do</FormLabel>

            <Datepicker
              date={getValues('dateTo')}
              onDateChange={(date: Date) => setValue('dateTo', date)}
            />
          </FormControl>

          <FormControl variant="fullWidth">
            <FormLabel htmlFor="insuranceCompany">Pojišťovna</FormLabel>

            <CompaniesSelect {...register('insuranceCompany')} />
          </FormControl>

          <FormControl variant="fullWidth">
            <FormLabel htmlFor="spz">Registrační značka</FormLabel>

            <InputGroup id="spz" placeholder="Např: 1A1 0000" {...register('spz')} />
          </FormControl>

          <FormControl variant="fullWidth">
            <FormLabel htmlFor="vin">VIN</FormLabel>

            <InputGroup id="vin" placeholder="Např: 2HNYD188068515943" {...register('vin')} />
          </FormControl>

          <FormControl variant="fullWidth">
            <FormLabel htmlFor="insureeName">Pojistník (příjmení/název firmy)</FormLabel>

            <InputGroup id="insureeName" placeholder="Např: Novák" {...register('insureeName')} />
          </FormControl>

          <FormControl variant="fullWidth">
            <FormLabel htmlFor="insureeNumber">Pojistník (rodné číslo/IČO)</FormLabel>

            <InputGroup
              id="insureeNumber"
              placeholder="Např: 9001012233"
              {...register('insureeNumber')}
            />
          </FormControl>

          <FormControl variant="fullWidth">
            <FormLabel htmlFor="organization">Organizační jednotka</FormLabel>

            <InputGroup
              id="organization"
              placeholder="Vyberte organizaci"
              {...register('organization')}
            />
          </FormControl>

          <FormControl variant="fullWidth">
            <FormLabel htmlFor="user">Uživatel</FormLabel>

            <InputGroup id="user" placeholder="Vyberte uživatele" {...register('user')} />
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: 4,
              rowGap: 4,
              alignItems: 'end',
              justifyContent: 'space-between',
              gridColumn: { base: '', md: '3 / 5' },
            }}
          >
            <FormControl>
              <FormLabel>Stav smlouvy</FormLabel>

              <RadioGroup control={control} name="contractStatus">
                <HStack sx={{ minH: '36px' }} spacing={{ base: 4, xl: 8 }}>
                  <Radio value="all">Všechny</Radio>
                  <Radio value="active">Aktivní</Radio>
                  <Radio value="canceled">Stornované</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>

            <Button type="submit" colorScheme="primary">
              <Search />
              Vyhledat
            </Button>
          </Box>
        </SearchWrapper>
      </form>
    </FormProvider>
  )
}
