import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import queryString from 'query-string'

export const useGetUserExport = (
  downloadUserExportCSV: (data: string) => void,
  isActive?: number,
  search?: string,
) => {
  const query = queryString.stringify({
    isActive,
    search,
  })
  return useMutation(() => api.get<string>(`${ApiRouteEnum.USER_EXPORT}?${query}`), {
    mutationKey: [ApiRouteEnum.USER_EXPORT],
    onSuccess: (data) => {
      downloadUserExportCSV(data.data)
    },
    onError: (error) => {
      console.log(error)
    },
  })
}
