import { Icon } from '@chakra-ui/react'

export const StepsIconBriefcase = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M7.5 3C7.36739 3 7.24021 3.05268 7.14645 3.14645C7.05268 3.24021 7 3.36739 7 3.5V6H5.5C4.83696 6 4.20107 6.26339 3.73223 6.73223C3.26339 7.20107 3 7.83696 3 8.5V13.5C3 14.163 3.26339 14.7989 3.73223 15.2678C4.20107 15.7366 4.83696 16 5.5 16H14.5C15.163 16 15.7989 15.7366 16.2678 15.2678C16.7366 14.7989 17 14.163 17 13.5V8.5C17 7.83696 16.7366 7.20107 16.2678 6.73223C15.7989 6.26339 15.163 6 14.5 6H13V3.5C13 3.36739 12.9473 3.24021 12.8536 3.14645C12.7598 3.05268 12.6326 3 12.5 3H7.5ZM12 6H8V4H12V6ZM5.5 7H14.5C14.8978 7 15.2794 7.15804 15.5607 7.43934C15.842 7.72064 16 8.10218 16 8.5V13.5C16 13.8978 15.842 14.2794 15.5607 14.5607C15.2794 14.842 14.8978 15 14.5 15H5.5C5.10218 15 4.72064 14.842 4.43934 14.5607C4.15804 14.2794 4 13.8978 4 13.5V8.5C4 8.10218 4.15804 7.72064 4.43934 7.43934C4.72064 7.15804 5.10218 7 5.5 7Z" fill="currentColor"/>
	</Icon>
)


export const StepsIconBriefcaseString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 3C7.36739 3 7.24021 3.05268 7.14645 3.14645C7.05268 3.24021 7 3.36739 7 3.5V6H5.5C4.83696 6 4.20107 6.26339 3.73223 6.73223C3.26339 7.20107 3 7.83696 3 8.5V13.5C3 14.163 3.26339 14.7989 3.73223 15.2678C4.20107 15.7366 4.83696 16 5.5 16H14.5C15.163 16 15.7989 15.7366 16.2678 15.2678C16.7366 14.7989 17 14.163 17 13.5V8.5C17 7.83696 16.7366 7.20107 16.2678 6.73223C15.7989 6.26339 15.163 6 14.5 6H13V3.5C13 3.36739 12.9473 3.24021 12.8536 3.14645C12.7598 3.05268 12.6326 3 12.5 3H7.5ZM12 6H8V4H12V6ZM5.5 7H14.5C14.8978 7 15.2794 7.15804 15.5607 7.43934C15.842 7.72064 16 8.10218 16 8.5V13.5C16 13.8978 15.842 14.2794 15.5607 14.5607C15.2794 14.842 14.8978 15 14.5 15H5.5C5.10218 15 4.72064 14.842 4.43934 14.5607C4.15804 14.2794 4 13.8978 4 13.5V8.5C4 8.10218 4.15804 7.72064 4.43934 7.43934C4.72064 7.15804 5.10218 7 5.5 7Z" fill="#7B341E"/>
</svg>
`;