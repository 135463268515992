import { Box } from '@chakra-ui/react'

import { OfferCar } from './OfferCar'
import { carOffers } from '../../data/offers/car'

export const OffersCarList = ({ offerId }: { offerId: string }) => {
  return (
    <Box sx={{ display: 'grid', gap: 12 }}>
      {carOffers &&
        carOffers.map((offer) => {
          return (
            <OfferCar
              key={offer.quoteId}
              offerId={offerId}
              status={String(offer.status)}
              quoteId={String(offer.quoteId)}
              company={offer.company}
              validUntil={offer.validUntil}
            />
          )
        })}
    </Box>
  )
}
