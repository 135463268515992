import { Icon } from '@chakra-ui/react'

export const External = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M9.516 6C9.38339 6 9.25622 6.05268 9.16245 6.14645C9.06868 6.24021 9.016 6.36739 9.016 6.5C9.016 6.63261 9.06868 6.75979 9.16245 6.85355C9.25622 6.94732 9.38339 7 9.516 7H12.293L8.146 11.146C8.09951 11.1925 8.06264 11.2477 8.03748 11.3084C8.01232 11.3692 7.99937 11.4343 7.99937 11.5C7.99937 11.5657 8.01232 11.6308 8.03748 11.6916C8.06264 11.7523 8.09951 11.8075 8.146 11.854C8.19249 11.9005 8.24768 11.9374 8.30842 11.9625C8.36916 11.9877 8.43426 12.0006 8.5 12.0006C8.56574 12.0006 8.63084 11.9877 8.69158 11.9625C8.75232 11.9374 8.80751 11.9005 8.854 11.854L13 7.707V10.484C13 10.6166 13.0527 10.7438 13.1464 10.8376C13.2402 10.9313 13.3674 10.984 13.5 10.984C13.6326 10.984 13.7598 10.9313 13.8536 10.8376C13.9473 10.7438 14 10.6166 14 10.484V6.5C14 6.36739 13.9473 6.24021 13.8536 6.14645C13.7598 6.05268 13.6326 6 13.5 6H9.516Z" fill="currentColor"/>
<path d="M12.766 17C13.3616 17.0001 13.9376 16.7877 14.3904 16.4009C14.8432 16.014 15.1431 15.4783 15.236 14.89C15.7469 14.7325 16.1938 14.4154 16.5113 13.9853C16.8287 13.5551 17 13.0346 17 12.5V5.5C17 4.83696 16.7366 4.20107 16.2678 3.73223C15.7989 3.26339 15.163 3 14.5 3H7.5C6.9595 2.99997 6.43353 3.17505 6.00087 3.499C5.56821 3.82295 5.25214 4.27835 5.1 4.797C4.51374 4.89203 3.98046 5.19266 3.59567 5.64507C3.21088 6.09747 2.99971 6.67209 3 7.266V13.5C3 14.4283 3.36875 15.3185 4.02513 15.9749C4.6815 16.6313 5.57174 17 6.5 17H12.766ZM4 7.266C3.99963 6.9555 4.09562 6.65254 4.27474 6.39892C4.45385 6.14529 4.70726 5.9535 5 5.85V12.5C5 13.163 5.26339 13.7989 5.73223 14.2678C6.20107 14.7366 6.83696 15 7.5 15H14.18C14.0766 15.2924 13.8851 15.5456 13.6319 15.7247C13.3787 15.9038 13.0762 16 12.766 16H6.5C5.83696 16 5.20107 15.7366 4.73223 15.2678C4.26339 14.7989 4 14.163 4 13.5V7.266ZM7.5 4H14.5C14.8978 4 15.2794 4.15804 15.5607 4.43934C15.842 4.72064 16 5.10218 16 5.5V12.5C16 12.8978 15.842 13.2794 15.5607 13.5607C15.2794 13.842 14.8978 14 14.5 14H7.5C7.10218 14 6.72064 13.842 6.43934 13.5607C6.15804 13.2794 6 12.8978 6 12.5V5.5C6 5.10218 6.15804 4.72064 6.43934 4.43934C6.72064 4.15804 7.10218 4 7.5 4V4Z" fill="currentColor"/>
	</Icon>
)


export const ExternalString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.516 6C9.38339 6 9.25622 6.05268 9.16245 6.14645C9.06868 6.24021 9.016 6.36739 9.016 6.5C9.016 6.63261 9.06868 6.75979 9.16245 6.85355C9.25622 6.94732 9.38339 7 9.516 7H12.293L8.146 11.146C8.09951 11.1925 8.06264 11.2477 8.03748 11.3084C8.01232 11.3692 7.99937 11.4343 7.99937 11.5C7.99937 11.5657 8.01232 11.6308 8.03748 11.6916C8.06264 11.7523 8.09951 11.8075 8.146 11.854C8.19249 11.9005 8.24768 11.9374 8.30842 11.9625C8.36916 11.9877 8.43426 12.0006 8.5 12.0006C8.56574 12.0006 8.63084 11.9877 8.69158 11.9625C8.75232 11.9374 8.80751 11.9005 8.854 11.854L13 7.707V10.484C13 10.6166 13.0527 10.7438 13.1464 10.8376C13.2402 10.9313 13.3674 10.984 13.5 10.984C13.6326 10.984 13.7598 10.9313 13.8536 10.8376C13.9473 10.7438 14 10.6166 14 10.484V6.5C14 6.36739 13.9473 6.24021 13.8536 6.14645C13.7598 6.05268 13.6326 6 13.5 6H9.516Z" fill="#003D66"/>
<path d="M12.766 17C13.3616 17.0001 13.9376 16.7877 14.3904 16.4009C14.8432 16.014 15.1431 15.4783 15.236 14.89C15.7469 14.7325 16.1938 14.4154 16.5113 13.9853C16.8287 13.5551 17 13.0346 17 12.5V5.5C17 4.83696 16.7366 4.20107 16.2678 3.73223C15.7989 3.26339 15.163 3 14.5 3H7.5C6.9595 2.99997 6.43353 3.17505 6.00087 3.499C5.56821 3.82295 5.25214 4.27835 5.1 4.797C4.51374 4.89203 3.98046 5.19266 3.59567 5.64507C3.21088 6.09747 2.99971 6.67209 3 7.266V13.5C3 14.4283 3.36875 15.3185 4.02513 15.9749C4.6815 16.6313 5.57174 17 6.5 17H12.766ZM4 7.266C3.99963 6.9555 4.09562 6.65254 4.27474 6.39892C4.45385 6.14529 4.70726 5.9535 5 5.85V12.5C5 13.163 5.26339 13.7989 5.73223 14.2678C6.20107 14.7366 6.83696 15 7.5 15H14.18C14.0766 15.2924 13.8851 15.5456 13.6319 15.7247C13.3787 15.9038 13.0762 16 12.766 16H6.5C5.83696 16 5.20107 15.7366 4.73223 15.2678C4.26339 14.7989 4 14.163 4 13.5V7.266ZM7.5 4H14.5C14.8978 4 15.2794 4.15804 15.5607 4.43934C15.842 4.72064 16 5.10218 16 5.5V12.5C16 12.8978 15.842 13.2794 15.5607 13.5607C15.2794 13.842 14.8978 14 14.5 14H7.5C7.10218 14 6.72064 13.842 6.43934 13.5607C6.15804 13.2794 6 12.8978 6 12.5V5.5C6 5.10218 6.15804 4.72064 6.43934 4.43934C6.72064 4.15804 7.10218 4 7.5 4V4Z" fill="#003D66"/>
</svg>
`;