import { Icon } from '@chakra-ui/react'

export const Logout = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M8.5 11.25C8.69891 11.25 8.88968 11.171 9.03033 11.0303C9.17098 10.8897 9.25 10.6989 9.25 10.5C9.25 10.3011 9.17098 10.1103 9.03033 9.96966C8.88968 9.82901 8.69891 9.74999 8.5 9.74999C8.30109 9.74999 8.11032 9.82901 7.96967 9.96966C7.82902 10.1103 7.75 10.3011 7.75 10.5C7.75 10.6989 7.82902 10.8897 7.96967 11.0303C8.11032 11.171 8.30109 11.25 8.5 11.25ZM11 3.49999C11 3.42792 10.9844 3.35671 10.9542 3.29123C10.9241 3.22576 10.8802 3.16757 10.8255 3.12065C10.7708 3.07373 10.7066 3.03919 10.6373 3.0194C10.568 2.99961 10.4952 2.99504 10.424 3.00599L3.424 4.07599C3.30598 4.09414 3.19836 4.15393 3.12059 4.24453C3.04282 4.33514 3.00005 4.45059 3 4.56999V15.43C3.00005 15.5494 3.04282 15.6648 3.12059 15.7555C3.19836 15.8461 3.30598 15.9058 3.424 15.924L10.424 16.995C10.4952 17.0059 10.568 17.0014 10.6373 16.9816C10.7066 16.9618 10.7708 16.9273 10.8255 16.8803C10.8802 16.8334 10.9241 16.7752 10.9542 16.7098C10.9844 16.6443 11 16.5731 11 16.501V9.99999H16.172L15.175 10.874C15.0812 10.9628 15.0254 11.0845 15.0193 11.2136C15.0132 11.3427 15.0573 11.4691 15.1424 11.5664C15.2275 11.6636 15.347 11.7241 15.4757 11.7353C15.6045 11.7464 15.7325 11.7072 15.833 11.626L17.829 9.87599C17.8825 9.82907 17.9254 9.77124 17.9548 9.7064C17.9842 9.64156 17.9994 9.57119 17.9994 9.49999C17.9994 9.4288 17.9842 9.35843 17.9548 9.29358C17.9254 9.22874 17.8825 9.17092 17.829 9.12399L15.833 7.37399C15.7333 7.28674 15.603 7.24267 15.4708 7.25148C15.3386 7.2603 15.2153 7.32127 15.128 7.42099C15.0407 7.52071 14.9967 7.65101 15.0055 7.78323C15.0143 7.91544 15.0753 8.03874 15.175 8.12599L16.172 8.99999H11V3.49999ZM10 4.08199V15.92L4 15V4.99899L10 4.08199ZM12.5 16H12V11H13V15.5C13 15.6326 12.9473 15.7598 12.8536 15.8535C12.7598 15.9473 12.6326 16 12.5 16ZM12 7.99999V3.99999H12.5C12.6326 3.99999 12.7598 4.05267 12.8536 4.14644C12.9473 4.24021 13 4.36738 13 4.49999V7.99999H12Z" fill="currentColor"/>
	</Icon>
)


export const LogoutString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 11.25C8.69891 11.25 8.88968 11.171 9.03033 11.0303C9.17098 10.8897 9.25 10.6989 9.25 10.5C9.25 10.3011 9.17098 10.1103 9.03033 9.96966C8.88968 9.82901 8.69891 9.74999 8.5 9.74999C8.30109 9.74999 8.11032 9.82901 7.96967 9.96966C7.82902 10.1103 7.75 10.3011 7.75 10.5C7.75 10.6989 7.82902 10.8897 7.96967 11.0303C8.11032 11.171 8.30109 11.25 8.5 11.25ZM11 3.49999C11 3.42792 10.9844 3.35671 10.9542 3.29123C10.9241 3.22576 10.8802 3.16757 10.8255 3.12065C10.7708 3.07373 10.7066 3.03919 10.6373 3.0194C10.568 2.99961 10.4952 2.99504 10.424 3.00599L3.424 4.07599C3.30598 4.09414 3.19836 4.15393 3.12059 4.24453C3.04282 4.33514 3.00005 4.45059 3 4.56999V15.43C3.00005 15.5494 3.04282 15.6648 3.12059 15.7555C3.19836 15.8461 3.30598 15.9058 3.424 15.924L10.424 16.995C10.4952 17.0059 10.568 17.0014 10.6373 16.9816C10.7066 16.9618 10.7708 16.9273 10.8255 16.8803C10.8802 16.8334 10.9241 16.7752 10.9542 16.7098C10.9844 16.6443 11 16.5731 11 16.501V9.99999H16.172L15.175 10.874C15.0812 10.9628 15.0254 11.0845 15.0193 11.2136C15.0132 11.3427 15.0573 11.4691 15.1424 11.5664C15.2275 11.6636 15.347 11.7241 15.4757 11.7353C15.6045 11.7464 15.7325 11.7072 15.833 11.626L17.829 9.87599C17.8825 9.82907 17.9254 9.77124 17.9548 9.7064C17.9842 9.64156 17.9994 9.57119 17.9994 9.49999C17.9994 9.4288 17.9842 9.35843 17.9548 9.29358C17.9254 9.22874 17.8825 9.17092 17.829 9.12399L15.833 7.37399C15.7333 7.28674 15.603 7.24267 15.4708 7.25148C15.3386 7.2603 15.2153 7.32127 15.128 7.42099C15.0407 7.52071 14.9967 7.65101 15.0055 7.78323C15.0143 7.91544 15.0753 8.03874 15.175 8.12599L16.172 8.99999H11V3.49999ZM10 4.08199V15.92L4 15V4.99899L10 4.08199ZM12.5 16H12V11H13V15.5C13 15.6326 12.9473 15.7598 12.8536 15.8535C12.7598 15.9473 12.6326 16 12.5 16ZM12 7.99999V3.99999H12.5C12.6326 3.99999 12.7598 4.05267 12.8536 4.14644C12.9473 4.24021 13 4.36738 13 4.49999V7.99999H12Z" fill="#003D66"/>
</svg>
`;