import React from 'react'
import PropTypes from 'prop-types'

import { logos } from '../../utils/companiesLogos'
import { Box, Flex, Text } from '@chakra-ui/react'

export const IndividualCompany = ({
  children,
  id,
  name,
}: {
  children: React.ReactNode
  id: string
  name: string
}) => {
  const logoImage = logos[id]

  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      <Flex sx={{ gap: 4, alignItems: 'center', fontWeight: 'medium' }} as="header">
        {logoImage && <img src={logoImage} alt={name} width={64} height={48} />}

        <Text as="p">{name}</Text>
      </Flex>

      {children && children}
    </Box>
  )
}

IndividualCompany.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
