import { IItem } from '../interfaces/entities/IItem'

export const transformAndSortOffers = (
  offers: IItem[],
  selectedOnly: boolean,
  selectingItemsIDs: number[],
  sortMode: string | undefined,
): IItem[] => {
  return offers
    .filter((item) => !selectedOnly || selectingItemsIDs.includes(item.id))
    .map((item) => ({
      ...item,
      limits: item.limits.map((limit) => {
        if (typeof limit.subLimits === 'undefined') {
          return {
            ...limit,
            subLimits: [limit],
          }
        }
        return { ...limit }
      }),
    }))
    .sort((a, b) => {
      if (!sortMode) {
        return 0
      }

      if (sortMode === 'asc') {
        return a.pricePerYear > b.pricePerYear ? 1 : -1
      }

      return a.pricePerYear > b.pricePerYear ? -1 : 1
    }) as IItem[]
}
