import { useNavigate } from 'react-router-dom'

import { Container } from '../../../components/Container'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { Step1Form } from '../../../containers/sme/Step1Form'
import {
  initialAdditionalValues,
  initialApplicantValues,
  initialBusinessValues,
  initialInsuranceValues,
  initialPlaceValues,
} from '../../../model/constants/insuranceForm'

export const NewStep1 = () => {
  const navigate = useNavigate()

  const handleSubmit = (response: any) => {
    navigate(`/sme/wizard/edit/${response.id}/2`)
  }

  return (
    <Section>
      <Container>
        <StepsContent>
          <Steps icon="briefcase" currentStep={1} />

          <Step1Form
            type={'new'}
            initialBusinessValues={initialBusinessValues}
            initialInsuranceValues={initialInsuranceValues}
            initialPlaceValues={[{ ...initialPlaceValues }]}
            initialAdditionalValues={initialAdditionalValues}
            initialApplicantValues={initialApplicantValues}
            onSubmit={handleSubmit}
          />
        </StepsContent>
      </Container>
    </Section>
  )
}
