import { useState } from 'react'
import {
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormHelperText,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { FormGroup } from '../../components/FormGroup'
import { FormLabel } from '../../components/FormLabel'
import { FormSpacer } from '../../components/FormSpacer'
import { InputGroup } from '../../components/InputGroup'
import { MainContent } from '../../components/MainContent'
import { Datepicker } from '../../components/Datepicker'
import { Check, Cloud } from '../../icons'
import { FormButtons } from '../FormButtons'
import { RadioStack } from '../../components/RadioStack'
import { InsuranceCompanies } from '../../components/InsuranceCompanies'
import { RadioIconGroup } from '../../components/RadioIconGroup'
import { RadioIcon } from '../../components/RadioIcon'
import { TextareaGroup } from '../../components/TextareaGroup'
import { Panel } from '../../components/Panel'
import { VehicleInformation } from '../../components/VehicleInformation'
import { VehicleEvaluation } from '../../components/VehicleEvaluation'
import { BCNOptions } from '../BCNOptions'
import { YearlyMilageSelect } from '../YearlyMilageSelect'
import { InsureeType } from '../InsureeType'
import { InsureeName } from '../InsureeName'
import { InsureeAddress } from '../InsureeAddress'
import { carkulkaCompanies } from '../../utils/companies'
import { InsuranceType } from './InsuranceType'

interface Step1FormProps {
  onSubmit: Function
}

export interface Step1FormValues {
  projectName: string
  pov: string
  hav: string
  participation: string
  startDate: Date
  contractType: string
  paymentFrequency: string
  paymentMethod: string
  regNumber: string
  tp: string
  vin: string
  vehicleType: string
  fuelType: string
  brand: string
  baseModel: string
  model: string
  volume: string
  maxPerf: string
  maxPerf2: string
  weight: string
  origin: string
  validity: string
  seats: string
  registrationDate: Date
  odometer: string
  yearlyMilage: string
  usage:
    | null
    | 'regular'
    | 'taxi'
    | 'rental-carsharing'
    | 'danger-cargo'
    | 'right-of-way'
    | 'driving-school'
    | 'delivery-courier'
  vehiclePriceType: string
  price: string
  priceVAT: boolean
  extraEquipmentValue: string
  extraEquipmentDescription: string
  insureeType: string
  bcn: string
  dateOfBirth: string
  nationality: string
  firstName: string
  lastName: string
  postalCode: string
  country: string
  city: string
  insureeVehicleHolder?: string
  insureeVehicleHolder_insureeType?: string
  insureeVehicleHolder_firstName?: string
  insureeVehicleHolder_lastName?: string
  insureeVehicleHolder_postalCode?: string
  insureeVehicleHolder_country?: string
  insureeVehicleHolder_city?: string
  insureeVehicleHolder_bcn?: string
  insureeVehicleHolder_dateOfBirth?: string
  insureeVehicleHolder_nationality?: string
  insureeVehicleOwner?: string
  insureeVehicleOwner_insureeType?: string
  insureeVehicleOwner_firstName?: string
  insureeVehicleOwner_lastName?: string
  insureeVehicleOwner_postalCode?: string
  insureeVehicleOwner_country?: string
  insureeVehicleOwner_city?: string
  insureeVehicleOwner_bcn?: string
  insureeVehicleOwner_dateOfBirth?: string
  insureeVehicleOwner_nationality?: string
}

const vehicleInformationItems = [
  { label: 'Registrační značka (SPZ)', value: '' },
  { label: 'Číslo TP', value: '' },
  { label: 'VIN', value: 'TMBER6NH7J4546558' },
  { label: 'Kontrola VIN', value: '' },
  { label: 'Značka', value: 'SKODA' },
  { label: 'Model', value: 'RAPID, RAPID SPACEBACK' },
  { label: 'Generace', value: '2' },
  { label: 'Verze', value: '1.0 TSI 81kW Monte Carlo Spaceback ' },
  { label: 'První registrace', value: '30.01.2018' },
  { label: 'Druh vozidla', value: 'osobní (hatchback)' },
  { label: 'Druh paliva', value: 'B' },
  { label: 'Celková hmotnost', value: '1645' },
  { label: 'Výkon', value: '81' },
  { label: 'Objem', value: '999' },
  { label: 'Míst k sezení', value: '5' },
]

export const Step1Form = ({ onSubmit }: Step1FormProps) => {
  const methods = useForm<Step1FormValues>({
    defaultValues: {
      startDate: new Date(),
      brand: '',
      baseModel: '',
      model: '',
      origin: 'cz',
      validity: 'europe',
      seats: '5',
      usage: 'regular',
      vehiclePriceType: 'invoice',
      nationality: 'CZ',
      insureeType: '2',
      insureeVehicleHolder: '1',
      insureeVehicleOwner: '1',
    },
  })
  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = methods
  const [showVehicleInformation, setShowVehicleInformation] = useState(false)
  const [vehicleInformationLoading, setVehicleInformationLoading] = useState(false)
  const [showVehicleEvaluation, setShowVehicleEvaluation] = useState(false)
  const [vehicleEvaluationLoading, setVehicleEvaluationLoading] = useState(false)

  const onSubmitForm = (values: Step1FormValues) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(values)
        resolve(true)
      }, 500)
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <MainContent>
          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Parametry pojištění</FormSectionTitle>

              <FormSectionDescription>Zadejte základní parametry pojištění.</FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              {/* Project name */}
              <FormControl isInvalid={!!errors['projectName']}>
                <FormLabel htmlFor="projectName">Název nabídky</FormLabel>
                <InputGroup
                  id="projectName"
                  placeholder="Zadejte název nabídky"
                  htmlSize={32}
                  errors={errors['projectName']}
                  {...register('projectName', {
                    required: 'Zadejte název nabídky',
                  })}
                />
              </FormControl>

              <FormSpacer />

              {/* Insurance type */}
              <InsuranceType />

              <FormSpacer />

              <FormGroup size="lg">
                {/* Date */}
                <FormControl>
                  <FormLabel htmlFor="startDate">Datum počátku pojištění</FormLabel>

                  <Datepicker
                    date={getValues('startDate')}
                    onDateChange={(date: Date) => setValue('startDate', date)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Způsob sjednání smlouvy</FormLabel>

                  <RadioGroup>
                    <RadioStack variant="stackedTile">
                      <Radio value="signature" {...register('contractType')}>
                        Podpisem
                      </Radio>
                      <Radio value="payment" {...register('contractType')}>
                        Zaplacením
                      </Radio>
                    </RadioStack>
                  </RadioGroup>
                </FormControl>
              </FormGroup>

              <FormSpacer />

              <FormGroup size="lg">
                <FormControl>
                  <FormLabel htmlFor="paymentFrequency">Frekvence plateb</FormLabel>
                  <RadioGroup>
                    <Stack direction="column" spacing={4}>
                      <Radio value="1" {...register('paymentFrequency')}>
                        Roční
                      </Radio>
                      <Radio value="2" {...register('paymentFrequency')}>
                        Pololetní
                      </Radio>
                      <Radio value="3" {...register('paymentFrequency')}>
                        Čtvrtletní
                      </Radio>
                      <Radio value="4" {...register('paymentFrequency')}>
                        Měsíční
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="paymentMethod">Způsob platby</FormLabel>
                  <RadioGroup>
                    <Stack direction="column" spacing={4}>
                      <Radio value="1" {...register('paymentMethod')}>
                        Převodem z účtu
                      </Radio>
                      <Radio value="2" {...register('paymentMethod')}>
                        Složenkou
                      </Radio>
                      <Radio value="3" {...register('paymentMethod')}>
                        SIPO
                      </Radio>
                      <Radio value="4" {...register('paymentMethod')}>
                        Inkasem z účtu
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </FormGroup>

              <FormSpacer />

              <InsuranceCompanies companies={carkulkaCompanies} />

              <Alert colorScheme="orange">
                Pojišťovna PVZP nebude kalkulována, protože nenabízí variantu do 5% spoluúčasti.
                Pojištění u Allianz bude kalkulováno v balíčku včetně skel. Pojištění u Allianz bude
                kalkulováno se spoluúčastí 5 000 Kč.
              </Alert>
            </FormSectionBody>
          </FormSection>

          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Informace o vozidle</FormSectionTitle>

              <FormSectionDescription>
                Využijte automatické vyplnění pomocí SPZ a čísla velkého technického průkazu nebo
                VIN čísla.
              </FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              <FormGroup variant="carRegistration">
                <FormGroup>
                  {/* SPZ */}
                  <FormControl isInvalid={!!errors['regNumber']}>
                    <FormLabel htmlFor="regNumber">Reg. značka (SPZ)</FormLabel>
                    <InputGroup
                      id="regNumber"
                      placeholder="SPZ"
                      htmlSize={8}
                      autoComplete="off"
                      errors={errors['regNumber']}
                      {...register('regNumber', {
                        pattern: /[a-zA-Z]{6,8}/gi,
                        minLength: 7,
                      })}
                    />
                  </FormControl>

                  {/* TP */}
                  <FormControl isInvalid={!!errors['tp']}>
                    <FormLabel htmlFor="tp">Číslo velkého TP</FormLabel>
                    <InputGroup
                      id="tp"
                      placeholder="Číslo TP"
                      htmlSize={8}
                      autoComplete="off"
                      errors={errors['tp']}
                      {...register('tp', {
                        minLength: 8,
                        maxLength: 8,
                      })}
                    />
                  </FormControl>

                  <Text variant="textMuted" sx={{ alignSelf: 'end', height: '30px' }}>
                    nebo
                  </Text>

                  {/* VIN */}
                  <FormControl isInvalid={!!errors['vin']}>
                    <FormLabel htmlFor="vin" tooltip="VIN">
                      VIN
                    </FormLabel>
                    <InputGroup
                      id="vin"
                      placeholder="Např: WMWXP7C54G2A43037"
                      htmlSize={18}
                      autoComplete="off"
                      errors={errors['vin']}
                      {...register('vin')}
                    />
                  </FormControl>
                </FormGroup>

                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setVehicleInformationLoading(true)
                    setShowVehicleInformation(false)

                    setTimeout(() => {
                      setShowVehicleInformation(true)
                      setVehicleInformationLoading(false)
                    }, 2000)
                  }}
                  isLoading={vehicleInformationLoading}
                >
                  <Cloud color="secondary.500" />
                  Načíst údaje o vozidle
                </Button>
              </FormGroup>

              {showVehicleInformation && (
                <Panel>
                  <Text>
                    <VehicleInformation items={vehicleInformationItems} />
                  </Text>

                  <Button type="button" variant="outline">
                    Vložit informace do nabídky
                  </Button>
                </Panel>
              )}

              <FormSpacer />

              <FormControl>
                <FormLabel tooltip="Vyberte typ vozidla podle technického průkazu">
                  Druh vozidla
                </FormLabel>

                <RadioIconGroup control={control} name="vehicleType">
                  <RadioStack variant="tile">
                    <RadioIcon icon="car" value="car" {...register('vehicleType')}>
                      Osobní auto
                    </RadioIcon>
                    <RadioIcon icon="truck" value="truck" {...register('vehicleType')}>
                      Užitkové vozidlo
                    </RadioIcon>
                    <RadioIcon icon="motobike" value="motobike" {...register('vehicleType')}>
                      Motocykl
                    </RadioIcon>
                    <RadioIcon icon="other" value="other" {...register('vehicleType')}>
                      Ostatní
                    </RadioIcon>
                  </RadioStack>
                </RadioIconGroup>
              </FormControl>

              <FormSpacer />

              <FormGroup>
                <FormControl sx={{ w: '280px' }}>
                  <FormLabel htmlFor="fuelType">Palivo</FormLabel>

                  <FormHelperText>Vyberte druh paliva podle technického průkazu</FormHelperText>

                  <Select id="fuelType" {...register('fuelType')}>
                    <option value="gas">Benzín</option>
                    <option value="diesel">Nafta</option>
                    <option value="electric">Elektrické</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="brand" tooltip="Vyberte značku podle technického průkazu">
                    Tovární značka
                  </FormLabel>

                  <FormHelperText>Vyberte značku podle technického průkazu</FormHelperText>

                  <Select id="brand" {...register('brand')}>
                    <option value="" disabled>
                      Vyberte tovární značku vozidla
                    </option>
                    <option value="abarth">Abarth</option>
                    <option value="alfa-romeo">Alfa Romeo</option>
                    <option value="audi">Audi</option>
                  </Select>
                </FormControl>
              </FormGroup>

              <FormSpacer />

              <FormGroup>
                <FormControl sx={{ w: '280px' }}>
                  <FormLabel htmlFor="baseModel" tooltip="Základní model">
                    Základní model
                  </FormLabel>

                  <Select id="baseModel" {...register('baseModel')}>
                    <option disabled value="">
                      Vyberte základní model vozidla
                    </option>
                    <option value="cooper">Cooper</option>
                    <option value="cooper-s">Cooper S</option>
                    <option value="cooper-jcw">Cooper JCW</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="model" tooltip="Model">
                    Model
                  </FormLabel>

                  <Select id="model" {...register('model')}>
                    <option disabled value="">
                      Vyberte model vozidla
                    </option>
                    <option value="cooper">Cooper</option>
                    <option value="cooper-s">Cooper S</option>
                    <option value="cooper-jcw">Cooper JCW</option>
                  </Select>
                </FormControl>
              </FormGroup>

              <FormSpacer />

              <FormGroup>
                <FormControl isInvalid={!!errors['volume']}>
                  <FormLabel htmlFor="volume" tooltip="Zdvihový objem">
                    Zdvihový objem
                  </FormLabel>
                  <InputGroup
                    id="volume"
                    placeholder="Např. 1589"
                    htmlSize={6}
                    autoComplete="off"
                    inputMode="decimal"
                    errors={errors['volume']}
                    rightElement="㎤"
                    {...register('volume')}
                  />
                </FormControl>

                <FormControl isInvalid={!!errors['maxPerf']}>
                  <FormLabel htmlFor="maxPerf" tooltip="Maximální výkon">
                    Maximální výkon
                  </FormLabel>
                  <InputGroup
                    id="maxPerf"
                    placeholder="Např. 125"
                    htmlSize={6}
                    autoComplete="off"
                    inputMode="decimal"
                    errors={errors['maxPerf']}
                    rightElement="kW"
                    {...register('maxPerf')}
                  />
                </FormControl>

                <FormControl isInvalid={!!errors['maxPerf2']}>
                  <FormLabel htmlFor="maxPerf2" tooltip="Maximální výkon 2">
                    Maximální výkon 2
                  </FormLabel>
                  <InputGroup
                    id="maxPerf2"
                    placeholder="Např. 200"
                    htmlSize={6}
                    autoComplete="off"
                    inputMode="decimal"
                    errors={errors['maxPerf2']}
                    rightElement="kW"
                    {...register('maxPerf2')}
                  />
                </FormControl>

                <FormControl isInvalid={!!errors['weight']}>
                  <FormLabel htmlFor="weight" tooltip="Největší technicky přípustná hmotnost">
                    Největší technicky přípustná hmotnost
                  </FormLabel>
                  <InputGroup
                    id="weight"
                    placeholder="Např. 1600"
                    htmlSize={6}
                    autoComplete="off"
                    inputMode="decimal"
                    errors={errors['weight']}
                    rightElement="kg"
                    {...register('weight')}
                  />
                </FormControl>
              </FormGroup>

              <FormSpacer />

              <FormGroup>
                <FormControl>
                  <FormLabel>Původ vozidla</FormLabel>

                  <FormHelperText>&nbsp;</FormHelperText>

                  <RadioGroup>
                    <RadioStack variant="stackedTile">
                      <Radio value="cz" {...register('origin')}>
                        Česká republika
                      </Radio>
                      <Radio value="foreign" {...register('origin')}>
                        Zahraničí
                      </Radio>
                    </RadioStack>
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <FormLabel>Územní platnost</FormLabel>

                  <FormHelperText>Má vliv na cenu u pojišťoven: Direct, Slavia</FormHelperText>

                  <RadioGroup>
                    <RadioStack variant="stackedTile">
                      <Radio value="europe" {...register('validity')}>
                        Evropa
                      </Radio>
                      <Radio value="cz" {...register('validity')}>
                        Česká republika
                      </Radio>
                    </RadioStack>
                  </RadioGroup>
                </FormControl>
              </FormGroup>

              <FormSpacer />

              <FormGroup>
                <FormControl isInvalid={!!errors['seats']}>
                  <FormLabel htmlFor="seats" tooltip="Počet míst k sezení">
                    Počet míst k sezení
                  </FormLabel>
                  <InputGroup
                    id="seats"
                    placeholder="Např. 5"
                    type="number"
                    htmlSize={6}
                    min="1"
                    max="200"
                    autoComplete="off"
                    inputMode="numeric"
                    errors={errors['seats']}
                    {...register('seats')}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="registrationDate" tooltip="Datum první registrace">
                    Datum první registrace
                  </FormLabel>

                  <Datepicker
                    date={getValues('registrationDate')}
                    onDateChange={(date: Date) => setValue('registrationDate', date)}
                  />
                </FormControl>

                <FormControl isInvalid={!!errors['odometer']}>
                  <FormLabel htmlFor="odometer" tooltip="Stav tachometru">
                    Stav tachometru
                  </FormLabel>
                  <InputGroup
                    id="odometer"
                    placeholder="Např. 68 000"
                    type="number"
                    htmlSize={12}
                    min="0"
                    max="99999999"
                    autoComplete="off"
                    inputMode="decimal"
                    errors={errors['odometer']}
                    rightElement="km"
                    {...register('odometer', {
                      required: 'Zadejte prosím počet ujetých kilometrů.',
                    })}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="yearlyMilage" tooltip="Roční nájezd">
                    Roční nájezd
                  </FormLabel>

                  <YearlyMilageSelect {...register('yearlyMilage')} />
                </FormControl>
              </FormGroup>

              <FormSpacer />

              {/* Usage */}
              <FormControl>
                <FormLabel
                  htmlFor="usage"
                  tooltip="V případě jiného použití než „běžné“ se sjednává přirážka k pojistnému."
                >
                  Použití
                </FormLabel>

                <Select id="usage" {...register('usage')}>
                  <option value="regular">Běžné použití</option>
                  <option value="taxi">Taxi nebo sdílená přeprava</option>
                  <option value="rental-carsharing">Autopůjčovna nebo carsharing</option>
                  <option value="danger-cargo">Přeprava nebezpečných nákladů</option>
                  <option value="right-of-way">S právem přednosti v jízdě</option>
                  <option value="driving-school">Autoškola</option>
                  <option value="delivery-courier">Rozvoz zboží, kurýrní služby</option>
                </Select>
              </FormControl>

              <FormSpacer />

              <FormGroup variant="price">
                <FormControl>
                  <FormLabel tooltip="Cena vozidla z">Cena vozidla z</FormLabel>

                  <RadioGroup>
                    <RadioStack variant="stackedTile">
                      <Radio value="invoice" {...register('vehiclePriceType')}>
                        Faktury
                      </Radio>
                      <Radio value="evaluation" {...register('vehiclePriceType')}>
                        Ocenení vozidla
                      </Radio>
                      <Radio value="customer" {...register('vehiclePriceType')}>
                        Dle pojistníka
                      </Radio>
                    </RadioStack>
                  </RadioGroup>
                </FormControl>

                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setVehicleEvaluationLoading(true)
                    setShowVehicleEvaluation(false)

                    setTimeout(() => {
                      setShowVehicleEvaluation(true)
                      setVehicleEvaluationLoading(false)
                    }, 1000)
                  }}
                  isLoading={vehicleEvaluationLoading}
                >
                  <Cloud color="secondary.500" /> Ocenit vozidlo
                </Button>
              </FormGroup>

              {showVehicleEvaluation && (
                <VehicleEvaluation
                  description="SKODA RAPID 2. gen 1.0 TSI 81kW Monte Carlo Spaceback"
                  pdfLink="https://carkulka.okholding.cz/test/InsuranceWizard/GetIdentificatedVehiclePdf/15586?vin=TMBER6NH7J4546558&amp;vehicleId=AEO-3VU.AMA-MEW"
                  onMarketSince={new Date(2014, 11, 1)}
                  onMarketTill={new Date(2018, 4, 28)}
                  priceWithVAT={453200}
                  onApplyEvaluation={({ price, withTax }: { price: number; withTax: boolean }) => {
                    setValue('price', price.toString())
                    setValue('priceVAT', withTax)
                    setShowVehicleEvaluation(false)
                  }}
                />
              )}

              <FormGroup>
                <FormControl isInvalid={!!errors['price']}>
                  <FormLabel htmlFor="price" tooltip="Cena vozidla">
                    Cena vozidla
                  </FormLabel>
                  <InputGroup
                    id="price"
                    placeholder="Např. 599 000"
                    type="number"
                    htmlSize={12}
                    min="0"
                    max="999999999"
                    autoComplete="off"
                    inputMode="decimal"
                    errors={errors['price']}
                    {...register('price')}
                  />
                </FormControl>

                <FormControl
                  sx={{ display: 'flex', alignItems: 'center', alignSelf: 'end', py: 1 }}
                >
                  <FormLabel htmlFor="priceVAT" variant="switch">
                    bez DPH
                  </FormLabel>
                  <Switch id="priceVAT" isChecked={watch('priceVAT')} {...register('priceVAT')} />
                  <FormLabel htmlFor="priceVAT" variant="switch">
                    s DPH
                  </FormLabel>
                </FormControl>
              </FormGroup>

              <FormSpacer />

              <FormControl>
                <FormLabel htmlFor="equipment">Výbava vozidla</FormLabel>

                <FormHelperText>
                  Ovlivňuje cenu havarijního pojištění u Kooperativa pojišťovny.
                </FormHelperText>

                <CheckboxGroup defaultValue={['awd']}>
                  <Stack spacing={4} direction={['column', 'row']}>
                    <Checkbox value="park-assist">Parkovací asistent</Checkbox>
                    <Checkbox value="transmission-auto">Automatická převodovka</Checkbox>
                    <Checkbox value="awd">Pohon 4×4</Checkbox>
                  </Stack>
                </CheckboxGroup>
              </FormControl>

              <FormSpacer />

              <FormControl>
                <FormLabel htmlFor="equipment" tooltip="Zabezpečení">
                  Zabezpečení
                </FormLabel>

                <CheckboxGroup defaultValue={['awd']}>
                  <Stack spacing={4} direction={['column', 'row']}>
                    <Stack spacing={4} direction="column">
                      <Checkbox value="imobiliser">Imobilizér</Checkbox>
                      <Checkbox value="alarm">Alarm</Checkbox>
                      <Checkbox value="lock">Zamykání řadící páky</Checkbox>
                      <Checkbox value="lock2">Zamykání automatické převodovky</Checkbox>
                    </Stack>
                    <Stack spacing={4} direction="column">
                      <Checkbox value="passive-localization">
                        Pasivní vyhledávací zabezpečení
                      </Checkbox>
                      <Checkbox value="active-localization">
                        Aktivní vyhledávací zabezpečení
                      </Checkbox>
                      <Checkbox value="window-marking">Značení skel</Checkbox>
                      <Checkbox value="steering-wheel-lock">Blokování volantu</Checkbox>
                    </Stack>
                  </Stack>
                </CheckboxGroup>
              </FormControl>

              <FormSpacer />

              <FormLabel>Mimořádná výbava</FormLabel>

              <FormControl isInvalid={!!errors['extraEquipmentValue']}>
                <FormLabel htmlFor="extraEquipmentValue" tooltip="Hodnota mimořádné výbavy">
                  Hodnota mimořádné výbavy
                </FormLabel>
                <InputGroup
                  id="extraEquipmentValue"
                  placeholder="Např. 50 000"
                  type="number"
                  htmlSize={12}
                  min="0"
                  autoComplete="off"
                  inputMode="decimal"
                  errors={errors['extraEquipmentValue']}
                  {...register('extraEquipmentValue')}
                />
              </FormControl>

              <FormControl isInvalid={!!errors['extraEquipmentDescription']}>
                <FormLabel htmlFor="extraEquipmentDescription" tooltip="Popis mimořádné výbavy">
                  Popis mimořádné výbavy
                </FormLabel>
                <TextareaGroup
                  id="extraEquipmentDescription"
                  placeholder="Např. Polepy, tuning apod."
                  cols={76}
                  rows={4}
                  autoComplete="off"
                  errors={errors['extraEquipmentDescription']}
                  {...register('extraEquipmentDescription')}
                />

                <FormHelperText>Tento popis bude uveden ve smlouvě</FormHelperText>
              </FormControl>
            </FormSectionBody>
          </FormSection>

          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Informace o pojistníkovi</FormSectionTitle>

              <FormSectionDescription>Zadejte informace o pojistníkovi.</FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              <InsureeType />

              <FormSpacer />

              <BCNOptions />

              <FormGroup>
                <InsureeName />
              </FormGroup>

              <FormSpacer />

              <InsureeAddress />

              <FormSpacer />

              <FormControl>
                <FormLabel tooltip="Držitel/provozovatel vozidla">
                  Držitel/provozovatel vozidla
                </FormLabel>

                <RadioGroup>
                  <RadioStack variant="stackedTile">
                    <Radio value="sameAsInsuree" {...register('insureeVehicleHolder')}>
                      Stejný s pojistníkem
                    </Radio>
                    <Radio value="other" {...register('insureeVehicleHolder')}>
                      Jiný
                    </Radio>
                  </RadioStack>
                </RadioGroup>
              </FormControl>

              {watch('insureeVehicleHolder') === 'other' && (
                <Panel>
                  <InsureeType insureeTypeId="insureeVehicleHolder_insureeType" />

                  <FormSpacer />

                  <BCNOptions
                    bcnId="insureeVehicleHolder_bcn"
                    noBCNId="insureeVehicleHolder_noBCN"
                    dateOfBirthId="insureeVehicleHolder_dateOfBirth"
                    nationalityId="insureeVehicleHolder_nationality"
                  />

                  <FormGroup>
                    <InsureeName
                      firstNameId="insureeVehicleHolder_firstName"
                      lastNameId="insureeVehicleHolder_lastName"
                    />
                  </FormGroup>

                  <FormSpacer />

                  <InsureeAddress
                    postalCodeId="insureeVehicleHolder_postalCode"
                    countryId="insureeVehicleHolder_country"
                    cityId="insureeVehicleHolder_city"
                  />
                </Panel>
              )}

              <FormControl>
                <FormLabel tooltip="Vlastník vozidla">Vlastník vozidla</FormLabel>

                <RadioGroup>
                  <RadioStack variant="stackedTile">
                    <Radio value="sameAsInsuree" {...register('insureeVehicleOwner')}>
                      Stejný s pojistníkem
                    </Radio>
                    <Radio value="sameAsHolder" {...register('insureeVehicleOwner')}>
                      Stejný s držitelem/provozovatelem
                    </Radio>
                    <Radio value="other" {...register('insureeVehicleOwner')}>
                      Jiný
                    </Radio>
                  </RadioStack>
                </RadioGroup>
              </FormControl>

              {watch('insureeVehicleOwner') === 'other' && (
                <Panel>
                  <InsureeType insureeTypeId="insureeVehicleOwner_insureeType" />

                  <FormSpacer />

                  <BCNOptions
                    bcnId="insureeVehicleOwner_bcn"
                    noBCNId="insureeVehicleOwner_noBCN"
                    dateOfBirthId="insureeVehicleOwner_dateOfBirth"
                    nationalityId="insureeVehicleOwner_nationality"
                  />

                  <FormGroup>
                    <InsureeName
                      firstNameId="insureeVehicleOwner_firstName"
                      lastNameId="insureeVehicleOwner_lastName"
                    />
                  </FormGroup>

                  <FormSpacer />

                  <InsureeAddress
                    postalCodeId="insureeVehicleOwner_postalCode"
                    countryId="insureeVehicleOwner_country"
                    cityId="insureeVehicleOwner_city"
                  />
                </Panel>
              )}
            </FormSectionBody>
          </FormSection>

          <FormButtons
            rightButtons={
              <>
                <Button variant="outline" disabled={isSubmitting} type="button">
                  Uložit
                </Button>
                <Button
                  colorScheme="primary"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  loadingText="Ukládám..."
                  type="submit"
                >
                  <Check />
                  Uložit a pokračovat
                </Button>
              </>
            }
          />
        </MainContent>
      </form>
    </FormProvider>
  )
}
