import { useState } from 'react'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { useQuery } from '@tanstack/react-query'
import api from '../api'

interface EmailTemplate {
  headerText: string
  bodyText: string
}

const useEmailTemplate = () => {
  return useQuery(
    [ApiRouteEnum.EMAIL_TEMPLATE],
    () => api.get<EmailTemplate>(ApiRouteEnum.EMAIL_TEMPLATE),
    {
      retry: false
    },
  )
}

export default useEmailTemplate
