import { Icon } from '@chakra-ui/react'

export const User = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M7.99996 7C7.99996 6.46957 8.21068 5.96086 8.58575 5.58579C8.96082 5.21071 9.46953 5 9.99996 5C10.5304 5 11.0391 5.21071 11.4142 5.58579C11.7892 5.96086 12 6.46957 12 7C12 7.53043 11.7892 8.03914 11.4142 8.41421C11.0391 8.78929 10.5304 9 9.99996 9C9.46953 9 8.96082 8.78929 8.58575 8.41421C8.21068 8.03914 7.99996 7.53043 7.99996 7Z" fill="currentColor"/>
<path d="M7.49997 10H12.5C12.8978 10 13.2793 10.158 13.5606 10.4393C13.8419 10.7206 14 11.1022 14 11.5C14 12.616 13.541 13.51 12.788 14.115C12.047 14.71 11.053 15 9.99997 15C8.94697 15 7.95297 14.71 7.21197 14.115C6.45997 13.51 5.99997 12.616 5.99997 11.5C5.99997 11.1022 6.15801 10.7206 6.43931 10.4393C6.72062 10.158 7.10215 10 7.49997 10V10Z" fill="currentColor"/>
<path d="M9.99997 2C7.87823 2 5.8434 2.84285 4.34311 4.34315C2.84282 5.84344 1.99997 7.87827 1.99997 10C1.99997 12.1217 2.84282 14.1566 4.34311 15.6569C5.8434 17.1571 7.87823 18 9.99997 18C12.1217 18 14.1565 17.1571 15.6568 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6568 4.34315C14.1565 2.84285 12.1217 2 9.99997 2V2ZM2.99997 10C2.99997 9.08075 3.18103 8.17049 3.53281 7.32122C3.88459 6.47194 4.40021 5.70026 5.05022 5.05025C5.70023 4.40024 6.4719 3.88463 7.32118 3.53284C8.17046 3.18106 9.08071 3 9.99997 3C10.9192 3 11.8295 3.18106 12.6787 3.53284C13.528 3.88463 14.2997 4.40024 14.9497 5.05025C15.5997 5.70026 16.1153 6.47194 16.4671 7.32122C16.8189 8.17049 17 9.08075 17 10C17 11.8565 16.2625 13.637 14.9497 14.9497C13.637 16.2625 11.8565 17 9.99997 17C8.14345 17 6.36297 16.2625 5.05022 14.9497C3.73746 13.637 2.99997 11.8565 2.99997 10Z" fill="currentColor"/>
	</Icon>
)


export const UserString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.99996 7C7.99996 6.46957 8.21068 5.96086 8.58575 5.58579C8.96082 5.21071 9.46953 5 9.99996 5C10.5304 5 11.0391 5.21071 11.4142 5.58579C11.7892 5.96086 12 6.46957 12 7C12 7.53043 11.7892 8.03914 11.4142 8.41421C11.0391 8.78929 10.5304 9 9.99996 9C9.46953 9 8.96082 8.78929 8.58575 8.41421C8.21068 8.03914 7.99996 7.53043 7.99996 7Z" fill="#003D66"/>
<path d="M7.49997 10H12.5C12.8978 10 13.2793 10.158 13.5606 10.4393C13.8419 10.7206 14 11.1022 14 11.5C14 12.616 13.541 13.51 12.788 14.115C12.047 14.71 11.053 15 9.99997 15C8.94697 15 7.95297 14.71 7.21197 14.115C6.45997 13.51 5.99997 12.616 5.99997 11.5C5.99997 11.1022 6.15801 10.7206 6.43931 10.4393C6.72062 10.158 7.10215 10 7.49997 10V10Z" fill="#003D66"/>
<path d="M9.99997 2C7.87823 2 5.8434 2.84285 4.34311 4.34315C2.84282 5.84344 1.99997 7.87827 1.99997 10C1.99997 12.1217 2.84282 14.1566 4.34311 15.6569C5.8434 17.1571 7.87823 18 9.99997 18C12.1217 18 14.1565 17.1571 15.6568 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6568 4.34315C14.1565 2.84285 12.1217 2 9.99997 2V2ZM2.99997 10C2.99997 9.08075 3.18103 8.17049 3.53281 7.32122C3.88459 6.47194 4.40021 5.70026 5.05022 5.05025C5.70023 4.40024 6.4719 3.88463 7.32118 3.53284C8.17046 3.18106 9.08071 3 9.99997 3C10.9192 3 11.8295 3.18106 12.6787 3.53284C13.528 3.88463 14.2997 4.40024 14.9497 5.05025C15.5997 5.70026 16.1153 6.47194 16.4671 7.32122C16.8189 8.17049 17 9.08075 17 10C17 11.8565 16.2625 13.637 14.9497 14.9497C13.637 16.2625 11.8565 17 9.99997 17C8.14345 17 6.36297 16.2625 5.05022 14.9497C3.73746 13.637 2.99997 11.8565 2.99997 10Z" fill="#003D66"/>
</svg>
`;