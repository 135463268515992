import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

interface StepsContentProps extends BoxProps {
  children: React.ReactNode
}

export const StepsContent = ({ children }: StepsContentProps) => {
  return <Box sx={{ display: 'grid', gap: 8, alignItems: 'start' }}>{children}</Box>
}
