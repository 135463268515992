import { Button, Flex, Text } from '@chakra-ui/react'
import { MouseEventHandler } from 'react'
import { IQueryParams } from '../model/interfaces/entities/IQueryParams'
import { ChevronLeft, ChevronRight } from '../icons'

interface PaginationProps {
  totalPages: number
  currentPage: number | undefined
  queryParams?: IQueryParams
  setQueryParams?: Function
  startPage?: number
  endPage?: number
  onSetStartPage: any
  onSetEndPage: any
  hide?: boolean
  count?: number
}

const Page = ({
  pageNumber,
  isCurrent,
  onClick,
}: {
  pageNumber: number
  isCurrent: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}) => {
  return (
    <Button
      type="button"
      variant={isCurrent ? 'solid' : 'ghost'}
      colorScheme={isCurrent ? 'primary' : 'white'}
      onClick={onClick}
    >
      {pageNumber}
    </Button>
  )
}

export const Pagination = ({
  totalPages,
  currentPage,
  queryParams,
  setQueryParams,
  startPage = 1,
  endPage = 10,
  onSetStartPage,
  onSetEndPage,
  hide,
  count,
}: PaginationProps) => {
  const shiftValue = 5
  const handlePageMove = (direction: string) => {
    if (direction === 'prev' && queryParams && setQueryParams && currentPage) {
      onSetStartPage((prevStartPage: number) => prevStartPage && prevStartPage - shiftValue)
      onSetEndPage((prevEndPage: number) => prevEndPage && prevEndPage - shiftValue)
    }
    if (direction === 'next' && queryParams && setQueryParams) {
      onSetStartPage((prevStartPage: number) => prevStartPage && prevStartPage + shiftValue)
      onSetEndPage(
        (prevEndPage: number) => prevEndPage && Math.min(totalPages, prevEndPage + shiftValue),
      )
    }
  }
  const onNextPageClick = (event: any) => {
    const clickedPage: number = Number(event.target.textContent)
    if (clickedPage === currentPage) {
      return null
    }

    if (queryParams && setQueryParams) {
      setQueryParams({ ...queryParams, page: clickedPage })
    }

    if (clickedPage === endPage && endPage !== totalPages) {
      onSetStartPage((prevStartPage: number) => prevStartPage && prevStartPage + shiftValue)
      onSetEndPage(
        (prevEndPage: number) => prevEndPage && Math.min(totalPages, prevEndPage + shiftValue),
      )
    }
    if (clickedPage === startPage && startPage !== 1) {
      onSetStartPage((prevStartPage: number) => prevStartPage && prevStartPage - shiftValue)
      onSetEndPage((prevEndPage: number) => prevEndPage && prevEndPage - shiftValue)
    }
  }

  return (
    <>
      <Flex
        visibility={hide ? 'hidden' : 'visible'}
        sx={{ w: '100%', gap: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        {startPage > 1 && (
          <ChevronLeft sx={{ cursor: 'pointer' }} onClick={() => handlePageMove('prev')} />
        )}
        {Array.from(
          { length: Math.min(totalPages, endPage) - startPage + 1 },
          (_, i) => startPage + i,
        ).map((page) => (
          <Page
            key={page}
            pageNumber={page}
            isCurrent={page === currentPage}
            onClick={(event) => onNextPageClick(event)}
          />
        ))}
        {endPage < totalPages && (
          <ChevronRight sx={{ cursor: 'pointer' }} onClick={() => handlePageMove('next')} />
        )}
      </Flex>
      {typeof count !== 'undefined' && (
        <Flex
          visibility={hide ? 'hidden' : 'visible'}
          sx={{ w: '100%', gap: 1, justifyContent: 'end', alignItems: 'end' }}
        >
          <Text>celkem {count} záznamů</Text>
        </Flex>
      )}
    </>
  )
}
