import { Checkbox, Flex, FormControl, Radio, RadioGroup, Tooltip } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { FormGroup } from '../components/FormGroup'
import { FormLabel } from '../components/FormLabel'
import { FormSpacer } from '../components/FormSpacer'
import { InputGroup } from '../components/InputGroup'
import { PhoneInputGroup } from '../components/PhoneInputGroup'
import { RadioStack } from '../components/RadioStack'
import { Info } from '../icons'
import { BCNOptions } from './BCNOptions'
import { InsureeAddress } from './InsureeAddress'
import { InsureeName } from './InsureeName'
import { InsureeType } from './InsureeType'

export const AdditionalInsureePropertyInfo = () => {
  const { register } = useFormContext()

  return (
    <>
      <InsureeType />

      <FormSpacer />

      <BCNOptions />

      <FormGroup>
        <FormControl>
          <FormLabel>Titul před jménem</FormLabel>

          <InputGroup placeholder="Např. Ing." htmlSize={8} {...register('namePrefix')} />
        </FormControl>

        <InsureeName isDisabled />

        <FormControl>
          <FormLabel>Titul za jménem</FormLabel>

          <InputGroup placeholder="Např. PhD." htmlSize={8} {...register('namePostfix')} />
        </FormControl>
      </FormGroup>

      <FormSpacer />

      <>
        <FormGroup>
          <FormControl>
            <FormLabel>Ulice</FormLabel>

            <InputGroup placeholder="Např. Dlouhá" htmlSize={24} {...register('street')} />
          </FormControl>

          <FormControl>
            <FormLabel>Číslo popisné nebo orientační</FormLabel>

            <InputGroup placeholder="Např. 123" htmlSize={4} {...register('houseNumber')} />
          </FormControl>
        </FormGroup>

        <InsureeAddress isDisabled />
      </>

      <FormSpacer />

      <FormGroup>
        <FormControl>
          <FormLabel>Mobilní telefon</FormLabel>

          <PhoneInputGroup
            select={register('mobilePhonePrefix')}
            input={register('mobilePhoneNumber')}
          />
        </FormControl>

        <FormControl>
          <FormLabel optional>Pevná linka</FormLabel>

          <PhoneInputGroup select={register('landlinePrefix')} input={register('landlineNumber')} />
        </FormControl>

        <FormControl>
          <FormLabel>E-mail</FormLabel>

          <InputGroup type="email" {...register('email')} />
        </FormControl>
      </FormGroup>

      <Flex sx={{ gap: 2 }}>
        <Checkbox {...register('acceptECommunication')}>
          Souhlas s elektronickou komunikací
        </Checkbox>
        <Tooltip
          shouldWrapChildren
          hasArrow
          label="Souhlas s elektronickou komunikací"
          placement="top"
        >
          <Info color="blue.500" />
        </Tooltip>
      </Flex>

      <FormSpacer />

      <FormControl>
        <FormLabel tooltip="Držitel/provozovatel vozidla">Pojištěný</FormLabel>

        <RadioGroup>
          <RadioStack variant="stackedTile">
            <Radio value="1" {...register('insureeVehicleHolder')}>
              Stejný s pojistníkem
            </Radio>
            <Radio value="2" {...register('insureeVehicleHolder')}>
              Jiný
            </Radio>
          </RadioStack>
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabel tooltip="Korespondenční adresa">Korespondenční adresa</FormLabel>

        <RadioGroup>
          <RadioStack variant="stackedTile">
            <Radio value="1" {...register('insureeCorrespondenceAddress')}>
              Stejná s pojistníkem
            </Radio>
            <Radio value="1" {...register('insureeCorrespondenceAddress')}>
              Stejná s držitelem
            </Radio>
            <Radio value="3" {...register('insureeCorrespondenceAddress')}>
              Jiný
            </Radio>
          </RadioStack>
        </RadioGroup>
      </FormControl>
    </>
  )
}
