import { Box, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { OrganizationEditFormValues } from '../model/interfaces/entities/IOrganizationEditFormValues'
import { usePutOrganizations } from '../model/api/organization/usePutOrganizations'
import { IOrganization } from '../model/interfaces/entities/IOrganization'
import { useGetCompanies } from '../model/api/company/useGetCompany'
import { useGetOrganizationsId } from '../model/api/organization/useGetOrganizationId'
import { Loading } from '../components/Loading'
import { OrganizationForm } from './OrganizationForm'
import transformPersonalNumbers from '../model/utils/transformPersonalNumbers'
import { useIsSupervisorOrAdmin } from '../model/utils/userUtils'
import { useEffect } from 'react'

export const OrganizationEditForm = () => {
  const toastMessage = useToast()
  const navigate = useNavigate()
  const { id } = useParams<'id'>()
  const { data, isLoading: organizationIsLoading } = useGetOrganizationsId(id)
  const { data: dataCompany } = useGetCompanies()
  const userIsSupervisor = useIsSupervisorOrAdmin()
  useEffect(() => {
    if (userIsSupervisor === false) {
      navigate('/404')
    }
  }, [userIsSupervisor, navigate])

  const successCallBack: (data: IOrganization) => void = (data: any) => {
    navigate(`/organization/detail/${id}`)
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Došlo k chybě',
      description: error + '! Please try again!',
      status: 'error',
      isClosable: true,
    })
  }
  const { mutate, isLoading } = usePutOrganizations(successCallBack, errorCallBack, id)
  const onSubmitForm: (formValues: OrganizationEditFormValues) => void = (
    formValues: OrganizationEditFormValues,
  ) => {
    delete formValues.logo
    if (typeof formValues.documentUrls !== 'string') {
      formValues.documentUrls = ''
    }
    mutate(formValues)
  }
  if (organizationIsLoading || !data || !dataCompany) {
    return <Loading />
  }
  return (
    <Box>
      <OrganizationForm
        initialData={
          transformPersonalNumbers(dataCompany.data, data.data) as OrganizationEditFormValues
        }
        onSubmitForm={onSubmitForm}
        onGoBack={() => navigate(`/organization/detail/${id}`)}
        isLoading={isLoading}
      />
    </Box>
  )
}
