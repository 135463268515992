import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Save } from '../icons'

export const ExtraInsuranceModal = ({
  children,
  isOpen = false,
  onClose,
}: {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <>
      <Modal size="6xl" onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nastavení parametrů pro všechny nabídky</ModalHeader>
          <ModalBody>
            <Box sx={{ display: 'grid', gap: 8, justifyContent: 'start' }}>{children}</Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="primary" onClick={onClose}>
              <Save />
              Uložit a přepočítat
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
