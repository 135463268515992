import { ICompanyV2 } from '../model/interfaces/entities/ICompany'

export interface ICompany {
  id: string | number
  name: string
  businessDiscounts?: any
  logo?: string,
}

export const companies: Array<ICompany> = [
  {
    id: 'allianz',
    name: 'Allianz',
    businessDiscounts: {
      chaloupka: {
        'Obchodní sleva': {
          min: '0%',
          max: '30%',
        },
      },
    },
  },
  {
    id: 'cpp',
    name: 'ČPP',
    businessDiscounts: {
      carkulka: {
        HAV: {
          min: '0%',
          max: '20%',
        },
        POV: {
          min: '0%',
          max: '20%',
        },
      },
      chaloupka: {
        'Obchodní sleva': {
          min: '0%',
          max: '20%',
        },
      },
    },
  },
  {
    id: 'csob',
    name: 'ČSOB pojišťovna',
    businessDiscounts: {
      carkulka: {
        Smlouva: {
          min: '0%',
          max: '20%',
        },
      },
      chaloupka: {
        'Obchodní sleva': {
          min: '0%',
          max: '20%',
        },
      },
    },
  },
  {
    id: 'direct',
    name: 'Direct pojišťovna',
    businessDiscounts: {
      carkulka: {
        POV: {
          min: '0%',
          max: '10%',
        },
      },
    },
  },
  {
    id: 'generali',
    name: 'Generali Česká pojišťovna',
    businessDiscounts: {
      carkulka: {
        HAV: {
          min: '0%',
          max: '40%',
        },
        POV: {
          min: '0%',
          max: '40%',
        },
      },
      chaloupka: {
        'Obchodní sleva': {
          min: '0%',
          max: '20%',
        },
      },
    },
  },
  {
    id: 'hvp',
    name: 'HVP',
    businessDiscounts: {
      carkulka: {
        Smlouva: {
          min: '0%',
          max: '20%',
        },
      },
    },
  },
  {
    id: 'kooperativa',
    name: 'Kooperativa',
    businessDiscounts: {
      carkulka: {
        HAV: {
          min: '0%',
          max: '25%',
        },
        POV: {
          min: '0%',
          max: '30%',
        },
      },
    },
  },
  {
    id: 'pillow',
    name: 'Pillow',
  },
  {
    id: 'vzp',
    name: 'VZP pojišťovna',
    businessDiscounts: {
      carkulka: {
        HAV: {
          min: '0%',
          max: '20%',
        },
        POV: {
          min: '0%',
          max: '20%',
        },
      },
    },
  },
  {
    id: 'slavia',
    name: 'Slavia pojišťovna',
    businessDiscounts: {
      carkulka: {
        Provize: {
          min: '1%',
          max: '50%',
        },
      },
    },
  },
  {
    id: 'uniqa',
    name: 'Uniqa pojišťovna',
    businessDiscounts: {
      carkulka: {
        HAV: {
          min: '0%',
          max: '20%',
        },
        POV: {
          min: '0%',
          max: '10%',
        },
      },
    },
  },
  {
    id: 'uniqa_axa',
    name: 'Uniqa AXA pojišťovna',
    businessDiscounts: {
      carkulka: {
        Provize: {
          min: '1%',
          max: '10%',
        },
      },
    },
  },
]

const carkulkaCompaniesList: Array<string> = [
  'allianz',
  'cpp',
  'csob',
  'direct',
  'generali',
  'hvp',
  'kooperativa',
  'pillow',
  'vzp',
  'slavia',
  'uniqa',
  'uniqa_axa',
]

const chaloupkaCompaniesList: Array<string> = [
  'allianz',
  'cpp',
  'csob',
  'direct',
  'generali',
  'kooperativa',
  'uniqa',
  'uniqa_axa',
]

const smeCompaniesList: Array<string> = ['cpp', 'csob', 'direct', 'slavia']

const filterCompaniesByApp = (list: Array<string>) => {
  // Vyfiltruje všechny companies, jejichž id je obsazženo v list
  return companies.filter((company) => {
    return list.includes(company.id.toString())
  })
}

export const getCompanyNameById = (id: number, companies: ICompanyV2[]) => {
  const company = companies.find((company) => company.id === id)

  return company?.name
}

export const carkulkaCompanies: Array<ICompany> = filterCompaniesByApp(carkulkaCompaniesList)
export const chaloupkaCompanies: Array<ICompany> = filterCompaniesByApp(chaloupkaCompaniesList)
export const smeCompanies: Array<ICompany> = filterCompaniesByApp(smeCompaniesList)
