import { Box } from '@chakra-ui/react'
import React from 'react'

interface CheckboxStackProps {
  children: React.ReactNode
  variant?: string
}

export const CheckboxStack = ({ children, variant = 'tile' }: CheckboxStackProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: {
          base: 'row',
          lg: 'column',
        },
        justifyContent: 'start',
        gap: variant === 'stackedTile' ? 0 : 2,
      }}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            variant,
          } as any)
        }
        return child
      })}
    </Box>
  )
}
