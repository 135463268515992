import axios from 'axios'
import { ApiRouteEnum } from '../enums/ApiRouteEnum'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.withCredentials = true

const handleNotFound = () => {
  /**
   * If we receive 404 on Offer Edit, it might mean that Offer has been already
   * finished and is Contract now.
   * For that reason we let caller decide how to handle redirect
   */
  if (window.location.href.includes('sme/wizard/edit')) {
    return
  }

  window.location.href = '/not-found'
}
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('auth')) {
      window.location.href = ApiRouteEnum.LOGIN
      return
    }

    if (error.response.status === 404) {
      handleNotFound()
    }

    return Promise.reject(error)
  },
)

const api = axios

export default api
