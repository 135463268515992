import { Box, FormControl, Heading, IconButton, Select, Text, useBoolean } from '@chakra-ui/react'
import React, { MouseEventHandler } from 'react'
import { Card, CardBody } from '../../../../components/Card'
import { FormLabel } from '../../../../components/FormLabel'
import { NotSelected, Selected, Settings, Subtract } from '../../../../icons'

const ExtraInsuranceTrigger = ({
  label,
  isSelected = false,
  hasContent = false,
  onSelectClick,
  onDeselectClick,
  onSettingsClick,
}: {
  label: string
  isSelected?: boolean
  hasContent?: boolean
  onSelectClick?: MouseEventHandler<HTMLButtonElement> | undefined
  onDeselectClick?: MouseEventHandler<HTMLButtonElement> | undefined
  onSettingsClick?: MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const iconSize = '20px'

  return (
    <Box
      sx={{
        display: 'flex',
        p: 2,
        justifyContent: 'space-between',
        border: '1px solid',
        borderColor: 'gray.200',
        borderRadius: 'md',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, placeItems: 'center' }}>
        {isSelected && (
          <IconButton colorScheme="white" aria-label="Odebrat" onClick={onDeselectClick}>
            <Selected color="green.500" width={iconSize} height={iconSize} />
          </IconButton>
        )}
        {!isSelected && (
          <IconButton colorScheme="white" aria-label="Vybrat" onClick={onSelectClick}>
            <NotSelected color="gray.500" width={iconSize} height={iconSize} />
          </IconButton>
        )}
        <Text>{label}</Text>
      </Box>

      <Box sx={{ display: 'flex', gap: 1, placeItems: 'center' }}>
        {isSelected && (
          <IconButton colorScheme="white" aria-label="Odebrat" onClick={onDeselectClick}>
            <Subtract color="red.500" width={iconSize} height={iconSize} />
          </IconButton>
        )}

        {hasContent && (
          <IconButton colorScheme="white" aria-label="Nastavit" onClick={onSettingsClick}>
            <Settings color="primary.500" width={iconSize} height={iconSize} />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

const ExtraInsuranceContent = ({
  children,
  heading,
}: {
  children: React.ReactNode
  heading: string
}) => {
  return (
    <Card
      sx={{
        position: { base: 'static', lg: 'absolute' },
        w: { base: 'min-content', lg: 'max-content' },
        maxW: 'calc(100vw - 4rem)',
        top: 'calc(100% + var(--ok-space-1))',
        left: '0',
        zIndex: { base: '0', lg: 'popover' },
        boxShadow: { base: 'card', lg: 'lg' },
        '& ul': {
          pl: 4,
        }
      }}
    >
      <CardBody sx={{ maxW: '100vw', p: { base: 4, lg: 8 } }}>
        <Heading sx={{ mb: 2, fontSize: 'lg' }} as="h3">
          {heading}
        </Heading>

        <Box sx={{ display: 'grid', gap: 4 }}>{children}</Box>
      </CardBody>
    </Card>
  )
}

const ExtraInsuranceSection = ({
  children,
  label,
  defaultSelected = false,
  contentHeading = '',
}: {
  children?: React.ReactNode | string
  label: string
  defaultSelected?: boolean
  contentHeading?: string
}) => {
  const [isOpen, setIsOpen] = useBoolean(false)
  const [isSelected, setIsSelected] = useBoolean(defaultSelected)

  return (
    <Box sx={{ display: 'grid', gap: 2, position: 'relative' }}>
      <ExtraInsuranceTrigger
        label={label}
        isSelected={isSelected}
        onSelectClick={setIsSelected.on}
        onDeselectClick={setIsSelected.off}
        onSettingsClick={setIsOpen.toggle}
        hasContent={!!React.Children.count(children)}
      />

      {isOpen && children && (
        <ExtraInsuranceContent heading={contentHeading}>{children}</ExtraInsuranceContent>
      )}
    </Box>
  )
}

export const ExtraInsuranceTab = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { base: '1fr', lg: 'repeat(4, 1fr)' },
        columnGap: 4,
        rowGap: 2,
      }}
    >
      <ExtraInsuranceSection label="Skla" contentHeading="Pojištění skel">
        <>
          <ul>
            <li>
              varianty pojištění:
              <ul>
                <li>pojištění čelního skla</li>
                <li>
                  pojištění všech výhledových skel vozidla (pro osobní a užitková vozidla do 3,5 t)
                </li>
                <li>za výhledové sklo se nepovažuje střešní sklo vozidla</li>
              </ul>
              <li>limit pojistného plnění: od 4 000 Kč do 500 000 Kč</li>
              <li>
                spoluúčast: 500 Kč při výměně (spoluúčast neuplatníme v případě opravy scelením)
              </li>
              <li>územní platnost: geografické území Evropy a celé území Turecka</li>
            </li>
          </ul>

          <FormControl>
            <FormLabel>Vyberte pojištění skel</FormLabel>

            <Select maxW={'calc(100vw - 6rem)'}>
              <option>
                Pojištění čelního skla vozidla s pojistnou částkou 5000 Kč - spoluúčast 500 Kč
                {/* Pojištění čelního skla vozidla */}
              </option>
            </Select>
          </FormControl>
        </>
      </ExtraInsuranceSection>

      <ExtraInsuranceSection label="Asistence" defaultSelected />
      <ExtraInsuranceSection label="Úraz osob" />
      <ExtraInsuranceSection label="Zavazadla" />
      <ExtraInsuranceSection label="Náhradní vozidlo" defaultSelected />
      <ExtraInsuranceSection label="Přírodní rizika" />
      <ExtraInsuranceSection label="Živel" />
    </Box>
  )
}
