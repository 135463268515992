import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { ICreateUserItem } from '../../interfaces/entities/IUser'

export const usePostUser = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
) => {
  return useMutation(
    (userData: Partial<ICreateUserItem>) => api.post(ApiRouteEnum.USER, userData),
    {
      mutationKey: [ApiRouteEnum.USER],
      onSuccess: (data) => {
        successCallBack(data.data)
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
