import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IStep1FormValues } from '../../../data/InsuranceForm'

export const usePostStep1 = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
) => {
  const queryClient = useQueryClient()

  return useMutation((form1Body: any) => api.post(ApiRouteEnum.OFFERS, form1Body), {
    mutationKey: [`${ApiRouteEnum.OFFERS}/step1`],
    onSuccess: (data) => {
      successCallBack(data.data)
      return queryClient.invalidateQueries({ queryKey: [ApiRouteEnum.ORGANIZATION, '/step1'] })
    },
    onError: (error) => errorCallBack(error),
  })
}
