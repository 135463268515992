import { Box, useStyleConfig } from '@chakra-ui/react'

interface ComponentProps {
  variant?: String
  children: React.ReactNode
}

export const Section = (props: ComponentProps) => {
  const { variant, ...rest } = props

  const styles = useStyleConfig('Section', { variant })

  return <Box __css={styles} {...rest} />
}
