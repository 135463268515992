import { IHelpDeskFormValues } from '../model/interfaces/entities/IHelpDeskFormValues'

export const helpDeskInitialValues: IHelpDeskFormValues = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  questionType: 1,
  message: '',
  file: {
    content: '',
    filename: '',
  },
}
