import { Icon } from '@chakra-ui/react'

export const TextBold = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 24 24" fill="none">
<path d="M6.935 4.44C7.07428 4.30055 7.23967 4.18992 7.42172 4.11442C7.60377 4.03892 7.79892 4.00004 7.996 4H12.379C15.017 4 17 6.182 17 8.625C16.9973 9.58716 16.695 10.5246 16.135 11.307C17.212 12.134 18.001 13.427 18.001 15.12C18 18.232 15.3 20 13.12 20H8C7.60218 20 7.22065 19.842 6.93934 19.5607C6.65804 19.2794 6.5 18.8978 6.5 18.5L6.496 5.5C6.496 5.103 6.654 4.721 6.936 4.44H6.935ZM9.5 10.25H12.38C13.283 10.25 14 9.49 14 8.625C14 7.76 13.281 7 12.38 7H9.498L9.5 10.25ZM9.5 13.25V17H13.12C13.994 17 15 16.246 15 15.12C15 13.99 14.026 13.25 13.12 13.25H9.5Z" fill="currentColor"/>
	</Icon>
)


export const TextBoldString=`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.935 4.44C7.07428 4.30055 7.23967 4.18992 7.42172 4.11442C7.60377 4.03892 7.79892 4.00004 7.996 4H12.379C15.017 4 17 6.182 17 8.625C16.9973 9.58716 16.695 10.5246 16.135 11.307C17.212 12.134 18.001 13.427 18.001 15.12C18 18.232 15.3 20 13.12 20H8C7.60218 20 7.22065 19.842 6.93934 19.5607C6.65804 19.2794 6.5 18.8978 6.5 18.5L6.496 5.5C6.496 5.103 6.654 4.721 6.936 4.44H6.935ZM9.5 10.25H12.38C13.283 10.25 14 9.49 14 8.625C14 7.76 13.281 7 12.38 7H9.498L9.5 10.25ZM9.5 13.25V17H13.12C13.994 17 15 16.246 15 15.12C15 13.99 14.026 13.25 13.12 13.25H9.5Z" fill="#003D66"/>
</svg>
`;