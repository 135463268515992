import { Box, Button, FormControl } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormGroup } from '../../components/FormGroup'
import { FormLabel } from '../../components/FormLabel'
import { FormSpacer } from '../../components/FormSpacer'
import { InsuranceCompanies } from '../../components/InsuranceCompanies'
import { Add } from '../../icons'
import { carkulkaCompanies } from '../../utils/companies'
import { WindowInsuranceSelect } from '../WindowInsuranceSelect'

import { YearlyMilageSelect } from '../YearlyMilageSelect'
import { InsuranceType } from './InsuranceType'

export interface NewInsuranceValues {
  windowInsurace: string
  yearlyMilage: string
}

export const NewInsuranceSettings = ({ onSubmit }: { onSubmit: Function }) => {
  const methods = useForm<NewInsuranceValues>({
    defaultValues: {},
  })
  const { register, handleSubmit } = methods

  const onSubmitForm = (values: NewInsuranceValues) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(values)
        resolve(true)
      }, 500)
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Box sx={{ display: 'grid', gap: 4, placeItems: 'start' }}>
          <InsuranceType />

          <FormSpacer />

          <FormGroup>
            <FormControl>
              <FormLabel htmlFor="windowInsurace" tooltip="Pojištění všech skel">
                Pojištění všech skel
              </FormLabel>

              <WindowInsuranceSelect id="windowInsurace" {...register('windowInsurace')} />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="yearlyMilage" tooltip="Roční nájezd">
                Roční nájezd
              </FormLabel>

              <YearlyMilageSelect id="yearlyMilage" {...register('yearlyMilage')} />
            </FormControl>
          </FormGroup>

          <FormSpacer />

          <InsuranceCompanies companies={carkulkaCompanies} />

          <Button type="submit" variant="outline">
            <Add color="secondary.500" />
            Přidat do kalkulace
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}
