import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import api from '../api'
import { IHelpDeskQuestionType } from '../../interfaces/entities/IHelpDeskQuestionType'

export const useGetHelpDeskQuestionTypes = () =>
  useQuery(
    [ApiRouteEnum.HELP_DESK_QUESTION_TYPES],
    () => api.get<IHelpDeskQuestionType[]>(ApiRouteEnum.HELP_DESK_QUESTION_TYPES),
    {
      retry: false,
    },
  )
