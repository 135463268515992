import { Icon } from '@chakra-ui/react'

export const DismissCircle = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2V2ZM10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C11.8565 17 13.637 16.2625 14.9497 14.9497C16.2625 13.637 17 11.8565 17 10C17 8.14348 16.2625 6.36301 14.9497 5.05025C13.637 3.7375 11.8565 3 10 3ZM7.81 7.114L7.879 7.172L10 9.292L12.121 7.172C12.2037 7.08917 12.3129 7.038 12.4295 7.0274C12.5461 7.0168 12.6627 7.04745 12.759 7.114L12.829 7.172C12.9114 7.25479 12.9623 7.36383 12.9727 7.4802C12.9831 7.59656 12.9524 7.71289 12.886 7.809L12.828 7.879L10.708 10L12.828 12.121C12.9108 12.2037 12.962 12.3129 12.9726 12.4295C12.9832 12.5461 12.9526 12.6627 12.886 12.759L12.828 12.829C12.7452 12.9114 12.6362 12.9623 12.5198 12.9727C12.4034 12.9831 12.2871 12.9524 12.191 12.886L12.121 12.828L10 10.708L7.879 12.828C7.79627 12.9108 7.6871 12.962 7.57051 12.9726C7.45392 12.9832 7.33731 12.9526 7.241 12.886L7.171 12.828C7.08858 12.7452 7.03775 12.6362 7.02734 12.5198C7.01692 12.4034 7.04759 12.2871 7.114 12.191L7.172 12.121L9.292 10L7.172 7.879C7.08917 7.79627 7.038 7.6871 7.0274 7.57051C7.0168 7.45392 7.04745 7.33731 7.114 7.241L7.172 7.171C7.25479 7.08858 7.36383 7.03775 7.4802 7.02734C7.59656 7.01692 7.71289 7.04759 7.809 7.114H7.81Z" fill="currentColor"/>
	</Icon>
)


export const DismissCircleString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2V2ZM10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C11.8565 17 13.637 16.2625 14.9497 14.9497C16.2625 13.637 17 11.8565 17 10C17 8.14348 16.2625 6.36301 14.9497 5.05025C13.637 3.7375 11.8565 3 10 3ZM7.81 7.114L7.879 7.172L10 9.292L12.121 7.172C12.2037 7.08917 12.3129 7.038 12.4295 7.0274C12.5461 7.0168 12.6627 7.04745 12.759 7.114L12.829 7.172C12.9114 7.25479 12.9623 7.36383 12.9727 7.4802C12.9831 7.59656 12.9524 7.71289 12.886 7.809L12.828 7.879L10.708 10L12.828 12.121C12.9108 12.2037 12.962 12.3129 12.9726 12.4295C12.9832 12.5461 12.9526 12.6627 12.886 12.759L12.828 12.829C12.7452 12.9114 12.6362 12.9623 12.5198 12.9727C12.4034 12.9831 12.2871 12.9524 12.191 12.886L12.121 12.828L10 10.708L7.879 12.828C7.79627 12.9108 7.6871 12.962 7.57051 12.9726C7.45392 12.9832 7.33731 12.9526 7.241 12.886L7.171 12.828C7.08858 12.7452 7.03775 12.6362 7.02734 12.5198C7.01692 12.4034 7.04759 12.2871 7.114 12.191L7.172 12.121L9.292 10L7.172 7.879C7.08917 7.79627 7.038 7.6871 7.0274 7.57051C7.0168 7.45392 7.04745 7.33731 7.114 7.241L7.172 7.171C7.25479 7.08858 7.36383 7.03775 7.4802 7.02734C7.59656 7.01692 7.71289 7.04759 7.809 7.114H7.81Z" fill="#003D66"/>
</svg>
`;