import React, { useState } from 'react'
import { Button, Flex, useToast } from '@chakra-ui/react'
import { Check } from '../icons'
import { ContractStatusEnum } from '../model/enums/ContractStatusEnum'
import { useReactivateContract } from '../model/api/contracts/useReactivateContract'

export const ReactivateContract: React.FC<{ id: string | number; status: number }> = ({
  id,
  status,
}) => {
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false)
  const toast = useToast()

  const onSuccess = () => {
    toast({
      title: 'Smlouva byla reaktivována',
      status: 'success',
      position: 'top',
    })
  }
  const onError = (error: any) => {
    toast({
      title: 'Smlouvu se nepodařilo reaktivovat',
      description: error.response?.data.error,
      status: 'error',
      position: 'top',
    })
  }

  const { mutate, isLoading } = useReactivateContract(onSuccess, onError, id)

  if (status !== ContractStatusEnum.CANCELED) {
    return null
  }

  if (confirmCancel) {
    return (
      <Flex sx={{ gap: 2 }}>
        <Button variant="solid" colorScheme="green" onClick={() => mutate()} disabled={isLoading}>
          <Check />
          {isLoading ? 'Reaktivuji...' : 'Opravdu reaktivovat?'}
        </Button>

        {!isLoading && (
          <Button variant="outline" onClick={() => setConfirmCancel(false)}>
            Nereaktivovat
          </Button>
        )}
      </Flex>
    )
  }

  return (
    <Button
      variant="outline"
      colorScheme="green"
      onClick={() => setConfirmCancel(true)}
      isDisabled={isLoading}
    >
      <Check />
      Reaktivovat smlouvu
    </Button>
  )
}
