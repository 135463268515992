import { Icon } from '@chakra-ui/react'

export const Stock = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 28 28" fill="none">
<path d="M12.3562 2.93183C13.4104 2.50441 14.5897 2.50441 15.6439 2.93183L24.3915 6.4785C24.7683 6.63112 25.091 6.89276 25.3182 7.22989C25.5453 7.56702 25.6667 7.9643 25.6667 8.37083V19.6327C25.6665 20.039 25.545 20.436 25.3178 20.7729C25.0907 21.1098 24.7682 21.3713 24.3915 21.5238L15.6439 25.0705C14.5897 25.4979 13.4104 25.4979 12.3562 25.0705L3.60854 21.5238C3.23175 21.3712 2.90909 21.1096 2.68191 20.7724C2.45473 20.4353 2.33337 20.038 2.33337 19.6315V8.372C2.33337 7.96547 2.45473 7.56819 2.68191 7.23106C2.90909 6.89393 3.23175 6.63229 3.60854 6.47967L12.3562 2.933V2.93183ZM14.9859 4.5535C14.3536 4.2973 13.6465 4.2973 13.0142 4.5535L10.78 5.46L19.5615 8.87483L22.6754 7.67317L14.9847 4.55467L14.9859 4.5535ZM17.1395 9.80933L8.41754 6.41667L5.35504 7.658L14.0012 11.0203L17.1395 9.80933ZM4.08337 19.6327C4.08353 19.6906 4.10092 19.7471 4.13333 19.7951C4.16574 19.8431 4.21171 19.8804 4.26537 19.9022L13.0142 23.4488C13.0504 23.464 13.0877 23.478 13.125 23.4908V12.558L4.08337 9.04167V19.6327ZM14.9859 23.4488L23.7347 19.9022C23.7886 19.8803 23.8347 19.8429 23.8671 19.7947C23.8995 19.7464 23.9168 19.6896 23.9167 19.6315V9.06967L14.875 12.5603V23.492C14.9123 23.4789 14.9493 23.4649 14.9859 23.45V23.4488Z" fill="currentColor"/>
	</Icon>
)


export const StockString=`<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3562 2.93183C13.4104 2.50441 14.5897 2.50441 15.6439 2.93183L24.3915 6.4785C24.7683 6.63112 25.091 6.89276 25.3182 7.22989C25.5453 7.56702 25.6667 7.9643 25.6667 8.37083V19.6327C25.6665 20.039 25.545 20.436 25.3178 20.7729C25.0907 21.1098 24.7682 21.3713 24.3915 21.5238L15.6439 25.0705C14.5897 25.4979 13.4104 25.4979 12.3562 25.0705L3.60854 21.5238C3.23175 21.3712 2.90909 21.1096 2.68191 20.7724C2.45473 20.4353 2.33337 20.038 2.33337 19.6315V8.372C2.33337 7.96547 2.45473 7.56819 2.68191 7.23106C2.90909 6.89393 3.23175 6.63229 3.60854 6.47967L12.3562 2.933V2.93183ZM14.9859 4.5535C14.3536 4.2973 13.6465 4.2973 13.0142 4.5535L10.78 5.46L19.5615 8.87483L22.6754 7.67317L14.9847 4.55467L14.9859 4.5535ZM17.1395 9.80933L8.41754 6.41667L5.35504 7.658L14.0012 11.0203L17.1395 9.80933ZM4.08337 19.6327C4.08353 19.6906 4.10092 19.7471 4.13333 19.7951C4.16574 19.8431 4.21171 19.8804 4.26537 19.9022L13.0142 23.4488C13.0504 23.464 13.0877 23.478 13.125 23.4908V12.558L4.08337 9.04167V19.6327ZM14.9859 23.4488L23.7347 19.9022C23.7886 19.8803 23.8347 19.8429 23.8671 19.7947C23.8995 19.7464 23.9168 19.6896 23.9167 19.6315V9.06967L14.875 12.5603V23.492C14.9123 23.4789 14.9493 23.4649 14.9859 23.45V23.4488Z" fill="#EE7400"/>
</svg>
`;