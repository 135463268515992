import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import api from '../api'

export const useRefreshOffer = (offerId?: string, itemId?: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    () =>
      api.post(`${ApiRouteEnum.OFFERS}/${offerId}/step3/${itemId}/recalculate`),
    {
      mutationKey: [`${ApiRouteEnum.OFFERS}/${itemId}/step3`],
      onSuccess: () => {
        queryClient.invalidateQueries([ApiRouteEnum.OFFERS])
      },
      onError: (error) => {
        console.log(error)
      },
    },
  )
}
