import { Button, ButtonProps } from "@chakra-ui/react"

import { Pdf } from '../icons'

interface DownloadButtonProps extends ButtonProps {
  isDownloadAll?: boolean
}

export const DownloadButton = ({ children, sx, isDownloadAll = false, ...rest }: DownloadButtonProps) => {
  return (
    <Button
      type="button"
      variant="ghost"
      sx={{
        px: 4,
        py: isDownloadAll ? 4 : 1,
        gap: 2,
        fontWeight: isDownloadAll ? 'medium' : 'normal',
        ...sx,
      }}
      {...rest}
    >
      <Pdf color="secondary.500" />
      {children}
    </Button>
  )
}
