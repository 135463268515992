import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IUserType } from '../../interfaces/entities/IUserType'

export const useGetUserType = () => {
  return useQuery([ApiRouteEnum.USER_TYPE], () => api.get<IUserType[]>(ApiRouteEnum.USER_TYPE), {
    retry: false,
  })
}
