import { FormSection, FormSectionBody, FormSectionDescription, FormSectionHeader, FormSectionTitle } from "../../components/FormSection"
import { NewInsuranceVariant } from "./NewInsuranceVariant"

export const NewInsuranceVariantWrapper = () => {
  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>Přidat další variantu pojištění</FormSectionTitle>

        <FormSectionDescription>
          Zadejte parametry pro vytvoření další varianty pojištění.
        </FormSectionDescription>
      </FormSectionHeader>

      <FormSectionBody>
        <NewInsuranceVariant />
      </FormSectionBody>
    </FormSection>
  )
}