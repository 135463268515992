import { Flex, Heading, useBoolean } from '@chakra-ui/react'

import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { ContractsList } from '../../containers/contracts'
import { PageContent } from '../../containers/PageContent'
import { PageToolbar } from '../../containers/PageToolbar'
import { ContractsCarSearch } from '../../containers/search/carkulka/ContractsCarSearch'
import { SearchButton } from '../../containers/search/SearchButton'
import { contractsCars } from '../../data/contracts'

export const ContractsCarPage = () => {
  const [isOpen, setOpen] = useBoolean(false)

  return (
    <Section>
      <Container>
        <PageContent>
          <PageToolbar
            leftElements={
              <Flex sx={{ gap: 4, alignItems: 'center' }}>
                <Heading as="h1">Smlouvy</Heading>
              </Flex>
            }
            rightElements={
              <Flex sx={{ gap: 4 }}>
                <SearchButton isOpen={isOpen} setOpen={setOpen} />
              </Flex>
            }
          />

          {isOpen && <ContractsCarSearch />}

          <ContractsList contracts={contractsCars} withPaginator />
        </PageContent>
      </Container>
    </Section>
  )
}
