import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const useReactivateContract = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id: string | number,
) => {
  const queryClient = useQueryClient()
  return useMutation(() => api.post(`${ApiRouteEnum.CONTRACT}/${id}/reactivate`), {
    mutationKey: [ApiRouteEnum.CONTRACT, id, 'reactivate'],
    onSuccess: (data) => {
      queryClient.invalidateQueries([ApiRouteEnum.CONTRACT])

      successCallBack(data)
    },
    onError: errorCallBack,
  })
}
