import {
  As,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  StylesProvider,
  Text,
  useMultiStyleConfig,
  useStyles,
  useToast,
} from '@chakra-ui/react'

import { variants } from '../../theme/components/NewsItem'
import { getNewsItemStatusColor, getNewsItemTypeColor, INewsItem } from '../../data/news'

import { Link } from 'react-router-dom'
import { INewsItemV2 } from '../../model/interfaces/entities/INews'
import { usePatchReadNews } from '../../model/api/news/usePatchReadNews'
import { UnreadDot } from './UnreadDot'
import { useQueryClient } from '@tanstack/react-query'
import { dateToCzechShortFormat } from '../../model/utils/dateUtils'

interface NewsItemProps extends INewsItemV2 {
  variant?: keyof typeof variants
  showReadButton?: boolean
}

export const getNewsItemTypeBadge = (type: INewsItem['type'] | undefined) => {
  let label
  const colorScheme = getNewsItemTypeColor(type)

  switch (type) {
    case 'info':
      label = 'Informace'
      break
    case 'announcement':
      label = 'Oznámení'
      break
    case 'warning':
      label = 'Upozornění'
      break
    case 'outage':
      label = 'Odstávka'
      break
    case 'danger':
      label = 'Odstávka'
      break
  }

  return <Badge colorScheme={colorScheme}>{label}</Badge>
}

const NewsItemMetadata = ({ children, ...rest }: { children: React.ReactNode }) => {
  const styles = useStyles()

  return (
    <Box sx={styles.metadata} {...rest}>
      {children}
    </Box>
  )
}

const NewsItemTagDateWrapper = ({ children, ...rest }: { children: React.ReactNode }) => {
  const styles = useStyles()

  return (
    <Box sx={{ ...styles.tagDateWrapper }} {...rest}>
      {children}
    </Box>
  )
}

const NewsItemDateDotWrapper = ({ children, ...rest }: { children: React.ReactNode }) => {
  return <Flex sx={{ alignItems: 'center', gap: 2 }}>{children}</Flex>
}

const NewsItemTitle = ({ children, as, ...rest }: { children: React.ReactNode; as: As }) => {
  const styles = useStyles()

  return (
    <Heading sx={styles.title} {...rest}>
      {children}
    </Heading>
  )
}

const NewsItemContent = ({ children, ...rest }: { children: string }) => {
  const styles = useStyles()

  return (
    <Text sx={styles.textContent} {...rest}>
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </Text>
  )
}

export const NewsItem = ({
  id,
  title,
  tagLabel,
  status,
  date,
  content,
  variant,
  showReadButton = false,
  unread,
  ...rest
}: NewsItemProps) => {
  const colorScheme = getNewsItemStatusColor(status)
  const styles = useMultiStyleConfig('NewsItem', { variant, colorScheme })
  const newsItemLink = `/news/${id}`
  const toastMessage = useToast()
  const queryClient = useQueryClient()
  const successCallBack: () => void = () => {
    // navigate('/news/admin')
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Error',
      description: error.response.data + '! Please try again!',
      status: 'error',
      isClosable: true,
    })
  }
  const { mutate } = usePatchReadNews(id, successCallBack, errorCallBack)
  const onUnReadNewsClick = () => {
    mutate()
  }

  return (
    <Box sx={{ ...styles.container }} {...rest}>
      <StylesProvider value={styles}>
        <NewsItemMetadata>
          <NewsItemTagDateWrapper>
            <NewsItemDateDotWrapper>
              {date && (
                <Text sx={{ color: 'gray.700', fontSize: 'sm' }} as="time">
                  {dateToCzechShortFormat(date)}
                </Text>
              )}
              {unread && <UnreadDot />}
            </NewsItemDateDotWrapper>
          </NewsItemTagDateWrapper>

          <NewsItemTitle as="h2">
            <Link to={newsItemLink}>{title}</Link>
          </NewsItemTitle>
        </NewsItemMetadata>

        {content && <NewsItemContent>{content}</NewsItemContent>}

        <Flex
          sx={{
            w: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'end',
          }}
        >
          {showReadButton && (
            <Button as={Link} to={newsItemLink} variant="outline" size="sm">
              Detail
            </Button>
          )}

          {unread && (
            <Button type="button" variant="ghost" size="sm" onClick={onUnReadNewsClick}>
              Označit jako přečtenou
            </Button>
          )}
        </Flex>
      </StylesProvider>
    </Box>
  )
}
