import { ICompany } from "../utils/companies"

type StartType = 'payment' | 'signature'

export const getContractStartType = (startType: StartType) => {
  switch (startType) {
    case 'payment':
      return 'Zaplacením'
    case 'signature':
      return 'Podpisem'
  }
}

export interface IContract {
  id: string
  startDate?: Date
  expiryDate?: Date
  insureeName?: string
  subject?: string
  company?: ICompany['id']
  startType: StartType
}

export const contractsCars: Array<IContract> = [
  {
    id: '2001565801',
    startDate: new Date(2022, 10, 1),
    expiryDate: new Date(2023, 9, 21),
    insureeName: 'Blahová Dagmar',
    subject: 'Rodinný dům, Rožnov pod Radh...',
    company: 'cpp',
    startType: 'payment',
  },
  {
    id: '2001565800',
    startDate: new Date(2022, 10, 1),
    expiryDate: new Date(2023, 9, 21),
    insureeName: 'Jan Novák',
    subject: 'MINI COOPER S 2020',
    company: 'direct',
    startType: 'signature',
  },
]

export const contractsProperties: Array<IContract> = [
  {
    id: '2001565801',
    startDate: new Date(2022, 10, 1),
    expiryDate: new Date(2023, 9, 21),
    insureeName: 'Blahová Dagmar',
    subject: 'Rodinný dům, Rožnov pod Radhoštěm',
    company: 'cpp',
    startType: 'payment',
  },
  {
    id: '2001565800',
    startDate: new Date(2022, 10, 1),
    expiryDate: new Date(2023, 9, 21),
    insureeName: 'Jan Novák',
    subject: 'Rodinný dům, Rožnov pod Radhoštěm',
    company: 'direct',
    startType: 'signature',
  },
]