import { Icon } from '@chakra-ui/react'

export const Company = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 18 18" fill="none">
<path d="M6.07496 5.85C6.25398 5.85 6.42567 5.77888 6.55226 5.6523C6.67884 5.52571 6.74996 5.35402 6.74996 5.175C6.74996 4.99598 6.67884 4.82429 6.55226 4.6977C6.42567 4.57112 6.25398 4.5 6.07496 4.5C5.89594 4.5 5.72425 4.57112 5.59766 4.6977C5.47107 4.82429 5.39996 4.99598 5.39996 5.175C5.39996 5.35402 5.47107 5.52571 5.59766 5.6523C5.72425 5.77888 5.89594 5.85 6.07496 5.85Z" fill="currentColor"/>
<path d="M6.74996 7.87498C6.74996 8.054 6.67884 8.22569 6.55226 8.35228C6.42567 8.47887 6.25398 8.54998 6.07496 8.54998C5.89594 8.54998 5.72425 8.47887 5.59766 8.35228C5.47107 8.22569 5.39996 8.054 5.39996 7.87498C5.39996 7.69596 5.47107 7.52427 5.59766 7.39768C5.72425 7.2711 5.89594 7.19998 6.07496 7.19998C6.25398 7.19998 6.42567 7.2711 6.55226 7.39768C6.67884 7.52427 6.74996 7.69596 6.74996 7.87498Z" fill="currentColor"/>
<path d="M6.07496 11.25C6.1636 11.25 6.25137 11.2325 6.33327 11.1986C6.41516 11.1647 6.48958 11.115 6.55226 11.0523C6.61493 10.9896 6.66465 10.9152 6.69858 10.8333C6.7325 10.7514 6.74996 10.6636 6.74996 10.575C6.74996 10.4864 6.7325 10.3986 6.69858 10.3167C6.66465 10.2348 6.61493 10.1604 6.55226 10.0977C6.48958 10.035 6.41516 9.9853 6.33327 9.95138C6.25137 9.91745 6.1636 9.89999 6.07496 9.89999C5.89594 9.89999 5.72425 9.97111 5.59766 10.0977C5.47107 10.2243 5.39996 10.396 5.39996 10.575C5.39996 10.754 5.47107 10.9257 5.59766 11.0523C5.72425 11.1789 5.89594 11.25 6.07496 11.25Z" fill="currentColor"/>
<path d="M9.44999 5.175C9.44999 5.35402 9.37887 5.52571 9.25228 5.6523C9.1257 5.77888 8.95401 5.85 8.77499 5.85C8.59597 5.85 8.42428 5.77888 8.29769 5.6523C8.1711 5.52571 8.09999 5.35402 8.09999 5.175C8.09999 4.99598 8.1711 4.82429 8.29769 4.6977C8.42428 4.57112 8.59597 4.5 8.77499 4.5C8.95401 4.5 9.1257 4.57112 9.25228 4.6977C9.37887 4.82429 9.44999 4.99598 9.44999 5.175Z" fill="currentColor"/>
<path d="M8.77499 8.54998C8.95401 8.54998 9.1257 8.47887 9.25228 8.35228C9.37887 8.22569 9.44999 8.054 9.44999 7.87498C9.44999 7.69596 9.37887 7.52427 9.25228 7.39768C9.1257 7.2711 8.95401 7.19998 8.77499 7.19998C8.59597 7.19998 8.42428 7.2711 8.29769 7.39768C8.1711 7.52427 8.09999 7.69596 8.09999 7.87498C8.09999 8.054 8.1711 8.22569 8.29769 8.35228C8.42428 8.47887 8.59597 8.54998 8.77499 8.54998Z" fill="currentColor"/>
<path d="M9.44999 10.575C9.44999 10.754 9.37887 10.9257 9.25228 11.0523C9.1257 11.1789 8.95401 11.25 8.77499 11.25C8.59597 11.25 8.42428 11.1789 8.29769 11.0523C8.1711 10.9257 8.09999 10.754 8.09999 10.575C8.09999 10.396 8.1711 10.2243 8.29769 10.0977C8.42428 9.97111 8.59597 9.89999 8.77499 9.89999C8.95401 9.89999 9.1257 9.97111 9.25228 10.0977C9.37887 10.2243 9.44999 10.396 9.44999 10.575Z" fill="currentColor"/>
<path d="M11.475 11.25C11.5637 11.25 11.6514 11.2325 11.7333 11.1986C11.8152 11.1647 11.8896 11.115 11.9523 11.0523C12.015 10.9896 12.0647 10.9152 12.0986 10.8333C12.1326 10.7514 12.15 10.6636 12.15 10.575C12.15 10.4864 12.1326 10.3986 12.0986 10.3167C12.0647 10.2348 12.015 10.1604 11.9523 10.0977C11.8896 10.035 11.8152 9.9853 11.7333 9.95138C11.6514 9.91745 11.5637 9.89999 11.475 9.89999C11.296 9.89999 11.1243 9.97111 10.9977 10.0977C10.8711 10.2243 10.8 10.396 10.8 10.575C10.8 10.754 10.8711 10.9257 10.9977 11.0523C11.1243 11.1789 11.296 11.25 11.475 11.25Z" fill="currentColor"/>
<path d="M4.04999 16.2C3.93064 16.2 3.81618 16.1526 3.73179 16.0682C3.6474 15.9838 3.59999 15.8693 3.59999 15.75V3.14999C3.59999 2.79195 3.74222 2.44857 3.99539 2.19539C4.24857 1.94222 4.59195 1.79999 4.94999 1.79999H10.35C10.708 1.79999 11.0514 1.94222 11.3046 2.19539C11.5578 2.44857 11.7 2.79195 11.7 3.14999V7.19999H13.05C13.408 7.19999 13.7514 7.34222 14.0046 7.59539C14.2578 7.84857 14.4 8.19195 14.4 8.54999V15.75C14.4 15.8693 14.3526 15.9838 14.2682 16.0682C14.1838 16.1526 14.0693 16.2 13.95 16.2H4.04999ZM4.49999 3.14999V15.3H6.29999V13.05C6.29999 12.9306 6.3474 12.8162 6.43179 12.7318C6.51618 12.6474 6.63064 12.6 6.74999 12.6H11.25C11.3693 12.6 11.4838 12.6474 11.5682 12.7318C11.6526 12.8162 11.7 12.9306 11.7 13.05V15.3H13.5V8.54999C13.5 8.43064 13.4526 8.31618 13.3682 8.23179C13.2838 8.1474 13.1693 8.09999 13.05 8.09999H11.25C11.1306 8.09999 11.0162 8.05258 10.9318 7.96819C10.8474 7.8838 10.8 7.76934 10.8 7.64999V3.14999C10.8 3.03064 10.7526 2.91618 10.6682 2.83179C10.5838 2.7474 10.4693 2.69999 10.35 2.69999H4.94999C4.83064 2.69999 4.71618 2.7474 4.63179 2.83179C4.5474 2.91618 4.49999 3.03064 4.49999 3.14999ZM10.8 13.5H9.44999V15.3H10.8V13.5ZM8.54999 13.5H7.19999V15.3H8.54999V13.5Z" fill="currentColor"/>
	</Icon>
)


export const CompanyString=`<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.07496 5.85C6.25398 5.85 6.42567 5.77888 6.55226 5.6523C6.67884 5.52571 6.74996 5.35402 6.74996 5.175C6.74996 4.99598 6.67884 4.82429 6.55226 4.6977C6.42567 4.57112 6.25398 4.5 6.07496 4.5C5.89594 4.5 5.72425 4.57112 5.59766 4.6977C5.47107 4.82429 5.39996 4.99598 5.39996 5.175C5.39996 5.35402 5.47107 5.52571 5.59766 5.6523C5.72425 5.77888 5.89594 5.85 6.07496 5.85Z" fill="#4E5D69"/>
<path d="M6.74996 7.87498C6.74996 8.054 6.67884 8.22569 6.55226 8.35228C6.42567 8.47887 6.25398 8.54998 6.07496 8.54998C5.89594 8.54998 5.72425 8.47887 5.59766 8.35228C5.47107 8.22569 5.39996 8.054 5.39996 7.87498C5.39996 7.69596 5.47107 7.52427 5.59766 7.39768C5.72425 7.2711 5.89594 7.19998 6.07496 7.19998C6.25398 7.19998 6.42567 7.2711 6.55226 7.39768C6.67884 7.52427 6.74996 7.69596 6.74996 7.87498Z" fill="#4E5D69"/>
<path d="M6.07496 11.25C6.1636 11.25 6.25137 11.2325 6.33327 11.1986C6.41516 11.1647 6.48958 11.115 6.55226 11.0523C6.61493 10.9896 6.66465 10.9152 6.69858 10.8333C6.7325 10.7514 6.74996 10.6636 6.74996 10.575C6.74996 10.4864 6.7325 10.3986 6.69858 10.3167C6.66465 10.2348 6.61493 10.1604 6.55226 10.0977C6.48958 10.035 6.41516 9.9853 6.33327 9.95138C6.25137 9.91745 6.1636 9.89999 6.07496 9.89999C5.89594 9.89999 5.72425 9.97111 5.59766 10.0977C5.47107 10.2243 5.39996 10.396 5.39996 10.575C5.39996 10.754 5.47107 10.9257 5.59766 11.0523C5.72425 11.1789 5.89594 11.25 6.07496 11.25Z" fill="#4E5D69"/>
<path d="M9.44999 5.175C9.44999 5.35402 9.37887 5.52571 9.25228 5.6523C9.1257 5.77888 8.95401 5.85 8.77499 5.85C8.59597 5.85 8.42428 5.77888 8.29769 5.6523C8.1711 5.52571 8.09999 5.35402 8.09999 5.175C8.09999 4.99598 8.1711 4.82429 8.29769 4.6977C8.42428 4.57112 8.59597 4.5 8.77499 4.5C8.95401 4.5 9.1257 4.57112 9.25228 4.6977C9.37887 4.82429 9.44999 4.99598 9.44999 5.175Z" fill="#4E5D69"/>
<path d="M8.77499 8.54998C8.95401 8.54998 9.1257 8.47887 9.25228 8.35228C9.37887 8.22569 9.44999 8.054 9.44999 7.87498C9.44999 7.69596 9.37887 7.52427 9.25228 7.39768C9.1257 7.2711 8.95401 7.19998 8.77499 7.19998C8.59597 7.19998 8.42428 7.2711 8.29769 7.39768C8.1711 7.52427 8.09999 7.69596 8.09999 7.87498C8.09999 8.054 8.1711 8.22569 8.29769 8.35228C8.42428 8.47887 8.59597 8.54998 8.77499 8.54998Z" fill="#4E5D69"/>
<path d="M9.44999 10.575C9.44999 10.754 9.37887 10.9257 9.25228 11.0523C9.1257 11.1789 8.95401 11.25 8.77499 11.25C8.59597 11.25 8.42428 11.1789 8.29769 11.0523C8.1711 10.9257 8.09999 10.754 8.09999 10.575C8.09999 10.396 8.1711 10.2243 8.29769 10.0977C8.42428 9.97111 8.59597 9.89999 8.77499 9.89999C8.95401 9.89999 9.1257 9.97111 9.25228 10.0977C9.37887 10.2243 9.44999 10.396 9.44999 10.575Z" fill="#4E5D69"/>
<path d="M11.475 11.25C11.5637 11.25 11.6514 11.2325 11.7333 11.1986C11.8152 11.1647 11.8896 11.115 11.9523 11.0523C12.015 10.9896 12.0647 10.9152 12.0986 10.8333C12.1326 10.7514 12.15 10.6636 12.15 10.575C12.15 10.4864 12.1326 10.3986 12.0986 10.3167C12.0647 10.2348 12.015 10.1604 11.9523 10.0977C11.8896 10.035 11.8152 9.9853 11.7333 9.95138C11.6514 9.91745 11.5637 9.89999 11.475 9.89999C11.296 9.89999 11.1243 9.97111 10.9977 10.0977C10.8711 10.2243 10.8 10.396 10.8 10.575C10.8 10.754 10.8711 10.9257 10.9977 11.0523C11.1243 11.1789 11.296 11.25 11.475 11.25Z" fill="#4E5D69"/>
<path d="M4.04999 16.2C3.93064 16.2 3.81618 16.1526 3.73179 16.0682C3.6474 15.9838 3.59999 15.8693 3.59999 15.75V3.14999C3.59999 2.79195 3.74222 2.44857 3.99539 2.19539C4.24857 1.94222 4.59195 1.79999 4.94999 1.79999H10.35C10.708 1.79999 11.0514 1.94222 11.3046 2.19539C11.5578 2.44857 11.7 2.79195 11.7 3.14999V7.19999H13.05C13.408 7.19999 13.7514 7.34222 14.0046 7.59539C14.2578 7.84857 14.4 8.19195 14.4 8.54999V15.75C14.4 15.8693 14.3526 15.9838 14.2682 16.0682C14.1838 16.1526 14.0693 16.2 13.95 16.2H4.04999ZM4.49999 3.14999V15.3H6.29999V13.05C6.29999 12.9306 6.3474 12.8162 6.43179 12.7318C6.51618 12.6474 6.63064 12.6 6.74999 12.6H11.25C11.3693 12.6 11.4838 12.6474 11.5682 12.7318C11.6526 12.8162 11.7 12.9306 11.7 13.05V15.3H13.5V8.54999C13.5 8.43064 13.4526 8.31618 13.3682 8.23179C13.2838 8.1474 13.1693 8.09999 13.05 8.09999H11.25C11.1306 8.09999 11.0162 8.05258 10.9318 7.96819C10.8474 7.8838 10.8 7.76934 10.8 7.64999V3.14999C10.8 3.03064 10.7526 2.91618 10.6682 2.83179C10.5838 2.7474 10.4693 2.69999 10.35 2.69999H4.94999C4.83064 2.69999 4.71618 2.7474 4.63179 2.83179C4.5474 2.91618 4.49999 3.03064 4.49999 3.14999ZM10.8 13.5H9.44999V15.3H10.8V13.5ZM8.54999 13.5H7.19999V15.3H8.54999V13.5Z" fill="#4E5D69"/>
</svg>
`;