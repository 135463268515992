import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'
import { PartsStyleFunction, mode } from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleFunction = (props) => {
  return {
    footer: {
      py: 8,
      pb: {
        base: 24,
        xl: 8,
      },
      background: mode('primary.800', 'gray.800')(props),
    },
    content: {
      display: 'grid',
      gap: {
        xs: 4,
        xl: 6,
      },
      gridTemplateColumns: {
        base: '1fr',
        xl: 'auto auto',
      },
      alignItems: 'center',
      justifyContent: { base: 'space-between' },
    },
  }
}

export const Footer: ComponentMultiStyleConfig = {
  parts: ['footer', 'content'],
  baseStyle,
}
