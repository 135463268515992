import { useState } from 'react'
import { Box, BoxProps, Button } from '@chakra-ui/react'
import { Add } from '../../icons'
import { NewInsuranceSettings, NewInsuranceValues } from './NewInsuranceSettings'

export const NewInsuranceVariant = () => {
  const [opened, setOpened] = useState(false)
  const closedLabel = 'Přidat další variantu pojištění'
  const openedLabel = 'Skrýt'
  const headerFooterSx: BoxProps = {
    display: 'grid',
    w: '100%',
    justifyItems: 'end',
  }

  return (
    <Box sx={{ w: '100%', display: 'grid', gap: 4 }}>
      <Box as="header" sx={headerFooterSx}>
        <Button variant="outline" onClick={() => setOpened(!opened)}>
          {!opened && <Add color="secondary.500" />}
          {opened ? openedLabel : closedLabel}
        </Button>
      </Box>

      {opened && <NewInsuranceSettings onSubmit={(values: NewInsuranceValues) => console.log(values)} />}
    </Box>
  )
}
