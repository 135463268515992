import {
  Badge,
  Box,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { Pagination } from '../../components/Pagination'
import { TableWrapper } from '../../components/TableWrapper'
import { Alert } from '../../icons'
import { getCompanyNameById } from '../../utils/companies'
import { getNewsItemTypeBadge } from './NewsItem'
import { useGetNews } from '../../model/api/news/useGetNews'
import {
  INewsItemV2,
  IQueryParamsNews,
  IQueryParamsNewsAdminList,
} from '../../model/interfaces/entities/INews'
import { useEffect, useState } from 'react'
import { calculateTotalPages } from '../../utils/calculateTotalPages'
import { dateToCzechShortFormat } from '../../model/utils/dateUtils'
import { useGetCompanies } from '../../model/api/company/useGetCompany'
import { useNavigate } from 'react-router-dom'

const NewsAdminTable = ({
  news,
  totalPages,
  currentPage,
  queryParams,
  setQueryParams,
}: {
  news: Array<INewsItemV2>
  totalPages: number
  currentPage: number
  queryParams?: IQueryParamsNews
  setQueryParams?: Function
}) => {
  const { data } = useGetCompanies()
  const navigate = useNavigate()
  const redirectToEdit = (id: number) => {
    navigate(`/news/edit/${id}`)
  }

  const [startPage, setStartPage] = useState<number>(1)
  const [endPage, setEndPage] = useState<number>(10)

  return (
    <TableWrapper>
      <Table>
        <Thead>
          <Tr>
            <Th>{/* Priority */}</Th>
            <Th>
              Datum
              <br />
              Nadpis
            </Th>
            <Th>Nastavení</Th>
          </Tr>
        </Thead>
        <Tbody>
          {news &&
            news.length &&
            news.map(({ id, priority, date, isActive, title, type, company }) => {
              return (
                <Tr
                  key={title + Math.random()}
                  style={{ cursor: 'pointer' }}
                  onClick={() => redirectToEdit(id)}
                >
                  <Td>{priority === 'high' && <Alert color="red.500" />}</Td>
                  <Td>
                    <Flex sx={{ gap: 1 }}>
                      {date && (
                        <Text sx={{ color: 'blue.600', fontSize: 'sm', fontWeight: 'medium' }}>
                          {dateToCzechShortFormat(date)}
                        </Text>
                      )}
                      {isActive && <Badge colorScheme="blue">Zobrazená</Badge>}
                      {!isActive && <Badge colorScheme="gray">Skrytá</Badge>}
                    </Flex>
                    <Text
                      sx={{
                        display: 'block',
                        maxW: '320px',
                        fontSize: 'sm',
                        fontWeight: 'medium',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: { base: 'wrap', lg: 'nowrap' },
                      }}
                    >
                      {title}
                    </Text>
                  </Td>
                  <Td>
                    <Flex sx={{ gap: 1 }}>
                      {type && getNewsItemTypeBadge(type)}
                      {company && (
                        <Badge colorScheme="blue">
                          {getCompanyNameById(company, data?.data || [])}
                        </Badge>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              )
            })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th colSpan={3}>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                startPage={startPage}
                endPage={endPage}
                onSetStartPage={setStartPage}
                onSetEndPage={setEndPage}
              />
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableWrapper>
  )
}

export const NewsAdminList = () => {
  const [queryParams, setQueryParams] = useState<IQueryParamsNewsAdminList>({
    limit: 10,
    page: 1,
  })
  const { data, error, isError, refetch } = useGetNews(queryParams)
  const toastMessage = useToast()

  useEffect(() => {
    refetch()
  }, [queryParams])

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Error',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  return (
    <>
      <Box>
        <Box>
          {data ? (
            <NewsAdminTable
              news={data?.data.items}
              totalPages={calculateTotalPages(data?.data.count, 10)}
              currentPage={queryParams.page}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
            />
          ) : (
            <Spinner />
          )}
        </Box>
      </Box>
    </>
  )
}
