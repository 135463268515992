export const countries = [
  { name: 'Afghánistán', code: 'AF' },
  { name: 'Ålandské ostrovy', code: 'AX' },
  { name: 'Albánie', code: 'AL' },
  { name: 'Alžírsko', code: 'DZ' },
  { name: 'Americká Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarktida', code: 'AQ' },
  { name: 'Antigua a Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Arménie', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Austrálie', code: 'AU' },
  { name: 'Ázerbájdžán', code: 'AZ' },
  { name: 'Bahamy', code: 'BS' },
  { name: 'Bahrajn', code: 'BH' },
  { name: 'Bangladéš', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belgie', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Bělorusko', code: 'BY' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermudy', code: 'BM' },
  { name: 'Bhútán', code: 'BT' },
  { name: 'Bolívarovská republika Venezuela', code: 'VE' },
  { name: 'Bonaire, Svatý Eustach a Saba', code: 'BQ' },
  { name: 'Bosna a Hercegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvetův ostrov', code: 'BV' },
  { name: 'Brazílie', code: 'BR' },
  { name: 'Britské indickooceánské území', code: 'IO' },
  { name: 'Brunej', code: 'BN' },
  { name: 'Bulharsko', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Čad', code: 'TD' },
  { name: 'Černá Hora', code: 'ME' },
  { name: 'Česká republika', code: 'CZ' },
  { name: 'Chile', code: 'CL' },
  { name: 'Chorvatsko', code: 'HR' },
  { name: 'Čína', code: 'CN' },
  { name: 'Cookovy ostrovy', code: 'CK' },
  { name: 'Curaçao', code: 'CW' },
  { name: 'Dánsko', code: 'DK' },
  { name: 'Dominika', code: 'DM' },
  { name: 'Dominikánská republika', code: 'DO' },
  { name: 'Džibutsko', code: 'DJ' },
  { name: 'Egypt', code: 'EG' },
  { name: 'Ekvádor', code: 'EC' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonsko', code: 'EE' },
  { name: 'Etiopie', code: 'ET' },
  { name: 'Faerské ostrovy', code: 'FO' },
  { name: 'Falkandské ostrovy (Malvíny)', code: 'FK' },
  { name: 'Fidži', code: 'FJ' },
  { name: 'Filipíny', code: 'PH' },
  { name: 'Finsko', code: 'FI' },
  { name: 'Francie', code: 'FR' },
  { name: 'Francouzská Guayana', code: 'GF' },
  { name: 'Francouzská Polynésie', code: 'PF' },
  { name: 'Francouzská jižní území', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambie', code: 'GM' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Grónsko', code: 'GL' },
  { name: 'Gruzie', code: 'GE' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heardův a McDonaldovy ostrovy', code: 'HM' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hongkong', code: 'HK' },
  { name: 'Indie', code: 'IN' },
  { name: 'Indonésie', code: 'ID' },
  { name: 'Irák', code: 'IQ' },
  { name: 'Írán, islámská republika', code: 'IR' },
  { name: 'Irsko', code: 'IE' },
  { name: 'Island', code: 'IS' },
  { name: 'Itálie', code: 'IT' },
  { name: 'Izrael', code: 'IL' },
  { name: 'Jamajka', code: 'JM' },
  { name: 'Japonsko', code: 'JP' },
  { name: 'Jemen', code: 'YE' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jihoafrická republika', code: 'ZA' },
  { name: 'Jižní Georgie a Jižní Sandwichovy ostrovy', code: 'GS' },
  { name: 'Jižní Súdán', code: 'SS' },
  { name: 'Jordánsko', code: 'JO' },
  { name: 'Kajmanské ostrovy', code: 'KY' },
  { name: 'Kambodža', code: 'KH' },
  { name: 'Kamerun', code: 'CM' },
  { name: 'Kanada', code: 'CA' },
  { name: 'Kapverdské ostrovy', code: 'CV' },
  { name: 'Katar', code: 'QA' },
  { name: 'Kazachstán', code: 'KZ' },
  { name: 'Keňa', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Kokosové ostrovy', code: 'CC' },
  { name: 'Kolumbie', code: 'CO' },
  { name: 'Komory', code: 'KM' },
  { name: 'Kongo', code: 'CG' },
  { name: 'Konžská demokratická republika', code: 'CD' },
  { name: 'Korea, lidově demokratická republika', code: 'KP' },
  { name: 'Korea, republika', code: 'KR' },
  { name: 'Kostarika', code: 'CR' },
  { name: 'Kuba', code: 'CU' },
  { name: 'Kuvajt', code: 'KW' },
  { name: 'Kypr', code: 'CY' },
  { name: 'Kyrgyzstán', code: 'KG' },
  { name: 'Laoská lidově demokratická republika', code: 'LA' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Libanon', code: 'LB' },
  { name: 'Libérie', code: 'LR' },
  { name: 'Libye', code: 'LY' },
  { name: 'Lichtenštejnsko', code: 'LI' },
  { name: 'Litva', code: 'LT' },
  { name: 'Lotyšsko', code: 'LV' },
  { name: 'Lucembursko', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Madagaskar', code: 'MG' },
  { name: 'Maďarsko', code: 'HU' },
  { name: 'Makedonie, republika', code: 'MK' },
  { name: 'Malajsie', code: 'MY' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Maledivy', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Maroko', code: 'MA' },
  { name: 'Marshallovy ostrovy', code: 'MH' },
  { name: 'Martinik', code: 'MQ' },
  { name: 'Mauricius', code: 'MU' },
  { name: 'Mauritánie', code: 'MR' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Menší odlehlé ostrovy Spojených států', code: 'UM' },
  { name: 'Mexiko', code: 'MX' },
  { name: 'Mikronésie, federativní státy', code: 'FM' },
  { name: 'Mnohonárodní stát Bolívie', code: 'BO' },
  { name: 'Moldavská republika', code: 'MD' },
  { name: 'Monako', code: 'MC' },
  { name: 'Mongolsko', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Mosambik', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibie', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Německo', code: 'DE' },
  { name: 'Nepál', code: 'NP' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigérie', code: 'NG' },
  { name: 'Nikaragua', code: 'NI' },
  { name: 'Niue', code: 'NU' },
  { name: 'Nizozemsko', code: 'NL' },
  { name: 'Norfolkský ostrov', code: 'NF' },
  { name: 'Norsko', code: 'NO' },
  { name: 'Nová Kaledonie', code: 'NC' },
  { name: 'Nový Zéland', code: 'NZ' },
  { name: 'Omán', code: 'OM' },
  { name: 'Ostrov Man', code: 'IM' },
  { name: 'Pákistán', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinský stát', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Panenské ostrovy, americké', code: 'VI' },
  { name: 'Panenské ostrovy, britské', code: 'VG' },
  { name: 'Papua Nová Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Pitcairnovy ostrovy', code: 'PN' },
  { name: 'Pobřeží slonoviny', code: 'CI' },
  { name: 'Polsko', code: 'PL' },
  { name: 'Portoriko', code: 'PR' },
  { name: 'Portugalsko', code: 'PT' },
  { name: 'Rakousko', code: 'AT' },
  { name: 'Řecko', code: 'GR' },
  { name: 'Réunion', code: 'RE' },
  { name: 'Rovníková Guinea', code: 'GQ' },
  { name: 'Rumunsko', code: 'RO' },
  { name: 'Ruská federace', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Šalamounovy ostrovy', code: 'SB' },
  { name: 'Salvador', code: 'SV' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Saúdská Arábie', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Severní Mariany', code: 'MP' },
  { name: 'Seychely', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapur', code: 'SG' },
  { name: 'Slovensko', code: 'SK' },
  { name: 'Slovinsko', code: 'SI' },
  { name: 'Somálsko', code: 'SO' },
  { name: 'Španělsko', code: 'ES' },
  { name: 'Spojené arabské emiráty', code: 'AE' },
  { name: 'Spojené království', code: 'GB' },
  { name: 'Spojené státy', code: 'US' },
  { name: 'Srbsko', code: 'RS' },
  { name: 'Šrí Lanka', code: 'LK' },
  { name: 'Středoafrická republika', code: 'CF' },
  { name: 'Súdán', code: 'SD' },
  { name: 'Surinam', code: 'SR' },
  { name: 'Svalbard a Jan Mayen', code: 'SJ' },
  { name: 'Svatá Helena, Ascension a Tristan da Cunha', code: 'SH' },
  { name: 'Svatá Lucie', code: 'LC' },
  { name: 'Svatý Bartoloměj', code: 'BL' },
  { name: 'Svatý Kryštof a Nevis', code: 'KN' },
  { name: 'Svatý Martin (francouzská část)', code: 'MF' },
  { name: 'Svatý Martin (nizozemská část)', code: 'SX' },
  { name: 'Svatý Pierre a Miquelon', code: 'PM' },
  { name: 'Svatý Tomáš a Princův ostrov', code: 'ST' },
  { name: 'Svatý Vincenc a Grenadiny', code: 'VC' },
  { name: 'Svatý stolec (Vatikánský městský stát)', code: 'VA' },
  { name: 'Svazijsko', code: 'SZ' },
  { name: 'Švédsko', code: 'SE' },
  { name: 'Švýcarsko', code: 'CH' },
  { name: 'Syrská arabská republika', code: 'SY' },
  { name: 'Tádžikistán', code: 'TJ' },
  { name: 'Tanzanie, sjednocená republika', code: 'TZ' },
  { name: 'Tchaj-wan', code: 'TW' },
  { name: 'Thajsko', code: 'TH' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad a Tobago', code: 'TT' },
  { name: 'Tunisko', code: 'TN' },
  { name: 'Turecko', code: 'TR' },
  { name: 'Turkmenistán', code: 'TM' },
  { name: 'Turks a Caicos', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukrajina', code: 'UA' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistán', code: 'UZ' },
  { name: 'Vánoční ostrov', code: 'CX' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Východní Timor', code: 'TL' },
  { name: 'Wallis a Futuna', code: 'WF' },
  { name: 'Zambie', code: 'ZM' },
  { name: 'Západní Sahara', code: 'EH' },
  { name: 'Zimbabwe', code: 'ZW' },
]
