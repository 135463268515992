import React from 'react'
import { IOrganization } from '../../model/interfaces/entities/IOrganization'
import { Badge, Flex, Td, Text, Tr } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { getOrganizationTypTranslated } from '../../model/utils/organizationUtils'
import { OrganizationTypeEnum } from '../../model/enums/OrganizationTypeEnum'

export const OrganizationChildrenRow: React.FC<{ organization: IOrganization }> = ({
  organization,
}) => {
  return (
    <Tr>
      <Td>
        <Text sx={{ color: 'primary.500', fontWeight: 'medium' }}>
          <Link to={`/organization/detail/${organization.id}`}>{organization.username}</Link>
        </Text>
      </Td>
      <Td>{organization.description || ''}</Td>
      <Td>
        <Text sx={{ color: 'gray.600', fontSize: 'sm' }}>
          {getOrganizationTypTranslated(organization.type as OrganizationTypeEnum) || '-'}
        </Text>
      </Td>
      <Td>
        <Flex sx={{ flexWrap: 'wrap', gap: 1, justifyContent: 'end' }}>
          {organization.isActive && <Badge colorScheme="green">Aktivní</Badge>}
          {!organization.isActive && <Badge colorScheme="orange">Neaktivní</Badge>}
          {organization.role && <Badge colorScheme="blue">{organization.role}</Badge>}
        </Flex>
      </Td>
    </Tr>
  )
}
