import {
  Box,
  Button,
  Flex,
  FormControl,
  IconButton,
  Input,
  InputGroup as ChakraInputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Switch,
  Tooltip,
} from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { FormGroup } from '../../components/FormGroup'
import { FormLabel } from '../../components/FormLabel'
import { FormSpacer } from '../../components/FormSpacer'
import { InputGroup } from '../../components/InputGroup'
import { MainContent } from '../../components/MainContent'
import { Calculate, Calendar, Check, External, QuestionMark } from '../../icons'
import { FormButtons } from '../FormButtons'
import { RadioStack } from '../../components/RadioStack'
import { InsuranceCompanies } from '../../components/InsuranceCompanies'
import { BCNOptions } from '../BCNOptions'
import { InsureeType } from '../InsureeType'
import { InsureeName } from '../InsureeName'
import { InsureeAddress } from '../InsureeAddress'
import { chaloupkaCompanies } from '../../utils/companies'
import { PropertyInsuranceTypeSelector } from '../PropertyInsuranceTypeSelector'
import { PropertyInsuranceTypeValues } from '../../utils/propertyInsuranceTypes'
import { RadioGroup } from '../../components/RadioGroup'
import { Radio } from '../../components/Radio'
import { RadioIcon } from '../../components/RadioIcon'
import { RadioIconGroup } from '../../components/RadioIconGroup'
import { HouseFloorSelector } from '../HouseFloorSelector'
import { SwitchControl } from '../../components/SwitchControl'
import { InsuranceType } from './InsuranceType'

interface Step1FormProps {
  onSubmit: Function
}

export interface Step1FormValues {
  projectName: string
  propertyType: PropertyInsuranceTypeValues['propertyType']
  insuranceType: PropertyInsuranceTypeValues['insuranceType']
  insuranceLevel: 'lower' | 'higher'
  participation: string
  startDate: string
  contractType: string
  paymentFrequency: string
  paymentMethod: string
  propertyPlaceType: 'house' | 'flat' | 'recreational-object'
  onlyLotNumber: boolean
  lotNumber: string
  houseStreet: string
  houseHouseNumber: string
  housePostalCode: string
  houseCity: string
  houseWithBasement: 'yes' | 'no' | null
  garageAtDifferentAddress: 'yes' | 'no' | null
  examined: 'yes' | 'no' | null
  examinationOrContructionYear: string
  houseTotalFloorsize: string
  floorSizeForBusiness: string
  builtUpArea: string
  floorsAboveGround: string
  roofType: string
  buildingType: string
  constructionType: string
  buildingState: string
  goodTechnicalConditions: 'yes' | 'no' | null
  propertyInUseBy: 'owner' | 'relative' | 'other' | null
  propertyValue: string
  adjacentBuildings: 'yes' | 'no' | null
  householdNumberOfFloors: string
  householdTotalFloorsize: string
  householdUnitNumber: string
  peopleInHousehold: string
  householdEquipmentType: string
  numberOfDamagaesInLast10Years: string
  householdEquipmentValue: string
  insureeType: string
  bcn: string
  dateOfBirth: string
  nationality: string
  firstName: string
  lastName: string
  postalCode: string
  country: string
  city: string
  insureeVehicleHolder: string
  insureeVehicleOwner: string
  propertyRelationship: string
}

export const Step1Form = ({ onSubmit }: Step1FormProps) => {
  const methods = useForm<Step1FormValues>({
    defaultValues: {
      propertyType: 'house',
      insuranceType: 'all',
      insuranceLevel: 'lower',
      paymentFrequency: '1',
      paymentMethod: '1',
      propertyPlaceType: 'house',
      floorsAboveGround: '1',
      roofType: '',
      buildingType: '2',
      constructionType: '1',
      buildingState: '1',
      goodTechnicalConditions: 'yes',
      propertyInUseBy: 'owner',
      adjacentBuildings: null,
      householdNumberOfFloors: '1',
      nationality: 'CZ',
      insureeType: '2',
      insureeVehicleHolder: '1',
      insureeVehicleOwner: '1',
      propertyRelationship: '1',
    },
  })
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    watch,
    setValue,
  } = methods

  const onSubmitForm = (values: Step1FormValues) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(values)
        resolve(true)
      }, 500)
    })
  }

  const onlyLotNumberValue = watch('onlyLotNumber')
  const floorsAboveGroundValue = watch('floorsAboveGround')
  const houseWithBasementValue = watch('houseWithBasement')
  const householdNumberOfFloorsValue = watch('householdNumberOfFloors')

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <MainContent>
          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Pojištěné věci</FormSectionTitle>

              <FormSectionDescription>Vyberte, které věci budou pojištěny.</FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody sx={{ w: '100%' }}>
              <PropertyInsuranceTypeSelector />
            </FormSectionBody>
          </FormSection>

          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Parametry pojištění</FormSectionTitle>

              <FormSectionDescription>Zadejte základní parametry pojištění.</FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              {/* Project name */}
              <FormControl isInvalid={!!errors['projectName']}>
                <FormLabel htmlFor="projectName">Název nabídky</FormLabel>
                <InputGroup
                  id="projectName"
                  placeholder="Zadejte název nabídky"
                  htmlSize={32}
                  errors={errors['projectName']}
                  {...register('projectName', {
                    // required: 'Zadejte název nabídky',
                  })}
                />
              </FormControl>

              <FormSpacer />

              <InsuranceType />

              <FormSpacer />

              <FormControl>
                <FormLabel>Rozsah pojištění</FormLabel>

                <RadioGroup control={control} name="insuranceLevel">
                  <RadioStack variant="stackedTile">
                    <Radio value="lower">Nižší</Radio>
                    <Radio value="higher">Vyšší</Radio>
                  </RadioStack>
                </RadioGroup>
              </FormControl>

              <FormSpacer />

              {/* Date */}
              <FormControl>
                <FormLabel htmlFor="startDate">Datum počátku pojištění</FormLabel>

                <ChakraInputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Calendar width={4} height={4} />}
                  />
                  <Input id="startDate" type="date" {...register('startDate')} />
                </ChakraInputGroup>
              </FormControl>

              <FormSpacer />

              <FormGroup size="lg">
                <FormControl>
                  <FormLabel htmlFor="paymentFrequency">Frekvence plateb</FormLabel>

                  <RadioGroup control={control} name="paymentFrequency">
                    <Stack direction="column" spacing={4}>
                      <Radio value="1">Roční</Radio>
                      <Radio value="2">Pololetní</Radio>
                      <Radio value="3">Čtvrtletní</Radio>
                      <Radio value="4">Měsíční</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="paymentMethod">Způsob platby</FormLabel>

                  <RadioGroup control={control} name="paymentMethod">
                    <Stack direction="column" spacing={4}>
                      <Radio value="1">Převodem z účtu</Radio>
                      <Radio value="2">Složenkou</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </FormGroup>

              <FormSpacer />

              <InsuranceCompanies companies={chaloupkaCompanies} />
            </FormSectionBody>
          </FormSection>

          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Místo pojištění</FormSectionTitle>

              <FormSectionDescription>Zadejte základní údaje o nemovitosti.</FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              <FormControl>
                <FormLabel>Typ nemovitosti</FormLabel>

                <RadioIconGroup control={control} name="propertyPlaceType">
                  <RadioStack variant="simple">
                    <RadioIcon icon="house" value="house">
                      Rodinný dům
                    </RadioIcon>
                    <RadioIcon icon="household" value="flat">
                      Byt
                    </RadioIcon>
                    <RadioIcon icon="hut" value="recreational-object">
                      Rekreační objekt
                    </RadioIcon>
                  </RadioStack>
                </RadioIconGroup>
              </FormControl>

              <FormSpacer />

              <SwitchControl>
                <Switch id="onlyLotNumber" {...register('onlyLotNumber')} />
                <FormLabel htmlFor="onlyLotNumber" variant="switch">
                  Mám pouze parcelní číslo
                </FormLabel>
              </SwitchControl>

              {!onlyLotNumberValue && (
                <>
                  <FormGroup>
                    <FormControl>
                      <FormLabel>Ulice</FormLabel>

                      <InputGroup
                        htmlSize={32}
                        placeholder="Např. Hlavní"
                        {...register('houseStreet')}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Číslo popisné nebo orientační</FormLabel>

                      <InputGroup
                        htmlSize={8}
                        placeholder="Např. 1234"
                        {...register('houseHouseNumber')}
                      />
                    </FormControl>
                  </FormGroup>

                  <FormGroup>
                    <FormControl>
                      <FormLabel tooltip="PSČ">PSČ</FormLabel>

                      <InputGroup
                        htmlSize={6}
                        placeholder="Např. 110 00"
                        {...register('housePostalCode')}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel tooltip="Obec">Obec</FormLabel>

                      <Select {...register('houseCity')}>
                        <option disabled value="">
                          Vyberte obec
                        </option>
                      </Select>
                    </FormControl>
                  </FormGroup>
                </>
              )}

              {onlyLotNumberValue && (
                <>
                  <FormGroup>
                    <FormControl>
                      <FormLabel>Parcelní číslo</FormLabel>

                      <InputGroup htmlSize={32} {...register('lotNumber')} />
                    </FormControl>
                  </FormGroup>
                </>
              )}

              <FormSpacer />

              <FormControl>
                <FormLabel>Je dům podsklepený</FormLabel>

                <RadioGroup control={control} name="houseWithBasement">
                  <RadioStack variant="stackedTile">
                    <Radio value="yes">Ano</Radio>
                    <Radio value="no">Ne</Radio>
                  </RadioStack>
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel>Garáž na jiné adrese</FormLabel>

                <RadioGroup control={control} name="garageAtDifferentAddress">
                  <RadioStack variant="stackedTile">
                    <Radio value="yes">Ano</Radio>
                    <Radio value="no">Ne</Radio>
                  </RadioStack>
                </RadioGroup>
              </FormControl>
            </FormSectionBody>
          </FormSection>

          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Nemovitost</FormSectionTitle>

              <FormSectionDescription>Trvale obývaná.</FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              <FormControl>
                <FormLabel tooltip="Zkoulaudováno">Zkoulaudováno</FormLabel>

                <RadioGroup control={control} name="examined">
                  <RadioStack variant="stackedTile">
                    <Radio value="yes">Ano</Radio>
                    <Radio value="no">Ne</Radio>
                  </RadioStack>
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel tooltip="Rok kolaudace či výstavby">Rok kolaudace či výstavby</FormLabel>

                <InputGroup htmlSize={1} {...register('examinationOrContructionYear')} />
              </FormControl>

              <FormSpacer />

              <FormGroup>
                <FormControl>
                  <FormLabel tooltip="Celková podlahová plocha">Celková podlahová plocha</FormLabel>

                  <ChakraInputGroup maxW={32}>
                    <Input placeholder="Např. 128" {...register('houseTotalFloorsize')} />
                    <InputRightElement children="㎡" />
                  </ChakraInputGroup>
                </FormControl>

                <FormControl>
                  <FormLabel tooltip="z toho určeno k podnikání">
                    z toho určeno k podnikání
                  </FormLabel>

                  <ChakraInputGroup maxW={32}>
                    <Input placeholder="Např. 20" {...register('floorSizeForBusiness')} />
                    <InputRightElement children="%" />
                  </ChakraInputGroup>
                </FormControl>
              </FormGroup>

              <FormGroup sx={{ alignItems: 'end' }}>
                <FormControl>
                  <FormLabel tooltip="Zastavěná plocha">Zastavěná plocha</FormLabel>

                  <ChakraInputGroup maxW={32}>
                    <Input placeholder="Např. 128" {...register('builtUpArea')} />
                    <InputRightElement children="㎡" />
                  </ChakraInputGroup>
                </FormControl>

                <Button
                  as="a"
                  href="https://intranet.okgroup.cz/okg-informace/13664"
                  target="_blank"
                  rel="noopener"
                  variant="outline"
                >
                  Vyhledat v katastru nemovitostí <External color="secondary.500" />
                </Button>
              </FormGroup>

              <FormSpacer />

              <Flex sx={{ columnGap: 32, rowGap: 8, placeItems: 'start', flexWrap: 'wrap' }}>
                <Box sx={{ display: 'grid', gap: 4 }}>
                  <FormControl>
                    <FormLabel tooltip="Počet nadzemních podlaží">
                      Počet nadzemních podlaží
                    </FormLabel>

                    <InputGroup placeholder="Např. 1" {...register('floorsAboveGround')} />
                  </FormControl>

                  <FormControl>
                    <FormLabel tooltip="Typ střechy">Typ střechy</FormLabel>

                    <Select {...register('roofType')}>
                      <option disabled value="">
                        Vyberte typ střechy
                      </option>
                      <option value="1">Sedlová</option>
                      <option value="2">Plochá</option>
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel tooltip="Provedení budovy">Provedení budovy</FormLabel>

                    <Select {...register('buildingType')}>
                      <option disabled value="">
                        Vyberte provedení budovy
                      </option>
                      <option value="1">Základní</option>
                      <option value="2">Standardní</option>
                      <option value="3">Luxusní</option>
                    </Select>
                  </FormControl>
                </Box>

                <Box>
                  <HouseFloorSelector
                    floors={parseInt(floorsAboveGroundValue) || 1}
                    basement={houseWithBasementValue === 'yes'}
                    onFloorsChange={(floors: number) =>
                      setValue('floorsAboveGround', String(floors))
                    }
                    onBasementChange={(isSelected: boolean) => {
                      setValue('houseWithBasement', isSelected ? 'yes' : 'no')
                    }}
                  />
                </Box>
              </Flex>

              <FormSpacer />

              <FormControl>
                <FormLabel tooltip="Typ konstrukce">Typ konstrukce</FormLabel>

                <Select {...register('constructionType')}>
                  <option disabled value="">
                    Vyberte typ konstrukce
                  </option>
                  <option value="1">Zděná</option>
                  <option value="2">Dřevěná</option>
                  <option value="3">Smíšená</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel tooltip="Stav nemovitosti">Stav nemovitosti</FormLabel>

                <Select {...register('buildingState')}>
                  <option disabled value="">
                    Vyberte stav nemovitosti
                  </option>
                  <option value="1">Dokončená</option>
                  <option value="2">V rekonstrukci</option>
                  <option value="3">Ve výstavbě</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel tooltip="Dobrý technický stav">Dobrý technický stav</FormLabel>

                <RadioGroup control={control} name="goodTechnicalConditions">
                  <RadioStack variant="stackedTile">
                    <Radio value="yes">Ano</Radio>
                    <Radio value="no">Ne</Radio>
                  </RadioStack>
                </RadioGroup>
              </FormControl>

              <FormSpacer />

              <FormControl>
                <FormLabel>Nemovitost užívá</FormLabel>

                <RadioGroup control={control} name="propertyInUseBy">
                  <RadioStack variant="stackedTile">
                    <Radio value="owner">Vlastník</Radio>
                    <Radio value="relative">Příbuzný</Radio>
                    <Radio value="other">Jiná osoba</Radio>
                  </RadioStack>
                </RadioGroup>
              </FormControl>

              <FormSpacer />

              <FormGroup sx={{ alignItems: 'end' }}>
                <FormControl>
                  <FormLabel tooltip="Hodnota nemovitosti">Hodnota nemovitosti</FormLabel>

                  <ChakraInputGroup maxW={48}>
                    <Input placeholder="Např. 10 000 000" {...register('propertyValue')} />
                    <InputRightElement children="Kč" />
                  </ChakraInputGroup>
                </FormControl>

                <Flex sx={{ gap: 4, alignItems: 'center' }}>
                  <Button variant="outline">
                    <Calculate color="secondary.500" />
                    Minimální částka
                  </Button>

                  <Tooltip shouldWrapChildren label="Nápověda k minimální částce">
                    <IconButton variant="ghost" aria-label="">
                      <QuestionMark />
                    </IconButton>
                  </Tooltip>
                </Flex>
              </FormGroup>

              <FormControl>
                <FormLabel tooltip="Vedlejší budovy">Vedlejší budovy</FormLabel>

                <RadioGroup control={control} name="adjacentBuildings">
                  <RadioStack variant="stackedTile">
                    <Radio value="yes">Ano</Radio>
                    <Radio value="no">Ne</Radio>
                  </RadioStack>
                </RadioGroup>
              </FormControl>
            </FormSectionBody>
          </FormSection>

          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Domácnost</FormSectionTitle>

              <FormSectionDescription>Trvale obývaná.</FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              <Flex sx={{ columnGap: 32, rowGap: 8, placeItems: 'start', flexWrap: 'wrap' }}>
                <Box sx={{ display: 'grid', gap: 4 }}>
                  <FormControl>
                    <FormLabel>Podlaží</FormLabel>

                    <InputGroup
                      htmlSize={8}
                      maxW={32}
                      placeholder="Např. 1"
                      {...register('householdNumberOfFloors')}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel tooltip="Podlahová plocha">Podlahová plocha</FormLabel>

                    <ChakraInputGroup maxW={32}>
                      <Input placeholder="Např. 128" {...register('householdTotalFloorsize')} />
                      <InputRightElement children="㎡" />
                    </ChakraInputGroup>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Číslo bytu</FormLabel>

                    <InputGroup
                      htmlSize={8}
                      maxW={32}
                      placeholder="Např. 1"
                      {...register('householdUnitNumber')}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel tooltip="Počet osob v domácnosti">Počet osob v domácnosti</FormLabel>

                    <InputGroup
                      htmlSize={3}
                      placeholder="Např. 5"
                      {...register('peopleInHousehold')}
                    />
                  </FormControl>
                </Box>

                <Box sx={{ display: 'grid', gap: 4 }}>
                  <FormLabel>Obývaná podlaží</FormLabel>

                  <HouseFloorSelector
                    floors={parseInt(householdNumberOfFloorsValue) || 1}
                    basement={false}
                    onFloorsChange={(floors: number) =>
                      setValue('householdNumberOfFloors', String(floors))
                    }
                    onBasementChange={(isSelected: boolean) => {
                      setValue(
                        'householdNumberOfFloors',
                        String(parseInt(householdNumberOfFloorsValue) + (isSelected ? 1 : 0)),
                      )
                    }}
                  />
                </Box>
              </Flex>

              <FormControl>
                <FormLabel tooltip="Vybavení">Vybavení</FormLabel>

                <Select {...register('householdEquipmentType')}>
                  <option disabled value="">
                    Vyberte vybavení
                  </option>
                  <option value="1">Základní</option>
                  <option value="2">Standardní</option>
                  <option value="3">Luxusní</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Počet škoda za posledních 10 let</FormLabel>

                <InputGroup
                  htmlSize={8}
                  maxW={32}
                  placeholder="Např. 0"
                  {...register('numberOfDamagaesInLast10Years')}
                />
              </FormControl>

              <FormGroup sx={{ alignItems: 'end' }}>
                <FormControl>
                  <FormLabel tooltip="Vybavení domácnosti">Vybavení domácnosti</FormLabel>

                  <ChakraInputGroup maxW={48}>
                    <Input
                      placeholder="Např. 10 000 000"
                      {...register('householdEquipmentValue')}
                    />
                    <InputRightElement children="Kč" />
                  </ChakraInputGroup>
                </FormControl>

                <Flex sx={{ gap: 4, alignItems: 'center' }}>
                  <Button variant="outline">
                    <Calculate color="secondary.500" />
                    Minimální částka
                  </Button>

                  <Tooltip shouldWrapChildren label="Nápověda k minimální částce">
                    <IconButton variant="ghost" aria-label="">
                      <QuestionMark />
                    </IconButton>
                  </Tooltip>
                </Flex>
              </FormGroup>
            </FormSectionBody>
          </FormSection>

          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Informace o pojistníkovi</FormSectionTitle>

              <FormSectionDescription>Zadejte informace o pojistníkovi.</FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              <InsureeType />

              <FormSpacer />

              <BCNOptions />

              <FormGroup>
                <InsureeName />
              </FormGroup>

              <FormSpacer />

              <InsureeAddress />

              <FormSpacer />

              <FormControl>
                <FormLabel tooltip="Vztah k předmětu pojištění">
                  Vztah k předmětu pojištění
                </FormLabel>

                <RadioGroup control={control} name="propertyRelationship">
                  <RadioStack variant="stackedTile">
                    <Radio value="1">Vlastník</Radio>
                    <Radio value="2">Spoluvlastník</Radio>
                    <Radio value="3">Nájemce</Radio>
                    <Radio value="4">Jiný</Radio>
                  </RadioStack>
                </RadioGroup>
              </FormControl>
            </FormSectionBody>
          </FormSection>

          <FormButtons
            rightButtons={
              <>
                <Button variant="outline" disabled={isSubmitting} type="button">
                  Uložit
                </Button>
                <Button
                  colorScheme="primary"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  loadingText="Ukládám..."
                  type="submit"
                >
                  <Check />
                  Uložit a pokračovat
                </Button>
              </>
            }
          />
        </MainContent>
      </form>
    </FormProvider>
  )
}
