import { isEmpty } from 'lodash'
import { FormSubmitEnum } from '../enums/FormSubmitEnum'
import * as React from 'react'
import { FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { IOffersItem } from '../interfaces/entities/IOffers'

export const validateAndScroll = (values: any, validateForm: any, toastMessage: any) => {
  validateForm(values).then((errors: object) => {
    if (!isEmpty(errors)) {
      toastMessage({
        title: 'Formulář obsahuje chyby',
        status: 'error',
        position: 'top',
      })

      return setTimeout(() => {
        const errorElements = document.getElementsByClassName('chakra-form__error-message')

        if (errorElements.length > 0) {
          const el = errorElements[0]
          const y = el.getBoundingClientRect().top + window.scrollY - 250
          return window.scrollTo({ top: y, behavior: 'smooth' })
        }

        window.scrollTo(0, 0)
      }, 250)
    }
  })
}

export const submitHandler = (
  e: FormEvent<HTMLFormElement>,
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
): void => {
  e.stopPropagation()
  e.preventDefault()

  // @ts-ignore
  const submitter: FormSubmitEnum | undefined = e.nativeEvent?.submitter?.name

  setFieldValue(FormSubmitEnum.SUBMITTER_FIELD, submitter)
  handleSubmit(e)
}

export const useFormStepGuard = (currentStep: number, offer?: IOffersItem): void => {
  const navigate = useNavigate()

  if (typeof offer === 'undefined') {
    return
  }

  const { id, step: offerStep } = offer

  if (typeof offerStep === 'undefined') {
    return
  }

  if (offerStep < currentStep - 1) {
    navigate(`/sme/wizard/too-far/${id}`)
  }
}
