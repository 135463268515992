import { Button, Flex } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { InputGroup } from '../components/InputGroup'
import { Search } from '../icons'
import { IQueryParams } from '../model/interfaces/entities/IQueryParams'

interface SearchFieldFormValues {
  query?: string | null
  search?: string
}

export const SearchField = ({
  inputPlaceholder = 'Hledat',
  buttonLabel = 'Hledat',
  setQueryParams,
  queryParams,
}: {
  inputPlaceholder?: string
  buttonLabel?: string
  setQueryParams: Function
  queryParams: IQueryParams | undefined | {search?: string, unreadOnly?: number}
}) => {
  const onSubmit = (values: SearchFieldFormValues) => {
    setQueryParams({ ...queryParams, page: 1, search: values.search })
  }

  const initialValues = { search: queryParams?.search || '' }

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => onSubmit(values)}>
      {({ handleChange, values }) => (
        <Form>
          <Flex sx={{ gap: 2 }}>
            <InputGroup
              id="search"
              name="search"
              placeholder={inputPlaceholder}
              onChange={handleChange}
              value={values.search}
            />

            <Button type="submit" variant="outline">
              <Search color="secondary.500" />
              {buttonLabel}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
