import React, { useContext } from 'react'
import { FormControl as ChakraFormControl } from '@chakra-ui/react'
import * as _chakra_ui_system from '@chakra-ui/system'
import { FormControlProps } from '@chakra-ui/form-control'
import { FormIsDisabledContext } from '../model/contexts/FormIsDisabledContext'

export const FormControl: _chakra_ui_system.ComponentWithAs<'div', FormControlProps> = (props) => {
  const formIsDisabled = useContext(FormIsDisabledContext)
  return <ChakraFormControl isDisabled={formIsDisabled} {...props} />
}
