import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePatchStep1 = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id: string | undefined,
) => {
  const queryClient = useQueryClient()

  return useMutation((form1Body: any) => api.patch(ApiRouteEnum.OFFERS + `/${id}`, form1Body), {
    mutationKey: [`${ApiRouteEnum.OFFERS}/step1`],
    onSuccess: (data) => {
      successCallBack(data.data)
      return queryClient.invalidateQueries({ queryKey: [ApiRouteEnum.ORGANIZATION, '/step1'] })
    },
    onError: (error) => errorCallBack(error),
  })
}
