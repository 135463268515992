import { IAvailableApps } from '../../utils/availableApps'
import { useIsProduction } from './useIsProduction'
interface availableAppsProps {
  id: IAvailableApps['appId']
  name: string
  link: string
}

export const useAvailableApps = (): Array<availableAppsProps> => {
  const isProduction = useIsProduction()
  return [
    {
      id: 'carkulka',
      name: 'Carkulka',
      link: isProduction ? 'https://carkulka.okholding.cz/' : 'https://carkulka.okholding.cz/test',
    },
    {
      id: 'chaloupka',
      name: 'Chaloupka',
      link: isProduction ? 'https://chaloupka.okholding.cz/' : 'https://chaloupka.okholding.cz/test',
    },
    {
      id: 'sme',
      name: 'pojištění podnikání',
      link: '/',
    },
    {
      id: 'cestovko',
      name: 'Cestovka',
      link: isProduction ? 'https://cestovka.okholding.cz/' : 'https://cestovka.okholding.cz/test',
    },
  ]
}
