import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'
import { PartsStyleFunction, mode } from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleFunction = (props) => {
  return {
    card: {
      display: 'grid',
      alignItems: 'start',
      bg: mode('white', 'gray.800')(props),
    },
    title: {
      display: 'flex',
      gap: 4,
      alignItems: 'center',
      justifyItems: 'start',
      py: {
        base: 2,
        xl: 4,
      },
      px: 4,
      bg: 'primary.700',
      borderTopRadius: 'md',
      color: 'white',
      fontWeight: 'medium',
    },
    body: {
      p: {
        base: 4,
        lg: 8,
      },
      gap: {
        base: 2,
        lg: 4,
      },
    },
  }
}

export const Card: ComponentMultiStyleConfig = {
  parts: ['card', 'title', 'body'],
  baseStyle,
  variants: {
    default: {
      card: {
        borderRadius: 'md',
        boxShadow: 'card',
      },
      title: {},
      body: {},
    },
  },
  defaultProps: {
    variant: 'default',
  },
}
