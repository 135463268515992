import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import api from '../api'
import { IOfferField } from '../../interfaces/entities/IOfferField'

export const useGetOfferFields = () => {
  return useQuery([ApiRouteEnum.OFFER_FIELDS], () =>
    api.get<IOfferField[]>(ApiRouteEnum.OFFER_FIELDS),
  )
}
