import { Icon } from '@chakra-ui/react'

export const Elements = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M36 28C36 21.2 28.5 10.5 24 6.5C20 10.5 12.5357 20.5 12 28C11.6667 32.6667 13.6 42 24 42C34.4 42 36.3333 32.6667 36 28Z" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
)


export const ElementsString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36 28C36 21.2 28.5 10.5 24 6.5C20 10.5 12.5357 20.5 12 28C11.6667 32.6667 13.6 42 24 42C34.4 42 36.3333 32.6667 36 28Z" fill="none" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;