import { Box, Button, FormControl } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormLabel } from '../../components/FormLabel'
import { FormSpacer } from '../../components/FormSpacer'
import { InsuranceCompanies } from '../../components/InsuranceCompanies'
import { Radio } from '../../components/Radio'
import { RadioGroup } from '../../components/RadioGroup'
import { RadioStack } from '../../components/RadioStack'
import { Add } from '../../icons'
import { chaloupkaCompanies } from '../../utils/companies'
import { InsuranceType } from './InsuranceType'

export interface NewInsuranceValues {
  windowInsurace: string
  yearlyMilage: string
}

export const NewInsuranceSettings = ({ onSubmit }: { onSubmit: Function }) => {
  const methods = useForm<NewInsuranceValues>({
    defaultValues: {},
  })
  const { control, handleSubmit } = methods

  const onSubmitForm = (values: NewInsuranceValues) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(values)
        resolve(true)
      }, 500)
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Box sx={{ display: 'grid', gap: 4, placeItems: 'start' }}>
          <InsuranceType />

          <FormSpacer />

          <FormControl>
            <FormLabel>Rozsah pojištění</FormLabel>

            <RadioGroup control={control} name="insuranceLevel">
              <RadioStack variant="stackedTile">
                <Radio value="lower">Nižší</Radio>
                <Radio value="higher">Vyšší</Radio>
              </RadioStack>
            </RadioGroup>
          </FormControl>

          <FormSpacer />

          <InsuranceCompanies companies={chaloupkaCompanies} />

          <Button type="submit" variant="outline">
            <Add color="secondary.500" />
            Přidat do kalkulace
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}
