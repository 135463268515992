import { Box } from '@chakra-ui/react'

export const UnreadDot = () => {
  const dotSize = '6px'

  return (
    <Box
      title="Nepřečteno"
      sx={{
        display: 'inline-block',
        borderRadius: dotSize,
        width: dotSize,
        height: dotSize,
        backgroundColor: 'primary.700',
      }}
    />
  )
}
