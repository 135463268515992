import { Box } from '@chakra-ui/react'

const StepsProgressStep = ({
  inProgress = false,
  done = false,
}: {
  inProgress?: boolean
  done?: boolean
}) => {
  let bgColor = 'gray.400'

  if (inProgress) bgColor = 'secondary.500'
  if (done) bgColor = 'primary.500'

  return <Box sx={{ width: 4, height: 2, bg: bgColor }} />
}

export type Progress = 1 | 2 | 3 | 4 | 5

export const StepsProgress = ({ progress = 1 }: { progress: Progress }) => {
  const steps = [1, 2, 3, 4, 5]

  return (
    <Box
      sx={{
        display: 'inline-grid',
        gridAutoFlow: 'column',
        placeContent: 'start',
        placeItems: 'start',
        gap: 'px',
        borderRadius: 'md',
        overflow: 'hidden',
      }}
    >
      {steps.map((step) => {
        const inProgress = progress >= step
        const done = progress === 5

        return <StepsProgressStep key={step} inProgress={inProgress} done={done} />
      })}
    </Box>
  )
}
