import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePatchReadNews = (
  id: number,
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
) => {
  const queryClient = useQueryClient()
  const readNewsLink: string = ApiRouteEnum.NEWS + `/${id}/read`

  return useMutation(
    () =>
      api.patch(readNewsLink, {
        read: true,
      }),
    {
      mutationKey: [readNewsLink],
      onSuccess: (data) => {
        successCallBack(data.data)
        return queryClient.invalidateQueries([ApiRouteEnum.NEWS])
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
