import { Icon } from '@chakra-ui/react'

export const Windows = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M13 13H35L46 30.8889C43.25 32.5926 35 36 24 36C13 36 4.75 32.5926 2 30.8889L13 13Z" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M24 18.1111L18.5 23.2222M32.25 18.1111L18.5 30.8889" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
)


export const WindowsString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 13H35L46 30.8889C43.25 32.5926 35 36 24 36C13 36 4.75 32.5926 2 30.8889L13 13Z" fill="none" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M24 18.1111L18.5 23.2222M32.25 18.1111L18.5 30.8889" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;