import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IFAQ } from '../../interfaces/entities/IFAQ'

export const usePostFAQ = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (faqData: IFAQ) => {
      return api.post(ApiRouteEnum.FAQ, faqData)
    },
    {
      onSuccess: (data) => {
        successCallBack(data.data)
        return queryClient.invalidateQueries([ApiRouteEnum.FAQ])
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
