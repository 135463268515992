import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IApplicant } from '../../../data/InsuranceForm'

export const usePatchStep4 = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id?: string,
) => {
  return useMutation(
    (form4Body: IApplicant) => {
      const { type, ino, companyName, vatPayer, ...rest } = form4Body
      return api.patch(`${ApiRouteEnum.OFFERS}/${id}/step4`, rest)
    },
    {
      mutationKey: [`patch-${ApiRouteEnum.OFFERS}/step4`],
      onSuccess: (data) => {
        successCallBack(data.data)
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
