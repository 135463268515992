const tokens = {
  xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
  sm: '0 2px 0.125rem 0 rgba(0, 0, 0, 0.05)',
  base: '0 1px 0.25rem 0 rgba(0, 0, 0, 0.1), 0 1px 0.06125rem 0 rgba(0, 0, 0, 0.2)', // , 0 1px 0.125rem 0 rgba(0, 0, 0, 0.06)
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.15), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 1rem 1rem -3px rgba(0, 0, 0, 0.15), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.15), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  '3xl':
    '0 0 1rem .25rem #003d661a, 0 .25rem 80px -.5rem #4a556840, 0 .25rem .25rem -.125rem #71809626',
  outline: '0 0 0 2px rgba(66, 153, 225, 0.6)',
  inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
  none: 'none',
  'dark-lg':
    'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px',
}

export const shadows = {
  ...tokens,
  card: tokens.base,
  checkbox: tokens.sm,
  header: '0 1px 0.5rem rgba(0, 0, 0, 0.1)',
  input: tokens.sm,
  helpdesk: tokens.lg,
  modal: tokens.lg,
  toast: tokens.lg,
  tooltip: tokens['3xl'],
}
