import { Icon } from '@chakra-ui/react'

export const Email = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M15.5 4C16.163 4 16.7989 4.26339 17.2678 4.73223C17.7366 5.20107 18 5.83696 18 6.5V14.5C18 15.163 17.7366 15.7989 17.2678 16.2678C16.7989 16.7366 16.163 17 15.5 17H4.5C3.83696 17 3.20107 16.7366 2.73223 16.2678C2.26339 15.7989 2 15.163 2 14.5V6.5C2 5.83696 2.26339 5.20107 2.73223 4.73223C3.20107 4.26339 3.83696 4 4.5 4H15.5ZM17 7.961L10.254 11.931C10.1902 11.9684 10.1189 11.9913 10.0453 11.9978C9.9716 12.0044 9.8974 11.9946 9.828 11.969L9.746 11.931L3 7.963V14.5C3 14.8978 3.15804 15.2794 3.43934 15.5607C3.72064 15.842 4.10218 16 4.5 16H15.5C15.8978 16 16.2794 15.842 16.5607 15.5607C16.842 15.2794 17 14.8978 17 14.5V7.961ZM15.5 5H4.5C4.10218 5 3.72064 5.15804 3.43934 5.43934C3.15804 5.72064 3 6.10218 3 6.5V6.802L10 10.92L17 6.8V6.5C17 6.10218 16.842 5.72064 16.5607 5.43934C16.2794 5.15804 15.8978 5 15.5 5V5Z" fill="currentColor"/>
	</Icon>
)


export const EmailString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 4C16.163 4 16.7989 4.26339 17.2678 4.73223C17.7366 5.20107 18 5.83696 18 6.5V14.5C18 15.163 17.7366 15.7989 17.2678 16.2678C16.7989 16.7366 16.163 17 15.5 17H4.5C3.83696 17 3.20107 16.7366 2.73223 16.2678C2.26339 15.7989 2 15.163 2 14.5V6.5C2 5.83696 2.26339 5.20107 2.73223 4.73223C3.20107 4.26339 3.83696 4 4.5 4H15.5ZM17 7.961L10.254 11.931C10.1902 11.9684 10.1189 11.9913 10.0453 11.9978C9.9716 12.0044 9.8974 11.9946 9.828 11.969L9.746 11.931L3 7.963V14.5C3 14.8978 3.15804 15.2794 3.43934 15.5607C3.72064 15.842 4.10218 16 4.5 16H15.5C15.8978 16 16.2794 15.842 16.5607 15.5607C16.842 15.2794 17 14.8978 17 14.5V7.961ZM15.5 5H4.5C4.10218 5 3.72064 5.15804 3.43934 5.43934C3.15804 5.72064 3 6.10218 3 6.5V6.802L10 10.92L17 6.8V6.5C17 6.10218 16.842 5.72064 16.5607 5.43934C16.2794 5.15804 15.8978 5 15.5 5V5Z" fill="#003D66"/>
</svg>
`;