import { IAddressSuggestion } from '../interfaces/entities/IAddressSuggestion'

/**
 * Some small villages don't have 'streets', so 'city' name is used instead
 */
export const transformAddressToStreet = (address: IAddressSuggestion): string => {
  return address.street || address.city || ''
}

export const transformAddressToStreetNumber = (address: IAddressSuggestion): string => {
  const parts: Array<string | number | undefined> = [address.descriptiveNumber]

  if (address.orientationNumber && address.orientationNumber !== '0') {
    parts.push(`${address.orientationNumber}${address.orientationNumberSymbol}`)
  }

  return parts.join('/')
}

export const createAddressFromOptionalBits = (
  city?: string,
  zip?: string,
  street?: string,
  streetNumber?: string,
): string => {
  if (!city || !zip || !street || !streetNumber) {
    return ''
  }

  return `${street} ${streetNumber}, ${zip} ${city}`
}
