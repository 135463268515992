import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePostOfferGeneratePDF = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id?: string,
) => {
  return useMutation(
    () =>
      api.post(
        `${ApiRouteEnum.OFFERS}/${id}/generate-pdf`,
        {},
        {
          responseType: 'arraybuffer',
        },
      ),
    {
      mutationKey: [`${ApiRouteEnum.OFFERS}/${id}/send-email`],
      onSuccess: (data) => {
        successCallBack(data)
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
