import { Icon } from '@chakra-ui/react'

export const Injury = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M16.9824 17.5452H17.7324V16.7952V9.55836C17.7324 7.32161 19.5456 5.50836 21.7824 5.50836H26.5919C28.8287 5.50836 30.6419 7.32161 30.6419 9.55836V16.7952V17.5452H31.3919H38.4C40.6367 17.5452 42.45 19.3585 42.45 21.5952V26.4048C42.45 28.6416 40.6367 30.4548 38.4 30.4548H31.3919H30.6419V31.2048V38.3584C30.6419 40.5951 28.8287 42.4084 26.5919 42.4084H21.7824C19.5456 42.4084 17.7324 40.5951 17.7324 38.3584V31.2048V30.4548H16.9824H9.59999C7.36323 30.4548 5.54999 28.6415 5.54999 26.4048V21.5952C5.54999 19.3585 7.36323 17.5452 9.59999 17.5452H16.9824Z" fill="none" stroke="currentColor" strokeWidth="1.5"/>
	</Icon>
)


export const InjuryString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.9824 17.5452H17.7324V16.7952V9.55836C17.7324 7.32161 19.5456 5.50836 21.7824 5.50836H26.5919C28.8287 5.50836 30.6419 7.32161 30.6419 9.55836V16.7952V17.5452H31.3919H38.4C40.6367 17.5452 42.45 19.3585 42.45 21.5952V26.4048C42.45 28.6416 40.6367 30.4548 38.4 30.4548H31.3919H30.6419V31.2048V38.3584C30.6419 40.5951 28.8287 42.4084 26.5919 42.4084H21.7824C19.5456 42.4084 17.7324 40.5951 17.7324 38.3584V31.2048V30.4548H16.9824H9.59999C7.36323 30.4548 5.54999 28.6415 5.54999 26.4048V21.5952C5.54999 19.3585 7.36323 17.5452 9.59999 17.5452H16.9824Z" fill="none" stroke="#EE7400" stroke-width="1.5"/>
</svg>
`;