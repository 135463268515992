import { NumberInputDecorator } from './numberInputUtils'

export const generateInsuranceLiabilityOptions = (): Array<{ value: number; label: string }> => {
  const options = [
    1000000, 2000000, 3000000, 3500000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000,
    10000000, 15000000, 20000000, 25000000, 30000000, 50000000,
  ]

  const out: Array<{ value: number; label: string }> = []

  options.forEach((option) => {
    out.push({
      value: option,
      label: NumberInputDecorator.denormalize(option),
    })
  })

  return out
}

export const LIABILITY_OPTIONS = generateInsuranceLiabilityOptions()
