import { Icon } from '@chakra-ui/react'

export const Check = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M7.032 13.907L3.561 10.002C3.42892 9.85321 3.24315 9.76299 3.04455 9.75117C2.84595 9.73936 2.65079 9.80692 2.502 9.939C2.35321 10.0711 2.26299 10.2568 2.25117 10.4555C2.23936 10.6541 2.30692 10.8492 2.439 10.998L6.439 15.498C6.5069 15.5744 6.58971 15.6361 6.68233 15.6793C6.77495 15.7226 6.87542 15.7464 6.97759 15.7494C7.07977 15.7524 7.18147 15.7345 7.27646 15.6968C7.37146 15.659 7.45774 15.6023 7.53 15.53L18.03 5.03C18.1625 4.88782 18.2346 4.69978 18.2312 4.50548C18.2277 4.31118 18.149 4.12579 18.0116 3.98838C17.8742 3.85096 17.6888 3.77225 17.4945 3.76882C17.3002 3.7654 17.1122 3.83752 16.97 3.97L7.032 13.907V13.907Z" fill="currentColor"/>
	</Icon>
)


export const CheckString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.032 13.907L3.561 10.002C3.42892 9.85321 3.24315 9.76299 3.04455 9.75117C2.84595 9.73936 2.65079 9.80692 2.502 9.939C2.35321 10.0711 2.26299 10.2568 2.25117 10.4555C2.23936 10.6541 2.30692 10.8492 2.439 10.998L6.439 15.498C6.5069 15.5744 6.58971 15.6361 6.68233 15.6793C6.77495 15.7226 6.87542 15.7464 6.97759 15.7494C7.07977 15.7524 7.18147 15.7345 7.27646 15.6968C7.37146 15.659 7.45774 15.6023 7.53 15.53L18.03 5.03C18.1625 4.88782 18.2346 4.69978 18.2312 4.50548C18.2277 4.31118 18.149 4.12579 18.0116 3.98838C17.8742 3.85096 17.6888 3.77225 17.4945 3.76882C17.3002 3.7654 17.1122 3.83752 16.97 3.97L7.032 13.907V13.907Z" fill="#003D66"/>
</svg>
`;