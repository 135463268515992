// import { useNavigate } from 'react-router-dom'
import {
  Badge,
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

// import { offerStatusColorSchemes } from '../../../data/offers/utils/offerStatusColorSchemes'
import { IInsurance, IPropertyOfferInsurance } from '../../../data/offers/property'
import { Check, Duplicate, Pdf, Refresh } from '../../../icons'
import { formatPrice } from '../../../utils/formatPrice'
import { OfferCell, OfferPropertyColumnSection } from './OferPropertyColumnSection'
import { OfferInstallmentCell } from './OfferInstallmentCell'
import { useOfferGrid } from '../../../context/OfferGridContext'
import { prefixUrlWithBasePath } from '../../../model/utils/urlUtils'
import { IItem, ILimit } from '../../../model/interfaces/entities/IItem'
import { usePostStep3 } from '../../../model/api/insuranceForm/usePostStep3'
import { useNavigate, useParams } from 'react-router-dom'
import { Fragment, useContext, useEffect } from 'react'
import { LoadingOverlay } from '../../../components/LoadingOverlay'
import { useIsMutating } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../../model/enums/ApiRouteEnum'
import { offerStatusColors } from '../../../data/offers/utils/offerStatusColors'
import { FormIsDisabledContext } from '../../../model/contexts/FormIsDisabledContext'
import { useCopyItem } from '../../../model/api/insuranceForm/useCopyItem'
import { useRefreshOffer } from '../../../model/api/offers/useRefreshOffer'
import { useIsSupervisor } from '../../../model/utils/userUtils'

// import { useOfferProperty } from '../../context/OfferPropertyContext'

export interface OfferProps {
  item?: IItem
  itemId: string
  offerId?: string
  status: string
  pricePerYear: number
  pricePerInterval: number
  company: {
    id: string | number
    name: string
    logo: string
    isAllowedDiscount: boolean
    minDiscount: number
    maxDiscount: number
    infoTextK3?: string
  }
  responseBody: string
  requestBody: string
  limits: ILimit[]
  selectingItemsIDs?: number[]
  setSelectingItemsIDs?: Function
  quoteId?: string
  validUntil?: Date
  passesAllParameters?: boolean
  propertyInsurance?: IPropertyOfferInsurance
  householdInsurance?: IPropertyOfferInsurance
  columnSections?: any
  insuranceGroups?: Array<IInsurance | Array<IInsurance>>
  productName?: string
  pdfFile?: string | null
  errors?: string[]
  viewOnly?: boolean
  paymentFrequency?: string
  discount: number
  contractRequestBody?: string
  contractResponseBody?: string
}

const OfferColumnSection = ({ children, sx, ...rest }: BoxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 2,
        py: 2,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export const OfferPropertyColumn = ({
  item,
  itemId,
  offerId,
  quoteId,
  status,
  company,
  pricePerYear,
  pricePerInterval,
  responseBody,
  requestBody,
  productName,
  validUntil,
  passesAllParameters,
  propertyInsurance,
  householdInsurance,
  columnSections,
  insuranceGroups,
  limits,
  setSelectingItemsIDs,
  selectingItemsIDs,
  pdfFile,
  errors,
  viewOnly,
  paymentFrequency,
  discount,
  contractResponseBody,
  contractRequestBody,
}: OfferProps) => {
  // const navigate = useNavigate()
  // const { onCompareClick } = useOfferProperty()
  // const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    isOpen: isXMLModalOpen,
    onOpen: onXMLModalOpen,
    onClose: onXMLModalClose,
  } = useDisclosure()
  const { openedColumns, onSectionTitleClick } = useOfferGrid()
  const displayId = quoteId ? `${itemId}/${quoteId}` : itemId
  const elementId = `offer-${displayId.replace(/\//, '')}`
  const isSelected = item !== undefined && item.id === Number(itemId)
  const statusColorKey: string = (() => {
    if (isSelected) {
      return 'selected'
    }
    if (status === 'error') {
      return 'error'
    }

    return 'valid'
  })()
  const color = offerStatusColors[statusColorKey]
  const { id: generalID } = useParams<'id'>()
  const toastMessage = useToast()
  const navigate = useNavigate()
  const isMutating = useIsMutating({ mutationKey: [`${ApiRouteEnum.OFFERS}/${itemId}/step3`] })
  const formIsDisabled = useContext(FormIsDisabledContext)
  const successCallBack = () => {
    navigate(`/sme/wizard/edit/${generalID}/4`)
  }
  const refreshOffer = useRefreshOffer(offerId, itemId)
  const userIsSupervisor = useIsSupervisor()

  const errorCallBack = (error: any) => {
    const errorMessage = error?.response?.data?.error || 'Nepodařilo se uložit'

    toastMessage({
      title: 'Chyba',
      description: errorMessage,
      status: 'error',
      isClosable: true,
      position: 'top',
    })
  }

  const { mutate } = usePostStep3(successCallBack, errorCallBack, generalID, itemId)
  const { mutate: mutateCopyItem, isLoading: isCopyingItem } = useCopyItem(
    () => {
      toastMessage({
        title: 'Zkopírováno',
        status: 'success',
      })
    },
    () =>
      toastMessage({
        title: 'Chyba',
        description: 'Nepodařilo se naklonovat',
        status: 'error',
      }),
    generalID,
    itemId,
  )

  const onSwitchChange = () => {
    const id = Number(itemId)

    if (setSelectingItemsIDs && selectingItemsIDs) {
      const findIndex = selectingItemsIDs.findIndex((i) => i === id)
      if (findIndex === -1) {
        setSelectingItemsIDs([...selectingItemsIDs, id])
      } else {
        const toSplice = [...selectingItemsIDs]
        toSplice.splice(findIndex, 1)
        setSelectingItemsIDs(toSplice)
      }
    }
  }

  return (
    <LoadingOverlay isLoading={isMutating > 0}>
      <Box
        id={elementId}
        sx={{
          display: 'grid',
          gap: 'px',
          placeContent: 'start',
          borderLeft: '1px solid',
          borderLeftColor: 'gray.300',
        }}
      >
        <OfferColumnSection
          sx={{
            px: 2,
            py: 1,
            bg: color,
          }}
        >
          <Text sx={{ fontSize: 'sm', fontWeight: 'medium' }}>{`#${displayId}`}</Text>

          {status === 'error' && (
            <Tooltip
              shouldWrapChildren
              hasArrow
              label={errors?.map((err) => (
                <Fragment key={err}>
                  {err} <br />
                </Fragment>
              ))}
              placement="top"
            >
              <Badge colorScheme="red">Chyba</Badge>
            </Tooltip>
          )}

          {status !== 'error' && (
            <Tooltip
              shouldWrapChildren
              hasArrow
              label={errors?.map((err) => (
                <Fragment key={err}>
                  {err} <br />
                </Fragment>
              ))}
              placement="top"
              visibility={isSelected ? 'hidden' : 'visible'}
            >
              <Badge colorScheme="green" visibility={isSelected ? 'visible' : 'hidden'}>
                Vybraná
              </Badge>
            </Tooltip>
          )}

          <Flex sx={{ alignItems: 'center', gap: 2 }}>
            <Switch
              size="sm"
              isChecked={!!selectingItemsIDs?.find((e) => e === Number(itemId))}
              onChange={onSwitchChange}
              isDisabled={formIsDisabled}
            />
          </Flex>
        </OfferColumnSection>

        <OfferColumnSection
          sx={{
            gap: 8,
            px: 2,
            py: 0,
          }}
        >
          <Image
            src={company.logo ? prefixUrlWithBasePath(company.logo) : undefined}
            alt={company.name}
            width="64px"
            height="48px"
            style={{ mixBlendMode: 'multiply' }}
          />

          {company.infoTextK3 && company.infoTextK3 !== '' && (
            <Popover placement="right">
              <PopoverTrigger>
                <Badge cursor="pointer" bgColor="green.500" textColor="white">
                  Info
                </Badge>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <Box p={4}>
                  <div dangerouslySetInnerHTML={{ __html: company.infoTextK3.replace('\\', '') }} />
                </Box>
              </PopoverContent>
            </Popover>
          )}

          {status !== 'error' &&
            viewOnly &&
            typeof item != 'undefined' &&
            parseInt(itemId) === item.id && (
              <Button
                colorScheme="primary"
                size="sm"
                onClick={(e) => mutate()}
                isDisabled={formIsDisabled}
              >
                <Check />
                Sjednáno
              </Button>
            )}
          {status !== 'error' && !viewOnly && (
            <Button
              colorScheme="primary"
              size="sm"
              onClick={(e) => mutate()}
              isDisabled={formIsDisabled}
            >
              <Check />
              Sjednat
            </Button>
          )}

          {status === 'error' && (
            <Tooltip
              shouldWrapChildren
              hasArrow
              label={errors?.map((err) => (
                <Fragment key={err}>
                  {err} <br />
                </Fragment>
              ))}
              placement="top"
            >
              <Text sx={{ color: 'red.800', fontSize: 'xs', fontWeight: 'semibold' }}>
                Nabídku se nepodařilo zakalkulovat
              </Text>
            </Tooltip>
          )}
        </OfferColumnSection>

        <OfferColumnSection
          sx={{
            p: 2,
            bg: color,
            minWidth: '300px',
          }}
        >
          <Text sx={{ fontSize: 'sm', fontWeight: 'medium' }}></Text>

          <Text sx={{ fontSize: 'sm', fontWeight: 'semibold' }}>
            {formatPrice({ price: pricePerYear })}
          </Text>
        </OfferColumnSection>

        <OfferColumnSection
          sx={{
            gap: 8,
            px: 2,
          }}
        >
          <Flex sx={{ gap: 1 }}>
            <Text fontWeight="semibold">{productName}</Text>
          </Flex>
          <Flex sx={{ gap: 1 }}>
            {!formIsDisabled && (
              <IconButton
                isLoading={isCopyingItem}
                onClick={() => mutateCopyItem()}
                variant="ghost"
                aria-label="Klonovat"
              >
                <Duplicate color="secondary.500" />
              </IconButton>
            )}
            {pdfFile && (
              <IconButton
                variant="ghost"
                aria-label="PDF"
                onClick={() => window.open(pdfFile, '_blank')}
              >
                <Pdf color="secondary.500" />
              </IconButton>
            )}
            {userIsSupervisor && (
              <Button variant="ghost" size="sm" onClick={onXMLModalOpen}>
                XML
              </Button>
            )}
            {!viewOnly && (
              <IconButton
                variant="ghost"
                aria-label="Obnovit"
                onClick={() => refreshOffer.mutateAsync()}
              >
                <Refresh color="secondary.500" />
              </IconButton>
            )}
          </Flex>
        </OfferColumnSection>

        {limits.map((limit, index) => {
          return (
            <OfferPropertyColumnSection
              onClick={() => onSectionTitleClick(limit.name)}
              isOpened={openedColumns.includes(limit.name)}
              key={limit.name}
              titleBg={color}
              subLimits={limit.subLimits}
              itemId={itemId}
              viewOnly={viewOnly}
            />
          )
        })}

        <OfferCell
          id="discount"
          type="string"
          value={company.isAllowedDiscount ? discount.toString() : ''}
          isEditable
          hideCheckbox
          isBEChecked={company.isAllowedDiscount}
          suffix={company.isAllowedDiscount ? '%' : undefined}
          itemId={itemId}
          offerId={offerId}
          minDiscount={company.minDiscount}
          maxDiscount={company.maxDiscount}
          viewOnly={viewOnly}
        />
        <OfferInstallmentCell
          id="installment"
          itemId={itemId}
          offerId={offerId}
          type="string"
          value={pricePerInterval}
          paymentFrequency={paymentFrequency}
          isEditable
          hideCheckbox
          viewOnly={viewOnly}
        />

        <Modal isOpen={isXMLModalOpen} size="xl" onClose={onXMLModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Nabídka</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack divider={<Divider />} spacing={4}>
                <Box>
                  <Text fontSize="large" fontWeight="semibold">
                    Request
                  </Text>
                  <Text fontSize="small" wordBreak="break-word">
                    {requestBody}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="large" fontWeight="semibold">
                    Response
                  </Text>
                  <Text fontSize="small" wordBreak="break-word">
                    {responseBody}
                  </Text>
                </Box>
              </VStack>
            </ModalBody>

            {contractRequestBody && contractResponseBody && (
              <>
                <ModalHeader>Sjednání</ModalHeader>
                <ModalBody>
                  <VStack divider={<Divider />} spacing={4}>
                    <Box>
                      <Text fontSize="large" fontWeight="semibold">
                        Request
                      </Text>
                      <Text fontSize="small" wordBreak="break-word">
                        {contractRequestBody}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="large" fontWeight="semibold">
                        Response
                      </Text>
                      <Text fontSize="small" wordBreak="break-word">
                        {contractResponseBody}
                      </Text>
                    </Box>
                  </VStack>
                </ModalBody>
              </>
            )}

            <ModalFooter>
              <Button colorScheme="primary" mr={3} onClick={onXMLModalClose}>
                Zavřít
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </LoadingOverlay>
  )
}
