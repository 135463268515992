import { Container } from '../../../components/Container'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { InsuranceInfo } from '../../../containers/InsuranceInfo'

export const Step5 = () => {
  return (
    <Section>
      <Container>
        <StepsContent>
          <Steps icon="house" currentStep={5} />

          <InsuranceInfo
            offerId="16834"
            startDate={new Date(2022, 11, 31)}
            insureeDetails="Jan Novák, Hlavní třída 667, 708 00 Ostrava-Poruba"
            subjectDetails="Bělohorská 21, 605 00 Šumperk"
            additionalInfo="Rodinný dům, 2 podlaží, sklep"
            broker={{
              broker: { name: 'Ondřej Vaverka', link: '#', email: 'ondrej.vaverka@okklient.cz' },
              company: { name: 'OK KLIENT a.s.', link: '#' },
              date: new Date(2022, 10, 12, 14, 14, 26),
            }}
          />
        </StepsContent>
      </Container>
    </Section>
  )
}
