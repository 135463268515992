import { Box, Table, Tbody, Tr } from '@chakra-ui/react'

import { IProfile } from '../../data/profile'
import { External, Group, Key, User } from '../../icons'
import { Card, CardBody } from '../../components/Card'
import { UserHeader } from './containers/UserHeader'
import { NumbersTitle, NumbersWrapper } from './containers/NumbersTable'
import { UserDetailItemLabel, UserDetailItemValue } from './containers/UserDetailItem'
import { PageContent } from '../PageContent'

export const LoggedProfile = ({ profile }: { profile: IProfile }) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    address,
    status,
    insuranceAllowed,
    brokerNumbers,
    personalNumbers,
  } = profile
  const fullName = `${firstName} ${lastName}`

  return (
    <PageContent>
      <UserHeader
        fullName={fullName}
        email={email}
        phone={phone}
        address={address}
        status={status}
        insuranceAllowed={insuranceAllowed}
        isCurrent
      />

      <Card>
        <CardBody>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { base: '1fr', lg: '21.25rem 1fr 1fr' },
              alignItems: 'start',
              columnGap: 8,
              rowGap: 4,
            }}
          >
            <Table variant="unstyled">
              <Tbody sx={{ '& > td': { mb: 2 } }}>
                <Tr>
                  <td>
                    <UserDetailItemLabel>
                      <Group />
                      Organizace
                    </UserDetailItemLabel>
                  </td>
                  <td>
                    <UserDetailItemValue>OK Group Supervizor</UserDetailItemValue>
                  </td>
                </Tr>
                <Tr>
                  <td>
                    <UserDetailItemLabel>
                      <User />
                      Typ uživatele
                    </UserDetailItemLabel>
                  </td>
                  <td>
                    <UserDetailItemValue>APP user</UserDetailItemValue>
                  </td>
                </Tr>
                <Tr>
                  <td>
                    <UserDetailItemLabel>
                      <Key />
                      Role
                    </UserDetailItemLabel>
                  </td>
                  <td>
                    <UserDetailItemValue>Full</UserDetailItemValue>
                  </td>
                </Tr>
                <Tr>
                  <td>
                    <UserDetailItemLabel>
                      <External />
                      Uživatelské jméno
                      <br />
                      do&nbsp;jiných systémů
                    </UserDetailItemLabel>
                  </td>
                  <td>
                    <UserDetailItemValue>jana.novakova</UserDetailItemValue>
                  </td>
                </Tr>
              </Tbody>
            </Table>

            {brokerNumbers && (
              <NumbersWrapper>
                <NumbersTitle>Ziskatelská čísla</NumbersTitle>

                {/*<UserNumbersTable rows={brokerNumbers} />*/}
              </NumbersWrapper>
            )}

            {personalNumbers && (
              <NumbersWrapper>
                <NumbersTitle>Osobní čísla</NumbersTitle>

                {/*<UserNumbersTable rows={personalNumbers} />*/}
              </NumbersWrapper>
            )}
          </Box>
        </CardBody>
      </Card>
    </PageContent>
  )
}
