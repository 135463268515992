import { Heading } from '@chakra-ui/react'
import { IOrganization } from '../../data/organizations'
import { OrganizationCreateForm } from '../OrganizationCreateForm'
import { PageContent } from '../PageContent'

export const OrganizationCreate = ({ organization }: { organization?: IOrganization }) => {
  return (
    <PageContent>
      <Heading>Vytvořit organizaci</Heading>

      {organization && <OrganizationCreateForm organization={organization} />}
    </PageContent>
  )
}
