import { Heading } from '@chakra-ui/react'

import { IProfile } from '../../data/profile'
import { PageContent } from '../PageContent'
import { UserCreateForm } from '../UserCreateForm'

export const UserCreate = ({ profile }: { profile: IProfile }) => {
  return (
    <PageContent>
      <Heading>Vytvořit uživatele</Heading>

      <UserCreateForm profile={profile} />
    </PageContent>
  )
}
