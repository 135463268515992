import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Flex, Text, Button } from '@chakra-ui/react'

import { OfferHeader } from './OfferHeader'
import { BasicInfoTab, DiscountsTab } from './tabs/chaloupka'
import { Check, ColumnTriple } from '../../icons'
import { formatDate } from '../../utils/formatDate'
import { offerStatusColors } from '../../data/offers/utils/offerStatusColors'
import { IPropertyOfferInsurance } from '../../data/offers/property'
// import { useOfferProperty } from '../../context/OfferPropertyContext'
import { OfferWrapper } from './OfferWrapper'

export interface OfferProps {
  offerId: string
  quoteId: string
  status: string
  company: {
    id: string
    name: string
  }
  validUntil?: Date
  propertyInsurance?: IPropertyOfferInsurance
  householdInsurance?: IPropertyOfferInsurance
}

const OfferFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ bgColor: 'gray.100', borderBottomRadius: 'md' }} as="footer">
      {children}
    </Box>
  )
}

export const OfferProperty = ({
  offerId,
  quoteId,
  status,
  company,
  validUntil,
  propertyInsurance,
  householdInsurance,
}: OfferProps) => {
  const navigate = useNavigate()
  // const { onCompareClick } = useOfferProperty()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [toCompare, setToCompare] = useState<boolean>(false)
  const displayId = `${offerId}/${quoteId}`
  const elementId = `offer-${displayId.replace(/\//, '')}`
  const color = offerStatusColors[status]

  const scrollToOffer = (elementId: string) => {
    const element: HTMLElement | null = document.querySelector(`#${elementId}`)

    element?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  return (
    <OfferWrapper id={elementId}>
      <OfferHeader displayId={displayId} company={company} status={status} color={color} />

      <OfferFooter>
        <Tabs variant="default" colorScheme="primary" onChange={() => scrollToOffer(elementId)}>
          <TabPanels>
            <TabPanel>
              <BasicInfoTab
                status={status}
                color={color}
                insurances={[propertyInsurance, householdInsurance]}
              />
            </TabPanel>
            <TabPanel>
              <DiscountsTab />
            </TabPanel>
          </TabPanels>

          <Flex
            sx={{
              display: {
                base: 'grid',
                md: 'flex',
              },
              rowGap: 2,
              px: [3, 3, 4],
              pb: [3, 3, 4],
              alignItems: 'center',
              justifyContent: { base: 'stretch', md: 'space-between' },
            }}
          >
            <TabList>
              <Tab>Základní přehled</Tab>
              <Tab>Slevy</Tab>
            </TabList>

            <Flex
              sx={{
                gap: 8,
                alignItems: 'center',
                justifyContent: { base: 'space-between', lg: 'end' },
              }}
            >
              {validUntil && (
                <Text variant="textMuted" sx={{ fontSize: 'sm' }} as="p">
                  Nabídka platí do {formatDate({ date: validUntil })}
                </Text>
              )}

              <Flex sx={{ gap: 4 }}>
                <Button variant="outline" onClick={() => setToCompare(!toCompare)}>
                  {toCompare ? (
                    <Check color="secondary.500" />
                  ) : (
                    <ColumnTriple color="secondary.500" />
                  )}
                  {toCompare ? 'V porovnávání' : 'Porovnat'}
                </Button>

                {status !== 'error' && (
                  <Button
                    colorScheme="primary"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    loadingText="Ukládám..."
                    onClick={() => {
                      setIsSubmitting(true)
                      setTimeout(() => {
                        navigate('/carkulka/wizard/4')
                      }, 1000)
                    }}
                  >
                    <Check />
                    Sjednat
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Tabs>
      </OfferFooter>
    </OfferWrapper>
  )
}
