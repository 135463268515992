import { Icon } from '@chakra-ui/react'

export const Duplicate = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M8 2C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4V14C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V4C16 3.46957 15.7893 2.96086 15.4142 2.58579C15.0391 2.21071 14.5304 2 14 2H8ZM7 4C7 3.73478 7.10536 3.48043 7.29289 3.29289C7.48043 3.10536 7.73478 3 8 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8946 14.2652 15 14 15H8C7.73478 15 7.48043 14.8946 7.29289 14.7071C7.10536 14.5196 7 14.2652 7 14V4Z" fill="currentColor"/>
<path d="M4 6.00001C4.00001 5.64894 4.09243 5.30406 4.26796 5.00003C4.4435 4.69601 4.69597 4.44354 5 4.26801V14.5C5 15.163 5.26339 15.7989 5.73223 16.2678C6.20107 16.7366 6.83696 17 7.5 17H13.732C13.5565 17.304 13.304 17.5565 13 17.732C12.6959 17.9076 12.3511 18 12 18H7.5C6.57174 18 5.6815 17.6313 5.02513 16.9749C4.36875 16.3185 4 15.4283 4 14.5V6.00001Z" fill="currentColor"/>
	</Icon>
)


export const DuplicateString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 2C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4V14C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V4C16 3.46957 15.7893 2.96086 15.4142 2.58579C15.0391 2.21071 14.5304 2 14 2H8ZM7 4C7 3.73478 7.10536 3.48043 7.29289 3.29289C7.48043 3.10536 7.73478 3 8 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8946 14.2652 15 14 15H8C7.73478 15 7.48043 14.8946 7.29289 14.7071C7.10536 14.5196 7 14.2652 7 14V4Z" fill="#003D66"/>
<path d="M4 6.00001C4.00001 5.64894 4.09243 5.30406 4.26796 5.00003C4.4435 4.69601 4.69597 4.44354 5 4.26801V14.5C5 15.163 5.26339 15.7989 5.73223 16.2678C6.20107 16.7366 6.83696 17 7.5 17H13.732C13.5565 17.304 13.304 17.5565 13 17.732C12.6959 17.9076 12.3511 18 12 18H7.5C6.57174 18 5.6815 17.6313 5.02513 16.9749C4.36875 16.3185 4 15.4283 4 14.5V6.00001Z" fill="#003D66"/>
</svg>
`;