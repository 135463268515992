import { Box } from '@chakra-ui/react'

import { OfferProperty } from './OfferProperty'
import { propertyOffers } from '../../data/offers/property'

export const OffersPropertyList = ({ offerId }: { offerId: string }) => {
  const offers = propertyOffers.flatMap(({ passed, offers }) => offers)

  return (
    <Box sx={{ display: 'grid', gap: 12 }}>
      {offers &&
        offers.length > 0 &&
        offers.map((offer) => {
          return (
            <OfferProperty
              key={offer.quoteId}
              offerId={offerId}
              status={String(offer.status)}
              quoteId={String(offer.quoteId)}
              company={offer.company}
              validUntil={offer.validUntil}
              propertyInsurance={offer.propertyInsurance}
              householdInsurance={offer.householdInsurance}
            />
          )
        })}
    </Box>
  )
}
