import { Icon } from '@chakra-ui/react'

export const Motobike = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 28 28" fill="none">
<path d="M13.6154 5H16.7115L17.7067 7.65116M20.6923 15.6047L18.7019 10.3023M18.7019 10.3023L18.3702 9.4186M18.7019 10.3023H16.3798M4.3269 12.0698H7.42306M17.7067 7.65116H20.6923V9.4186H18.3702M17.7067 7.65116L18.3702 9.4186M16.3798 10.3023H14.0577L11.4038 12.0698H7.42306M16.3798 10.3023L14.5 16.4884L11.4038 17.814M7.42306 12.0698V15.6047" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.0962 19.5814C11.0962 21.6068 9.45238 23.25 7.42308 23.25C5.39378 23.25 3.75 21.6068 3.75 19.5814C3.75 17.556 5.39378 15.9128 7.42308 15.9128C9.45238 15.9128 11.0962 17.556 11.0962 19.5814Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25.25 19.5814C25.25 21.6068 23.6062 23.25 21.5769 23.25C19.5476 23.25 17.9038 21.6068 17.9038 19.5814C17.9038 17.556 19.5476 15.9128 21.5769 15.9128C23.6062 15.9128 25.25 17.556 25.25 19.5814Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
)


export const MotobikeString=`<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6154 5H16.7115L17.7067 7.65116M20.6923 15.6047L18.7019 10.3023M18.7019 10.3023L18.3702 9.4186M18.7019 10.3023H16.3798M4.3269 12.0698H7.42306M17.7067 7.65116H20.6923V9.4186H18.3702M17.7067 7.65116L18.3702 9.4186M16.3798 10.3023H14.0577L11.4038 12.0698H7.42306M16.3798 10.3023L14.5 16.4884L11.4038 17.814M7.42306 12.0698V15.6047" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.0962 19.5814C11.0962 21.6068 9.45238 23.25 7.42308 23.25C5.39378 23.25 3.75 21.6068 3.75 19.5814C3.75 17.556 5.39378 15.9128 7.42308 15.9128C9.45238 15.9128 11.0962 17.556 11.0962 19.5814Z" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.25 19.5814C25.25 21.6068 23.6062 23.25 21.5769 23.25C19.5476 23.25 17.9038 21.6068 17.9038 19.5814C17.9038 17.556 19.5476 15.9128 21.5769 15.9128C23.6062 15.9128 25.25 17.556 25.25 19.5814Z" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;