import {
  Badge,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Loading } from '../../components/Loading'
import { Pagination } from '../../components/Pagination'
import { TableWrapper } from '../../components/TableWrapper'
import { getUserStatus, getUserType } from '../../data/profile'

import { Add } from '../../icons'
import { useGetUser } from '../../model/api/user/useGetUser'
import { IQueryParams } from '../../model/interfaces/entities/IQueryParams'
import { IUserItem } from '../../model/interfaces/entities/IUser'
import { calculateTotalPages } from '../../utils/calculateTotalPages'
import { PageToolbar } from '../PageToolbar'
import { SearchField } from '../SearchField'
import { ShowInactiveSwitch } from '../ShowInactiveSwitch'
import { OrgInfoSectionHeading } from './OrganizationDetail'
import { useIsSupervisorOrAdmin, userRoleToLabel } from '../../model/utils/userUtils'
import { appendOrRemoveQueryParameters } from '../../model/utils/urlUtils'
import { omit } from 'lodash'

const UserRow = (user: IUserItem, isActive: boolean) => {
  const { colorScheme: statusColorScheme, label: statusLabel } = getUserStatus(user.isActive)
  const { colorScheme: userTypeColorScheme, label: userTypeLabel } = getUserType(user.userType)
  return (
    <>
      <Tr>
        <Td>
          <Text sx={{ color: 'primary.500', fontWeight: 'medium' }}>
            <Link to={`/user/detail/${user.id}`}>
              {user.lastName} {user.firstName}
            </Link>
          </Text>
        </Td>
        <Td>
          <Text sx={{ color: 'gray.500' }}>{user.username}</Text>
        </Td>
        <Td>
          <Text sx={{ color: 'primary.700' }}>{user.email}</Text>
        </Td>
        <Td>
          <Text sx={{ color: 'primary.700' }}>{user.role && userRoleToLabel(user.role)}</Text>
        </Td>
        <Td>
          <Flex sx={{ gap: 1, justifyContent: 'end' }}>
            {isActive && <Badge colorScheme={statusColorScheme}>{statusLabel}</Badge>}
            {user.userType && <Badge colorScheme={userTypeColorScheme}>{userTypeLabel}</Badge>}
          </Flex>
        </Td>
      </Tr>
    </>
  )
}

export const ORGANIZATION_USER_LIST_PREFIX = 'organizationUser-'
const ORGANIZATION_USER_LIMIT = 1_000_000

export const OrganizationUserList = () => {
  const { id } = useParams<'id'>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [queryParams, setRawQueryParams] = useState<IQueryParams>({
    unreadOnly:
      searchParams.get(ORGANIZATION_USER_LIST_PREFIX + 'unreadOnly') === '0' ? undefined : 1,
    page: searchParams.get(ORGANIZATION_USER_LIST_PREFIX + 'page')
      ? parseInt(searchParams.get(ORGANIZATION_USER_LIST_PREFIX + 'page')!)
      : 1,
    search: searchParams.get(ORGANIZATION_USER_LIST_PREFIX + 'search')
      ? searchParams.get(ORGANIZATION_USER_LIST_PREFIX + 'search')!
      : undefined,
    limit: ORGANIZATION_USER_LIMIT,
  })

  const setQueryParams = (params: IQueryParams): void => {
    const newSearchParams = appendOrRemoveQueryParameters(
      omit(params, ['limit']),
      searchParams,
      ORGANIZATION_USER_LIST_PREFIX,
    )

    newSearchParams.delete(`${ORGANIZATION_USER_LIST_PREFIX}unreadOnly`)
    if (typeof params.unreadOnly === 'undefined') {
      newSearchParams.set(`${ORGANIZATION_USER_LIST_PREFIX}unreadOnly`, '0')
    }

    setSearchParams(newSearchParams)
    setRawQueryParams(params)
  }

  const navigate = useNavigate()
  const { data, isError, isLoading, error } = useGetUser(
    queryParams.page,
    queryParams.limit,
    queryParams.search,
    queryParams.unreadOnly,
    id,
  )
  const userIsSupervisor = useIsSupervisorOrAdmin()

  const toastMessage = useToast()

  const [startPage, setStartPage] = useState<number>(1)
  const [endPage, setEndPage] = useState<number>(10)

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      <PageToolbar
        leftElements={<OrgInfoSectionHeading>Uživatelé v organizaci </OrgInfoSectionHeading>}
        rightElements={
          <Flex sx={{ gap: 4 }}>
            <ShowInactiveSwitch queryParams={queryParams} setQueryParams={setQueryParams} />
            <Button
              hidden={!userIsSupervisor}
              colorScheme="primary"
              size="sm"
              onClick={() => navigate(`/user/create/${id}`)}
            >
              <Add />
              Vytvořit nového uživatele
            </Button>
          </Flex>
        }
      />

      <SearchField
        inputPlaceholder="Hledat dle jména"
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />

      <TableWrapper>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Td>Jméno</Td>
              <Td>Uživatelské jméno</Td>
              <Td>E-mail</Td>
              <Td>Role</Td>
              <Td></Td>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {data?.data.items &&
                  data.data.items.length > 0 &&
                  data.data.items.map((user: IUserItem) => {
                    return <UserRow key={user.id} {...user} />
                  })}
              </>
            )}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td colSpan={4}>
                <Pagination
                  totalPages={calculateTotalPages(data?.data.count, ORGANIZATION_USER_LIMIT)}
                  currentPage={queryParams.page}
                  queryParams={queryParams}
                  setQueryParams={setQueryParams}
                  startPage={startPage}
                  endPage={endPage}
                  onSetStartPage={setStartPage}
                  onSetEndPage={setEndPage}
                  hide={true}
                />
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </TableWrapper>
    </Box>
  )
}
