import { Box, Flex, Text } from '@chakra-ui/react'
import { FormProvider, useFormContext } from 'react-hook-form'

import { RadioStack } from '../components/RadioStack'
import {
  flatObjects,
  houseObjects,
  IPropertyObject,
  PropertyInsuranceTypeValues,
} from '../utils/propertyInsuranceTypes'
import { useEffect, useState } from 'react'
import { Check } from '../icons'
import { RadioIcon } from '../components/RadioIcon'
import { RadioIconGroup } from '../components/RadioIconGroup'

const ObjectIcon = ({ isDisabled = false }: { isDisabled: boolean }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        placeContent: 'center',
        w: 6,
        h: 6,
        bg: isDisabled ? 'gray.100' : 'secondary.50',
        borderRadius: 'full',
      }}
    >
      {!isDisabled && <Check width="1rem" height="1rem" color="secondary.500" />}
    </Box>
  )
}

const Object = ({ name, isDisabled = false }: { name: string; isDisabled: boolean }) => {
  return (
    <Flex sx={{ alignItems: 'center', gap: 2 }}>
      <ObjectIcon isDisabled={isDisabled} />
      <Text sx={{ color: isDisabled ? 'gray.400' : 'primary.800', fontSize: 'sm' }}>{name}</Text>
    </Flex>
  )
}

const isObjectDisabled = (
  object: IPropertyObject,
  insuranceType: PropertyInsuranceTypeValues['insuranceType'],
) => {
  if (insuranceType === 'all') return false

  return insuranceType !== object.insuranceType
}

const ObjectsList = ({
  objects,
  insuranceType,
}: {
  objects: Array<IPropertyObject>
  insuranceType: PropertyInsuranceTypeValues['insuranceType']
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        w: '100%',
        gridAutoFlow: { base: 'row', md: 'column' },
        gridTemplateRows: { base: 'auto', md: 'repeat(5, 1fr)' },
        gridTemplateColumns: { base: '1fr 1fr', md: 'repeat(auto-fill, minmax(22%, 1fr))' },
        columnGap: 8,
        rowGap: 4,
      }}
    >
      {objects &&
        objects.length &&
        objects.map((object) => {
          return (
            object && (
              <Object
                key={`${object.name}-${object.id}`}
                name={object.name}
                isDisabled={isObjectDisabled(object, insuranceType)}
              />
            )
          )
        })}
    </Box>
  )
}

const getPropertyTypesObject = (type: PropertyInsuranceTypeValues['propertyType']) => {
  switch (type) {
    default:
    case 'house':
      return houseObjects
    case 'flat':
      return flatObjects
  }
}

export const PropertyInsuranceTypeSelector = () => {
  const methods = useFormContext<PropertyInsuranceTypeValues>()
  const { control, watch } = methods
  const [objects, setObjects] = useState<IPropertyObject[]>([])
  const propertyTypeValue = watch('propertyType')
  const insuranceTypeValue = watch('insuranceType')

  useEffect(() => {
    const propertyTypeObject: IPropertyObject[] = getPropertyTypesObject(propertyTypeValue)
    setObjects(propertyTypeObject)
  }, [propertyTypeValue])

  return (
    <FormProvider {...methods}>
      <Box sx={{ w: '100%', display: 'grid', gap: 8 }}>
        <RadioIconGroup control={control} name="propertyType">
          <RadioStack>
            <RadioIcon icon="house" value="house">
              Rodinný dům
            </RadioIcon>
            <RadioIcon icon="household" value="flat">
              Byt
            </RadioIcon>
          </RadioStack>
        </RadioIconGroup>

        <RadioIconGroup control={control} name="insuranceType">
          <RadioStack>
            <RadioIcon icon="householdProperty" value="all">
              Domácnost + nemovitost
            </RadioIcon>
            <RadioIcon icon="house" value="property">
              Nemovitost
            </RadioIcon>
            <RadioIcon icon="household" value="household">
              Domácnost
            </RadioIcon>
          </RadioStack>
        </RadioIconGroup>

        <ObjectsList objects={objects} insuranceType={insuranceTypeValue} />
      </Box>
    </FormProvider>
  )
}
