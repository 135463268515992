import { Button, Flex, Heading } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { NewsAdminList } from '../../containers/news/NewsAdminList'
import { PageContent } from '../../containers/PageContent'
import { PageToolbar } from '../../containers/PageToolbar'
import { Add } from '../../icons'

export const NewsAdmin = () => {
  const navigate = useNavigate()

  return (
    <Section>
      <Container>
        <PageContent>
          <PageToolbar
            leftElements={
              <Flex sx={{ gap: 4, alignItems: 'center' }}>
                <Heading as="h1">Správa aktualit</Heading>
              </Flex>
            }
            rightElements={
              <Flex sx={{ gap: 4 }}>
                <Button colorScheme="primary" onClick={() => navigate('/news/new')}>
                  <Add />
                  Vytvořit novou aktualitu
                </Button>
              </Flex>
            }
          />

          <NewsAdminList />
        </PageContent>
      </Container>
    </Section>
  )
}
