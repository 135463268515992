import { Box, Button, Flex, Link, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { formatDate } from '../utils/formatDate'

import { formatPrice } from '../utils/formatPrice'
import { Card, CardBody } from './Card'

interface VehicleEvaluationProps {
  description: string
  pdfLink?: string
  onMarketSince?: Date | string
  onMarketTill?: Date | string
  priceWithVAT?: number
  onApplyEvaluation: ({ price, withTax }: { price: number; withTax: boolean }) => void
}

export const VehicleEvaluation = ({
  description,
  pdfLink,
  onMarketSince,
  onMarketTill,
  priceWithVAT,
  onApplyEvaluation,
}: VehicleEvaluationProps) => {
  const [evaluated, setEvaluated] = useState(false)
  const priceWithTax = 314900
  const priceWithoutTax = 260248

  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      <Table variant="striped" sx={{ fontSize: 'sm' }}>
        <Thead>
          <Tr>
            <Th>Popis</Th>
            <Th>V prodeji od</Th>
            <Th>V prodeji do</Th>
            <Th>Cena nového včetně DPH</Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr>
            <Td>
              <Text>
                {description}
                {' '}
                {pdfLink && (
                  <Link variant="underline" href={pdfLink}>
                    PDF
                  </Link>
                )}
              </Text>
            </Td>
            <Td>
              {typeof onMarketSince === 'string'
                ? onMarketSince
                : formatDate({ date: onMarketSince as Date })}
            </Td>
            <Td>
              {typeof onMarketTill === 'string'
                ? onMarketTill
                : formatDate({ date: onMarketTill as Date })}
            </Td>
            <Td>
              <Flex sx={{ flexWrap: 'wrap', gap: 4, placeItems: 'center' }}>
                {priceWithVAT && <Text>{formatPrice({ price: priceWithVAT })}</Text>}

                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() => setEvaluated(true)}
                >
                  Ocenit
                </Button>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {evaluated && (
        <Card sx={{ fontSize: 'sm' }}>
          <CardBody sx={{ p: 4 }}>
            <Flex sx={{ placeItems: 'center', placeContent: 'space-between' }}>
              <Text as="span">Obvyklá cena ojetého vozidla</Text>

              <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  colorScheme="blue"
                  onClick={() => onApplyEvaluation({ price: priceWithoutTax, withTax: false })}
                >
                  <Text as="span" sx={{ fontWeight: 'bold' }}>
                    {formatPrice({ price: priceWithoutTax })}
                  </Text>
                  bez DPH
                </Button>

                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  colorScheme="green"
                  onClick={() => onApplyEvaluation({ price: priceWithTax, withTax: true })}
                >
                  <Text as="span" sx={{ fontWeight: 'bold' }}>
                    {formatPrice({ price: priceWithTax })}
                  </Text>
                  s DPH
                </Button>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      )}
    </Box>
  )
}
