import { Box, FormErrorMessage, FormLabel, RadioGroup } from '@chakra-ui/react'

import { RadioStack } from '../../components/RadioStack'
import { RadioIcon } from '../../components/RadioIcon'
import { IBusiness } from '../../data/InsuranceForm'
import { Field, FieldProps } from 'formik'
import { useGetOfferFields } from '../../model/api/offerFields/useGetOfferFields'
import { IOfferField } from '../../model/interfaces/entities/IOfferField'
import { FormControl } from '../../components/FormControl'
import { RequiredField } from '../RequiredField'
import { Select as Select2 } from 'chakra-react-select'
import { FormError } from '../../theme/components/FormError'

export type ISmeBusinessProps = {
  values: IBusiness
  setValues: Function
  touched: any
  errors: any
}

const Description = ({ items }: { items: Array<string> }) => {
  return (
    <Box as="ul" sx={{ fontSize: 'sm', listStyle: 'none', textAlign: 'center' }}>
      {items.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </Box>
  )
}

export const SmeBusinessAreaSelector = (props: ISmeBusinessProps) => {
  const { values, setValues, touched, errors } = props
  const { data: offerFieldsData } = useGetOfferFields()
  const offerFields = offerFieldsData?.data
  const selectedOfferField = offerFields?.find((offerField) => offerField.id === values.profession)
  const offerFieldsSelectOptions = selectedOfferField?.items?.map((offerFieldItem) => ({
    label: offerFieldItem.name,
    value: offerFieldItem.id.toString(),
  }))

  return (
    <Box sx={{ display: 'grid', gap: 8 }}>
      <Field name="business.profession">
        {({ field }: FieldProps) => {
          const { onChange, ...rest } = field
          return (
            <FormControl isInvalid={touched.business?.profession && !!errors.business?.profession}>
              <RadioGroup {...rest} name="business.profession">
                <RadioStack sx={{ gridTemplateColumns: { base: 'unset', lg: 'repeat(5, 1fr)' } }}>
                  {offerFields?.map((offerField: IOfferField) => (
                    <RadioIcon
                      key={offerField.id}
                      value={offerField.id}
                      icon={offerField.icon || 'retail'}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          profession: e.target.value ? parseInt(e.target.value) : 0,
                          field: 0,
                        })
                      }
                      isChecked={selectedOfferField?.id === offerField.id}
                      description={<Description items={offerField.subtitles || []} />}
                    >
                      {offerField.name}
                    </RadioIcon>
                  ))}
                </RadioStack>
              </RadioGroup>
              {touched.business?.profession && (
                <FormErrorMessage>{errors.business?.profession}</FormErrorMessage>
              )}

              {values.oldProfession?.id && (
                <div style={{fontSize: 'var(--ok-fontSizes-sm)', color: 'gray'}}>Neplatná hodnota: {values.oldProfession.name}</div>
              )}
            </FormControl>
          )
        }}
      </Field>

      <FormControl isInvalid={touched.business?.field && !!errors.business?.field}>
        <FormLabel sx={{ fontSize: 'xl' }}>
          Vyberte obor podnikání, ze kterého má klient nejvyšší příjmy
          <RequiredField />
        </FormLabel>

        <Box w="100%">
          <Select2
            placeholder="Vyberte obor…"
            onChange={(option) =>
              setValues({ ...values, field: option?.value ? parseInt(option.value) : 0 })
            }
            value={offerFieldsSelectOptions?.find(
              (o) => parseInt(o.value) === parseInt(values.field as any),
            )}
            options={offerFieldsSelectOptions}
            isClearable={true}
          />
        </Box>
        {touched.business?.field && <FormErrorMessage>{errors.business?.field}</FormErrorMessage>}
        {values.oldField?.id && (
          <div style={{fontSize: 'var(--ok-fontSizes-sm)', color: 'gray'}}>Neplatná hodnota: {values.oldField.name}</div>
        )}
      </FormControl>
    </Box>
  )
}
