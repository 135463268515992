import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { INewsItemV2 } from '../../interfaces/entities/INews'

export const useGetNewsDetails = (id: string | undefined) => {
  const newsDetailsEndpoint: string = ApiRouteEnum.NEWS + `/${id}`
  return useQuery([ApiRouteEnum.NEWS, id], () => api.get<INewsItemV2>(newsDetailsEndpoint), {
    retry: false,
  })
}
