import { useEffect, useState } from 'react'
import {
  InputGroup,
  InputRightElement,
  NumberInput,
  NumberInputField,
  NumberInputProps,
} from '@chakra-ui/react'

interface CustomNumberInputProps extends NumberInputProps {
  onValueChange: (value: string) => void
  defaultValue: number
  inputRightElement?: string | React.ReactNode | null
}

export const CustomNumberInput = ({
  onValueChange,
  defaultValue,
  inputRightElement = 'Kč',
  ...rest
}: CustomNumberInputProps) => {
  const [value, setValue] = useState(defaultValue)
  const format = (val: number) => new Intl.NumberFormat('cs-CZ').format(Number(val))
  const parse = (val: number) => String(val).replace(/ /, '')

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <InputGroup>
      <NumberInput
        value={format(value)}
        focusInputOnChange={false}
        onChange={(valueAsString: string, valueAsNumber: number) => {
          setValue(valueAsNumber)
        }}
        onBlur={() => {
          onValueChange(parse(value))
        }}
        {...rest}
      >
        <NumberInputField maxW={128} />
        {inputRightElement && <InputRightElement>{inputRightElement}</InputRightElement>}
      </NumberInput>
    </InputGroup>
  )
}
