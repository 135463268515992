import { Box } from "@chakra-ui/react"

interface PanelProps {
  children: React.ReactNode
}

export const Panel = ({ children }: PanelProps) => {
  return (
    <Box
      sx={{
        w: '100%',
        display: 'grid',
        gap: 4,
        placeItems: 'start',
        p: 4,
        bg: 'gray.50',
        borderRadius: 'md',
      }}
    >
      {children}
    </Box>
  )
}