import { Icon } from '@chakra-ui/react'

export const TextItalics = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 24 24" fill="none">
<path d="M9.75 4H18.254C18.444 4.00006 18.6269 4.07224 18.7658 4.20197C18.9046 4.3317 18.9891 4.5093 19.002 4.69888C19.015 4.88846 18.9555 5.07589 18.8356 5.2233C18.7157 5.37071 18.5443 5.4671 18.356 5.493L18.254 5.499H15.057L10.037 18.5H14.25C14.4311 18.5002 14.6059 18.566 14.7423 18.6851C14.8787 18.8042 14.9674 18.9686 14.992 19.148L14.999 19.25C14.999 19.4312 14.9334 19.6063 14.8142 19.7429C14.6951 19.8795 14.5306 19.9684 14.351 19.993L14.25 20H4.75C4.65174 19.9999 4.55446 19.9804 4.46375 19.9426C4.37304 19.9048 4.29068 19.8495 4.22138 19.7798C4.15208 19.7101 4.09721 19.6275 4.05991 19.5366C4.02261 19.4456 4.00361 19.3483 4.004 19.25C4.004 18.87 4.284 18.556 4.649 18.507L4.75 18.5H8.435L8.456 18.435L13.45 5.499H9.75C9.56894 5.49875 9.39408 5.43301 9.2577 5.3139C9.12132 5.1948 9.03263 5.03038 9.008 4.851L9 4.75C9.00001 4.56876 9.06565 4.39366 9.18477 4.25707C9.30389 4.12048 9.46845 4.03165 9.648 4.007L9.751 4H18.254H9.751H9.75Z" fill="currentColor"/>
	</Icon>
)


export const TextItalicsString=`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.75 4H18.254C18.444 4.00006 18.6269 4.07224 18.7658 4.20197C18.9046 4.3317 18.9891 4.5093 19.002 4.69888C19.015 4.88846 18.9555 5.07589 18.8356 5.2233C18.7157 5.37071 18.5443 5.4671 18.356 5.493L18.254 5.499H15.057L10.037 18.5H14.25C14.4311 18.5002 14.6059 18.566 14.7423 18.6851C14.8787 18.8042 14.9674 18.9686 14.992 19.148L14.999 19.25C14.999 19.4312 14.9334 19.6063 14.8142 19.7429C14.6951 19.8795 14.5306 19.9684 14.351 19.993L14.25 20H4.75C4.65174 19.9999 4.55446 19.9804 4.46375 19.9426C4.37304 19.9048 4.29068 19.8495 4.22138 19.7798C4.15208 19.7101 4.09721 19.6275 4.05991 19.5366C4.02261 19.4456 4.00361 19.3483 4.004 19.25C4.004 18.87 4.284 18.556 4.649 18.507L4.75 18.5H8.435L8.456 18.435L13.45 5.499H9.75C9.56894 5.49875 9.39408 5.43301 9.2577 5.3139C9.12132 5.1948 9.03263 5.03038 9.008 4.851L9 4.75C9.00001 4.56876 9.06565 4.39366 9.18477 4.25707C9.30389 4.12048 9.46845 4.03165 9.648 4.007L9.751 4H18.254H9.751H9.75Z" fill="#003D66"/>
</svg>
`;