import { createBreakpoints } from '@chakra-ui/theme-tools'

/**
 * Breakpoints for responsive design
 */
const breakpoints = createBreakpoints({
  xs: '36rem',
  sm: '36rem',
  md: '48rem',
  lg: '62rem',
  xl: '77rem',
  '2xl': '96rem',
})

export default breakpoints
