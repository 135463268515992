import { Link, Table, Tbody } from '@chakra-ui/react'

import {
  FormSection,
  FormSectionBody,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { RecapTableRow } from './Recap'

export const ContractRecap = () => {
  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>Údaje o smlouvě</FormSectionTitle>
      </FormSectionHeader>

      <FormSectionBody>
        <Table variant="unstyled">
          <Tbody>
            <RecapTableRow col1="Název nabídky" col2="Jan Novák, Škoda Octavia" />
            <RecapTableRow col1="Pojistník" col2="Jan Novák" />
            <RecapTableRow col1="Adresa" col2="Hlavní třída 667, 708 00 Ostrava-Poruba" />
            <RecapTableRow
              col1="Kontakty"
              col2={
                <>
                  <Link href="tel:+420123123123">+420 123 123 123</Link>, 
                  <Link href="mailto:jurasek@ctyrimedia.cz">jurasek@ctyrimedia.cz</Link>
                </>
              }
            />
          </Tbody>
        </Table>

        <Table variant="unstyled">
          <Tbody>
            <RecapTableRow
              col1="Vozidlo"
              col2="SKODA OCTAVIA COMBI III DIESEL 2.0 TDI 135 kW 4x4 DSG L&K Combi"
            />
          </Tbody>
        </Table>

        <Table variant="unstyled">
          <Tbody>
            <RecapTableRow
              col1="Zpracovatel nabídky"
              col2="Lumír Černý, l.cerny@ibs-group.cz, 10. 1. 2021, 10:35"
            />
            <RecapTableRow
              col1="Sjednatel smlouvy"
              col2="Marie Nováková, m.novakova@ibs-group.cz, 15. 1. 2021"
            />
            <RecapTableRow col1="Ziskatelské číslo" col2="7830274" />
          </Tbody>
        </Table>
      </FormSectionBody>
    </FormSection>
  )
}
