import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'
import { PartsStyleFunction, mode } from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleFunction = (props) => {
  return {
    wrapper: {
      display: 'grid',
      gap: 8,
      gridTemplateColumns: {
        base: '1fr',
        lg: '15rem auto',
      },
      mb: 8,
      p: {
        base: 4,
        lg: 8,
      },
      bgColor: mode('white', 'gray.700')(props),
      borderRadius: 'md',
      shadow: 'card',
    },
    header: {
      display: 'grid',
      gap: 2,
      alignContent: 'start',
    },
    title: {
      color: 'body',
      fontSize: 'lg',
      fontWeight: 'medium',
      lineHeight: 'heading',
    },
    description: {
      color:  mode('gray.600', 'gray.200')(props),
      fontSize: 'sm',
    },
    body: {
      display: 'grid',
      gap: 4,
      alignContent: 'start',
      justifyItems: 'start',
    },
  }
}

export const FormSection: ComponentMultiStyleConfig = {
  parts: ['wrapper', 'header', 'title', 'description', 'body'],
  baseStyle,
  variants: {
    default: {
      wrapper: {},
      header: {},
      title: {},
      description: {},
      body: {},
    },
  },
  defaultProps: {
    variant: 'default',
  },
}
