import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import IModalEmailFormValues from '../../interfaces/entities/IModalEmailFormValues'

export const usePostOfferSendMail = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id?: string,
) => {
  return useMutation((values?: IModalEmailFormValues) => api.post(`${ApiRouteEnum.OFFERS}/${id}/send-email`, values), {
    mutationKey: [`${ApiRouteEnum.OFFERS}/${id}/send-email`],
    onSuccess: (data) => {
      successCallBack(data.data)
    },
    onError: (error) => errorCallBack(error),
  })
}
