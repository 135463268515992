import * as yup from 'yup'
import { IS_REQUIRED, MIN_CHARACTERS } from '../model/constants/validationMessages'
import { UserRoleEnum } from '../model/enums/UserRoleEnum'

export const UserEditSchema = yup.object().shape({
  username: yup
    .string()
    .optional()
    .when('role', {
      is: UserRoleEnum.SALESMAN,
      then: yup.string().optional().nullable(),
      otherwise: yup.string().min(3, MIN_CHARACTERS).required(IS_REQUIRED),
    }),
  firstName: yup.string().min(3, MIN_CHARACTERS).required(IS_REQUIRED),
  lastName: yup.string().min(3, MIN_CHARACTERS).required(IS_REQUIRED),
  email: yup.string().when('role', {
    is: UserRoleEnum.SALESMAN,
    then: yup.string().optional().nullable(),
    otherwise: yup.string().email('Musí být validní email').required(IS_REQUIRED),
  }),
  usernameOtherSystem: yup
    .string()
    .optional()
    .when('role', {
      is: UserRoleEnum.SALESMAN,
      then: yup.string().min(3, MIN_CHARACTERS).required(IS_REQUIRED),
      otherwise: yup.string().optional().nullable(),
    }),
})
