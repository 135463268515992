import { ICompany } from "../utils/companies"

export type IConceptStatus = 'created' | 'toSend' | 'sent'

export const getConceptStatus = (status: IConceptStatus) => {
  switch (status) {
    case 'created':
      return { badgeColorScheme: 'blue', badgeLabel: 'Vytvořena' }
    case 'toSend':
      return { badgeColorScheme: 'orange', badgeLabel: 'K odeslání' }
    case 'sent':
      return { badgeColorScheme: 'green', badgeLabel: 'Odeslána' }
  }
}

export interface IConcept {
  id: string
  createDate?: Date
  startDate?: Date
  insureeName?: string
  subject?: string
  status: IConceptStatus
  projectName?: string
  brokerFullName?: string
  company?: ICompany['id']
  note?: string
  canSend?: boolean
  canEdit?: boolean
  progress?: number
}

export const conceptsCars: Array<IConcept> = [
  {
    id: '15267',
    createDate: new Date(2022, 9, 21),
    startDate: new Date(2022, 10, 1),
    insureeName: 'Blahová Dagmar',
    subject: 'SKODA KAROQ DIESEL',
    company: 'cpp',
    projectName: 'Dagmar Karoq',
    brokerFullName: 'Ing. Jan Novák',
    status: 'created',
    note: 'Poznámka k této nabídce',
    progress: 2,
  },
  {
    id: '15266',
    createDate: new Date(2022, 9, 21),
    startDate: new Date(2022, 10, 1),
    insureeName: 'Jan Novák',
    subject: 'MINI COOPER S 2020',
    company: 'direct',
    projectName: 'Honza Mini Cooper',
    brokerFullName: 'Ing. Jan Novák',
    status: 'toSend',
    canSend: true,
    progress: 5,
  },
  {
    id: '15265',
    createDate: new Date(2022, 9, 21),
    startDate: new Date(2022, 10, 1),
    insureeName: 'Jan Novák',
    subject: 'MINI COOPER S 2020',
    company: 'direct',
    projectName: 'Honza Mini Cooper',
    brokerFullName: 'Ing. Jan Novák',
    status: 'sent',
    progress: 5,
  },
]

export const conceptsProperties: Array<IConcept> = [
  {
    id: '15267',
    createDate: new Date(2022, 9, 21),
    startDate: new Date(2022, 10, 1),
    insureeName: 'Blahová Dagmar',
    subject: 'Rodinný dům, Rožnov pod Radhoštěm',
    company: 'cpp',
    projectName: 'Dům Rožnov',
    brokerFullName: 'Ing. Jan Novák',
    status: 'created',
    note: 'Poznámka k této nabídce',
    progress: 2,
  },
  {
    id: '15266',
    createDate: new Date(2022, 9, 21),
    startDate: new Date(2022, 10, 1),
    insureeName: 'Jan Novák',
    subject: 'Rodinný dům, Rožnov pod Radhoštěm',
    company: 'direct',
    projectName: 'Dům Rožnov',
    brokerFullName: 'Ing. Jan Novák',
    status: 'toSend',
    canSend: true,
    progress: 5,
  },
  {
    id: '15265',
    createDate: new Date(2022, 9, 21),
    startDate: new Date(2022, 10, 1),
    insureeName: 'Jan Novák',
    subject: 'Rodinný dům, Rožnov pod Radhoštěm',
    company: 'direct',
    projectName: 'Dům Rožnov',
    brokerFullName: 'Ing. Jan Novák',
    status: 'sent',
    progress: 5,
  },
]