import { Box, Text } from '@chakra-ui/react'

import { Card, CardBody } from '../components/Card'
import { formatDate } from '../utils/formatDate'
import React from 'react'
import { IOffersItem } from '../model/interfaces/entities/IOffers'
import moment from 'moment'

const InsuranceSummaryItem = ({
  label,
  value,
}: {
  label: string
  value: string | number | React.ReactNode
}) => {
  return (
    <Box sx={{ display: 'grid', gap: 1, alignContent: 'start', justifyItems: 'start' }}>
      <Text sx={{ fontSize: 'sm', fontWeight: 'semibold', lineHeight: 'body' }}>{label}</Text>
      <Text sx={{ color: 'primary.400', fontSize: 'lg', fontWeight: 'medium' }}>{value}</Text>
    </Box>
  )
}

export const InsuranceSummary: React.FC<{ offer?: IOffersItem }> = ({ offer }) => {
  return (
    <Card>
      <CardBody
        sx={{
          display: 'grid',
          gridTemplateColumns: { base: '1fr 1fr', lg: 'repeat(5, 1fr)' },
          gap: { base: 4, lg: 8 },
        }}
      >
        <InsuranceSummaryItem
          label="Pojistník"
          value={`${offer?.applicant.firstName} ${offer?.applicant.familyName}`}
        />

        <InsuranceSummaryItem
          label="Telefonní číslo"
          value={<a href={`tel:${offer?.applicant.mobilePhone}`}>{offer?.applicant.mobilePhone}</a>}
        />
        <InsuranceSummaryItem label="Číslo pojistné smlouvy" value={offer?.number} />
        <InsuranceSummaryItem
          label="Počátek pojištění"
          value={formatDate({ date: moment(offer?.insurance.dateStart).toDate() })}
        />
        <InsuranceSummaryItem
          label="Splatnost 1. pojistného"
          value={formatDate({ date: moment(offer?.firstPaymentDueDate).toDate() })}
        />
      </CardBody>
    </Card>
  )
}
