import { Box, useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { Loading } from '../../components/Loading'
import { useGetFAQ } from '../../model/api/faq/useGetFAQ'
import { FAQItem } from './FAQItem'

export const FAQList = () => {
  const toastMessage = useToast()
  const { data, isLoading, isError, error } = useGetFAQ()

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box sx={{ display: 'grid', gap: 4 }}>
          {data?.data.items &&
            data?.data.items.length > 0 &&
            data?.data.items.map((item) => {
              return <FAQItem key={item.id} item={item} />
            })}
        </Box>
      )}
    </>
  )
}
