import { tableAnatomy as parts } from '@chakra-ui/anatomy'

import { mode } from '@chakra-ui/theme-tools'
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleObject<typeof parts> = {
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderCollapse: 'collapse',
    width: 'full',
    maxW: '100%',
  },
  th: {
    fontFamily: 'heading',
    fontWeight: 'medium',
    textTransform: 'normal',
    letterSpacing: 'normal',
    textAlign: 'start',
  },
  td: {
    textAlign: 'start',
  },
  caption: {
    mt: 4,
    fontFamily: 'heading',
    textAlign: 'center',
    fontWeight: 'medium',
  },
}

const numericStyles: SystemStyleObject = {
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
}

const variantSimple: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c } = props

  return {
    table: {
      borderRadius: 'md',
      overflow: 'hidden',
    },
    th: {
      bg: mode('gray.100', 'gray.800')(props),
      color: mode('gray.700', 'gray.400')(props),
      borderBottom: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      ...numericStyles,
    },
    td: {
      bg: mode('white', 'gray.800')(props),
      borderBottom: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      ...numericStyles,
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  }
}

const variantStripe: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c } = props

  return {
    table: {
      borderRadius: 'md',
      boxShadow: 'card',
      overflow: 'hidden',
    },
    th: {
      bg: mode('gray.100', 'gray.800')(props),
      color: mode('gray.600', 'gray.400')(props),
      borderBottom: '0',
      borderColor: mode(`${c}.200`, `${c}.700`)(props),
      ...numericStyles,
    },
    td: {
      bg: mode('gray.50', 'gray.900')(props),
      borderBottom: '0',
      borderColor: mode(`${c}.200`, `${c}.700`)(props),
      ...numericStyles,
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          'th, td': {
            borderBottomWidth: '1px',
            borderColor: mode(`${c}.200`, `${c}.700`)(props),
          },
          td: {
            bg: mode(`white`, `${c}.800`)(props),
          },
        },
      },
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  }
}

const variants = {
  simple: variantSimple,
  striped: variantStripe,
  unstyled: {},
}

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    th: {
      px: 3,
      py: 1,
      lineHeight: '4',
      fontSize: 'xs',
    },
    td: {
      px: 3,
      py: 1,
      fontSize: 'sm',
      lineHeight: '4',
    },
    caption: {
      px: 3,
      py: 1,
      fontSize: 'xs',
    },
  },
  md: {
    th: {
      px: 4,
      py: 2,
      lineHeight: '4',
      fontSize: 'sm',
    },
    td: {
      px: 4,
      py: 2,
      lineHeight: '5',
    },
    caption: {
      px: 4,
      py: 2,
      fontSize: 'sm',
    },
  },
  lg: {
    th: {
      px: '8',
      py: '4',
      lineHeight: '5',
      fontSize: 'sm',
    },
    td: {
      px: '8',
      py: '5',
      lineHeight: '6',
    },
    caption: {
      px: '6',
      py: '2',
      fontSize: 'md',
    },
  },
}

const defaultProps = {
  variant: 'simple',
  size: 'md',
  colorScheme: 'gray',
}

export const Table = {
  parts: parts.keys,
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
