import { IUserItem } from './../model/interfaces/entities/IUser'

export const userInitialValues: IUserItem = {
  userType: 'ldap',
  username: '',
  firstName: '',
  lastName: '',
  country: 'Česko',
  street: '',
  streetNumber: '',
  city: '',
  zip: '',
  phone: '',
  email: '',
  role: 'basic',
  isActive: true,
  isAllowedInsurance: true,
  isAllowedInsuranceAnotherUser: false,
  personalNumbers: [],
}
