import { formAnatomy as parts } from '@chakra-ui/anatomy'
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyleRequiredIndicator: SystemStyleFunction = (props) => {
  return {
    marginStart: 1,
    color: mode('red.500', 'red.300')(props),
  }
}

const baseStyleHelperText: SystemStyleFunction = (props) => {
  return {
    mt: 0,
    color: mode('gray.600', 'whiteAlpha.600')(props),
    lineHeight: 'normal',
    fontSize: 'sm',
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: {
    width: 'auto',
    position: 'relative',
    display: 'grid',
    gap: 2,
  },
  requiredIndicator: baseStyleRequiredIndicator(props),
  helperText: baseStyleHelperText(props),
})

const variantDefault: PartsStyleFunction = (props) => {
  return {
    container: {
      width: 'auto',
      justifyItems: 'start',
      '& input': {
        width: 'auto',
      },
      '& select, & .chakra-select__wrapper': {
        width: 'auto',
      },
    },
  }
}

const variantFullWidth: PartsStyleFunction = (props) => {
  return {
    container: {
      width: '100%',
      '& input, & select, & textarea': {
        width: '100%',
      },
    },
  }
}

const variants = {
  default: variantDefault,
  fullWidth: variantFullWidth,
}

const defaultProps = {
  variant: 'default',
}

export const Form = {
  parts: parts.keys,
  baseStyle,
  variants,
  defaultProps,
}
