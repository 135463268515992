import { IUserRole } from './../../interfaces/entities/IUserRole'
import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const useGetUserRole = (id?: string) => {
  return useQuery([ApiRouteEnum.USER_ROLE, id], () => api.get<IUserRole[]>(ApiRouteEnum.USER_ROLE + "/" + id), {
    retry: false,
  })
}
