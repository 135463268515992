import { FormControl, Radio, RadioGroup, Stack, useToken } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { FormGroup } from '../../components/FormGroup'
import { FormLabel } from '../../components/FormLabel'
import { Hav, Liquidation, Pov } from '../../icons'

export const InsuranceType = () => {
  const [secondaryColor] = useToken('colors', ['secondary.500'])
  const { register } = useFormContext()

  return (
    <FormGroup size="lg">
      <FormControl>
        <Pov secondary={secondaryColor} width={8} height={8} sx={{ mx: 'auto', mb: 2 }} />

        <FormLabel htmlFor="pov" tooltip="Popis povinného ručení">
          Povinné ručení
        </FormLabel>
        <RadioGroup>
          <Stack direction="column" spacing={4}>
            <Radio value="1" {...register('pov')}>
              Bez POV
            </Radio>
            <Radio value="2" {...register('pov')}>
              150/150 +
            </Radio>
            <Radio value="3" {...register('pov')}>
              100/100 +
            </Radio>
            <Radio value="4" {...register('pov')}>
              50/50 +
            </Radio>
            <Radio value="5" {...register('pov')}>
              35/35 +
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl>
        <Hav secondary={secondaryColor} width={8} height={8} sx={{ mx: 'auto', mb: 2 }} />

        <FormLabel htmlFor="hav" tooltip="Popis havarijního ručení">
          Havarijní pojištění
        </FormLabel>
        <RadioGroup>
          <Stack direction="column" spacing={4}>
            <Radio value="1" {...register('hav')}>
              Bez HAV
            </Radio>
            <Radio value="2" {...register('hav')}>
              Allrisk
            </Radio>
            <Radio value="3" {...register('hav')}>
              Allrisk + GAP
            </Radio>
            <Radio value="4" {...register('hav')}>
              Havárie
            </Radio>
            <Radio value="5" {...register('hav')}>
              Odcizení
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl>
        <Liquidation secondary={secondaryColor} width={8} height={8} sx={{ mx: 'auto', mb: 2 }} />

        <FormLabel htmlFor="participation" tooltip="Popis spoluúčasti">
          Spoluúčast
        </FormLabel>
        <RadioGroup>
          <Stack direction="column" spacing={4}>
            <Radio value="1" {...register('participation')}>
              do 5 %, min. 5 000 Kč
            </Radio>
            <Radio value="2" {...register('participation')}>
              5 %, min. 5 000 Kč
            </Radio>
            <Radio value="3" {...register('participation')}>
              10 %, min. 10 000 Kč
            </Radio>
            <Radio value="4" {...register('participation')}>
              nad 10 %, min. 10 000 Kč
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </FormGroup>
  )
}
