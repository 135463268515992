import { useNavigate } from 'react-router-dom'

import { Container } from '../../../components/Container'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { Step4Form, Step4FormValues } from '../../../containers/carkulka/Step4Form'
import { InsuranceInfo } from '../../../containers/InsuranceInfo'

export const Step4 = () => {
  const navigate = useNavigate()

  const handleSubmit = (values: Step4FormValues) => {
    navigate('/carkulka/wizard/5')
  }

  return (
    <Section>
      <Container>
        <StepsContent>
          <Steps icon="car" currentStep={4} />

          <InsuranceInfo
            offerId="16834"
            startDate={new Date(2022, 11, 31)}
            insureeDetails="Jan Novák, Hlavní třída 667, 708 00 Ostrava-Poruba"
            subjectDetails="SKODA OCTAVIA COMBI III DIESEL 2.0 TDI 135 kW 4x4 DSG L&amp;K Combi"
            additionalInfo="VIN: TMBLK9NE4J0338194, Roční nájezd: 15 000 km"
            broker={{
              broker: { name: 'Ondřej Vaverka', link: '#', email: 'ondrej.vaverka@okklient.cz' },
              company: { name: 'OK KLIENT a.s.', link: '#' },
              date: new Date(2022, 10, 12, 14, 14, 26),
            }}
          />

          <Step4Form onSubmit={handleSubmit} />
        </StepsContent>
      </Container>
    </Section>
  )
}
