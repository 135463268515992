import { useMutation } from '@tanstack/react-query'
import api from '../api'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const useSetPassword = (onSuccess: (data: any) => void, onError: (data: any) => void) => {
  return useMutation(
    ({ hash, password }: { hash: string; password: string }) =>
      api.patch(`${ApiRouteEnum.USER}/${hash}/password`, { password }),
    {
      mutationKey: [ApiRouteEnum.USER, 'setPassword'],
      onSuccess,
      onError,
    },
  )
}
