import React from 'react'
import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react'

interface FormGroupProps extends BoxProps {
  children: React.ReactNode
  variant?: string
  size?: string
}

export const FormGroup = ({
  children,
  variant = 'default',
  size = 'md',
  sx = {},
  ...rest
}: FormGroupProps) => {
  const styles = useStyleConfig('FormGroup', { variant, size })

  return (
    <Box __css={styles} sx={sx} {...rest}>
      {children}
    </Box>
  )
}
