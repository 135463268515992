import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import { Avatar, Box, Button, Flex, Heading, useToast } from '@chakra-ui/react'

import { ArrowLeft, Building, Check, Edit, Email, Lock, Mobile } from '../../../icons'
import { UserInfo, UserInfoWrapper } from './UserInfo'
import { UserStatus } from './UserInsuranceAllowed'
import { UserInsuranceAllowed } from './UserInsuranceStatus'
import { IProfile } from '../../../data/profile'
import {
  useIsBasic,
  useIsFull,
  useIsSupervisorOrAdmin,
  useLogout,
} from '../../../model/utils/userUtils'
import { useResetPassword } from '../../../model/api/user/useResetPassword'
import React, { useState } from 'react'

interface IUserHeaderProps {
  fullName: string | undefined
  email: string | undefined
  phone: string | undefined
  address: string | undefined
  status: IProfile['status']
  insuranceAllowed: boolean
  isCurrent?: boolean
  canResetPassword?: boolean
  parentId?: number
}

export const UserHeader = ({
  fullName,
  email,
  phone,
  address,
  status,
  insuranceAllowed,
  isCurrent = false,
  canResetPassword = false,
  parentId,
}: IUserHeaderProps) => {
  const toast = useToast()
  const { id } = useParams<'id'>()
  const isSupervisor = useIsSupervisorOrAdmin()
  const isEditable = isCurrent || isSupervisor
  const [justResetPassword, setJustResetPassword] = useState<boolean>(false)
  const userIsSupervisor = useIsSupervisorOrAdmin()
  const userIsFull = useIsFull()
  const userIsBasic = useIsBasic()

  const navigate = useNavigate()
  const logout = useLogout()

  const { mutate: resetPassword, isLoading: isResettingPassword } = useResetPassword(
    () => {
      setJustResetPassword(true)
      toast({
        title: 'Na email uživatele byly odeslány instrukce ke změně hesla',
        status: 'success',
        position: 'top',
        duration: 5000,
        isClosable: true,
      })
    },
    (error) => {
      setJustResetPassword(false)
      toast({
        title: 'Nepodařilo se odeslat email o změně',
        description: error?.data?.error,
        status: 'error',
        position: 'top',
      })
    },
  )

  return (
    <Flex sx={{ gap: 4, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
      <Flex sx={{ gap: 4, alignItems: 'start' }}>
        <Avatar name={fullName} colorScheme="green" size="lg" />

        <Box sx={{ display: 'grid', columnGap: 1, rowGap: 4 }}>
          <Flex sx={{ columnGap: 4, rowGap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
            <Heading as="h1" sx={{ fontSize: '3xl' }}>
              {fullName}
            </Heading>

            <Flex sx={{ gap: 2 }}>
              <UserStatus status={status} />
              <UserInsuranceAllowed allowed={insuranceAllowed} />
            </Flex>
          </Flex>

          <UserInfoWrapper>
            {email && (
              <UserInfo>
                <Email color="secondary.500" />
                {email}
              </UserInfo>
            )}
            {phone && (
              <UserInfo>
                <Mobile color="secondary.500" />
                {phone}
              </UserInfo>
            )}
            {address && (
              <UserInfo>
                <Building color="secondary.500" />
                {address}
              </UserInfo>
            )}
          </UserInfoWrapper>
        </Box>
      </Flex>

      {isEditable && (
        <Flex sx={{ alignItems: 'center', gap: 4 }}>
          {isCurrent && (
            <Button variant="ghost" onClick={logout}>
              Odhlásit se
            </Button>
          )}
          {canResetPassword && (
            <Button
              type="button"
              variant="outline"
              onClick={() => resetPassword(id!)}
              isLoading={isResettingPassword}
              isDisabled={justResetPassword}
            >
              {justResetPassword ? (
                <>
                  <Check color="green.500" />
                  Změna hesla odeslána
                </>
              ) : (
                <>
                  <Lock color="secondary.500" />
                  Změnit heslo
                </>
              )}
            </Button>
          )}
          {userIsBasic === false && (
            <Button variant="ghost" onClick={() => navigate(`/organization/detail/${parentId}`)}>
              <ArrowLeft color="secondary.500" />
              Zpět
            </Button>
          )}
          <Button
            hidden={!userIsSupervisor && !(userIsFull && isCurrent)}
            colorScheme="primary"
            as={RouterLink}
            to={`/user/edit/${id}`}
          >
            <Edit />
            Upravit uživatele
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
