import { Badge, Button, Flex, Heading, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'

import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { PageContent } from '../../containers/PageContent'
import { PageToolbar } from '../../containers/PageToolbar'
import { ArrowLeft, ChevronRight, Edit } from '../../icons'
import { organizations } from '../../data/organizations'
import { OrganizationDetail } from '../../containers/organization/OrganizationDetail'
import { useEffect } from 'react'
import { Loading } from '../../components/Loading'
import { useGetOrganizationsId } from '../../model/api/organization/useGetOrganizationId'
import { useIsSupervisorOrAdmin } from '../../model/utils/userUtils'

export const OrganizationDetailPage = () => {
  const organization = organizations.find((organization) => organization.id === 1)
  const navigate = useNavigate()
  const toastMessage = useToast()
  const { id } = useParams<'id'>()
  const { data, isLoading, isError, error } = useGetOrganizationsId(id)
  const userIsSupervisor = useIsSupervisorOrAdmin()
  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  const parentId = (data?.data.parent as unknown as { id: number } | undefined)?.id
  const parentName = (data?.data.parent as unknown as { username: string } | undefined)?.username

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Section>
          <Container>
            <PageContent>
              <PageToolbar
                leftElements={
                  <Flex sx={{ gap: 4, alignItems: 'center' }}>
                    {typeof parentId !== 'undefined' && (
                      <Heading
                        as="h1"
                        cursor="pointer"
                        onClick={() => navigate(`/organization/detail/${parentId}`)}
                      >
                        {parentName} &nbsp;
                        <ChevronRight />
                      </Heading>
                    )}
                    <Heading as="h1">{data?.data.username}</Heading>
                    <Badge colorScheme="blue">{data?.data.role}</Badge>
                  </Flex>
                }
                rightElements={
                  <Flex sx={{ gap: 4 }}>
                    <Button variant="ghost" onClick={() => navigate('/organization/list')}>
                      <ArrowLeft color="secondary.500" />
                      Zpět do seznamu organizací
                    </Button>
                    <Button
                      hidden={!userIsSupervisor}
                      colorScheme="primary"
                      onClick={() => navigate(`/organization/edit/${id}`)}
                    >
                      <Edit />
                      Upravit organizaci
                    </Button>
                  </Flex>
                }
              />

              {organization && <OrganizationDetail organization={organization} />}
            </PageContent>
          </Container>
        </Section>
      )}
    </>
  )
}
