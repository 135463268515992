import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IMenu } from '../../interfaces/entities/IMenu'

export const useGetUserCurrent = () => {
  return useQuery([ApiRouteEnum.USER_CURRENT], () => api.get<IMenu>(ApiRouteEnum.USER_CURRENT), {
    retry: false,
  })
}
