import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import * as Yup from 'yup'
import IModalEmailFormValues from '../model/interfaces/entities/IModalEmailFormValues'
import { Loading } from './Loading'
import useEmailTemplate from '../model/api/emailForm/useEmailTemplate'

interface ModalEmailFormProps {
  insureeEmail?: string
  onMailMutate: (values: IModalEmailFormValues) => void
}

function ModalEmailForm({ insureeEmail, onMailMutate }: ModalEmailFormProps) {
  const handleSubmit = (values: IModalEmailFormValues) => {
    onMailMutate(values)
  }

  const { data, isLoading } = useEmailTemplate()

  if (isLoading) {
    return <Loading />
  }

  return (
    <Formik
      initialValues={{
        email: insureeEmail ? insureeEmail : '',
        headerText: data?.data?.headerText,
        bodyText: data?.data?.bodyText,
      }}
      validationSchema={modalEmailValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Flex flexFlow="column nowrap" justifyContent="space-between" alignItems="center">
          <Field name="email">
            {({ field, form }: FieldProps<string>) => (
              <FormControl isInvalid={!!(form.errors.email && form.touched.emai)} mb={4} w="100%">
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input {...field} id="email" placeholder="Zadejte email" w="100%" />
                <FormErrorMessage>{String(form.errors.email)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="headerText">
            {({ field, form }: FieldProps<string>) => (
              <FormControl
                isInvalid={!!(form.errors.headerText && form.touched.headerText)}
                mb={4}
                w="100%"
              >
                <FormLabel htmlFor="headerText">Hlavička</FormLabel>
                <Textarea {...field} id="headerText" placeholder="Zadejte hlavičku" w="full" />
                <FormErrorMessage>{String(form.errors.headerText)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="bodyText">
            {({ field, form }: FieldProps<string>) => (
              <FormControl
                isInvalid={!!(form.errors.bodyText && form.touched.bodyText)}
                mb={4}
                w="100%"
              >
                <FormLabel htmlFor="bodyText">Obsah</FormLabel>
                <Textarea
                  {...field}
                  id="bodyText"
                  placeholder="Zadejte volitelný obsah"
                  w="full"
                  rows={8}
                />
                <FormErrorMessage>{String(form.errors.bodyText)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Button mt={4} colorScheme="teal" type="submit">
            Odeslat
          </Button>
        </Flex>
      </Form>
    </Formik>
  )
}

const modalEmailValidationSchema = Yup.object().shape({
  email: Yup.string().email('Neplatná adresa').required('Zadejte email'),
})

export default ModalEmailForm
