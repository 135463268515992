import { Icon } from '@chakra-ui/react'

export const Car = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 28 28" fill="none">
<path d="M14 3.5H10C9.16667 3.5 7.4 4 7 6C6.81073 6.94635 6.614 7.93002 6.44158 8.79209M9 20.5V22.5C9 23 8.5 24 7 24C5.5 24 7.6 24 6 24C4.4 24 4 23 4 22.5C4 21.9673 4 21.2734 4 20.5M9 20.5H14M9 20.5H4M6.44158 8.79209C6.24962 9.7519 6.0878 10.561 6 11C5.33333 11 4 11.5 4 13.5C4 15.0083 4 18.1279 4 20.5M6.44158 8.79209H4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 17H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14 3.5H18C18.8333 3.5 20.6 4 21 6C21.1893 6.94635 21.386 7.93002 21.5584 8.79209M22 11C22.6667 11 24 11.5 24 13.5C24 15.0083 24 18.1279 24 20.5M22 11C21.9122 10.561 21.7504 9.7519 21.5584 8.79209M22 11H6M19 20.5V22.5C19 23 19.5 24 21 24C22.5 24 20.4 24 22 24C23.6 24 24 23 24 22.5C24 21.9673 24 21.2734 24 20.5M19 20.5H14M19 20.5H24M21.5584 8.79209H24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<circle cx="8" cy="15" r="0.75" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
<circle cx="20" cy="15" r="0.75" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
	</Icon>
)


export const CarString=`<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 3.5H10C9.16667 3.5 7.4 4 7 6C6.81073 6.94635 6.614 7.93002 6.44158 8.79209M9 20.5V22.5C9 23 8.5 24 7 24C5.5 24 7.6 24 6 24C4.4 24 4 23 4 22.5C4 21.9673 4 21.2734 4 20.5M9 20.5H14M9 20.5H4M6.44158 8.79209C6.24962 9.7519 6.0878 10.561 6 11C5.33333 11 4 11.5 4 13.5C4 15.0083 4 18.1279 4 20.5M6.44158 8.79209H4" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 17H16" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 3.5H18C18.8333 3.5 20.6 4 21 6C21.1893 6.94635 21.386 7.93002 21.5584 8.79209M22 11C22.6667 11 24 11.5 24 13.5C24 15.0083 24 18.1279 24 20.5M22 11C21.9122 10.561 21.7504 9.7519 21.5584 8.79209M22 11H6M19 20.5V22.5C19 23 19.5 24 21 24C22.5 24 20.4 24 22 24C23.6 24 24 23 24 22.5C24 21.9673 24 21.2734 24 20.5M19 20.5H14M19 20.5H24M21.5584 8.79209H24" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="8" cy="15" r="0.75" fill="#EE7400" stroke="#EE7400" stroke-width="0.5"/>
<circle cx="20" cy="15" r="0.75" fill="#EE7400" stroke="#EE7400" stroke-width="0.5"/>
</svg>
`;