import { Box } from '@chakra-ui/react'

const Passes = ({ partially = false }: { partially?: boolean }) => (
  <Box
    sx={{
      px: 2,
      py: 1,
      fontSize: 'xs',
      bg: !partially ? 'green.50' : 'yellow.50',
      borderBlockEnd: '1px solid',
      borderBlockEndColor: !partially ? 'green.200' : 'yellow.200',
    }}
  >
    {!partially
      ? 'Nabídky vyhovující všem vybraným parametrům'
      : 'Nabídky vyhovující některým parametrům'}
  </Box>
)

export const OfferCompleteness = ({ passed }: { passed: 'fully' | 'partially' }) => {
  return <Passes partially={passed === 'partially'} />
}
