import { Icon } from '@chakra-ui/react'

export const Calculate = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M8 11C8 11.2652 7.89464 11.5196 7.70711 11.7071C7.51957 11.8946 7.26522 12 7 12C6.73478 12 6.48043 11.8946 6.29289 11.7071C6.10536 11.5196 6 11.2652 6 11C6 10.7348 6.10536 10.4804 6.29289 10.2929C6.48043 10.1054 6.73478 10 7 10C7.26522 10 7.51957 10.1054 7.70711 10.2929C7.89464 10.4804 8 10.7348 8 11V11Z" fill="currentColor"/>
<path d="M8 14C8 14.2652 7.89464 14.5196 7.70711 14.7071C7.51957 14.8946 7.26522 15 7 15C6.73478 15 6.48043 14.8946 6.29289 14.7071C6.10536 14.5196 6 14.2652 6 14C6 13.7348 6.10536 13.4804 6.29289 13.2929C6.48043 13.1054 6.73478 13 7 13C7.26522 13 7.51957 13.1054 7.70711 13.2929C7.89464 13.4804 8 13.7348 8 14V14Z" fill="currentColor"/>
<path d="M13 12C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11C14 10.7348 13.8946 10.4804 13.7071 10.2929C13.5196 10.1054 13.2652 10 13 10C12.7348 10 12.4804 10.1054 12.2929 10.2929C12.1054 10.4804 12 10.7348 12 11C12 11.2652 12.1054 11.5196 12.2929 11.7071C12.4804 11.8946 12.7348 12 13 12V12Z" fill="currentColor"/>
<path d="M14 14C14 14.2652 13.8946 14.5196 13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15C12.7348 15 12.4804 14.8946 12.2929 14.7071C12.1054 14.5196 12 14.2652 12 14C12 13.7348 12.1054 13.4804 12.2929 13.2929C12.4804 13.1054 12.7348 13 13 13C13.2652 13 13.5196 13.1054 13.7071 13.2929C13.8946 13.4804 14 13.7348 14 14V14Z" fill="currentColor"/>
<path d="M10 12C10.2652 12 10.5196 11.8946 10.7071 11.7071C10.8946 11.5196 11 11.2652 11 11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11C9 11.2652 9.10536 11.5196 9.29289 11.7071C9.48043 11.8946 9.73478 12 10 12V12Z" fill="currentColor"/>
<path d="M11 14C11 14.2652 10.8946 14.5196 10.7071 14.7071C10.5196 14.8946 10.2652 15 10 15C9.73478 15 9.48043 14.8946 9.29289 14.7071C9.10536 14.5196 9 14.2652 9 14C9 13.7348 9.10536 13.4804 9.29289 13.2929C9.48043 13.1054 9.73478 13 10 13C10.2652 13 10.5196 13.1054 10.7071 13.2929C10.8946 13.4804 11 13.7348 11 14V14Z" fill="currentColor"/>
<path d="M7.5 4C7.10218 4 6.72064 4.15804 6.43934 4.43934C6.15804 4.72064 6 5.10218 6 5.5V6.5C6 6.89782 6.15804 7.27936 6.43934 7.56066C6.72064 7.84196 7.10218 8 7.5 8H12.5C12.8978 8 13.2794 7.84196 13.5607 7.56066C13.842 7.27936 14 6.89782 14 6.5V5.5C14 5.10218 13.842 4.72064 13.5607 4.43934C13.2794 4.15804 12.8978 4 12.5 4H7.5ZM7 5.5C7 5.36739 7.05268 5.24021 7.14645 5.14645C7.24021 5.05268 7.36739 5 7.5 5H12.5C12.6326 5 12.7598 5.05268 12.8536 5.14645C12.9473 5.24021 13 5.36739 13 5.5V6.5C13 6.63261 12.9473 6.75979 12.8536 6.85355C12.7598 6.94732 12.6326 7 12.5 7H7.5C7.36739 7 7.24021 6.94732 7.14645 6.85355C7.05268 6.75979 7 6.63261 7 6.5V5.5Z" fill="currentColor"/>
<path d="M16 15.5C16 16.163 15.7366 16.7989 15.2678 17.2678C14.7989 17.7366 14.163 18 13.5 18H6.5C5.83696 18 5.20107 17.7366 4.73223 17.2678C4.26339 16.7989 4 16.163 4 15.5V4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2H13.5C14.163 2 14.7989 2.26339 15.2678 2.73223C15.7366 3.20107 16 3.83696 16 4.5V15.5ZM15 4.5C15 4.10218 14.842 3.72064 14.5607 3.43934C14.2794 3.15804 13.8978 3 13.5 3H6.5C6.10218 3 5.72064 3.15804 5.43934 3.43934C5.15804 3.72064 5 4.10218 5 4.5V15.5C5 15.8978 5.15804 16.2794 5.43934 16.5607C5.72064 16.842 6.10218 17 6.5 17H13.5C13.8978 17 14.2794 16.842 14.5607 16.5607C14.842 16.2794 15 15.8978 15 15.5V4.5Z" fill="currentColor"/>
	</Icon>
)


export const CalculateString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C8 11.2652 7.89464 11.5196 7.70711 11.7071C7.51957 11.8946 7.26522 12 7 12C6.73478 12 6.48043 11.8946 6.29289 11.7071C6.10536 11.5196 6 11.2652 6 11C6 10.7348 6.10536 10.4804 6.29289 10.2929C6.48043 10.1054 6.73478 10 7 10C7.26522 10 7.51957 10.1054 7.70711 10.2929C7.89464 10.4804 8 10.7348 8 11V11Z" fill="#003D66"/>
<path d="M8 14C8 14.2652 7.89464 14.5196 7.70711 14.7071C7.51957 14.8946 7.26522 15 7 15C6.73478 15 6.48043 14.8946 6.29289 14.7071C6.10536 14.5196 6 14.2652 6 14C6 13.7348 6.10536 13.4804 6.29289 13.2929C6.48043 13.1054 6.73478 13 7 13C7.26522 13 7.51957 13.1054 7.70711 13.2929C7.89464 13.4804 8 13.7348 8 14V14Z" fill="#003D66"/>
<path d="M13 12C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11C14 10.7348 13.8946 10.4804 13.7071 10.2929C13.5196 10.1054 13.2652 10 13 10C12.7348 10 12.4804 10.1054 12.2929 10.2929C12.1054 10.4804 12 10.7348 12 11C12 11.2652 12.1054 11.5196 12.2929 11.7071C12.4804 11.8946 12.7348 12 13 12V12Z" fill="#003D66"/>
<path d="M14 14C14 14.2652 13.8946 14.5196 13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15C12.7348 15 12.4804 14.8946 12.2929 14.7071C12.1054 14.5196 12 14.2652 12 14C12 13.7348 12.1054 13.4804 12.2929 13.2929C12.4804 13.1054 12.7348 13 13 13C13.2652 13 13.5196 13.1054 13.7071 13.2929C13.8946 13.4804 14 13.7348 14 14V14Z" fill="#003D66"/>
<path d="M10 12C10.2652 12 10.5196 11.8946 10.7071 11.7071C10.8946 11.5196 11 11.2652 11 11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11C9 11.2652 9.10536 11.5196 9.29289 11.7071C9.48043 11.8946 9.73478 12 10 12V12Z" fill="#003D66"/>
<path d="M11 14C11 14.2652 10.8946 14.5196 10.7071 14.7071C10.5196 14.8946 10.2652 15 10 15C9.73478 15 9.48043 14.8946 9.29289 14.7071C9.10536 14.5196 9 14.2652 9 14C9 13.7348 9.10536 13.4804 9.29289 13.2929C9.48043 13.1054 9.73478 13 10 13C10.2652 13 10.5196 13.1054 10.7071 13.2929C10.8946 13.4804 11 13.7348 11 14V14Z" fill="#003D66"/>
<path d="M7.5 4C7.10218 4 6.72064 4.15804 6.43934 4.43934C6.15804 4.72064 6 5.10218 6 5.5V6.5C6 6.89782 6.15804 7.27936 6.43934 7.56066C6.72064 7.84196 7.10218 8 7.5 8H12.5C12.8978 8 13.2794 7.84196 13.5607 7.56066C13.842 7.27936 14 6.89782 14 6.5V5.5C14 5.10218 13.842 4.72064 13.5607 4.43934C13.2794 4.15804 12.8978 4 12.5 4H7.5ZM7 5.5C7 5.36739 7.05268 5.24021 7.14645 5.14645C7.24021 5.05268 7.36739 5 7.5 5H12.5C12.6326 5 12.7598 5.05268 12.8536 5.14645C12.9473 5.24021 13 5.36739 13 5.5V6.5C13 6.63261 12.9473 6.75979 12.8536 6.85355C12.7598 6.94732 12.6326 7 12.5 7H7.5C7.36739 7 7.24021 6.94732 7.14645 6.85355C7.05268 6.75979 7 6.63261 7 6.5V5.5Z" fill="#003D66"/>
<path d="M16 15.5C16 16.163 15.7366 16.7989 15.2678 17.2678C14.7989 17.7366 14.163 18 13.5 18H6.5C5.83696 18 5.20107 17.7366 4.73223 17.2678C4.26339 16.7989 4 16.163 4 15.5V4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2H13.5C14.163 2 14.7989 2.26339 15.2678 2.73223C15.7366 3.20107 16 3.83696 16 4.5V15.5ZM15 4.5C15 4.10218 14.842 3.72064 14.5607 3.43934C14.2794 3.15804 13.8978 3 13.5 3H6.5C6.10218 3 5.72064 3.15804 5.43934 3.43934C5.15804 3.72064 5 4.10218 5 4.5V15.5C5 15.8978 5.15804 16.2794 5.43934 16.5607C5.72064 16.842 6.10218 17 6.5 17H13.5C13.8978 17 14.2794 16.842 14.5607 16.5607C14.842 16.2794 15 15.8978 15 15.5V4.5Z" fill="#003D66"/>
</svg>
`;