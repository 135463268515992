import { forwardRef, Select, SelectProps, Spinner, useToast } from '@chakra-ui/react'
import { useGetCompanies } from '../model/api/company/useGetCompany'
import { useEffect } from 'react'

interface CompaniesSelectProps extends SelectProps {}

export const CompaniesSelect = forwardRef((props: CompaniesSelectProps, ref) => {
  const { data, isLoading, isError, error } = useGetCompanies()
  const toastMessage = useToast()

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Error',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Select {...props} ref={ref}>
          <option value="">Všechny pojišťovny</option>
          {data?.data &&
            data?.data.length > 0 &&
            data?.data.map((company) => {
              return (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              )
            })}
        </Select>
      )}
    </>
  )
})
