import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { OrganizationEditFormValues } from '../../interfaces/entities/IOrganizationEditFormValues'

export const usePostOrganizations = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
) => {
  return useMutation(
    (organizationData: OrganizationEditFormValues) =>
      api.post(ApiRouteEnum.ORGANIZATION, organizationData),
    {
      mutationKey: [ApiRouteEnum.ORGANIZATION],
      onSuccess: (data) => {
        successCallBack(data.data)
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
