import { Link as RouterLink } from 'react-router-dom'
import { Button } from '@chakra-ui/react'

import { useApp } from '../context/AppContext'
import { Add } from '../icons'

export const NewInsuranceLink = () => {
  const { app } = useApp()

  return (
    <Button
      as={RouterLink}
      variant="solid"
      colorScheme="primary"
      to={`/${app}/wizard/create/1`}
      sx={{ display: { base: 'flex', xl: 'none' } }} // hide on xl screens
    >
      <Add />
      Nové pojištění
    </Button>
  )
}
