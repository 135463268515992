import React from 'react'
import { IUserItem } from '../model/interfaces/entities/IUser'
import moment from 'moment'
import { Link } from '@chakra-ui/react'
import { Link as RouterDomLink } from 'react-router-dom'

interface Props {
  user: IUserItem
  dateCreated?: string
}

export const UserLink: React.FC<Props> = ({ user, dateCreated }) => {
  return (
    <>
      <Link>
        <RouterDomLink to={`/user/detail/${user.id}`}>
          {`${user.firstName} ${user.lastName}`}
        </RouterDomLink>
      </Link>
      {user?.parent && (
        <Link>
          <RouterDomLink to={`/organization/detail/${user.parent.id}`}>
            , {`${user.parent.username}`}
          </RouterDomLink>
        </Link>
      )}
      {user.email.length > 0 ? <span>, <Link href={`mailto:${user.email}`}>{user.email}</Link></span> : null}
      {dateCreated && <>, {moment(dateCreated).format('DD.MM.Y HH:mm')}</>}
    </>
  )
}
