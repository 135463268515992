import { Button, Flex, Heading, Spinner, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from '../../components/Container'
import { Pagination } from '../../components/Pagination'
import { Section } from '../../components/Section'
import { NewsFilterForm } from '../../containers/news/NewsFilterForm'
import { NewsGrid } from '../../containers/news/NewsGrid'
import { PageContent } from '../../containers/PageContent'
import { PageToolbar } from '../../containers/PageToolbar'
import { Edit } from '../../icons'
import { useGetNews } from '../../model/api/news/useGetNews'
import { IQueryParamsNews } from '../../model/interfaces/entities/INews'
import { calculateTotalPages } from '../../utils/calculateTotalPages'
import { useIsSupervisor } from '../../model/utils/userUtils'

export const NewsListPage = () => {
  const [queryParams, setQueryParams] = useState<IQueryParamsNews>({
    companyId: undefined,
    unreadOnly: undefined,
    priority: undefined,
    limit: 12,
    page: 1,
  })
  const toastMessage = useToast()
  const { data, isError, error, refetch } = useGetNews(queryParams)
  const navigate = useNavigate()
  const [startPage, setStartPage] = useState<number>(1)
  const [endPage, setEndPage] = useState<number>(10)
  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Error',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  useEffect(() => {
    refetch()
  }, [queryParams])

  const isSupervisor = useIsSupervisor()

  return (
    <Section>
      <Container>
        <PageContent>
          <PageToolbar
            leftElements={
              <Flex sx={{ gap: 4, alignItems: 'center' }}>
                <Heading as="h1">Aktuality</Heading>
              </Flex>
            }
            rightElements={
              <Flex sx={{ gap: 4 }}>
                {isSupervisor && (
                  <Button colorScheme="primary" onClick={() => navigate('/news/admin')}>
                    <Edit />
                    Upravit aktuality
                  </Button>
                )}
              </Flex>
            }
          />

          <NewsFilterForm queryParams={queryParams} setQueryParams={setQueryParams} />

          {data ? <NewsGrid news={data.data.items} /> : <Spinner />}

          <Pagination
            totalPages={calculateTotalPages(data?.data.count, 10)}
            currentPage={queryParams.page}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            startPage={startPage}
            endPage={endPage}
            onSetStartPage={setStartPage}
            onSetEndPage={setEndPage}
          />
        </PageContent>
      </Container>
    </Section>
  )
}
