import { Icon } from '@chakra-ui/react'

export const InsuranceGlassDamage = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M4 4H44V44H4V4Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M8 8H40V40H8V8Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M24 18.1111L18.5 23.2222M32.25 18.1111L18.5 30.8889" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
)


export const InsuranceGlassDamageString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 4H44V44H4V4Z" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M8 8H40V40H8V8Z" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M24 18.1111L18.5 23.2222M32.25 18.1111L18.5 30.8889" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;