import { Icon } from '@chakra-ui/react'

export const InsurancePropertyOwnershipLiability = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M41 11C32.6 11 26.1667 7.33333 24 5.5C17.6 10.3 10 11.1667 7 11V21C7 34.6 18.3333 41.3333 24 43C37.6 39 41 26.6667 41 21V11Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M23.249 16.36C23.5395 16.127 23.9007 16 24.2731 16C24.6454 16 25.0067 16.127 25.2971 16.36L30.9337 20.8824C31.125 21.0358 31.2793 21.2302 31.3853 21.4513C31.4913 21.6723 31.5462 21.9144 31.5461 22.1596V30.3636C31.5461 30.7976 31.3737 31.2138 31.0668 31.5207C30.7599 31.8276 30.3437 32 29.9097 32H27.7278C27.2938 32 26.8775 31.8276 26.5706 31.5207C26.2637 31.2138 26.0913 30.7976 26.0913 30.3636V25.9997C26.0913 25.8551 26.0339 25.7163 25.9316 25.614C25.8293 25.5117 25.6905 25.4542 25.5458 25.4542H23.0003C22.8556 25.4542 22.7169 25.5117 22.6146 25.614C22.5123 25.7163 22.4548 25.8551 22.4548 25.9997V30.3636C22.4548 30.7976 22.2824 31.2138 21.9755 31.5207C21.6686 31.8276 21.2524 32 20.8184 32H18.6364C18.2024 32 17.7862 31.8276 17.4793 31.5207C17.1724 31.2138 17 30.7976 17 30.3636V22.1596C17 21.6635 17.2255 21.1937 17.6124 20.8831L23.249 16.3607V16.36ZM24.6142 17.211C24.5174 17.1334 24.3971 17.0912 24.2731 17.0912C24.149 17.0912 24.0287 17.1334 23.932 17.211L18.2953 21.7333C18.2316 21.7844 18.1801 21.8492 18.1447 21.9228C18.1094 21.9965 18.091 22.0771 18.091 22.1588V30.3628C18.091 30.6647 18.3353 30.9083 18.6364 30.9083H20.8184C20.963 30.9083 21.1018 30.8508 21.2041 30.7485C21.3064 30.6462 21.3638 30.5075 21.3638 30.3628V25.999C21.3638 25.565 21.5362 25.1488 21.8431 24.8419C22.15 24.535 22.5663 24.3626 23.0003 24.3626H25.5458C25.7608 24.3626 25.9737 24.4049 26.1723 24.4872C26.3708 24.5695 26.5513 24.6901 26.7032 24.8421C26.8552 24.9942 26.9757 25.1746 27.0579 25.3733C27.1401 25.5719 27.1824 25.7848 27.1823 25.9997V30.3636C27.1823 30.6654 27.4267 30.909 27.7278 30.909H29.9097C30.0544 30.909 30.1931 30.8516 30.2954 30.7493C30.3977 30.647 30.4552 30.5082 30.4552 30.3636V22.1596C30.4552 22.0779 30.437 21.9973 30.4017 21.9237C30.3665 21.85 30.3151 21.7852 30.2515 21.7341L24.6149 17.2117L24.6142 17.211Z" fill="currentColor"/>
	</Icon>
)


export const InsurancePropertyOwnershipLiabilityString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41 11C32.6 11 26.1667 7.33333 24 5.5C17.6 10.3 10 11.1667 7 11V21C7 34.6 18.3333 41.3333 24 43C37.6 39 41 26.6667 41 21V11Z" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M23.249 16.36C23.5395 16.127 23.9007 16 24.2731 16C24.6454 16 25.0067 16.127 25.2971 16.36L30.9337 20.8824C31.125 21.0358 31.2793 21.2302 31.3853 21.4513C31.4913 21.6723 31.5462 21.9144 31.5461 22.1596V30.3636C31.5461 30.7976 31.3737 31.2138 31.0668 31.5207C30.7599 31.8276 30.3437 32 29.9097 32H27.7278C27.2938 32 26.8775 31.8276 26.5706 31.5207C26.2637 31.2138 26.0913 30.7976 26.0913 30.3636V25.9997C26.0913 25.8551 26.0339 25.7163 25.9316 25.614C25.8293 25.5117 25.6905 25.4542 25.5458 25.4542H23.0003C22.8556 25.4542 22.7169 25.5117 22.6146 25.614C22.5123 25.7163 22.4548 25.8551 22.4548 25.9997V30.3636C22.4548 30.7976 22.2824 31.2138 21.9755 31.5207C21.6686 31.8276 21.2524 32 20.8184 32H18.6364C18.2024 32 17.7862 31.8276 17.4793 31.5207C17.1724 31.2138 17 30.7976 17 30.3636V22.1596C17 21.6635 17.2255 21.1937 17.6124 20.8831L23.249 16.3607V16.36ZM24.6142 17.211C24.5174 17.1334 24.3971 17.0912 24.2731 17.0912C24.149 17.0912 24.0287 17.1334 23.932 17.211L18.2953 21.7333C18.2316 21.7844 18.1801 21.8492 18.1447 21.9228C18.1094 21.9965 18.091 22.0771 18.091 22.1588V30.3628C18.091 30.6647 18.3353 30.9083 18.6364 30.9083H20.8184C20.963 30.9083 21.1018 30.8508 21.2041 30.7485C21.3064 30.6462 21.3638 30.5075 21.3638 30.3628V25.999C21.3638 25.565 21.5362 25.1488 21.8431 24.8419C22.15 24.535 22.5663 24.3626 23.0003 24.3626H25.5458C25.7608 24.3626 25.9737 24.4049 26.1723 24.4872C26.3708 24.5695 26.5513 24.6901 26.7032 24.8421C26.8552 24.9942 26.9757 25.1746 27.0579 25.3733C27.1401 25.5719 27.1824 25.7848 27.1823 25.9997V30.3636C27.1823 30.6654 27.4267 30.909 27.7278 30.909H29.9097C30.0544 30.909 30.1931 30.8516 30.2954 30.7493C30.3977 30.647 30.4552 30.5082 30.4552 30.3636V22.1596C30.4552 22.0779 30.437 21.9973 30.4017 21.9237C30.3665 21.85 30.3151 21.7852 30.2515 21.7341L24.6149 17.2117L24.6142 17.211Z" fill="#EE7400"/>
</svg>
`;