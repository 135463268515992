import { extendTheme } from '@chakra-ui/react'

import { components } from './components'
import { foundations } from './foundations'
import { styles } from './styles'

export const config = {
  cssVarPrefix: 'ok',
}

export const theme = extendTheme({
  styles,
  ...foundations,
  components,
  config,
})
