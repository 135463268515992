import { Button, Flex, FormControl, FormErrorMessage, Input } from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import * as Yup from 'yup'
import IModalEmailFormValues from '../model/interfaces/entities/IModalEmailFormValues'
import { Loading } from './Loading'
import useEmailTemplate from '../model/api/emailForm/useEmailTemplate'

interface ModalEmailFormProps {
  insureeEmail?: string
  onMailMutate: (values: IModalEmailFormValues) => void
  isLoading: boolean
}

function ModalEmailFormComplete({
  insureeEmail,
  onMailMutate,
  isLoading: isLoadingButton,
}: ModalEmailFormProps) {
  const handleSubmit = (values: IModalEmailFormValues) => {
    onMailMutate(values)
  }

  const { data, isLoading } = useEmailTemplate()

  if (isLoading) {
    return <Loading />
  }

  return (
    <Formik
      initialValues={{
        email: insureeEmail ? insureeEmail : '',
      }}
      validationSchema={modalEmailValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Flex flexFlow="column nowrap" justifyContent="space-between" alignItems="center">
          <Field name="email">
            {({ field, form }: FieldProps<string>) => (
              <FormControl
                isInvalid={!!(form.errors.email && form.touched.emai)}
                mb={4}
                w="100%"
                justifyItems="center"
              >
                <Input
                  {...field}
                  id="email"
                  placeholder="Zadejte email"
                  w="100%"
                  justifyItems="center"
                />
                <FormErrorMessage>{String(form.errors.email)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Button mt={4} colorScheme="teal" type="submit">
            {isLoadingButton ? <Loading /> : 'Odeslat'}
          </Button>
        </Flex>
      </Form>
    </Formik>
  )
}

const modalEmailValidationSchema = Yup.object().shape({
  email: Yup.string().email('Neplatná adresa').required('Zadejte email'),
})

export default ModalEmailFormComplete
