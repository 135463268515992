import { Box, Flex } from '@chakra-ui/react'
import React from 'react'

interface FormButtonsProps {
  leftButtons?: React.ReactNode
  rightButtons?: React.ReactNode
}

export const FormButtons = ({ leftButtons, rightButtons }: FormButtonsProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        justifyContent: 'space-between',
      }}
    >
      <Flex sx={{ gap: 2 }}>{leftButtons}</Flex>
      <Flex sx={{ gap: 2 }}>{rightButtons}</Flex>
    </Box>
  )
}
