import { Badge, Box, Container, Grid, Image, Spinner, useToast } from '@chakra-ui/react'

import { Section } from '../../components/Section'
import { Card, CardBody, CardTitle } from '../../components/Card'
import smeDashboardImage from '../../assets/images/sme-dashboard-image.jpg'
import { NewsList } from '../../containers/news/NewsList'
import { fadeInLeft } from '../../utils/fadeInLeft'
import { useEffect, useState } from 'react'
import { useGetNews } from '../../model/api/news/useGetNews'
import { conceptsProperties } from '../../data/concepts'
import { ConceptsList } from '../../containers/concepts'
import { ContractsList } from '../../containers/contracts'
import { contractsProperties } from '../../data/contracts'
import { useGetContracts } from '../../model/api/contracts/useGetContracts'

export const DashboardPage = () => {
  const [numberOfConcepts, setNumberOfConcepts] = useState(0)
  const [numberOfContracts, setNumberOfContracts] = useState(0)
  const { data, error, isError, isLoading } = useGetNews({
    limit: 5,
    page: 1,
  })
  const toastMessage = useToast()
  const handleNumberOfConcepts = (value: number) => {
    setNumberOfConcepts(value)
  }
  const handleNumberOfContracts = (value: number) => {
    setNumberOfContracts(value)
  }

  const { data: dataContracts, isLoading: isLoadingContracts } = useGetContracts({
    hasAniversary: new Date().toJSON().slice(0, 10),
    page: 1,
    limit: 3,
  })

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Error',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  return (
    <Section>
      <Container>
        <Grid sx={{ gap: 8 }}>
          <Grid
            className="dashboard-header"
            sx={{
              gridTemplateColumns: { base: '1fr', xl: '1fr 2fr' },
              gap: 4,
              justifyContent: 'space-around',
            }}
          >
            <Card sx={{ order: { base: 1, xl: 0 } }}>
              <CardTitle>
                Aktuality
                {data && <Badge>{data?.data.count}</Badge>}
              </CardTitle>

              <CardBody sx={{ p: 0 }}>
                {isLoading ? <Spinner /> : <NewsList news={data?.data.items} />}
              </CardBody>
            </Card>

            <Box sx={{ display: 'grid', placeContent: 'center', placeItems: 'center' }}>
              <Image
                sx={{
                  order: { base: 0, xl: 1 },
                  animation: `${fadeInLeft} 0.5s 1s`,
                  animationFillMode: 'backwards',
                }}
                width={453}
                src={smeDashboardImage}
                alt=""
                aria-hidden
                style={{ mixBlendMode: 'multiply' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Card sx={{ order: { base: 1, xl: 0 } }}>
          <CardTitle>
            Neuzavřené nabídky
            <Badge>{numberOfConcepts}</Badge>
          </CardTitle>

          <CardBody sx={{ p: 0 }}>
            <ConceptsList
              concepts={conceptsProperties}
              onNumberOfConcepts={handleNumberOfConcepts}
              params={{ page: 1, limit: 3 }}
            />
          </CardBody>
        </Card>
      </Container>
      <Container>
        <Card sx={{ order: { base: 1, xl: 0 } }}>
          <CardTitle>
            Blížící se výročí smluv
            <Badge>{numberOfContracts}</Badge>
          </CardTitle>
          <CardBody sx={{ p: 0 }}>
            {isLoadingContracts ? (
              <Spinner />
            ) : (
              <ContractsList
                contracts={contractsProperties}
                items={dataContracts?.data}
                onNumberOfContracts={handleNumberOfContracts}
              />
            )}
          </CardBody>
        </Card>
      </Container>
    </Section>
  )
}
