import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import api from '../api'

export const useUpdateDiscount = (
  errorCallBack: (data: any) => void,
  offerId?: string,
  itemId?: string,
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (params: { discount?: string }) =>
      api.post(`${ApiRouteEnum.OFFERS}/${offerId}/step3/${itemId}/discount`, {
        value: Number(params.discount),
        
      }),
    {
      mutationKey: [`${ApiRouteEnum.OFFERS}/${itemId}/step3`],
      onSuccess: () => {
        queryClient.invalidateQueries([ApiRouteEnum.OFFERS])
      },
      onError: (error) => {
        errorCallBack(error)
        console.log(error)
      },
    },
  )
}
