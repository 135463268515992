import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { OrganizationEditFormValues } from '../../interfaces/entities/IOrganizationEditFormValues'

export const useGetOrganizationsId = (id: string | undefined, enabled?: boolean) => {
  const organizationsDetails: string = ApiRouteEnum.ORGANIZATION + `/${id}`
  return useQuery(
    [ApiRouteEnum.ORGANIZATION, id],
    () => api.get<OrganizationEditFormValues>(organizationsDetails),
    {
      retry: false,
      enabled,
    },
  )
}
