import { Icon } from '@chakra-ui/react'

export const InsuranceBuildingResp = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M13.354 8.851C13.4478 8.75711 13.5004 8.62983 13.5003 8.49715C13.5002 8.36447 13.4474 8.23726 13.3535 8.1435C13.2596 8.04975 13.1323 7.99713 12.9996 7.99722C12.867 7.99732 12.7398 8.05011 12.646 8.144L9 11.79L7.354 10.143C7.30751 10.0965 7.25232 10.0596 7.19158 10.0345C7.13084 10.0093 7.06574 9.99637 7 9.99637C6.93426 9.99637 6.86916 10.0093 6.80842 10.0345C6.74768 10.0596 6.69249 10.0965 6.646 10.143C6.59951 10.1895 6.56264 10.2447 6.53748 10.3054C6.51232 10.3662 6.49937 10.4313 6.49937 10.497C6.49937 10.5627 6.51232 10.6278 6.53748 10.6886C6.56264 10.7493 6.59951 10.8045 6.646 10.851L8.646 12.851C8.69245 12.8976 8.74762 12.9345 8.80837 12.9597C8.86911 12.9849 8.93423 12.9979 9 12.9979C9.06577 12.9979 9.13089 12.9849 9.19163 12.9597C9.25238 12.9345 9.30755 12.8976 9.354 12.851L13.354 8.851ZM11.003 2.384C10.7277 2.13664 10.3706 1.99979 10.0005 1.99979C9.63038 1.99979 9.27332 2.13664 8.998 2.384L3.498 7.327C3.34132 7.46765 3.216 7.63971 3.1302 7.83199C3.04439 8.02426 3.00003 8.23245 3 8.443V15.5C3 15.8978 3.15804 16.2794 3.43934 16.5607C3.72064 16.842 4.10218 17 4.5 17H15.5C15.8978 17 16.2794 16.842 16.5607 16.5607C16.842 16.2794 17 15.8978 17 15.5V8.443C17.0001 8.23252 16.9559 8.02438 16.8703 7.83211C16.7846 7.63984 16.6595 7.46774 16.503 7.327L11.003 2.384ZM9.666 3.128C9.75775 3.04564 9.8767 3.00008 10 3.00008C10.1233 3.00008 10.2422 3.04564 10.334 3.128L15.834 8.071C15.8862 8.11788 15.928 8.17524 15.9566 8.23933C15.9852 8.30342 16 8.37282 16 8.443V15.5C16 15.6326 15.9473 15.7598 15.8536 15.8536C15.7598 15.9473 15.6326 16 15.5 16H4.5C4.36739 16 4.24021 15.9473 4.14645 15.8536C4.05268 15.7598 4 15.6326 4 15.5V8.443C4.00001 8.37282 4.0148 8.30342 4.0434 8.23933C4.072 8.17524 4.11377 8.11788 4.166 8.071L9.666 3.128Z" fill="currentColor"/>
	</Icon>
)


export const InsuranceBuildingRespString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.354 8.851C13.4478 8.75711 13.5004 8.62983 13.5003 8.49715C13.5002 8.36447 13.4474 8.23726 13.3535 8.1435C13.2596 8.04975 13.1323 7.99713 12.9996 7.99722C12.867 7.99732 12.7398 8.05011 12.646 8.144L9 11.79L7.354 10.143C7.30751 10.0965 7.25232 10.0596 7.19158 10.0345C7.13084 10.0093 7.06574 9.99637 7 9.99637C6.93426 9.99637 6.86916 10.0093 6.80842 10.0345C6.74768 10.0596 6.69249 10.0965 6.646 10.143C6.59951 10.1895 6.56264 10.2447 6.53748 10.3054C6.51232 10.3662 6.49937 10.4313 6.49937 10.497C6.49937 10.5627 6.51232 10.6278 6.53748 10.6886C6.56264 10.7493 6.59951 10.8045 6.646 10.851L8.646 12.851C8.69245 12.8976 8.74762 12.9345 8.80837 12.9597C8.86911 12.9849 8.93423 12.9979 9 12.9979C9.06577 12.9979 9.13089 12.9849 9.19163 12.9597C9.25238 12.9345 9.30755 12.8976 9.354 12.851L13.354 8.851ZM11.003 2.384C10.7277 2.13664 10.3706 1.99979 10.0005 1.99979C9.63038 1.99979 9.27332 2.13664 8.998 2.384L3.498 7.327C3.34132 7.46765 3.216 7.63971 3.1302 7.83199C3.04439 8.02426 3.00003 8.23245 3 8.443V15.5C3 15.8978 3.15804 16.2794 3.43934 16.5607C3.72064 16.842 4.10218 17 4.5 17H15.5C15.8978 17 16.2794 16.842 16.5607 16.5607C16.842 16.2794 17 15.8978 17 15.5V8.443C17.0001 8.23252 16.9559 8.02438 16.8703 7.83211C16.7846 7.63984 16.6595 7.46774 16.503 7.327L11.003 2.384ZM9.666 3.128C9.75775 3.04564 9.8767 3.00008 10 3.00008C10.1233 3.00008 10.2422 3.04564 10.334 3.128L15.834 8.071C15.8862 8.11788 15.928 8.17524 15.9566 8.23933C15.9852 8.30342 16 8.37282 16 8.443V15.5C16 15.6326 15.9473 15.7598 15.8536 15.8536C15.7598 15.9473 15.6326 16 15.5 16H4.5C4.36739 16 4.24021 15.9473 4.14645 15.8536C4.05268 15.7598 4 15.6326 4 15.5V8.443C4.00001 8.37282 4.0148 8.30342 4.0434 8.23933C4.072 8.17524 4.11377 8.11788 4.166 8.071L9.666 3.128Z" fill="#EE7400"/>
</svg>
`;