import { Heading } from '@chakra-ui/react'

import { Container } from '../../../components/Container'
import { MainContent } from '../../../components/MainContent'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { Recap } from '../../../containers/chaloupka/Recap'
import { MeetingRecordOverview } from '../../../containers/MeetingRecordOverview'
import { ContractRecap } from '../../../containers/chaloupka/ContractRecap'
import { ExpiredContractCancellationForm } from '../../../containers/ExpiredContractCancellationForm'
import { InsuranceSummary } from '../../../containers/InsuranceSummary'
import { InsuranceDownloads } from '../../../containers/chaloupka/InsuranceDownloads'

export const CompletedPage = () => {
  return (
    <Section>
      <Container>
        <StepsContent>
          <Heading
            sx={{
              fontSize: '5xl',
              fontWeight: 'bold',
              letterSpacing: 'tighter',
              textAlign: 'center',
            }}
            as="h1"
          >
            Sjednané pojištění č. 1683442791
          </Heading>

          <Steps icon="house" currentStep={5} />

          <InsuranceSummary />

          <MainContent>
            <InsuranceDownloads />

            <ExpiredContractCancellationForm onSubmit={() => {}} hideCompany hideDate />

            <Recap />

            <ContractRecap />

            <MeetingRecordOverview />
          </MainContent>
        </StepsContent>
      </Container>
    </Section>
  )
}
