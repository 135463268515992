import { Icon } from '@chakra-ui/react'

export const Alert = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M10 7C10.1326 7 10.2598 7.05268 10.3536 7.14645C10.4473 7.24021 10.5 7.36739 10.5 7.5V11.5C10.5 11.6326 10.4473 11.7598 10.3536 11.8536C10.2598 11.9473 10.1326 12 10 12C9.86739 12 9.74021 11.9473 9.64645 11.8536C9.55268 11.7598 9.5 11.6326 9.5 11.5V7.5C9.5 7.36739 9.55268 7.24021 9.64645 7.14645C9.74021 7.05268 9.86739 7 10 7ZM10 14.5C10.1989 14.5 10.3897 14.421 10.5303 14.2803C10.671 14.1397 10.75 13.9489 10.75 13.75C10.75 13.5511 10.671 13.3603 10.5303 13.2197C10.3897 13.079 10.1989 13 10 13C9.80109 13 9.61032 13.079 9.46967 13.2197C9.32902 13.3603 9.25 13.5511 9.25 13.75C9.25 13.9489 9.32902 14.1397 9.46967 14.2803C9.61032 14.421 9.80109 14.5 10 14.5ZM8.686 2.852C8.81549 2.61682 9.00572 2.4207 9.23685 2.28411C9.46798 2.14752 9.73153 2.07547 10 2.07547C10.2685 2.07547 10.532 2.14752 10.7632 2.28411C10.9943 2.4207 11.1845 2.61682 11.314 2.852L17.874 14.777C17.9997 15.0055 18.0637 15.2629 18.0596 15.5237C18.0554 15.7845 17.9834 16.0397 17.8505 16.2641C17.7177 16.4886 17.5286 16.6745 17.3019 16.8035C17.0752 16.9326 16.8188 17.0003 16.558 17H3.44C3.17934 17 2.9232 16.932 2.69681 16.8028C2.47042 16.6736 2.2816 16.4877 2.14897 16.2633C2.01634 16.0389 1.94446 15.7838 1.94044 15.5232C1.93641 15.2626 2.00036 15.0054 2.126 14.777L8.686 2.852ZM10.438 3.334C10.3948 3.25561 10.3314 3.19023 10.2544 3.1447C10.1773 3.09917 10.0895 3.07516 10 3.07516C9.91051 3.07516 9.82266 3.09917 9.74562 3.1447C9.66857 3.19023 9.60516 3.25561 9.562 3.334L3.003 15.26C2.96112 15.3361 2.9398 15.4219 2.94114 15.5087C2.94249 15.5956 2.96645 15.6806 3.01066 15.7554C3.05487 15.8302 3.11781 15.8922 3.19327 15.9353C3.26873 15.9783 3.35411 16.001 3.441 16.001H16.56C16.6467 16.0009 16.732 15.9782 16.8073 15.9352C16.8826 15.8922 16.9455 15.8303 16.9896 15.7557C17.0338 15.681 17.0578 15.5962 17.0593 15.5094C17.0608 15.4227 17.0396 15.3371 16.998 15.261L10.438 3.333V3.334Z" fill="currentColor"/>
	</Icon>
)


export const AlertString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7C10.1326 7 10.2598 7.05268 10.3536 7.14645C10.4473 7.24021 10.5 7.36739 10.5 7.5V11.5C10.5 11.6326 10.4473 11.7598 10.3536 11.8536C10.2598 11.9473 10.1326 12 10 12C9.86739 12 9.74021 11.9473 9.64645 11.8536C9.55268 11.7598 9.5 11.6326 9.5 11.5V7.5C9.5 7.36739 9.55268 7.24021 9.64645 7.14645C9.74021 7.05268 9.86739 7 10 7ZM10 14.5C10.1989 14.5 10.3897 14.421 10.5303 14.2803C10.671 14.1397 10.75 13.9489 10.75 13.75C10.75 13.5511 10.671 13.3603 10.5303 13.2197C10.3897 13.079 10.1989 13 10 13C9.80109 13 9.61032 13.079 9.46967 13.2197C9.32902 13.3603 9.25 13.5511 9.25 13.75C9.25 13.9489 9.32902 14.1397 9.46967 14.2803C9.61032 14.421 9.80109 14.5 10 14.5ZM8.686 2.852C8.81549 2.61682 9.00572 2.4207 9.23685 2.28411C9.46798 2.14752 9.73153 2.07547 10 2.07547C10.2685 2.07547 10.532 2.14752 10.7632 2.28411C10.9943 2.4207 11.1845 2.61682 11.314 2.852L17.874 14.777C17.9997 15.0055 18.0637 15.2629 18.0596 15.5237C18.0554 15.7845 17.9834 16.0397 17.8505 16.2641C17.7177 16.4886 17.5286 16.6745 17.3019 16.8035C17.0752 16.9326 16.8188 17.0003 16.558 17H3.44C3.17934 17 2.9232 16.932 2.69681 16.8028C2.47042 16.6736 2.2816 16.4877 2.14897 16.2633C2.01634 16.0389 1.94446 15.7838 1.94044 15.5232C1.93641 15.2626 2.00036 15.0054 2.126 14.777L8.686 2.852ZM10.438 3.334C10.3948 3.25561 10.3314 3.19023 10.2544 3.1447C10.1773 3.09917 10.0895 3.07516 10 3.07516C9.91051 3.07516 9.82266 3.09917 9.74562 3.1447C9.66857 3.19023 9.60516 3.25561 9.562 3.334L3.003 15.26C2.96112 15.3361 2.9398 15.4219 2.94114 15.5087C2.94249 15.5956 2.96645 15.6806 3.01066 15.7554C3.05487 15.8302 3.11781 15.8922 3.19327 15.9353C3.26873 15.9783 3.35411 16.001 3.441 16.001H16.56C16.6467 16.0009 16.732 15.9782 16.8073 15.9352C16.8826 15.8922 16.9455 15.8303 16.9896 15.7557C17.0338 15.681 17.0578 15.5962 17.0593 15.5094C17.0608 15.4227 17.0396 15.3371 16.998 15.261L10.438 3.333V3.334Z" fill="#003D66"/>
</svg>
`;