import { useMemo } from 'react'
import {
  FormErrorMessage,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioGroupReturn,
} from '@chakra-ui/react'
import { chakra, forwardRef, ThemingProps, HTMLChakraProps } from '@chakra-ui/system'
import { cx, __DEV__ } from '@chakra-ui/utils'
import { createContext } from '@chakra-ui/react-utils'
import { Control, useController } from 'react-hook-form'

export interface RadioIconGroupContext
  extends Pick<UseRadioGroupReturn, 'onChange' | 'value' | 'name' | 'isDisabled' | 'isFocusable'>,
    Omit<ThemingProps<'Radio'>, 'orientation'> {}

const [RadioIconGroupProvider, useRadioIconGroupContext] = createContext<RadioIconGroupContext>({
  name: 'RadioIconGroupContext',
  strict: false,
})

export { useRadioIconGroupContext }

type Omitted = 'onChange' | 'value' | 'defaultValue' | 'defaultChecked' | 'children' | 'name'
export interface RadioIconGroupProps
  extends UseRadioGroupProps,
    Omit<HTMLChakraProps<'div'>, Omitted>,
    Omit<ThemingProps<'Radio'>, 'orientation'> {
  children: React.ReactNode
  control?: Control<any>
  name: string
}

/**
 * Used for multiple radios which are bound in one group,
 * and it indicates which option is selected.
 *
 * @see Docs https://chakra-ui.com/radio
 */
export const RadioIconGroup = forwardRef<RadioIconGroupProps, 'div'>((props, ref) => {
  const {
    colorScheme,
    size,
    variant,
    children,
    name,
    className,
    control,
    isDisabled,
    isFocusable,
    ...rest
  } = props

  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name,
  })
  const { getRootProps, htmlProps } = useRadioGroup({
    onChange: field.onChange,
    value: field.value,
    name,
    ...rest,
  })

  const group = useMemo(
    () => ({
      name,
      size,
      onChange: field.onChange,
      colorScheme,
      value: field.value,
      variant,
      isDisabled,
      isFocusable,
    }),
    [name, size, field.onChange, colorScheme, field.value, variant, isDisabled, isFocusable],
  )

  const groupProps = getRootProps(htmlProps, ref)
  const _className = cx('chakra-radio-group', className)
  const errorMessage = errors && errors[name] && errors[name]?.message

  return (
    <RadioIconGroupProvider value={group}>
      <chakra.div {...groupProps} className={_className}>
        {children}
      </chakra.div>
      {errorMessage && <FormErrorMessage>{errorMessage as string}</FormErrorMessage>}
    </RadioIconGroupProvider>
  )
})

if (__DEV__) {
  RadioIconGroup.displayName = 'RadioIconGroup'
}
