import { useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { StarEmpty, StarFilled } from '../icons'

export const Stars = ({
  rating = 0,
  onChange,
}: {
  rating?: 0 | 1 | 2 | 3
  onChange?: Function
}) => {
  const [currentRating, setCurrentRating] = useState(rating as number)
  const starColor = 'yellow.400'
  const maxStars = 3
  const starsArray = Array.from(Array(maxStars).keys())

  const handleClick = (index: number) => {
    let newCurrentRating = index

    // Clicking current star will reset rating to 0
    if (index === currentRating) {
      newCurrentRating = 0
    }

    setCurrentRating(newCurrentRating)
    onChange && onChange(newCurrentRating)
  }

  return (
    <Flex sx={{ gap: 0 }}>
      {starsArray.map((starIndex) => {
        if (starIndex < currentRating) {
          return (
            <Box
              key={starIndex}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClick(starIndex + 1)}
            >
              <StarFilled color={starColor} />
            </Box>
          )
        }

        return (
          <Box
            key={starIndex}
            sx={{ cursor: 'pointer' }}
            onClick={() => handleClick(starIndex + 1)}
          >
            <StarEmpty color={starColor} />
          </Box>
        )
      })}
    </Flex>
  )
}
