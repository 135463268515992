import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

interface RadioStackProps {
  children: React.ReactNode
  variant?: string
  sx?: BoxProps['sx']
}

export const RadioStack = ({ children, sx = {}, variant = 'tile' }: RadioStackProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: {
          base: 'row',
          lg: 'column',
        },
        justifyContent: 'start',
        gap: variant === 'stackedTile' ? 0 : 2,
        ...sx,
      }}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            variant,
          } as any)
        }
        return child
      })}
    </Box>
  )
}
