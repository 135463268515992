import { FrequencyEnum } from '../enums/FrequencyEnum'

export const translatePeriodicity = (periodicity: string | FrequencyEnum): string => {
  switch (periodicity) {
    case FrequencyEnum.MONTH:
      return 'Měsíční'
    case FrequencyEnum.YEAR:
      return 'Roční'
    case FrequencyEnum.QUARTER:
      return 'Čtvrtletní'
    default:
      return 'Pololetní'
  }
}
