import {
  Badge,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Loading } from '../../components/Loading'

import { Pagination } from '../../components/Pagination'
import { SearchField } from '../../containers/SearchField'
import { useGetOrganizationsPagination } from '../../model/api/organization/useGetOrganizationPagination'
import { IQueryParams } from '../../model/interfaces/entities/IQueryParams'
import { ORGANIZATION_LIST_PAGE_LIMIT } from '../../pages/organization/constants'
import { calculateTotalPages } from '../../utils/calculateTotalPages'
import { getOrganizationTypTranslated } from '../../model/utils/organizationUtils'
import { OrganizationTypeEnum } from '../../model/enums/OrganizationTypeEnum'

export const OrganizationsList = ({
  queryParams,
  setQueryParams,
}: {
  queryParams?: IQueryParams
  setQueryParams: Function
}) => {
  const toastMessage = useToast()
  const { data, isLoading, isError, error, refetch } = useGetOrganizationsPagination(
    queryParams?.page,
    ORGANIZATION_LIST_PAGE_LIMIT,
    queryParams?.unreadOnly,
    queryParams?.search,
    undefined,
    queryParams?.organizationType,
  )

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Error',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  useEffect(() => {
    refetch()
  }, [queryParams])

  const [startPage, setStartPage] = useState<number>(1)
  const [endPage, setEndPage] = useState<number>(10)

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      <SearchField
        inputPlaceholder="Hledat dle názvu"
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />

      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Organizace</Th>
            <Th>Nadřízená organizace</Th>
            <Th>Popis</Th>
            <Th>Typ</Th>
            <Th></Th>
          </Tr>
        </Thead>

        <Tbody>
          {data?.data.items &&
            data?.data.items.length > 0 &&
            data.data.items.map((organization, index) => {
              return (
                <Tr key={index}>
                  <Td>
                    <Link to={`/organization/detail/${organization.id}`}>
                      <Text sx={{ color: 'primary.400', fontWeight: 'medium' }}>
                        {organization.username}
                      </Text>
                    </Link>
                  </Td>
                  <Td>
                    {organization.parent && (
                      <Link to={`/organization/detail/${organization.parent.id}`}>
                        <Text sx={{ color: 'primary.400', fontWeight: 'medium' }}>
                          {organization.parent.username}
                        </Text>
                      </Link>
                    )}
                  </Td>
                  <Td>
                    <Text sx={{ color: 'gray.600', fontSize: 'sm' }}>
                      {organization.description || '-'}
                    </Text>
                  </Td>
                  <Td>
                    <Text sx={{ color: 'gray.600', fontSize: 'sm' }}>
                      {getOrganizationTypTranslated(organization.type as OrganizationTypeEnum) || '-'}
                    </Text>
                  </Td>
                  <Td>
                    <Flex sx={{ flexWrap: 'wrap', gap: 1, justifyContent: 'end' }}>
                      {organization.isActive && <Badge colorScheme="green">Aktivní</Badge>}
                      {!organization.isActive && <Badge colorScheme="orange">Neaktivní</Badge>}
                      {organization.role && <Badge colorScheme="blue">{organization.role}</Badge>}
                    </Flex>
                  </Td>
                </Tr>
              )
            })}
        </Tbody>

        <Tfoot>
          <Tr>
            <Td colSpan={6}>
              <Flex sx={{ placeContent: 'center' }}>
                <Pagination
                  totalPages={calculateTotalPages(data?.data.count, ORGANIZATION_LIST_PAGE_LIMIT)}
                  currentPage={queryParams?.page || 1}
                  queryParams={queryParams}
                  setQueryParams={setQueryParams}
                  startPage={startPage}
                  endPage={endPage}
                  onSetStartPage={setStartPage}
                  onSetEndPage={setEndPage}
                  hide={true}
                />
              </Flex>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  )
}
