import { useNavigate } from 'react-router-dom'
import { MainContent } from '../components/MainContent'
import { Recap } from './carkulka/Recap'

export const MeetingRecordCar = () => {
  const navigate = useNavigate()

  return (
    <MainContent>
      <Recap />
    </MainContent>
  )
}
