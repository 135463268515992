import { Icon } from '@chakra-ui/react'

export const ArrowLeft = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M8.735 16.792C8.88039 16.9237 9.0716 16.9932 9.26763 16.9856C9.46365 16.978 9.64889 16.8938 9.78363 16.7513C9.91837 16.6087 9.99187 16.419 9.98839 16.2228C9.98491 16.0267 9.90471 15.8397 9.765 15.702L4.526 10.747H17.25C17.4489 10.747 17.6397 10.668 17.7803 10.5273C17.921 10.3867 18 10.1959 18 9.997C18 9.79809 17.921 9.60732 17.7803 9.46667C17.6397 9.32602 17.4489 9.247 17.25 9.247H4.524L9.765 4.29C9.90471 4.15229 9.98491 3.96532 9.98839 3.76917C9.99187 3.57303 9.91837 3.38333 9.78363 3.24075C9.64889 3.09816 9.46365 3.01404 9.26763 3.00643C9.0716 2.99881 8.88039 3.0683 8.735 3.2L2.317 9.27C2.15734 9.42044 2.05187 9.61942 2.017 9.836C1.99341 9.94447 1.99409 10.0568 2.019 10.165C2.05499 10.3785 2.15961 10.5744 2.317 10.723L8.735 16.792Z" fill="currentColor"/>
	</Icon>
)


export const ArrowLeftString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.735 16.792C8.88039 16.9237 9.0716 16.9932 9.26763 16.9856C9.46365 16.978 9.64889 16.8938 9.78363 16.7513C9.91837 16.6087 9.99187 16.419 9.98839 16.2228C9.98491 16.0267 9.90471 15.8397 9.765 15.702L4.526 10.747H17.25C17.4489 10.747 17.6397 10.668 17.7803 10.5273C17.921 10.3867 18 10.1959 18 9.997C18 9.79809 17.921 9.60732 17.7803 9.46667C17.6397 9.32602 17.4489 9.247 17.25 9.247H4.524L9.765 4.29C9.90471 4.15229 9.98491 3.96532 9.98839 3.76917C9.99187 3.57303 9.91837 3.38333 9.78363 3.24075C9.64889 3.09816 9.46365 3.01404 9.26763 3.00643C9.0716 2.99881 8.88039 3.0683 8.735 3.2L2.317 9.27C2.15734 9.42044 2.05187 9.61942 2.017 9.836C1.99341 9.94447 1.99409 10.0568 2.019 10.165C2.05499 10.3785 2.15961 10.5744 2.317 10.723L8.735 16.792Z" fill="#003D66"/>
</svg>
`;