import { useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'
export const DefaultErrorCallback = (error: AxiosError) => {
  const toastMessage = useToast()
  const errorMessage =
    (error?.response?.data as { error?: string })?.error || 'Nepodařilo se uložit'

  toastMessage({
    title: 'Chyba',
    description: errorMessage,
    status: 'error',
    isClosable: true,
    position: 'top',
  })
}
