import { Icon } from '@chakra-ui/react'

export const ChevronLeft = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 12 12" fill="none">
<path d="M7.85311 9.85375C7.89967 9.8073 7.93661 9.75213 7.96182 9.69138C7.98703 9.63064 8 9.56551 8 9.49975C8 9.43398 7.98703 9.36886 7.96182 9.30811C7.93661 9.24737 7.89967 9.19219 7.85311 9.14575L4.70611 5.99975L7.85311 2.85375C7.94699 2.75986 7.99974 2.63252 7.99974 2.49975C7.99974 2.36697 7.94699 2.23963 7.85311 2.14575C7.75922 2.05186 7.63188 1.99912 7.49911 1.99912C7.36633 1.99912 7.23899 2.05186 7.14511 2.14575L3.64511 5.64575C3.59854 5.69219 3.5616 5.74737 3.53639 5.80811C3.51119 5.86886 3.49821 5.93398 3.49821 5.99975C3.49821 6.06551 3.51119 6.13064 3.53639 6.19138C3.5616 6.25213 3.59854 6.3073 3.64511 6.35375L7.14511 9.85375C7.19155 9.90031 7.24673 9.93725 7.30747 9.96246C7.36822 9.98767 7.43334 10.0006 7.49911 10.0006C7.56487 10.0006 7.62999 9.98767 7.69074 9.96246C7.75149 9.93725 7.80666 9.90031 7.85311 9.85375V9.85375Z" fill="currentColor"/>
	</Icon>
)


export const ChevronLeftString=`<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.85311 9.85375C7.89967 9.8073 7.93661 9.75213 7.96182 9.69138C7.98703 9.63064 8 9.56551 8 9.49975C8 9.43398 7.98703 9.36886 7.96182 9.30811C7.93661 9.24737 7.89967 9.19219 7.85311 9.14575L4.70611 5.99975L7.85311 2.85375C7.94699 2.75986 7.99974 2.63252 7.99974 2.49975C7.99974 2.36697 7.94699 2.23963 7.85311 2.14575C7.75922 2.05186 7.63188 1.99912 7.49911 1.99912C7.36633 1.99912 7.23899 2.05186 7.14511 2.14575L3.64511 5.64575C3.59854 5.69219 3.5616 5.74737 3.53639 5.80811C3.51119 5.86886 3.49821 5.93398 3.49821 5.99975C3.49821 6.06551 3.51119 6.13064 3.53639 6.19138C3.5616 6.25213 3.59854 6.3073 3.64511 6.35375L7.14511 9.85375C7.19155 9.90031 7.24673 9.93725 7.30747 9.96246C7.36822 9.98767 7.43334 10.0006 7.49911 10.0006C7.56487 10.0006 7.62999 9.98767 7.69074 9.96246C7.75149 9.93725 7.80666 9.90031 7.85311 9.85375V9.85375Z" fill="#003D66"/>
</svg>
`;