import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePostLogo = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id: string | undefined,
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (logo: string | ArrayBuffer | null) =>
      api.post(`${ApiRouteEnum.ORGANIZATION}/${id}/logo`, { logo }),
    {
      mutationKey: [`${ApiRouteEnum.ORGANIZATION}/logo`],
      onSuccess: (data) => {
        successCallBack(data.data)
        return queryClient.invalidateQueries({ queryKey: [ApiRouteEnum.ORGANIZATION, id] })
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
