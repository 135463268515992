import { Container } from '../components/Container'
import { Section } from '../components/Section'
import { EmptyState } from '../components/EmptyState'
import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const NotFoundPage = () => {
  return (
    <Section>
      <Container>
        <EmptyState
          title="Stránka nenalezena"
          cta={<Button as={Link} to="/" colorScheme="primary">Zpět na nástěnku</Button>}
        />
      </Container>
    </Section>
  )
}
