import {
  Box,
  Button,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  Flex,
  IconButton,
  useBoolean,
  useEditableControls,
  useToast,
} from '@chakra-ui/react'
import { Close, EditFilled, Plus, Trash } from '../../icons'
import { IFAQItem, IFAQProps } from '../../model/interfaces/entities/IFAQ'
import { useDeleteFAQ } from '../../model/api/faq/useDeleteFAQ'

export type IEditableProps = {
  item?: IFAQItem
}

const EditableControls = (props: IEditableProps) => {
  const { isEditing, getCancelButtonProps, getEditButtonProps } = useEditableControls()
  const toastMessage = useToast()
  const iconSize = 4
  const successCallBack: () => void = () => {
    toastMessage({
      title: 'Odstraněno',
      status: 'success',
      isClosable: true,
    })
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Error',
      description: error.response.data,
      status: 'error',
      isClosable: true,
    })
  }
  const { mutate } = useDeleteFAQ(successCallBack, errorCallBack)
  const onDeleteQuestion = (id: number | undefined) => {
    if (id) {
      mutate(id)
    }
  }

  return isEditing ? (
    <ButtonGroup justifyContent="start">
      <Button
        variant="outline"
        colorScheme="yellow"
        aria-label="Zrušit"
        {...getCancelButtonProps()}
      >
        <Close width={iconSize} height={iconSize} />
        Zrušit úpravy
      </Button>
    </ButtonGroup>
  ) : (
    <Flex justifyContent="start" gap="2">
      <Button variant="outline" sx={{ py: 2 }} {...getEditButtonProps()}>
        <EditFilled color="gray.500" width={iconSize} height={iconSize} />
        Upravit odpověď
      </Button>
      {props.item && (
        <Button
          type="button"
          variant="outline"
          size="sm"
          colorScheme="orange"
          onClick={() => onDeleteQuestion(props.item?.id)}
        >
          <Trash color="red.500" />
          Smazat otázku
        </Button>
      )}
    </Flex>
  )
}

export const FAQAdminItem = (props: IFAQProps) => {
  const { item, onSubmitForm } = props
  const [isOpen, setOpen] = useBoolean()

  return (
    <Box sx={{ display: 'grid', p: 4, gap: 4, bg: 'white', borderRadius: 'md', boxShadow: 'card' }}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          placeContent: 'start',
          placeItems: 'center',
        }}
      >
        <IconButton aria-label="Upravit novinku" variant="ghost" onClick={setOpen.toggle}>
          <Plus color="secondary.500" />
        </IconButton>

        <Editable
          sx={{ w: '100%' }}
          defaultValue={item?.question}
          placeholder="Napište text otázky"
          onSubmit={(nextValue) => {
            if (!nextValue) {
              return
            }

            if (nextValue !== item?.question) {
              const newFaq = {
                ...item,
                question: nextValue,
                answer: item?.answer || '',
              }
              onSubmitForm(newFaq)
            }
          }}
        >
          <EditablePreview sx={{ fontWeight: 'semibold' }} />
          <EditableInput sx={{ w: '100%' }} />
        </Editable>
      </Box>

      {isOpen && (
        <Box sx={{ pl: 7, pb: 4 }}>
          <Editable
            sx={{ w: '100%', display: 'grid', gap: 2 }}
            defaultValue={item?.answer}
            placeholder={item?.answer || ''}
            onSubmit={(nextValue) => {
              if (nextValue.trim() !== '') {
                if (nextValue !== item?.answer) {
                  onSubmitForm({
                    ...item,
                    answer: nextValue,
                    question: item?.question || '',
                  })
                }
              }
            }}
          >
            <EditablePreview />
            <EditableTextarea rows={10} sx={{ w: '100%' }} />
            <EditableControls item={item} />
          </Editable>
        </Box>
      )}
    </Box>
  )
}
