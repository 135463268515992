import { Box, useDisclosure } from '@chakra-ui/react'

import { OfferPropertyProvider } from '../../context/OfferPropertyContext'
import { OffersBottomToolbar } from './OffersBottomToolbar'
import { ExtraInsuranceProperty } from '../ExtraInsuranceProperty'
import { ExtraInsuranceModal } from '../ExtraInsuranceModal'
import { PaymentFrequency } from '../PaymentFrequency'
import { OffersPropertyList } from './OffersPropertyList'
import { OffersToolbar } from './OffersToolbar'
import { Step2FormValues } from '../chaloupka/Step2Form'
import { FormProvider, useForm } from 'react-hook-form'
import { PageContent } from '../PageContent'
import { NewInsuranceVariantWrapper } from '../chaloupka/NewInsuranceVariantWrapper'

export const OffersProperty = ({ onCompareClick }: { onCompareClick: Function }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const methods = useForm<Step2FormValues>({
    defaultValues: {
      propertyOwnershipLiabilityValue: 3000000,
      lifeLiabilityValue: 5000000,
      insuredPropertyValue: 20000000,
      insuredHouseholdValue: 200000,
    },
  })
  const { handleSubmit } = methods

  const onSubmitForm = (values: Step2FormValues) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 500)
    })
  }

  return (
    <OfferPropertyProvider onCompareClick={() => onCompareClick()}>
      <PageContent>
        <OffersToolbar
          showParticipation={false}
          showCompareButton
          onSettingsClick={() => onOpen()}
          onCompareClick={() => onCompareClick()}
        />

        <OffersPropertyList offerId="16834" />

        <OffersBottomToolbar />

        <NewInsuranceVariantWrapper />

        <ExtraInsuranceModal isOpen={isOpen} onClose={onClose}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <Box sx={{ display: 'grid', gap: 8 }}>
                <PaymentFrequency />
                <ExtraInsuranceProperty showIcons={false} />
              </Box>
            </form>
          </FormProvider>
        </ExtraInsuranceModal>
      </PageContent>
    </OfferPropertyProvider>
  )
}
