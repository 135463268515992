import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FormGroup } from '../components/FormGroup'

import { FormLabel } from '../components/FormLabel'
import {
  FormSection,
  FormSectionBody,
  FormSectionHeader,
  FormSectionTitle,
} from '../components/FormSection'
import { MainContent } from '../components/MainContent'
import React from 'react'
import { IOffersItem } from '../model/interfaces/entities/IOffers'

export const MeetingRecordOverview: React.FC<{ offer?: IOffersItem }> = ({ offer }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <MainContent>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>Záznam z jednání</FormSectionTitle>
        </FormSectionHeader>

        <FormSectionBody sx={{ gap: 8 }}>
          {isOpen && (
            <Box sx={{ display: 'grid', gap: 4 }}>
              <FormControl variant="fullWidth">
                <FormLabel>
                  Případné nesrovnalosti mezi požadavky Zákazníka a sjednávaným pojištěním nebo jeho
                  podstatnou změnou
                </FormLabel>

                <Text>{offer?.meetingMinutes.discrepanciesDescription}</Text>
              </FormControl>

              <FormControl>
                <FormLabel>Důvody, na kterých je doporučení založeno</FormLabel>

                <Text>Rozsah pojistného krytí s ohledem na výši pojistného.</Text>
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel tooltip="Popis dopadů sjednání/změny/ukončení pojištění, včetně souvisejících rizik">
                  Popis dopadů sjednání/změny/ukončení pojištění, včetně souvisejících rizik
                </FormLabel>

                <Text>{offer?.meetingMinutes.reasonDescription}</Text>
              </FormControl>

              <FormControl>
                <FormLabel tooltip="Zákazník odmítá sdělit další požadavky?">
                  Zákazník odmítá sdělit další požadavky?
                </FormLabel>

                <Text>{offer?.meetingMinutes.otherRequirements ? 'Ano' : 'Ne'}</Text>
              </FormControl>

              <FormControl>
                <FormLabel tooltip="Má zákazník vedle této pojistné smlouvy uzavřenou další se stejným krytím?">
                  Má zákazník vedle této pojistné smlouvy uzavřenou další se stejným krytím?
                </FormLabel>

                <Text>{offer?.meetingMinutes.otherInsurance ? 'Ano' : 'Ne'}</Text>
              </FormControl>

              <FormControl>
                <FormLabel tooltip="Jaké má zákazník znalosti produktů?">
                  Jaké má zákazník znalosti produktů?
                </FormLabel>

                <Text>
                  Zákazník již měl sjednáno pojištění s obdobným rozsahem pojistného krytí
                </Text>
              </FormControl>

              <Checkbox isChecked isReadOnly>
                Zákazník ani Zprostředkovatel si nejsou vědomi žádných nesrovnalostí mezi požadavky
                Zákazníka a nabízeným pojištěním.
              </Checkbox>

              <FormControl>
                <FormLabel>Místo sjednání</FormLabel>

                <Text>{offer?.meetingMinutes.place}</Text>
              </FormControl>

              <FormControl>
                <FormLabel tooltip="Druh smlouvy">Druh smlouvy</FormLabel>

                <Text>Nová smlouva</Text>
              </FormControl>

              <FormGroup>
                <FormControl>
                  <FormLabel>Způsob sjednání</FormLabel>

                  <Text>
                    {offer?.meetingMinutes.negotiationMethod === 1
                      ? 'Sjednání podpisem'
                      : 'Sjednání zaplacením'}
                  </Text>
                </FormControl>

                <FormControl>
                  <FormLabel tooltip="Upozornění na blížící se datum splatnosti prvního pojistného">
                    Upozornění na blížící se datum splatnosti prvního pojistného
                  </FormLabel>

                  <HStack>
                    <Checkbox
                      value="insuree"
                      isChecked={offer?.meetingMinutes.dueNotice.includes(1)}
                      isReadOnly
                    >
                      Sjednatel
                    </Checkbox>
                    <Checkbox
                      value="customer"
                      isChecked={offer?.meetingMinutes.dueNotice.includes(2)}
                      isReadOnly
                    >
                      Zákazník
                    </Checkbox>
                  </HStack>
                </FormControl>
              </FormGroup>

              <FormControl>
                <>
                  {(offer?.manager || offer?.gainer) && (
                    <>
                      <FormLabel>Sjednání za jiného uživatele</FormLabel>
                      {offer.gainer && (
                        <FormControl flex="">
                          <Flex gap={1}>
                            <FormLabel>Ziskatel</FormLabel>

                            <Text>
                              {offer?.gainer?.firstName} {offer?.gainer?.lastName}
                            </Text>
                          </Flex>
                        </FormControl>
                      )}
                      {offer.manager && (
                        <FormControl flex="">
                          <Flex gap={1}>
                            <FormLabel>Správce</FormLabel>

                            <Text>
                              {offer?.manager?.firstName} {offer?.manager?.lastName}
                            </Text>
                          </Flex>
                        </FormControl>
                      )}
                    </>
                  )}
                </>
              </FormControl>

              <FormControl>
                <FormLabel>Ziskatelské číslo</FormLabel>

                <Text>{offer?.meetingMinutes.personalNumber}</Text>
              </FormControl>
            </Box>
          )}

          <Flex sx={{ w: '100%', placeContent: 'end' }}>
            <Button variant="outline" onClick={isOpen ? onClose : onOpen}>
              {isOpen ? 'Skrýt záznam z jednání' : 'Zobrazit záznam z jednání'}
            </Button>
          </Flex>
        </FormSectionBody>
      </FormSection>
    </MainContent>
  )
}
