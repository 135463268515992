import { Icon } from '@chakra-ui/react'

export const Note = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M14 3C14.7652 2.99996 15.5015 3.29233 16.0583 3.81728C16.615 4.34224 16.9501 5.06011 16.995 5.824L17 6V10.379C16.9999 10.8485 16.8346 11.3031 16.533 11.663L16.414 11.793L11.793 16.414C11.461 16.746 11.0227 16.9506 10.555 16.992L10.379 17H6C5.23479 17 4.49849 16.7077 3.94174 16.1827C3.38499 15.6578 3.04989 14.9399 3.005 14.176L3 14V6C2.99996 5.23479 3.29233 4.49849 3.81728 3.94174C4.34224 3.38499 5.06011 3.04989 5.824 3.005L6 3H14ZM14 4H6C5.49542 3.99984 5.00943 4.19041 4.63945 4.5335C4.26947 4.87659 4.04284 5.34684 4.005 5.85L4 6V14C3.99984 14.5046 4.19041 14.9906 4.5335 15.3605C4.87659 15.7305 5.34684 15.9572 5.85 15.995L6 16H10V13C9.99996 12.2348 10.2923 11.4985 10.8173 10.9417C11.3422 10.385 12.0601 10.0499 12.824 10.005L13 10H16V6C16.0002 5.49542 15.8096 5.00943 15.4665 4.63945C15.1234 4.26947 14.6532 4.04284 14.15 4.005L14 4ZM15.783 11.001L13 11C12.4954 10.9998 12.0094 11.1904 11.6395 11.5335C11.2695 11.8766 11.0428 12.3468 11.005 12.85L11 13V15.781L11.086 15.707L15.707 11.086C15.734 11.059 15.759 11.031 15.782 11.001H15.783Z" fill="currentColor"/>
	</Icon>
)


export const NoteString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 3C14.7652 2.99996 15.5015 3.29233 16.0583 3.81728C16.615 4.34224 16.9501 5.06011 16.995 5.824L17 6V10.379C16.9999 10.8485 16.8346 11.3031 16.533 11.663L16.414 11.793L11.793 16.414C11.461 16.746 11.0227 16.9506 10.555 16.992L10.379 17H6C5.23479 17 4.49849 16.7077 3.94174 16.1827C3.38499 15.6578 3.04989 14.9399 3.005 14.176L3 14V6C2.99996 5.23479 3.29233 4.49849 3.81728 3.94174C4.34224 3.38499 5.06011 3.04989 5.824 3.005L6 3H14ZM14 4H6C5.49542 3.99984 5.00943 4.19041 4.63945 4.5335C4.26947 4.87659 4.04284 5.34684 4.005 5.85L4 6V14C3.99984 14.5046 4.19041 14.9906 4.5335 15.3605C4.87659 15.7305 5.34684 15.9572 5.85 15.995L6 16H10V13C9.99996 12.2348 10.2923 11.4985 10.8173 10.9417C11.3422 10.385 12.0601 10.0499 12.824 10.005L13 10H16V6C16.0002 5.49542 15.8096 5.00943 15.4665 4.63945C15.1234 4.26947 14.6532 4.04284 14.15 4.005L14 4ZM15.783 11.001L13 11C12.4954 10.9998 12.0094 11.1904 11.6395 11.5335C11.2695 11.8766 11.0428 12.3468 11.005 12.85L11 13V15.781L11.086 15.707L15.707 11.086C15.734 11.059 15.759 11.031 15.782 11.001H15.783Z" fill="#003D66"/>
</svg>
`;