import { Badge, Box, IconButton, Image } from '@chakra-ui/react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import caretDown from '../assets/images/app-switcher-caret-base.svg'
import { useApp } from '../context/AppContext'
import { getCurrentAppLogo } from '../utils/getCurrentAppLogo'
import { Card, CardBody } from './Card'
import { useIsProduction } from '../model/hooks/useIsProduction'
import { useAvailableApps } from '../model/hooks/useAvailableApps'

export const AppSwitcher = () => {
  const [isOpened, setIsOpened] = useState(false)
  const { app } = useApp()
  const currentApp = app
  const availableApps = useAvailableApps()
  const currentAppObject = availableApps.find((app) => app.id === currentApp)
  const otherApps = availableApps.filter((app) => app.id !== currentApp)
  const logo = getCurrentAppLogo({ app: currentApp })
  const logoWidth = 'auto'
  const logoHeight = '32px'
  const isProduction = useIsProduction()

  const handleClick = () => setIsOpened(!isOpened)

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        gap: 1,
        position: 'relative',
        zIndex: 1200, // Above z-index sticky default (=1100)
      }}
    >
      {!isProduction && (
        <Badge
          colorScheme="green"
          sx={{
            _hover: { opacity: 0.2 },
            boxShadow: 'md',
            fontWeight: 'medium',
            position: 'absolute',
            left: '-3',
            top: '-3',
            transform: 'rotate(-3deg)',
            transition: 'opacity 0.2s ease-in-out',
          }}
          variant="solid"
        >
          TEST
        </Badge>
      )}
      <Link to={currentAppObject?.link || '/'}>
        <Image src={logo} alt={currentApp} width={logoWidth} height={logoHeight} />
      </Link>

      <Box
        sx={{
          display: isOpened ? 'grid' : ' none',
          position: 'absolute',
          top: 'calc(100% + 0.5rem)',
          left: 0,
        }}
      >
        <Card>
          <CardBody sx={{ p: 3, gap: 1, shadow: 'lg' }}>
            {Array.isArray(otherApps) &&
              otherApps.map((app) => {
                if (app.link.startsWith('http')) {
                  return (
                    <a key={app.id} href={app.link}>
                      <IconButton
                        colorScheme="whiteAlpha"
                        sx={{ display: 'grid', p: 2, justifyContent: 'start' }}
                        aria-label={app.name}
                        key={app.id}
                        onClick={handleClick}
                      >
                        <Image
                          src={getCurrentAppLogo({ app: app.id })}
                          alt={app.name}
                          style={{ maxWidth: 'none' }}
                          height={logoHeight}
                        />
                      </IconButton>
                    </a>
                  )
                }
                return (
                  <IconButton
                    colorScheme="whiteAlpha"
                    sx={{ display: 'grid', p: 2, justifyContent: 'start' }}
                    aria-label={app.name}
                    as={Link}
                    key={app.id}
                    to={app.link}
                    onClick={handleClick}
                  >
                    <Image
                      src={getCurrentAppLogo({ app: app.id })}
                      alt={app.name}
                      style={{ maxWidth: 'none' }}
                      height={logoHeight}
                    />
                  </IconButton>
                )
              })}
          </CardBody>
        </Card>
      </Box>

      <IconButton
        type="button"
        colorScheme="white"
        aria-label={isOpened ? 'Zavřít výběr aplikací' : 'Otevřít výběr aplikací'}
        onClick={handleClick}
        sx={{ w: 6, h: 6 }}
      >
        <Image
          src={caretDown}
          alt=""
          width="1rem"
          height="1rem"
          sx={{ transform: isOpened ? 'rotate(0deg)' : 'rotate(90deg)' }}
        />
      </IconButton>
    </Box>
  )
}
