import { Box, Button, Select, Text } from '@chakra-ui/react'
import { ColumnTriple, ToggleRightFilled } from '../../icons'

const FilterOrderWrapper = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>{children}</Box>
}

const Buttons = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'start', gap: 2 }}>{children}</Box>
  )
}

const Order = ({ setSortMode, sortMode }: { setSortMode?: Function; sortMode?: string }) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Text sx={{ fontWeight: 'medium' }}>Řazení</Text>

      <Select
        defaultValue=""
        value={sortMode ? sortMode : ''}
        size="sm"
        onChange={(e) => setSortMode && setSortMode(e.target.value)}
      >
        <option value="" disabled>
          Dle pojistného
        </option>
        <option value="desc">Sestupně dle ceny</option>
        <option value="asc">Vzestupně dle ceny</option>
      </Select>
    </Box>
  )
}

export const OffersToolbar = ({
  showParticipation = true,
  showCompareButton = false,
  onCompareClick,
  showListButton,
  onSettingsClick,
  onSelectedItems,
  selectedItems,
  onResetFilters,
  setSortMode,
  sortMode,
}: {
  showParticipation?: boolean
  showCompareButton?: boolean
  showListButton?: boolean
  onCompareClick?: Function
  onSettingsClick?: Function
  onSelectedItems?: (event: any) => void
  selectedItems?: number[]
  onResetFilters?: (event: any) => void
  setSortMode?: Function
  sortMode?: string
}) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, justifyContent: 'space-between' }}>
      <FilterOrderWrapper>
        <Order setSortMode={setSortMode} sortMode={sortMode} />
      </FilterOrderWrapper>

      <Buttons>
        <Button variant="outline" size="sm" onClick={onSelectedItems}>
          <ToggleRightFilled color="secondary.500" />
          Pouze vybrané
        </Button>

        <Button variant="outline" size="sm" onClick={onResetFilters}>
          Zrušit filtry
        </Button>

        {showCompareButton && (
          <Button
            colorScheme="primary"
            size="sm"
            onClick={() => onCompareClick && onCompareClick()}
          >
            <ColumnTriple color="white" />
            Porovnat
          </Button>
        )}

        {showListButton && (
          <Button
            colorScheme="primary"
            size="sm"
            onClick={() => onCompareClick && onCompareClick()}
          >
            <ColumnTriple color="white" />
            Zobrazit nabídky
          </Button>
        )}
      </Buttons>
    </Box>
  )
}
