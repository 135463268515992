import { Box, Button, FormControl, Select, Text } from '@chakra-ui/react'
import { FormLabel } from '../../../../components/FormLabel'
import { Percent, Search } from '../../../../icons'
import { formatPrice } from '../../../../utils/formatPrice'

const InsuranceInfo = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { base: '1fr', lg: '1fr 1fr' },
        alignContent: 'start',
        w: '100%',
        columnGap: 8,
        rowGap: 2,
      }}
    >
      {children}
    </Box>
  )
}

const InsuranceInfoItem = ({
  label,
  name,
  price,
}: {
  label: string
  name: string
  price: number
}) => {
  return (
    <Box sx={{ display: 'grid', gap: { base: 1, lg: 2 } }}>
      <Text sx={{ lineHeight: '1rem' }}>{label}</Text>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          bgColor: 'gray.100',
          borderRadius: 'base',
          lineHeight: '1.25rem',
        }}
      >
        <Text sx={{ fontSize: 'sm', fontWeight: 'semibold' }}>{name}</Text>
        <Text sx={{ fontWeight: 'semibold' }}>{formatPrice({ price })}</Text>
      </Box>
    </Box>
  )
}

const ExtraInsurance = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ display: 'grid', gap: 1 }}>{children}</Box>
}

const ExtraInsuranceItem = ({ name, price }: { name: string; price: number }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Text>{name}</Text>
      <Text sx={{ fontWeight: 'medium' }}>{formatPrice({ price })}</Text>
    </Box>
  )
}

const ControlsTotals = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: 4,
      }}
    >
      {children}
    </Box>
  )
}

const Controls = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        alignItems: 'end',
        columnGap: 4,
        rowGap: 2,
      }}
    >
      {children}
    </Box>
  )
}

const Totals = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: { base: 2, lg: 4 },
      }}
    >
      {children}
    </Box>
  )
}

const Total = ({
  label,
  size = 'md',
  color = 'gray.100',
  price,
}: {
  label: string
  size?: string
  color?: string
  price: number
}) => {
  return (
    <Box sx={{ display: 'grid', gap: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          bgColor: color,
          borderRadius: 'base',
          lineHeight: '1.25rem',
        }}
      >
        <Text sx={{ fontSize: 'base' }}>{label}</Text>
        <Text sx={{ fontSize: size === 'lg' ? 'xl' : 'base', fontWeight: 'semibold' }}>
          {formatPrice({ price })}
        </Text>
      </Box>
    </Box>
  )
}

export const BasicInfoTab = ({ status, color }: { status: string, color: string }) => {
  return (
    <Box
      sx={{
        display: { base: 'grid' },
        gridTemplateColumns: { base: '1fr', md: '2fr 1fr' },
        columnGap: 8,
        rowGap: 4,
      }}
    >
      <Box sx={{ display: 'grid', gap: 4, alignContent: 'start' }}>
        <InsuranceInfo>
          <InsuranceInfoItem label="Povinné ručení" name="limit 200/200 mil. Kč" price={6822} />
          <InsuranceInfoItem
            label="Havarijní pojištění"
            name="Allrisk, Spoluúčast 3%, min. 3 000 Kč "
            price={16927}
          />
        </InsuranceInfo>

        <ExtraInsurance>
          <ExtraInsuranceItem name="Asistence IDEÁL" price={1543} />
          <ExtraInsuranceItem
            name="Všechna skla LIMIT, pojistná částka 10 000 Kč, spoluúčast 500 Kč"
            price={1000}
          />
        </ExtraInsurance>
      </Box>

      {/* Controls and totals */}
      <ControlsTotals>
        <Controls>
          <FormControl sx={{ gap: 3 }}>
            <FormLabel sx={{ fontWeight: 'normal' }}>Frekvence platby</FormLabel>

            <Select>
              <option value="monthly">Měsíční</option>
              <option value="quarterly">Čtvrtletní</option>
              <option value="semiyearly">Pololetní</option>
              <option value="yearly">Roční</option>
            </Select>
          </FormControl>

          <Button variant="outline">
            <Percent color="secondary.500" />
            Slevy
          </Button>

          <Button variant="outline">
            <Search color="secondary.500" />
            ČKP
          </Button>
        </Controls>

        <Totals>
          <Total label="Splátka" price={5853} />
          <Total label="Pojistné za rok" price={23749} size="lg" color={color} />
        </Totals>
      </ControlsTotals>
    </Box>
  )
}
