import React from 'react'
import { Text } from '@chakra-ui/react'
import moment from 'moment'

export const LastChangeInformation: React.FC<{ updatedAt?: string; updatedBy?: string }> = ({
  updatedAt,
  updatedBy,
}) => {
  return (
    <Text sx={{ color: 'gray.500', fontSize: 'sm' }}>
      Poslední změna: {moment(updatedAt).format('DD.MM.Y HH:mm:ss')}, {updatedBy}
    </Text>
  )
}
