import { useGetOfferById } from '../api/offers/useGetOfferById'
import { useGetContractById } from '../api/contracts/usetGetContractById'
import { AxiosResponse } from 'axios'
import { IOffersItem } from '../interfaces/entities/IOffers'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import React from 'react'

export const useOfferOrContractData = (
  id: string | undefined,
  successCallBack: (data: any) => void,
  errorCallBack: (error: any) => void,
  viewOnly: boolean,
): [AxiosResponse<IOffersItem, any> | undefined, boolean] => {
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const { data: offerData, isLoading: offerIsLoading } = useGetOfferById(
    id,
    successCallBack,
    (error: any) => {
      if (errorCallBack) errorCallBack(error)
      if (error?.response?.status === 404) {
        toast({
          title: 'Nabídka již neexistuje',
          description: 'Načítám smlouvu',
          position: 'top',
          status: 'success',
        })
        navigate(location.pathname.replace('edit', 'view'))
      }
    },
    !viewOnly,
  )
  const { data: contractData, isLoading: contractIsLoading } = useGetContractById(
    id,
    successCallBack,
    errorCallBack,
    viewOnly,
  )

  return viewOnly ? [contractData, contractIsLoading] : [offerData, offerIsLoading]
}
