import { Icon } from '@chakra-ui/react'

export const Plus = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M11 3.5C11 3.36739 10.9473 3.24021 10.8536 3.14645C10.7598 3.05268 10.6326 3 10.5 3C10.3674 3 10.2402 3.05268 10.1464 3.14645C10.0527 3.24021 10 3.36739 10 3.5V10H3.5C3.36739 10 3.24021 10.0527 3.14645 10.1464C3.05268 10.2402 3 10.3674 3 10.5C3 10.6326 3.05268 10.7598 3.14645 10.8536C3.24021 10.9473 3.36739 11 3.5 11H10V17.5C10 17.6326 10.0527 17.7598 10.1464 17.8536C10.2402 17.9473 10.3674 18 10.5 18C10.6326 18 10.7598 17.9473 10.8536 17.8536C10.9473 17.7598 11 17.6326 11 17.5V11H17.5C17.6326 11 17.7598 10.9473 17.8536 10.8536C17.9473 10.7598 18 10.6326 18 10.5C18 10.3674 17.9473 10.2402 17.8536 10.1464C17.7598 10.0527 17.6326 10 17.5 10H11V3.5Z" fill="currentColor"/>
	</Icon>
)


export const PlusString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 3.5C11 3.36739 10.9473 3.24021 10.8536 3.14645C10.7598 3.05268 10.6326 3 10.5 3C10.3674 3 10.2402 3.05268 10.1464 3.14645C10.0527 3.24021 10 3.36739 10 3.5V10H3.5C3.36739 10 3.24021 10.0527 3.14645 10.1464C3.05268 10.2402 3 10.3674 3 10.5C3 10.6326 3.05268 10.7598 3.14645 10.8536C3.24021 10.9473 3.36739 11 3.5 11H10V17.5C10 17.6326 10.0527 17.7598 10.1464 17.8536C10.2402 17.9473 10.3674 18 10.5 18C10.6326 18 10.7598 17.9473 10.8536 17.8536C10.9473 17.7598 11 17.6326 11 17.5V11H17.5C17.6326 11 17.7598 10.9473 17.8536 10.8536C17.9473 10.7598 18 10.6326 18 10.5C18 10.3674 17.9473 10.2402 17.8536 10.1464C17.7598 10.0527 17.6326 10 17.5 10H11V3.5Z" fill="#003D66"/>
</svg>
`;