import { FormControl } from '@chakra-ui/react'
import { FieldError, useFormContext } from 'react-hook-form'

import { FormLabel } from '../components/FormLabel'
import { InputGroup, InputGroupProps } from '../components/InputGroup'

export interface InsureeNameProps extends InputGroupProps {
  firstNameId?: string
  lastNameId?: string
}

export const InsureeName = (props: InsureeNameProps) => {
  const { firstNameId = 'firstName', lastNameId = 'lastName', ...rest } = props
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <FormControl isInvalid={!!errors[firstNameId]}>
        <FormLabel htmlFor={firstNameId}>Jméno</FormLabel>
        <InputGroup
          id={firstNameId}
          placeholder="Jméno klienta"
          autoComplete="given-name"
          errors={errors[firstNameId] as FieldError}
          {...rest}
          {...register(firstNameId)}
        />
      </FormControl>

      <FormControl isInvalid={!!errors['lastNameId']}>
        <FormLabel htmlFor={lastNameId}>Příjmení</FormLabel>
        <InputGroup
          id={lastNameId}
          placeholder="Příjmení klienta"
          autoComplete="family-name"
          errors={errors['lastNameId'] as FieldError}
          {...rest}
          {...register(lastNameId)}
        />
      </FormControl>
    </>
  )
}
