import React from 'react'
import { Container as ChakraContainer } from '@chakra-ui/react'

interface ContainerProps {
  children: React.ReactNode
}

export const Container = ({ children, ...rest }: ContainerProps) => {
  return <ChakraContainer {...rest}>{children}</ChakraContainer>
}
