import { Box } from '@chakra-ui/react'
import { NewsItem } from './NewsItem'
import { INewsItemV2 } from '../../model/interfaces/entities/INews'

interface INewsList {
  news?: Array<INewsItemV2>
}

export const NewsGrid = ({ news }: INewsList) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { base: '1fr', md: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' },
        gap: 8,
      }}
    >
      {news &&
        news.length > 0 &&
        news.map(({ id, app, title, date, tagLabel, status, unread, content }) => {
          return (
            <NewsItem
              variant="grid"
              key={id}
              id={id}
              app={app}
              title={title}
              date={date}
              status={status}
              tagLabel={tagLabel}
              unread={unread}
              content={content}
              showReadButton
            />
          )
        })}
    </Box>
  )
}
