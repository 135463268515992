import { FormControl, RadioGroup, Radio, Flex } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { FormLabel } from '../components/FormLabel'
import { RadioStack } from '../components/RadioStack'
import { Business, Citizen, Company } from '../icons'

const RadioLabelWithIcon = ({ children }: { children: React.ReactNode }) => {
  return <Flex sx={{ gap: 2, alignItems: 'center' }}>{children}</Flex>
}

export interface InsureeTypeProps {
  insureeTypeId?: string
}

export const InsureeType = ({ insureeTypeId = 'insureeType' }: InsureeTypeProps) => {
  const { register } = useFormContext()

  const { name, ...rest } = register(insureeTypeId, { value: 1 })
  const iconColor = 'gray.500'

  return (
    <FormControl>
      <FormLabel>Typ</FormLabel>

      <RadioGroup name={name}>
        <RadioStack variant="tile">
          <Radio value="1" {...rest}>
            <RadioLabelWithIcon>
              Občan
              <Citizen color={iconColor} />
            </RadioLabelWithIcon>
          </Radio>
          <Radio value="2" {...rest}>
            <RadioLabelWithIcon>
              Fyzická osoba podnikatel
              <Business color={iconColor} />
            </RadioLabelWithIcon>
          </Radio>
          <Radio value="3" {...rest}>
            <RadioLabelWithIcon>
              Právnická osoba
              <Company color={iconColor} />
            </RadioLabelWithIcon>
          </Radio>
        </RadioStack>
      </RadioGroup>
    </FormControl>
  )
}
