import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  PropsOf,
  SystemProps,
  SystemStyleObject,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system'
import { callAll, cx } from '@chakra-ui/shared-utils'
import { mode } from '@chakra-ui/theme-tools'
import { CheckboxIcon as ChakraCheckboxIcon, useCheckbox, UseCheckboxProps } from '@chakra-ui/react'
import { useCheckboxIconGroupContext } from './CheckboxIconGroup'
import { getRadioIcon } from './RadioIcon'
import React from 'react'

export type CheckboxOptions = {
  /**
   * The spacing between the checkbox and its label text
   * @default 0.5rem
   * @type SystemProps["marginLeft"]
   */
  spacing?: SystemProps['marginLeft']
  /**
   * The color of the checkbox icon when checked or indeterminate
   */
  iconColor?: string
  /**
   * The size of the checkbox icon when checked or indeterminate
   */
  iconSize?: string | number
  /**
   * The checked icon to use
   *
   * @type React.ReactElement
   * @default CheckboxIcon
   */
  icon?: React.ReactElement
  /**
   * Additional props to be forwarded to the `input` element
   */
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

const rootStyles: SystemStyleObject = {
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  verticalAlign: 'top',
  position: 'relative',
}

const controlStyles: SystemStyleObject = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'top',
  userSelect: 'none',
  flexShrink: 0,
}

type CheckboxControlProps = Omit<HTMLChakraProps<'div'>, keyof UseCheckboxProps>

type BaseInputProps = Pick<PropsOf<'input'>, 'onBlur' | 'checked' | 'defaultChecked'>

export interface CheckboxProps
  extends CheckboxControlProps,
    BaseInputProps,
    ThemingProps<'Checkbox'>,
    UseCheckboxProps,
    CheckboxOptions {
  customIcon?: string
}

/**
 * CheckboxIcon
 *
 * React component used in forms when a user needs to select
 * multiple values from several options.
 *
 * This has been edited to show custom icons
 *
 * @see Docs https://chakra-ui.com/checkbox
 * @see WAI-ARIA https://www.w3.org/WAI/ARIA/apg/patterns/checkbox/
 */
export const CheckboxIcon = forwardRef<CheckboxProps, 'input'>(function Checkbox(props, ref) {
  const group = useCheckboxIconGroupContext()

  const mergedProps = { ...group, ...props } as CheckboxProps
  const styles = useMultiStyleConfig('Checkbox', mergedProps)

  const ownProps = omitThemingProps(props)

  const {
    spacing = '0.5rem',
    className,
    children,
    icon = <ChakraCheckboxIcon />,
    iconColor,
    iconSize,
    isChecked: isCheckedProp,
    isDisabled = group?.isDisabled,
    onChange: onChangeProp,
    inputProps,
    customIcon,
    ...rest
  } = ownProps

  let isChecked = isCheckedProp

  let onChange = onChangeProp
  if (group?.onChange && ownProps.value) {
    onChange = callAll(group.onChange, onChangeProp)
  }

  const { state, getInputProps, getLabelProps, getRootProps, getCheckboxProps } = useCheckbox({
    ...rest,
    isDisabled,
    isChecked,
    onChange,
  })

  const iconStyles: SystemStyleObject = React.useMemo(
    () => ({
      opacity: state.isChecked || state.isIndeterminate ? 1 : 0,
      transform: state.isChecked || state.isIndeterminate ? 'scale(1)' : 'scale(0.95)',
      fontSize: iconSize,
      color: iconColor,
      ...styles.icon,
    }),
    [iconColor, iconSize, state.isChecked, state.isIndeterminate, styles.icon],
  )

  const clonedIcon = React.cloneElement(icon, {
    __css: iconStyles,
    isIndeterminate: state.isIndeterminate,
    isChecked: state.isChecked,
  })

  styles.container = {
    ...styles.container,
    bg: state.isChecked ? mode('white', 'whiteAlpha.800') : mode('gray.50', 'whiteAlpha.900'),
    borderColor: state.isChecked ? 'green' : mode('gray.400', 'whiteAlpha.800'),
  }

  return (
    <chakra.label
      __css={{ ...rootStyles, ...styles.container }}
      className={cx('chakra-checkbox-icon', className)}
      {...getRootProps()}
    >
      {customIcon && getRadioIcon({ icon: customIcon })}

      <input className="chakra-checkbox__input" {...getInputProps(inputProps, ref)} />

      <chakra.span
        __css={{ ...controlStyles, ...styles.control }}
        className="chakra-checkbox__control"
        {...getCheckboxProps()}
      >
        {clonedIcon}
      </chakra.span>

      {children && (
        <chakra.span
          className="chakra-checkbox__label"
          {...getLabelProps()}
          __css={{
            marginStart: spacing,
            textAlign: 'center',
            fontWeight: '500',
            ...styles.label,
          }}
        >
          {children}
        </chakra.span>
      )}
    </chakra.label>
  )
})

CheckboxIcon.displayName = 'CheckboxIcon'
