import { Box, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { IUser, IUserItem } from '../model/interfaces/entities/IUser'
import { userInitialValues } from '../utils/UserEditInitialValues'
import { UserEditFormProps } from '../model/interfaces/entities/IUserEditFormValues'
import { usePutUser } from '../model/api/user/usePutUser'
import { useGetCompanies } from '../model/api/company/useGetCompany'
import { useGetUserId } from '../model/api/user/useGetUserId'
import { Loading } from '../components/Loading'
import { UserForm } from './UserForm'
import transformPersonalNumbers from '../model/utils/transformPersonalNumbers'
import { useEffect } from 'react'
import { useIsCurrent, useIsFull, useIsSupervisorOrAdmin } from '../model/utils/userUtils'
import { UserRoleEnum } from '../model/enums/UserRoleEnum'

export const UserEditForm = ({ profile }: UserEditFormProps) => {
  const navigate = useNavigate()
  const toastMessage = useToast()

  const { id } = useParams<'id'>()
  const { data: dataCompany } = useGetCompanies()
  const { data, isLoading: isGetUserLoading } = useGetUserId(id)
  const initialValueApi: IUserItem = data ? data.data : userInitialValues
  const userIsSupervisor = useIsSupervisorOrAdmin()
  const userIsFull = useIsFull()
  const userIsCurrentUser = useIsCurrent(Number(id))

  useEffect(() => {
    if (userIsSupervisor === false && (userIsCurrentUser === false || userIsFull === false)) {
      navigate('/404')
    }
  }, [userIsSupervisor, userIsCurrentUser, userIsFull, navigate])

  const successCallBack: (data: IUser) => void = (data: any) => {
    navigate(`/user/detail/${id}`)
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Došlo k chybě',
      description: error,
      status: 'error',
      isClosable: true,
    })
  }

  const { mutate, isLoading } = usePutUser(successCallBack, errorCallBack, id)
  const onSubmitForm: (formValues: IUserItem) => void = (formValues: IUserItem) => {
    if (formValues.role === UserRoleEnum.SALESMAN) {
      return mutate({
        parent: Number(formValues.parent) as number,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        usernameOtherSystem: formValues.usernameOtherSystem,
        role: formValues.role,
      })
    }

    mutate(formValues)
  }
  if (isGetUserLoading || !data || !dataCompany) {
    return <Loading />
  }
  return (
    <>
      {' '}
      <Box>
        <UserForm
          initialValues={{
            ...transformPersonalNumbers(dataCompany.data, data.data),
            parent: (initialValueApi.parent?.id || undefined) as any,
          }}
          onSubmitForm={onSubmitForm}
          onGoBack={() => navigate(`/user/detail/${id}`)}
          isLoading={isLoading}
        />
      </Box>
    </>
  )
}
