import { Icon } from '@chakra-ui/react'

export const InsurancePersonal = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M5 6C5 4.93913 5.42143 3.92172 6.17157 3.17157C6.92172 2.42143 7.93913 2 9 2C10.0609 2 11.0783 2.42143 11.8284 3.17157C12.5786 3.92172 13 4.93913 13 6C13 7.06087 12.5786 8.07828 11.8284 8.82843C11.0783 9.57857 10.0609 10 9 10C7.93913 10 6.92172 9.57857 6.17157 8.82843C5.42143 8.07828 5 7.06087 5 6ZM9 3C8.20435 3 7.44129 3.31607 6.87868 3.87868C6.31607 4.44129 6 5.20435 6 6C6 6.79565 6.31607 7.55871 6.87868 8.12132C7.44129 8.68393 8.20435 9 9 9C9.79565 9 10.5587 8.68393 11.1213 8.12132C11.6839 7.55871 12 6.79565 12 6C12 5.20435 11.6839 4.44129 11.1213 3.87868C10.5587 3.31607 9.79565 3 9 3Z" fill="currentColor"/>
<path d="M2 13C2 11.887 2.903 11 4.009 11H10.257C10.0023 11.3085 9.782 11.6438 9.6 12H4.009C3.448 12 3 12.447 3 13C3 14.309 3.622 15.284 4.673 15.953C5.743 16.636 7.265 17 9 17C9.2 17 9.398 16.995 9.592 16.985C9.765 17.327 9.973 17.647 10.21 17.943C9.815 17.981 9.41 18 9 18C7.145 18 5.417 17.614 4.135 16.797C2.833 15.967 2 14.69 2 13Z" fill="currentColor"/>
<path d="M19 14.5C19 15.6935 18.5259 16.8381 17.682 17.682C16.8381 18.5259 15.6935 19 14.5 19C13.3065 19 12.1619 18.5259 11.318 17.682C10.4741 16.8381 10 15.6935 10 14.5C10 13.3065 10.4741 12.1619 11.318 11.318C12.1619 10.4741 13.3065 10 14.5 10C15.6935 10 16.8381 10.4741 17.682 11.318C18.5259 12.1619 19 13.3065 19 14.5ZM16.854 12.646C16.8076 12.5994 16.7524 12.5625 16.6916 12.5373C16.6309 12.5121 16.5658 12.4991 16.5 12.4991C16.4342 12.4991 16.3691 12.5121 16.3084 12.5373C16.2476 12.5625 16.1924 12.5994 16.146 12.646L13.5 15.293L12.854 14.646C12.7601 14.5521 12.6328 14.4994 12.5 14.4994C12.3672 14.4994 12.2399 14.5521 12.146 14.646C12.0521 14.7399 11.9994 14.8672 11.9994 15C11.9994 15.1328 12.0521 15.2601 12.146 15.354L13.146 16.354C13.1924 16.4006 13.2476 16.4375 13.3084 16.4627C13.3691 16.4879 13.4342 16.5009 13.5 16.5009C13.5658 16.5009 13.6309 16.4879 13.6916 16.4627C13.7524 16.4375 13.8076 16.4006 13.854 16.354L16.854 13.354C16.9006 13.3076 16.9375 13.2524 16.9627 13.1916C16.9879 13.1309 17.0009 13.0658 17.0009 13C17.0009 12.9342 16.9879 12.8691 16.9627 12.8084C16.9375 12.7476 16.9006 12.6924 16.854 12.646Z" fill="currentColor"/>
	</Icon>
)


export const InsurancePersonalString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 6C5 4.93913 5.42143 3.92172 6.17157 3.17157C6.92172 2.42143 7.93913 2 9 2C10.0609 2 11.0783 2.42143 11.8284 3.17157C12.5786 3.92172 13 4.93913 13 6C13 7.06087 12.5786 8.07828 11.8284 8.82843C11.0783 9.57857 10.0609 10 9 10C7.93913 10 6.92172 9.57857 6.17157 8.82843C5.42143 8.07828 5 7.06087 5 6ZM9 3C8.20435 3 7.44129 3.31607 6.87868 3.87868C6.31607 4.44129 6 5.20435 6 6C6 6.79565 6.31607 7.55871 6.87868 8.12132C7.44129 8.68393 8.20435 9 9 9C9.79565 9 10.5587 8.68393 11.1213 8.12132C11.6839 7.55871 12 6.79565 12 6C12 5.20435 11.6839 4.44129 11.1213 3.87868C10.5587 3.31607 9.79565 3 9 3Z" fill="#EE7400"/>
<path d="M2 13C2 11.887 2.903 11 4.009 11H10.257C10.0023 11.3085 9.782 11.6438 9.6 12H4.009C3.448 12 3 12.447 3 13C3 14.309 3.622 15.284 4.673 15.953C5.743 16.636 7.265 17 9 17C9.2 17 9.398 16.995 9.592 16.985C9.765 17.327 9.973 17.647 10.21 17.943C9.815 17.981 9.41 18 9 18C7.145 18 5.417 17.614 4.135 16.797C2.833 15.967 2 14.69 2 13Z" fill="#EE7400"/>
<path d="M19 14.5C19 15.6935 18.5259 16.8381 17.682 17.682C16.8381 18.5259 15.6935 19 14.5 19C13.3065 19 12.1619 18.5259 11.318 17.682C10.4741 16.8381 10 15.6935 10 14.5C10 13.3065 10.4741 12.1619 11.318 11.318C12.1619 10.4741 13.3065 10 14.5 10C15.6935 10 16.8381 10.4741 17.682 11.318C18.5259 12.1619 19 13.3065 19 14.5ZM16.854 12.646C16.8076 12.5994 16.7524 12.5625 16.6916 12.5373C16.6309 12.5121 16.5658 12.4991 16.5 12.4991C16.4342 12.4991 16.3691 12.5121 16.3084 12.5373C16.2476 12.5625 16.1924 12.5994 16.146 12.646L13.5 15.293L12.854 14.646C12.7601 14.5521 12.6328 14.4994 12.5 14.4994C12.3672 14.4994 12.2399 14.5521 12.146 14.646C12.0521 14.7399 11.9994 14.8672 11.9994 15C11.9994 15.1328 12.0521 15.2601 12.146 15.354L13.146 16.354C13.1924 16.4006 13.2476 16.4375 13.3084 16.4627C13.3691 16.4879 13.4342 16.5009 13.5 16.5009C13.5658 16.5009 13.6309 16.4879 13.6916 16.4627C13.7524 16.4375 13.8076 16.4006 13.854 16.354L16.854 13.354C16.9006 13.3076 16.9375 13.2524 16.9627 13.1916C16.9879 13.1309 17.0009 13.0658 17.0009 13C17.0009 12.9342 16.9879 12.8691 16.9627 12.8084C16.9375 12.7476 16.9006 12.6924 16.854 12.646Z" fill="#EE7400"/>
</svg>
`;