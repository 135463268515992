import React from 'react'
import { Box, BoxProps, FormControl, Select } from '@chakra-ui/react'

import { FormLabel } from '../components/FormLabel'
import { Animal, Assistance, Elements, Injury, Thief, Windows } from '../icons'

const enum ExtraInsuranceCarItemIconsEnum {
  Assistance = 'assistance',
  Windows = 'windows',
  Injury = 'injury',
  Elements = 'elements',
  Animal = 'animal',
  Thief = 'thief',
}

const getExtraInsuranceCarIcon = (icon: ExtraInsuranceCarItemIconsEnum) => {
  const size: string = '48px'

  switch (icon) {
    case 'assistance':
      return <Assistance width={size} height={size} />
    case 'windows':
      return <Windows width={size} height={size} />
    case 'injury':
      return <Injury width={size} height={size} />
    case 'elements':
      return <Elements width={size} height={size} />
    case 'animal':
      return <Animal width={size} height={size} />
    case 'thief':
      return <Thief width={size} height={size} />
  }
}

const ExtraInsuranceGroup = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ w: '100%', display: 'grid', rowGap: 8 }}>{children}</Box>
}

const ExtraInsuranceItem = ({
  children,
  label,
  tooltip,
  icon,
  sx,
}: {
  children: React.ReactNode
  label: string
  tooltip?: string
  icon: ExtraInsuranceCarItemIconsEnum
  sx?: BoxProps['sx']
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, ...sx }}>
      {icon && <Box sx={{ color: 'secondary.500' }}>{getExtraInsuranceCarIcon(icon)}</Box>}
      <FormControl sx={{}}>
        <FormLabel tooltip={tooltip}>{label}</FormLabel>
        {children}
      </FormControl>
    </Box>
  )
}

export const ExtraInsuranceCar = () => {
  return (
    <Box
      sx={{
        w: '100%',
        display: 'grid',
        gridTemplateColumns: { base: '1fr', lg: '1fr 1fr' },
        gap: 8,
      }}
    >
      <ExtraInsuranceGroup>
        <ExtraInsuranceItem
          label="Příplatková asistence"
          tooltip="Příplatková asistence"
          icon={ExtraInsuranceCarItemIconsEnum.Assistance}
        >
          <Select defaultValue="">
            <option disabled value="">
              Limit plnění připojištění
            </option>
          </Select>
        </ExtraInsuranceItem>

        <ExtraInsuranceItem label="Skla" tooltip="Skla" icon={ExtraInsuranceCarItemIconsEnum.Windows}>
          <Select defaultValue="">
            <option disabled value="">
              Limit plnění připojištění
            </option>
          </Select>
        </ExtraInsuranceItem>

        <ExtraInsuranceItem label="Úraz" tooltip="Úraz" icon={ExtraInsuranceCarItemIconsEnum.Injury}>
          <Select defaultValue="">
            <option disabled value="">
              Limit plnění připojištění
            </option>
          </Select>
        </ExtraInsuranceItem>
      </ExtraInsuranceGroup>

      <ExtraInsuranceGroup>
        <ExtraInsuranceItem
          label="Živel"
          tooltip="Živel"
          icon={ExtraInsuranceCarItemIconsEnum.Elements}
        >
          <Select defaultValue="">
            <option disabled value="">
              Limit plnění připojištění
            </option>
          </Select>
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Srážka se zvěří"
          tooltip="Srážka se zvěří"
          icon={ExtraInsuranceCarItemIconsEnum.Animal}
        >
          <Select defaultValue="">
            <option disabled value="">
              Limit plnění připojištění
            </option>
          </Select>
        </ExtraInsuranceItem>

        <ExtraInsuranceItem
          label="Odcizení vozidla"
          tooltip="Odcizení vozidla"
          icon={ExtraInsuranceCarItemIconsEnum.Thief}
        >
          <Select defaultValue="">
            <option disabled value="">
              Limit plnění připojištění
            </option>
          </Select>
        </ExtraInsuranceItem>
      </ExtraInsuranceGroup>
    </Box>
  )
}
