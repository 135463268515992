import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  InputGroup as ChakraInputGroup,
  InputLeftElement,
  Switch,
  useBoolean,
} from '@chakra-ui/react'
import { forwardRef, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Datepicker } from '../components/Datepicker'
import { FormLabel } from '../components/FormLabel'
import { InputGroup } from '../components/InputGroup'
import { SwitchControl } from '../components/SwitchControl'
import { Calendar, Cloud } from '../icons'
import { CountrySelect } from './CountrySelect'

export interface BCNOptionsProps extends BCNFormProps, DOBNationalityFormProps {
  noBCNId?: string
}

export interface BCNFormProps {
  bcnId?: string
}

export interface DOBNationalityFormProps {
  dateOfBirthId?: string
  nationalityId?: string
}

const BCNForm = (props: BCNFormProps) => {
  const { bcnId = 'bcn' } = props
  const { register, watch, getFieldState } = useFormContext()
  const bcn = watch(bcnId)
  const [fetchClient, setFetchClient] = useState<{
    client: string | null
    loading: boolean
    state: 'error' | 'success' | null
  }>({
    client: null,
    loading: false,
    state: null,
  })

  useEffect(() => {
    if (!bcn) {
      setFetchClient({
        client: null,
        loading: false,
        state: null,
      })
    }
  }, [bcn])

  const errors = getFieldState(bcnId).error

  return (
    <Flex>
      <FormControl>
        <FormLabel tooltip="Rodné číslo" htmlFor={bcnId}>
          Rodné číslo
        </FormLabel>

        <Flex sx={{ columnGap: 8, rowGap: 2, flexWrap: 'wrap', alignItems: 'start' }}>
          <FormControl isInvalid={!!errors}>
            <InputGroup
              id={bcnId}
              placeholder="Např. 7505250000"
              htmlSize={16}
              errors={errors}
              {...register(bcnId, {
                // required: 'Zadejte číslice bez mezer, lomítek či pomlček.',
                // pattern: /[0-9]{7,8}/gi,
                // minLength: 7,
                // maxLength: 8,
              })}
            />

            {typeof errors === 'undefined' && (
              <FormHelperText>Zadejte číslice bez mezer, lomítek či pomlček.</FormHelperText>
            )}
          </FormControl>

          <FormControl isInvalid={fetchClient.state === 'error'}>
            <Button
              type="button"
              variant="outline"
              isLoading={fetchClient.loading}
              isDisabled={fetchClient.loading || !bcn}
              onClick={() => {
                setFetchClient({ ...fetchClient, loading: true })

                setTimeout(() => {
                  setFetchClient({ ...fetchClient, loading: false, state: 'error' })
                }, 1000)
              }}
            >
              <Cloud color="secondary.500" />
              Načíst údaje o klientovi
            </Button>

            {fetchClient.state === 'error' && !fetchClient.loading && (
              <FormErrorMessage>Klient nenalezen.</FormErrorMessage>
            )}
          </FormControl>
        </Flex>
      </FormControl>
    </Flex>
  )
}

const DOBNationalityForm = forwardRef(
  (
    { dateOfBirthId = 'dateOfBirth', nationalityId = 'nationality' }: DOBNationalityFormProps,
    ref: any,
  ) => {
    const { register, getValues, setValue } = useFormContext()

    return (
      <Flex sx={{ gap: 8 }}>
        <FormControl>
          <FormLabel htmlFor={dateOfBirthId}>Datum narození</FormLabel>

          <ChakraInputGroup>
            <InputLeftElement pointerEvents="none" children={<Calendar width={4} height={4} />} />

            <Datepicker
              date={getValues(dateOfBirthId)}
              onDateChange={(date: Date) => setValue(dateOfBirthId, date)}
            />
          </ChakraInputGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Státní občanství</FormLabel>

          <CountrySelect {...register(nationalityId)} />
        </FormControl>
      </Flex>
    )
  },
)

export const BCNOptions = (props: BCNOptionsProps) => {
  const {
    noBCNId = 'noBCN',
    bcnId = 'bcn',
    dateOfBirthId = 'dateOfBirth',
    nationalityId = 'nationality',
  } = props
  const [hasBCN, setHasBCN] = useBoolean(true)

  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      <FormControl>
        <FormLabel tooltip="Rodné číslo">Rodné číslo</FormLabel>
        <SwitchControl>
          <Switch id={noBCNId} onChange={setHasBCN.toggle} />
          <FormLabel htmlFor={noBCNId} variant="switch">
            Nemá rodné číslo
          </FormLabel>
        </SwitchControl>
      </FormControl>

      {hasBCN && <BCNForm bcnId={bcnId} />}

      {!hasBCN && <DOBNationalityForm dateOfBirthId={dateOfBirthId} nationalityId={nationalityId} />}
    </Box>
  )
}
