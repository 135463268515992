import { Icon } from '@chakra-ui/react'

export const ChevronRight = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 12 12" fill="none">
<path d="M4.64598 2.14601C4.59941 2.19245 4.56247 2.24763 4.53727 2.30838C4.51206 2.36912 4.49908 2.43424 4.49908 2.50001C4.49908 2.56578 4.51206 2.6309 4.53727 2.69164C4.56247 2.75239 4.59941 2.80756 4.64598 2.85401L7.79298 6.00001L4.64598 9.14601C4.55209 9.2399 4.49935 9.36723 4.49935 9.50001C4.49935 9.63278 4.55209 9.76012 4.64598 9.85401C4.73986 9.9479 4.8672 10.0006 4.99998 10.0006C5.13275 10.0006 5.26009 9.9479 5.35398 9.85401L8.85398 6.35401C8.90054 6.30756 8.93748 6.25239 8.96269 6.19164C8.9879 6.1309 9.00087 6.06578 9.00087 6.00001C9.00087 5.93424 8.9879 5.86912 8.96269 5.80838C8.93748 5.74763 8.90054 5.69245 8.85398 5.64601L5.35398 2.14601C5.30753 2.09945 5.25236 2.0625 5.19161 2.0373C5.13087 2.01209 5.06575 1.99911 4.99998 1.99911C4.93421 1.99911 4.86909 2.01209 4.80834 2.0373C4.7476 2.0625 4.69242 2.09945 4.64598 2.14601V2.14601Z" fill="currentColor"/>
	</Icon>
)


export const ChevronRightString=`<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.64598 2.14601C4.59941 2.19245 4.56247 2.24763 4.53727 2.30838C4.51206 2.36912 4.49908 2.43424 4.49908 2.50001C4.49908 2.56578 4.51206 2.6309 4.53727 2.69164C4.56247 2.75239 4.59941 2.80756 4.64598 2.85401L7.79298 6.00001L4.64598 9.14601C4.55209 9.2399 4.49935 9.36723 4.49935 9.50001C4.49935 9.63278 4.55209 9.76012 4.64598 9.85401C4.73986 9.9479 4.8672 10.0006 4.99998 10.0006C5.13275 10.0006 5.26009 9.9479 5.35398 9.85401L8.85398 6.35401C8.90054 6.30756 8.93748 6.25239 8.96269 6.19164C8.9879 6.1309 9.00087 6.06578 9.00087 6.00001C9.00087 5.93424 8.9879 5.86912 8.96269 5.80838C8.93748 5.74763 8.90054 5.69245 8.85398 5.64601L5.35398 2.14601C5.30753 2.09945 5.25236 2.0625 5.19161 2.0373C5.13087 2.01209 5.06575 1.99911 4.99998 1.99911C4.93421 1.99911 4.86909 2.01209 4.80834 2.0373C4.7476 2.0625 4.69242 2.09945 4.64598 2.14601V2.14601Z" fill="#003D66"/>
</svg>
`;