import { Heading } from '@chakra-ui/react'

import { IProfile } from '../../data/profile'
import { PageContent } from '../PageContent'
import { UserEditForm } from '../UserEditForm'

export const UserEdit = ({ profile }: { profile: IProfile }) => {
  return (
    <PageContent>
      <Heading>Upravit uživatele</Heading>

      <UserEditForm profile={profile} />
    </PageContent>
  )
}
