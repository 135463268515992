import allianz from '../assets/images/companies-logos/allianz_64x48.png'
import cpp from '../assets/images/companies-logos/cpp_64x48.png'
import csob from '../assets/images/companies-logos/csob_64x48.png'
import direct from '../assets/images/companies-logos/direct_64x48.png'
import generali from '../assets/images/companies-logos/generali_64x48.png'
import hvp from '../assets/images/companies-logos/hvp_64x48.png'
import kooperativa from '../assets/images/companies-logos/kooperativa_64x48.png'
import pillow from '../assets/images/companies-logos/pillow_64x48.png'
import slavia from '../assets/images/companies-logos/slavia_64x48.png'
import uniqa from '../assets/images/companies-logos/uniqa_64x48.png'
import uniqa_axa from '../assets/images/companies-logos/uniqa-axa_64x48.png'
import vzp from '../assets/images/companies-logos/vzp_64x48.png'

interface Logos {
  [index: string]: string
}

export const logos: Logos = {
  allianz: allianz,
  cpp: cpp,
  csob: csob,
  direct: direct,
  generali: generali,
  hvp: hvp,
  kooperativa: kooperativa,
  pillow: pillow,
  slavia: slavia,
  uniqa: uniqa,
  uniqa_axa: uniqa_axa,
  vzp: vzp,
}
