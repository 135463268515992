import React from 'react'

export const removeNonDigits = (value: string): string => value.replace(/\D/g, '')

export const formatInputNumberForDisplay = (value: string | number | undefined | null): string => {
  if (value === null) {
    return ''
  }

  if (typeof value === 'undefined') {
    return ''
  }

  if (typeof value === 'number') {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const formatInputNumberForStore = (
  value: string | number | undefined,
): number | undefined => {
  if (typeof value === 'number') {
    return value
  }

  if (typeof value === 'undefined') {
    return 0
  }

  if (value === '') {
    return undefined
  }

  const number = Number(value.replace(/\D/g, ''))

  if (isNaN(number)) {
    return undefined
  }

  return number
}

export const NumberInputDecorator = {
  normalize: formatInputNumberForStore,
  normalizeFromEvent: (e: React.ChangeEvent<HTMLInputElement>) =>
    formatInputNumberForStore(e.target.value),
  denormalize: formatInputNumberForDisplay,
  removeNonDigits,
}
