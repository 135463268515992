import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { LoginForm } from '../../containers/LoginForm'

export const LoginPage = () => {
  return (
    <Section variant="login">
      <Container>
        <LoginForm />
      </Container>
    </Section>
  )
}
