import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import api from '../api'
import { IOfferOtherField } from '../../interfaces/entities/IOfferOtherField'

export const useGetOfferOtherFields = () => {
  return useQuery([ApiRouteEnum.OFFER_OTHER_FIELDS], () =>
    api.get<IOfferOtherField[]>(ApiRouteEnum.OFFER_OTHER_FIELDS),
  )
}
