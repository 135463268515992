import { Outlet } from 'react-router-dom'

import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { AppProvider } from '../context/AppContext'
import { IAvailableApps } from '../utils/availableApps'
import { Box, Flex } from '@chakra-ui/react'

export interface BaseLayoutProps {
  app: IAvailableApps['appId']
}

export const BaseLayout = ({ app }: BaseLayoutProps) => {
  return (
    <AppProvider app={app}>
      <Flex direction="column" style={{ minHeight: '100vh' }}>
        <Header />

        <Box w="100%" style={{ flex: '1' }}>
          <Outlet />
        </Box>

        <Footer />
      </Flex>
    </AppProvider>
  )
}
