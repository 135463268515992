import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { OrganizationCreate } from '../../containers/organization/OrganizationCreate'
import { organizations } from '../../data/organizations'

export const OrganizationCreatePage = () => {
  return (
    <Section>
      <Container>
        <OrganizationCreate
          organization={organizations.find((organization) => organization.id === 1)}
        />
      </Container>
    </Section>
  )
}
