import { useNavigate, useParams } from 'react-router-dom'

import { Container } from '../../../components/Container'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { Step4Form } from '../../../containers/sme/Step4Form'
import { Loading } from '../../../components/Loading'
import { InsuranceInfoSme } from './InsuranceInfoSme'
import { useFormStepGuard } from '../../../model/utils/formHelpers'
import React from 'react'
import { FormIsDisabledContext } from '../../../model/contexts/FormIsDisabledContext'
import { useOfferOrContractData } from '../../../model/hooks/useOfferOrContractData'
import {useState} from 'react';

export const Step4: React.FC<{ viewOnly?: boolean }> = ({ viewOnly = false }) => {
  const navigate = useNavigate()
  const { id } = useParams<'id'>()
  const [isLoading, setIsLoading] = useState(true);

  const [insureeEmail, setInsureeEmail] = useState<string>('')
  const handleInsureeEmailValue = (value: string) => {
    setInsureeEmail(value)
  }

  const successCallBack = () => {
    setIsLoading(false);
    return
  }

  const errorCallBack = (error: any) => {
    console.error(error)
  }
  const [data] = useOfferOrContractData(id, successCallBack, errorCallBack, viewOnly)

  const handleSubmit = () => {
    navigate(`/sme/wizard/edit/${id}/5`)
  }

  useFormStepGuard(4, data?.data)

  if (data?.data.item === undefined || isLoading) {
    return <Loading />
  }

  return (
    <>
        <Section>
          <Container>
            <StepsContent>
              <Steps icon="briefcase" currentStep={4} viewOnly={viewOnly} passed={data?.data.step ?? 0} />

              <InsuranceInfoSme
                emailAndPrintAvailable={true}
                offer={data.data}
                cloneAvailable={true}
                insureeEmail={insureeEmail}
              />
              <FormIsDisabledContext.Provider value={viewOnly}>
                <Step4Form onSubmit={handleSubmit} initialValues={data.data} viewOnly={viewOnly} onInsureeEmailValue={handleInsureeEmailValue} />
              </FormIsDisabledContext.Provider>
            </StepsContent>
          </Container>
        </Section>
    </>
  )
}
