import React from 'react'
import { IItem } from '../../../model/interfaces/entities/IItem'
import { OfferCompleteness } from './OfferCompleteness'
import { Box } from '@chakra-ui/react'
import { OfferPropertyColumn } from './OfferPropertyColumn'

export const OfferPropertyColumnWrapper: React.FC<{
  item?: IItem
  items: IItem[]
  status: 'success' | 'error'
  selectingItemsIDs?: number[]
  setSelectingItemsIDs?: Function
  viewOnly?: boolean
  offerId?: number
}> = ({ item, items, status, setSelectingItemsIDs, selectingItemsIDs, viewOnly, offerId }) => {
  return (
    <Box>
      <OfferCompleteness passed={status === 'success' ? 'fully' : 'partially'} />
      <Box display="flex">
        {items &&
          items.map(
            (
              {
                id,
                company,
                pricePerYear,
                pricePerInterval,
                responseBody,
                limits,
                requestBody,
                productName,
                pdfFile,
                errors,
                paymentFrequency,
                discount,
                contractResponseBody,
                contractRequestBody,
              },
              index,
            ) => {
              return (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <OfferPropertyColumn
                    item={item}
                    itemId={id.toString()}
                    key={index}
                    offerId={offerId?.toString()}
                    status={status}
                    company={company}
                    pricePerYear={pricePerYear}
                    responseBody={responseBody}
                    requestBody={requestBody}
                    limits={limits}
                    setSelectingItemsIDs={setSelectingItemsIDs}
                    selectingItemsIDs={selectingItemsIDs}
                    productName={productName}
                    pdfFile={pdfFile}
                    errors={errors}
                    viewOnly={viewOnly}
                    paymentFrequency={paymentFrequency}
                    discount={discount}
                    pricePerInterval={pricePerInterval}
                    contractResponseBody={contractResponseBody}
                    contractRequestBody={contractRequestBody}
                  />
                </Box>
              )
            },
          )}
      </Box>
    </Box>
  )
}
