import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import api from '../api'

export const useUpdateItemVisibility = (
  offerId?: string,
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (params: { items: {itemId: number, isVisible: boolean}[] }) =>
      api.post(`${ApiRouteEnum.OFFERS}/${offerId}/step3/items-visibility`, {
        items: params.items
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ApiRouteEnum.OFFERS])
      },
      onError: (error) => {
        console.log(error)
      },
    },
  )
}
