import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import api from '../api'
import { IOfferAdditionalInsurance } from '../../../containers/offers/grid/OfferAdditionalInsurance'

export const useOfferAdditionalInsurance = (id?: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (values: IOfferAdditionalInsurance) =>
      api.post(`${ApiRouteEnum.OFFERS}/${id}/step3/add`, {
        scope: values.range,
        companies: values.insurance.companies,
      }),
    {
      mutationKey: [`patch-${ApiRouteEnum.OFFERS}/step3`],
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [ApiRouteEnum.OFFERS, id] })
      },
      onError: (error) => {
        queryClient.invalidateQueries({ queryKey: [ApiRouteEnum.OFFERS, id] })
        console.log(error)
      },
    },
  )
}
