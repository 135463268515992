import type { SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools'

const baseStyle: SystemStyleObject = {
  display: 'inline-flex',
  w: 'auto',
  fontSize: 'md',
  marginEnd: 0,
  mb: 0,
  fontWeight: 'medium',
  lineHeight: '4',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  opacity: 1,
  _disabled: {
    opacity: 0.4,
  },
}

const variantSwitch: SystemStyleFunction = () => {
  return {
    marginEnd: 0,
    fontSize: 'sm',
    fontWeight: 'normal',
    lineHeight: 1,
  }
}

const variants = {
  switch: variantSwitch,
}

export const FormLabel = {
  baseStyle,
  variants,
}
