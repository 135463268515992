import { Button, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import { Pagination } from '../../components/Pagination'
import { TableWrapper } from '../../components/TableWrapper'
import { IContract } from '../../data/contracts'
import { IContracts, IPageLimit, IParams } from '../../model/interfaces/entities/IContracts'
import { CONTRACT_LIST_PAGE_LIMIT } from '../../pages/chaloupka/constats'
import { calculateTotalPages } from '../../utils/calculateTotalPages'
import { ContractRow } from './ContractRow'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const ContractsList = ({
  contracts,
  setQueryParams,
  queryParams,
  withPaginator = false,
  items,
  onNumberOfContracts,
}: {
  queryParams?: IPageLimit
  setQueryParams?: React.SetStateAction<any>
  params?: IParams
  contracts: Array<IContract>
  withPaginator?: boolean
  items?: IContracts
  onNumberOfContracts?: (value: number) => void
}) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (items?.items && onNumberOfContracts) {
      onNumberOfContracts(items?.count)
    }
  }, [items, onNumberOfContracts])

  const [startPage, setStartPage] = useState<number>(1)
  const [endPage, setEndPage] = useState<number>(10)

  return (
    <TableWrapper>
      <Table cellPadding="0" cellSpacing="0">
        <Thead>
          <Tr>
            <Th>
              <p>Číslo smlouvy</p>
            </Th>

            <Th>
              <p>Číslo nabídky</p>
            </Th>

            <Th>
              <p>Datum zahájení platnosti</p>
              <p>Datum sjednání</p>
            </Th>

            <Th sx={{ px: 0 }}>{/* <!-- Space for initials --> */}</Th>

            <Th sx={{ pl: 0 }}>
              <p>Pojistník</p>
            </Th>

            <Th>
              <p>Pojišťovna</p>
            </Th>

            <Th>
              <p>Sjednání</p>
            </Th>

            <Th>{/* <!-- Space for buttons --> */}</Th>
          </Tr>
        </Thead>

        <Tbody>
          <>
            {items?.items &&
              items.items.length > 0 &&
              items.items.map((contract) => {
                return <ContractRow key={contract.id} {...contract} />
              })}
          </>
        </Tbody>

        <Tfoot>
          <Tr>
            <Td colSpan={8}>
              {withPaginator && (
                <Pagination
                  totalPages={calculateTotalPages(items?.count, CONTRACT_LIST_PAGE_LIMIT)}
                  currentPage={queryParams?.page}
                  queryParams={queryParams}
                  setQueryParams={setQueryParams}
                  startPage={startPage}
                  endPage={endPage}
                  onSetStartPage={setStartPage}
                  onSetEndPage={setEndPage}
                  count={items?.count}
                />
              )}

              {!withPaginator && (
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() =>
                    navigate(`/sme/contracts?hasAniversary=${new Date().toJSON().slice(0, 10)}`)
                  }
                >
                  Více výročí smluv
                </Button>
              )}
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableWrapper>
  )
}
