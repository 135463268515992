import { Icon } from '@chakra-ui/react'

export const Trash = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M11.5 4C11.5 3.60218 11.342 3.22064 11.0607 2.93934C10.7794 2.65804 10.3978 2.5 10 2.5C9.60218 2.5 9.22064 2.65804 8.93934 2.93934C8.65804 3.22064 8.5 3.60218 8.5 4H7.5C7.5 3.33696 7.76339 2.70107 8.23223 2.23223C8.70107 1.76339 9.33696 1.5 10 1.5C10.663 1.5 11.2989 1.76339 11.7678 2.23223C12.2366 2.70107 12.5 3.33696 12.5 4H17C17.1326 4 17.2598 4.05268 17.3536 4.14645C17.4473 4.24021 17.5 4.36739 17.5 4.5C17.5 4.63261 17.4473 4.75979 17.3536 4.85355C17.2598 4.94732 17.1326 5 17 5H16.446L15.15 16.23C15.0936 16.7174 14.86 17.167 14.4936 17.4934C14.1272 17.8198 13.6537 18.0001 13.163 18H6.837C6.34635 18.0001 5.87279 17.8198 5.50642 17.4934C5.14005 17.167 4.90642 16.7174 4.85 16.23L3.553 5H3C2.88297 5.00004 2.76964 4.95903 2.67974 4.88411C2.58984 4.80919 2.52906 4.70511 2.508 4.59L2.5 4.5C2.5 4.36739 2.55268 4.24021 2.64645 4.14645C2.74021 4.05268 2.86739 4 3 4H11.5ZM15.438 5H4.561L5.843 16.115C5.87122 16.3588 5.9881 16.5837 6.1714 16.7469C6.35469 16.9101 6.59159 17.0002 6.837 17H13.163C13.4082 16.9999 13.6449 16.9097 13.828 16.7465C14.0111 16.5834 14.1278 16.3586 14.156 16.115L15.438 5ZM8.5 7.5C8.745 7.5 8.95 7.655 8.992 7.859L9 7.938V14.063C9 14.304 8.776 14.5 8.5 14.5C8.255 14.5 8.05 14.345 8.008 14.141L8 14.062V7.939C8 7.697 8.224 7.501 8.5 7.501V7.5ZM11.5 7.5C11.745 7.5 11.95 7.655 11.992 7.859L12 7.938V14.063C12 14.304 11.776 14.5 11.5 14.5C11.255 14.5 11.05 14.345 11.008 14.141L11 14.062V7.939C11 7.697 11.224 7.501 11.5 7.501V7.5Z" fill="currentColor"/>
	</Icon>
)


export const TrashString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5 4C11.5 3.60218 11.342 3.22064 11.0607 2.93934C10.7794 2.65804 10.3978 2.5 10 2.5C9.60218 2.5 9.22064 2.65804 8.93934 2.93934C8.65804 3.22064 8.5 3.60218 8.5 4H7.5C7.5 3.33696 7.76339 2.70107 8.23223 2.23223C8.70107 1.76339 9.33696 1.5 10 1.5C10.663 1.5 11.2989 1.76339 11.7678 2.23223C12.2366 2.70107 12.5 3.33696 12.5 4H17C17.1326 4 17.2598 4.05268 17.3536 4.14645C17.4473 4.24021 17.5 4.36739 17.5 4.5C17.5 4.63261 17.4473 4.75979 17.3536 4.85355C17.2598 4.94732 17.1326 5 17 5H16.446L15.15 16.23C15.0936 16.7174 14.86 17.167 14.4936 17.4934C14.1272 17.8198 13.6537 18.0001 13.163 18H6.837C6.34635 18.0001 5.87279 17.8198 5.50642 17.4934C5.14005 17.167 4.90642 16.7174 4.85 16.23L3.553 5H3C2.88297 5.00004 2.76964 4.95903 2.67974 4.88411C2.58984 4.80919 2.52906 4.70511 2.508 4.59L2.5 4.5C2.5 4.36739 2.55268 4.24021 2.64645 4.14645C2.74021 4.05268 2.86739 4 3 4H11.5ZM15.438 5H4.561L5.843 16.115C5.87122 16.3588 5.9881 16.5837 6.1714 16.7469C6.35469 16.9101 6.59159 17.0002 6.837 17H13.163C13.4082 16.9999 13.6449 16.9097 13.828 16.7465C14.0111 16.5834 14.1278 16.3586 14.156 16.115L15.438 5ZM8.5 7.5C8.745 7.5 8.95 7.655 8.992 7.859L9 7.938V14.063C9 14.304 8.776 14.5 8.5 14.5C8.255 14.5 8.05 14.345 8.008 14.141L8 14.062V7.939C8 7.697 8.224 7.501 8.5 7.501V7.5ZM11.5 7.5C11.745 7.5 11.95 7.655 11.992 7.859L12 7.938V14.063C12 14.304 11.776 14.5 11.5 14.5C11.255 14.5 11.05 14.345 11.008 14.141L11 14.062V7.939C11 7.697 11.224 7.501 11.5 7.501V7.5Z" fill="#003D66"/>
</svg>
`;