import React from 'react'
import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react'
import { Edit } from '../../icons'
import { IConceptRows } from '../../model/interfaces/entities/IConceptRows'
import { dateToCzechShortFormat } from '../../model/utils/dateUtils'
import { StepsProgress } from '../../components/StepsProgress'
import { useNavigate } from 'react-router-dom'
import { prefixUrlWithBasePath } from '../../model/utils/urlUtils'

export type Progress = 1 | 2 | 3 | 4 | 5

export const ConceptRow = ({
  id,
  dateCreated,
  insurance,
  number,
  user,
  status,
  canSend = false,
  canEdit = true,
  item,
  step,
  applicant,
}: IConceptRows) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { dateStart, name, companies } = insurance
  const navigate = useNavigate()

  const [buttonsVisible, setButtonsVisible] = useBoolean(false)

  return (
    <Tr onMouseEnter={setButtonsVisible.on} onMouseLeave={setButtonsVisible.off}>
      <Td>
        <Flex sx={{ columnGap: 4, rowGap: 1, flexWrap: 'wrap' }}>
          <Box sx={{ display: 'grid', gap: 1 }} className="contract__id-progress">
            <Link
              href={`/sme/wizard/edit/${id}/${step ? step + 1 : 1}`}
              cursor="pointer"
              sx={{ color: 'primary.400', fontSize: 'xl', fontWeight: 'semibold' }}
              _hover={{ textDecoration: 'none' }}
            >
              {id}
            </Link>
            <StepsProgress progress={(step ? step + 1 : 1) as Progress} />
          </Box>
        </Flex>
      </Td>

      <Td>
        {dateCreated && <Text sx={{ fontSize: 'sm' }}>{dateToCzechShortFormat(dateCreated)}</Text>}
        {dateStart && (
          <Text sx={{ fontSize: 'sm', fontWeight: 'semibold' }}>
            {dateToCzechShortFormat(dateStart)}
          </Text>
        )}
      </Td>

      <Td sx={{ px: 0 }}>
        {applicant.companyName && (
          <Avatar size="sm" colorScheme="primary" name={`${applicant.companyName}`} />
        )}
      </Td>

      <Td sx={{ pl: 0 }}>
        {applicant.companyName && <Text sx={{ fontSize: 'sm' }}>{applicant.companyName}</Text>}
      </Td>

      <Td>
        {item && (
          <Image
            src={prefixUrlWithBasePath(item.company.logo)}
            alt={item.company.name}
            width="64px"
            height="48px"
            key={item.company.id}
          />
        )}
      </Td>

      <Td>
        <Text
          sx={{
            color: name ? 'primary.500' : 'gray.500',
            fontSize: 'sm',
          }}
        >
          {name ? name : 'Nezadáno'}
        </Text>
        {user && (
          <Text sx={{ color: 'primary.500', fontSize: 'sm', fontWeight: 'semibold' }}>
            {user.firstName} {user.lastName}
          </Text>
        )}
      </Td>

      <Td></Td>

      <Td>
        <Flex
          sx={{ gap: 1, placeContent: 'end', visibility: buttonsVisible ? 'visible' : 'hidden' }}
        >
          {canSend && (
            <>
              <Button
                size="sm"
                colorScheme="primary"
                data-tooltip-content="Odešle nabídku na klientův e-mail."
                onClick={onOpen}
              >
                Odeslat klientovi
              </Button>

              <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Odeslat e-mail klientovi?</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Text>Přejete si odelsat e-mail klientovi?</Text>
                  </ModalBody>

                  <ModalFooter>
                    <Flex sx={{ gap: 2 }}>
                      <Button variant="outline" onClick={onClose}>
                        Zrušit
                      </Button>
                      <Button>Odeslat</Button>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
          )}

          {canEdit && (
            <Link href={`/sme/wizard/edit/${id}/${step || 1}`} _hover={{ textDecoration: 'none' }}>
              <Button variant="outline" size="sm">
                <Edit />
                Upravit
              </Button>
            </Link>
          )}
        </Flex>
      </Td>
    </Tr>
  )
}
