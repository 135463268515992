import { Button, Flex, Heading, useBoolean } from '@chakra-ui/react'
import React, { useState } from 'react'

import { Container } from '../../components/Container'
import { Loading } from '../../components/Loading'
import { Section } from '../../components/Section'
import { ContractsList } from '../../containers/contracts'
import { PageContent } from '../../containers/PageContent'
import { PageToolbar } from '../../containers/PageToolbar'

import { ContractsPropertySearchForm } from '../../containers/search/chaloupka/ContractsPropertySearchForm'
import { SearchButton } from '../../containers/search/SearchButton'
import { contractsProperties } from '../../data/contracts'
import { useGetContracts } from '../../model/api/contracts/useGetContracts'
import { IParams } from '../../model/interfaces/entities/IContracts'
import { CONTRACT_LIST_PAGE_LIMIT } from './constats'
import { IPagination } from '../../model/interfaces/entities/IContractsSearchForm'
import { useSearchParams } from 'react-router-dom'
import { convertParamsToSearchParams } from '../../model/utils/urlUtils'
import { omit } from 'lodash'
import { Export } from '../../icons'
import { ContractsPropertyExportForm } from '../../containers/search/chaloupka/ContractsPropertyExportForm'

export const ContractsPropertyPage = () => {
  const [isOpen, setOpen] = useBoolean(false)
  const [isExportOpen, setExportOpen] = useBoolean(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const [params, setRawParams] = useState<IPagination & IParams>({
    number: searchParams.get('number') || undefined,
    dateCreatedFrom: searchParams.get('dateCreatedFrom') || undefined,
    dateCreatedTo: searchParams.get('dateCreatedTo') || undefined,
    hasAniversary: searchParams.get('hasAniversary') || undefined,
    companyId: searchParams.get('companyId') || undefined,
    client: searchParams.get('client') || undefined,
    in: searchParams.get('in') || undefined,
    organizationId: searchParams.get('organizationId') || undefined,
    userId: searchParams.get('userId') || undefined,
    status: searchParams.get('status') ? searchParams.get('status')! : undefined,
    page: searchParams.get('page') ? parseInt(searchParams.get('page')!) : 1,
    limit: CONTRACT_LIST_PAGE_LIMIT,
  })

  const setParams = (newParams: IPagination & IParams) => {
    setSearchParams(convertParamsToSearchParams(omit(newParams, ['limit'])))
    setRawParams(newParams)
  }

  const { data, isLoading } = useGetContracts({ ...params })

  return (
    <Section>
      <Container>
        <PageContent>
          <PageToolbar
            leftElements={
              <Flex sx={{ gap: 4, alignItems: 'center' }}>
                <Heading as="h1">Smlouvy</Heading>
              </Flex>
            }
            rightElements={
              <Flex sx={{ gap: 4 }}>
                <Button variant="outline" onClick={setExportOpen.toggle}>
                  <Export color="secondary.500" style={{ transform: 'rotate(180deg)' }} />
                  {isExportOpen ? 'Skrýt export' : 'Export'}
                </Button>
                <SearchButton isOpen={isOpen} setOpen={setOpen} />
              </Flex>
            }
          />

          {isExportOpen && <ContractsPropertyExportForm />}
          {isOpen && <ContractsPropertySearchForm params={params} setParams={setParams} />}

          {isLoading ? (
            <Loading />
          ) : (
            <>
              <ContractsList
                contracts={contractsProperties}
                withPaginator
                setQueryParams={setParams}
                queryParams={params}
                items={data?.data}
              />
            </>
          )}
        </PageContent>
      </Container>
    </Section>
  )
}
