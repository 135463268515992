import { Icon } from '@chakra-ui/react'

export const Save = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H13.379C13.9094 3.00011 14.418 3.2109 14.793 3.586L16.414 5.207C16.7891 5.58199 16.9999 6.09061 17 6.621V15C17 15.5304 16.7893 16.0391 16.4142 16.4142C16.0391 16.7893 15.5304 17 15 17H5C4.46957 17 3.96086 16.7893 3.58579 16.4142C3.21071 16.0391 3 15.5304 3 15V5ZM5 4C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V15C4 15.2652 4.10536 15.5196 4.29289 15.7071C4.48043 15.8946 4.73478 16 5 16V11.5C5 11.1022 5.15804 10.7206 5.43934 10.4393C5.72064 10.158 6.10218 10 6.5 10H13.5C13.8978 10 14.2794 10.158 14.5607 10.4393C14.842 10.7206 15 11.1022 15 11.5V16C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15V6.621C15.9999 6.35581 15.8945 6.10149 15.707 5.914L14.086 4.293C13.8985 4.10545 13.6442 4.00006 13.379 4H13V6.5C13 6.89782 12.842 7.27936 12.5607 7.56066C12.2794 7.84196 11.8978 8 11.5 8H7.5C7.10218 8 6.72064 7.84196 6.43934 7.56066C6.15804 7.27936 6 6.89782 6 6.5V4H5ZM7 4V6.5C7 6.63261 7.05268 6.75979 7.14645 6.85355C7.24021 6.94732 7.36739 7 7.5 7H11.5C11.6326 7 11.7598 6.94732 11.8536 6.85355C11.9473 6.75979 12 6.63261 12 6.5V4H7ZM14 16V11.5C14 11.3674 13.9473 11.2402 13.8536 11.1464C13.7598 11.0527 13.6326 11 13.5 11H6.5C6.36739 11 6.24021 11.0527 6.14645 11.1464C6.05268 11.2402 6 11.3674 6 11.5V16H14Z" fill="currentColor"/>
	</Icon>
)


export const SaveString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H13.379C13.9094 3.00011 14.418 3.2109 14.793 3.586L16.414 5.207C16.7891 5.58199 16.9999 6.09061 17 6.621V15C17 15.5304 16.7893 16.0391 16.4142 16.4142C16.0391 16.7893 15.5304 17 15 17H5C4.46957 17 3.96086 16.7893 3.58579 16.4142C3.21071 16.0391 3 15.5304 3 15V5ZM5 4C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V15C4 15.2652 4.10536 15.5196 4.29289 15.7071C4.48043 15.8946 4.73478 16 5 16V11.5C5 11.1022 5.15804 10.7206 5.43934 10.4393C5.72064 10.158 6.10218 10 6.5 10H13.5C13.8978 10 14.2794 10.158 14.5607 10.4393C14.842 10.7206 15 11.1022 15 11.5V16C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15V6.621C15.9999 6.35581 15.8945 6.10149 15.707 5.914L14.086 4.293C13.8985 4.10545 13.6442 4.00006 13.379 4H13V6.5C13 6.89782 12.842 7.27936 12.5607 7.56066C12.2794 7.84196 11.8978 8 11.5 8H7.5C7.10218 8 6.72064 7.84196 6.43934 7.56066C6.15804 7.27936 6 6.89782 6 6.5V4H5ZM7 4V6.5C7 6.63261 7.05268 6.75979 7.14645 6.85355C7.24021 6.94732 7.36739 7 7.5 7H11.5C11.6326 7 11.7598 6.94732 11.8536 6.85355C11.9473 6.75979 12 6.63261 12 6.5V4H7ZM14 16V11.5C14 11.3674 13.9473 11.2402 13.8536 11.1464C13.7598 11.0527 13.6326 11 13.5 11H6.5C6.36739 11 6.24021 11.0527 6.14645 11.1464C6.05268 11.2402 6 11.3674 6 11.5V16H14Z" fill="#003D66"/>
</svg>
`;