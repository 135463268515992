import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { OrganizationEdit } from '../../containers/organization/OrganizationEdit'
import { organizations } from '../../data/organizations'

export const OrganizationEditPage = () => {
  return (
    <Section>
      <Container>
        <OrganizationEdit
          organization={organizations.find((organization) => organization.id === 1)}
        />
      </Container>
    </Section>
  )
}
