import { Button } from '@chakra-ui/react'
import { Search } from '../../icons'

interface SearchButtonProps {
  isOpen: boolean
  setOpen: {
    on: () => void
    off: () => void
    toggle: () => void
  }
}

export const SearchButton = ({ isOpen, setOpen }: SearchButtonProps) => {
  return (
    <Button variant="outline" onClick={setOpen.toggle}>
      <Search color="secondary.500" />
      {isOpen ? 'Skrýt vyhledávání' : 'Vyhledávání'}
    </Button>
  )
}
