import { IOfferCompany } from './utils/IOfferCompany'
import { offerStatusEnum } from './utils/offerStatusEnum'

interface IPropertyOffer {
  quoteId: number
  status: offerStatusEnum
  company: IOfferCompany
  validUntil: Date
}

export const carOffers: Array<IPropertyOffer> = [
  {
    quoteId: 42791,
    status: offerStatusEnum.Valid,
    company: { id: 'kooperativa', name: 'Pojišťovna Kooperativa, a.s.' },
    validUntil: new Date(2022, 6, 22),
  },
  {
    quoteId: 42792,
    status: offerStatusEnum.Valid,
    company: { id: 'direct', name: 'Direct pojišťovna, a.s.' },
    validUntil: new Date(2022, 6, 22),
  },
  {
    quoteId: 42793,
    status: offerStatusEnum.Selected,
    company: { id: 'direct', name: 'Direct pojišťovna, a.s.' },
    validUntil: new Date(2022, 6, 22),
  },
  {
    quoteId: 42794,
    status: offerStatusEnum.Error,
    company: { id: 'direct', name: 'Direct pojišťovna, a.s.' },
    validUntil: new Date(2022, 6, 22),
  },
]
