import { useNavigate, useParams } from 'react-router-dom'

import { Container } from '../../../components/Container'
import { Section } from '../../../components/Section'
import { Steps } from '../../../components/Steps'
import { StepsContent } from '../../../components/StepsContent'
import { Step2Form } from '../../../containers/sme/Step2Form'
import { IOffersItem } from '../../../model/interfaces/entities/IOffers'
import { Loading } from '../../../components/Loading'
import React from 'react'
import { InsuranceInfoSme } from './InsuranceInfoSme'
import { FormIsDisabledContext } from '../../../model/contexts/FormIsDisabledContext'
import { useOfferOrContractData } from '../../../model/hooks/useOfferOrContractData'

export const Step2: React.FC<{ viewOnly?: boolean }> = ({ viewOnly = false }) => {
  const navigate = useNavigate()
  const { id } = useParams<'id'>()
  const successCallBack = (resp: IOffersItem) => {}

  const errorCallBack = (error: any) => {
    console.error(error)
  }
  const [data, isLoading] = useOfferOrContractData(id, successCallBack, errorCallBack, viewOnly)

  const handleSubmit = () => {
    navigate(`/sme/wizard/edit/${id}/3`)
  }

  return (
    <Section>
      <Container>
        <StepsContent sx={{ minH: '3000px' }}>
          <Steps icon="briefcase" currentStep={2} viewOnly={viewOnly} passed={data?.data.step ?? 0} />
          {data ? (
            <>
              <FormIsDisabledContext.Provider value={viewOnly}>
                <InsuranceInfoSme emailAndPrintAvailable={false} offer={data.data} />
                <Step2Form
                  onSubmit={handleSubmit}
                  initialValues={data.data.additionalInsurance}
                  currentValidStep={data.data.step as number}
                  viewOnly={viewOnly}
                />
              </FormIsDisabledContext.Provider>
            </>
          ) : (
            <Loading />
          )}
        </StepsContent>
      </Container>
    </Section>
  )
}
