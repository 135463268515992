import { Icon } from '@chakra-ui/react'

export const HouseholdProperty = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 70 28" fill="none">
<path d="M9.5 15C9.89782 15 10.2794 14.842 10.5607 14.5607C10.842 14.2794 11 13.8978 11 13.5C11 13.1022 10.842 12.7206 10.5607 12.4393C10.2794 12.158 9.89782 12 9.5 12C9.10218 12 8.72064 12.158 8.43934 12.4393C8.15804 12.7206 8 13.1022 8 13.5C8 13.8978 8.15804 14.2794 8.43934 14.5607C8.72064 14.842 9.10218 15 9.5 15ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2H20C20.7956 2 21.5587 2.31607 22.1213 2.87868C22.6839 3.44129 23 4.20435 23 5V23C23 23.7956 22.6839 24.5587 22.1213 25.1213C21.5587 25.6839 20.7956 26 20 26H8C7.20435 26 6.44129 25.6839 5.87868 25.1213C5.31607 24.5587 5 23.7956 5 23V5ZM8 3.5C7.60218 3.5 7.22064 3.65804 6.93934 3.93934C6.65804 4.22064 6.5 4.60218 6.5 5V23C6.5 23.3978 6.65804 23.7794 6.93934 24.0607C7.22064 24.342 7.60218 24.5 8 24.5H20C20.3978 24.5 20.7794 24.342 21.0607 24.0607C21.342 23.7794 21.5 23.3978 21.5 23V5C21.5 4.60218 21.342 4.22064 21.0607 3.93934C20.7794 3.65804 20.3978 3.5 20 3.5H8Z" fill="currentColor"/>
<path d="M35 9V19M30 14H40" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M54.592 3.496C54.9913 3.17561 55.488 3.001 56 3.001C56.512 3.001 57.0087 3.17561 57.408 3.496L65.158 9.714C65.4209 9.92493 65.6331 10.1922 65.7788 10.4962C65.9246 10.8001 66.0001 11.1329 66 11.47V22.75C66 23.3467 65.7629 23.919 65.341 24.341C64.919 24.7629 64.3467 25 63.75 25H60.75C60.1533 25 59.581 24.7629 59.159 24.341C58.7371 23.919 58.5 23.3467 58.5 22.75V16.75C58.5 16.5511 58.421 16.3603 58.2803 16.2197C58.1397 16.079 57.9489 16 57.75 16H54.25C54.0511 16 53.8603 16.079 53.7197 16.2197C53.579 16.3603 53.5 16.5511 53.5 16.75V22.75C53.5 23.3467 53.2629 23.919 52.841 24.341C52.419 24.7629 51.8467 25 51.25 25H48.25C47.6533 25 47.081 24.7629 46.659 24.341C46.2371 23.919 46 23.3467 46 22.75V11.47C46 10.788 46.31 10.142 46.842 9.715L54.592 3.497V3.496ZM56.469 4.666C56.3359 4.55937 56.1705 4.50126 56 4.50126C55.8295 4.50126 55.6641 4.55937 55.531 4.666L47.781 10.884C47.6933 10.9542 47.6226 11.0433 47.5739 11.1445C47.5253 11.2458 47.5 11.3567 47.5 11.469V22.749C47.5 23.164 47.836 23.499 48.25 23.499H51.25C51.4489 23.499 51.6397 23.42 51.7803 23.2793C51.921 23.1387 52 22.9479 52 22.749V16.749C52 16.1523 52.2371 15.58 52.659 15.158C53.081 14.736 53.6533 14.499 54.25 14.499H57.75C58.0456 14.499 58.3382 14.5572 58.6113 14.6704C58.8843 14.7835 59.1324 14.9493 59.3413 15.1584C59.5503 15.3674 59.716 15.6156 59.829 15.8887C59.942 16.1617 60.0001 16.4544 60 16.75V22.75C60 23.165 60.336 23.5 60.75 23.5H63.75C63.9489 23.5 64.1397 23.421 64.2803 23.2803C64.421 23.1397 64.5 22.9489 64.5 22.75V11.47C64.5001 11.3577 64.475 11.2469 64.4265 11.1457C64.378 11.0444 64.3075 10.9553 64.22 10.885L56.47 4.667L56.469 4.666Z" fill="currentColor"/>
	</Icon>
)


export const HouseholdPropertyString=`<svg width="70" height="28" viewBox="0 0 70 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 15C9.89782 15 10.2794 14.842 10.5607 14.5607C10.842 14.2794 11 13.8978 11 13.5C11 13.1022 10.842 12.7206 10.5607 12.4393C10.2794 12.158 9.89782 12 9.5 12C9.10218 12 8.72064 12.158 8.43934 12.4393C8.15804 12.7206 8 13.1022 8 13.5C8 13.8978 8.15804 14.2794 8.43934 14.5607C8.72064 14.842 9.10218 15 9.5 15ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2H20C20.7956 2 21.5587 2.31607 22.1213 2.87868C22.6839 3.44129 23 4.20435 23 5V23C23 23.7956 22.6839 24.5587 22.1213 25.1213C21.5587 25.6839 20.7956 26 20 26H8C7.20435 26 6.44129 25.6839 5.87868 25.1213C5.31607 24.5587 5 23.7956 5 23V5ZM8 3.5C7.60218 3.5 7.22064 3.65804 6.93934 3.93934C6.65804 4.22064 6.5 4.60218 6.5 5V23C6.5 23.3978 6.65804 23.7794 6.93934 24.0607C7.22064 24.342 7.60218 24.5 8 24.5H20C20.3978 24.5 20.7794 24.342 21.0607 24.0607C21.342 23.7794 21.5 23.3978 21.5 23V5C21.5 4.60218 21.342 4.22064 21.0607 3.93934C20.7794 3.65804 20.3978 3.5 20 3.5H8Z" fill="#EE7400"/>
<path d="M35 9V19M30 14H40" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M54.592 3.496C54.9913 3.17561 55.488 3.001 56 3.001C56.512 3.001 57.0087 3.17561 57.408 3.496L65.158 9.714C65.4209 9.92493 65.6331 10.1922 65.7788 10.4962C65.9246 10.8001 66.0001 11.1329 66 11.47V22.75C66 23.3467 65.7629 23.919 65.341 24.341C64.919 24.7629 64.3467 25 63.75 25H60.75C60.1533 25 59.581 24.7629 59.159 24.341C58.7371 23.919 58.5 23.3467 58.5 22.75V16.75C58.5 16.5511 58.421 16.3603 58.2803 16.2197C58.1397 16.079 57.9489 16 57.75 16H54.25C54.0511 16 53.8603 16.079 53.7197 16.2197C53.579 16.3603 53.5 16.5511 53.5 16.75V22.75C53.5 23.3467 53.2629 23.919 52.841 24.341C52.419 24.7629 51.8467 25 51.25 25H48.25C47.6533 25 47.081 24.7629 46.659 24.341C46.2371 23.919 46 23.3467 46 22.75V11.47C46 10.788 46.31 10.142 46.842 9.715L54.592 3.497V3.496ZM56.469 4.666C56.3359 4.55937 56.1705 4.50126 56 4.50126C55.8295 4.50126 55.6641 4.55937 55.531 4.666L47.781 10.884C47.6933 10.9542 47.6226 11.0433 47.5739 11.1445C47.5253 11.2458 47.5 11.3567 47.5 11.469V22.749C47.5 23.164 47.836 23.499 48.25 23.499H51.25C51.4489 23.499 51.6397 23.42 51.7803 23.2793C51.921 23.1387 52 22.9479 52 22.749V16.749C52 16.1523 52.2371 15.58 52.659 15.158C53.081 14.736 53.6533 14.499 54.25 14.499H57.75C58.0456 14.499 58.3382 14.5572 58.6113 14.6704C58.8843 14.7835 59.1324 14.9493 59.3413 15.1584C59.5503 15.3674 59.716 15.6156 59.829 15.8887C59.942 16.1617 60.0001 16.4544 60 16.75V22.75C60 23.165 60.336 23.5 60.75 23.5H63.75C63.9489 23.5 64.1397 23.421 64.2803 23.2803C64.421 23.1397 64.5 22.9489 64.5 22.75V11.47C64.5001 11.3577 64.475 11.2469 64.4265 11.1457C64.378 11.0444 64.3075 10.9553 64.22 10.885L56.47 4.667L56.469 4.666Z" fill="#EE7400"/>
</svg>
`;