import { IndividualCompany } from '../IndividualCompany'
import { Checkbox, IndividualDiscountsGroup, IndividualDiscountsWrapper, LocalInputGroup } from '../IndividualDiscountsWrapper'


export const IndividualDiscounts = () => {
  return (
    <IndividualDiscountsWrapper>
      <IndividualDiscountsGroup>
        <IndividualCompany id="allianz" name="Allianz">
          <LocalInputGroup
            id="contract-allianz"
            name="contract-allianz"
            label="Náhrada smlouvy číslo"
            tooltip="Náhrada smlouvy číslo"
            optional
            placeholder="Zadejte číslo náhrady smlouvy"
          />
        </IndividualCompany>

        <IndividualCompany id="cpp" name="ČPP">
          <LocalInputGroup
            id="contract-cpp"
            name="contract-cpp"
            label="Náhrada smlouvy číslo"
            tooltip="Náhrada smlouvy číslo"
            optional
            placeholder="Zadejte číslo náhrady smlouvy"
          />
        </IndividualCompany>
      </IndividualDiscountsGroup>

      <IndividualDiscountsGroup>
        <IndividualCompany id="uniqa_axa" name="UNIQA (AXA)">
          <LocalInputGroup
            id="contract-other-uniqa-axa"
            name="contract-other-uniqa-axa"
            label="Pojistná smlouva u UNIQA (AXA)"
            optional
            placeholder="Číslo pojistné smlouvy"
          />
        </IndividualCompany>

        <IndividualCompany id="csob" name="ČSOB Pojišťovna">
          <LocalInputGroup
            id="contract-other-csob"
            name="contract-other-csob"
            label="Pojistná smlouva u ČSOB Pojištovna"
            optional
            placeholder="Číslo pojistné smlouvy"
          />

          <Checkbox id="ba-skoring" name="ba-skoring" label="BA Skóring" tooltip="BA Skóring" />
        </IndividualCompany>
      </IndividualDiscountsGroup>
    </IndividualDiscountsWrapper>
  )
}
