import queryString from 'query-string'
import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IContracts } from '../../interfaces/entities/IContracts'

export const useGetContracts = (params?: any) => {
  const query = queryString.stringify(params, { skipEmptyString: true })
  const offers: string = `${ApiRouteEnum.CONTRACT}?${query}`
  return useQuery([ApiRouteEnum.CONTRACT, query], () => api.get<IContracts>(offers), {
    retry: false,
  })
}
