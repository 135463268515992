import { Icon } from '@chakra-ui/react'

export const InsuranceElecricityShort = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M13 22.4499L27.9015 7L22.275 18.1188L36 19.5607L17.2918 41L27.2666 23.962L13 22.4499Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
	</Icon>
)


export const InsuranceElecricityShortString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 22.4499L27.9015 7L22.275 18.1188L36 19.5607L17.2918 41L27.2666 23.962L13 22.4499Z" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
</svg>
`;