import { Icon } from '@chakra-ui/react'

export const InsuranceBrokenPipe = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<rect x="0.5" y="19.5" rx="0.5" stroke="currentColor"/>
<path d="M21 22.25C20.5858 22.25 20.25 22.5858 20.25 23C20.25 23.4142 20.5858 23.75 21 23.75V22.25ZM21 23.75C21.517 23.75 21.9928 23.6476 22.4028 23.404C22.8177 23.1575 23.1072 22.801 23.3044 22.3917C23.6768 21.6184 23.75 20.5851 23.75 19.5H22.25C22.25 20.5913 22.1614 21.308 21.953 21.7407C21.8597 21.9343 21.754 22.0447 21.6367 22.1144C21.5146 22.1869 21.3212 22.25 21 22.25V23.75ZM23.75 19.5V2H22.25V19.5H23.75Z" fill="currentColor"/>
<path d="M2 23H15.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M29 36C34 36 37 33.5 37 28.5V2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2 36H24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<rect x="20.5" y="1.5" rx="0.5" transform="rotate(-90 20.5 1.5)" stroke="currentColor" strokeLinecap="round"/>
<path d="M15.5 23L22.5 27.5L18.5 31L24 36" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21 23L27 26L23.5 30.5L29 36" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
)


export const InsuranceBrokenPipeString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="19.5" width="1" height="20" rx="0.5" stroke="#EE7400"/>
<path d="M21 22.25C20.5858 22.25 20.25 22.5858 20.25 23C20.25 23.4142 20.5858 23.75 21 23.75V22.25ZM21 23.75C21.517 23.75 21.9928 23.6476 22.4028 23.404C22.8177 23.1575 23.1072 22.801 23.3044 22.3917C23.6768 21.6184 23.75 20.5851 23.75 19.5H22.25C22.25 20.5913 22.1614 21.308 21.953 21.7407C21.8597 21.9343 21.754 22.0447 21.6367 22.1144C21.5146 22.1869 21.3212 22.25 21 22.25V23.75ZM23.75 19.5V2H22.25V19.5H23.75Z" fill="#EE7400"/>
<path d="M2 23H15.5" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29 36C34 36 37 33.5 37 28.5V2" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 36H24" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<rect x="20.5" y="1.5" width="1" height="19" rx="0.5" transform="rotate(-90 20.5 1.5)" stroke="#EE7400" stroke-linecap="round"/>
<path d="M15.5 23L22.5 27.5L18.5 31L24 36" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 23L27 26L23.5 30.5L29 36" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;