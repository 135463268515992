import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Flex, Text, Button } from '@chakra-ui/react'

import { OfferHeader } from './OfferHeader'
import { BasicInfoTab, DiscountsTab, ExtraInsuranceTab, YearlyMilageTab } from './tabs/carkulka'
import { Check } from '../../icons'
import { formatDate } from '../../utils/formatDate'
import { offerStatusColors } from '../../data/offers/utils/offerStatusColors'
import { OfferWrapper } from './OfferWrapper'

export interface OfferProps {
  offerId: string
  quoteId: string
  status: string
  company: {
    id: string
    name: string
  }
  validUntil?: Date
}

const OfferFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ bgColor: 'gray.100', borderBottomRadius: 'md' }} as="footer">
      {children}
    </Box>
  )
}

export const OfferCar = ({ offerId, quoteId, status, company, validUntil }: OfferProps) => {
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const displayId = `${offerId}/${quoteId}`
  const elementId = `offer-${displayId.replace(/\//, '')}`
  const color = offerStatusColors[status]

  const scrollToOffer = (elementId: string) => {
    const element: HTMLElement | null = document.querySelector(`#${elementId}`)

    element?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  return (
    <OfferWrapper id={elementId}>
      <OfferHeader displayId={displayId} company={company} status={status} color={color} />

      <OfferFooter>
        <Tabs variant="default" colorScheme="primary" onChange={() => scrollToOffer(elementId)}>
          <TabPanels>
            <TabPanel>
              <BasicInfoTab status={status} color={color} />
            </TabPanel>
            <TabPanel>
              <ExtraInsuranceTab />
            </TabPanel>
            <TabPanel>
              <DiscountsTab />
            </TabPanel>
            <TabPanel>
              <YearlyMilageTab />
            </TabPanel>
          </TabPanels>

          <Flex
            sx={{
              display: {
                base: 'grid',
                md: 'flex',
              },
              rowGap: 2,
              px: [3, 3, 4],
              pb: [3, 3, 4],
              alignItems: 'center',
              justifyContent: { base: 'stretch', md: 'space-between' },
            }}
          >
            <TabList>
              <Tab>Základní přehled</Tab>
              <Tab>Připojištění</Tab>
              <Tab>Slevy</Tab>
              <Tab>Nájezd kilomterů</Tab>
            </TabList>

            <Flex
              sx={{
                gap: 8,
                alignItems: 'center',
                justifyContent: { base: 'space-between', lg: 'end' },
              }}
            >
              {validUntil && (
                <Text variant="textMuted" sx={{ fontSize: 'sm' }} as="p">
                  Nabídka platí do {formatDate({ date: validUntil })}
                </Text>
              )}

              {status !== 'error' && (
                <Button
                  colorScheme="primary"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  loadingText="Ukládám..."
                  onClick={() => {
                    setIsSubmitting(true)
                    setTimeout(() => {
                      navigate('/carkulka/wizard/4')
                    }, 1000)
                  }}
                >
                  <Check />
                  Sjednat
                </Button>
              )}
            </Flex>
          </Flex>
        </Tabs>
      </OfferFooter>
    </OfferWrapper>
  )
}
