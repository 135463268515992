import { Icon } from '@chakra-ui/react'

export const EditFilled = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 16 16" fill="none">
<path d="M12.007 6.81L6.058 12.76C5.739 13.078 5.339 13.305 4.902 13.414L2.619 13.984C2.53566 14.0048 2.44835 14.0037 2.36556 13.9808C2.28277 13.9579 2.20731 13.914 2.14652 13.8533C2.08573 13.7926 2.04167 13.7172 2.01862 13.6344C1.99558 13.5517 1.99433 13.4644 2.015 13.381L2.585 11.098C2.69488 10.6603 2.92163 10.2608 3.241 9.942L9.189 3.992L12.007 6.809V6.81ZM13.417 2.584C14.194 3.362 14.194 4.623 13.417 5.401L12.711 6.105L9.894 3.287L10.599 2.584C10.9726 2.21046 11.4792 2.00061 12.0075 2.00061C12.5358 2.00061 13.0424 2.21046 13.416 2.584H13.417Z" fill="currentColor"/>
	</Icon>
)


export const EditFilledString=`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.007 6.81L6.058 12.76C5.739 13.078 5.339 13.305 4.902 13.414L2.619 13.984C2.53566 14.0048 2.44835 14.0037 2.36556 13.9808C2.28277 13.9579 2.20731 13.914 2.14652 13.8533C2.08573 13.7926 2.04167 13.7172 2.01862 13.6344C1.99558 13.5517 1.99433 13.4644 2.015 13.381L2.585 11.098C2.69488 10.6603 2.92163 10.2608 3.241 9.942L9.189 3.992L12.007 6.809V6.81ZM13.417 2.584C14.194 3.362 14.194 4.623 13.417 5.401L12.711 6.105L9.894 3.287L10.599 2.584C10.9726 2.21046 11.4792 2.00061 12.0075 2.00061C12.5358 2.00061 13.0424 2.21046 13.416 2.584H13.417Z" fill="#A0AEC0"/>
</svg>
`;