import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

import { IUser } from '../../interfaces/entities/IUser'
import queryString from 'query-string'

export const useGetUser = (
  page?: number | undefined,
  limit?: number,
  search?: string,
  unreadOnly?: number,
  organizationId?: string,
  role?: string,
  disabled?: boolean,
) => {
  const query = queryString.stringify(
    {
      page,
      limit,
      isActive: unreadOnly,
      search,
      organizationId,
      role,
    },
    {
      skipEmptyString: true,
    },
  )

  const filterUser: string = `${ApiRouteEnum.USER}?${query}`
  return useQuery([ApiRouteEnum.USER, query], () => api.get<IUser>(filterUser), {
    retry: false,
    enabled: !disabled,
  })
}
