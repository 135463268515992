import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePatchStep2 = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id?: string,
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (form1Body: any) => api.patch(`${ApiRouteEnum.OFFERS}/${id}/step2`, form1Body),
    {
      mutationKey: [`${ApiRouteEnum.OFFERS}/step2`],
      onSuccess: (data) => {
        successCallBack(data.data)
        return queryClient.invalidateQueries({ queryKey: [ApiRouteEnum.ORGANIZATION, '/step2'] })
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
