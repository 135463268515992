import {
  Box,
  Flex,
  FormControl,
  Input,
  InputGroup,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tooltip,
} from '@chakra-ui/react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Card, CardBody } from '../../../../components/Card'
import { FormLabel } from '../../../../components/FormLabel'
import { Switch } from '../../../../components/Switch'
import { formatPrice } from '../../../../utils/formatPrice'
import {
  ExtraInsurancePropertyItemIconsEnum,
  getExtraInsurancePropertyIcon,
} from '../../../../utils/getExtraInsurancePropertyIcon'
import { CustomNumberInput } from '../../../CustomNumberInput'
import { LiabilitySlider } from '../../../LiabilitySlider'

interface IInsurance {
  name: string
  label: string
  selected?: boolean
  value?: number
  min?: number
  max?: number
}

const insurances: Array<IInsurance> = [
  { name: 'assistance', label: 'Asistence', selected: true, value: 100000, min: 0, max: 5000000 },
  {
    name: 'assistanceExtra',
    label: 'Asistence rozšířená',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
  {
    name: 'brokenPipe',
    label: 'Havárie rozvodů (lom trubky)',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
  { name: 'theft', label: 'Odcizení věci', selected: true, value: 100000, min: 0, max: 5000000 },
  {
    name: 'glassDamage',
    label: 'Pojištění skel',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
  { name: 'flood', label: 'Povodeň, záplava', selected: true, value: 100000, min: 0, max: 5000000 },
  {
    name: 'elecricityShort',
    label: 'Přepětí, podpětí, zkrat',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
  { name: 'vandalism', label: 'Vandalismus', selected: true, value: 100000, min: 0, max: 5000000 },
  {
    name: 'waterPipesDamage',
    label: 'Vodovodní škody',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
  {
    name: 'atmosphericLeakage',
    label: 'Zatečení atmosférických srážek',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
  {
    name: 'naturalDamage',
    label: 'Živelní škody',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
  {
    name: 'propertyOwnershipLiability',
    label: 'Odpovědnost z vlastnictví nemovitosti',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
  {
    name: 'lifeLiability',
    label: 'Odpovědnost z běžného života',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
  {
    name: 'valorization',
    label: 'Souhlas s valorizací pojistné částky',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
  {
    name: 'newPriceInsurance',
    label: 'Pojištění na novou cenu',
    selected: true,
    value: 100000,
    min: 0,
    max: 5000000,
  },
]

const InsuranceLimitIcon = ({ icon, color }: { icon: string; color?: string }) => {
  return (
    <Box>
      {getExtraInsurancePropertyIcon(icon as ExtraInsurancePropertyItemIconsEnum, '32px', color)}
    </Box>
  )
}

const InsuranceLimit = ({ name, label }: IInsurance) => {
  const { register, setValue, watch } = useFormContext()
  const fieldName = `${name}`
  register(fieldName)
  const fieldValue = watch(fieldName)
  const selected = !!fieldValue.selected

  return (
    <Popover placement="end-start">
      <PopoverTrigger>
        <Box sx={{ p: 1, borderRadius: 'md', cursor: 'pointer', _hover: { bg: 'gray.100' } }}>
          <Tooltip
            label={
              selected ? `Limit plnění: ${formatPrice({ price: fieldValue.value })}` : 'Neaktivní'
            }
            hasArrow
            openDelay={300}
            shouldWrapChildren
          >
            <InsuranceLimitIcon icon={name} color={selected ? 'secondary.500' : 'gray.800'} />
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <Card>
            <CardBody sx={{ p: 4, display: 'grid', gap: 4 }}>
              <FormControl>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                <Switch
                  name={fieldName}
                  isChecked={fieldValue.selected}
                  onChange={(event: any) => {
                    setValue(fieldName, { ...fieldValue, selected: !!event.currentTarget.checked })
                  }}
                />
              </FormControl>

              {selected && (
                <Box
                  sx={{
                    display: 'grid',
                    gap: 8,
                    gridTemplateColumns: { base: '1fr', md: '1fr 1.8fr' },
                  }}
                >
                  <FormControl>
                    <FormLabel>Limit plnění</FormLabel>

                    <InputGroup>
                      {fieldValue && (
                        <CustomNumberInput
                          defaultValue={fieldValue.value}
                          onValueChange={(value) => setValue(fieldName, { ...fieldValue, value })}
                          min={fieldValue.min}
                          max={fieldValue.max}
                        />
                      )}
                    </InputGroup>

                    <Input type="hidden" {...fieldValue} />
                  </FormControl>

                  <LiabilitySlider
                    name={name}
                    min={fieldValue.min}
                    max={fieldValue.max}
                    step={100000}
                    defaultValue={fieldValue.value}
                    onChange={(value) => {
                      setValue(fieldName, { ...fieldValue, value })
                    }}
                  />
                </Box>
              )}
            </CardBody>
          </Card>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export interface InsuranceLimitsSelectorValues {
  assistance: { selected: boolean; value: number }
  assistanceExtra: { selected: boolean; value: number }
  brokenPipe: { selected: boolean; value: number }
  theft: { selected: boolean; value: number }
  glassDamage: { selected: boolean; value: number }
  flood: { selected: boolean; value: number }
  elecricityShort: { selected: boolean; value: number }
  vandalism: { selected: boolean; value: number }
  waterPipesDamage: { selected: boolean; value: number }
  atmosphericLeakage: { selected: boolean; value: number }
  naturalDamage: { selected: boolean; value: number }
  propertyOwnershipLiability: { selected: boolean; value: number }
  lifeLiability: { selected: boolean; value: number }
  valorization: { selected: boolean; value: number }
  newPriceInsurance: { selected: boolean; value: number }
}

let defaultValues: {
  [index: string]: { selected: boolean; value: number; min: number; max: number }
} = {}

insurances.forEach((insurance) => {
  const key: string = insurance.name
  defaultValues[key] = {
    selected: insurance.selected || false,
    value: insurance.value || 0,
    min: insurance.min || 0,
    max: insurance.max || 5000000,
  }
})

export const InsuranceLimitsSelector = () => {
  const methods = useForm<InsuranceLimitsSelectorValues>({
    defaultValues,
  })
  const { handleSubmit } = methods

  const onSubmitForm = (values: InsuranceLimitsSelectorValues) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // onSubmit(values)
        resolve(true)
      }, 500)
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Flex sx={{ gap: 0.5, flexWrap: 'wrap' }}>
          {insurances &&
            insurances.length > 0 &&
            insurances.map(({ name, label }: IInsurance) => {
              return <InsuranceLimit key={name} name={name} label={label} />
            })}
        </Flex>
      </form>
    </FormProvider>
  )
}
