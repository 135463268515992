import { Icon } from '@chakra-ui/react'

export const StarEmpty = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M9.104 2.899C9.18652 2.73153 9.31428 2.59051 9.47281 2.4919C9.63134 2.39329 9.8143 2.34103 10.001 2.34103C10.1877 2.34103 10.3707 2.39329 10.5292 2.4919C10.6877 2.59051 10.8155 2.73153 10.898 2.899L12.828 6.81L17.145 7.437C17.3297 7.46382 17.5032 7.5418 17.6459 7.6621C17.7886 7.78241 17.8948 7.94025 17.9524 8.11776C18.01 8.29527 18.0169 8.48537 17.9721 8.66656C17.9273 8.84774 17.8327 9.01278 17.699 9.143L14.575 12.188L15.313 16.487C15.3445 16.6709 15.3239 16.86 15.2536 17.0328C15.1833 17.2056 15.066 17.3553 14.9151 17.465C14.7641 17.5746 14.5855 17.6398 14.3994 17.6533C14.2133 17.6667 14.0271 17.6278 13.862 17.541L10.002 15.511L6.14 17.541C5.97488 17.6276 5.78886 17.6662 5.60293 17.6526C5.41699 17.6391 5.23856 17.5738 5.08776 17.4642C4.93696 17.3546 4.81981 17.205 4.74953 17.0323C4.67924 16.8596 4.65862 16.6708 4.69 16.487L5.427 12.187L2.303 9.143C2.1693 9.01278 2.0747 8.84774 2.02992 8.66656C1.98514 8.48537 1.99196 8.29527 2.0496 8.11776C2.10725 7.94025 2.21342 7.78241 2.3561 7.6621C2.49879 7.5418 2.6723 7.46382 2.857 7.437L7.174 6.81L9.104 2.9V2.899Z" fill="white"/>
<path d="M9.104 2.899C9.18652 2.73153 9.31428 2.59051 9.47281 2.4919C9.63134 2.39329 9.8143 2.34103 10.001 2.34103C10.1877 2.34103 10.3707 2.39329 10.5292 2.4919C10.6877 2.59051 10.8155 2.73153 10.898 2.899L12.828 6.81L17.145 7.437C17.3297 7.46382 17.5032 7.5418 17.6459 7.6621C17.7886 7.78241 17.8948 7.94025 17.9524 8.11776C18.01 8.29527 18.0169 8.48537 17.9721 8.66656C17.9273 8.84774 17.8327 9.01278 17.699 9.143L14.575 12.188L15.313 16.487C15.3445 16.6709 15.3239 16.86 15.2536 17.0328C15.1833 17.2056 15.066 17.3553 14.9151 17.465C14.7641 17.5746 14.5855 17.6398 14.3994 17.6533C14.2133 17.6667 14.0271 17.6278 13.862 17.541L10.002 15.511L6.14 17.541C5.97488 17.6276 5.78886 17.6662 5.60293 17.6526C5.417 17.6391 5.23856 17.5738 5.08776 17.4642C4.93697 17.3546 4.81981 17.205 4.74953 17.0323C4.67924 16.8596 4.65862 16.6708 4.69 16.487L5.427 12.187L2.303 9.143C2.1693 9.01278 2.0747 8.84774 2.02992 8.66656C1.98514 8.48537 1.99196 8.29527 2.0496 8.11776C2.10725 7.94025 2.21342 7.78241 2.35611 7.6621C2.49879 7.5418 2.6723 7.46382 2.857 7.437L7.174 6.81L9.104 2.9V2.899ZM10.001 3.341L8.071 7.253C7.99924 7.39839 7.89319 7.52415 7.76201 7.61944C7.63084 7.71473 7.47845 7.7767 7.318 7.8L3 8.427L6.124 11.472C6.23997 11.5852 6.32669 11.7249 6.37669 11.8791C6.42669 12.0333 6.43846 12.1973 6.411 12.357L5.674 16.656L9.534 14.626C9.67755 14.5505 9.83731 14.511 9.9995 14.511C10.1617 14.511 10.3215 14.5505 10.465 14.626L14.326 16.656L13.589 12.356C13.5617 12.1964 13.5736 12.0326 13.6236 11.8786C13.6736 11.7247 13.7602 11.5851 13.876 11.472L17 8.427L12.683 7.8C12.5227 7.77655 12.3705 7.71451 12.2396 7.61923C12.1086 7.52394 12.0027 7.39826 11.931 7.253L10.001 3.341Z" fill="currentColor"/>
	</Icon>
)


export const StarEmptyString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.104 2.899C9.18652 2.73153 9.31428 2.59051 9.47281 2.4919C9.63134 2.39329 9.8143 2.34103 10.001 2.34103C10.1877 2.34103 10.3707 2.39329 10.5292 2.4919C10.6877 2.59051 10.8155 2.73153 10.898 2.899L12.828 6.81L17.145 7.437C17.3297 7.46382 17.5032 7.5418 17.6459 7.6621C17.7886 7.78241 17.8948 7.94025 17.9524 8.11776C18.01 8.29527 18.0169 8.48537 17.9721 8.66656C17.9273 8.84774 17.8327 9.01278 17.699 9.143L14.575 12.188L15.313 16.487C15.3445 16.6709 15.3239 16.86 15.2536 17.0328C15.1833 17.2056 15.066 17.3553 14.9151 17.465C14.7641 17.5746 14.5855 17.6398 14.3994 17.6533C14.2133 17.6667 14.0271 17.6278 13.862 17.541L10.002 15.511L6.14 17.541C5.97488 17.6276 5.78886 17.6662 5.60293 17.6526C5.41699 17.6391 5.23856 17.5738 5.08776 17.4642C4.93696 17.3546 4.81981 17.205 4.74953 17.0323C4.67924 16.8596 4.65862 16.6708 4.69 16.487L5.427 12.187L2.303 9.143C2.1693 9.01278 2.0747 8.84774 2.02992 8.66656C1.98514 8.48537 1.99196 8.29527 2.0496 8.11776C2.10725 7.94025 2.21342 7.78241 2.3561 7.6621C2.49879 7.5418 2.6723 7.46382 2.857 7.437L7.174 6.81L9.104 2.9V2.899Z" fill="white"/>
<path d="M9.104 2.899C9.18652 2.73153 9.31428 2.59051 9.47281 2.4919C9.63134 2.39329 9.8143 2.34103 10.001 2.34103C10.1877 2.34103 10.3707 2.39329 10.5292 2.4919C10.6877 2.59051 10.8155 2.73153 10.898 2.899L12.828 6.81L17.145 7.437C17.3297 7.46382 17.5032 7.5418 17.6459 7.6621C17.7886 7.78241 17.8948 7.94025 17.9524 8.11776C18.01 8.29527 18.0169 8.48537 17.9721 8.66656C17.9273 8.84774 17.8327 9.01278 17.699 9.143L14.575 12.188L15.313 16.487C15.3445 16.6709 15.3239 16.86 15.2536 17.0328C15.1833 17.2056 15.066 17.3553 14.9151 17.465C14.7641 17.5746 14.5855 17.6398 14.3994 17.6533C14.2133 17.6667 14.0271 17.6278 13.862 17.541L10.002 15.511L6.14 17.541C5.97488 17.6276 5.78886 17.6662 5.60293 17.6526C5.417 17.6391 5.23856 17.5738 5.08776 17.4642C4.93697 17.3546 4.81981 17.205 4.74953 17.0323C4.67924 16.8596 4.65862 16.6708 4.69 16.487L5.427 12.187L2.303 9.143C2.1693 9.01278 2.0747 8.84774 2.02992 8.66656C1.98514 8.48537 1.99196 8.29527 2.0496 8.11776C2.10725 7.94025 2.21342 7.78241 2.35611 7.6621C2.49879 7.5418 2.6723 7.46382 2.857 7.437L7.174 6.81L9.104 2.9V2.899ZM10.001 3.341L8.071 7.253C7.99924 7.39839 7.89319 7.52415 7.76201 7.61944C7.63084 7.71473 7.47845 7.7767 7.318 7.8L3 8.427L6.124 11.472C6.23997 11.5852 6.32669 11.7249 6.37669 11.8791C6.42669 12.0333 6.43846 12.1973 6.411 12.357L5.674 16.656L9.534 14.626C9.67755 14.5505 9.83731 14.511 9.9995 14.511C10.1617 14.511 10.3215 14.5505 10.465 14.626L14.326 16.656L13.589 12.356C13.5617 12.1964 13.5736 12.0326 13.6236 11.8786C13.6736 11.7247 13.7602 11.5851 13.876 11.472L17 8.427L12.683 7.8C12.5227 7.77655 12.3705 7.71451 12.2396 7.61923C12.1086 7.52394 12.0027 7.39826 11.931 7.253L10.001 3.341Z" fill="#ECC94B"/>
</svg>
`;