import { useMutation } from '@tanstack/react-query'
import api from '../api'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IOffersItem } from '../../interfaces/entities/IOffers'
import { AxiosResponse } from 'axios'

export const useCopyOffer = (
  onSuccess: (data: AxiosResponse<IOffersItem>, openInNewab: boolean) => void,
  onError: (data: any) => void,
  offerId?: string,
) => {
  return useMutation(
    (data?: any) =>
      api.post<undefined, AxiosResponse<IOffersItem>>(`${ApiRouteEnum.OFFERS}/${offerId}/copy`),
    {
      mutationKey: [ApiRouteEnum.OFFERS, offerId, 'clone'],
      onSuccess: (data, variables) => {
        onSuccess(data, variables)
      },
      onError: (error) => onError(error),
    },
  )
}
