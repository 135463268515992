import { IAvailableApps } from '../utils/availableApps'
import { ICompany } from '../utils/companies'
import { INewsItemV2 } from '../model/interfaces/entities/INews'

export interface INewsItem {
  id: number
  app: IAvailableApps['appId']
  isActive?: boolean
  author?: string
  company?: ICompany['id']
  title: string
  tagLabel?: string
  date?: string
  unread: boolean
  content?: string
  status: 'default' | 'success' | 'info' | 'warning' | 'danger'
  priority?: 'standard' | 'high'
  type?: 'info' | 'announcement' | 'warning' | 'outage' | 'danger'
}

export const NewsItemStatusColorMap = {
  default: 'gray',
  success: 'green',
  info: 'blue',
  warning: 'orange',
  danger: 'red',
}

export const getNewsItemStatusColor = (status: keyof typeof NewsItemStatusColorMap | undefined) =>
  status && NewsItemStatusColorMap[status]

export const NewsItemTypeColorMap = {
  info: 'blue',
  announcement: 'green',
  warning: 'orange',
  outage: 'red',
  danger: 'red',
}

export const getNewsItemTypeColor = (type: keyof typeof NewsItemTypeColorMap | undefined) =>
  type && NewsItemTypeColorMap[type]

export const filterNewsByApp = (news: Array<INewsItemV2>, app: IAvailableApps['appId']) => {
  return news.filter((newItem) => newItem.app === app)
}
