import { ConceptsCarSearchForm, ConceptsCarSearchFormValues } from './ConceptsCarSearchForm'

export const ConceptsCarSearch = () => {
  return (
    <ConceptsCarSearchForm
      onSubmit={(values: ConceptsCarSearchFormValues) => {
        console.log(values)
      }}
    />
  )
}
