import { forwardRef, Select, SelectProps } from '@chakra-ui/react'

export const WindowInsuranceSelect = forwardRef((props: SelectProps, ref) => {
  return (
    <Select ref={ref} {...props}>
      <option value="">
        Bez pojištění skel
      </option>
      <option value="205" data-value="5000">
        Pojištění skel s limitem 5 000 Kč
      </option>
      <option value="206" data-value="6000">
        Pojištění skel s limitem 6 000 Kč
      </option>
      <option value="207" data-value="7000">
        Pojištění skel s limitem 7 000 Kč
      </option>
      <option value="208" data-value="8000">
        Pojištění skel s limitem 8 000 Kč
      </option>
      <option value="209" data-value="9000">
        Pojištění skel s limitem 9 000 Kč
      </option>
      <option value="210" data-value="10000">
        Pojištění skel s limitem 10 000 Kč
      </option>
      <option value="211" data-value="11000">
        Pojištění skel s limitem 11 000 Kč
      </option>
      <option value="212" data-value="12000">
        Pojištění skel s limitem 12 000 Kč
      </option>
      <option value="213" data-value="13000">
        Pojištění skel s limitem 13 000 Kč
      </option>
      <option value="214" data-value="14000">
        Pojištění skel s limitem 14 000 Kč
      </option>
      <option value="215" data-value="15000">
        Pojištění skel s limitem 15 000 Kč
      </option>
      <option value="216" data-value="16000">
        Pojištění skel s limitem 16 000 Kč
      </option>
      <option value="217" data-value="17000">
        Pojištění skel s limitem 17 000 Kč
      </option>
      <option value="218" data-value="18000">
        Pojištění skel s limitem 18 000 Kč
      </option>
      <option value="219" data-value="19000">
        Pojištění skel s limitem 19 000 Kč
      </option>
      <option value="220" data-value="20000">
        Pojištění skel s limitem 20 000 Kč
      </option>
      <option value="221" data-value="30000">
        Pojištění skel s limitem 30 000 Kč
      </option>
      <option value="222" data-value="40000">
        Pojištění skel s limitem 40 000 Kč
      </option>
      <option value="223" data-value="50000">
        Pojištění skel s limitem 50 000 Kč
      </option>
      <option value="224" data-value="60000">
        Pojištění skel s limitem 60 000 Kč
      </option>
      <option value="225" data-value="70000">
        Pojištění skel s limitem 70 000 Kč
      </option>
      <option value="226" data-value="80000">
        Pojištění skel s limitem 80 000 Kč
      </option>
      <option value="227" data-value="90000">
        Pojištění skel s limitem 90 000 Kč
      </option>
      <option value="228" data-value="100000">
        Pojištění skel s limitem 100 000 Kč
      </option>
      <option value="229" data-value="110000">
        Pojištění skel s limitem 110 000 Kč
      </option>
      <option value="230" data-value="120000">
        Pojištění skel s limitem 120 000 Kč
      </option>
    </Select>
  )
})
