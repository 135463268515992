import { Button, Flex } from '@chakra-ui/react'
import { Document, Duplicate, Email, Print } from '../../icons'

export const OffersBottomToolbar = () => {
  return (
    <Flex
      sx={{
        w: '100%',
        flexWrap: 'wrap',
        justifyContent: { base: 'start', md: 'space-between' },
        gap: 2,
      }}
    >
      <Flex sx={{ gap: 2 }}>
        <Button variant="outline">
          <Duplicate color="secondary.500" />
          Klonovat nabídku
        </Button>
      </Flex>

      <Flex sx={{ gap: 2, flexWrap: 'wrap' }}>
        <Button variant="outline">
          <Email color="secondary.500" />
          E-mail
        </Button>

        <Button variant="outline">
          <Print color="secondary.500" />
          Tisk
        </Button>

        <Button variant="outline">
          <Document color="secondary.500" />
          IPID dokumenty
        </Button>
      </Flex>
    </Flex>
  )
}
