import { ContractsCarSearchForm, ContractsCarSearchFormValues } from './ContractsCarSearchForm'

export const ContractsCarSearch = () => {
  return (
    <ContractsCarSearchForm
      onSubmit={(values: ContractsCarSearchFormValues) => {
        console.log(values)
      }}
    />
  )
}
