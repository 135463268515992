import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { FAQList } from '../../containers/faq/FAQList'
import { PageContent } from '../../containers/PageContent'
import { PageToolbar } from '../../containers/PageToolbar'
import { useIsSupervisor } from '../../model/utils/userUtils'

export const FAQPage = () => {
  const isSupervisor = useIsSupervisor()

  return (
    <Section>
      <Container>
        <PageContent>
          <PageToolbar
            rightElements={
              <>
                {isSupervisor && (
                  <Button type="button" colorScheme="primary" as={Link} to="/faq/admin">
                    Upravit FAQ
                  </Button>
                )}
              </>
            }
          />
          <FAQList />
        </PageContent>
      </Container>
    </Section>
  )
}
