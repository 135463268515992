import { Icon } from '@chakra-ui/react'

export const Household = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 28 28" fill="none">
<path d="M9.5 15C9.89782 15 10.2794 14.842 10.5607 14.5607C10.842 14.2794 11 13.8978 11 13.5C11 13.1022 10.842 12.7206 10.5607 12.4393C10.2794 12.158 9.89782 12 9.5 12C9.10218 12 8.72064 12.158 8.43934 12.4393C8.15804 12.7206 8 13.1022 8 13.5C8 13.8978 8.15804 14.2794 8.43934 14.5607C8.72064 14.842 9.10218 15 9.5 15ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2H20C20.7956 2 21.5587 2.31607 22.1213 2.87868C22.6839 3.44129 23 4.20435 23 5V23C23 23.7956 22.6839 24.5587 22.1213 25.1213C21.5587 25.6839 20.7956 26 20 26H8C7.20435 26 6.44129 25.6839 5.87868 25.1213C5.31607 24.5587 5 23.7956 5 23V5ZM8 3.5C7.60218 3.5 7.22064 3.65804 6.93934 3.93934C6.65804 4.22064 6.5 4.60218 6.5 5V23C6.5 23.3978 6.65804 23.7794 6.93934 24.0607C7.22064 24.342 7.60218 24.5 8 24.5H20C20.3978 24.5 20.7794 24.342 21.0607 24.0607C21.342 23.7794 21.5 23.3978 21.5 23V5C21.5 4.60218 21.342 4.22064 21.0607 3.93934C20.7794 3.65804 20.3978 3.5 20 3.5H8Z" fill="currentColor"/>
	</Icon>
)


export const HouseholdString=`<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 15C9.89782 15 10.2794 14.842 10.5607 14.5607C10.842 14.2794 11 13.8978 11 13.5C11 13.1022 10.842 12.7206 10.5607 12.4393C10.2794 12.158 9.89782 12 9.5 12C9.10218 12 8.72064 12.158 8.43934 12.4393C8.15804 12.7206 8 13.1022 8 13.5C8 13.8978 8.15804 14.2794 8.43934 14.5607C8.72064 14.842 9.10218 15 9.5 15ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2H20C20.7956 2 21.5587 2.31607 22.1213 2.87868C22.6839 3.44129 23 4.20435 23 5V23C23 23.7956 22.6839 24.5587 22.1213 25.1213C21.5587 25.6839 20.7956 26 20 26H8C7.20435 26 6.44129 25.6839 5.87868 25.1213C5.31607 24.5587 5 23.7956 5 23V5ZM8 3.5C7.60218 3.5 7.22064 3.65804 6.93934 3.93934C6.65804 4.22064 6.5 4.60218 6.5 5V23C6.5 23.3978 6.65804 23.7794 6.93934 24.0607C7.22064 24.342 7.60218 24.5 8 24.5H20C20.3978 24.5 20.7794 24.342 21.0607 24.0607C21.342 23.7794 21.5 23.3978 21.5 23V5C21.5 4.60218 21.342 4.22064 21.0607 3.93934C20.7794 3.65804 20.3978 3.5 20 3.5H8Z" fill="#EE7400"/>
</svg>
`;