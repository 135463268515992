import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { ICompanyV2 } from '../../interfaces/entities/ICompany'
import { ICompany } from '../../../utils/companies'
export const useGetCompanies = () => {
  return useQuery([ApiRouteEnum.COMPANY], () => api.get<ICompanyV2[]>(ApiRouteEnum.COMPANY), {
    retry: false,
  })
}
