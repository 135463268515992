import { useMutation } from '@tanstack/react-query'
import api from '../api'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import queryString from 'query-string'
import moment from 'moment'

export const useGetContractsExportMutation = () => {
  return useMutation(
    (data: any) => {
      const query = queryString.stringify({
        dateFrom: moment(data.dateFrom).format(),
        dateTo: moment(data.dateTo).format(),
        type: data.type,
        email: data.email,
      })
      return api.get(`${ApiRouteEnum.CONTRACT}/export?${query}`)
    },
    {
      mutationKey: [],
    },
  )
}
