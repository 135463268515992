import {
  Box,
  BoxProps,
  Button,
  Flex,
  FormControl,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useBoolean,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { MouseEvent, MouseEventHandler, useEffect, useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { Card, CardBody } from '../components/Card'
import { FormLabel } from '../components/FormLabel'
import { Duplicate, Email, Note, Print, Save } from '../icons'
import { formatDate } from '../utils/formatDate'
import { usePostOfferSendMail } from '../model/api/offers/usePostOfferSendMail'
import { usePostOfferGeneratePDF } from '../model/api/offers/usePostOfferGeneratePDF'
import { AxiosResponse } from 'axios'
import ModalEmailForm from '../components/ModalEmailForm'
import { useCustomCopyOffer } from '../model/hooks/useCustomCopyOffer'
import useCloneClickHandler from '../model/hooks/useCloneClickHandler'

interface InsuranceInfoBasicProps extends BoxProps {
  isSticky: boolean
}

interface InsuranceInfoFooterProps extends BoxProps {}

interface InsuranceInfoBrokerProps {
  broker: { name: string; link: string; email?: string }
  company: { name: string; link: string }
  date: Date
}

interface InsuranceInfoProps {
  offerId?: string
  startDate?: Date
  insureeDetails?: string | React.ReactNode
  subjectDetails?: string
  additionalInfo?: string | React.ReactNode
  broker?: InsuranceInfoBrokerProps
  emailAndPrintAvailable?: boolean
  cloneAvailable?: boolean
  insureeEmail?: string
}

const InsuranceInfoBasic = ({ children, sx, isSticky, ...rest }: InsuranceInfoBasicProps) => {
  let _sx: typeof sx = {
    display: { base: 'grid', md: 'flex' },
    gridTemplateAreas: '"number date" "details details"',
    rowGap: 4,
    columnGap: 8,
    ...sx,
  }

  if (isSticky) {
    _sx = {
      ..._sx,
      p: { lg: 4 },
      m: { lg: '-4' },
      bgColor: { lg: 'white' },
      boxShadow: { lg: 'card' },
      borderBottomRadius: { lg: 'md' },
    }
  }

  return (
    <Box sx={_sx} {...rest}>
      {children}
    </Box>
  )
}

const InsuranceInfoItem = ({
  children,
  label,
  value,
}: {
  children?: React.ReactNode
  label: string
  value: string | number
}) => {
  return (
    <Box sx={{ display: 'grid', gap: 1, alignContent: 'start', justifyItems: 'start' }}>
      <Text sx={{ fontSize: 'sm', fontWeight: 'semibold', lineHeight: 'body' }}>{label}</Text>
      <Text sx={{ color: 'primary.400', fontSize: 'lg', fontWeight: 'medium' }}>{value}</Text>

      {children && children}
    </Box>
  )
}

const InsuranceInfoDetails = ({
  insureeDetails,
  subjectDetails,
  additionalInfo,
}: {
  insureeDetails?: InsuranceInfoProps['insureeDetails']
  subjectDetails?: InsuranceInfoProps['subjectDetails']
  additionalInfo?: InsuranceInfoProps['additionalInfo']
}) => {
  return (
    <Box sx={{ gridArea: 'details', fontSize: 'sm' }}>
      {insureeDetails && <Text sx={{ fontWeight: 'medium' }}>{insureeDetails}</Text>}
      {subjectDetails && (
        <Text sx={{ fontWeight: 'medium' }} dangerouslySetInnerHTML={{ __html: subjectDetails }} />
      )}
      Pojištění podnikání
    </Box>
  )
}

const InsuranceInfoNote = ({
  children,
  sx,
  onClick,
  ...rest
}: {
  children: React.ReactNode
  sx?: BoxProps
  onClick?: MouseEventHandler<HTMLDivElement> | undefined
}) => {
  return (
    <Flex sx={{ gap: 2, alignItems: 'center', fontSize: 'sm', ...sx }} onClick={onClick} {...rest}>
      <Note color="secondary.500" />

      {children}
    </Flex>
  )
}

const InsuranceInfoFooter = ({ children, sx, ...rest }: InsuranceInfoFooterProps) => {
  return (
    <Box
      sx={{
        display: { base: 'grid', md: 'flex' },
        rowGap: { base: 4, lg: 0 },
        alignItems: 'center',
        justifyContent: 'space-between',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const InsuranceInfoBroker = ({ broker, company, date }: InsuranceInfoBrokerProps) => {
  return (
    <Text sx={{ fontSize: 'sm', fontWeight: 'medium' }} as="p">
      <Text sx={{ fontWeight: 'semibold' }} as="span">
        Nabídku zpracoval(a):&nbsp;
      </Text>
      <Link as={RouterLink} colorScheme="blue" to={broker.link}>
        {broker.name}
      </Link>
      ,&nbsp;
      <Link as={RouterLink} colorScheme="blue" to={company.link}>
        {company.name}
      </Link>
      ,&nbsp;
      {broker.email && (
        <Link colorScheme="blue" href={`mailto:${broker.email}`}>
          {broker.email}
        </Link>
      )}
      ,&nbsp;
      <Text as="span">
        {Intl.DateTimeFormat('cs-CZ', { dateStyle: 'medium', timeStyle: 'medium' }).format(date)}
      </Text>
    </Text>
  )
}

interface InsuranceInfoWrapperProps extends BoxProps {
  isSticky: boolean
}

const InsuranceInfoWrapper = ({ isSticky, ...rest }: InsuranceInfoWrapperProps) => {
  return (
    <Box
      as="aside"
      sx={{
        position: {
          base: 'static',
          lg: isSticky && 'sticky',
        },
        top: 0,
        zIndex: {
          base: 'base',
          lg: 'sticky',
        },
      }}
      {...rest}
    />
  )
}

const getOffset = (element: Element | null) => element?.getBoundingClientRect()?.y

export const InsuranceInfo = ({
  offerId,
  startDate,
  insureeDetails,
  subjectDetails,
  additionalInfo,
  broker,
  emailAndPrintAvailable,
  cloneAvailable,
  insureeEmail,
}: InsuranceInfoProps) => {
  const insuranceInfoWrapper = document.querySelector('.js-insurance-info-wrapper')
  const [isSticky, setIsSticky] = useState(false)
  const [showNoteTextarea, setShowNoteTextarea] = useBoolean(false)
  const [note, setNote] = useState('')
  const shouldStick = isSticky && !showNoteTextarea
  const toastMessage = useToast()
  const { id } = useParams<'id'>()
  const {
    isOpen: isEmailModalOpen,
    onOpen: onEmailModalOpen,
    onClose: onEmailModalClose,
  } = useDisclosure()
  useEffect(() => {
    const listenToScroll = () => {
      const heightToHide = getOffset(insuranceInfoWrapper) || 0

      setIsSticky(heightToHide <= 0)
    }

    const scrollInterval = setInterval(listenToScroll, 16)

    return () => clearInterval(scrollInterval)
  }, [insuranceInfoWrapper])

  const handleSaveNote = (event: any) => {
    const value = event.target.value.trim()

    setNote(value)
    setShowNoteTextarea.off()
  }

  const getFileNameFromBlobResponse = (response: AxiosResponse<BlobPart>): string | null => {
    let disposition = response.headers['content-disposition']

    if (!disposition) {
      return null
    }
    const match = disposition.match(/filename\*=utf-8''(.*)/)

    // @ts-ignore
    if (match.length < 2) {
      return null
    }

    // @ts-ignore
    return decodeURI(match[1])
  }

  const openPdfAsDownload = (blob: BlobPart, fileName = 'Report.pdf'): void => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let newBlob = new Blob([blob], { type: 'application/pdf' })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //@ts-ignore
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //@ts-ignore
      window.navigator.msSaveOrOpenBlob(newBlob)
      return
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob)
    let link = document.createElement('a')
    document.body.append(link)
    link.href = data
    link.download = fileName
    link.target = '_blank'
    link.click()
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data)
      link.remove()
    }, 100)
  }

  const successSendMailCallBack = (data: any) => {
    toastMessage({
      title: 'Odesláno',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
    onEmailModalClose()
  }

  const errorSendMailCallBack = (error: any) => {
    toastMessage({
      title: 'Something was wrong!',
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }

  const successGeneratePDFCallBack = (dataFromBE: AxiosResponse<BlobPart>) => {
    const fileName: string | null = getFileNameFromBlobResponse(dataFromBE)
    openPdfAsDownload(dataFromBE.data, fileName !== null ? fileName : 'Dokument.pdf')
  }

  const errorGeneratePDFCallBack = (error: any) => {
    toastMessage({
      title: error.response?.data.error,
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }

  const { mutate: generatePDFMutate } = usePostOfferGeneratePDF(
    successGeneratePDFCallBack,
    errorGeneratePDFCallBack,
    id,
  )

  const { mutate: sendMailMutate } = usePostOfferSendMail(
    successSendMailCallBack,
    errorSendMailCallBack,
    id,
  )

  const { mutateCopyOffer, isCopyingOffer } = useCustomCopyOffer(id)

  const handleCloneClick = useCloneClickHandler(mutateCopyOffer)

  // @ts-ignore
  return (
    <InsuranceInfoWrapper className="js-insurance-info-wrapper" isSticky={shouldStick}>
      <Card
        sx={{
          bgColor: shouldStick ? { lg: 'transparent' } : 'white',
          boxShadow: shouldStick ? { base: 'card', lg: 'none' } : 'card',
        }}
      >
        <CardBody sx={{ display: 'grid', p: 4, gap: 4 }}>
          <InsuranceInfoBasic isSticky={shouldStick} className="insurance-info">
            {offerId && <InsuranceInfoItem label="Nabídka pojištění" value={offerId} />}

            {startDate && (
              <InsuranceInfoItem
                label="Počátek pojištění"
                value={formatDate({ date: startDate })}
              />
            )}

            <InsuranceInfoDetails
              insureeDetails={insureeDetails}
              subjectDetails={subjectDetails}
              additionalInfo={additionalInfo}
            />
          </InsuranceInfoBasic>

          <InsuranceInfoFooter
            as="footer"
            sx={{ transition: 'all 100ms', opacity: shouldStick ? { lg: 0 } : 1 }}
          >
            {broker && (
              <InsuranceInfoBroker
                broker={broker?.broker}
                company={broker?.company}
                date={broker?.date}
              />
            )}

            <Flex sx={{ gap: 2 }} className="insurance-info__footer-buttons">
              {!showNoteTextarea && emailAndPrintAvailable && (
                <>
                  <Button variant="outline" size="sm" type="button" onClick={onEmailModalOpen}>
                    <Email color="secondary.500" />
                    E-mail
                  </Button>
                  <Modal isOpen={isEmailModalOpen} onClose={onEmailModalClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>E-mail</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <ModalEmailForm insureeEmail={insureeEmail} onMailMutate={sendMailMutate} />
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </>
              )}
              {!showNoteTextarea && emailAndPrintAvailable && (
                <Button
                  variant="outline"
                  size="sm"
                  type="button"
                  onClick={() => generatePDFMutate()}
                >
                  <Print color="secondary.500" />
                  Tisk
                </Button>
              )}
              {cloneAvailable && (
                <Button
                  isLoading={isCopyingOffer}
                  variant="outline"
                  size="sm"
                  type="button"
                  onMouseDown={handleCloneClick}
                >
                  <Duplicate color="secondary.500" />
                  Klonovat
                </Button>
              )}

              {showNoteTextarea && (
                <Button
                  variant="solid"
                  colorScheme="primary"
                  size="sm"
                  type="button"
                  onClick={setShowNoteTextarea.off}
                >
                  <Save color="white" />
                  Uložit poznámku
                </Button>
              )}
            </Flex>
          </InsuranceInfoFooter>

          {showNoteTextarea && (
            <FormControl variant="fullWidth">
              <FormLabel>Poznámka ke kalkulaci</FormLabel>
              <Textarea autoFocus defaultValue={note} onBlur={handleSaveNote} />
            </FormControl>
          )}

          {note && !showNoteTextarea && (
            <InsuranceInfoNote
              sx={{ transition: 'all 100ms', opacity: isSticky ? { lg: 0 } : 1 }}
              onClick={setShowNoteTextarea.on}
            >
              <Text variant="textMuted">{note}</Text>
            </InsuranceInfoNote>
          )}
        </CardBody>
      </Card>
    </InsuranceInfoWrapper>
  )
}
