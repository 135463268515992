import { IndividualCompany } from '../IndividualCompany'
import {
  Checkbox,
  IndividualDiscountsGroup,
  IndividualDiscountsWrapper,
  LocalInputGroup,
} from '../IndividualDiscountsWrapper'

export const IndividualDiscounts = () => {
  return (
    <IndividualDiscountsWrapper>
      <IndividualDiscountsGroup>
        <IndividualCompany id="cpp" name="ČPP">
          <Checkbox
            id="extrabenefit-profi-pov"
            name="extrabenefit-profi-pov"
            label="Extrabenefit PROFI POV"
            tooltip="Extrabenefit PROFI POV"
          />
          <Checkbox
            id="spouucast-v-zahranici"
            name="spouucast-v-zahranici"
            label="Spoluúčast v zahraničí"
            tooltip="Spoluúčast v zahraničí"
          />

          <LocalInputGroup
            id="contract-cpp"
            name="contract-cpp"
            label="Náhrada smlouvy číslo"
            tooltip="Náhrada smlouvy číslo"
            optional
            placeholder="Zadejte číslo náhrady smlouvy"
          />
        </IndividualCompany>

        <IndividualCompany id="csob" name="ČSOB Pojišťovna">
          <Checkbox id="ba-skoring" name="ba-skoring" label="BA Skóring" tooltip="BA Skóring" />

          <LocalInputGroup
            id="contract-other-csob"
            name="contract-other-csob"
            label="Pojistná smlouva u ČSOB Pojištovna"
            optional
            placeholder="Číslo pojistné smlouvy"
          />

          <LocalInputGroup
            id="contract-csob"
            name="contract-csob"
            label="Náhrada smlouvy číslo"
            tooltip="Náhrada smlouvy číslo"
            optional
            placeholder="Zadejte číslo náhrady smlouvy"
          />
        </IndividualCompany>

        <IndividualCompany id="slavia" name="Slavia pojišťovna">
          <Checkbox id="jeden-ridic" name="jeden-ridic" label="Jeden řidič" tooltip="Jeden řidič" />
          <Checkbox
            id="sleva-jen-cr"
            name="sleva-jen-cr"
            label="Sleva jen ČR"
            tooltip="Sleva jen ČR"
          />
          <Checkbox
            id="plneni-rozpoctem"
            name="plneni-rozpoctem"
            label="Plnění rozpočtem"
            tooltip="Plnění rozpočtem"
          />

          <LocalInputGroup
            id="contract-other-slavia"
            name="contract-other-slavia"
            label="Pojistná smlouva u Slavia pojišťovna"
            optional
            placeholder="Číslo pojistné smlouvy"
          />

          <LocalInputGroup
            id="contract-slavia"
            name="contract-slavia"
            label="Náhrada smlouvy číslo"
            tooltip="Náhrada smlouvy číslo"
            optional
            placeholder="Zadejte číslo náhrady smlouvy"
          />
        </IndividualCompany>

        <IndividualCompany id="kooperativa" name="Kooperativa">
          <Checkbox
            id="preferovany-zpusob-likvidace"
            name="preferovany-zpusob-likvidace"
            label="Preferovaný způsob likvidace"
            tooltip="Preferovaný způsob likvidace"
          />

          <LocalInputGroup
            id="contract-kooperativa"
            name="contract-kooperativa"
            label="Náhrada smlouvy číslo"
            tooltip="Náhrada smlouvy číslo"
            optional
            placeholder="Zadejte číslo náhrady smlouvy"
          />
        </IndividualCompany>
      </IndividualDiscountsGroup>

      <IndividualDiscountsGroup>
        <IndividualCompany id="pillow" name="Pillow">
          <Checkbox
            id="vozidlo-parkuje-v-garazi"
            name="vozidlo-parkuje-v-garazi"
            label="Vozidlo parkuje v garáži"
            tooltip="Vozidlo parkuje v garáži"
          />
          <Checkbox
            id="oprava-pouze-ve-smluvni-opravne"
            name="oprava-pouze-ve-smluvni-opravne"
            label="Oprava pouze ve smluvní opravně"
            tooltip="Oprava pouze ve smluvní opravně"
          />

          <LocalInputGroup
            id="contract-pillow"
            name="contract-pillow"
            label="Náhrada smlouvy číslo"
            tooltip="Náhrada smlouvy číslo"
            optional
            placeholder="Zadejte číslo náhrady smlouvy"
          />
        </IndividualCompany>

        <IndividualCompany id="generali" name="Generali Česká pojišťovna">
          <Checkbox
            id="akceptace-doporucene-opravny"
            name="akceptace-doporucene-opravny"
            label="Akceptace doporučené opravny"
            tooltip="Akceptace doporučené opravny"
          />

          <LocalInputGroup
            id="contract-generali"
            name="contract-generali"
            label="Náhrada smlouvy číslo"
            tooltip="Náhrada smlouvy číslo"
            optional
            placeholder="Zadejte číslo náhrady smlouvy"
          />
        </IndividualCompany>

        <IndividualCompany id="uniqa" name="UNIQA">
          <Checkbox
            id="drzitel-ztp-ztpp"
            name="drzitel-ztp-ztpp"
            label="Držitel ZTP, ZTP-P"
            tooltip="Držitel ZTP, ZTP-P"
          />
          <Checkbox id="zkusenost" name="zkusenost" label="Zkušenost" tooltip="Zkušenost" />

          <LocalInputGroup
            id="contract-uniqa"
            name="contract-uniqa"
            label="Náhrada smlouvy číslo"
            tooltip="Náhrada smlouvy číslo"
            optional
            placeholder="Zadejte číslo náhrady smlouvy"
          />
        </IndividualCompany>

        <IndividualCompany id="uniqa_axa" name="UNIQA (AXA)">
          <Checkbox
            id="pojistnik-ma-jen-jednu-adresu"
            name="pojistnik-ma-jen-jednu-adresu"
            label="Pojistník má jen jednu adresu"
            tooltip="Pojistník má jen jednu adresu"
          />
          <Checkbox
            id="drzitel-ztp,-ztpp"
            name="drzitel-ztp,-ztpp"
            label="Držitel ZTP, ZTP-P"
            tooltip="Držitel ZTP, ZTP-P"
          />
          <Checkbox
            id="spoluucast-mladeho-ridice"
            name="spoluucast-mladeho-ridice"
            label="Spoluúčast mladého řidiče"
            tooltip="Spoluúčast mladého řidiče"
          />
          <Checkbox
            id="pojisteni-za-polovinu"
            name="pojisteni-za-polovinu"
            label="Pojišťění za polovinu"
            tooltip="Pojišťění za polovinu"
          />
          <Checkbox
            id="souhlas-s-elektronickou-komunikaci"
            name="souhlas-s-elektronickou-komunikaci"
            label="Souhlas s elektronickou komunikací"
            tooltip="Souhlas s elektronickou komunikací"
          />

          <LocalInputGroup
            id="contract-other-uniqa-axa"
            name="contract-other-uniqa-axa"
            label="Pojistná smlouva u UNIQA (AXA)"
            optional
            placeholder="Číslo pojistné smlouvy"
          />

          <LocalInputGroup
            id="contract-uniqa-axa"
            name="contract-uniqa-axa"
            label="Náhrada smlouvy číslo"
            tooltip="Náhrada smlouvy číslo"
            optional
            placeholder="Zadejte číslo náhrady smlouvy"
          />
        </IndividualCompany>
      </IndividualDiscountsGroup>
    </IndividualDiscountsWrapper>
  )
}
