import { Box, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  OrganizationEditFormProps,
  OrganizationEditFormValues,
} from '../model/interfaces/entities/IOrganizationEditFormValues'
import { usePostOrganizations } from '../model/api/organization/usePostOrganizations'
import { initialValues } from '../utils/OrganizationEditInitialValues'
import { IOrganization } from '../model/interfaces/entities/IOrganization'
import { useGetCompanies } from '../model/api/company/useGetCompany'
import { useEffect } from 'react'
import { useGetOrganizationsId } from '../model/api/organization/useGetOrganizationId'
import { Loading } from '../components/Loading'
import { OrganizationForm } from './OrganizationForm'
import { useIsSupervisorOrAdmin } from '../model/utils/userUtils'

export const OrganizationCreateForm = ({ organization }: OrganizationEditFormProps) => {
  const toastMessage = useToast()
  const navigate = useNavigate()
  const userIsSupervisor = useIsSupervisorOrAdmin()
  useEffect(() => {
    if (userIsSupervisor === false) {
      navigate('/404')
    }
  }, [userIsSupervisor, navigate])

  const { data: companiesData } = useGetCompanies()
  const { parentId } = useParams<'parentId'>()
  const { data: parentData, isLoading: parentIsLoading } = useGetOrganizationsId(
    parentId,
    typeof parentId !== 'undefined',
  )

  const successCallBack: (data: IOrganization) => void = (data: any) => {
    navigate(`/organization/detail/${data.id}`)
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Chyba',
      description: error.response.data.error,
      status: 'error',
      isClosable: true,
    })
  }

  const { mutate, isLoading } = usePostOrganizations(successCallBack, errorCallBack)

  if (
    (typeof parentId !== 'undefined' && parentIsLoading) ||
    typeof companiesData === 'undefined'
  ) {
    return <Loading />
  }
  const companies = companiesData?.data || []
  const personalNumbers = companies.map((company) => {
    const personalNumber = parentData?.data.personalNumbers.find(personalNumber => personalNumber.company.id === company.id);
    return {
      number: parentData?.data.personalNumbers.find(personalNumber => personalNumber.company.id === company.id)?.number ?? '',
      company: {
        id: company.id,
        name: company.name || '',
      },
      companyId: company.id,
      enabled: personalNumber?.enabled ?? true,
    }
  })
  const decidedInitialValues =
    typeof parentId !== 'undefined' && parentData?.data && parentData.data.prefillData === true
      ? { ...parentData.data, username: '', personalNumbers }
      : { ...initialValues, personalNumbers }

  const onSubmitForm: (formValues: OrganizationEditFormValues) => void = (
    formValues: OrganizationEditFormValues,
  ) => {
    delete formValues.logo

    if (typeof parentId !== 'undefined') {
      formValues.parent = Number(parentId)
    } else {
      delete formValues.parent
    }
    mutate(formValues)
  }

  return (
    <Box>
      <OrganizationForm
        initialData={decidedInitialValues}
        onSubmitForm={onSubmitForm}
        onGoBack={() => navigate('/organization/list')}
        isLoading={isLoading}
      />
    </Box>
  )
}
