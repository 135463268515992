import { IOffersItem } from './../../interfaces/entities/IOffers'
import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const useGetOfferById = (
  id: string | undefined,
  successCallBack?: Function,
  errorCallBack?: Function,
  enabled: boolean = true,
) => {
  const offers: string = `${ApiRouteEnum.OFFERS}/${id}`
  return useQuery([ApiRouteEnum.OFFERS, id], () => api.get<IOffersItem>(offers), {
    retry: false,
    onSuccess: (response) => typeof successCallBack !== 'undefined' ? successCallBack(response) : response,
    onError: (error) => typeof errorCallBack !== 'undefined' ? errorCallBack(error) : error,
    enabled,
  })
}
