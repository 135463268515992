import { Icon } from '@chakra-ui/react'

export const Business = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 18 18" fill="none">
<path d="M6.75002 2.70001C6.63067 2.70001 6.51621 2.74742 6.43182 2.83181C6.34743 2.91621 6.30002 3.03066 6.30002 3.15001V5.40001H4.95002C4.35328 5.40001 3.78099 5.63706 3.35903 6.05902C2.93707 6.48098 2.70002 7.05328 2.70002 7.65001V12.15C2.70002 12.7467 2.93707 13.319 3.35903 13.741C3.78099 14.163 4.35328 14.4 4.95002 14.4H13.05C13.6468 14.4 14.2191 14.163 14.641 13.741C15.063 13.319 15.3 12.7467 15.3 12.15V7.65001C15.3 7.05328 15.063 6.48098 14.641 6.05902C14.2191 5.63706 13.6468 5.40001 13.05 5.40001H11.7V3.15001C11.7 3.03066 11.6526 2.91621 11.5682 2.83181C11.4838 2.74742 11.3694 2.70001 11.25 2.70001H6.75002ZM10.8 5.40001H7.20002V3.60001H10.8V5.40001ZM4.95002 6.30001H13.05C13.4081 6.30001 13.7514 6.44224 14.0046 6.69542C14.2578 6.94859 14.4 7.29197 14.4 7.65001V12.15C14.4 12.5081 14.2578 12.8514 14.0046 13.1046C13.7514 13.3578 13.4081 13.5 13.05 13.5H4.95002C4.59198 13.5 4.2486 13.3578 3.99543 13.1046C3.74225 12.8514 3.60002 12.5081 3.60002 12.15V7.65001C3.60002 7.29197 3.74225 6.94859 3.99543 6.69542C4.2486 6.44224 4.59198 6.30001 4.95002 6.30001Z" fill="currentColor"/>
	</Icon>
)


export const BusinessString=`<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.75002 2.70001C6.63067 2.70001 6.51621 2.74742 6.43182 2.83181C6.34743 2.91621 6.30002 3.03066 6.30002 3.15001V5.40001H4.95002C4.35328 5.40001 3.78099 5.63706 3.35903 6.05902C2.93707 6.48098 2.70002 7.05328 2.70002 7.65001V12.15C2.70002 12.7467 2.93707 13.319 3.35903 13.741C3.78099 14.163 4.35328 14.4 4.95002 14.4H13.05C13.6468 14.4 14.2191 14.163 14.641 13.741C15.063 13.319 15.3 12.7467 15.3 12.15V7.65001C15.3 7.05328 15.063 6.48098 14.641 6.05902C14.2191 5.63706 13.6468 5.40001 13.05 5.40001H11.7V3.15001C11.7 3.03066 11.6526 2.91621 11.5682 2.83181C11.4838 2.74742 11.3694 2.70001 11.25 2.70001H6.75002ZM10.8 5.40001H7.20002V3.60001H10.8V5.40001ZM4.95002 6.30001H13.05C13.4081 6.30001 13.7514 6.44224 14.0046 6.69542C14.2578 6.94859 14.4 7.29197 14.4 7.65001V12.15C14.4 12.5081 14.2578 12.8514 14.0046 13.1046C13.7514 13.3578 13.4081 13.5 13.05 13.5H4.95002C4.59198 13.5 4.2486 13.3578 3.99543 13.1046C3.74225 12.8514 3.60002 12.5081 3.60002 12.15V7.65001C3.60002 7.29197 3.74225 6.94859 3.99543 6.69542C4.2486 6.44224 4.59198 6.30001 4.95002 6.30001Z" fill="#4E5D69"/>
</svg>
`;