import React, { forwardRef } from 'react'
import {
  FormErrorMessage,
  Input,
  InputGroup as ChakraInputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

export interface InputGroupProps extends InputProps {
  errors?: FieldError | undefined
  selectAllonFocus?: boolean | undefined
  leftElement?: string | React.ReactNode
  rightElement?: string | React.ReactNode
}

export const InputGroup = forwardRef(
  (
    {
      errors,
      selectAllonFocus = false,
      leftElement,
      rightElement,
      htmlSize,
      sx,
      ...rest
    }: InputGroupProps,
    ref: any,
  ) => {
    const handleFocus = (event: any) => {
      if (selectAllonFocus) event.target.select()
    }

    const errorMessage = errors && errors?.message

    return (
      <ChakraInputGroup sx={{ w: 'auto', display: 'grid', gap: 2, ...sx }}>
        {leftElement && <InputRightElement sx={{ fontSize: 'sm' }} children={leftElement} />}

        <Input onFocus={handleFocus} htmlSize={htmlSize} {...rest} ref={ref} />

        {rightElement && <InputRightElement sx={{ fontSize: 'sm' }} children={rightElement} />}

        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </ChakraInputGroup>
    )
  },
)
