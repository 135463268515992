import { Box, Checkbox as ChakraCheckbox, FormControl, InputGroupProps } from '@chakra-ui/react'

import { FormLabel } from '../../components/FormLabel'
import { InputGroup } from '../../components/InputGroup'

interface LocalInputGroupProps extends InputGroupProps {
  label: string
  name?: string
  id?: string
  tooltip?: string
  optional?: boolean
}

export const Checkbox = (props: any) => {
  return (
    <FormControl>
      <ChakraCheckbox>{props.label}</ChakraCheckbox>
    </FormControl>
  )
}

export const LocalInputGroup = ({ label, name, tooltip, optional, ...rest }: LocalInputGroupProps) => {
  return (
    <FormControl>
      <FormLabel tooltip={tooltip}>{label}</FormLabel>

      <InputGroup {...rest} />
    </FormControl>
  )
}

export const IndividualDiscountsGroup = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ display: 'grid', gap: 8 }}>{children}</Box>
}

export const IndividualDiscountsWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: 8,
        columnGap: 16,
        width: '100%',
        alignContent: 'start',
        alignItems: 'start',
        gridTemplateColumns: {
          base: '1fr',
          lg: '1fr 1fr',
        },
      }}
    >
      {children}
    </Box>
  )
}
