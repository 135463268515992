import { Icon } from '@chakra-ui/react'

export const InsuranceLifeLiability = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M41 11C32.6 11 26.1667 7.33333 24 5.5C17.6 10.3 10 11.1667 7 11V21C7 34.6 18.3333 41.3333 24 43C37.6 39 41 26.6667 41 21V11Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M28.9 24.84C29.457 24.84 29.9911 25.0549 30.3849 25.4375C30.7788 25.8201 31 26.339 31 26.88V27.56C31 30.2406 28.396 33 24 33C19.604 33 17 30.2406 17 27.56V26.88C17 26.339 17.2212 25.8201 17.6151 25.4375C18.0089 25.0549 18.543 24.84 19.1 24.84H28.9ZM28.9 26.2H19.1C18.9143 26.2 18.7363 26.2716 18.605 26.3992C18.4737 26.5267 18.4 26.6997 18.4 26.88V27.56C18.4 29.5157 20.4048 31.64 24 31.64C27.5952 31.64 29.6 29.5157 29.6 27.56V26.88C29.6 26.6997 29.5263 26.5267 29.395 26.3992C29.2637 26.2716 29.0857 26.2 28.9 26.2ZM24 16C25.0211 16 26.0003 16.394 26.7224 17.0954C27.4444 17.7968 27.85 18.7481 27.85 19.74C27.85 20.7319 27.4444 21.6832 26.7224 22.3846C26.0003 23.086 25.0211 23.48 24 23.48C22.9789 23.48 21.9997 23.086 21.2776 22.3846C20.5556 21.6832 20.15 20.7319 20.15 19.74C20.15 18.7481 20.5556 17.7968 21.2776 17.0954C21.9997 16.394 22.9789 16 24 16ZM24 17.36C23.3502 17.36 22.7271 17.6107 22.2676 18.0571C21.8081 18.5034 21.55 19.1088 21.55 19.74C21.55 20.3712 21.8081 20.9766 22.2676 21.4229C22.7271 21.8693 23.3502 22.12 24 22.12C24.6498 22.12 25.2729 21.8693 25.7324 21.4229C26.1919 20.9766 26.45 20.3712 26.45 19.74C26.45 19.1088 26.1919 18.5034 25.7324 18.0571C25.2729 17.6107 24.6498 17.36 24 17.36Z" fill="currentColor"/>
	</Icon>
)


export const InsuranceLifeLiabilityString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41 11C32.6 11 26.1667 7.33333 24 5.5C17.6 10.3 10 11.1667 7 11V21C7 34.6 18.3333 41.3333 24 43C37.6 39 41 26.6667 41 21V11Z" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M28.9 24.84C29.457 24.84 29.9911 25.0549 30.3849 25.4375C30.7788 25.8201 31 26.339 31 26.88V27.56C31 30.2406 28.396 33 24 33C19.604 33 17 30.2406 17 27.56V26.88C17 26.339 17.2212 25.8201 17.6151 25.4375C18.0089 25.0549 18.543 24.84 19.1 24.84H28.9ZM28.9 26.2H19.1C18.9143 26.2 18.7363 26.2716 18.605 26.3992C18.4737 26.5267 18.4 26.6997 18.4 26.88V27.56C18.4 29.5157 20.4048 31.64 24 31.64C27.5952 31.64 29.6 29.5157 29.6 27.56V26.88C29.6 26.6997 29.5263 26.5267 29.395 26.3992C29.2637 26.2716 29.0857 26.2 28.9 26.2ZM24 16C25.0211 16 26.0003 16.394 26.7224 17.0954C27.4444 17.7968 27.85 18.7481 27.85 19.74C27.85 20.7319 27.4444 21.6832 26.7224 22.3846C26.0003 23.086 25.0211 23.48 24 23.48C22.9789 23.48 21.9997 23.086 21.2776 22.3846C20.5556 21.6832 20.15 20.7319 20.15 19.74C20.15 18.7481 20.5556 17.7968 21.2776 17.0954C21.9997 16.394 22.9789 16 24 16ZM24 17.36C23.3502 17.36 22.7271 17.6107 22.2676 18.0571C21.8081 18.5034 21.55 19.1088 21.55 19.74C21.55 20.3712 21.8081 20.9766 22.2676 21.4229C22.7271 21.8693 23.3502 22.12 24 22.12C24.6498 22.12 25.2729 21.8693 25.7324 21.4229C26.1919 20.9766 26.45 20.3712 26.45 19.74C26.45 19.1088 26.1919 18.5034 25.7324 18.0571C25.2729 17.6107 24.6498 17.36 24 17.36Z" fill="#EE7400"/>
</svg>
`;