import { offerStatusEnum } from './utils/offerStatusEnum'
import { IOfferCompany } from './utils/IOfferCompany'
import { IBodyCell } from '../../containers/offers/grid/columnSections'

export interface IPropertyOfferInsurance {
  label: string
  name: string
  value: number
  participation: number
}

export interface IInsurance {
  id: string
  insurances?: Array<IBodyCell | Array<IBodyCell>>
}

export interface IPropertyOffer {
  quoteId: number
  status: offerStatusEnum
  company: IOfferCompany
  validUntil: Date
  passesAllParameters?: boolean
  insuranceGroups?: Array<IInsurance | Array<IInsurance>>
  propertyInsurance: IPropertyOfferInsurance
  householdInsurance: IPropertyOfferInsurance
}

type IPropertyOffersGroup = {
  passed: 'fully' | 'partially'
  offers: Array<IPropertyOffer>
}[]

export const insuranceGroups = [
  {
    id: 'household',
    title: 'Domácnost',
    icon: 'household',
    insurances: [
      {
        id: 'assistance',
        title: 'Asistence',
      },
      {
        id: 'assistanceExtra',
        title: 'Asistence rozšířená',
      },
      {
        id: 'brokenPipe',
        title: 'Havárie rozvodů (lom trubky)',
      },
      {
        id: 'theft',
        title: 'Odcizení věci',
      },
      {
        id: 'glassDamage',
        title: 'Pojištění skel',
      },
      {
        id: 'flood',
        title: 'Povodeň, záplava',
      },
      {
        id: 'elecricityShort',
        title: 'Přepětí, podpětí, zkrat',
      },
      {
        id: 'vandalism',
        title: 'Vandalismus',
      },
      {
        id: 'waterPipesDamage',
        title: 'Vodovodní škody',
      },
      {
        id: 'atmosphericLeakage',
        title: 'Zatečení atmosférických srážek',
      },
      {
        id: 'naturalDamage',
        title: 'Živelní škody',
      },
    ],
  },
  {
    id: 'property',
    title: 'Nemovitost',
    icon: 'property',
  },
  {
    id: 'common',
    title: 'Společné',
    icon: 'common',
  },
  {
    id: 'lifeLiability',
    title: 'Občanská odpovědnost',
    icon: 'lifeLiability',
  },
  {
    id: 'propertyLiability',
    title: 'Odpovědnost nemovitosti',
    icon: 'propertyLiability',
  },
]

const dummyInsuranceGroups: Array<IInsurance | Array<IInsurance>> = [
  {
    id: 'household',
    insurances: [
      {
        id: 'assistance',
        type: 'boolean',
        value: true,
        isEditable: true,
      },
      {
        id: 'assistanceExtra',
        type: 'boolean',
        value: true,
      },
      {
        id: 'brokenPipe',
        type: 'boolean',
        value: true,
      },
      {
        id: 'theft',
        type: 'number',
        value: 1500000,
        isEditable: true,
      },
      [
        {
          id: 'glassDamage1',
          type: 'number',
          value: 50000,
          isEditable: true,
        },
        {
          id: 'glassDamage2',
          type: 'number',
          value: 100000,
          isEditable: true,
        },
      ],
      {
        id: 'flood',
        type: 'boolean',
        value: true,
      },
      {
        id: 'elecricityShort',
        type: 'boolean',
        value: true,
      },
      {
        id: 'vandalism',
        type: 'boolean',
        value: true,
      },
      {
        id: 'waterPipesDamage',
        type: 'boolean',
        value: true,
      },
      {
        id: 'atmosphericLeakage',
        type: 'boolean',
        value: true,
      },
      {
        id: 'naturalDamage',
        type: 'boolean',
        value: true,
      },
    ],
  },
  {
    id: 'property',
  },
  {
    id: 'common',
  },
  {
    id: 'lifeLiability',
  },
  {
    id: 'propertyLiability',
  },
]

export const propertyOffers: IPropertyOffersGroup = [
  {
    passed: 'fully',
    offers: [
      {
        quoteId: 42791,
        status: offerStatusEnum.Valid,
        company: { id: 'kooperativa', name: 'Pojišťovna Kooperativa, a.s.' },
        validUntil: new Date(2022, 6, 22),
        passesAllParameters: true,
        insuranceGroups: dummyInsuranceGroups,
        propertyInsurance: {
          label: 'Pojištění nemovitosti',
          name: 'Komfort + Max',
          value: 5000000,
          participation: 10000,
        },
        householdInsurance: {
          label: 'Pojištění domácnosti',
          name: 'Komfort + Max',
          value: 200000,
          participation: 5000,
        },
      },
      {
        quoteId: 42792,
        status: offerStatusEnum.Valid,
        company: { id: 'direct', name: 'Direct pojišťovna, a.s.' },
        validUntil: new Date(2022, 6, 22),
        passesAllParameters: true,
        insuranceGroups: dummyInsuranceGroups,
        propertyInsurance: {
          label: 'Pojištění nemovitosti',
          name: 'Komfort + Max',
          value: 5000000,
          participation: 10000,
        },
        householdInsurance: {
          label: 'Pojištění domácnosti',
          name: 'Komfort + Max',
          value: 200000,
          participation: 5000,
        },
      },
      {
        quoteId: 42793,
        status: offerStatusEnum.Selected,
        company: { id: 'direct', name: 'Direct pojišťovna, a.s.' },
        validUntil: new Date(2022, 6, 22),
        passesAllParameters: true,
        insuranceGroups: dummyInsuranceGroups,
        propertyInsurance: {
          label: 'Pojištění nemovitosti',
          name: 'Komfort + Max',
          value: 5000000,
          participation: 10000,
        },
        householdInsurance: {
          label: 'Pojištění domácnosti',
          name: 'Komfort + Max',
          value: 200000,
          participation: 5000,
        },
      },
    ],
  },
  {
    passed: 'partially',
    offers: [
      {
        quoteId: 42794,
        status: offerStatusEnum.Error,
        company: { id: 'direct', name: 'Direct pojišťovna, a.s.' },
        validUntil: new Date(2022, 6, 22),
        passesAllParameters: false,
        insuranceGroups: dummyInsuranceGroups,
        propertyInsurance: {
          label: 'Pojištění nemovitosti',
          name: 'Komfort + Max',
          value: 5000000,
          participation: 10000,
        },
        householdInsurance: {
          label: 'Pojištění domácnosti',
          name: 'Komfort + Max',
          value: 200000,
          participation: 5000,
        },
      },
    ],
  },
]
