import React, { HTMLAttributeAnchorTarget } from 'react'
import { Box, BoxProps, Button, ButtonProps, useDisclosure } from '@chakra-ui/react'
import { useApp } from '../context/AppContext'
import { Add, Contract, External, Help, Logout, News, Offer, Settings, User } from '../icons'
import { useGetUserCurrent } from '../model/api/user/useGetUserCurrent'
import { useIsSupervisor, useIsSupervisorOrAdmin, useLogout } from '../model/utils/userUtils'

interface IMenuGroupProps {
  children: React.ReactNode
}

interface IMenuItemWithSubMenu {
  button: React.ReactElement
  subMenu: React.ReactNode
}

interface IMenuItemProps extends ButtonProps {
  children: React.ReactNode
  href?: any
  variant?: string
  colorScheme?: string
  target?: HTMLAttributeAnchorTarget
}

const MenuGroup = ({ children }: IMenuGroupProps) => {
  return (
    <Box
      as="ul"
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gap: {
          xl: 1,
        },
        width: '100%',
        listStyle: 'none',
      }}
    >
      {children}
    </Box>
  )
}

export const MenuItem = ({ children, href, variant, colorScheme, ...rest }: IMenuItemProps) => {
  return (
    <Button
      as="a"
      variant={variant || 'ghost'}
      colorScheme={colorScheme || 'primary'}
      sx={{ gap: 1, justifyContent: 'start' }}
      href={href}
      {...rest}
    >
      {children}
    </Button>
  )
}

const SubMenu = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      sx={{
        p: 2,
        width: 'max-content',
        position: 'absolute',
        top: '100%',
        left: 0,
        bg: 'white',
        borderRadius: 'md',
        boxShadow: 'md',
        zIndex: 500,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const MenuItemWithSubMenu = ({ button, subMenu }: IMenuItemWithSubMenu) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box
      sx={{ gap: 1, position: 'relative', justifyContent: 'start', textAlign: 'start' }}
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      {button && button}

      {subMenu && isOpen && subMenu}
    </Box>
  )
}

export const Menu = () => {
  const { app } = useApp()

  const { data, isLoading } = useGetUserCurrent()
  const logout = useLogout()
  const isSupervisorOrAdmin = useIsSupervisorOrAdmin()
  const isSupervisor = useIsSupervisor()

  return (
    <Box
      sx={{
        display: { base: 'none', xl: 'grid' },
        width: '100%',
        gridAutoFlow: 'column',
        justifyContent: 'space-between',
        justifyItems: 'space-between',
      }}
      className="site-menu-wrapper"
    >
      <MenuGroup>
        <li>
          <MenuItem variant="solid" colorScheme="primary" href={`/${app}/wizard/create/1`}>
            <Add />
            Nové pojištění
          </MenuItem>
        </li>
        <li>
          <MenuItem href={`/${app}/concepts`}>
            <Offer color="secondary.500" />
            Nabídky
          </MenuItem>
        </li>
        <li>
          <MenuItem href={`/${app}/contracts`}>
            <Contract color="secondary.500" />
            Smlouvy
          </MenuItem>
        </li>
        {isLoading ? (
          <></>
        ) : (
          <>
            {isSupervisorOrAdmin ? (
              <>
                <li>
                  <MenuItemWithSubMenu
                    button={
                      <MenuItem>
                        <Settings color="secondary.500" />
                        Nastavení
                      </MenuItem>
                    }
                    subMenu={
                      <SubMenu>
                        <li>
                          <MenuItem href="/organization/list">Organizace</MenuItem>
                        </li>
                        <li>
                          <MenuItem href="/user/list">Uživatelé</MenuItem>
                        </li>
                        {isSupervisor && (
                          <>
                            <li>
                              <MenuItem
                                href="https://trello.com/b/4QLwMRBu/okh-sme-businesska"
                                target="_blank"
                              >
                                HelpDesk
                                <External />
                              </MenuItem>
                            </li>
                          </>
                        )}
                      </SubMenu>
                    }
                  />
                </li>
              </>
            ) : null}
          </>
        )}
      </MenuGroup>

      <MenuGroup>
        <li>
          <MenuItem href="/faq">
            <Help color="secondary.500" />
            Nápověda
          </MenuItem>
        </li>
        <li>
          <MenuItem href="/news">
            <News color="secondary.500" />
            Aktuality
          </MenuItem>
        </li>

        <li>
          <MenuItem href={`/user/detail/${data?.data.id}`}>
            <User color="secondary.500" />
            {data?.data.firstName} {data?.data.lastName}
          </MenuItem>
        </li>

        <li>
          <MenuItem onClick={logout}>
            <Logout color="secondary.500" />
          </MenuItem>
        </li>
      </MenuGroup>
    </Box>
  )
}
