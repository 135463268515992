import { Box, Flex, Text } from '@chakra-ui/react'

interface VehicleInformationItemProps {
  label: string
  value: string
}

interface VehicleInformationProps {
  items: Array<VehicleInformationItemProps>
}

const VehicleInformationItem = ({ label, value }: VehicleInformationItemProps) => {
  return (
    <Flex>
      <Text sx={{ fontWeight: 'bold' }}>
        {label}:{' '}
      </Text>
      <Text>{value}</Text>
    </Flex>
  )
}

export const VehicleInformation = ({ items }: VehicleInformationProps) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: { base: '1fr', md: '1fr 1fr' } }}>
      {items &&
        items.length > 0 &&
        items.map((item) => {
          return <VehicleInformationItem key={item.label} label={item.label} value={item.value} />
        })}
    </Box>
  )
}
