import { Icon } from '@chakra-ui/react'

export const TextAlignJustify = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 24 24" fill="none">
<path d="M2 5.75C2 5.55109 2.07902 5.36032 2.21967 5.21967C2.36032 5.07902 2.55109 5 2.75 5H21.25C21.4489 5 21.6397 5.07902 21.7803 5.21967C21.921 5.36032 22 5.55109 22 5.75C22 5.94891 21.921 6.13968 21.7803 6.28033C21.6397 6.42098 21.4489 6.5 21.25 6.5H2.75C2.55109 6.5 2.36032 6.42098 2.21967 6.28033C2.07902 6.13968 2 5.94891 2 5.75ZM2 18.75C2 18.5511 2.07902 18.3603 2.21967 18.2197C2.36032 18.079 2.55109 18 2.75 18H21.25C21.4489 18 21.6397 18.079 21.7803 18.2197C21.921 18.3603 22 18.5511 22 18.75C22 18.9489 21.921 19.1397 21.7803 19.2803C21.6397 19.421 21.4489 19.5 21.25 19.5H2.75C2.55109 19.5 2.36032 19.421 2.21967 19.2803C2.07902 19.1397 2 18.9489 2 18.75ZM2.75 11.5C2.55109 11.5 2.36032 11.579 2.21967 11.7197C2.07902 11.8603 2 12.0511 2 12.25C2 12.4489 2.07902 12.6397 2.21967 12.7803C2.36032 12.921 2.55109 13 2.75 13H21.25C21.4489 13 21.6397 12.921 21.7803 12.7803C21.921 12.6397 22 12.4489 22 12.25C22 12.0511 21.921 11.8603 21.7803 11.7197C21.6397 11.579 21.4489 11.5 21.25 11.5H2.75Z" fill="currentColor"/>
	</Icon>
)


export const TextAlignJustifyString=`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 5.75C2 5.55109 2.07902 5.36032 2.21967 5.21967C2.36032 5.07902 2.55109 5 2.75 5H21.25C21.4489 5 21.6397 5.07902 21.7803 5.21967C21.921 5.36032 22 5.55109 22 5.75C22 5.94891 21.921 6.13968 21.7803 6.28033C21.6397 6.42098 21.4489 6.5 21.25 6.5H2.75C2.55109 6.5 2.36032 6.42098 2.21967 6.28033C2.07902 6.13968 2 5.94891 2 5.75ZM2 18.75C2 18.5511 2.07902 18.3603 2.21967 18.2197C2.36032 18.079 2.55109 18 2.75 18H21.25C21.4489 18 21.6397 18.079 21.7803 18.2197C21.921 18.3603 22 18.5511 22 18.75C22 18.9489 21.921 19.1397 21.7803 19.2803C21.6397 19.421 21.4489 19.5 21.25 19.5H2.75C2.55109 19.5 2.36032 19.421 2.21967 19.2803C2.07902 19.1397 2 18.9489 2 18.75ZM2.75 11.5C2.55109 11.5 2.36032 11.579 2.21967 11.7197C2.07902 11.8603 2 12.0511 2 12.25C2 12.4489 2.07902 12.6397 2.21967 12.7803C2.36032 12.921 2.55109 13 2.75 13H21.25C21.4489 13 21.6397 12.921 21.7803 12.7803C21.921 12.6397 22 12.4489 22 12.25C22 12.0511 21.921 11.8603 21.7803 11.7197C21.6397 11.579 21.4489 11.5 21.25 11.5H2.75Z" fill="#003D66"/>
</svg>
`;