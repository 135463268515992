import {
  Box,
  Button,
  FormControl,
  InputGroup as ChakraInputGroup,
  Input,
  InputLeftElement,
  Select,
  FormErrorMessage,
} from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormGroup } from '../components/FormGroup'
import { FormLabel } from '../components/FormLabel'

import {
  FormSection,
  FormSectionBody,
  FormSectionHeader,
  FormSectionTitle,
} from '../components/FormSection'
import { InputGroup } from '../components/InputGroup'
import { Calendar, Email } from '../icons'
import { companies } from '../utils/companies'

interface ExpiredContractCancellationFormProps {
  onSubmit: Function
  hideCompany?: boolean
  hideDate?: boolean
}

export interface ExpiredContractCancellationFormValues {
  insuranceCompany: string
  contractNumber: string
  expiryDate: string
}

export const ExpiredContractCancellationForm = ({
  onSubmit,
  hideCompany = false,
  hideDate = false,
}: ExpiredContractCancellationFormProps) => {
  const methods = useForm<ExpiredContractCancellationFormValues>({
    defaultValues: {
      insuranceCompany: '',
      contractNumber: '',
      expiryDate: '',
    },
  })
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isSubmitting },
  } = methods

  const onSubmitForm = (values: ExpiredContractCancellationFormValues) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(values)
        resolve(true)
      }, 500)
    })
  }

  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>Zrušení předchozí smlouvy</FormSectionTitle>
      </FormSectionHeader>

      <FormSectionBody>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Box sx={{ display: 'grid', gap: 4, placeItems: 'start' }}>
              <FormGroup>
                {!hideCompany && (
                  <FormControl isInvalid={!!errors['insuranceCompany']}>
                    <FormLabel>Pojišťovna</FormLabel>

                    <Select
                      value={getValues('insuranceCompany')}
                      {...register('insuranceCompany', {
                        required: 'Vyberte prosím pojišťovnu.',
                      })}
                    >
                      <option disabled value="">
                        Vyberte pojišťovnu
                      </option>
                      {companies &&
                        companies.map(({ id, name }) => {
                          return (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          )
                        })}
                    </Select>

                    <FormErrorMessage>{errors['insuranceCompany']?.message}</FormErrorMessage>
                  </FormControl>
                )}

                <FormControl isInvalid={!!errors['contractNumber']}>
                  <FormLabel>Číslo předchozí smlouvy</FormLabel>

                  <InputGroup
                    inputMode="numeric"
                    placeholder="Např. 123456789"
                    htmlSize={12}
                    errors={errors['contractNumber']}
                    {...register('contractNumber', {
                      required: 'Vyplňte číslo předchozí smlouvy.',
                    })}
                  />
                </FormControl>

                {!hideDate && (
                  <FormControl isInvalid={!!errors['expiryDate']}>
                    <FormLabel>Datum výročí smlouvy</FormLabel>

                    <ChakraInputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<Calendar width={4} height={4} />}
                      />
                      <Input
                        id="startDate"
                        type="date"
                        {...register('expiryDate', {
                          required: 'Vyberte datum expirace smlouvy.',
                        })}
                      />
                    </ChakraInputGroup>

                    <FormErrorMessage>{errors['expiryDate']?.message}</FormErrorMessage>
                  </FormControl>
                )}
              </FormGroup>

              <Button
                type="submit"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                variant="outline"
              >
                <Email color="secondary.500" />
                Odeslat storno dokument klientovi
              </Button>
            </Box>
          </form>
        </FormProvider>
      </FormSectionBody>
    </FormSection>
  )
}
