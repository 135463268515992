import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IFAQItem } from '../../interfaces/entities/IFAQ'

export const usePatchFAQId = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: { faq: IFAQItem; id: number }) => api.patch(`${ApiRouteEnum.FAQ}/${data.id}`, data.faq),
    {
      onSuccess: (data) => {
        successCallBack(data.data)
        return queryClient.invalidateQueries([ApiRouteEnum.FAQ])
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
