import { ICompanyV2 } from '../interfaces/entities/ICompany'
import { IUserItem } from '../interfaces/entities/IUser'
import { OrganizationEditFormValues } from '../interfaces/entities/IOrganizationEditFormValues'

export const transformPersonalNumbers = <T extends IUserItem | OrganizationEditFormValues> (
  companies: ICompanyV2[],
  data: T,
): T => {
  const personalNumbers = companies.map((company) => {
    const personalNumber = data.personalNumbers.find((item) => item.company.id === company.id)
    return {
      number: personalNumber?.number ? personalNumber.number : '',
      company: {
        id: company.id,
        name: company.name || '',
      },
      companyId: company.id,
      enabled: true,
    }
  })
  const updatedData = { ...data, personalNumbers }
  return updatedData
}

export default transformPersonalNumbers
