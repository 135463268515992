import { Icon } from '@chakra-ui/react'

export const InsuranceAtmosphericLeakage = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M33.5 30H24H14.5C12.5 30 8.5 28.9 8.5 24.5C8.5 20.1 12.8333 19 15 19C15 19 15.5 11 24 11C31.5 11 32.8333 16.3333 33 19C35.1667 19.1667 39.5 20.5 39.5 24.5C39.5 28.5 35.5 29.8333 33.5 30Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.5 30L13.5 37M19.5 37L21 34.5M29 30L25 37M30.5 37L32 34.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
)


export const InsuranceAtmosphericLeakageString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.5 30H24H14.5C12.5 30 8.5 28.9 8.5 24.5C8.5 20.1 12.8333 19 15 19C15 19 15.5 11 24 11C31.5 11 32.8333 16.3333 33 19C35.1667 19.1667 39.5 20.5 39.5 24.5C39.5 28.5 35.5 29.8333 33.5 30Z" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.5 30L13.5 37M19.5 37L21 34.5M29 30L25 37M30.5 37L32 34.5" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;