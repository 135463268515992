import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  InputGroup,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardBody } from '../../components/Card'
import { FormLabel } from '../../components/FormLabel'
import { Loading } from '../../components/Loading'

import { TableWrapper } from '../../components/TableWrapper'
import { useGetOrganizationsId } from '../../model/api/organization/useGetOrganizationId'
import { usePostOrganizationInsurance } from '../../model/api/organization/usePostOrganizationInsurance'
import { IAvailableApps } from '../../utils/availableApps'
import { carkulkaCompanies, ICompany } from '../../utils/companies'
import { CustomNumberInput } from '../CustomNumberInput'
import { OrgInfoSectionHeading } from './OrganizationDetail'
import { BusinessNumber } from '../../model/interfaces/entities/IOrganizationEditFormValues'

const Discount = ({
  title,
  value,
  min,
  max,
  hidden,
  onValueChange,
  onMaximumChange,
  onHiddenChange,
}: {
  title?: string
  value: number
  min: number
  max: number
  hidden: number
  onValueChange?: Function
  onMaximumChange?: Function
  onHiddenChange?: Function
}) => {
  return (
    <Popover placement="end-start">
      <PopoverTrigger>
        <Box sx={{ p: 0.5, borderRadius: 'md', cursor: 'pointer', _hover: { bg: 'gray.100' } }}>
          {`${value}%`}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent minW="min-content">
          <Card>
            <CardBody sx={{ p: 4, display: 'grid', gap: 4 }}>
              <Box
                sx={{
                  display: 'grid',
                  gap: 4,
                }}
              >
                {title && <Text sx={{ fontSize: 'lg', fontWeight: 'medium' }}>{title} </Text>}

                <Flex sx={{ gap: 4, placeItems: 'start' }}>
                  <FormControl>
                    <FormLabel>Výchozí</FormLabel>

                    <InputGroup>
                      <CustomNumberInput
                        inputRightElement="%"
                        defaultValue={value}
                        onValueChange={(value) => {
                          onValueChange && onValueChange(value)
                        }}
                        min={min}
                        max={max}
                      />
                    </InputGroup>
                  </FormControl>
                </Flex>
              </Box>
            </CardBody>
          </Card>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const OrganizationDiscountsTable = ({
  companies,
  app,
}: {
  companies?: Array<ICompany>
  app?: IAvailableApps['appId']
}) => {
  const { id } = useParams<'id'>()
  const toastMessage = useToast()
  const { data, isLoading, isError, error, refetch } = useGetOrganizationsId(id)

  const successCallBack: (data: any) => void = (data: any) => {
    refetch()
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Došlo k chybě',
      description: error + '',
      status: 'error',
      isClosable: true,
    })
  }

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  const { mutate } = usePostOrganizationInsurance(successCallBack, errorCallBack, id)

  const onChangeState = (companyId: string, enabled: boolean, sale?: number) => {
    if (sale) {
      mutate({
        companyId,
        enabled,
        sale,
      })
    } else
      mutate({
        companyId,
        enabled,
      })
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <TableWrapper>
          <Table>
            <Thead>
              <Tr>
                <Th w={1}></Th>
                <Th w={200}>Pojišťovna</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.data.personalNumbers &&
                data?.data.personalNumbers.map((personalNumber) => {
                  const discounts: Array<BusinessNumber> = [personalNumber]

                  return (
                    <Tr key={personalNumber.company.id}>
                      <Td>
                        <Box sx={{ display: 'grid', alignItems: 'center' }}>
                          <Checkbox
                            size="sm"
                            defaultChecked={personalNumber.enabled}
                            onChange={(e) =>
                              onChangeState(
                                personalNumber.company.id.toLocaleString(),
                                e.target.checked,
                              )
                            }
                          />
                        </Box>
                      </Td>
                      <Td>
                        <Text sx={{ fontSize: 'xs', fontWeight: 'medium' }}>
                          {personalNumber.company.name}
                        </Text>
                      </Td>
                      <Td>
                        <Box sx={{ display: 'flex', gap: 2, fontSize: 'xs' }}>
                          {discounts.map((discount: any, index) => {
                            if (discount.enabled === true) {
                              return (
                                <Discount
                                  key={index}
                                  value={discount.sale === null ? 0 : discount.sale}
                                  min={discount.min}
                                  max={discount.max}
                                  hidden={discount.hidden}
                                  onValueChange={(value: string) =>
                                    onChangeState(
                                      personalNumber.company.id.toLocaleString(),
                                      true,
                                      Number(value),
                                    )
                                  }
                                />
                              )
                            }
                          })}
                        </Box>
                      </Td>
                    </Tr>
                  )
                })}
            </Tbody>
          </Table>
        </TableWrapper>
      )}
    </>
  )
}

export const OrganizationDiscounts = () => {
  return (
    <>
      <Box sx={{ display: 'grid', gap: 4 }}>
        <OrgInfoSectionHeading>Pojištění podnikatelé</OrgInfoSectionHeading>

        <OrganizationDiscountsTable />
      </Box>
    </>
  )
}
