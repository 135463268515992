import { Image, Table, TableCellProps, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { formatPrice } from '../../utils/formatPrice'
import { formatDate } from '../../utils/formatDate'
import { IOffersItem } from '../../model/interfaces/entities/IOffers'
import React from 'react'
import { prefixUrlWithBasePath } from '../../model/utils/urlUtils'
import { translatePeriodicity } from '../../model/utils/periodicityUtils'
import moment from 'moment'

interface RecapTableRowProps extends TableCellProps {
  col1: string | React.ReactNode
  col2?: string | React.ReactNode
  col3?: string | React.ReactNode
  isHighlighted?: boolean
}

export const RecapTableRow = ({
  col1,
  col2,
  col3,
  isHighlighted,
  sx,
  ...rest
}: RecapTableRowProps) => {
  return (
    <Tr sx={{ m: 0, p: 0 }}>
      <Td sx={{ w: '15rem', p: 1, ...sx }}>
        {col1 && <Text sx={{ fontWeight: isHighlighted ? 'semibold' : 'medium' }}>{col1}</Text>}
      </Td>
      <Td sx={{ p: 1, ...sx }}>{col2 && col2}</Td>
      {col3 && (
        <Td
          sx={{ p: 1, fontWeight: isHighlighted ? 'semibold' : 'normal', textAlign: 'end', ...sx }}
        >
          {col3}
        </Td>
      )}
    </Tr>
  )
}

export const Recap: React.FC<{ offer?: IOffersItem }> = ({ offer }) => {
  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>Rekapitulace pojištění</FormSectionTitle>

        <FormSectionDescription>
          <Image
            src={prefixUrlWithBasePath(offer?.item?.company.logo || '')}
            alt="Pojistovna - logo"
            width="64px"
            height="48px"
          />
        </FormSectionDescription>
      </FormSectionHeader>

      <FormSectionBody>
        <Table variant="unstyled">
          <Tbody>
            <RecapTableRow
              isHighlighted
              col1="Celkem"
              col2={
                <Text sx={{ fontWeight: 'semibold' }}>
                  {formatPrice({ price: offer?.pricePerYear ?? offer?.item?.pricePerYear ?? 0 })}
                </Text>
              }
            />
          </Tbody>
        </Table>

        <Table variant="unstyled">
          <Tbody>
            <RecapTableRow
              col1="Splátky pojistného"
              col2={translatePeriodicity(offer?.insurance.paymentFrequency || '')}
            />
            <RecapTableRow
              col1="Datum sjednání"
              col2={formatDate({ date: moment(offer?.insurance.dateStart).toDate() })}
            />
          </Tbody>
        </Table>
      </FormSectionBody>
    </FormSection>
  )
}
