import { Alert } from './Alert'
import { Avatar } from './Avatar'
import { Badge } from './Badge'
import { Button } from './Button'
import { Card } from './Card'
import { Checkbox } from './Checkbox'
import { Container } from './Container'
import { Footer } from './Footer'
import { Form } from './Form'
import { FormError } from './FormError'
import { FormGroup } from './FormGroup'
import { FormLabel } from './FormLabel'
import { FormSection } from './FormSection'
import { Header } from './Header'
import { Heading } from './Heading'
import { Input } from './Input'
import { MobileMenu } from './MobileMenu'
import { NewsItem } from './NewsItem'
import { NumberInput } from './NumberInput'
import { Link } from './Link'
import { Radio } from './Radio'
import { Section } from './Section'
import { Select } from './Select'
import { Spinner } from './Spinner'
import { Switch } from './Switch'
import { Table } from './Table'
import { Tabs } from './Tabs'
import { Text } from './Text'
import { Textarea } from './Textarea'
import { Tooltip } from './Tooltip'

export const components = {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  Container,
  Footer,
  Form,
  FormError,
  FormGroup,
  FormLabel,
  FormSection,
  Header,
  Heading,
  Input,
  MobileMenu,
  NewsItem,
  NumberInput,
  Link,
  Radio,
  Section,
  Select,
  Spinner,
  Switch,
  Table,
  Tabs,
  Text,
  Textarea,
  Tooltip,
}
