import { Icon } from '@chakra-ui/react'

export const Citizen = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 18 18" fill="none">
<path d="M9.00004 1.79999C8.04526 1.79999 7.12959 2.17927 6.45446 2.8544C5.77932 3.52953 5.40004 4.44521 5.40004 5.39999C5.40004 6.35477 5.77932 7.27044 6.45446 7.94557C7.12959 8.6207 8.04526 8.99999 9.00004 8.99999C9.95482 8.99999 10.8705 8.6207 11.5456 7.94557C12.2208 7.27044 12.6 6.35477 12.6 5.39999C12.6 4.44521 12.2208 3.52953 11.5456 2.8544C10.8705 2.17927 9.95482 1.79999 9.00004 1.79999ZM6.30004 5.39999C6.30004 4.6839 6.5845 3.99715 7.09085 3.4908C7.5972 2.98445 8.28396 2.69999 9.00004 2.69999C9.71612 2.69999 10.4029 2.98445 10.9092 3.4908C11.4156 3.99715 11.7 4.6839 11.7 5.39999C11.7 6.11607 11.4156 6.80283 10.9092 7.30918C10.4029 7.81553 9.71612 8.09999 9.00004 8.09999C8.28396 8.09999 7.5972 7.81553 7.09085 7.30918C6.5845 6.80283 6.30004 6.11607 6.30004 5.39999Z" fill="currentColor"/>
<path d="M4.50812 9.89998C4.27109 9.89903 4.03621 9.94488 3.81694 10.0349C3.59767 10.1249 3.39833 10.2574 3.23035 10.4246C3.06237 10.5918 2.92906 10.7906 2.83805 11.0094C2.74704 11.2283 2.70014 11.4629 2.70002 11.7C2.70002 13.2219 3.44972 14.3694 4.62152 15.1173C5.77532 15.8526 7.33052 16.2 9.00002 16.2C10.6695 16.2 12.2247 15.8526 13.3785 15.1173C14.5503 14.3703 15.3 13.221 15.3 11.7C15.3 11.2226 15.1104 10.7648 14.7728 10.4272C14.4352 10.0896 13.9774 9.89998 13.5 9.89998H4.50812ZM3.60002 11.7C3.60002 11.2023 4.00322 10.8 4.50812 10.8H13.5C13.7387 10.8 13.9676 10.8948 14.1364 11.0636C14.3052 11.2324 14.4 11.4613 14.4 11.7C14.4 12.8781 13.8402 13.7556 12.8943 14.3577C11.9313 14.9724 10.5615 15.3 9.00002 15.3C7.43852 15.3 6.06872 14.9724 5.10572 14.3577C4.16072 13.7547 3.60002 12.879 3.60002 11.7Z" fill="currentColor"/>
	</Icon>
)


export const CitizenString=`<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.00004 1.79999C8.04526 1.79999 7.12959 2.17927 6.45446 2.8544C5.77932 3.52953 5.40004 4.44521 5.40004 5.39999C5.40004 6.35477 5.77932 7.27044 6.45446 7.94557C7.12959 8.6207 8.04526 8.99999 9.00004 8.99999C9.95482 8.99999 10.8705 8.6207 11.5456 7.94557C12.2208 7.27044 12.6 6.35477 12.6 5.39999C12.6 4.44521 12.2208 3.52953 11.5456 2.8544C10.8705 2.17927 9.95482 1.79999 9.00004 1.79999ZM6.30004 5.39999C6.30004 4.6839 6.5845 3.99715 7.09085 3.4908C7.5972 2.98445 8.28396 2.69999 9.00004 2.69999C9.71612 2.69999 10.4029 2.98445 10.9092 3.4908C11.4156 3.99715 11.7 4.6839 11.7 5.39999C11.7 6.11607 11.4156 6.80283 10.9092 7.30918C10.4029 7.81553 9.71612 8.09999 9.00004 8.09999C8.28396 8.09999 7.5972 7.81553 7.09085 7.30918C6.5845 6.80283 6.30004 6.11607 6.30004 5.39999Z" fill="#4E5D69"/>
<path d="M4.50812 9.89998C4.27109 9.89903 4.03621 9.94488 3.81694 10.0349C3.59767 10.1249 3.39833 10.2574 3.23035 10.4246C3.06237 10.5918 2.92906 10.7906 2.83805 11.0094C2.74704 11.2283 2.70014 11.4629 2.70002 11.7C2.70002 13.2219 3.44972 14.3694 4.62152 15.1173C5.77532 15.8526 7.33052 16.2 9.00002 16.2C10.6695 16.2 12.2247 15.8526 13.3785 15.1173C14.5503 14.3703 15.3 13.221 15.3 11.7C15.3 11.2226 15.1104 10.7648 14.7728 10.4272C14.4352 10.0896 13.9774 9.89998 13.5 9.89998H4.50812ZM3.60002 11.7C3.60002 11.2023 4.00322 10.8 4.50812 10.8H13.5C13.7387 10.8 13.9676 10.8948 14.1364 11.0636C14.3052 11.2324 14.4 11.4613 14.4 11.7C14.4 12.8781 13.8402 13.7556 12.8943 14.3577C11.9313 14.9724 10.5615 15.3 9.00002 15.3C7.43852 15.3 6.06872 14.9724 5.10572 14.3577C4.16072 13.7547 3.60002 12.879 3.60002 11.7Z" fill="#4E5D69"/>
</svg>
`;