import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePostOrganizationInsurance = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  organizationId: string | undefined,
) => {
  return useMutation(
    (data: { companyId?: string; enabled: boolean; sale?: number }) =>
      api.post(ApiRouteEnum.ORGANIZATION + `/${organizationId}/insurance/${data.companyId}`, {
        enabled: data.enabled,
        sale: data.sale,
      }),
    {
      onSuccess: (data) => {
        successCallBack(data.data)
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
