import { Icon } from '@chakra-ui/react'

export const InsuranceFlood = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M40 36V21C40 20.2 39.3333 19.3333 39 19C35 15.3333 26.8 7.8 26 7C25.2 6.2 24.3333 6 24 6C23.6667 6 22.8 6.2 22 7C21.2 7.8 13 15.3333 9 19C8.66667 19.3333 8 20.2 8 21V36M29 33.5V29C29 27 28 26.5 26 26.5H22C20 26.5 19 27 19 29V33.5" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M1.5 31.5C2.33333 33.1667 5.4 36.5 11 36.5C18 36.5 18.5 32 24 32C29.5 32 30 36.5 37 36.5C42.6 36.5 46 33.5 47 32" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M1.5 35.5C2.33333 37.1667 5.4 40.5 11 40.5C18 40.5 18.5 36 24 36C29.5 36 30 40.5 37 40.5C42.6 40.5 46 37.5 47 36" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
	</Icon>
)


export const InsuranceFloodString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40 36V21C40 20.2 39.3333 19.3333 39 19C35 15.3333 26.8 7.8 26 7C25.2 6.2 24.3333 6 24 6C23.6667 6 22.8 6.2 22 7C21.2 7.8 13 15.3333 9 19C8.66667 19.3333 8 20.2 8 21V36M29 33.5V29C29 27 28 26.5 26 26.5H22C20 26.5 19 27 19 29V33.5" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M1.5 31.5C2.33333 33.1667 5.4 36.5 11 36.5C18 36.5 18.5 32 24 32C29.5 32 30 36.5 37 36.5C42.6 36.5 46 33.5 47 32" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M1.5 35.5C2.33333 37.1667 5.4 40.5 11 40.5C18 40.5 18.5 36 24 36C29.5 36 30 40.5 37 40.5C42.6 40.5 46 37.5 47 36" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
</svg>
`;