import { Icon } from '@chakra-ui/react'

export const Close = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M4.089 4.216L4.146 4.146C4.22885 4.06333 4.33809 4.01234 4.45467 4.00192C4.57126 3.99151 4.6878 4.02232 4.784 4.089L4.854 4.146L10 9.293L15.146 4.146C15.2289 4.06333 15.3381 4.01234 15.4547 4.00192C15.5713 3.99151 15.6878 4.02232 15.784 4.089L15.854 4.146C15.9367 4.22886 15.9877 4.33809 15.9981 4.45468C16.0085 4.57126 15.9777 4.6878 15.911 4.784L15.854 4.854L10.707 10L15.854 15.146C15.9367 15.2289 15.9877 15.3381 15.9981 15.4547C16.0085 15.5713 15.9777 15.6878 15.911 15.784L15.854 15.854C15.7711 15.9367 15.6619 15.9877 15.5453 15.9981C15.4287 16.0085 15.3122 15.9777 15.216 15.911L15.146 15.854L10 10.707L4.854 15.854C4.77114 15.9367 4.66191 15.9877 4.54533 15.9981C4.42874 16.0085 4.3122 15.9777 4.216 15.911L4.146 15.854C4.06332 15.7711 4.01234 15.6619 4.00192 15.5453C3.99151 15.4287 4.02232 15.3122 4.089 15.216L4.146 15.146L9.293 10L4.146 4.854C4.06332 4.77115 4.01234 4.66191 4.00192 4.54533C3.99151 4.42874 4.02232 4.3122 4.089 4.216L4.146 4.146L4.089 4.216V4.216Z" fill="currentColor"/>
	</Icon>
)


export const CloseString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.089 4.216L4.146 4.146C4.22885 4.06333 4.33809 4.01234 4.45467 4.00192C4.57126 3.99151 4.6878 4.02232 4.784 4.089L4.854 4.146L10 9.293L15.146 4.146C15.2289 4.06333 15.3381 4.01234 15.4547 4.00192C15.5713 3.99151 15.6878 4.02232 15.784 4.089L15.854 4.146C15.9367 4.22886 15.9877 4.33809 15.9981 4.45468C16.0085 4.57126 15.9777 4.6878 15.911 4.784L15.854 4.854L10.707 10L15.854 15.146C15.9367 15.2289 15.9877 15.3381 15.9981 15.4547C16.0085 15.5713 15.9777 15.6878 15.911 15.784L15.854 15.854C15.7711 15.9367 15.6619 15.9877 15.5453 15.9981C15.4287 16.0085 15.3122 15.9777 15.216 15.911L15.146 15.854L10 10.707L4.854 15.854C4.77114 15.9367 4.66191 15.9877 4.54533 15.9981C4.42874 16.0085 4.3122 15.9777 4.216 15.911L4.146 15.854C4.06332 15.7711 4.01234 15.6619 4.00192 15.5453C3.99151 15.4287 4.02232 15.3122 4.089 15.216L4.146 15.146L9.293 10L4.146 4.854C4.06332 4.77115 4.01234 4.66191 4.00192 4.54533C3.99151 4.42874 4.02232 4.3122 4.089 4.216L4.146 4.146L4.089 4.216V4.216Z" fill="#003D66"/>
</svg>
`;