import React, { useEffect } from 'react'

/**
 * Chrome is automatically filling inputs without triggering Reacts onChange event.
 * This component is a workaround for that.
 * Currently, works only for username and password inputs.
 * `values` and `setValues` are marked as `any` because it's a workaround for a bug in Formik,
 * and I am lazy :))
 */
export const AutofillSync: React.FC<{ values: any; setValues: any }> = (props) => {
  useEffect(() => {
    setTimeout(() => {
      if (
        (document.querySelector('input[name="username"]') as HTMLInputElement)?.value ||
        (document.querySelector('input[name="password"]') as HTMLInputElement)?.value
      ) {
        if (!props.values.email || !props.values.password) {
          props.setValues({
            username:
              (document.querySelector('input[name="username"]') as HTMLInputElement)?.value || '',
            password:
              (document.querySelector('input[name="password"]') as HTMLInputElement)?.value || '',
          })
        }
      }
    }, 200)
    // Really want to call this only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
