import { forwardRef, Select } from '@chakra-ui/react'
import { countries } from '../utils/countries'

export const CountrySelect = forwardRef((props: any, ref: any) => {
  return (
    <Select {...props} ref={ref}>
      <option disabled value="">
        Vyberte státní občanství
      </option>
      {countries &&
        countries.length > 0 &&
        countries.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
    </Select>
  )
})
