import { useGetUserCurrent } from '../api/user/useGetUserCurrent'
import { UserRoleEnum } from '../enums/UserRoleEnum'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useDeleteLogin } from '../api/login/useDeleteLogin'
import { IUserItem } from '../interfaces/entities/IUser'
import { ISelectOption } from '../interfaces/ISelectOption'

export const useIsSupervisorOrAdmin = (): boolean | undefined => {
  const { data, isLoading } = useGetUserCurrent()
  if (isLoading) {
    return undefined
  }

  const role = data?.data.role

  if (!role) {
    return false
  }

  return role === UserRoleEnum.SUPERVISOR || role === UserRoleEnum.ADMIN
}
export const useIsSupervisor = (): boolean | undefined => {
  const { data, isLoading } = useGetUserCurrent()
  if (isLoading) {
    return undefined
  }
  return data?.data.role === UserRoleEnum.SUPERVISOR || false
}

export const useIsFull = (): boolean | undefined => {
  const { data, isLoading } = useGetUserCurrent()
  if (isLoading) {
    return undefined
  }
  return data?.data.role === UserRoleEnum.FULL || false
}

export const useIsBasic = (): boolean | undefined => {
  const { data, isLoading } = useGetUserCurrent()
  if (isLoading) {
    return undefined
  }
  return data?.data.role === UserRoleEnum.BASIC || false
}

export const useIsCurrent = (value: number): boolean | undefined => {
  const { data, isLoading } = useGetUserCurrent()
  if (isLoading) {
    return undefined
  }
  return data?.data.id === value || false
}

export const useLogout = (): (() => Promise<void>) => {
  const navigate = useNavigate()
  const [, , removeCookie] = useCookies()
  const { mutateAsync: deleteLoginAsync } = useDeleteLogin()

  return async () => {
    await deleteLoginAsync()
    removeCookie('PHPSESSID', { path: '/' })
    navigate('/auth/login')
  }
}

export const usersListToSelectOptions = (list: IUserItem[]): ISelectOption[] => {
  return list.map((item) => ({
    label: `${item.firstName} ${item.lastName}`,
    value: item.id,
  }))
}

export const useUserIsAllowedInsurance = (): boolean | undefined => {
  const { data, isLoading } = useGetUserCurrent()

  if (isLoading || !data) {
    return undefined
  }

  return !!data.data.isAllowedInsurance
}

export const useUserIsAllowedInsuranceAnotherUser = (): boolean | undefined => {
  const { data, isLoading } = useGetUserCurrent()

  if (isLoading || !data) {
    return undefined
  }

  return !!data.data.isAllowedInsuranceAnotherUser
}

export const userRoleToLabel = (role: string): string => {
  switch (role) {
    case UserRoleEnum.SALESMAN:
      return 'obchodník'
    case UserRoleEnum.ADMIN:
      return 'OU administrátor'
    default:
      return role
  }
}
