import { useState } from 'react'
import { Box, BoxProps, Button } from '@chakra-ui/react'

import { IndividualDiscounts } from './carkulka/IndividualDiscounts'
import { BusinessDiscounts } from './carkulka/BusinessDiscounts'

export const DiscountsCars = () => {
  const [opened, setOpened] = useState(false)
  const closedLabel = 'Nastavit slevy'
  const openedLabel = 'Skrýt slevy'
  const headerFooterSx: BoxProps = {
    display: 'grid',
    w: '100%',
    justifyItems: 'end',
  }

  return (
    <Box sx={{ w: '100%', display: 'grid', gap: 4 }}>
      <Box as="header" sx={headerFooterSx}>
        <Button variant="outline" onClick={() => setOpened(!opened)}>
          {opened ? openedLabel : closedLabel}
        </Button>
      </Box>

      <Box sx={{ display: opened ? 'grid' : 'none', gap: 8 }}>
        <IndividualDiscounts />

        <BusinessDiscounts />
      </Box>

      {opened && (
        <Box as="footer" sx={headerFooterSx}>
          <Button variant="outline" onClick={() => setOpened(!opened)}>
            {opened ? openedLabel : closedLabel}
          </Button>
        </Box>
      )}
    </Box>
  )
}
