import { Box, Button, Checkbox, Image, Text } from '@chakra-ui/react'

import { FormLabel } from './FormLabel'
import { ChangeEvent, useCallback, useEffect, useState, useContext } from 'react'
import { ICompany } from '../utils/companies'
import { prefixUrlWithBasePath } from '../model/utils/urlUtils'
import { FormIsDisabledContext } from '../model/contexts/FormIsDisabledContext'
import { RequiredField } from '../containers/RequiredField'

interface CompanyProps extends ICompany {
  checked?: boolean
}

const CompanyCheckbox = ({ children }: any) => {
  return (
    <Box
      as="label"
      sx={{
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        px: 4,
        py: 1,
        borderRadius: 'sm',
        cursor: 'pointer',
        _hover: {
          backgroundColor: 'gray.50',
        },
      }}
    >
      {children}
    </Box>
  )
}

const getCompaniesState = (companies: Array<ICompany>) => {
  const checkedCompanies = companies.map((company: ICompany) => {
    return {
      ...company,
      checked: false,
    }
  })

  return checkedCompanies
}

export const InsuranceCompanies = ({
  companies,
  setFinalCompanies,
  values,
  selectAllOnMount,
}: {
  companies: Array<ICompany>
  setFinalCompanies?: Function
  values?: any
  selectAllOnMount?: boolean
}) => {
  const [companiesState, setCompaniesState] = useState(getCompaniesState(companies))
  const checkedCount = useCallback(
    () => companiesState.filter(({ checked }: CompanyProps) => checked === true).length,
    [companiesState],
  )
  const checkSelectAll = () => checkedCount() === 0
  const [selectAll, setSelectAll] = useState(checkSelectAll())
  const formIsDisabled = useContext(FormIsDisabledContext)

  useEffect(() => {
    if (values) {
      companiesState.map((e) => {
        values.companies.map((elm: number) => {
          if (e.id === elm) {
            e.checked = true
          }
        })
      })
    }
  }, [])

  useEffect(() => {
    setSelectAll(checkedCount() === 0)
    if (values && setFinalCompanies) {
      setFinalCompanies({
        ...values,
        companies: companiesState.filter((e: { checked: any }) => e.checked).map((e) => e.id),
      })
    }
  }, [companiesState, checkedCount])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const { checked, value } = event.target
    const newCompaniesState: any = companiesState.map((company: CompanyProps) => {
      if (company.id === Number(value)) {
        company.checked = checked
      }
      return company
    })

    setCompaniesState(newCompaniesState)
    setSelectAll(checkSelectAll())
  }

  const handleSelectAll = () => {
    const newCompaniesState = companiesState.map((company: CompanyProps) => {
      return {
        ...company,
        checked: selectAll,
      }
    })

    setCompaniesState(newCompaniesState)
    setSelectAll(checkSelectAll())
  }

  useEffect(() => {
    if (values?.companies?.length === 0 && selectAllOnMount) {
      handleSelectAll()
    }
  }, [selectAllOnMount])

  return (
    <Box>
      <Box as="header" sx={{ display: 'flex', gap: 4, mb: 4 }}>
        <FormLabel>
          Pojišťovny
          <RequiredField />
        </FormLabel>
        {!formIsDisabled && (
          <Button type="button" variant="outline" size="sm" onClick={handleSelectAll}>
            {selectAll ? 'Vybrat vše' : 'Zrušit výběr'}
          </Button>
        )}
      </Box>

      <Box as="main" sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
        {companiesState &&
          companiesState.map(({ id, name, checked, logo }: CompanyProps) => {
            const logoUrl = logo ? prefixUrlWithBasePath(logo) : undefined

            return (
              <CompanyCheckbox key={id}>
                <Checkbox type="checkbox" value={id} isChecked={checked} onChange={handleChange} />

                {logoUrl ? (
                  <Image src={logoUrl} alt={name} title={name} width="64px" height="48px" />
                ) : (
                  <Text>{name}</Text>
                )}
              </CompanyCheckbox>
            )
          })}
      </Box>
    </Box>
  )
}
