import * as yup from 'yup'
import { IS_REQUIRED, MIN_CHARACTERS } from '../model/constants/validationMessages'

export const OrganizationEditSchema = yup.object().shape({
  username: yup.string().min(3, MIN_CHARACTERS).required(IS_REQUIRED),
  ino: yup.string().min(2, MIN_CHARACTERS).required(IS_REQUIRED),
  street: yup.string().required(IS_REQUIRED),
  streetNumber: yup.string().required(IS_REQUIRED),
  zip: yup.string().required(IS_REQUIRED),
  web: yup.string().min(3, MIN_CHARACTERS),
  city: yup.string().required(IS_REQUIRED),
  ipidUrl: yup.string(),
  type: yup.string().required(IS_REQUIRED).typeError(IS_REQUIRED),
  email: yup.string().email('Email musí být ve správném formátu'),
})
