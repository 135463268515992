import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { formatPrice } from '../utils/formatPrice'

const SliderThumbIcon = () => {
  return (
    <Box
      sx={{
        w: 'px',
        h: 2,
        position: 'relative',
        bg: 'gray.500',
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          w: 'px',
          h: 2,
          bg: 'gray.500',
        },
        '&:before': { left: '-3px' },
        '&:after': { left: '3px' },
      }}
    />
  )
}

export const LiabilitySlider = ({
  name,
  defaultValue,
  min,
  max,
  step = 1,
  onChange,
}: {
  name: string
  defaultValue: number
  min: number
  max: number
  step?: number
  onChange: (value: number) => void
}) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <Slider
      aria-label={`slider-${name}`}
      defaultValue={defaultValue}
      value={value}
      maxW={'14rem'}
      min={min}
      max={max}
      step={step}
      sx={{ position: 'relative' }}
      onChange={(value) => {
        setValue(value)
      }}
      onChangeEnd={(value) => {
        onChange(value)
      }}
    >
      <SliderMark value={min} sx={{ position: 'absolute', left: 0, fontSize: 'sm' }}>
        {`${formatPrice({ price: min })}`}
      </SliderMark>
      <SliderMark
        value={max}
        sx={{
          position: 'absolute',
          left: '100%',
          transform: 'translateX(-100%)',
          fontSize: 'sm',
          whiteSpace: 'nowrap',
        }}
      >
        {`${formatPrice({ price: max })}`}
      </SliderMark>
      <SliderTrack sx={{ h: 2, mt: 3, bg: 'gray.200', borderRadius: 'md' }}>
        <SliderFilledTrack sx={{ bg: 'green.500' }} />
      </SliderTrack>
      <SliderThumb sx={{ w: 6, h: 6, mt: 3 }}>
        <SliderThumbIcon />
      </SliderThumb>
    </Slider>
  )
}
