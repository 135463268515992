import { Button } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { MainContent } from '../../components/MainContent'
import { FormButtons } from '../FormButtons'
import { Save } from '../../icons'
import { ExtraInsuranceCar } from '../ExtraInsuranceCar'
import { DiscountsCars } from '../discounts/DiscountsCars'

interface Step2FormProps {
  onSubmit: Function
}

export interface Step2FormValues {}

export const Step2Form = ({ onSubmit }: Step2FormProps) => {
  const methods = useForm<Step2FormValues>({
    defaultValues: {},
  })
  const {
    handleSubmit,
    // register,
    formState: { isSubmitting },
  } = methods

  const onSubmitForm = (values: Step2FormValues) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(values)
        resolve(true)
      }, 500)
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <MainContent>
          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Připojištění</FormSectionTitle>

              <FormSectionDescription>
                Vyberte limity zvoleného připojišťění.
              </FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              <ExtraInsuranceCar />
            </FormSectionBody>
          </FormSection>

          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>Slevy pojištění</FormSectionTitle>

              <FormSectionDescription>
                Nastavte slevy pojistníka a jednotlivých pojišťoven.
              </FormSectionDescription>
            </FormSectionHeader>

            <FormSectionBody>
              <DiscountsCars />
            </FormSectionBody>
          </FormSection>

          <FormButtons
            rightButtons={
              <>
                <Button variant="outline" disabled={isSubmitting} type="button">
                  Uložit
                </Button>
                <Button
                  colorScheme="primary"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  loadingText="Počítám nabídky..."
                  type="submit"
                >
                  <Save />
                  Uložit a spočítat nabídky
                </Button>
              </>
            }
          />
        </MainContent>
      </form>
    </FormProvider>
  )
}
