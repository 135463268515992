import { Container, Grid, Image } from '@chakra-ui/react'
import React from 'react'
import { Section } from '../../components/Section'
import okAppkyLogo from '../../assets/images/logo-ok-appky.svg'
import { Card, CardBody } from '../../components/Card'
import { NewPasswordForm } from '../../containers/NewPasswordForm'

export const ResetPassword: React.FC = () => {
  return (
    <Section variant="login">
      <Container>
        <Grid sx={{ maxW: 'loginFormMaxW', m: 'auto', gap: 8, alignContent: 'start' }}>
          <Image src={okAppkyLogo} w={'auto'} maxW="14rem" h={12} m="auto" alt="OK appky" />

          <Card>
            <CardBody>
              <NewPasswordForm />
            </CardBody>
          </Card>
        </Grid>
      </Container>
    </Section>
  )
}
