import { MouseEvent } from "react"

const useCloneClickHandler = (mutateCopyOffer: (arg: boolean) => void) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    // event.buttons === 4 is the middle mouse button (mouse wheel click)
    if (event.buttons === 4) {
      mutateCopyOffer(true)
    } else {
      mutateCopyOffer(false)
    }
  }
  return handleClick
}
export default useCloneClickHandler