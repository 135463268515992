import { Icon } from '@chakra-ui/react'

export const InsuranceValorization = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M41 6H7V41H41V6Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M9.46967 30.4697C9.17678 30.7626 9.17678 31.2374 9.46967 31.5303C9.76256 31.8232 10.2374 31.8232 10.5303 31.5303L9.46967 30.4697ZM16 25L16.5303 24.4697C16.2374 24.1768 15.7626 24.1768 15.4697 24.4697L16 25ZM20 29L19.4697 29.5303C19.7626 29.8232 20.2374 29.8232 20.5303 29.5303L20 29ZM26 23L26.5303 22.4697C26.2374 22.1768 25.7626 22.1768 25.4697 22.4697L26 23ZM29 26L28.4697 26.5303C28.7626 26.8232 29.2374 26.8232 29.5303 26.5303L29 26ZM38.5303 17.5303C38.8232 17.2374 38.8232 16.7626 38.5303 16.4697C38.2374 16.1768 37.7626 16.1768 37.4697 16.4697L38.5303 17.5303ZM10.5303 31.5303L16.5303 25.5303L15.4697 24.4697L9.46967 30.4697L10.5303 31.5303ZM15.4697 25.5303L19.4697 29.5303L20.5303 28.4697L16.5303 24.4697L15.4697 25.5303ZM20.5303 29.5303L26.5303 23.5303L25.4697 22.4697L19.4697 28.4697L20.5303 29.5303ZM25.4697 23.5303L28.4697 26.5303L29.5303 25.4697L26.5303 22.4697L25.4697 23.5303ZM29.5303 26.5303L38.5303 17.5303L37.4697 16.4697L28.4697 25.4697L29.5303 26.5303Z" fill="currentColor"/>
	</Icon>
)


export const InsuranceValorizationString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41 6H7V41H41V6Z" stroke="#EE7400" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M9.46967 30.4697C9.17678 30.7626 9.17678 31.2374 9.46967 31.5303C9.76256 31.8232 10.2374 31.8232 10.5303 31.5303L9.46967 30.4697ZM16 25L16.5303 24.4697C16.2374 24.1768 15.7626 24.1768 15.4697 24.4697L16 25ZM20 29L19.4697 29.5303C19.7626 29.8232 20.2374 29.8232 20.5303 29.5303L20 29ZM26 23L26.5303 22.4697C26.2374 22.1768 25.7626 22.1768 25.4697 22.4697L26 23ZM29 26L28.4697 26.5303C28.7626 26.8232 29.2374 26.8232 29.5303 26.5303L29 26ZM38.5303 17.5303C38.8232 17.2374 38.8232 16.7626 38.5303 16.4697C38.2374 16.1768 37.7626 16.1768 37.4697 16.4697L38.5303 17.5303ZM10.5303 31.5303L16.5303 25.5303L15.4697 24.4697L9.46967 30.4697L10.5303 31.5303ZM15.4697 25.5303L19.4697 29.5303L20.5303 28.4697L16.5303 24.4697L15.4697 25.5303ZM20.5303 29.5303L26.5303 23.5303L25.4697 22.4697L19.4697 28.4697L20.5303 29.5303ZM25.4697 23.5303L28.4697 26.5303L29.5303 25.4697L26.5303 22.4697L25.4697 23.5303ZM29.5303 26.5303L38.5303 17.5303L37.4697 16.4697L28.4697 25.4697L29.5303 26.5303Z" fill="#EE7400"/>
</svg>
`;