import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  StylesProvider,
  useDisclosure,
  useMultiStyleConfig,
  useStyles,
} from '@chakra-ui/react'
import React, { MouseEvent } from 'react'
import { Link, redirect } from 'react-router-dom'
import { useApp } from '../context/AppContext'

import { Contract, External, Logout, News, Offer, Settings, User } from '../icons'
import { useGetUserCurrent } from '../model/api/user/useGetUserCurrent'
import { MenuItem } from './Menu'

interface MobileMenuProps extends BoxProps {}

interface MobileMenuItemProps extends ButtonProps {
  to: string
  icon?: IconsEnum
}

export const enum IconsEnum {
  Offer = 'offer',
  Contract = 'contract',
  Settings = 'settings',
  News = 'news',
  User = 'user',
  Logout = 'logout',
}

const getMenuItemIcon = (icon: IconsEnum) => {
  switch (icon) {
    case 'offer':
      return <Offer />
    case 'contract':
      return <Contract />
    case 'settings':
      return <Settings />
    case 'news':
      return <News />
    case 'user':
      return <User />
    case 'logout':
      return <Logout />
  }
}

export const MobileMenuItem = ({ children, to, icon, ...rest }: MobileMenuItemProps) => {
  const styles = useStyles()

  return (
    <Button as={Link} to={to} variant="ghost" __css={styles.item} {...rest}>
      {icon && <Box sx={{ color: 'secondary.500' }}>{getMenuItemIcon(icon)}</Box>}
      {children}
    </Button>
  )
}

export const MobileMenu = (props: MobileMenuProps) => {
  const { app } = useApp()
  const { children, ...rest } = props
  const { data } = useGetUserCurrent()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSettingsClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const to = e.currentTarget.getAttribute('to')

    if (isOpen) {
      to && redirect(to)
    }

    onOpen()
  }

  const styles = useMultiStyleConfig('MobileMenu', props)

  const numberOfItems = React.Children.toArray(props.children).length

  return (
    <Box
      __css={styles.container}
      sx={{ gridTemplateColumns: `repeat(${numberOfItems})` }}
      {...rest}
    >
      <StylesProvider value={styles}>
        <MobileMenuItem to={`/${app}/concepts`} icon={IconsEnum.Offer}>
          Nabídky
        </MobileMenuItem>
        <MobileMenuItem to={`/${app}/contracts`} icon={IconsEnum.Contract}>
          Smlouvy
        </MobileMenuItem>
        <MobileMenuItem to="#" onClick={handleSettingsClick} icon={IconsEnum.Settings}>
          Nastavení
        </MobileMenuItem>
        <MobileMenuItem to="/news" icon={IconsEnum.News}>
          Aktuality
        </MobileMenuItem>
        <MobileMenuItem to={`/user/detail/${data?.data.id}`} icon={IconsEnum.User}>
          Profil
        </MobileMenuItem>
      </StylesProvider>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent sx={{ mx: 4, mt: 'auto' }}>
          <ModalCloseButton />

          <ModalHeader sx={{ px: 4 }}>Nastavení</ModalHeader>

          <ModalBody sx={{ px: 2, pt: 0 }}>
            <Box
              as="ul"
              sx={{
                listStyle: 'none',
                zIndex: 'subMenu',
              }}
            >
              <li>
                <MenuItem href="/organization/list">Organizace</MenuItem>
              </li>
              <li>
                <MenuItem href="/user/list">Uživatelé</MenuItem>
              </li>
              <li>
                <MenuItem href="https://trello.com/b/4QLwMRBu/okh-sme-businesska" target="_blank">
                  HelpDesk
                  <External />
                </MenuItem>
              </li>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
