import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  Select,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useState } from 'react'

import { Datepicker } from '../../../components/Datepicker'
import { InputGroup } from '../../../components/InputGroup'
import { Search } from '../../../icons'
import { SearchWrapper } from '../../search/SearchWrapper'
import {
  ConceptsPropertySearchFormProps,
  IConceptSearchForm,
} from '../../../model/interfaces/entities/IConceptsSearchForm'
import { useGetCompanies } from '../../../model/api/company/useGetCompany'
import { Loading } from '../../../components/Loading'
import { useGetOrganizations } from '../../../model/api/organization/useGetOrganizations'
import { useGetUser } from '../../../model/api/user/useGetUser'
import moment from 'moment'
import { OrganizationTypeEnum } from '../../../model/enums/OrganizationTypeEnum'
import { Select as Select2 } from 'chakra-react-select'
import { organizationListToSelectOptions } from '../../../model/utils/organizationUtils'
import { usersListToSelectOptions } from '../../../model/utils/userUtils'

const initialValuesConcepts: IConceptSearchForm = {
  number: undefined,
  dateCreatedFrom: undefined,
  dateCreatedTo: undefined,
  companyId: undefined,
  client: undefined,
  ino: undefined,
  organizationId: undefined,
  userId: undefined,
  status: [],
  page: undefined,
}

export const ConceptsPropertySearchForm = ({
  params,
  setParams,
}: ConceptsPropertySearchFormProps) => {
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    params.dateCreatedFrom ? moment(params.dateCreatedFrom).toDate() : undefined,
  )
  const [dateTo, setDateTo] = useState<Date | undefined>(
    params.dateCreatedTo ? moment(params.dateCreatedTo).toDate() : undefined,
  )

  const { data, isLoading } = useGetCompanies()
  const { data: organizations, isLoading: isLoadingOrganizations } = useGetOrganizations(
    1,
    1_000_000,
    true,
    OrganizationTypeEnum.PARTNER,
  )
  const { data: users, isLoading: isLoadingUsers } = useGetUser(1, 1_000_000)

  const organizationSelectOptions = organizationListToSelectOptions(
    organizations?.data?.items || [],
  )
  const userSelectOptions = usersListToSelectOptions(users?.data?.items || [])

  const onSubmitForm: (formValues: IConceptSearchForm) => void = (
    formValues: IConceptSearchForm,
  ) => {
    if (dateFrom) {
      formValues.dateCreatedFrom = dateFrom.toLocaleDateString('en-CA')
    }
    if (dateTo) {
      formValues.dateCreatedTo = dateTo.toLocaleDateString('en-CA')
    }
    setParams({
      ...formValues,
      page: 1,
    })
  }

  return (
    <Formik initialValues={params} onSubmit={(values) => onSubmitForm(values)}>
      {({ handleChange, values, setFieldValue }) => {
        console.log({ userId: values.userId, organizationId: values.organizationId })
        return (
          <Form>
            <SearchWrapper>
              <FormControl variant="fullWidth">
                <FormLabel htmlFor="number">Číslo nabídky </FormLabel>

                <InputGroup
                  name="number"
                  id="number"
                  placeholder="Např: 73622"
                  onChange={handleChange}
                  value={values.number}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="dateCreatedFrom">Datum od</FormLabel>

                <Datepicker
                  id="dateCreatedFrom"
                  name="dateCreatedFrom"
                  date={dateFrom}
                  onDateChange={(date: Date) => setDateFrom(date)}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="dateCreatedTo">Datum do</FormLabel>

                <Datepicker
                  id="dateCreatedTo"
                  name="dateCreatedTo"
                  date={dateTo}
                  onDateChange={(date: Date) => setDateTo(date)}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="companyId">Pojišťovna</FormLabel>

                {isLoading ? (
                  <Loading />
                ) : (
                  <Select
                    name="companyId"
                    id="companyId"
                    onChange={handleChange}
                    placeholder="Všechny pojišťovny"
                    value={values.companyId}
                  >
                    {data?.data &&
                      data?.data.length > 0 &&
                      data?.data.map((company) => {
                        return (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        )
                      })}
                  </Select>
                )}
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="client">Pojistník (příjmení/název firmy)</FormLabel>

                <InputGroup
                  name="client"
                  id="client"
                  placeholder="Např: Novák"
                  onChange={handleChange}
                  value={values.client}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="ino">Pojistník (rodné číslo/IČO)</FormLabel>

                <InputGroup
                  name="ino"
                  id="ino"
                  placeholder="Např: 9001012233"
                  onChange={handleChange}
                  value={values.ino}
                />
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="organizationId">Organizace </FormLabel>

                <FormControl variant="fullWidth">
                  <Select2
                    name="organizationId"
                    id="organizationId"
                    placeholder="Všechny organizace"
                    isLoading={isLoadingOrganizations}
                    value={organizationSelectOptions?.find((o) => o.value == values.organizationId)}
                    onChange={(option) => setFieldValue('organizationId', option?.value)}
                    options={organizationSelectOptions}
                    loadingMessage={() => 'Načítání organizací...'}
                    isClearable={true}
                  />
                </FormControl>
              </FormControl>

              <FormControl variant="fullWidth">
                <FormLabel htmlFor="userId">Uživatel</FormLabel>
                <Select2
                  id="userId"
                  name="userId"
                  placeholder="Všichni uživatelé"
                  onChange={(option) => {
                    console.log(option)
                    setFieldValue('userId', option?.value)
                  }}
                  value={userSelectOptions.find((u) => u.value == values.userId)}
                  options={userSelectOptions}
                  isLoading={isLoadingUsers}
                  loadingMessage={() => 'Načítání uživatelů...'}
                  isClearable={true}
                />
              </FormControl>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  columnGap: 4,
                  rowGap: 4,
                  alignItems: 'end',
                  justifyContent: 'space-between',
                  gridColumn: { base: '', md: '3 / 5' },
                }}
              >
                <FormControl>
                 {/* <FormLabel>Stav nabídky</FormLabel>

                  <CheckboxGroup value={values.status}>
                    <HStack spacing={{ base: 4, xl: 8 }} sx={{ minH: '36px' }}>
                      <Checkbox
                        value="1"
                        id="status"
                        onChange={handleChange}
                        isChecked={(values.status || []).includes('1')}
                      >
                        Vytvořená
                      </Checkbox>
                      <Checkbox
                        value="2"
                        id="status"
                        onChange={handleChange}
                        isChecked={(values.status || []).includes('2')}
                      >
                        K odeslání
                      </Checkbox>
                      <Checkbox
                        value="3"
                        id="status"
                        onChange={handleChange}
                        isChecked={(values.status || []).includes('3')}
                      >
                        Odeslaná
                      </Checkbox>
                    </HStack>
                  </CheckboxGroup>*/}
                </FormControl>
                <Button type="submit" colorScheme="primary">
                  <Search />
                  Vyhledat
                </Button>
              </Box>
            </SearchWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}
