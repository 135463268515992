import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from '../components/MainContent'
import { Recap } from './chaloupka/Recap'
import { IMeetingRecordFormValues, MeetingRecordForm } from './MeetingRecordForm'
import { useOfferOrContractData } from '../model/hooks/useOfferOrContractData'
import { CompanyAgreementTypeEnum } from '../model/enums/CompanyAgreementTypeEnum'
import { useToast } from '@chakra-ui/react'

const initialData: IMeetingRecordFormValues = {
  discrepanciesDescription: '',
  reason: 0,
  reasonDescription: '',
  impacts: [],
  insuranceDescription: '',
  otherRequirements: false,
  otherInsurance: false,
  productKnowledge: 0,
  discrepancies: false,
  place: '',
  contractType: 1,
  negotiationMethod: 0,
  dueNotice: [],
  personalNumber: 0,
}

export const MeetingRecordProperty: React.FC<{ viewOnly: boolean }> = ({ viewOnly }) => {
  const navigate = useNavigate()
  const toastMessage = useToast()
  const { id } = useParams<'id'>()
  const successCallBack = () => {
    return
  }

  const errorCallBack = (error: any) => {
    console.error(error)
  }
  const [data, isLoading] = useOfferOrContractData(id, successCallBack, errorCallBack, viewOnly)
  const insuranceCompanyAllowedAgreements = data?.data.item?.company?.agreements || []
  const insuranceAgreementMethod = data?.data.meetingMinutes?.negotiationMethod
    ? data?.data.meetingMinutes!.negotiationMethod === 1
      ? CompanyAgreementTypeEnum.SIGNATURE
      : CompanyAgreementTypeEnum.PAYMENT
    : data?.data.insurance.agreement
  const checkedAgreement = (() => {
    if (!insuranceAgreementMethod) {
      return insuranceCompanyAllowedAgreements[0]
    }

    if (insuranceCompanyAllowedAgreements.includes(insuranceAgreementMethod)) {
      return insuranceAgreementMethod
    }

    return insuranceCompanyAllowedAgreements[0]
  })()
  const agreementMethod = checkedAgreement === 'signature' ? 1 : 2

  const initialDataWithNegotiationMethod = data?.data.meetingMinutes
    ? {
        ...data?.data.meetingMinutes,
        dueNotice: Array.isArray(data?.data.meetingMinutes.dueNotice)
          ? data?.data.meetingMinutes.dueNotice
          : [],
        contractType: data?.data.meetingMinutes.contractType || 1,
        negotiationMethod: viewOnly ? data?.data.meetingMinutes.negotiationMethod : agreementMethod,
        manager: data?.data.manager ? data?.data.manager.id : null,
        gainer: data?.data.gainer ? data?.data.gainer.id : null,
      }
    : {
        ...initialData,
        negotiationMethod: agreementMethod,
      }

  return (
    <MainContent>
      <Recap offer={data?.data} />

      <MeetingRecordForm
        initialData={initialDataWithNegotiationMethod}
        onSubmit={() => {
          navigate(`/sme/wizard/${id}/completed`)
        }}
        viewOnly={viewOnly}
        offerErrors={data?.data?.errors}
        allowedAgreements={insuranceCompanyAllowedAgreements}
      />
    </MainContent>
  )
}
