import { Icon } from '@chakra-ui/react'

export const Edit = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 20 20" fill="none">
<path d="M13.245 2.817C13.7554 2.30752 14.4436 2.01605 15.1646 2.00399C15.8856 1.99193 16.5832 2.26022 17.1103 2.75234C17.6374 3.24446 17.953 3.92198 17.9904 4.64214C18.0278 5.3623 17.7843 6.06888 17.311 6.613L17.181 6.753L7.575 16.359C7.37024 16.5635 7.12357 16.7211 6.852 16.821L6.687 16.874L2.632 17.98C2.55345 18.0016 2.4708 18.0038 2.39125 17.9862C2.31169 17.9686 2.23764 17.9318 2.17554 17.8791C2.11343 17.8263 2.06516 17.7592 2.03491 17.6836C2.00467 17.6079 1.99337 17.526 2.002 17.445L2.018 17.365L3.124 13.311C3.2 13.031 3.336 12.771 3.522 12.551L3.639 12.423L13.245 2.817V2.817ZM12.385 5.092L4.346 13.13C4.25415 13.222 4.18112 13.331 4.131 13.451L4.089 13.574L3.212 16.784L6.424 15.909C6.50767 15.8862 6.58801 15.8526 6.663 15.809L6.77 15.737L6.868 15.652L14.906 7.612L12.385 5.092V5.092ZM16.474 3.524C16.1595 3.20929 15.7388 3.02352 15.2943 3.00317C14.8499 2.98282 14.4139 3.12935 14.072 3.414L13.952 3.524L13.092 4.384L15.612 6.906L16.474 6.046C16.7887 5.73152 16.9745 5.31076 16.9948 4.86633C17.0152 4.4219 16.8686 3.98592 16.584 3.644L16.474 3.524V3.524Z" fill="currentColor"/>
	</Icon>
)


export const EditString=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.245 2.817C13.7554 2.30752 14.4436 2.01605 15.1646 2.00399C15.8856 1.99193 16.5832 2.26022 17.1103 2.75234C17.6374 3.24446 17.953 3.92198 17.9904 4.64214C18.0278 5.3623 17.7843 6.06888 17.311 6.613L17.181 6.753L7.575 16.359C7.37024 16.5635 7.12357 16.7211 6.852 16.821L6.687 16.874L2.632 17.98C2.55345 18.0016 2.4708 18.0038 2.39125 17.9862C2.31169 17.9686 2.23764 17.9318 2.17554 17.8791C2.11343 17.8263 2.06516 17.7592 2.03491 17.6836C2.00467 17.6079 1.99337 17.526 2.002 17.445L2.018 17.365L3.124 13.311C3.2 13.031 3.336 12.771 3.522 12.551L3.639 12.423L13.245 2.817V2.817ZM12.385 5.092L4.346 13.13C4.25415 13.222 4.18112 13.331 4.131 13.451L4.089 13.574L3.212 16.784L6.424 15.909C6.50767 15.8862 6.58801 15.8526 6.663 15.809L6.77 15.737L6.868 15.652L14.906 7.612L12.385 5.092V5.092ZM16.474 3.524C16.1595 3.20929 15.7388 3.02352 15.2943 3.00317C14.8499 2.98282 14.4139 3.12935 14.072 3.414L13.952 3.524L13.092 4.384L15.612 6.906L16.474 6.046C16.7887 5.73152 16.9745 5.31076 16.9948 4.86633C17.0152 4.4219 16.8686 3.98592 16.584 3.644L16.474 3.524V3.524Z" fill="#003D66"/>
</svg>
`;