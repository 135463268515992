import { Icon } from '@chakra-ui/react'

export const Animal = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 48 48" fill="none">
<path d="M43.2 9.59999L38.4 19.2H33.6M4.79999 9.59999L9.59999 19.2H14.4M14.4 19.2L11.2 9.59999M14.4 19.2L19.2 20.4M9.59999 4.79999L11.2 9.59999M33.6 19.2L36.8 9.59999M33.6 19.2L28.8 20.4M38.4 4.79999L36.8 9.59999M36.8 9.59999L33.6 4.79999M11.2 9.59999L14.4 4.79999M19.2 20.4L24 21.6L28.8 20.4M19.2 20.4L16.8 12M28.8 20.4L31.2 12" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M28.8 22.8H19.2C18.24 22.8 16.4 24.4 15.6 25.2H9.59998C9.59998 28.08 13.6 29.6 15.6 30C15.6 35.76 19.2 43.2 19.2 43.2C19.2 43.2 20.4 44.4 21.6 44.4H26.4C28.32 44.4 28.8 43.2 28.8 43.2C28.8 43.2 32.4 36 32.4 30C36.24 30 38 26.8 38.4 25.2H32.4C32.4 24.24 30 23.2 28.8 22.8Z" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
)


export const AnimalString=`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M43.2 9.59999L38.4 19.2H33.6M4.79999 9.59999L9.59999 19.2H14.4M14.4 19.2L11.2 9.59999M14.4 19.2L19.2 20.4M9.59999 4.79999L11.2 9.59999M33.6 19.2L36.8 9.59999M33.6 19.2L28.8 20.4M38.4 4.79999L36.8 9.59999M36.8 9.59999L33.6 4.79999M11.2 9.59999L14.4 4.79999M19.2 20.4L24 21.6L28.8 20.4M19.2 20.4L16.8 12M28.8 20.4L31.2 12" fill="none" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.8 22.8H19.2C18.24 22.8 16.4 24.4 15.6 25.2H9.59998C9.59998 28.08 13.6 29.6 15.6 30C15.6 35.76 19.2 43.2 19.2 43.2C19.2 43.2 20.4 44.4 21.6 44.4H26.4C28.32 44.4 28.8 43.2 28.8 43.2C28.8 43.2 32.4 36 32.4 30C36.24 30 38 26.8 38.4 25.2H32.4C32.4 24.24 30 23.2 28.8 22.8Z" fill="none" stroke="#EE7400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;