import { checkboxAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const defaultBorderRadius = 'md'

const baseStyleControl: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props

  return {
    w: '100%',
    transitionProperty: 'box-shadow',
    transitionDuration: 'normal',
    border: '1px solid',
    borderRadius: 'sm',
    borderColor: 'gray.400',
    boxShadow: 'sm',
    color: 'white',

    _checked: {
      bg: mode(`${c}.500`, `${c}.200`)(props),
      borderColor: mode(`${c}.500`, `${c}.200`)(props),
      boxShadow: 'sm',
      color: mode('white', 'gray.900')(props),

      _hover: {
        bg: mode(`${c}.600`, `${c}.300`)(props),
        borderColor: mode(`${c}.600`, `${c}.300`)(props),
      },

      _disabled: {
        borderColor: mode('gray.200', 'transparent')(props),
        bg: mode('gray.200', 'whiteAlpha.300')(props),
        color: mode('gray.500', 'whiteAlpha.500')(props),
      },
    },

    _indeterminate: {
      bg: mode(`${c}.500`, `${c}.200`)(props),
      borderColor: mode(`${c}.500`, `${c}.200`)(props),
      color: mode('white', 'gray.900')(props),
    },

    _disabled: {
      bg: mode('gray.100', 'whiteAlpha.100')(props),
      borderColor: mode('gray.100', 'transparent')(props),
    },

    _focus: {
      boxShadow: 'outline',
    },

    _invalid: {
      borderColor: mode('red.500', 'red.300')(props),
    },
  }
}

const baseStyleContainer: SystemStyleObject = {
  display: 'grid',
  gap: 3,
  gridTemplateColumns: 'auto auto',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'start',
  _disabled: { cursor: 'not-allowed' },
}

const baseStyleLabel: SystemStyleObject = {
  lineHeight: '4',
  userSelect: 'none',
  ml: 0,
  _disabled: { opacity: 0.4 },
}

const baseStyleIcon: SystemStyleObject = {
  transitionProperty: 'transform',
  transitionDuration: 'normal',
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  icon: baseStyleIcon,
  container: baseStyleContainer,
  control: baseStyleControl(props),
  label: baseStyleLabel,
})

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    control: { h: 3, w: 3 },
    label: { fontSize: 'sm' },
    icon: { fontSize: '0.45rem' },
  },
  md: {
    control: { w: 4, h: 4 },
    label: { fontSize: 'md' },
    icon: { fontSize: '0.625rem' },
  },
  lg: {
    control: { w: 5, h: 5 },
    label: { fontSize: 'lg' },
    icon: { fontSize: '0.625rem' },
  },
}

const variantTile: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: {
      display: { base: 'flex', sm: 'grid' },
      gridTemplateColumns: 'auto',
      gap: 2,
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      justifyItems: 'center',
      px: 4,
      py: 3,
      bg: mode('gray.50', 'whiteAlpha.900')(props),
      border: '1px solid',
      borderColor: mode('gray.400', 'whiteAlpha.800')(props),
      borderRadius: defaultBorderRadius,
      _hover: {
        bg: 'white',
      },
      _last: {
        borderRightWidth: '1px',
      },
    },
  }
}

const variants = {
  tile: variantTile,
}

const defaultProps = {
  size: 'md',
  colorScheme: 'primary',
}

export const Checkbox = {
  parts: parts.keys,
  baseStyle,
  sizes,
  defaultProps,
  variants,
}
