import { INewsItem } from '../../data/news'
import { NewsItem } from './NewsItem'
import { INewsItemV2 } from '../../model/interfaces/entities/INews'

interface INewsList {
  news?: Array<INewsItemV2>
}

export const NewsList = ({ news }: INewsList) => {
  return (
    <>
      {news &&
        news.length > 0 &&
        news.map(({ id, app, title, date, tagLabel, status, unread }) => {
          return (
            <NewsItem
              variant="list"
              key={id}
              app={app}
              id={id}
              title={title}
              date={date}
              status={status}
              tagLabel={tagLabel}
              unread={unread}
            />
          )
        })}
    </>
  )
}
