import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import api from '../api'

export const useCancelContract = (id: number | string) => {
  const queryClient = useQueryClient()
  return useMutation(() => api.post(`${ApiRouteEnum.CONTRACT}/${id}/cancel`), {
    mutationKey: [ApiRouteEnum.CONTRACT],
    onSuccess: (data) => {
      queryClient.invalidateQueries([ApiRouteEnum.CONTRACT])
    },
  })
}
