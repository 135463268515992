import { FormControl } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { FormLabel } from '../../components/FormLabel'
import { RadioIcon } from '../../components/RadioIcon'
import { RadioIconGroup } from '../../components/RadioIconGroup'
import { RadioStack } from '../../components/RadioStack'

export const InsuranceType = () => {
  const { control } = useFormContext()

  return (
    <FormControl>
      <FormLabel>Typ pojištění</FormLabel>

      <RadioIconGroup control={control} name="insuranceType">
        <RadioStack>
          <RadioIcon icon="householdProperty" value="all">
            Domácnost + nemovitost
          </RadioIcon>
          <RadioIcon icon="house" value="property">
            Nemovitost
          </RadioIcon>
          <RadioIcon icon="household" value="household">
            Domácnost
          </RadioIcon>
        </RadioStack>
      </RadioIconGroup>
    </FormControl>
  )
}
