import React from 'react'
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { DownloadButton } from '../DownloadButton'
import { IDocument } from '../../model/interfaces/entities/IDocument'
import { useParams } from 'react-router-dom'
import { usePostContractSendMail } from '../../model/api/contracts/usePostContractSendMail'
import ModalEmailFormComplete from '../../components/ModalEmailFormComplete'

export const InsuranceDownloads: React.FC<{ documents: IDocument[], insureeEmail?: string }> = ({ documents, insureeEmail }) => {
  const openDocument = (url: string) => {
    window.open(url, '_blank')
  }
  const toastMessage = useToast()
  const { id } = useParams<'id'>()
  const successSendMailCallBack = (data: any) => {
    onEmailModalClose()
    toastMessage({
      title: 'Odesláno',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }

  const errorSendMailCallBack = (error: any) => {
    toastMessage({
      title: error.response?.data.error,
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }
  const { isLoading, mutate: sendMailMutate } = usePostContractSendMail(
    successSendMailCallBack,
    errorSendMailCallBack,
    id,
  )
  const {
    isOpen: isEmailModalOpen,
    onOpen: onEmailModalOpen,
    onClose: onEmailModalClose,
  } = useDisclosure()
  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>Dokumenty k pojištění</FormSectionTitle>

        <FormSectionDescription>
          <Button type="button" colorScheme="primary" onClick={onEmailModalOpen}>
            Odeslat e-mail klientovi
          </Button>
          <Modal isOpen={isEmailModalOpen} onClose={onEmailModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>E-mail</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ModalEmailFormComplete isLoading={isLoading} insureeEmail={insureeEmail} onMailMutate={sendMailMutate} />
              </ModalBody>
            </ModalContent>
          </Modal>
        </FormSectionDescription>
      </FormSectionHeader>

      <FormSectionBody>
        <Box sx={{ display: 'grid', gap: 0, placeItems: 'start' }}>
          {documents.map((document) => (
            <Box sx={{ display: 'grid', gap: 0, placeItems: 'start' }} key={document.id}>
              <DownloadButton onClick={() => openDocument(document.url)} isDownloadAll>
                {document.name}
              </DownloadButton>
            </Box>
          ))}
        </Box>
      </FormSectionBody>
    </FormSection>
  )
}
