import {
  mode,
  PartsStyleFunction,
  SystemStyleFunction,
  SystemStyleObject,
  anatomy,
} from '@chakra-ui/theme-tools'
import { ComponentMultiStyleConfig } from '@chakra-ui/react'

const mobileMenuAnatomy = anatomy('mobileMenu').parts('container', 'item')

const baseStyleControl: SystemStyleFunction = (props) => {
  return {
    display: { base: 'grid', xl: 'none' },
    gridAutoFlow: 'column',
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 'mobileMenu',
    background: mode('white', 'gray.800')(props),
    borderTop: '1px solid',
    borderTopColor: 'gray.200',
  }
}

const baseStyleItem: SystemStyleObject = {
  borderRadius: 'none',
  display: 'grid',
  width: '100%',
  px: 0.5,
  py: 2,
  gridAutoFlow: 'row',
  gap: 1,
  justifyItems: 'center',
  fontSize: 'xs',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const baseStyle: PartsStyleFunction<typeof mobileMenuAnatomy> = (props) => {
  return {
    container: baseStyleControl(props),
    item: baseStyleItem,
  }
}

export const MobileMenu: ComponentMultiStyleConfig = {
  parts: mobileMenuAnatomy.keys,
  baseStyle,
}
