import { Box, BoxProps, Button, Heading, useToast } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardProps } from '../../components/Card'

import { getNewsItemStatusColor } from '../../data/news'
import { ArrowLeft, Calendar, Clock, User } from '../../icons'
import { NewsGrid } from './NewsGrid'
import { INewsItemV2 } from '../../model/interfaces/entities/INews'
import { usePatchReadNews } from '../../model/api/news/usePatchReadNews'
import { useEffect } from 'react'
import { dateToCzechShortFormat } from '../../model/utils/dateUtils'

interface NewsDetailProps extends INewsItemV2 {
  otherNews: Array<INewsItemV2>
}

type NewsMetadataIconType = 'user' | 'calendar' | 'clock'

interface NewsMetadataItemProps {
  icon?: NewsMetadataIconType
}

interface NewsMetadataItemProps extends BoxProps {
  icon?: NewsMetadataIconType
}

const NewsMetadataIcon = ({ icon }: { icon: NewsMetadataIconType }) => {
  const color = 'secondary.500'
  switch (icon) {
    case 'user':
      return <User color={color} />
    case 'calendar':
      return <Calendar color={color} />
    case 'clock':
      return <Clock color={color} />
  }
}

const NewsDetailWrapper = (props: BoxProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        placeItems: 'start',
        gap: 16,
      }}
      {...props}
    />
  )
}

const NewsDetailArticle = (props: BoxProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        justifyItems: 'start',
        columnGap: 8,
        rowGap: 8,
        gridTemplateColumns: { base: '1fr', md: '2fr 1fr' },
      }}
      {...props}
    />
  )
}

const NewsDetailContent = (props: BoxProps) => {
  return <Box sx={{ display: 'grid', placeContent: 'start', gap: 4 }} {...props} />
}

const NewsDetailMetadata = (props: CardProps) => {
  const { children, as, sx, colorScheme, ...rest } = props

  return (
    <Card
      as={as}
      sx={{ borderLeft: '2px solid', borderColor: `${colorScheme}.500`, ...sx }}
      {...rest}
    >
      <CardBody sx={{ display: 'grid', p: 4, gap: 4, placeItems: 'start' }}>
        {props.children}
      </CardBody>
    </Card>
  )
}

const NewsMetadataItem = ({ icon, ...rest }: NewsMetadataItemProps) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      {icon && <NewsMetadataIcon icon={icon} />}
      {rest.children}
    </Box>
  )
}

const NewsOtherNewsWrapper = (props: BoxProps) => {
  return <Box sx={{ display: 'grid', gap: 4 }} {...props} />
}

export const NewsDetail = ({
  author,
  title,
  date,
  status,
  tagLabel,
  content = '',
  otherNews,
  unread,
  id,
}: NewsDetailProps) => {
  const colorScheme = getNewsItemStatusColor(status)
  const toastMessage = useToast()
  const successCallBack: () => void = () => {
    // navigate('/news/admin')
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Error',
      description: error.response.data + '! Please try again!',
      status: 'error',
      isClosable: true,
    })
  }
  const { mutate } = usePatchReadNews(id, successCallBack, errorCallBack)
  useEffect(() => {
    unread && mutate()
  }, [])

  return (
    <NewsDetailWrapper>
      <NewsDetailArticle as="article">
        <NewsDetailContent as="main">
          <Heading as="h1" sx={{ fontSize: '3xl' }}>
            {title}
          </Heading>

          <Box>
            {' '}
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </Box>
        </NewsDetailContent>

        <NewsDetailMetadata sx={{ w: '100%' }} as="aside" colorScheme={colorScheme}>
          <NewsMetadataItem icon="user">{author}</NewsMetadataItem>
          {date && (
            <NewsMetadataItem icon="calendar">{dateToCzechShortFormat(date)}</NewsMetadataItem>
          )}
        </NewsDetailMetadata>

        <Button as={Link} to="/news" variant="ghost">
          <ArrowLeft color="secondary.500" />
          Zpět do aktualit
        </Button>
      </NewsDetailArticle>

      <NewsOtherNewsWrapper>
        <Heading as="h2" sx={{ fontSize: 'lg' }}>
          Další aktuality
        </Heading>

        <NewsGrid news={otherNews} />
      </NewsOtherNewsWrapper>
    </NewsDetailWrapper>
  )
}
